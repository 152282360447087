import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import TableHeaderHtmlCustom from '../../../../../components/common/customTable/TableHeaderHtmlCustom';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const headerStandard = [
  [
    { text: 'M/C NO.', rowspan: 2 },
    { text: 'OPERATOR NAME', rowspan: 2 },
    { text: 'MOULD ID', rowspan: 2 },
    { text: 'MOULD NAME', rowspan: 2 },
    { text: 'PART NUMBER', rowspan: 2 },
    { text: 'PART NAME', rowspan: 2 },
    { text: 'SHIFT', rowspan: 2 },
    { text: 'PRODUCTION', colspan: 2, rowspan: 1 },
    { text: 'Defect Type / Qty', rowspan: 2 },
    { text: 'Total Rej. QTY.', rowspan: 2 },
    { text: 'Types Of B/Down ( Minutes ) ', colspan: 7, rowspan: 1 },
    { text: 'Remark', colspan: 3, rowspan: 2 },
  ],
  [
    { text: 'STD.' },
    { text: 'ACTUAL' },
    { text: '1 No Operator' },
    { text: '2 No Material	' },
    { text: '3 Electronics / Electric Fault	' },
    { text: '4 Mould B/d	' },
    { text: '5 No Electricity	' },
    { text: '6 Mechnical Fault	' },
    { text: 'Total B Time' },
  ],
];

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={21}
        rowSpan={3}
        labels={['Doc No', 'Rev. No.', 'Dated']}
        values={['F-25-03', '02', 'Rev. Date :']}
        heading="DAILY PRODUCTION CHART
 (MOULDING)"
      />
      <tr>
        <th colSpan={2}>Date : </th>
        <th colSpan={19} align="left">
          {isView ? (
            formatDate(moduleData.dailyProduction_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.dailyProduction_date)}
              onChange={(date) => {
                moduleData.dailyProduction_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <TableHeaderHtmlCustom tableHeaderObj={headerStandard} />
    </thead>
  );
};

export default THead;
