import PDCSection from '../../pages/ProductionPage/PDCSection';
import PressShop from '../../pages/ProductionPage/PressShop';
import RotorSection from '../../pages/ProductionPage/RotorSection';
import { Profile } from '../../pages/profile';
import {
  InspectionReportNewRouteList, InspectionReportNewCreate
} from "../../pages/RIDeparment";

interface routes {
  path: string;
  element: JSX.Element;
  errorElement?: string;
}

export const other: routes[] = [
  {
    path: 'profile',
    element: <Profile />,
  },
  {
    path: 'inspectionReportNewRouteList',
    element: <InspectionReportNewRouteList />,
  },
  {
    path: 'inspectionReportNewCreate/:partId/:pdirId?',
    element: <InspectionReportNewCreate />,
  },
];
