import moment from 'moment';
import lodash from 'lodash';

export const process = {
  machine_id: null,
  operator_id: null,
  produce_qty: null, // qty produced
  ok_qty: null,
  reject_qty: 0,
  supervisor_sign: '',
  remarks: '',
  reasons_for_rej: Array.from({ length: 10 }, () =>
    lodash.cloneDeep({ reason: null, rej_qty: null })
  ),
};

export const processes = [
  {
    dept: 'Moulding ',
    ...lodash.cloneDeep(process),
  },
  {
    dept: 'Defleshing',
    ...lodash.cloneDeep(process),
  },
  {
    dept: 'Finishing / Buffing ',
    ...lodash.cloneDeep(process),
  },
  {
    dept: 'Final Inspection',
    ...lodash.cloneDeep(process),
  },
  {
    dept: 'Electroplating',
    ...lodash.cloneDeep(process),
  },
  {
    dept: 'Post Curing',
    ...lodash.cloneDeep(process),
  },
];

export const initialState = {
  module_date: new Date(),
  part_id: null,
  mix_no: '',
  batch_no: '',

  processes: processes,
};
