export const listConstant = {
  childHeader: 'Layout Plan list',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'Layout Plan list',
  theadHeader: ' Layout Plan',
  extracolSpan: 2,
};

export const apiConstant = {
  // list api url
  gettableListDataurl: 'layoutPlanRoute/monthlylist',
  deleteListurl: 'layoutPlanRoute',
  getListPlanByid: 'layoutPlanRoute',
  createListPlanByid: 'layoutPlanRoute',

  // -----plan--
  sync: 'layoutPlanRoute/handleSync',
  getAllPlanById: 'layoutPlanRoute/allplansbyid',
  upadateCreateAllPlan: 'layoutPlanRoute/allplansCreateUpdate',
  maintenancePlanDeleteBulkById:
    'layoutPlanRoute/maintenancePlanDeleteBulkById',
};

export const settingPanelConstant = {
  // settingPanel setting for plan
  crudApi: 'layoutPlanSettingRoute',
  docType: 'layoutPlan',
};

export const tilesConstant = {
  childHeaderText: 'Layout Plan',
};

export const navigationConstant = {
  planRouter: '/common/layoutPlanCreate',
};
