import { useRef, useState } from 'react';
import CustomSelect from '../../../../components/common/SelectField';
import { Grid, MenuItem, Tooltip } from '@mui/material';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import moment from 'moment';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../components/common/formatDate';

interface IProps {
  moduleData: any;
  customerData: any;
  isView: boolean;
  handelDeleteRows: any;
  setIsOpenPopup: any;
  setPartIdx: any;
  setIsOpenPopupRejections: any;
  setRejectionsIndx: any;
}

const TBody = ({
  moduleData,
  customerData,
  isView,
  handelDeleteRows,
  setIsOpenPopup,
  setPartIdx,
  setIsOpenPopupRejections,
  setRejectionsIndx,
}: IProps) => {
  const [render, setRender] = useState(false);
  // console.log('moduleData', moduleData, isView);
  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <tr>
              <td>{index + 1}</td>
              <td>
                <TableInput
                  isView={isView}
                  name="process_description"
                  type="text"
                  value={process.process_description}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px', textAlign: 'center' }}>
                <TableInput
                  isView={isView}
                  name="item"
                  type="text"
                  value={process.item}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>{' '}
              <td>
                <TableInput
                  isView={isView}
                  name="specification"
                  type="text"
                  value={process.specification}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              {process?.observation.map((obs: string, obsIdx: number) => (
                <td style={{ minWidth: '100px', textAlign: 'center' }}>
                  <TableInput
                    isView={isView}
                    name={`observation+${obsIdx + 1}`}
                    type="text"
                    value={process.observation[obsIdx]}
                    onChange={(e) => {
                      process.observation[obsIdx] = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
              ))}
              <td style={{ minWidth: '100px', textAlign: 'center' }}>
                {isView ? (
                  process.status
                ) : (
                  <CustomSelect
                    name="status"
                    value={process.status}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  >
                    <MenuItem value="">NONE</MenuItem>
                    <MenuItem value="OK">OK</MenuItem>
                    <MenuItem value="NOT OK">NOT OK</MenuItem>
                  </CustomSelect>
                )}
              </td>
              <td
                style={{ minWidth: '80px', textAlign: 'center' }}
                colSpan={isView ? 2 : 1}
              >
                <TableInput
                  isView={isView}
                  name="remarks"
                  type="text"
                  value={process.remarks}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              {!isView && (
                <td style={{ minWidth: '100px', textAlign: 'center' }}>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
