import { Box } from '@mui/material';
import { RenderTable } from '../../../../components/ui';
import TBody from './TBody';
import THead from './THead';

interface IProps {
  moduleData: any;
  handleDelete: any;
  setModuleData: any;
  isView: boolean;
  defects: any;
  setIsOpenPopup: any;
  setRowIdx: any;
  handleChallan: any;
  handleUnpunch: any;
  vendors: any;
  customers: any;
}

const TController = ({
  moduleData,
  isView,
  handleDelete,
  setModuleData,
  defects,
  setIsOpenPopup,
  setRowIdx,
  handleChallan,
  handleUnpunch,
  vendors,
  customers,
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
            setModuleData,
            defects,
          }}
        />
        <TBody
          {...{
            setIsOpenPopup,
            defects,
            customers,
            vendors,
            moduleData,
            setRowIdx,
            isView,
            handleDelete,
            handleChallan,
            handleUnpunch,
          }}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
