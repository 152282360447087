import moment from 'moment';

export const processes = [
  {
    process_date: null,
    time: null,
    part_id: null,
    abnormal_situation:'',
    produced_qty: null,
    ok_qty: null,
    reject_qty: null,
    disposition:'',
    eng_sign:'',
  },
];