import { TableInput } from '../../../../../components/common';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import { useQuery } from '../../../../../hooks/UseQuery';
import React from 'react';
import TableHeaderHtmlCustom from '../../../../../components/common/customTable/TableHeaderHtmlCustom';
import { HeaderStandard, headerConstant } from '../helpers/constant';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';

interface IProps {
    moduleData: any;
    machineData?: any;
    updateModuleData?: any;
}

const THead = ({ machineData, moduleData, updateModuleData }: IProps) => {
    const isView = useQuery('isView') === 'true' ? true : false;
    const machineName = useQuery('machineName');
    const machineNo = useQuery('machineNo');
    const location = useQuery('location');
    const handleInputChange = (
        fieldName: string,
        value: string | number | null,
    ) => {
        updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
    };

    return (
        <thead>
            <TableCompHead
                colSpan={5}
                rowSpan={3}
                labels={['Doc. No:', 'Rev. No.:', 'Eff. Date:']}
                values={['HO-F-MNT-05', '08', '01-03-2022']}
                heading={`${headerConstant.tableCompHeadTitle}`}
            />
            <tr style={{ height: '50px' }}>
                <th colSpan={1}>CHECKSHEET NAME</th>
                <th colSpan={2}>{moduleData.checkList_name}</th>
                <th colSpan={2}></th>
            </tr>
            <tr>
                <th colSpan={2} align="left">Code No.: {machineNo}</th>
                <th colSpan={1} align="left">Machine Name: {machineName}</th>
                <th colSpan={1} align="left">Location: {location}</th>
                <th colSpan={1} align="left">Date:
                    <DatePickerMui
                        label=""
                        value={(moduleData?.checklist_date && moment(moduleData?.checklist_date)) || null}
                        onChange={(date) =>
                            handleInputChange("checklist_date", date)
                        }

                    />
                </th>
            </tr>
            <TableHeaderHtmlCustom tableHeaderObj={HeaderStandard} />
        </thead>
    );
};

export default React.memo(THead);
