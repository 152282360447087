import mushinlabFullLogo from '../../assets/images/ARC Logo.png';
import mushinlabHalfLogo from '../../assets/images/WHITE.png';
import logo from '../../assets/images/DP Mushin Labs.png';

interface ImagesType {
  mushinlabFullLogo: string;
  mushinlabHalfLogo: string;
  companyImg: string;
  moduleCompanyImgHeader: string;
  companyLogo: string;
}

export const images: ImagesType = {
  mushinlabFullLogo: mushinlabFullLogo,
  mushinlabHalfLogo: mushinlabHalfLogo,
  companyImg: `https://mushin-labs.s3.ap-south-1.amazonaws.com/kamal-rubber-plast/zyro-image.png`,
  moduleCompanyImgHeader: `https://mushin-labs.s3.ap-south-1.amazonaws.com/kamal-rubber-plast/zyro-image.png`,
  companyLogo: logo,
};

export const textInputs: {
  companyName: string;
  companyAcronym: string;
} = {
  companyName: 'KAMAL RUBPLAST INDUSTRIES PRIVATE LIMITED',
  companyAcronym: 'KRIPL',
};
