import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import THead from './THead';
import TFoot from './TFoot';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const TController = ({ moduleData, isView }: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
          }}
        />
        <TFoot  {...{
            moduleData,
            isView,
          }}/>
      </RenderTable>
    </Box>
  );
};

export default TController;
