import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import { CiCircleList } from 'react-icons/ci';
import { LiaListAlt } from 'react-icons/lia';

export type tilesType = {
  sNo: number;
  link: string;
  isLink: boolean;
  isIcon: boolean;
  clauseNo?: string | number;
  p: string;
  span: string;
  icon?: any;
  style?: {};
}[];
const tiles = [
  {
    sNo: 1,
    isLink: true,
    link: '/common/traningtopicslist',
    clauseNo: 5,
    icon: <CiCircleList size="5rem" color="#343a40" />,
    p: 'SET TNI',
    span: 'TOPIC',
    isIcon: true,
  },
  {
    sNo: 2,
    isLink: true,
    link: '/common/tniReportList',
    clauseNo: 5,
    icon: <LiaListAlt size="5rem" color="#343a40" />,
    p: 'TNI',
    span: 'Report',
    isIcon: true,
  },
];

const TniTiles = () => {
  return (
    <>
      <ChildHeader text="TRAINING" />
      <RenderTiles
        tilesObj={tiles}
        height={'100vh'}
        justify="start"
        columns="0"
      />
    </>
  );
};

export default TniTiles;
