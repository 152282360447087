import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // Update import statement
import PartDetailsHeader from '../../components/ui/PartDeatailsHeader';
import ChildHeader from '../../components/ui/ChildHeader';
import { useQuery } from '../../hooks/UseQuery';
import TableCustom from '../../components/common/customTable/TableCustom';
import { AddButton } from '../../components/common/button/CustomButtons';
import axios from 'axios';
import { TableBody, TableCell, TableRow } from '@mui/material';
import {
  DeleteIconButton,
  EditIconButton,
  ViewIconButton,
} from '../../components/common/button/CustomIconButton';
import { useAuth } from '../../services/auth/AuthProvider';
import formatDate from '../../components/common/formatDate';

interface IModule {
  controlplan: string;
  pfd: string;
  fmea: string;
  [type: string | number]: any;
}

const gettingModulFullName: IModule | undefined = {
  pfd: 'LIST OF PROCESS FLOW DIAGRAM',
  controlplan: 'LIST OF CONTROL PLAN',
  fmea: 'LIST OF FMEA',
};

const tableHeaderObj = [
  [
    { text: 'Rev. No.', rowspan: 1, colspan: 1 },
    { text: 'Item Number', rowspan: 1, colspan: 1 },
    { text: 'Revision Date', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const ModuleTableList = () => {
  const { revisedType, partId } = useParams();
  const partName = useQuery('part_name');
  const partNumber = useQuery('part_number');
  const master_format = useQuery('master_format');
  const [data, setData] = useState([]);
  const [render, setRender] = useState(false);
  const { state } = useAuth();
  const navigate = useNavigate();

  console.log(data);
  useEffect(() => {
    axios
      .get(`/api/revision/revisedTable/${revisedType}/${partId}`)
      .then((res) => setData(res.data))
      .catch((err) => console.log(err.message));
  }, [render]);

  function removeDocument(id: string) {
    if (window.confirm('Are you sure you want to delete this Part?')) {
      axios
        .delete(`/api/revision/${revisedType}/${id}`)
        .then(() => {
          alert(`${revisedType} deleted successfully.`);
          setRender((prev) => !prev);
        })
        .catch((err) => console.log(err));
    }
  }
  return (
    <div>
      {revisedType && (
        <ChildHeader text={gettingModulFullName[revisedType]}>
          {!data.length && (
            <AddButton
              label={`Create ${revisedType}`}
              onClick={() =>
                navigate(
                  `/admin/${revisedType}/${partId}?part_name=${partName}&part_number=${partNumber}&master_format=${master_format}`
                )
              }
            />
          )}
        </ChildHeader>
      )}
      <PartDetailsHeader
        partName={partName || ''}
        partNumber={partNumber || ''}
      />
      <div style={{ marginTop: '30px' }}>
        <TableCustom tableHeaderObj={tableHeaderObj}>
          <TableBody>
            {data.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">{item?.revision_no}</TableCell>
                  <TableCell align="center">
                    {item.part_id?.part_number}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(item?.revision_date)}
                  </TableCell>
                  <TableCell align="center">
                    <ViewIconButton
                      tooltipTitle={revisedType || ''}
                      onClick={() =>
                        navigate(
                          `/admin/viewControlplan/${partId}/${item._id}?master_format=${master_format}`
                        )
                      }
                    />
                    {state.user?.userType === 2 && (
                      <EditIconButton
                        tooltipTitle={revisedType || ''}
                        onClick={() =>
                          navigate(
                            `/admin/${revisedType}/${partId}/${item._id}?part_name=${partName}&part_number=${partNumber}&master_format=${master_format}`
                          )
                        }
                      />
                    )}
                    {state.user?.userType === 2 && (
                      <DeleteIconButton
                        tooltipTitle={revisedType || ''}
                        onClick={() => removeDocument(item._id)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </TableCustom>
      </div>
    </div>
  );
};

export default ModuleTableList;
