import React, { useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';

import { AddButton } from '../../components/common/button/CustomButtons';
import ChildHeader from '../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../components/common/FilterBy';
import { SearchBar } from '../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../components/common';
import useDebounce from '../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
} from '../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../components/common/Modal';
import { useQuery } from '../../hooks/UseQuery';
import useConfirm from '../../components/common/useConfirm';
import CustomUploadButton from '../../components/common/button/CustomUploadButton';

const tableHeaderObj = [
  [
    { text: 'S.No.', rowspan: 1, colspan: 1 },
    { text: 'Format Name', rowspan: 1, colspan: 1 },
    { text: 'Format Upload', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [
  { value: 'createdAt', name: 'A-Z' },
  { value: 'customer_name', name: 'Customer Name' },
];

const List = () => {
  const [data, setData] = useState<any[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [customerId, setCustomerId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const search = useQuery('search') || '';
  const filter = useQuery('filter') || 'createdAt';
  const page = useQuery('page') || 1;
  const [isLoading, setIsLoading] = useState(false);

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE CUSTOMER',
    'Are you sure you want to delete this?'
  );

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/masterFormatRoute/?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteCustomer = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/masterFormatRoute/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, search, render, isModal, filter]);

  const uploadImageFile = async (e: any, id: any) => {
    setIsLoading(true);
    const files = e.target.files;
    if (!files) return;
    let formData = new FormData();
    formData.append('file', files[0]);
    axios
      .patch(
        `/api/masterFormatRoute/uploadFile/${id}
      `,
        formData
      )
      .then((res) => {
        if (res.data) {
          alert('document upload successly');
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const downloadExcel = (url: any, fileName: any) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <ChildHeader text="FORMAT LIST">
        <AddButton onClick={() => setIsModal(true)} label="new format" />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search" />
        <FilterBy options={filterOptions} />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item?.format_name}</TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      {item.files?.length == 0 && (
                        <CustomUploadButton
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          id={`${index}_files_master_format`}
                          multiple={true}
                          onChange={(e) => {
                            uploadImageFile(e, item._id);
                          }}
                        />
                      )}
                      {item.files?.length > 0 ? (
                        <>
                          <ViewIconButton
                            tooltipTitle="view"
                            onClick={() =>
                              downloadExcel(
                                item.files[0].url,
                                item.files[0].name
                              )
                            }
                          />
                        </>
                      ) : (
                        <ViewIconButton
                          sx={{
                            opacity: '0',
                            PointerEvent: 'none',
                            visibility: 'hidden',
                          }}
                          tooltipTitle=""
                        />
                      )}
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          setCustomerId(item?._id || '');
                          setIsModal(true);
                        }}
                      />

                      {/* <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          deleteCustomer(item?._id || '');
                        }}
                      /> */}
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setCustomerId(null);
          setIsModal(false);
        }}
        title="CUSTOMER"
      >
        <Create
          id={customerId}
          setIsModal={setIsModal}
          setCustomerId={setCustomerId}
        />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
