export const listConstant = {
  childHeader: 'Calibration List',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'Calibration List',
  theadHeader: 'INSTRUMENT CALIBRATION SCHEDULE',
  extracolSpan: 6,
};

export const checkListList = {
  childHeader: 'Calibration STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'calibrationPlanRoute',
  gettableDataurl: 'calibrationPlanRoute/monthly',
  getPlanByid: 'calibrationPlanRoute',
  createPlanByid: 'calibrationPlanRoute',
  updatePlanByid: 'calibrationPlanRoute',
  sync: 'calibrationPlanRoute/handleSync',
  getAllCheckList: 'calibrationPlanRoute/getAllCheckList',
  checkListStatus: 'calibrationPlanRoute/handleStatus',
  checklistUpload: 'calibrationPlanRoute/handleUpload',
  checklistDeleteImage: 'calibrationPlanRoute/handleDeleteImage',
  checklistData: 'calibrationPlanRoute/checklistRoute',
};

export const tilesConstant = {
  childHeaderText: 'Calibration',
};

export const navigationConstant = {
  createPlanSchedule: '/common/calibrationPlanCreate',
  createChecklist: '/common/calibrationPlanChecklistCreate',
};
