import { Stack } from '@mui/material';
import {
  CustomButton,
  UpdateButton,
} from '../../../components/common/button/CustomButtons';
import { PersitedDatePicker } from '../../../components/common';
import { GoSync } from 'react-icons/go';
const HeaderCreate = ({
  handleUpdate,
  setModuleData,
  moduleData,
  handelSync,
}: any) => {
  return (
    <>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          pl: 2,
          pb: 1,
          pt: 2,
          gap: 2,
        }}
      >
        <Stack
          direction="row"
          sx={{
            justifyContent: 'start',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <PersitedDatePicker
            label="Search By Year"
            views={['year', 'month']}
          />
        </Stack>

        <Stack
          direction="row"
          sx={{
            gap: 2,
          }}
        >
          <CustomButton
            sx={{
              backgroundColor: '#228be6',
              color: '#e7f5ff',
              mr: 2,
            }}
            icon={<GoSync />}
            size="large"
            disabled={!moduleData._id}
            onClick={() => handelSync()}
          >
            Sync Part
          </CustomButton>
          <UpdateButton
            label="update"
            sx={{ mr: '37px', fontSize: '2rem' }}
            size="large"
            onClick={() => {
              handleUpdate();
            }}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default HeaderCreate;
