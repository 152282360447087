const reasons: { [key: string]: any } = {
  man: [
    {
      name: 'यदि आदमी छुट्टी पर हो तो',
      value: 'level1',
    },
    {
      name: 'ऑपरेटर को किसी दूसरी मशीन या लाईन पर लगाना हो तो।',
      value: 'level2',
    },
    {
      name: 'ऑपरेटर यदि एक शिफ्ट (12 घंटे) से अधिक काम करे',
      value: 'level3',
    },
    {
      name: 'ऑपरेटर लम्बी छुट्टियों के बाद दोबारा आए।',
      value: 'level4',
    },
    {
      name: 'कोई नया ऑपरेटर हो बिना किसी अनुभव के।',
      value: 'level5',
    },
    {
      name: 'अनुभव के साथ कोई नया ऑपरेटर हो।',
      value: 'level6',
    },
    {
      name: 'ऑपरेटर का मशीन पर Accident हो जाना',
      value: 'level7',
    },
    {
      name: 'ऑपरेटर का अचानक बिमार हो जाना।',
      value: 'level8',
    },
    {
      name: 'ऑपरेटर का डयुटी के समय से पहले  छुट्रटी करके चले जाना।',
      value: 'level9',
    },
    {
      name: 'ऑपरेटर के WI के अनुसार काम नहीं करने की अवस्था में',
      value: 'level10',
    },
  ],
  machine: [
    {
      name: 'मशीन में ब्रेकडाउन या एक्सीडेंट हो गया हो। या मशीन में चलते चलते टुल,  डाई या इन्सर्ट का टुट जाना। ',
      value: 'level11',
    },
    {
      name: 'मशीन या टुल या फिक्चर या डाई का  प्रिवेंटिव मेंनटिनेन्स होना ।',
      value: 'level12',
    },
    {
      name: 'Preventive Maintenance Delay होने की अवस्था में',
      value: 'level13',
    },
    {
      name: 'अचानक पावर का चला जाना',
      value: 'level14',
    },
    {
      name: 'मशीन में कोई पोको योको लगाया गया हो या बदला गया हो या पोको योको काम करना बंद कर दे तो',
      value: 'level15',
    },
    {
      name: 'Machine में Abnormal noise होने की अवस्था में',
      value: 'level16',
    },
    {
      name: 'मशीन द्वारा बार बार नॉट ओके पीस बनाना या मशीन के पैरामीटर बदल गए हो तो या मशीन का ले आऊट बदला हो।',
      value: 'level17',
    },
  ],
  material: [
    {
      name: 'Raw Material का Supplier  या  Raw Material  का Grade बदल गया हो।',
      value: 'level18',
    },
    {
      name: 'पार्ट के नीचे गिर जाने पर या नीचे गिरे हुए पाने पर',
      value: 'level19',
    },
    {
      name: 'एंटी रस्ट आयल चेंज करने की अवस्था में',
      value: 'level20',
    },
  ],
  method: [
    {
      name: 'इंसपेक्सन का तरीका बदला हो।',
      value: 'level21',
    },
    {
      name: 'गेज के नीचे गिर जाने पर',
      value: 'level22',
    },
    {
      name: 'गेज या इंस्ट्रूमेंट की कैलिब्रेशन डेट खत्म होने की अवस्था में',
      value: 'level23',
    },
    {
      name: 'फिक्चर या डाई में कुछ इंप्रुवमेंट कि गई हो',
      value: 'level24',
    },
    {
      name: 'नया Tool/ Tool Trial',
      value: 'level25',
    },
    {
      name: 'Lux level / Visibility कम होने / कम महसूस होने की अवस्था में',
      value: 'level26',
    },
    {
      name: 'Line change / Machine Change',
      value: 'level27',
    },

    {
      name: 'Line change / Machine Change',
      value: 'level28',
    },
    {
      name: 'Sub process सप्लायर बदल गया हो। या सप्लायर के प्रोसेस में चेंज हो',
      value: 'level29',
    },
  ],
};
const actionTakens: { [key: string]: any } = {
  level1: [
    {
      name: 'आदमी का स्किल लेवल, स्किल मैट्रिक्स, मशीन और प्रोसेस के अनुसार ठीक है तो',
      value: 'action1',
    },
    {
      name: 'आदमी का स्किल लेवल, स्किल मैट्रिक्स, मशीन और प्रोसेस के अनुसार ठीक नही हैं तो',
      value: 'action2',
    },
  ],
  level2: [
    {
      name: 'आदमी का स्किल लेवल, स्किल मैट्रिक्स, मशीन और प्रोसेस के अनुसार ठीक है तो',
      value: 'action1',
    },
    {
      name: 'आदमी का स्किल लेवल, स्किल मैट्रिक्स, मशीन और प्रोसेस के अनुसार ठीक नही हैं तो',
      value: 'action2',
    },
  ],
  level3: [
    {
      name: 'आदमी का स्किल लेवल, स्किल मैट्रिक्स, मशीन और प्रोसेस के अनुसार ठीक है तो',
      value: 'action1',
    },
    {
      name: 'आदमी का स्किल लेवल, स्किल मैट्रिक्स, मशीन और प्रोसेस के अनुसार ठीक नही हैं तो',
      value: 'action2',
    },
  ],
  level4: [
    {
      name: 'आदमी का स्किल लेवल, स्किल मैट्रिक्स, मशीन और प्रोसेस के अनुसार ठीक है तो',
      value: 'action1',
    },
  ],
  level5: [
    {
      name: 'आदमी का स्किल लेवल ,स्किल मैट्रिक्स, मशीन और प्रोसेस के अनुसार ठीक नही हैं तो',
      value: 'action1',
    },
  ],
  level6: [
    {
      name: 'Experience आदमी का स्किल लेवल, स्किल मैट्रिक्स, मशीन और प्रोसेस के अनुसार ठीक हैं तो',
      value: 'action1',
    },
  ],
  level7: [
    {
      name: 'आदमी का स्किल लेवल, स्किल मैट्रिक्स, मशीन और प्रोसेस के अनुसार ठीक हैं तो',
      value: 'action1',
    },
    {
      name: 'आदमी का स्किल लेवल, स्किल मैट्रिक्स, मशीन और प्रोसेस के अनुसार ठीक नही हैं तो',
      value: 'action2',
    },
  ],
  level8: [
    {
      name: 'आदमी का स्किल लेवल, स्किल मैट्रिक्स, मशीन और प्रोसेस के अनुसार ठीक हैं तो',
      value: 'action1',
    },
    {
      name: 'आदमी का स्किल लेवल, स्किल मैट्रिक्स, मशीन और प्रोसेस के अनुसार ठीक नही हैं तो',
      value: 'action2',
    },
  ],
  level9: [
    {
      name: 'आदमी का स्किल लेवल, स्किल मैट्रिक्स, मशीन और प्रोसेस के अनुसार ठीक हैं तो',
      value: 'action1',
    },
    {
      name: 'आदमी का स्किल लेवल, स्किल मैट्रिक्स, मशीन और प्रोसेस के अनुसार ठीक नही हैं तो',
      value: 'action2',
    },
  ],
  level10: [
    {
      name: 'ऑपरेटर WI के अनुसार काम नहीं कर रहा हैं तो ',
      value: 'action1',
    },
  ],
  level11: [
    {
      name: 'मशीन मे ब्रेकडाउन या एक्सीडेंट कि वजह से टुल या फिक्चर या मशीन का अन्य पार्ट का डेमेज नही हैं तो।',
      value: 'action1',
    },
    {
      name: 'मशीन मे ब्रेकडाउन या एक्सीडेंट कि वजह से टुल या फिक्चर या मशीन का अन्य पार्ट का डेमेज  हो तो 4M चेंज रिकॉर्ड करना हैं या नही उसके लिए WI-15-264 प्रयोग करें ',
      value: 'action2',
    },
  ],
  level12: [
    {
      name: 'मशीन का प्रिवेंटिव मेंनटिनेन्स को बाद मशीन कि वास्तविक स्थिति मशीन चैक सीट के अनुसार चैक करें व सभी पॉइंट्स ठीक होने चाहिए। मशीन से असामान्य आवाज नहीं आनी चाहिए।',
      value: 'action1',
    },
  ],
  level13: [
    {
      name: 'मशीन का Preventive Maintenance Plan के अनुसार होना चाहिए।',
      value: 'action1',
    },
  ],
  level14: [
    {
      name: 'Power cutWelding पीस को scrap करे। पार्ट  विजऊल व प्रोसेस सीट के अनुसार चैक करें व ठीक होना चाहिए। ',
      value: 'action1',
    },
  ],
  level15: [
    {
      name: 'पोको याको को चैक सीट के अनुसार चैक करें। यह सही तरीके से काम करना चाहिए।',
      value: 'action1',
    },
    {
      name: 'पोको याको को चैक सीट के अनुसार चैक करें। यह सही तरीके से काम नही कर रहा हैं तो',
      value: 'action2',
    },
  ],
  level16: [
    {
      name: 'Machine में Abnormal noise चेक करे ',
      value: 'action1',
    },
  ],
  level17: [
    {
      name: 'पार्ट  विजुअल व control plan /  OS के अनुसार चैक करें व ठीक होना चाहिए।',
      value: 'action1',
    },
  ],
  level18: [
    {
      name: 'Customer wise Raw Material approved list से चैक करें व यह सही होना चाहिए।',
      value: 'action1',
    },
  ],
  level19: [
    {
      name: 'पार्ट को  लाल बिन में रखे.',
      value: 'action1',
    },
  ],
  level20: [
    {
      name: 'एंटी रस्ट आयल कस्टमर की रिक्वायर्ड के अनुसार होना चाहिए',
      value: 'action1',
    },
  ],
  level21: [
    {
      name: 'control plan /  OS के अनुसार चैक करें व यह control plan /  OS के अनुसार ही होना चाहिए।',
      value: 'action1',
    },
  ],
  level22: [
    {
      name: 'Foreman को सूचित करे',
      value: 'action1',
    },
  ],
  level23: [
    {
      name: 'Foreman को सूचित करे',
      value: 'action1',
    },
  ],
  level24: [
    {
      name: 'मशीन पर पार्ट control plan /  OS के अनुसार बनने चाहिए।',
      value: 'action1',
    },
  ],
  level25: [
    {
      name: 'मशीन पर पार्ट control plan /  OS के अनुसार बनने चाहिए।',
      value: 'action1',
    },
  ],
  level26: [
    {
      name: 'Lux level को चैक सीट के अनुसार चैक करें।यदि Lux level सही नही हो तो',
      value: 'action1',
    },
  ],
  level27: [
    {
      name: 'मशीन पर पार्ट control plan /  OS के अनुसार बनने चाहिए। - 1',
      value: 'action1',
    },
    {
      name: 'मशीन पर पार्ट control plan /  OS के अनुसार बनने चाहिए। - 2',
      value: 'action2',
    },
  ],
  level28: [
    {
      name: 'कस्टमर से पैकिग स्टैंर्ड मान्य होना चाहिए।',
      value: 'action1',
    },
  ],
  level29: [
    {
      name: 'Approved सप्लायर लिस्ट में चैक करें।',
      value: 'action1',
    },
  ],
};

const actionTakenTickMarks: { [key: string]: any } = {
  level1action1: {
    planned: '✓',
    unplanned: '✕',
    checkpoint:
      'दुसरे आदमी को काम पर लगाने से पहले FOREMAN उस आदमी की स्किल लेवल, स्किल मैट्रिक्स से चेक करे',
    checkpoint_status: 'OK',
    change_situation: 'पहले बता कर छुट्टी पर हो yaa बिना बताये छुट्टी पर हो तो',
    abnormal: 'NO',
    procedure:
      'क्वालिटी डिपार्टमेंट के line inspector को सूचित करे और नए आदमी के द्वारा बनाये गये  पार्ट्स (Every Fixture) line inspector से control plan / OS के अनुसार चेक करवाए और अप्रूवल ले।',
    setup: true,
    retro: false,
    suspected: false,
  },
  level1action2: {
    planned: '✕',
    unplanned: '✓',
    change_situation: 'पहले बता कर छुट्टी पर हो yaa बिना बताये छुट्टी पर हो तो',
    checkpoint:
      'दुसरे आदमी को काम पर लगाने से पहले FOREMAN उस आदमी की स्किल लेवल, स्किल मैट्रिक्स से चेक करे',
    checkpoint_status: 'NG',
    abnormal: 'YES',
    procedure:
      'पहले डिपार्टमेंट का  FOREMAN  उसको उस मशीन के अनुसार ऑन जॉब ट्रेनिंग देगा और क्वालिटी डिपार्टमेंट के line inspector को सूचित करे । फिर उस आदमी द्वारा बनाये गये पार्ट्स (Every Fixture) line inspector से control plan / OS  के अनुसार चैक करवाए और अप्रूवल ले । ',
    setup: true,
    retro: false,
    suspected: true,
  },
  level2action1: {
    planned: '✓',
    unplanned: '✕',
    change_situation: 'पहले बता कर छुट्टी पर हो yaa बिना बताये छुट्टी पर हो तो',
    checkpoint:
      'दुसरे आदमी को काम पर लगाने से पहले FOREMAN उस आदमी की स्किल लेवल, स्किल मैट्रिक्स से चेक करे',
    checkpoint_status: 'OK',
    abnormal: 'NO',
    procedure:
      'क्वालिटी डिपार्टमेंट के line inspector को सूचित करे और नए आदमी के द्वारा बनाये गये  पार्ट्स (Every Fixture) line inspector से control plan / OS के अनुसार चेक करवाए और अप्रूवल ले।',
    setup: true,
    retro: false,
    suspected: false,
  },
  level2action2: {
    planned: '✕',
    unplanned: '✓',
    change_situation: 'पहले बता कर छुट्टी पर हो yaa बिना बताये छुट्टी पर हो तो',
    checkpoint:
      'दुसरे आदमी को काम पर लगाने से पहले FOREMAN उस आदमी की स्किल लेवल, स्किल मैट्रिक्स से चेक करे',
    checkpoint_status: 'NG',
    abnormal: 'YES',
    procedure:
      'पहले डिपार्टमेंट का  FOREMAN  उसको उस मशीन के अनुसार ऑन जॉब ट्रेनिंग देगा और क्वालिटी डिपार्टमेंट के line inspector को सूचित करे । फिर उस आदमी द्वारा बनाये गये पार्ट्स (Every Fixture) line inspector से control plan / OS  के अनुसार चैक करवाए और अप्रूवल ले । ',
    setup: true,
    retro: false,
    suspected: true,
  },
  level3action1: {
    planned: '✓',
    unplanned: '✕',
    change_situation: 'पहले से पता होना yaa पहले से पता नही होना',
    checkpoint: 'Work on Same Machine or Work on other Machine',
    checkpoint_status: 'OK',
    abnormal: 'YES',
    procedure:
      'क्वालिटी डिपार्टमेंट के line inspector को सूचित करे और नए आदमी के द्वारा बनाये गये  पार्ट्स (Every Fixture) line inspector से control plan / OS के अनुसार चेक करवाए और अप्रूवल ले।',
    setup: true,
    retro: false,
    suspected: true,
  },
  level3action2: {
    planned: '✕',
    unplanned: '✓',
    change_situation: 'पहले से पता होना yaa पहले से पता नही होना',
    checkpoint: 'Work on Same Machine or Work on other Machine',
    checkpoint_status: 'NG',
    abnormal: 'YES',
    procedure:
      'पहले डिपार्टमेंट का  FOREMAN  उसको उस मशीन के अनुसार ऑन जॉब ट्रेनिंग देगा और क्वालिटी डिपार्टमेंट के line inspector को सूचित करे । फिर उस आदमी द्वारा बनाये गये पार्ट्स (Every Fixture) line inspector से control plan / OS  के अनुसार चैक करवाए और अप्रूवल ले । ',
    setup: true,
    retro: false,
    suspected: true,
  },
  level4action1: {
    planned: '✓',
    unplanned: '✕',
    change_situation:
      'यदि  ऑपरेटर 30 दिन से ज्यादा / लम्बी छुटटीयों के बाद आता है तो',
    checkpoint:
      'ऑपरेटर का उस प्रोसेस या मशीन के लिए मिनिमम स्किल लेवल चैक करें।',
    checkpoint_status: 'OK',
    abnormal: 'NO',
    procedure:
      'पहले डिपार्टमेंट का  FOREMAN  उसको उस मशीन के अनुसार ऑन जॉब ट्रेनिंग देगा और क्वालिटी डिपार्टमेंट के line inspector को सूचित करे । फिर उस आदमी द्वारा बनाये गये पार्ट्स (Every Fixture) line inspector से control plan / OS  के अनुसार चैक करवाए और अप्रूवल ले । ',
    setup: true,
    retro: false,
    suspected: false,
  },
  level5action1: {
    planned: '✓',
    unplanned: '✕',
    change_situation: 'New Man power',
    checkpoint:
      'New man power को ऑन जॉब ट्रेनिंग देनी हैं और उसका मिनिमम स्किल लेवल मशीन चलाने के लेवल पर होना चाहिए',
    checkpoint_status: 'NG',
    abnormal: 'YES',
    procedure:
      'पहले डिपार्टमेंट का  FOREMAN  उसको उस मशीन के अनुसार ऑन जॉब ट्रेनिंग देगा और क्वालिटी डिपार्टमेंट के line inspector को सूचित करे । फिर उस आदमी द्वारा बनाये गये पार्ट्स (Every Fixture) line inspector से control plan / OS  के अनुसार चैक करवाए और अप्रूवल ले । ',
    setup: true,
    retro: false,
    suspected: true,
  },
  level6action1: {
    planned: '✓',
    unplanned: '✕',
    change_situation: 'New experience man power',
    checkpoint:
      'New  अनुभवी man power को ऑन जॉब ट्रेनिंग देनी हैं और उसका मिनिमम स्किल लेवल मशीन चलाने के लेवल पर होना चाहिए',
    checkpoint_status: 'OK',
    abnormal: 'NO',
    procedure:
      'पहले डिपार्टमेंट का  FOREMAN  उसको उस मशीन के अनुसार ऑन जॉब ट्रेनिंग देगा और क्वालिटी डिपार्टमेंट के line inspector को सूचित करे । फिर उस आदमी द्वारा बनाये गये पार्ट्स (Every Fixture) line inspector से control plan / OS  के अनुसार चैक करवाए और अप्रूवल ले । ',
    setup: true,
    retro: false,
    suspected: false,
  },
  level7action1: {
    planned: '✓',
    unplanned: '✕',
    change_situation: 'Operator changed',
    checkpoint:
      'दुसरे ऑपरेटर का उस प्रोसेस या मशीन के लिए मिनिमम स्किल लेवल होना चाहिए',
    checkpoint_status: 'OK',
    abnormal: 'NO',
    procedure:
      'क्वालिटी डिपार्टमेंट के line inspector को सूचित करे और उस आदमी के द्वारा बनाये गये  पार्ट्स (Every Fixture) line inspector से control plan / OS के अनुसार चेक करवाए और अप्रूवल ले। ',
    setup: true,
    retro: false,
    suspected: false,
  },
  level8action1: {
    planned: '✕',
    unplanned: '✓',
    change_situation: 'Operator changed',
    checkpoint:
      'दुसरे ऑपरेटर का उस प्रोसेस या मशीन के लिए मिनिमम स्किल लेवल होना चाहिए',
    checkpoint_status: 'NG',
    abnormal: 'YES',
    procedure:
      'पहले डिपार्टमेंट का  FOREMAN  उसको उस मशीन के अनुसार ऑन जॉब ट्रेनिंग देगा और क्वालिटी डिपार्टमेंट के line inspector को सूचित करे । फिर उस आदमी द्वारा बनाये गये  पार्ट्स (Every Fixture) line inspector से control plan / OS  के अनुसार चैक करवाए और अप्रूवल ले ।आखरी प्रोसेस इंस्पेक्शन के बाद के सभी पार्ट्स सस्पेक्टेड लॉट घोषित करे तथा लॉट को 100 % चेक करे।',
    setup: true,
    retro: false,
    suspected: true,
  },
  level9action1: {
    planned: '✕',
    unplanned: '✓',
    change_situation: 'Operator changed',
    checkpoint:
      'दुसरे ऑपरेटर का उस प्रोसेस या मशीन के लिए मिनिमम स्किल लेवल होना चाहिए',
    checkpoint_status: 'NG',
    abnormal: 'YES',
    procedure:
      'पहले डिपार्टमेंट का  FOREMAN  उसको उस मशीन के अनुसार ऑन जॉब ट्रेनिंग देगा और क्वालिटी डिपार्टमेंट के line inspector को सूचित करे । फिर उस आदमी द्वारा बनाये गये  पार्ट्स (Every Fixture) line inspector से control plan / OS  के अनुसार चैक करवाए और अप्रूवल ले ।आखरी प्रोसेस इंस्पेक्शन के बाद के सभी पार्ट्स सस्पेक्टेड लॉट घोषित करे तथा लॉट को 100 % चेक करे।',
    setup: true,
    retro: false,
    suspected: true,
  },
  level10action1: {
    planned: '✕',
    unplanned: '✓',
    change_situation: 'ऑपरेटर के WI के अनुसार काम नहीं करने की अवस्था में',
    checkpoint: 'चैक करें कि ऑपरेटर WI के अनुसार काम कर रहा हैं या नहीं',
    checkpoint_status: 'NG',
    abnormal: 'YES',
    procedure:
      'पहले डिपार्टमेंट का  FOREMAN  उसको उस मशीन के अनुसार ऑन जॉब ट्रेनिंग देगा और क्वालिटी डिपार्टमेंट के line inspector को सूचित करे । फिर उस आदमी द्वारा बनाये गये  पार्ट्स (Every Fixture) line inspector से control plan / OS  के अनुसार चैक करवाए और अप्रूवल ले ।  तथा पिछले 2 पीस चेक करे अगर एक भी पार्ट गलत मिलता हैं तो आखरी प्रोसेस इंस्पेक्शन के बाद के सभी पार्ट्स सस्पेक्टेड लॉट घोषित करे तथा लॉट को 100 % चेक करे।',
    setup: false,
    retro: true,
    suspected: true,
  },
  level11action1: {
    planned: '✕',
    unplanned: '✓',
    change_situation:
      'टुल या फिक्चर या मशीन का अन्य पार्ट का चेंज हो जाना या नही होना',
    checkpoint:
      'चैक करें कि मशीन मे ब्रेकडाउन या एक्सीडेंट कि वजह से टुल या फिक्चर या मशीन का अन्य पार्ट का डेमेज तो नहीं हो गया है। मशीन चैक शीट के अनुसार चैक करें व मशीन से आसामान्य आवाज नहीं आनी चाहिए',
    checkpoint_status: 'Ok',
    abnormal: 'NO',
    procedure:
      'मशीन ठीक होने के बाद Line Inspector को सूचित करें व मशीन ठीक होने के बाद के पार्ट्स (Every Fixture) line inspector से  control plan / OS  के अनुसार चैक करवाए और अप्रूवल ले । मशीन ब्रेकडाउन या एक्सीडेंट से पहले के 2 पीस चेक करे अगर उन 2 पीस में से एक भी पीस गलत मिलता हैं तो आखरी प्रोसेस इंस्पेक्शन के बाद के सारे लॉट को Suspected लॉट घोषित करे तथा लॉट को 100% चेक करे  4M चेंज रिकॉर्ड करना हैं या नही उसके लिए WI-15-264 प्रयोग करें',
    setup: false,
    retro: false,
    suspected: false,
  },
  level12action1: {
    planned: '✕',
    unplanned: '✓',
    change_situation:
      'टुल या फिक्चर या मशीन का अन्य पार्ट का चेंज हो जाना या नही होना',
    checkpoint:
      'मशीन मे ब्रेकडाउन या एक्सीडेंट कि वजह से टुल या फिक्चर या मशीन का अन्य पार्ट का डेमेज  हो तो 4M चेंज रिकॉर्ड करना हैं या नही उसके लिए WI-15-264 प्रयोग करें ',
    checkpoint_status: 'NG',
    abnormal: 'NO',
    procedure:
      'Emergency Switch को दबायें व टुल फिक्चर व अन्य पार्टस को चेक करें । मशीन ठीक होने के बाद Line Inspector को सूचित करें व मशीन ठीक होने के बाद के पार्ट्स (Every Fixture) line inspector से  control plan / OS  के अनुसार चैक करवाए और अप्रूवल ले ।मशीन ब्रेकडाउन या एक्सीडेंट से पहले के 2 पीस चेक करे अगर उन 2 पीस में   एक भी पीस गलत मिलता हैं तो आखरी प्रोसेस इंस्पेक्शन के बाद के सारे लॉट को Suspected लॉट घोषित करे तथा लॉट को 100% चेक करे  तथा 4M चेंज रिकॉर्ड करना हैं या नही उसके लिए WI-15-264 प्रयोग करें ',
    setup: false,
    retro: false,
    suspected: false,
  },
  level13action1: {
    planned: '✓',
    unplanned: '✕',
    change_situation:
      'टुल या फिक्चर या मशीन का अन्य पार्ट का चेंज हो जाना या नही होना',
    checkpoint:
      'मशीन प्रिवेंटिव मेंनटिनेन्स के बाद मशीन कि Working Condition मशीन चैक सीट के अनुसार होनी चाहिए। मशीन से असामान्य आवाज नहीं आनी चाहिए।',
    checkpoint_status: 'OK',
    abnormal: 'NO',
    procedure:
      'टुल फिक्चर व अन्य पार्टस को चेक करें ।मशीन ठीक होने के बाद Line Inspector को सूचित करें व मशीन ठीक होने के बाद के  पार्ट्स (Every Fixture) line inspector से  control plan / OS  के अनुसार चैक करवाए और अप्रूवल ले । ',
    setup: true,
    retro: false,
    suspected: false,
  },
  level14action1: {
    planned: '✕',
    unplanned: '✓',
    change_situation:
      'टुल या फिक्चर या मशीन का अन्य पार्ट का चेंज हो जाना या नही होना',
    checkpoint:
      'मशीन प्रिवेंटिव मेंनटिनेन्स के बाद मशीन कि Working Condition मशीन चैक सीट के अनुसार होनी चाहिए। मशीन से असामान्य आवाज नहीं आनी चाहिए।',
    checkpoint_status: 'NG',
    abnormal: 'YES',
    procedure:
      'उस मशीन द्वारा बनाए गये 2 पार्ट्स को चेक करे।  यदि  उन 2 पीस में से एक भी पीस गलत मिलता हैं तो पिछले सारे लॉट को सस्पेक्टेड लॉट घोषित करे तथा उस लॉट को 100 % चेक करे।',
    setup: true,
    retro: true,
    suspected: true,
  },
  level15action1: {
    planned: '✕',
    unplanned: '✓',
    change_situation: 'Power cut',
    checkpoint:
      'Welding पीस को scrap करे। मशीन पर बन रहे पीस पर टुल मार्क या पार्ट डेमेज नहीं होना चाहिए। पार्ट प्रोसेस कंट्रोल सीट के अनुसार होना चाहिए।',
    checkpoint_status: 'OK',
    abnormal: 'YES',
    procedure:
      'Welding पीस को scrap करे। Eye Hole and Shaft hole Boring पार्ट को  control plan /  OS  के अनुसार चैक करें  । पार्ट के सही होने पर उसे आगे प्रोसेस के लिए दे तथा  मशीन को रिसेट करे।  मशीन ठीक होने के बाद पार्ट्स (Every Fixture) line inspector से  control plan / OS  के अनुसार चैक करवाए और अप्रूवल ले ',
    setup: true,
    retro: true,
    suspected: true,
  },
  level16action1: {
    planned: '✓',
    unplanned: '✕',
    change_situation: 'Pokayoke changed or new add',
    checkpoint: 'पोको योको चैक सीट के अनुसार चैक करें। यह काम करना चाहिए।',
    checkpoint_status: 'OK',
    abnormal: 'YES',
    procedure:
      'पोका योको चैक सीट के अनुसार चेक करें । अगर काम कर रहा हैं तो मशीन चलाये',
    setup: true,
    retro: false,
    suspected: false,
  },
  level16action2: {
    planned: '✕',
    unplanned: '✓',
    change_situation: 'Pokayoke changed or new add',
    checkpoint: 'पोको योको चैक सीट के अनुसार चैक करें। यह काम करना चाहिए।',
    checkpoint_status: 'NG',
    abnormal: 'YES',
    procedure:
      'Line Inspector को सूचित करें व उस मशीन द्वारा बनाए गये पीस को चेक करे। उस मशीन द्वारा बनायें गये सारे लोट को सस्पेक्टेड लोट डिक्लेअर करे और सारे पीस को चेक करे। जब तक ओके पीस न मिल जाये जो की पोका योका फ़ैल होने से पहले बने हो। जब तक पोका योका ठीक न हो जाये मशीन तब तक नही चलनी चाहिए yaa Alternate method use करे  और Deviation लें तथा extra चेक point लगाए |',
    setup: true,
    retro: true,
    suspected: true,
  },
  level17action1: {
    planned: '✕',
    unplanned: '✓',
    change_situation: 'Machine में Abnormal noise होने की अवस्था में',
    checkpoint: 'Machine में Abnormal noise चेक करे ',
    checkpoint_status: 'NG',
    abnormal: 'YES',
    procedure:
      'Emergency Switch को दबायें व टुल फिक्चर व अन्य पार्टस को चेक करें । Line Supervisor और Maintenance engineer को सूचित करें। उस मशीन द्वारा बनाए गये अंतिम 2 पीस को चेक करे। यदि  उन 2 पीस में से एक भी पीस गलत मिलता हैं तो पिछले सारे लॉट को 100 % चेक करे।मशीन ठीक होने के बाद पार्ट्स  line inspector से  control plan / OS  के अनुसार चैक करवाए और अप्रूवल ले ।',
    setup: true,
    retro: true,
    suspected: true,
  },
  level18action1: {
    planned: '✓',
    unplanned: '✓',
    change_situation: 'Machine malfunction हो या प्रोग्राम पेरामीटर चेंज हो',
    checkpoint:
      'मशीन कि वास्तिविक स्थिति चैक करें। मशीन चैक सीट व प्रोसेस कंट्रोल सीट के अनुसार चैक करें।',
    checkpoint_status: 'OK',
    abnormal: 'YES',
    procedure:
      ' Line Inspector को सूचित करें व उस मशीन  द्वारा बनाए गये  पार्ट्स (Every Fixture) को control plan / OS चेक करे। उस मशीन द्वारा बनायें गये सारे लोट  को सस्पेक्टेड लोट डिक्लेअर करे और सारे पीस को 100% चेक करे।',
    setup: true,
    retro: true,
    suspected: true,
  },
  level19action1: {
    planned: '✓',
    unplanned: '✕',
    change_situation:
      'Raw Material का Supplier  या  Raw Material  का Grade बदल गया हो।',
    checkpoint:
      'MTC में चेक करे मटेरियल as  per specification & as per drawing होना चाहिए',
    checkpoint_status: 'NG',
    abnormal: 'NO',
    procedure: 'Customer को सूचित करे और Approval लें।',
    setup: true,
    retro: false,
    suspected: false,
  },
  level20action1: {
    planned: '✕',
    unplanned: '✕',
    change_situation: 'पार्ट के नीचे गिर जाने पर या नीचे गिरे हुए पाने पर',
    checkpoint: 'पार्ट को  लाल बिन में रखे.',
    checkpoint_status: 'NG',
    abnormal: 'YES',
    procedure:
      'Foreman को सूचित करें। गिरे हुए पार्ट्स को सबसे पहले अपने Line Inspector से चेक कराये तथा ok होने पर ही उन पार्ट्स को आगे बढ़ाएं।',
    setup: false,
    retro: false,
    suspected: false,
  },
  level21action1: {
    planned: '✓',
    unplanned: '✕',
    change_situation: 'एंटी रस्ट आयल चेंज करने की अवस्था में',
    checkpoint: 'Material पर RPO WI के अनुसार होना चाहिए',
    checkpoint_status: 'NG',
    abnormal: 'NO',
    procedure: 'Customer को सूचित करे और Approval लें।',
    setup: false,
    retro: false,
    suspected: false,
  },
  level22action1: {
    planned: '✓',
    unplanned: '✕',
    change_situation: 'इंसपेक्सन का तरीका बदला हो।',
    checkpoint: 'नयी w.i बनाकर इंस्पेक्शन मेथड के बारे में सभी को सूचित करना',
    checkpoint_status: 'NG',
    abnormal: 'NO',
    procedure:
      'Line Inspector को सूचित करें व उस मशीन  द्वारा बनाए Lot  के  पार्ट्स (Every Fixture)   Line Inspector से  control plan / OS  के  अनुसार चैक करवायें और अप्रूवल ले।',
    setup: true,
    retro: false,
    suspected: false,
  },
  level23action1: {
    planned: '✕',
    unplanned: '✓',
    change_situation: 'गेज के नीचे गिर जाने पर',
    checkpoint: 'नयी w.i बनाकर इंस्पेक्शन मेथड के बारे में सभी को सूचित करना',
    checkpoint_status: 'NG',
    abnormal: 'YES',
    procedure:
      'यदि गेज में डेंट मिलता है तो नया इशू कराये तथा पुराने गेज से उस शिफ्ट मै बनाये गए 2 पार्ट्स को नए गेज मै चेक करे. ',
    setup: true,
    retro: true,
    suspected: false,
  },
  level24action1: {
    planned: '✕',
    unplanned: '✓',
    change_situation:
      'गेज या इंस्ट्रूमेंट की कैलिब्रेशन डेट खत्म होने की अवस्था में',
    checkpoint:
      'गेज अथवा इंस्ट्रूमेंट को कैलिब्रेट करवाए या नया गेज अथवा इंस्ट्रूमेंट इशु कराये',
    checkpoint_status: 'NG',
    abnormal: 'YES',
    procedure:
      'गेज या इंस्ट्रूमेंट को कैलिब्रेट करवाए या अन्य गेज अथवा इंस्ट्रूमेंट का प्रयोग करे। पिछले 2 पार्ट्स को कैलिब्रेट किये हुए गेज/ इंस्ट्रूमेंट या नये गेज से चेक करे। यदि  भी पार्ट ख़राब मिलता हैं तो पिछले सारे लॉट को सस्पेक्टेड लॉट घोषित करे तथा उस लॉट को 100 % चेक करे।',
    setup: false,
    retro: true,
    suspected: true,
  },
  level25action1: {
    planned: '✓',
    unplanned: '✕',
    change_situation: 'फिक्चर या डाई में कुछ इंप्रुवमेंट कि गई हो',
    checkpoint:
      'टुल या फिक्चर या डाई में कुछ इंप्रुवमेंट कि गई हो सबसे पहले क्वालिटी डिपार्टमेंट से उसको ok करवाए.',
    checkpoint_status: 'OK',
    abnormal: 'NO',
    procedure:
      ' Line Inspector को सूचित करें व उस Process से बनाए हुए Lot के पार्ट्स (Every Fixture)  line inspector से  control plan / OS  के अनुसार चैक करवाए और अप्रूवल ले । ',
    setup: true,
    retro: false,
    suspected: false,
  },
  level26action1: {
    planned: '✓',
    unplanned: '✕',
    change_situation: 'नया Tool/ Tool Trial',
    checkpoint: 'नया Tool/ Tool Trial का approval होना चाहिए',
    checkpoint_status: 'OK',
    abnormal: 'NO',
    procedure:
      'Line Inspector को सूचित करें व उस Process से बनाए हुए Lot के पार्ट्स (Every Fixture)   line inspector से  control plan / OS  के अनुसार चैक करवाए और अप्रूवल ले । ',
    setup: true,
    retro: false,
    suspected: false,
  },
  level27action1: {
    planned: '✕',
    unplanned: '✓',
    change_situation:
      'Lux level / Visibility कम होने / कम महसूस होने की अवस्था में',
    checkpoint: 'Lux level / Visibility सही होनी  चाहिए',
    checkpoint_status: 'NG',
    abnormal: 'YES',
    procedure:
      'Line Supervisor और Maintenance Engineer को सूचित करें। Lux level चेक करवाए कम मिलने की अवस्था में ठीक कराये। उस मशीन द्वारा बनाए गये अंतिम 2 पीस को visual चेक करे। यदि  उन 2 पीस में से एक भी पीस गलत मिलता हैं तो पिछले सारे लॉट को 100 % चेक करे।',
    setup: false,
    retro: true,
    suspected: true,
  },
  level28action1: {
    planned: '✓',
    unplanned: '✕',
    change_situation: 'Line change / Machine Change',
    checkpoint:
      'Line change / Machine Change (of same type and same spec.) का approval Quality Head   से होना चाहिए',
    checkpoint_status: 'OK',
    abnormal: 'YES',
    procedure:
      ' Line Inspector को सूचित करें व उस Process से बनाए हुए Lot के पार्ट्स (Every Fixture) line inspector से  control plan / OS  के अनुसार चैक करवाए और अप्रूवल ले । ',
    setup: true,
    retro: false,
    suspected: false,
  },
  level28action2: {
    planned: '✕',
    unplanned: '✓',
    change_situation: 'Line change / Machine Change',
    checkpoint:
      'Line change / Machine Change (of same type and same spec.) का approval Quality Head   से होना चाहिए',
    checkpoint_status: 'OK',
    abnormal: 'YES',
    procedure:
      ' Line Inspector को सूचित करें व उस Process से बनाए हुए Lot के पार्ट्स (Every Fixture) line inspector से  control plan / OS  के अनुसार चैक करवाए और अप्रूवल ले । ',
    setup: true,
    retro: false,
    suspected: true,
  },
  level29action1: {
    planned: '✓',
    unplanned: '✕',
    change_situation: 'पैकिग स्टैंर्ड में बदलाव आया हो',
    checkpoint: 'पैकिग स्टैण्डर्ड के अनुसार होना चाहिए',
    checkpoint_status: 'NG',
    abnormal: 'NO',
    procedure: 'Customer को सूचित करे और Approval लें।',
    setup: false,
    retro: false,
    suspected: false,
  },
  level30action1: {
    planned: '✓',
    unplanned: '✕',
    change_situation:
      'Sub process सप्लायर बदल गया हो। या सप्लायर के प्रोसेस में चेंज हो',
    checkpoint: 'सप्लायर कस्टमर से approved  होना चाहिए।',
    checkpoint_status: 'NG',
    abnormal: 'NO',
    procedure: 'Customer को सूचित करे और Approval लें।',
    setup: false,
    retro: false,
    suspected: false,
  },
};

export { reasons, actionTakenTickMarks, actionTakens };
