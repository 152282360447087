import { Box } from '@mui/system';
import axios from 'axios';
import { useState } from 'react';
import { useQuery } from '../../../hooks/UseQuery';
import TController from './create/TController';
import useGetData from './helpers/useGetData';
import { Loader } from '../../../components/common/Loader';
import HeaderCreate from './HeaderCreate';
import { moduleConstant } from './constant';
import useConfirm from '../../../components/common/useConfirm';
import ModalCustom from '../../../components/common/Modal';
import PickRiPart from '../../../components/ui/PickRiPart';
import FromAndToDate from './components/FromAndToDate';

interface MyData {
  moduleData: any;
  vendors: any;
  customers: any;
  isLoading: boolean;
  defects: any;
  setModuleData: any;
  setRenderforUpdate: any;
  setParentRender: React.Dispatch<React.SetStateAction<boolean>>;
  error?: string | null;
}
// Function to filter data based on date range
function filterDataByDateRange(data: any, fromDate: Date, toDate: Date) {
  fromDate = new Date(fromDate);
  fromDate.setHours(0, 0, 0, 0);
  toDate = new Date(toDate);
  toDate.setHours(0, 0, 0, 0);
  return data.filter((item: any) => {
    let dataDate;
    if (item?.red_trolly_rejection_id?.date) {
      dataDate = item?.red_trolly_rejection_id?.date;
    }
    if (item?.lot_rejection_id?.date) {
      dataDate = item?.lot_rejection_id?.date;
    }
    if (item?.daily_line_rejection_id?.date) {
      dataDate = item?.daily_line_rejection_id?.date;
    }
    if (item.date) dataDate = item.date;
    let itemDate = new Date(dataDate);
    itemDate.setHours(0, 0, 0, 0);

    // Check if the item's date is within the specified range
    console.log(itemDate);
    return (
      itemDate >= fromDate && itemDate <= toDate && item.is_punched === false
    );
  });
}
const Create = () => {
  const date = useQuery('date');
  const [isView, setisView] = useState<boolean>(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [DialogUpdate, confirmUpdate] = useConfirm(
    'Save',
    'Are you sure you want to save?',
  );
  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this row?',
  );
  const [DialogPunch, confirmPunch] = useConfirm(
    'SEND TO RC',
    'Are you sure you want to punch this row?',
  );
  const [DialogUnPunch, confirmUnPunch] = useConfirm(
    'REMOVE FROM RC',
    'Are you sure you want to unpunch this row?',
  );

  const [rowIdx, setRowIdx] = useState<number>(0);
  const [isRcPopup, setIsRcPopup] = useState(false);

  const {
    error,
    moduleData,
    customers,
    setModuleData,
    isLoading,
    defects,
    vendors,
    setParentRender,
    setRenderforUpdate,
  }: MyData = useGetData();

  const handleUpdate = (submitType: string) => {
    return new Promise(async (resolve: any, reject: any) => {
      if (!date) {
        alert('date required');
        return;
      }
      if (submitType !== 'challan') {
        const ans = await confirmUpdate();
        if (!ans) return;
      }
      axios
        .post(`${moduleConstant.apiConstant}`, moduleData)
        .then((res) => {
          setRenderforUpdate((prev: any) => !prev);
          resolve();
          alert('successfully updated');
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  };

  const handleDelete = async (id: string, rowIndex: number) => {
    const ans = await confirmDelete();
    if (!ans) return;
    if (!id) {
      const values = [...moduleData];
      values.splice(rowIndex, 1);
      setModuleData(values);
      alert('row deleted successfully');
      return;
    }
    await axios
      .delete(`${moduleConstant.apiConstant}/${id}`)
      .then((res) => {
        if (res.data) {
          const values = [...moduleData];
          values.splice(rowIndex, 1);
          setModuleData(values);
          alert('row deleted successfully');
        }
      })
      .catch((err) => console.log(err));
  };
  const sendToRC = async (from: Date, to: Date) => {
    const ans = await confirmPunch();
    if (!ans) return;
    const filterData = filterDataByDateRange(moduleData, from, to);
    if (!filterData.length) return alert('no record found');
    const compileIds = filterData.map((item: any) => {
      item.is_punched = true;
      return item._id;
    });
    await handleUpdate('challan').catch((err) => console.log(err));
    await axios
      .put(
        `${moduleConstant.apiConstant}/sendToRcByFromAndTo?date=${date}`,
        compileIds,
      )
      .then((res) => {
        if (res.data) {
          alert('send to rc successfully');
          setIsRcPopup(false);
          setParentRender((prev) => !prev);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleChallan = async (id: string, item: any) => {
    const ans = await confirmPunch();
    if (!ans) return;
    item.is_punched = true;
    await handleUpdate('challan').catch((err) => console.log(err));
    await axios
      .patch(`${moduleConstant.apiConstant}/${id}?date=${date}`, {})
      .then((res) => {
        if (res.data) {
          alert('row punched successfully');
          setParentRender((prev) => !prev);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleUnpunch = async (id: string, item: any) => {
    const ans = await confirmUnPunch();
    if (!ans) return;
    item.is_punched = false;
    await handleUpdate('challan').catch((err) => console.log(err));
    await axios
      .delete(`${moduleConstant.apiConstant}/unPunch/${id}?date=${date}`)
      .then((res) => {
        if (res.data) {
          alert('row punched successfully');
          setParentRender((prev) => !prev);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <Box>
      <Loader loader={isLoading} />
      <HeaderCreate
        {...{
          sendToRC,
          handleUpdate,
          moduleData,
          setModuleData,
          setIsRcPopup,
        }}
      />
      <TController
        {...{
          moduleData,
          handleChallan,
          setIsOpenPopup,
          isView,
          customers,
          setRowIdx,
          handleDelete,
          setModuleData,
          setParentRender,
          defects,
          vendors,
          handleUnpunch,
        }}
      />
      <DialogUpdate isSubmitContent={false} />
      <DialogDelete isSubmitContent={false} />
      <DialogUnPunch isSubmitContent={false} />
      <DialogPunch isSubmitContent={false} />
      <ModalCustom
        title="Part List"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <PickRiPart moduleData={moduleData[rowIdx]} />
      </ModalCustom>
      <ModalCustom
        title="SEND TO RC"
        openModal={isRcPopup}
        closeModal={() => {
          setIsRcPopup(false);
        }}
      >
        <FromAndToDate {...{ sendToRC }} />
      </ModalCustom>
    </Box>
  );
};

export default Create;
