import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import ObservationInput from './ObservationInput';
import CustomSelect from '../../../../../components/common/SelectField';
import { Checkbox, MenuItem } from '@mui/material';
import THeadRow from './THeadRow';
import TBodyRow from './TBodyRow';
import TFootRow from './TFootRow';


interface IProps {
  isView: boolean;
  moduleData: any;
  machineData: any;
}
let x:Number;
const tData=[
  {
    inspec_items:'TOTAL LENGTH (in case of drill machine/Pokayoke)',
    method:'Measuring Tape',
    spec:'',
    insp_qty:'',
    ok_qty:'',
    ng_qty:'',
    remarks:'',
  },
  {
    inspec_items:'',
    method:'',
    spec:'',
    insp_qty:'',
    ok_qty:'',
    ng_qty:'',
    remarks:'',
  },
  {
    inspec_items:'CLIP Pitch',
    method:'Guage',
    spec:'',
    insp_qty:'',
    ok_qty:'',
    ng_qty:'',
    remarks:'',
  },
  {
    inspec_items:'',
    method:'',
    spec:'',
    insp_qty:'',
    ok_qty:'',
    ng_qty:'',
    remarks:'',
  },
  {
    inspec_items:'',
    method:'',
    spec:'',
    insp_qty:'',
    ok_qty:'',
    ng_qty:'',
    remarks:'',
  },
];
const TBody = ({ moduleData, isView, machineData}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>

     {
      moduleData?.process?.map((item:any,index:any)=>{
        return(
          <tr>
            <th>{index==0?1:(index==2)?2:''}</th>
            <th>{item?.inspec_items}</th>
            <th>{item?.method}</th>
            <th>
              <TableInput
                isView={isView}
                name="spec"
                type="text"
                value={item?.spec}
                onChange={(e) => {
                  item.spec = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </th>
            <th>
              <TableInput
                isView={isView}
                name="insp_qty"
                type="Number"
                value={item?.insp_qty}
                onChange={(e) => {
                  item.insp_qty = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </th>
            <th>
              <TableInput
                isView={isView}
                name="ok_qty"
                type="Number"
                value={item?.ok_qty}
                onChange={(e) => {
                  item.ok_qty = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </th>
            <th>
              <TableInput
                isView={isView}
                name="ng_qty"
                type="Number"
                value={item?.ng_qty}
                onChange={(e) => {
                  item.ng_qty = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </th>
            <th>
              <TableInput
                isView={isView}
                name="remarks"
                type="text"
                value={item?.remarks}
                onChange={(e) => {
                  item.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </th>
            
          </tr>
        )
      })
     }
    
    <tr>
    
       <th colSpan={2}>SAMPLE QTY.-20 Pcs</th>
       <th></th>
       <th colSpan={2}>JUDGEMENT </th>
       <th colSpan={2}>
         <TableInput
                isView={isView}
                name="judge"
                type="text"
                value={moduleData?.judge}
                onChange={(e) => {
                  moduleData.judge = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
       </th>
       <th></th>
    </tr>
    <tr>
    
       <th colSpan={2}>RESULTS</th>
       <th>
       <TableInput
                isView={isView}
                name="result"
                type="text"
                value={moduleData?.result}
                onChange={(e) => {
                  moduleData.result = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
       </th>
       <th colSpan={1}>ACCEPT  </th>
       <th colSpan={1}>
       <TableInput
                isView={isView}
                name="accept"
                type="text"
                value={moduleData?.accept}
                onChange={(e) => {
                  moduleData.accept = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
          </th>
          <th colSpan={2}>
          PREPARED-PRD Engr
          </th>
       <th colSpan={2}>
         <TableInput
                isView={isView}
                name="prepared_by"
                type="text"
                value={moduleData?.prepared_by}
                onChange={(e) => {
                  moduleData.prepared_by = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
       </th>
    </tr>
    <tr>
    
       <th colSpan={3}></th>
       <th colSpan={1}>REJECT  </th>
       <th colSpan={1}>
       <TableInput
                isView={isView}
                name="reject"
                type="text"
                value={moduleData?.reject}
                onChange={(e) => {
                  moduleData.reject = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
          </th>
          <th colSpan={2}>
          APPROVED -QA Engr   </th>
       <th colSpan={1}>
         <TableInput
                isView={isView}
                name="approved_by"
                type="text"
                value={moduleData?.approved_by}
                onChange={(e) => {
                  moduleData.approved_by = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
       </th>
    </tr>
    </tbody>
   

  );
};

export default TBody;
