import React, { useRef, useState } from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { TableCustom } from '../../../../../components/common';
import formatDate from '../../../../../components/common/formatDate';
import {
  CustomIconButton,
  DeleteIconButton,
  EditIconButton,
  ViewIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import { useNavigate } from 'react-router-dom';
import CustomUploadButton from '../../../../../components/common/button/CustomUploadButton';
import { UpdateButton } from '../../../../../components/common/button/CustomButtons';
import Add from '@mui/icons-material/Add';
import ModalCustom from '../../../../../components/common/Modal';
// import RIPartList from '../components/ui/RIPartList';
import axios from 'axios';
import RIPartList from '../RIPartList';

interface IProps {
  deleteDocument: (id: string) => void;
  uploadImage: (id: string) => void;
  setImages: React.Dispatch<React.SetStateAction<null>>;
  setRender: React.Dispatch<React.SetStateAction<boolean>>;
  tableData: {
    [key: string | number]: any;
  }[];
}

const Header = [
  [
    {
      text: '#',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'DRAWING NO',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'QUALITY PLAN NO',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'DRAWING REVISION NO',
      colspan: 1,
      rowspan: 1,
    },

    {
      text: 'DATE',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'CHOOSE IMAGE',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'CHOOSE PART',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'ACTION',
      colspan: 1,
      rowspan: 1,
    },
  ],
];

const InspectionStandardList = ({
  uploadImage,
  deleteDocument,
  tableData,
  setImages,
  setRender,
}: IProps) => {
  const navigate = useNavigate();
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const inspectionIdRef = useRef(null);
  const [partIds, setPartIds] = useState<string[]>([]);
  const handleUpdatePart = (id: string) => {
    const riIndex = partIds.indexOf(id);
    if (riIndex === -1) {
      partIds.push(id);
    } else {
      partIds.splice(riIndex, 1);
    }
    if (inspectionIdRef && partIds.length) {
      axios
        .patch(
          `/api/inspectionStandardNewRoute/partIds/${inspectionIdRef.current}`,
          partIds
        )
        .then((res) => {
          if (res.data) {
            alert('updated successfull');
            setRender((prev) => !prev);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <React.Fragment>
      <TableCustom tableHeaderObj={Header}>
        <TableBody>
          {tableData.map(
            (document: { [key: string]: any }, documentIndex: number) => (
              <TableRow>
                <TableCell align="center">{documentIndex + 1}</TableCell>

                <TableCell align="center">{document.drawing_no}</TableCell>
                <TableCell align="center">{document.quality_plan_no}</TableCell>
                <TableCell align="center">{document.drawing_rev_no}</TableCell>
                <TableCell align="center">
                  {formatDate(document.moduleData_date)}
                </TableCell>
                <TableCell align="center">
                  <CustomUploadButton
                    lable={'UPLOAD DRAWING'}
                    accept="image/png,image/jpeg,image/jpg"
                    onChange={(e) => setImages(e.target.files)}
                  />
                </TableCell>
                <TableCell align="center">
                  <CustomIconButton
                    tooltipTitle="view"
                    onClick={() => {
                      inspectionIdRef.current = document._id;
                      setPartIds(document.part_ids);
                      setIsOpenPopup(true);
                    }}
                  >
                    <Add />
                  </CustomIconButton>
                </TableCell>
                <TableCell align="center">
                  <ViewIconButton
                    tooltipTitle="view"
                    onClick={() =>
                      navigate(
                        `/common/createInspectionStandardNew/${document._id}?isView=true`
                      )
                    }
                  />
                  <EditIconButton
                    tooltipTitle="edit"
                    onClick={() =>
                      navigate(
                        `/common/createInspectionStandardNew/${document._id}?isView=false`
                      )
                    }
                  />
                  <DeleteIconButton
                    tooltipTitle="delete"
                    onClick={() => deleteDocument(document._id)}
                  />
                  <UpdateButton
                    label="update"
                    onClick={() => uploadImage(document._id)}
                  />
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </TableCustom>
      <ModalCustom
        title=" PART LIST"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <RIPartList type="choose" {...{ handleUpdatePart, partIds }} />
      </ModalCustom>
    </React.Fragment>
  );
};

export default InspectionStandardList;
