import React, { useEffect, useRef, useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { AddButton } from '../../components/common/button/CustomButtons';
import ChildHeader from '../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../components/common/FilterBy';
import { SearchBar } from '../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../components/common';
import useDebounce from '../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
  CardIconButton,
} from '../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../components/common/Modal';
import { useQuery } from '../../hooks/UseQuery';
import useConfirm from '../../components/common/useConfirm';
import { IData } from './Interface';
import { Loader } from '../../components/common/Loader';
import ViewFile from './ViewFile';
import CustomUploadButton from '../../components/common/button/CustomUploadButton';
import { useNavigate } from 'react-router-dom';
import formatDate from '../../components/common/formatDate';
import TableScroll from '../../components/ui/renderTable/TableScroll';

const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'Machine Name', rowspan: 1, colspan: 1 },
    { text: 'Machine (Equipment) No.', rowspan: 1, colspan: 1 },
    { text: 'Maker', rowspan: 1, colspan: 1 },
    { text: 'Date of Installation', rowspan: 1, colspan: 1 },
    { text: 'Service freq.', rowspan: 1, colspan: 1 },
    { text: 'Make / Type of Machine or Fixture', rowspan: 1, colspan: 1 },
    { text: 'Condition S / R / UNSV', rowspan: 1, colspan: 1 },
    { text: 'Pressure Gauge Range', rowspan: 1, colspan: 1 },
    { text: 'Make of Pressure Gauge', rowspan: 1, colspan: 1 },
    { text: 'Pressure Gauge No.', rowspan: 1, colspan: 1 },
    { text: 'Department', rowspan: 1, colspan: 1 },
    { text: 'Remarks', rowspan: 1, colspan: 1 },
    { text: 'Machine Checklist', rowspan: 1, colspan: 1 },
    { text: 'MACHINE HISTORY', rowspan: 1, colspan: 1 },
    // { text: 'Machine History Card', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [{ value: 'createdAt', name: 'Most Recent First' }];

const List = () => {
  const [data, setData] = useState<IData[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [machineId, setMachineId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const search = useQuery('search');
  const filter = useQuery('filter') || 'createdAt';
  const department = useQuery('department');
  const page = useQuery('page') || 1;
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [filterDepartment, setFilterDepartment] = useState([]);
  const idRef = useRef('');
  const navigate = useNavigate();
  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE ',
    'Are you sure you want to delete this?'
  );

  useEffect(() => {
    axios
      .get(`/api/department`)
      .then((res) => {
        if (res.data) {
          const formattedData = res.data.results.map((department: any) => ({
            name: department?.department_name,
            value: department?.department_name,
          }));
          setFilterDepartment(formattedData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/machineRoutes?page=${page}&department=${department}&sortBy=${filter}&search=${search}&perPage=${perPage}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteCustomer = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/machineRoutes/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, search, department, render, isModal]);
  const uploadFile = async (e: any, id: string) => {
    if (!window.confirm('Are you sure you want upload this Files')) return;
    const files = e.target.files;
    if (!files) return;
    let formData = new FormData();
    for (const key of Object.keys(files)) {
      formData.append('files', files[key]);
    }
    setIsLoading(true);
    axios
      .patch(`/api/machineRoutes/uploadFile/${id}`, formData)
      .then((res) => {
        if (res.data) {
          alert('document upload successly');
          setIsLoading(false);
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  return (
    <>
      <ChildHeader text="MACHINE LIST">
        <AddButton onClick={() => setIsModal(true)} label="New Machine" />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search" />
        <Stack direction={'row'} gap={2} flex="1">
          <FilterBy
            sx={{ width: 130 }}
            name="department"
            label="Filter By Department"
            options={filterDepartment}
          />
          <FilterBy sx={{ width: 130 }} options={filterOptions} />
        </Stack>
      </SearchFilterContainer>
      <Loader loader={isLoading} />
      <TableScroll>
        <TableCustom tableHeaderObj={tableHeaderObj}>
          <TableBody>
            {data &&
              data.map((item: IData, index: number) => (
                <React.Fragment key={item._id}>
                  <TableRow>
                    <TableCell align="center">
                      {perPage * (+page - 1) + index + 1}
                    </TableCell>
                    <TableCell align="center">{item?.machine_name}</TableCell>
                    <TableCell align="center">{item?.machine_no}</TableCell>
                    <TableCell align="center">{item?.maker}</TableCell>
                    <TableCell align="center">
                      {formatDate(item?.date_of_install || '')}
                    </TableCell>
                    <TableCell align="center">{item?.service_freq}</TableCell>
                    <TableCell align="center">{item?.make}</TableCell>
                    <TableCell align="center">{item?.condition}</TableCell>
                    <TableCell align="center">
                      {item?.pressure_guage_range}
                    </TableCell>
                    <TableCell align="center">
                      {item?.pressure_guage_make}
                    </TableCell>
                    <TableCell align="center">
                      {item?.pressure_guage_no}
                    </TableCell>
                    <TableCell align="center">
                      {item?.department_id &&
                        typeof item?.department_id !== 'string' &&
                        item?.department_id?.department_name}
                    </TableCell>
                    <TableCell align="center">{item?.remarks}</TableCell>
                    <TableCell>
                      <Stack
                        direction={'row'}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <CustomUploadButton
                          id="upload-vendor-documents"
                          accept="image/png,image/jpeg,image/jpg"
                          multiple={true}
                          onChange={(e) => {
                            uploadFile(e, item?._id || '');
                          }}
                        />
                        {item?.files?.length > 0 && (
                          <>
                            <ViewIconButton
                              tooltipTitle="view"
                              sx={{ marginLeft: '1.5rem' }}
                              onClick={() => {
                                idRef.current = item?._id || '';
                                setIsOpenPopup(true);
                              }}
                            />
                          </>
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" sx={{ justifyContent: 'center' }}>
                        <CardIconButton
                          tooltipTitle="Machine History"
                          onClick={() => {
                            navigate(`/admin/machineHistory/${item._id}`);
                          }}
                        />
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" sx={{ justifyContent: 'center' }}>
                        <EditIconButton
                          tooltipTitle="EDIT"
                          onClick={() => {
                            setMachineId(item?._id || '');
                            setIsModal(true);
                          }}
                        />

                        <DeleteIconButton
                          tooltipTitle="DELETE"
                          onClick={() => {
                            deleteCustomer(item?._id || '');
                          }}
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </TableCustom>
      </TableScroll>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setMachineId(null);
          setIsModal(false);
        }}
        title="MACHINE"
      >
        <Create id={machineId} setIsModal={setIsModal} />
      </ModalCustom>
      <ModalCustom
        title="FILE LIST"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <ViewFile id={idRef?.current} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
