import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import moment from 'moment';
import {
  AddIconButton,
  CustomIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../components/common/button/CustomIconButton';
import { Grid, Stack } from '@mui/material';
import { GiPunch } from 'react-icons/gi';
import { CustomButton } from '../../../../components/common/button/CustomButtons';
import formatDate from '../../../../components/common/formatDate';
import AutocompleteGridmui from '../../../../components/common/autocomplete/AutocompleteGridmui';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';

interface IProps {
  item: any;
  isView: any;
  handleDelete?: any;
  index: number;
  defects?: any;
  setIsOpenPopup: any;
  setRowIdx: any;
  setRowRender?: any;
  handleChallan?: any;
  handleUnpunch?: any;
  vendors?: any;
  customers?: any;
}
const DateTableCell = ({
  item,
  setRowRender,
}: {
  item: any;
  setRowRender: any;
}) => {
  if (
    item?.red_trolly_rejection_id ||
    item?.lot_rejection_id ||
    item?.daily_line_rejection_id
  ) {
    return (
      <td>
        {formatDate(item?.red_trolly_rejection_id?.date) ||
          formatDate(item?.lot_rejection_id?.date) ||
          formatDate(item?.daily_line_rejection_id?.date)}
      </td>
    );
  }
  return (
    <td style={{ textAlign: 'center', minWidth: '180px' }}>
      <DatePickerMui
        label=""
        onChange={(date) => {
          item.date = moment(date || null);
          setRowRender((prev: any) => !prev);
        }}
        value={(item?.date && moment(item?.date)) || null}
      />
    </td>
  );
};

const PartTableCell = ({
  item,
  index,
  isView,
  setIsOpenPopup,
  setRowIdx,
  setRowRender,
}: IProps) => {
  if (
    item?.red_trolly_rejection_id ||
    item?.lot_rejection_id ||
    item?.daily_line_rejection_id
  ) {
    return (
      <td>
        {item?.red_trolly_rejection_id?.ri_part_id?.part_number ||
          item?.lot_rejection_id?.ri_part_id?.part_number ||
          item?.daily_line_rejection_id?.ri_part_id?.part_number}
      </td>
    );
  }
  return (
    <td style={{ textAlign: 'center', minWidth: '200px' }}>
      {isView ? (
        item?.ri_part_id && item?.ri_part_id?.part_number
      ) : (
        <Grid container xs={12} direction="row" alignItems={'center'}>
          <Grid item xs={10}>
            <p
              style={{
                fontSize: '1.2rem',
              }}
            >
              {typeof item?.ri_part_id === 'object' && item?.ri_part_id !== null
                ? item?.ri_part_id?.part_number
                : item?.partNumber || ''}
              {!item?.ri_part_id && 'Pick Part Number'}
            </p>
          </Grid>

          {item.ri_part_id ? (
            <Grid item xs={2}>
              <RemoveIconButton
                tooltipTitle="remove part"
                onClick={() => {
                  item.ri_part_id = null;
                  item.partNumber = null;
                  item.partName = null;
                  setRowRender((prev: any) => !prev);
                }}
              />
            </Grid>
          ) : (
            <Grid item xs={2}>
              <AddIconButton
                tooltipTitle="add part"
                onClick={() => {
                  setIsOpenPopup(true);
                  setRowIdx(index);
                }}
              />
            </Grid>
          )}
        </Grid>
      )}
    </td>
  );
};

const getTotalProblematicQty = (defects: any, item: any) => {
  const totalRejectedQty = defects.reduce((sum: any, defect: any) => {
    if (item?.daily_line_rejection_id?.[defect._id])
      return sum + +item?.daily_line_rejection_id?.[defect._id];
    if (item?.lot_rejection_id?.[defect._id])
      return sum + +item?.lot_rejection_id?.[defect._id];
    if (item?.red_trolly_rejection_id?.[defect._id])
      return sum + +item?.red_trolly_rejection_id?.[defect._id];
    if (item[defect._id]) return sum + +item[defect._id];
    return sum;
  }, 0);
  if (totalRejectedQty === 0) return null;
  return totalRejectedQty;
};

const TBodyRow = ({
  defects,
  item,
  index,
  customers,
  isView,
  handleDelete,
  setIsOpenPopup,
  setRowIdx,
  handleChallan,
  handleUnpunch,
  vendors,
}: IProps) => {
  const [rowRender, setRowRender] = useState(false);

  const handleChange = (e: any) => {
    item[e.target.name] = e.target.value;
  };
  return (
    <>
      <tr>
        <td style={{ textAlign: 'center' }}>{index + 1}</td>
        <td style={{ textAlign: 'center', minWidth: '230px' }}>
          {isView ? (
            `${item?.vendor_id?.supplier_name}/${item?.vendor_id?.address}`
          ) : (
            <AutocompleteMuiCustom
              id="supplier_id"
              label=""
              option_name="address"
              arrayofObj={vendors}
              value={item.vendor_id || ''}
              onChange={(e, value) => {
                item.vendor_id = value;
                setRowRender((prev) => !prev);
              }}
            />
          )}
        </td>
        <DateTableCell {...{ item, setRowRender }} />
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="invoice_no"
            value={item?.invoice_no || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="batch_code"
            value={item?.batch_code || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <PartTableCell
          {...{ item, index, isView, setIsOpenPopup, setRowIdx, setRowRender }}
        />
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="number"
            name="invoice_qty"
            value={item?.invoice_qty || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center', minWidth: '200px' }}>
          {isView ? (
            `${item?.customer_id?.customer_name}`
          ) : (
            <AutocompleteMuiCustom
              id="customer_id"
              label=""
              option_name="customer_name"
              arrayofObj={customers}
              value={item.customer_id || ''}
              onChange={(e, value) => {
                item.customer_id = value;
                setRowRender((prev) => !prev);
              }}
            />
          )}
        </td>
        <td style={{ textAlign: 'center' }}>{item.source}</td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="hold"
            style={{ minWidth: '113px' }}
            value={item?.hold || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="number"
            name="accepted_qty"
            style={{ minWidth: '113px' }}
            value={item?.accepted_qty || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          {getTotalProblematicQty(defects, item)}
        </td>
        {defects.map((defect: any) => (
          <td style={{ textAlign: 'center' }}>
            {item?.red_trolly_rejection_id ||
            item?.lot_rejection_id ||
            item?.daily_line_rejection_id ? (
              item?.daily_line_rejection_id?.[defect._id] ||
              item?.red_trolly_rejection_id?.[defect._id] ||
              item?.lot_rejection_id?.[defect._id]
            ) : (
              <TableInput
                isView={isView}
                type="number"
                name={defect._id}
                disabled={item.is_punched}
                value={item[defect._id] || ''}
                onChange={(e) => {
                  handleChange(e);
                  setRowRender((prev) => !prev);
                }}
              />
            )}
          </td>
        ))}
        {!isView && (
          <td style={{ textAlign: 'center', minWidth: '250px' }}>
            <Stack
              direction={'row'}
              alignItems="center"
              justifyContent={'space-evenly'}
            >
              {item._id &&
                (item.is_punched ? (
                  <CustomButton
                    size="small"
                    sx={{ width: '160px' }}
                    disabled={false}
                    onClick={() => {
                      handleUnpunch(item._id, item);
                    }}
                  >
                    Remove from RC
                  </CustomButton>
                ) : (
                  <CustomButton
                    color="greenButton"
                    size="small"
                    sx={{ width: '160px' }}
                    disabled={false}
                    onClick={() => {
                      handleChallan(item._id, item);
                    }}
                  >
                    Send to RC
                  </CustomButton>
                ))}
              <DeleteIconButton
                tooltipTitle="DELETE"
                disabled={
                  item.red_trolly_rejection_id ||
                  item.lot_rejection_id ||
                  item.daily_line_rejection_id
                }
                style={{
                  color:
                    item.red_trolly_rejection_id ||
                    item.lot_rejection_id ||
                    item.daily_line_rejection_id
                      ? '#495057'
                      : '#c92a2a',
                }}
                onClick={() => {
                  handleDelete(item._id, index);
                }}
              />
            </Stack>
          </td>
        )}
      </tr>
    </>
  );
};

export default TBodyRow;
