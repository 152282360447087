import {
  MushinStanddardCp,
  Format_1,
  Maruti,
  Honda,
} from '../components/view/index';

const customerControlMap = [
  {
    path: 'format1',
    component: Format_1,
  },
  {
    path: 'Maruti',
    component: Maruti,
  },
  {
    path: 'Honda',
    component: Honda,
  },
];

export const getControlPlanAsPerCustomer = (master_format: any) => {
  const formatName = (master_format || '').toLowerCase();

  for (const entry of customerControlMap) {
    const regex = new RegExp(entry.path, 'i');
    if (regex.test(formatName)) {
      const ControlPlanComponent = entry.component;
      return ControlPlanComponent;
    }
  }

  // ----x---- default ----x----
  return MushinStanddardCp;
};
