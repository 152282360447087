export const listConstant = {
  childHeader: 'Calibration plan list',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'Calibration plan list',
  theadHeader: 'Calibration Plan',
  extracolSpan: 5,
};

export const apiConstant = {
  // list api url
  gettableListDataurl: 'calibrationPlanNewRoute/monthlylist',
  deleteListurl: 'calibrationPlanNewRoute',
  getListPlanByid: 'calibrationPlanNewRoute',
  createListPlanByid: 'calibrationPlanNewRoute',

  // -----plan--
  sync: 'calibrationPlanNewRoute/handleSync',
  getAllPlanById: 'calibrationPlanNewRoute/allplansbyid',
  upadateCreateAllPlan: 'calibrationPlanNewRoute/allplansCreateUpdate',
  maintenancePlanDeleteBulkById:
    'calibrationPlanNewRoute/maintenancePlanDeleteBulkById',
  forAutoComplete:
    'masterlistinstrument/instrumentList/instumentForAutoComplete',
};

export const settingPanelConstant = {
  // settingPanel setting for plan
  crudApi: 'calibrationPlanNewSettingRoute',
  docType: 'calibrationPlanNew',
};

export const tilesConstant = {
  childHeaderText: 'Calibration plan',
};

export const navigationConstant = {
  planRouter: '/common/calibrationPlanNewCreate',
};
