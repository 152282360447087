export const checkListList = {
  childHeader: 'Layout PLAN STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  getAllStatus: 'layoutPlanStatusRoute/getAllSatusByYear',
  upadateListStatus: 'layoutPlanStatusRoute/handleStatusUpdate',
  checklistUpload: 'layoutPlanStatusRoute/handleUpload',
  checklistDeleteImage: 'layoutPlanStatusRoute/handleDeleteImage',
  machineDataForAutoComplete: '',
};

export const navigationConstant = {
  checklistRouter: '/common/layoutPlanStatusCheckList',
};
