import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';
import { convToTime } from '../../../../utils/helpers';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import {
  moduleConstant,
  style2,
  tableHeaderObj1,
  tableHeaderObj2,
} from '../../constant';
import { getTotalColsSpan } from '../../../cp/helpers/utils';
import CustomSelect from '../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';

interface IProps {
  moduleData: any;
  isView: boolean;
  tabValue: number;
  machineData?: any;
  mouldData?: any;
}

const THead = ({
  tabValue,
  moduleData,
  isView,
  machineData,
  mouldData,
}: IProps) => {
  const [render, setRender] = useState(false);

  tableHeaderObj1[0][0]['component'] = isView ? (
    moduleData?.time_1 ? (
      convToTime(moduleData?.time_1)
    ) : (
      ''
    )
  ) : (
    <TimePicker
      sx={{ width: '140px', background: '#f8f9fa' }}
      value={moduleData?.time_1 ? moment(moduleData?.time_1) : null}
      onChange={(date) => {
        moduleData.time_1 = date;
        setRender((prev) => !prev);
      }}
    />
  );
  tableHeaderObj1[0][1]['component'] = isView ? (
    moduleData?.time_2 ? (
      convToTime(moduleData?.time_2)
    ) : (
      ''
    )
  ) : (
    <TimePicker
      sx={{ width: '140px', background: '#f8f9fa' }}
      value={moduleData?.time_2 ? moment(moduleData?.time_2) : null}
      onChange={(date) => {
        moduleData.time_2 = date;
        setRender((prev) => !prev);
      }}
    />
  );
  tableHeaderObj1[0][2]['component'] = isView ? (
    moduleData?.time_3 ? (
      convToTime(moduleData?.time_3)
    ) : (
      ''
    )
  ) : (
    <TimePicker
      sx={{ width: '140px', background: '#f8f9fa' }}
      value={moduleData?.time_3 ? moment(moduleData?.time_3) : null}
      onChange={(date) => {
        moduleData.time_3 = date;
        setRender((prev) => !prev);
      }}
    />
  );

  return (
    <thead>
      <TableCompHead
        colSpan={getTotalColsSpan(tableHeaderObj1)}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dated']}
        values={['F-QA-10A', '02', '22.03.2023']}
        heading={moduleConstant.heading}
      />
      <tr>
        <th colSpan={4} align="left">
          MOULD NO.
        </th>
        <th colSpan={4} align="left">
          {isView ? (
            moduleData?.mould_id?.moulding_id
          ) : (
            <AutocompleteMuiCustom
              id="Machine"
              label=""
              option_name="moulding_id"
              arrayofObj={mouldData}
              value={moduleData?.mould_id || ''}
              onChange={(e, value) => {
                moduleData.mould_id = value;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={4} align="left">
          METAL PART NAME
        </th>
        <th colSpan={4} align="left">
          {moduleData.part_id?.part_name}
        </th>
        <th colSpan={4} align="left">
          COMPOUND
        </th>
        <th colSpan={4} align="left">
          <TableInput
            name="compound"
            type="text"
            value={moduleData.compound}
            onChange={(e) => {
              moduleData.compound = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={4} align="left">
          MOULD DETAIL
        </th>
        <th colSpan={4} align="left">
          {moduleData?.mould_id?.moulding_name}
        </th>
        <th colSpan={4} align="left">
          METAL PART NO.
        </th>
        <th colSpan={4} align="left">
          {moduleData.part_id?.part_number}
        </th>
        <th colSpan={4} align="left">
          HARDNESS
        </th>
        <th colSpan={4} align="left">
          <TableInput
            name="hardness"
            type="text"
            value={moduleData.hardness}
            onChange={(e) => {
              moduleData.hardness = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={4} align="left">
          Machine No
        </th>
        <th colSpan={4} align="left">
          {isView ? (
            moduleData?.setups[tabValue]?.machine_id?.machine_no
          ) : (
            <AutocompleteMuiCustom
              id="Machine"
              label=""
              option_name="machine_no"
              option_name_2="machine_name"
              key={tabValue + 'machine'}
              arrayofObj={machineData}
              value={moduleData?.setups[tabValue]?.machine_id || ''}
              onChange={(e, value) => {
                moduleData.setups[tabValue].machine_id = value;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={4} align="left">
          MRR No.
        </th>
        <th colSpan={4} align="left">
          <TableInput
            name="mrr_no"
            type="text"
            value={moduleData.mrr_no}
            onChange={(e) => {
              moduleData.mrr_no = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
         
        </th>
        

        <th colSpan={4} align="left">
          Date
        </th>
        <th colSpan={4} align="left">
          {isView ? (
            formatDate(moduleData.setup_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.setup_date)}
              onChange={(date) => {
                moduleData.setup_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <TableHeaderHtmlCustom tableHeaderObj={tableHeaderObj1} />


      <tr>
        <th colSpan={3} style={style2}></th>
        <th colSpan={1} align="left">
          <TableInput
            name="visual_defects_list_1_a1"
            type="text"
            value={moduleData.visual_defects_list_1_a1}
            onChange={(e) => {
              moduleData.visual_defects_list_1_a1 = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={1} align="left">
          <TableInput
            name="visual_defects_list_1_a2"
            type="text"
            value={moduleData.visual_defects_list_1_a2}
            onChange={(e) => {
              moduleData.visual_defects_list_1_a2 = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        {/*  */}
        <th colSpan={1} align="left">
          <TableInput
            name="visual_defects_list_1_a3"
            type="text"
            value={moduleData.visual_defects_list_1_a3}
            onChange={(e) => {
              moduleData.visual_defects_list_1_a3 = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        {/*  */}
        <th colSpan={3} style={style2}></th>
        <th colSpan={1} align="left">
          <TableInput
            name="visual_defects_list_2_a1"
            type="text"
            value={moduleData.visual_defects_list_2_a1}
            onChange={(e) => {
              moduleData.visual_defects_list_2_a1 = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={1} align="left">
          <TableInput
            name="visual_defects_list_2_a2"
            type="text"
            value={moduleData.visual_defects_list_2_a2}
            onChange={(e) => {
              moduleData.visual_defects_list_2_a2 = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={1} align="left">
          <TableInput
            name="visual_defects_list_2_a3"
            type="text"
            value={moduleData.visual_defects_list_2_a3}
            onChange={(e) => {
              moduleData.visual_defects_list_2_a3 = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>

        <th colSpan={3} style={style2}></th>
        <th colSpan={1} align="left">
          <TableInput
            name="visual_defects_list_3_a1"
            type="text"
            value={moduleData.visual_defects_list_3_a1}
            onChange={(e) => {
              moduleData.visual_defects_list_3_a1 = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={1} align="left">
          <TableInput
            name="visual_defects_list_3_a2"
            type="text"
            value={moduleData.visual_defects_list_3_a2}
            onChange={(e) => {
              moduleData.visual_defects_list_3_a2 = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={2} align="left">
          <TableInput
            name="visual_defects_list_3_a3"
            type="text"
            value={moduleData.visual_defects_list_3_a3}
            onChange={(e) => {
              moduleData.visual_defects_list_3_a3 = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>

    </thead>
  );
};

export default THead;
