import { useState } from 'react';
import { TableInput } from '../../../../components/common';

import { TableCompHead } from '../../../../components/ui';
import formatDate from '../../../../components/common/formatDate';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import moment from 'moment';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={19}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading="Mould History Card"
      />
      <tr>
        <th colSpan={4} align="left">
          PART NO:- {moduleData?.part_id?.part_number}
        </th>
        <th colSpan={4} align="left">
          JOINT :- {moduleData?.moulding_name}
        </th>
        <th colSpan={4} align="left">
          MOULD ID NO :- {moduleData?.moulding_id}
        </th>
        <th colSpan={3} align="left">
          RELEASED BY :-
          <TableInput
            name="released_by"
            type="text"
            value={moduleData?.released_by}
            onChange={(e) => {
              moduleData.released_by = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>

        <th colSpan={4} rowSpan={3} align="left">
          Mould preventing maintanance has to be done at 40000 shots or 3 months
          whichever is earlier. New mould changed after 500000 shots.
        </th>
      </tr>
      <tr>
        <th colSpan={4} align="left">
          PART NAME:- {moduleData?.part_id?.part_name}
        </th>
        <th colSpan={11} align="left">
          MODEL:-
          <TableInput
            name="model"
            type="text"
            value={moduleData?.model}
            onChange={(e) => {
              moduleData.model = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={4} align="left">
          CUSTOMER:- {moduleData?.part_id?.customer_id?.customer_name}
        </th>
        <th colSpan={8} align="left">
          COMMISSIONING/RELEASE DATE :-
          {isView ? (
            formatDate(moduleData?.commissioing_release_date)
          ) : (
            <DatePickerMui
              value={
                moduleData?.commissioing_release_date
                  ? moment(moduleData.commissioing_release_date)
                  : null
              }
              onChange={(date) => {
                moduleData.commissioing_release_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={3} align="left">
          ACCEPTED BY :-
          <TableInput
            name="accepted_by"
            type="text"
            value={moduleData?.accepted_by}
            onChange={(e) => {
              moduleData.accepted_by = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={8} align="left">
          Q.C. :-
          <TableInput
            name="qc"
            type="text"
            value={moduleData.qc}
            onChange={(e) => {
              moduleData.qc = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={7} align="left">
          PROD. :-
          <TableInput
            name="prod"
            type="text"
            value={moduleData.prod}
            onChange={(e) => {
              moduleData.prod = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={4} rowSpan={2} align="left">
          Note : All work done on mold i.e preventive maintenance, break down
          maintenance, health check up ,spare used to be mentioned on History
          card.
        </th>
      </tr>
      <tr>
        <th colSpan={15} align="left">
          ANY OTHER DETAILS :
          <TableInput
            name="any_other_details"
            type="text"
            value={moduleData.any_other_details}
            onChange={(e) => {
              moduleData.any_other_details = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={2}>LOADING</th>
        <th colSpan={6}>UNLOADING</th>
        <th colSpan={2}>PM PLANNING</th>
        <th colSpan={9}>RECTIFICATION/PREVENTIVE MAINTANANCE</th>
      </tr>
      <tr style={{ textTransform: 'uppercase' }}>
        {[
          'DATE/TIME',
          'PROD. INCHARGE SIGN.',
          'DATE/TIME',
          'QUANTITY PRODUCED',
          'COMMULATIVE SHOTS FOR PM',
          'COMMULATIVE SHOTS FOR MOULD CHANGE',
          'REASON OF UNLOADING',
          'PROD. INCHARGE SIGN.',
          'NO.OF SHOTS SINCE LAST PM',
          'NO.OF MONTHS SINCE LAST PM',
          'MOLD RECEIPT DATE ',
          'DEFECT OBSERVED BY TOOL ROOM/WORK DONE/ACTION TAKEN',
          'DATE OF COMPLETION/DATE OF TRIAL',
          'TIME SPENT',
          'SPARE PART USED',
          'T/R INCHARGE SIGN',
          'T/R HEAD SIGN',
          'STATUS',
          'REMARKS',
        ].map((head) => (
          <th
            key={head}
            style={{
              textTransform: 'uppercase',
            }}
          >
            {head}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
