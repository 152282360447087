import moment from 'moment';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { useQuery } from '../../../../../hooks/UseQuery';
import React from 'react';

interface Ifoot {
  preparedBy: string | null | undefined;
  approvedBy: string | null | undefined;
}
const TFoot = ({ moduleData, updateModuleData }: any) => {
  const isView = useQuery('isView') === 'true' ? true : false;
  const handleInputChange = (
    fieldName: string,
    value: string | number | null
  ) => {
    updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
  };

  return (
    <tfoot>
      <tr>
        <th colSpan={5}></th>
        <th colSpan={2} style={{ textAlign: 'left' }}>
          Done By :
          <TableInput
            isView={isView}
            name="done_by_1"
            type="text"
            value={moduleData.done_by_1}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={1} style={{ textAlign: 'left' }}>
          PM Status:
          <TableInput
            isView={isView}
            name="pm_status_1"
            type="text"
            value={moduleData.pm_status_1}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={2} style={{ textAlign: 'left' }}>
          Done By :
          <TableInput
            isView={isView}
            name="done_by_2"
            type="text"
            value={moduleData.done_by_2}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={1} style={{ textAlign: 'left' }}>
          PM Status:
          <TableInput
            isView={isView}
            name="pm_status_2"
            type="text"
            value={moduleData.pm_status_2}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
      </tr>
      {/*  */}
      <tr>
        <th colSpan={5}></th>
        <th colSpan={2} style={{ textAlign: 'left' }}>
          Verified By :
          <TableInput
            isView={isView}
            name="verified_by_1"
            type="text"
            value={moduleData.verified_by_1}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={1} style={{ textAlign: 'left' }}>
          Next Due Date :
          {isView ? (
            formatDate(moduleData.next_due_date_1)
          ) : (
            <DatePickerMui
              label=""
              value={
                moduleData.next_due_date_1
                  ? moment(moduleData.next_due_date_1)
                  : null
              }
              onChange={(date) => {
                handleInputChange('next_due_date_1', date);
              }}
            />
          )}
        </th>
        <th colSpan={2} style={{ textAlign: 'left' }}>
          Verified By :
          <TableInput
            isView={isView}
            name="verified_by_2"
            type="text"
            value={moduleData.verified_by_2}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th colSpan={1} style={{ textAlign: 'left' }}>
          Next Due Date :
          {isView ? (
            formatDate(moduleData.next_due_date_2)
          ) : (
            <DatePickerMui
              label=""
              value={
                moduleData?.next_due_date_2
                  ? moment(moduleData.next_due_date_2)
                  : null
              }
              onChange={(date) => {
                handleInputChange('next_due_date_2', date);
              }}
            />
          )}
        </th>
      </tr>
    </tfoot>
  );
};

export default React.memo(TFoot);
