import { useState } from 'react';
import CustomSelect from '../../../../../components/common/SelectField';
import { Grid, MenuItem, Tooltip } from '@mui/material';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';

import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';

import Operator from '../../../components/Operator';
// import OperationSelect from '../OperationSelect';

import ModalCustom from '../../../../../components/common/Modal';
import OperatorFor4mLogs from './OperatorFor4mLogs';
interface IProps {
  moduleData: any;
  isView: boolean;
  handleSumbit: any;
  machineData: any;
  autoCompleteMachineValue: any;
  handelDeleteRows: any;
  isOpenPopup: any;
  setIsOpenPopup: any;
  setMouldIdx: any;
  setIsOpenPopupRejections: any;
  setRejectionsIndx: any;
}

const TBody = ({
  moduleData,
  isView,
  handleSumbit,
  machineData,
  autoCompleteMachineValue,
  handelDeleteRows,
  isOpenPopup,
  setIsOpenPopup,
  setMouldIdx,
  setIsOpenPopupRejections,
  setRejectionsIndx,
}: IProps) => {
  const [render, setRender] = useState(false);
  const [isOpen4mModel, setIsOpen4mModel] = useState(false);
  const [modalIdx, setModalIdx] = useState<any>(null);

  function calcBTownTotal(process: any) {
    const totalBDown = process?.type_of_b_down?.reduce(
      (total: number, value: any) => {
        if (value !== null) {
          total += +value;
        }
        return total;
      },
      0
    );
    process.total_b_down = totalBDown;
  }



  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <tr key={index + 'module data'}>
              <td style={{ minWidth: '200px' }}>
                {isView
                  ? process?.mc_no?.machine_no
                  : process.date &&
                    process?.mould_id && (
                      <AutocompleteMuiCustom
                        id="Machine"
                        label=""
                        option_name="machine_no"
                        arrayofObj={machineData}
                        value={process?.mc_no || ''}
                        onChange={(e, value) => {
                          if (value === null) value = null;
                          process.mc_no = value;
                          setRender((prev) => !prev);
                        }}
                      />
                    )}
              </td>
              {/* <td style={{ minWidth: '200px' }}>
                <OperationSelect
                  {...{ process, isView, setRender }}
                  partId={
                    typeof process.part_id === 'string'
                      ? process?.part_id
                      : process?.part_id?._id
                  }
                />
              </td> */}
              <td style={{ minWidth: '200px' }}>
                {process.mc_no && process.date && (
                  <Operator
                    onSubmit={handleSumbit}
                    field="operator"
                    machineId={process.mc_no?._id}
                    dataObj={{
                      date: moduleData.dailyProduction_date,
                      part_id: process.mould_id?.part_id,
                      operation_name: process.operation_name,
                    }}
                    {...{ isView, process }}
                  />
                )}
              </td>
              <td style={{ width: '250px', minWidth: '250px' }}>
                {isView ? (
                  process?.mould_id && process?.mould_id?.moulding_id
                ) : (
                  <Grid container xs={12} direction="row" alignItems={'center'}>
                    <Grid item xs={10}>
                      <p
                        style={{
                          fontSize: '1.6rem',
                        }}
                      >
                        {typeof process?.mould_id === 'object' &&
                        process?.mould_id !== null
                          ? process?.mould_id?.moulding_id
                          : process?.mouldingId || ''}
                        {!process?.mould_id && 'Pick Mould Id'}
                      </p>
                    </Grid>

                    {process.mould_id ? (
                      <Grid item xs={2}>
                        <RemoveIconButton
                          tooltipTitle="remove mould"
                          onClick={() => {
                            process.mould_id = null;
                            process.partNumber = null;
                            process.partName = null;
                            process.mouldingId = null;
                            process.mouldingName = null;
                            setRender((prev) => !prev);
                          }}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={2}>
                        <AddIconButton
                          tooltipTitle="add part"
                          onClick={() => {
                            setIsOpenPopup(true);
                            setMouldIdx(index);
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              </td>
              <td style={{ width: '250px', minWidth: '250px' }}>
              {typeof process?.mould_id === 'object' &&
                        process?.mould_id !== null
                          ? process?.mould_id?.moulding_name
                          : process?.mouldingName || ''}
                </td>
                <td style={{ width: '250px', minWidth: '250px' }}>
                {typeof process?.mould_id?.part_id === 'object' &&
                        process?.mould_id?.part_id !== null
                          ? process?.mould_id?.part_id?.part_number
                          : process?.partNumber || ''}
                </td>
                <td style={{ width: '250px', minWidth: '250px' }}>
                {typeof process?.mould_id?.part_id === 'object' &&
                        process?.mould_id?.part_id !== null
                          ? process?.mould_id?.part_id?.part_name
                          : process?.partName || ''}
                </td>
              <td style={{ minWidth: '130px' }}>
                {isView ? (
                  process.shift
                ) : (
                  <CustomSelect
                    name="shift"
                    value={process.shift || ''}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  >
                    <MenuItem value="A">A</MenuItem>
                    <MenuItem value="B">B</MenuItem>
                  </CustomSelect>
                )}
              </td>
              <td style={{ minWidth: '150px' }}>
                <TableInput
                  isView={isView}
                  name="plan_qty"
                  type="text"
                  value={process.plan_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '150px' }}>
                <TableInput
                  isView={isView}
                  name="actual_qty"
                  type="text"
                  value={process.actual_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td
                style={{
                  width: '300px',
                  minWidth: '300px',
                }}
              >
                <div
                  style={{
                    ...(!isView && { display: 'flex' }),
                    ...(!isView && { justifyContent: 'space-between' }),
                  }}
                >
                  <div style={{ border: 'none' }}>
                    {process?.reasons_for_rej?.map((item: any) => {
                      return item?.reason ? (
                        <span>
                          {item?.reason} - {item?.rej_qty}
                          <br />
                          <hr />
                        </span>
                      ) : null;
                    })}
                  </div>
                  {!isView && (
                    <AddIconButton
                      tooltipTitle="add reasons and qty"
                      onClick={() => {
                        setIsOpenPopupRejections(true);
                        setRejectionsIndx(index);
                      }}
                    />
                  )}
                </div>
              </td>
              <td style={{ minWidth: '250px' }}>{process?.rej_qty}</td>
              {[0, 1, 2, 3, 4, 5].map((_, bTownIndex: number) => (
                <td
                  style={{ width: '150px', minWidth: '150px' }}
                  key={'Bdown' + bTownIndex}
                >
                  <TableInput
                    isView={isView}
                    name="type_of_b_down"
                    type="number"
                    value={process.type_of_b_down[bTownIndex]}
                    onChange={(e) => {
                      moduleData.processes[index].type_of_b_down[bTownIndex] =
                        e.target.value;
                      calcBTownTotal(process);
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
              ))}
              <td style={{ width: '150px', minWidth: '150px' }}>
                {process?.total_b_down}
              </td>
              <td style={{ minWidth: '200px' }} colSpan={isView ? 3 : 2}>
                <TableInput
                  isView={isView}
                  name="remarks"
                  type="text"
                  value={process.remarks}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              {/* {!isView && (
                <td style={{ minWidth: '100px' }}>
                  <AddIconButton
                    tooltipTitle={'Add To 4M'}
                    disabled={true}
                    onClick={() => {
                      setModalIdx(index);
                      setIsOpen4mModel(true);
                    }}
                  />
                </td>
              )} */}
              {!isView && (
                <td style={{ minWidth: '100px' }}>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                </td>
              )}
            </tr>
          ))}
      </tbody>
      <ModalCustom
        title="SUMMARY DATA"
        openModal={isOpen4mModel}
        closeModal={() => {
          setIsOpen4mModel(false);
        }}
      >
        <OperatorFor4mLogs
          onSubmit={handleSumbit}
          field="operator"
          machineId={moduleData?.processes?.[modalIdx]?.mc_no?._id}
          dataObj={{
            date: moduleData?.dailyProduction_date,
            part_id: moduleData?.processes?.[modalIdx]?.part_id,
            operation_name: moduleData?.processes?.[modalIdx]?.operation_name,
          }}
          process={moduleData?.processes?.[modalIdx]}
          {...{ isView, isOpen4mModel, setIsOpen4mModel }}
        />
      </ModalCustom>
    </>
  );
};

export default TBody;
