import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import { createConstant } from '../../constant';
import { useQuery } from '../../../../../hooks/UseQuery';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};
interface IProps {
  moduleData: any;
  isView: boolean;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const yearRange = useQuery('yearRange');
  const date = new Date(moduleData?.date_range)
    ?.getFullYear()
    .toString()
    .slice(-2);
  return (
    <thead>
      <TableCompHead
        colSpan={14 + createConstant.extracolSpan}
        rowSpan={3}
        labels={['Doc. No. : ', 'Doc. Rev. No. : ', 'Doc. Rev. Date. : ']}
        values={['FM-QAD- 12', '04', '11/10/2021']}
        heading={`${createConstant.theadHeader} ${yearRange}`}
      />
      {[
        'S. NO.',
        'INSTRUMENTS IDENTIFICATION No.',
        'DESCRIPTION',
        'LOCATION',
        'DATE OF CALIBRATION',
        'NEXT DUE DATE CALIBRATION',
        'STATUS',
      ].map((el, index) => (
        <th
          key={`${index}_head`}
          align="center"
          style={{
            textAlign: 'center',
            ...style1,
            minWidth: `${
              (el === 'CUSTOMER' && '160px') ||
              (el === 'S. NO.' && '50px') ||
              style1.minWidth
            }`,
          }}
        >
          {el}
        </th>
      ))}

      {[
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
        '01',
        '02',
        '03',
      ].map((el, index) => (
        <th
          key={`${index}_headcol`}
          align="center"
          style={{ textAlign: 'center', ...style2 }}
        >
          {el}/{index > 8 ? +date + 1 : date}
        </th>
      ))}
      <th style={{ textAlign: 'center', ...style1 }}>Remarks</th>
    </thead>
  );
};

export default THead;
