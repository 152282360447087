import React, { useEffect, useState } from 'react';
import TableCustom from '../../components/common/customTable/TableCustom';
import {
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import { SearchBar } from '../../components/common/SearchBar';
import FilterBy from '../../components/common/FilterBy';
import { SearchFilterContainer } from '../../assets/styles/layout/searchFilter.styled';
import ChildHeader from '../../components/ui/ChildHeader';
import axios from 'axios';
import AddEditPart, { IPart } from '../addparts/AddEditPart';
import useDebounce from '../../hooks/useDebounce';
import { useAuth } from '../../services/auth/AuthProvider';
import formatDate from '../../components/common/formatDate';
import {
  EditIconButton,
  ViewIconButton,
  DeleteIconButton,
} from '../../components/common/button/CustomIconButton';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import ModalCustom from '../../components/common/Modal';
import { useQuery } from '../../hooks/UseQuery';
import { useNavigate } from 'react-router-dom';
import { CustomPagination } from '../../components/common';
import ConfirmDialogueForInput from '../../components/common/ConfirmDialogueForInput';
import TableCellStatus from './components/TabcellStatus';
import { AIButton } from '../../components/common/button/CustomButtons';
import useConfirm from '../../components/common/useConfirm';
import Aimodal from '../addparts/Aimodal';
import InputMuiCustom from '../../components/common/inputs/InputMuiCustom';
import DatePickerMui from '../../components/common/DatePickerMui';
import moment from 'moment';
import AutocompleteMuiCustom from '../../components/common/AutocompleteMuiCustom';

const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'Item Number', rowspan: 1, colspan: 1 },
    { text: 'Item Description', rowspan: 1, colspan: 1 },
    { text: 'Customer', rowspan: 1, colspan: 1 },
    { text: 'Category', rowspan: 1, colspan: 1 },
    { text: 'Model Name', rowspan: 1, colspan: 1 },
    { text: ' Code', rowspan: 1, colspan: 1 },
    { text: 'Created At', rowspan: 1, colspan: 1 },
    { text: 'ControlPlan Status', rowspan: 1, colspan: 1 },
    { text: 'Document Name', rowspan: 1, colspan: 1 },
    { text: 'Document No.', rowspan: 1, colspan: 1 },
    { text: 'Latest Issue No.', rowspan: 1, colspan: 1 },
    { text: 'Retention Period', rowspan: 1, colspan: 1 },
    { text: 'Issued Date', rowspan: 1, colspan: 1 },
    { text: 'Distribution Status', rowspan: 1, colspan: 1 },
    { text: 'AI Analysis', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const filterCategoryOptions = [
  { value: 'BOP', name: 'BOP' },
  { value: 'MIG WELDING', name: 'MIG WELDING' },
  { value: 'PLATING & POWDER COATING', name: 'PLATING & POWDER COATING' },
  { value: 'PRESS SHOP', name: 'PRESS SHOP' },
  { value: 'WELD SHOP', name: 'WELD SHOP' },
];
const getFilterModelByCustomer = (filterModel: any, customerName: any) => {
  return filterModel?.filter(
    (model: any) => model?.customerName === customerName
  );
};
const ViewAdminPpapDoc = () => {
  const { state } = useAuth();
  const navigate = useNavigate();
  const [data, setData] = useState<IPart[]>([]);
  const [isNewPartModal, setIsNewPartModal] = useState(false);
  const [render, setRender] = useState(false);
  const [dropDownRender, setDropDownRender] = useState(false);
  const [duplicateOpenModal, setDuplicateOpenModal] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [partId, setPartId] = useState<string | null>('');
  const [itemPartNumber, setItemPartNumber] = useState<string | null>(null);
  const [confirmOpenForDelete, setConfirmOpenForDelete] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState<string | null>(null);

  const [filterCustomer, setFilterCustomer] = useState([]);
  const [filterModel, setFilterModel] = useState([]);
  const [DialogConfirmAi, confirmSubmitAi] = useConfirm(
    'AI ✨',
    'Do you want to confirm using AI for analysis?'
  );
  const [aiModal, setAiModal] = useState(false);

  const search = useQuery('search');
  const category = useQuery('category');
  const customer = useQuery('customer');
  const model = useQuery('model');
  const page = useQuery('page') || 1;

  useEffect(() => {
    axios
      .get('/api/customerRoutes/customerList/customerForAutoComplete')
      .then((res) => {
        if (res.data) {
          const formattedData = res.data.map((customer: any) => ({
            name: customer.customer_name,
            value: customer.customer_name,
          }));
          setFilterCustomer(formattedData);
        }
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get('/api/modelsRoutes/modelsList/modelsForAutoComplete')
      .then((res) => {
        if (res.data) {
          const formattedData = res.data.map((model: any) => ({
            name: model.model_name,
            value: model.model_name,
            customerName: model?.customer_id?.customer_name,
          }));
          setFilterModel(formattedData);
        }
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const fetchData = async () => {
    const res: any = await axios
      .get(
        `/api/addPartRoute/viewPpapPart/publish?page=${page}&customer=${customer}&category=${category}&model=${model}&search=${search}&perPage=${10}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res.data?.result);
    setPerPage(res.data?.perPage);
    setTotalPages(res.data?.pages);
  };

  useDebounce(fetchData, 1000, [
    page,
    search,
    render,
    customer,
    category,
    model,
    duplicateOpenModal,
    isNewPartModal,
  ]);
  const deletePart = async (id: string) => {
    await axios.delete(`/api/addPartRoute/${id}`);
    setRender((prev) => !prev);
  };

  const handleDialogWithInput = () => {
    if (!partId) return;
    if (itemPartNumber === textFieldValue) {
      deletePart(partId);
      setPartId(null);
      setTextFieldValue(null);
      setConfirmOpenForDelete(false);
    }
  };

  const statusOptions = [
    { label: 'Status: Red', color: '#FF0000' },
    { label: 'Status: Green', color: '#00FF00' },
  ];

  const [editingRowId, setEditingRowId] = useState<string | null>(null);

  const [itemData, setItemData] = useState({
    document_name: '',
    document_no: '',
    latest_issue_no: '',
    retention_period: '',
    issued_date: Date(),
    distribution_status: '',
  });

  const handlePartData = (e: any) => {
    const { name, value } = e.target;
    setItemData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const updateItem = async (id: any) => {
    const updatedData = {
      ...itemData,
      issued_date: itemData.issued_date,
    };

    console.log('Updated Data:', updatedData);

    try {
      const response = await axios.put(`/api/addPartRoute/${id}`, updatedData);
      alert('SuccesFully Updated');

      setData((prevData) => {
        const updatedItems = prevData.map((item) => {
          if (item._id === id) {
            return {
              ...item,
              ...updatedData,
            };
          }
          return item;
        });
        return updatedItems;
      });

      setEditingRowId(null);
      console.log('Response:', response.data);
    } catch (error) {
      alert('Try Again Later');
      console.error('Error updating item:', error);
    }
  };

  console.log('data', data);

  return (
    <div>
      <ChildHeader text="PART LIST"></ChildHeader>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        sx={{
          justifyItems: 'center',
          alignItems: 'center',
          margin: '15px',
          width: '100%',
          gap: 2,
        }}
      >
        <SearchBar
          label="Search Part"
          sx={
            customer
              ? { width: { xs: '100%', sm: '53%' } }
              : { width: { xs: '100%', sm: '67%' } }
          }
        />
        <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ gap: 2 }}>
          <FilterBy
            sx={{ minWidth: '190px', maxWidth: '190px' }}
            name="customer"
            label="Filter By Customer"
            options={filterCustomer}
            variant={'outlined'}
          />
          {customer && (
            <FilterBy
              sx={{ minWidth: '180px', maxWidth: '180px' }}
              name="model"
              label="Filter By Model"
              options={getFilterModelByCustomer(filterModel, customer)}
              variant={'outlined'}
            />
          )}
          <FilterBy
            sx={{ minWidth: '180px', maxWidth: '180px' }}
            name="category"
            label="Filter By Category"
            options={filterCategoryOptions}
          />
        </Stack>
      </Stack>
      <div style={{ overflowX: 'auto', maxWidth: '100%', marginTop: '20px' }}>
        <TableCustom tableHeaderObj={tableHeaderObj}>
          <TableBody>
            {data &&
              data.map((item: IPart, index: number) => (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell align="center" width={10}>
                      {perPage * (+page - 1) + index + 1}
                    </TableCell>
                    <TableCell align="center" width={30}>
                      {item?.part_number}
                    </TableCell>
                    <TableCell align="center" width={30}>
                      {item?.part_name}
                    </TableCell>
                    <TableCell align="center" width={30}>
                      {item?.customer_id &&
                        typeof item?.customer_id !== 'string' &&
                        item.customer_id.customer_name}
                    </TableCell>
                    <TableCell align="center" width={50}>
                      {item?.supplier_id &&
                        typeof item?.supplier_id !== 'string' &&
                        item.category}
                    </TableCell>
                    <TableCell align="center" width={50}>
                      {item?.model_id?.model_name}
                    </TableCell>
                    <TableCell align="center" width={30}>
                      {item?.alp_code}
                    </TableCell>
                    <TableCell align="center" width={30}>
                      {formatDate(item?.createdAt || '')}
                    </TableCell>
                    <TableCellStatus partId={item._id} />

                    <TableCell align="center">
                      {editingRowId === item._id ? (
                        <InputMuiCustom
                          type="text"
                          name="document_name"
                          placeholder="Document Name"
                          onChange={handlePartData}
                          value={itemData.document_name}
                        />
                      ) : (
                        item?.document_name || ''
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {editingRowId === item._id ? (
                        <InputMuiCustom
                          type="text"
                          name="document_no"
                          placeholder="Document No"
                          onChange={handlePartData}
                          value={itemData.document_no}
                        />
                      ) : (
                        item?.document_no || ''
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {editingRowId === item._id ? (
                        <InputMuiCustom
                          type="text"
                          name="latest_issue_no"
                          placeholder="Latest Issue No"
                          onChange={handlePartData}
                          value={itemData.latest_issue_no}
                        />
                      ) : (
                        item?.latest_issue_no || ''
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {editingRowId === item._id ? (
                        <InputMuiCustom
                          type="text"
                          name="retention_period"
                          placeholder="Retention Period"
                          onChange={handlePartData}
                          value={itemData.retention_period}
                        />
                      ) : (
                        item?.retention_period || ''
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {editingRowId === item._id ? (
                        <DatePickerMui
                          label="Issued Date"
                          onChange={(date) => {
                            setItemData((prevData) => ({
                              ...prevData,
                              issued_date: date,
                            }));
                          }}
                          value={moment(itemData.issued_date) || null}
                        />
                      ) : (
                        formatDate(item?.issuedDate ?? new Date())
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {editingRowId === item._id ? (
                        <AutocompleteMuiCustom
                          id="distribution_status"
                          label="Distribution Status"
                          option_name="label"
                          arrayofObj={statusOptions}
                          value={
                            statusOptions.find(
                              (option) =>
                                option.color === itemData.distribution_status
                            ) || {}
                          }
                          onChange={(e, value) => {
                            setItemData((prevData) => ({
                              ...prevData,
                              distribution_status: value?.color || '',
                            }));
                          }}
                        />
                      ) : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div
                            style={{
                              width: 40,
                              height: 40,
                              marginLeft: 20,
                              backgroundColor:
                                item?.distribution_status || '#FFF',
                              marginRight: 8,
                            }}
                          />
                          {/* {statusOptions.find(
                          (option) => option.color === item?.distribution_status
                        )?.label || ''} */}
                        </div>
                      )}
                    </TableCell>

                    <TableCell align="center">
                      <AIButton
                        label="AI"
                        onClick={async () => {
                          const ans = await confirmSubmitAi();
                          if (!ans) return;
                          setPartId(item?._id!);
                          setAiModal(true);
                        }}
                      />
                    </TableCell>

                    <TableCell>
                      <Stack direction="row" sx={{ justifyContent: 'center' }}>
                        <EditIconButton
                          tooltipTitle="EDIT"
                          onClick={() => {
                            setPartId(item?._id!);
                            setIsNewPartModal(true);
                          }}
                        />

                        <ViewIconButton
                          tooltipTitle="VIEW"
                          onClick={() => {
                            navigate(
                              `/admin/ppapTiles/${item?._id}?part_name=${item.part_name}&part_number=${item.part_number}&master_format=${item.master_format?.format_name}`
                            );
                          }}
                        />
                        <DeleteIconButton
                          tooltipTitle="DELETE"
                          onClick={() => {
                            // deletePart(item?._id!);
                            setItemPartNumber(item?.part_number);
                            setPartId(item?._id!);
                            setConfirmOpenForDelete(true);
                          }}
                        />

                        <Tooltip
                          title={editingRowId === item._id ? 'SAVE' : 'EDIT'}
                        >
                          <IconButton
                            onClick={() => {
                              if (editingRowId === item._id) {
                                updateItem(item._id);
                                setEditingRowId(null);
                              } else if (item._id) {
                                setEditingRowId(item._id);
                                setItemData({
                                  document_name: item.document_name || '',
                                  document_no: item.document_no || '',
                                  latest_issue_no: item.latest_issue_no || '',
                                  retention_period: item.retention_period || '',
                                  issued_date: item.issuedDate
                                    ? moment(item.issuedDate).format(
                                        'YYYY-MM-DD'
                                      )
                                    : '',
                                  distribution_status:
                                    item.distribution_status || '',
                                });
                              }
                            }}
                          >
                            {editingRowId === item._id ? (
                              <SaveIcon />
                            ) : (
                              <EditNoteOutlinedIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </TableCustom>
      </div>
      <CustomPagination totalPage={totalPages} />
      <ConfirmDialogueForInput
        title="DELETE PART"
        open={confirmOpenForDelete}
        setOpen={setConfirmOpenForDelete}
        onConfirm={handleDialogWithInput}
        isSubmitContent={false}
      >
        <p style={{ margin: 0, fontSize: '1.6rem' }}>
          Are you sure you want to delete this part?
        </p>
        <p style={{ margin: 0, fontSize: '1.6rem' }}>
          Enter the <b>Part Number :</b> "{`${itemPartNumber}`}" to confirm part
          deletion:
        </p>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          autoComplete="false"
          label="Item Number"
          type="text"
          fullWidth
          variant="standard"
          onChange={(e) => setTextFieldValue(e.target.value)}
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
        />
      </ConfirmDialogueForInput>
      <ModalCustom
        openModal={isNewPartModal}
        closeModal={() => {
          setPartId(null);
          setIsNewPartModal(false);
        }}
        title="UPDATE PART"
      >
        <AddEditPart
          setIsNewPartModal={setIsNewPartModal}
          id={partId}
          setPartId={setPartId}
        />
      </ModalCustom>
      <ModalCustom
        title={'AI ANALYSIS'}
        dialogTitleCustomBackground={'#f8f9fa'}
        dialogTitleCustomColor={'#343a40'}
        isRequireCloseButton={false}
        openModal={aiModal}
        closeModal={() => {
          setAiModal(false);
        }}
      >
        <Aimodal partId={partId} setAiModal={setAiModal} />
      </ModalCustom>
      <DialogConfirmAi />
    </div>
  );
};

export default ViewAdminPpapDoc;
