import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import moment from 'moment';
import AlertMui from '../../../../components/common/AlertMui';
import { SubmitButton } from '../../../../components/common/button/CustomButtons';
import InputMuiCustom from '../../../../components/common/inputs/InputMuiCustom';
import FormControlMui, {
  StyledField,
} from '../../../../components/common/FormControlMui';
import useConfirm from '../../../../components/common/useConfirm';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import DatePickerMui from '../../../../components/common/DatePickerMui';
interface Iprop {
  setIsNewPartModal: Dispatch<SetStateAction<boolean>>;
  id?: string | object | null;
  setPartId: React.Dispatch<React.SetStateAction<string | null>>;
}

interface Vendor {
  vender_name: string;
  address: string | null;
  contact_person: string | null;
}

export interface IPart {
  _id?: string;
  uom?: string | null;
  type?: string | null;
  part_name?: string | null;
  part_name_2?: string;
  part_number?: string;
  part_number_2?: string;
  compound_detail?: string;
  vendor_id?: Vendor;
  mat_desc?: string;
  iso?: string;
  vendor_id_2?: Vendor;
  vendor_id_3?: Vendor;
  date_orig?: moment.Moment;
  createdAt?: Date;
  exp_date?: moment.Moment | null;
  contact_person?: string;
}

const AddEditPart = ({ setIsNewPartModal, id, setPartId }: Iprop) => {
  const [partData, setPartData] = useState<IPart>({
    date_orig: moment(),
    exp_date: moment(),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [supplierData, setSupplierData] = useState([]);
  const [render, setRender] = useState(false);
  const [alert, setAlert] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'PART',
    `Are you sure you want to ${id ? 'update' : 'save'} this part?`
  );

  const handlePartData = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPartData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    axios
      .get('/api/vendorRoute/vendor/forAutoComplete')
      .then((res) => {
        console.log('res.data is', res?.data);
        setSupplierData(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/riDepartment/riPart/${id}`)
        .then((res) => {
          setPartData(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      setPartData((prev) => prev);
    }
  }, [id]);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const ans = await confirmSubmit();
    if (!ans) return;
    setIsLoading(true);
    if (id) {
      await axios
        .put(`/api/riDepartment/riPart/${id}`, partData)
        .then((res) => {
          setIsLoading(false);
          setIsNewPartModal(false);
          setPartId(null);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      await axios
        .post('/api/riDepartment/riPart', partData)
        .then((res) => {
          setIsLoading(false);
          setIsNewPartModal(false);
          setPartId(null);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <Box>
        <FormControlMui handleSubmit={onSubmit}>
          <div
            className="btn_save"
            style={{
              position: 'absolute',
              right: '140px',
              top: '-0.6px',
            }}
          >
            <SubmitButton
              label={`${id ? 'update' : 'submit'}`}
              type="submit"
              disabled={isLoading}
            />
          </div>
          <StyledField>
            <InputMuiCustom
              type="text"
              name="part_name"
              placeholder="Part Name"
              required={true}
              onChange={handlePartData}
              value={partData?.part_name || ''}
            />
            <InputMuiCustom
              type="text"
              name="part_number"
              placeholder="Part Number"
              required={true}
              onChange={handlePartData}
              value={partData?.part_number || ''}
            />
            <InputMuiCustom
              type="text"
              name="mat_desc"
              placeholder="Material Description"
              onChange={handlePartData}
              value={partData?.mat_desc || ''}
            />
            {/* <InputMuiCustom
              type="text"
              name="part_name_2"
              placeholder="Sub Part Name"
              onChange={handlePartData}
              value={partData?.part_name_2 || ''}
            /> */}
            {/* <InputMuiCustom
              type="text"
              name="part_number_2"
              placeholder="Sub Part Number"
              onChange={handlePartData}
              value={partData?.part_number_2 || ''}
            /> */}
            {/* <InputMuiCustom
              type="text"
              name="part_number_2"
              placeholder="Supplier Name"
              onChange={handlePartData}
              value={partData?.part_number_2 || ''}
            /> */}
            <InputMuiCustom
              type="text"
              name="iso"
              placeholder="CERTIFY ( ISO / IATF )"
              onChange={handlePartData}
              value={partData?.iso || ''}
            />
            <AutocompleteMuiCustom
              id="Vendor"
              label="Supplier Name"
              option_name="vender_name"
              arrayofObj={supplierData}
              value={partData.vendor_id || ''}
              onChange={(e, value) => {
                partData.vendor_id = value;
                setRender((prev) => !prev);
              }}
            />
            <InputMuiCustom
              type="text"
              name="customer_location"
              placeholder="Supplier Location"
              value={partData?.vendor_id?.address || ''}
            />
            {/* <AutocompleteMuiCustom
              id="Vendor2"
              label="Customer 2"
              option_name="vender_name"
              arrayofObj={supplierData}
              value={partData.vendor_id_2 || ''}
              onChange={(e, value) => {
                partData.vendor_id_2 = value;
                setRender((prev) => !prev);
              }}
            /> */}
            {/* <AutocompleteMuiCustom
              id="Vendor3"
              label="Customer 3"
              option_name="vender_name"
              arrayofObj={supplierData}
              value={partData.vendor_id_3 || ''}
              onChange={(e, value) => {
                partData.vendor_id_3 = value;
                setRender((prev) => !prev);
              }}
            /> */}
            {/* <InputMuiCustom
              type="text"
              name="uom"
              placeholder="UOM"
              onChange={handlePartData}
              value={partData?.uom || ''}
            /> */}
            <InputMuiCustom
              type="text"
              name="contact_person"
              placeholder="Contact Person"
              onChange={handlePartData}
              value={partData?.vendor_id?.contact_person || ''}
            />
            {/* <InputMuiCustom
              type="text"
              name="exp_date"
              placeholder="Expiry Date"
              // onChange={handlePartData}
              onChange={(date: any) => {
                partData.exp_date = date;
                setRender((prev) => !prev);
              }}
              value={moment(partData?.exp_date) || null}
              // value={partData?.exp_date ? moment(partData.exp_date).format('YYYY-MM-DD') : ''}
            /> */}
            {/* <InputMuiCustom 
            type=""
            name="exp_date"
              /> */}
            <DatePickerMui
            label="Expiry Date"
              onChange={(date: any) => {
                partData.exp_date = date;
                setRender((prev) => !prev);
              }}
              value={moment(partData?.exp_date) || null}
            />
          </StyledField>
        </FormControlMui>
      </Box>
      <DialogSubmit />
      <AlertMui
        alert={alert}
        message={
          'Please select a customer from the list. The customer field cannot be left empty'
        }
        setAlert={setAlert}
        severity={'warning'}
      />
    </>
  );
};

export default AddEditPart;
