const style1 = {
    backgroundColor: '#4F81BD',
    color: '#fff',
    minWidth: '150px',
};
const style2 = {
    backgroundColor: '#00B050',
    color: '#fff',
};

export const HeaderStandard = [
    [
        { text: 'S.No.', style: style1 },
        { text: 'Check Points', style: style1 },
        { text: 'Checking Method', style: style1 },
        { text: 'Judgement Criteria', style: style1 },
        { text: 'Evaluation Before Cowork', style: style1 },
        { text: 'Evaluation After Cowork', style: style1 },
        { text: 'Done By', style: style1 },
        { text: 'Is M/c History Card updated', style: style1 },
        { text: 'History Card No.', style: style1 },
        { text: 'Verified By Engineer', style: style1 },
        { text: 'Verified Date', style: style1 },
        { text: 'Remark', style: style1 },
        { text: 'Action', style: { ...style1, backgroundColor: '#c92a2a' } },
    ],
];

export const listConstant = {
    childHeader: 'Annual Preventive Checksheet list',
    addButtonLable: 'New Checksheet',
};

export const headerConstant = {
    tableCompHeadTitle: 'Annual Preventive CHECKSHEET ',
};

export const apiConstant = {
    get: 'annualPreventivePlanMasterNewChecklistRoute',
    getById: 'annualPreventivePlanMasterNewChecklistRoute',
    post: 'annualPreventivePlanMasterNewChecklistRoute',
    update: 'annualPreventivePlanMasterNewChecklistRoute',
    delete: 'annualPreventivePlanMasterNewChecklistRoute',
    upload: 'annualPreventivePlanMasterNewChecklistRoute/uploadFile',
    forAutoComplete: 'machineRoutes/machine/ForAutocomplete',
};

export const tilesConstant = {
    childHeaderText: 'Annual Preventive',
};

export const navigationConstant = {
    createEditChecklist: '/common/annualPreventivePlanNewMasterChecklistCreate',
};
