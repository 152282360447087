import moment from 'moment';
import InteractiveIcon from '../../../../../components/common/InteractiveIcon';
import useConfirm from '../../../../../components/common/useConfirm';
import React from 'react';
import HtmlDateCustom from './HtmlDateCustom';
import { useQuery } from '../../../../../hooks/UseQuery';

interface IProps {
  item: any;
  isView: boolean;
  index: number;
  allmonth: any;
  updateModuleData: any;
}

const PlanType = {
  isPlan: 'P',
  isReplanPlan: 'R',
};

const TBodyRightDate = ({
  item,
  isView,
  index,
  allmonth,
  updateModuleData,
}: IProps) => {
  const plan_date = useQuery('plan_date');
  const [DialogisCheck, setDialogisCheck] = useConfirm(
    'Chnage Plan',
    'Are you sure you want to Change Plan?'
  );

  const handleDateClick = async (event: any, month: string) => {
    // const ans = await setDialogisCheck();
    // if (!ans) return;
    const dataString = event.target.value;
    let date: any = new Date(dataString);
    if (isNaN(date.getTime())) date = null;

    updateModuleData(index, month, { p: PlanType.isPlan });
    updateModuleData(index, month, {
      created_date: date,
    });
  };

  return (
    <>
      {allmonth.map((month: string) => (
        <td
          style={{
            padding: '0px',
            margin: '0px',
          }}
        >
          <HtmlDateCustom
            value={
              item[month]?.created_date
                ? moment(item[month]?.created_date).format('YYYY-MM-DD')
                : ''
            }
            style={{
              width: '150px',
              height: '50px',
            }}
            month={month}
            year={plan_date}
            onChange={(e: any) => handleDateClick(e, month)}
          />
        </td>
      ))}
      <DialogisCheck isSubmitContent={false} />
    </>
  );
};

export default React.memo(TBodyRightDate);
