import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import ObservationInput from './ObservationInput';
import CustomSelect from '../../../../../components/common/SelectField';
import { Checkbox, MenuItem } from '@mui/material';
import THeadRow from './THeadRow';
import TBodyRow from './TBodyRow';
import TFootRow from './TFootRow';

const common_header = [
  [{ text: 'DATES', colspan: 6, rowspan: 1 }],
  [{ text: 'TIMES', colspan: 6, rowspan: 1 }],
];
const new_common_header = [
  { text: 'DATE / TIME /OBSERVATION', rowspan: 1, colspan: 6 },
  {
    text: 'CALIBRATION STATUS ( ONCE IN WEEK )',
    rowspan: 3,
    colspan: 1,
  },
];
// fg
interface IProps {
  isView: boolean;
  moduleData: any;
  machineData: any;
}
let x:Number;
const TBody = ({ moduleData, isView, machineData}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
    <THeadRow
      headings={[
        [
          {
            text: moduleData?.processes_one?.heading,
            rowspan: moduleData?.processes_one?.processes.length + 4,
            colspan: 1,
          },
          { text: 'SECTION', rowspan: 3, colspan: 2 },
          { text: 'SPEC. LENGTH', rowspan: 3, colspan: 1 },

          ...new_common_header,
        ],
        ...common_header,
      ]}
      process={moduleData?.processes_one}
      isView={isView}
      x={1}
    />
    {moduleData?.processes_one?.processes?.map((item: any, index: number) => (
      <TBodyRow
        {...{
          item,
          index,
          isView,
        }}
        process_no={1}
      />
    ))}
    <TFootRow process_no={1} />
    {moduleData?.processes_seven?.processes?.map(
      (item: any, index: number) => (
        <TBodyRow
          {...{
            item,
            index,
            isView,
          }}
          process_no={7}
        />
      )
    )}
    <THeadRow
      headings={[
        [
          {
            text: moduleData?.processes_two?.heading,
            rowspan: moduleData?.processes_two?.processes.length + 3,
            colspan: 1,
          },
          { text: 'SECTION', rowspan: 3, colspan: 2 },
          { text: 'SPECIFICATION', rowspan: 3, colspan: 1 },
          ...new_common_header,
        ],
        ...common_header,
      ]}
      process={moduleData?.processes_two}
      isView={isView}
      x={2}
    />
    {moduleData?.processes_two?.processes?.map((item: any, index: number) => (
      <TBodyRow
        {...{
          item,
          index,
          isView,
        }}
        process_no={2}
      />
    ))}
    <THeadRow
      headings={[
        [
          {
            text: moduleData?.processes_three?.heading,
            rowspan: moduleData?.processes_three?.processes.length + 3,
            colspan: 1,
          },
          { text: 'MACHINE', rowspan: 3, colspan: 1 },
          { text: 'PARAMETER', rowspan: 3, colspan: 1 },
          { text: 'SPEC.', rowspan: 3, colspan: 1 },
          ...new_common_header,
        ],
        ...common_header,
      ]}
      process={moduleData?.processes_three}
      isView={isView}
      x={2}

    />
    {moduleData?.processes_three?.processes?.map(
      (item: any, index: number) => (
        <TBodyRow
          {...{
            item,
            index,
            isView,
            machineData,
          }}
          process_no={3}
        />
      )
    )}
    <THeadRow
      headings={[
        [
          {
            text: moduleData?.processes_four?.heading,
            rowspan: moduleData?.processes_four?.processes.length + 4,
            colspan: 1,
          },
          { text: 'TRIMMING SPEC.', rowspan: 3, colspan: 3 },
          ...new_common_header,
        ],
        ...common_header,
      ]}
      process={moduleData?.processes_four}
      isView={isView}
      x={2}

    />
    {moduleData?.processes_four?.processes?.map(
      (item: any, index: number) => (
        <TBodyRow
          {...{
            item,
            index,
            isView,
          }}
          process_no={4}
        />
      )
    )}
    <TFootRow process_no={4} />
    {moduleData?.processes_six?.processes?.map((item: any, index: number) => (
      <TBodyRow
        {...{
          item,
          index,
          isView,
        }}
        process_no={6}
      />
    ))}
    <THeadRow
      headings={[
        [
          {
            text: moduleData?.processes_five?.heading,
            rowspan: moduleData?.processes_five?.processes.length + 4,
            colspan: 1,
          },
          { text: 'FINAL INSPECTION SPEC.', rowspan: 3, colspan: 3 },
          ...new_common_header,
        ],
        ...common_header,
      ]}
      process={moduleData?.processes_five}
      isView={isView}
      x={2}

    />
    {moduleData?.processes_five?.processes?.map(
      (item: any, index: number) => (
        <TBodyRow
          {...{
            item,
            index,
            isView,
          }}
          process_no={5}
        />
      )
    )}
    <TFootRow process_no={5} />
  </tbody>
    // <tbody>
    //   {moduleData.specs.map((spec: any, index: number) => (
    //     <tr key={spec._id}>
    //       <td>{index + 1}</td>
    //       <td style={{ width: '200px', minWidth: '200px' }}>
    //         {spec.parameter}
    //       </td>
    //       <td style={{ width: '200px', minWidth: '200px' }}>
    //         {spec.specification}
    //       </td>
    //       <td style={{ width: '200px', minWidth: '200px' }}>{spec.methods}</td>
    //       {Array.from({ length: 10 }, (_, obsIndex) => (
    //         <td key={obsIndex + 'obs'} style={{ textTransform: 'uppercase' }}>
    //           <ObservationInput
    //             {...{ spec, setRender, isView, obsIndex }}
    //             obsNumber={0}
    //             checkType={spec.appearance}
    //           />
    //         </td>
    //       ))}
    //       <td style={{ textTransform: 'uppercase' }}>
    //         {spec.appearance === 'check3' ? (
    //           <CustomSelect
    //             name="dropdown"
    //             value={spec.status || ''}
    //             onChange={(e) => {
    //               spec.status = e.target.value;
    //               setRender((prev) => !prev);
    //             }}
    //             sx={{
    //               minWidth: '90px',
    //               textAlign: 'center',
    //             }}
    //           >
    //             <MenuItem value="">NONE</MenuItem>
    //             <MenuItem value="ok">OK</MenuItem>
    //             <MenuItem value="ng">NG</MenuItem>
    //           </CustomSelect>
    //         ) : (
    //           spec.status
    //         )}
    //       </td>
    //       <td>
    //         <TableInput
    //           isView={isView}
    //           name="verified_by"
    //           type="text"
    //           style={{
    //             minWidth: '100px',
    //           }}
    //           value={spec?.verified_by}
    //           onChange={(e) => {
    //             spec.verified_by = e.target.value;
    //             setRender((prev) => !prev);
    //           }}
    //         />
    //       </td>
    //       <td style={{ width: '150px', minWidth: '150px' }}>
    //         <TableInput
    //           isView={isView}
    //           name="remarks"
    //           type="text"
    //           value={spec?.remarks}
    //           onChange={(e) => {
    //             spec.remarks = e.target.value;
    //             setRender((prev) => !prev);
    //           }}
    //         />
    //       </td>
    //       <td>
    //         <Checkbox
    //           name="prev_appearance"
    //           checked={spec.appearance === 'check3'}
    //           onChange={() => {
    //             if (spec.prev_appearance) {
    //               spec.appearance = spec.prev_appearance;
    //               spec.prev_appearance = null;
    //             } else {
    //               spec.prev_appearance = spec.appearance;
    //               spec.appearance = 'check3';
    //             }
    //             setRender((prev) => !prev);
    //           }}
    //           style={{ color: '#064a29' }}
    //         />
    //       </td>
    //     </tr>
    //   ))}
    // </tbody>

  );
};

export default TBody;
