import React, { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { IProcess } from '../../../helpers/Interface';
import formatDate from '../../../../../components/common/formatDate';
import '../../common/ui/rowHoverstyle.css';

interface IProps {
  moduleData: any;
}
type revisionObj = {
  rev_no: number;
  summary: string;
  [key: string]: string | number;
};

let isView = false;
const colSpanRevHeader = (head: string) => {
  if (head === 'Action Taken') return 4;
  if (head === 'Detail After Change') return 3;
  if (head === 'Detail Before Change' || head === 'Change Sugg.To') return 2;
  return 1;
};

const TBody = ({ moduleData }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      {moduleData.processes &&
        moduleData.processes.map((process: IProcess, index: number) => (
          <React.Fragment key={index}>
            {process.specs.map((spec, indx) => (
              <React.Fragment key={indx + 'specstable'}>
                <tr className={'addHover'}>
                  {indx === 0 && (
                    <>
                      <td rowSpan={process.specs.length} align="center">
                        {process?.process_flow_no}
                      </td>
                      <td
                        data-img={process?.files[0]?.url || ''}
                        rowSpan={process.specs.length}
                        align="center"
                      >
                        <img
                          src={process?.files[0]?.url || ''}
                          alt={process?.files[0]?.name || ''}
                          height={200}
                          width={200}
                        />
                      </td>
                      <td rowSpan={process.specs.length} align="center">
                        {process.process_no}
                      </td>
                      <td rowSpan={process.specs.length} align="center">
                        {process.process_name}
                      </td>
                    </>
                  )}
                  {spec?.machine_include && (
                    <td
                      align="center"
                      style={{ border: '1px solid black' }}
                      rowSpan={(spec?.machine_rowSpan as number) || 1}
                    >
                      {spec?.machine}
                      {spec?.machine && spec?.tool_no ? '/' : ''}
                      {spec?.tool_no}
                    </td>
                  )}

                  <td align="center" style={{ border: '1px solid black' }}>
                    {indx + 1}
                  </td>

                  {/* product or process */}
                  {spec?.char_product_include && (
                    <td
                      align="center"
                      style={{
                        border: '1px solid black',
                      }}
                      rowSpan={(spec?.char_product_rowSpan as number) || 1}
                    >
                      {spec?.char_product}
                    </td>
                  )}
                  {spec?.char_process_include && (
                    <td
                      align="center"
                      style={{
                        border: '1px solid black',
                      }}
                      rowSpan={(spec?.char_process_rowSpan as number) || 1}
                    >
                      {spec?.char_process}
                    </td>
                  )}
                  <td
                    align="center"
                    style={{
                      border: '1px solid black',
                    }}
                  >
                    {spec?.special_character}
                  </td>

                  {/* PRODUCT SPECIFICATION */}
                  <td align="center" style={{ border: '1px solid black' }}>
                    {spec.control_product}
                  </td>

                  <td
                    style={{
                      border: '1px solid black',
                    }}
                    align="center"
                  >
                    {spec.methods_measure}
                  </td>

                  {spec?.methods_size_include && (
                    <td
                      align="center"
                      style={{
                        border: '1px solid black',
                      }}
                      rowSpan={(spec?.methods_size_rowSpan as number) || 1}
                    >
                      {spec?.methods_size}
                    </td>
                  )}

                  {spec?.methods_by_include && (
                    <td
                      align="center"
                      style={{
                        border: '1px solid black',
                      }}
                      rowSpan={(spec?.methods_by_rowSpan as number) || 1}
                    >
                      {spec?.methods_by}
                    </td>
                  )}

                  {spec?.methods_process_include && (
                    <td
                      align="center"
                      style={{
                        border: '1px solid black',
                      }}
                      rowSpan={(spec?.methods_process_rowSpan as number) || 1}
                    >
                      {spec?.methods_process}
                    </td>
                  )}

                  {spec?.reaction_plan_include && (
                    <td
                      align="center"
                      style={{
                        border: '1px solid black',
                      }}
                      rowSpan={(spec?.reaction_plan_rowSpan as number) || 1}
                    >
                      {spec?.reaction_plan}
                    </td>
                  )}

                  {spec?.corrective_action_include && (
                    <td
                      align="center"
                      style={{
                        border: '1px solid black',
                      }}
                      rowSpan={(spec?.corrective_action_rowSpan as number) || 1}
                    >
                      {spec?.corrective_action}
                    </td>
                  )}

                  {spec?.responsibility_include && (
                    <td
                      align="center"
                      style={{
                        border: '1px solid black',
                      }}
                      rowSpan={(spec?.responsibility_rowSpan as number) || 1}
                    >
                      {spec?.responsibility}
                    </td>
                  )}

                  {spec?.checked_by_include && (
                    <td
                      align="center"
                      style={{
                        border: '1px solid black',
                      }}
                      rowSpan={(spec?.checked_by_rowSpan as number) || 1}
                    >
                      {spec?.checked_by}
                    </td>
                  )}

                  <td
                    align="center"
                    style={{
                      border: '1px solid black',
                    }}
                  >
                    {spec?.remarks}
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      <tr style={{ background: 'yellow' }}>
        {[
          'S.No.',
          'Drg.Rev.No.',
          'Drg.Rev.Date',
          'Detail Before Change',
          'Detail After Change',
          'Action Taken',
          'Change Sugg.To',
          'Change Sugg.By',
          'Status',
        ].map((head, index) => (
          <th
            colSpan={colSpanRevHeader(head)}
            // rowSpan={index === 3 || index === 4 || index === 5 ? 1 : 2}
            key={head + index}
          >
            {head}
          </th>
        ))}
        <th colSpan={3}></th>
      </tr>
      {/* <tr style={{ background: 'yellow' }}>
        <th colSpan={10} style={{ textTransform: 'uppercase' }}>
          Ammendment Details
        </th>
      </tr> */}
      {/* revision map  */}
      {moduleData &&
        moduleData.revisionData.map((revision: revisionObj, index: number) => (
          <tr style={{ background: 'yellow' }}>
            <td>{index}</td>
            <td>{revision.rev_no}</td>
            <td>{formatDate(revision.rev_date)}</td>
            <td colSpan={2}>
              {revision.summary?.split('𝗧𝗢')[0].split('𝗙𝗥𝗢𝗠')[1]}
            </td>
            <td colSpan={3}>{revision.summary?.split('𝗧𝗢')[1]}</td>
            <td colSpan={4}>{revision.action_taken}</td>
            <td colSpan={2}>{revision.sugg_to}</td>
            <td colSpan={1}>{revision.sugg_by}</td>
            <td colSpan={1}>{revision.status}</td>
            <td colSpan={4}></td>
          </tr>
        ))}
    </tbody>
  );
};

export default TBody;
