import { HiDocumentReport } from 'react-icons/hi';
import { CgCalendarToday } from 'react-icons/cg';
import { RiDeleteBin4Fill } from 'react-icons/ri';
import { GiExitDoor } from 'react-icons/gi';
import { RenderTiles } from '../../components/common';
import { GrUser } from 'react-icons/gr';
import { SiInstructure } from 'react-icons/si';
import { FaTruckArrowRight } from 'react-icons/fa6';
import { MdSupportAgent, MdWarehouse } from 'react-icons/md';
import { GiThermometerScale } from 'react-icons/gi';
import { SlNotebook } from 'react-icons/sl';
import AdminHomeRenderTiles from './AdminHomeRenderTiles';

// don't change id sequence in adminTilesObj

const adminTilesObj = [
  // {
  //   id: 1,
  //   link: '/admin/clause1',
  //   isLink: true,
  //   clauseNo: 1,
  //   p: 'PRODUCTION PREPARATION /',
  //   span: 'NEW PRODUCT DEVELOPEMENT',
  //   isIcon: false,
  // },
  {
    id: 2,
    link: '/admin/clause3',
    isLink: true,
    clauseNo: 3,
    p: 'CHANGING',
    span: 'MANAGEMENT',
    isIcon: false,
  },
  {
    id: 3,
    link: '/admin/clause4',
    isLink: true,
    clauseNo: 4,
    p: 'STANDARDS',
    span: 'MANAGEMENT',
    isIcon: false,
  },
  {
    id: 4,
    link: '/common/clause5',
    isLink: true,
    clauseNo: 5,
    p: 'EDUCATION AND',
    span: 'TRAINING',
    isIcon: false,
  },
  // {
  //   id: 5,
  //   link: '/admin/clause7',
  //   isLink: true,
  //   clauseNo: 7,
  //   p: 'Supplier',
  //   span: 'Control',
  //   isIcon: false,
  // },
  // {
  //   id: 6,
  //   link: '/admin/clause8',
  //   isLink: true,
  //   clauseNo: 8,
  //   p: 'HANDLING ABNORMILITY',
  //   span: 'IN QUALITY',
  //   isIcon: false,
  // },
  {
    id: 7,
    link: '/admin/clause10',
    isLink: true,
    clauseNo: 10,
    p: 'EQUIPMENT / INSPECTION',
    span: "EQUIPMENT'S MANAGEMENT",
    isIcon: false,
  },
  {
      id: 8,
      link: '/common/clause15',
      isLink: true,
      clauseNo: 15,
      p: 'ADEQUATE TESTING',
      span: 'FACILITY',
      isIcon: false,
  },
  // {
  //   id: 9,
  //   link: '/admin/clause18',
  //   isLink: true,
  //   clauseNo: 18,
  //   p: 'SAFETY',
  //   span: '',
  //   isIcon: false,
  // },
  // {
  //   id: 10,
  //   link: '/common/production',
  //   isLink: true,
  //   icon: <CgCalendarToday size="5rem" color="#212529" />,
  //   p: 'PRODUCTION',
  //   span: 'MANAGEMENT',
  //   isIcon: true,
  // },

  // {
  //   id: 11,
  //   link: '/common/scrapNoteList',
  //   isLink: true,
  //   icon: <RiDeleteBin4Fill size="5rem" color="#a12222" />,
  //   p: 'SCRAP NOTE',
  //   span: 'REPORT',
  //   isIcon: true,
  // },
  {
    id: 12,
    link: '/common/riDepartment',
    isLink: true,
    icon: <FaTruckArrowRight size="5rem" color="#343a40" />,
    p: 'RI',
    span: 'Department',
    isIcon: true,
  },
  // {
  //   id: 13,
  //   link: '/common/processRejection',
  //   isLink: true,
  //   icon: <CgCalendarToday size="5rem" color="#212529" />,
  //   p: 'PROCESS REJECTION',
  //   span: 'SHEET',
  //   isIcon: true,
  // },

  // {
  //   id: 14,
  //   link: '/admin/clause15',
  //   isLink: true,
  //   clauseNo: 15,
  //   p: 'ADEQUATE TESTING',
  //   span: 'FACILITY',
  //   isIcon: false,
  // },
  {
    id: 15,
    link: '/common/home/prductionDept',
    isLink: true,
    icon: <MdWarehouse size="5rem" color="#343a40" />,
    p: 'Production Department',
    span: '(Rubber Moulding)',
    isIcon: true,
  },
  {
    id: 17,
    link: '/common/qualityDepartmentHome',
    isLink: true,
    icon: <SlNotebook size="5rem" color="#a12222" />,
    p: 'QUALITY',
    span: 'DEPARTMENT',
    isIcon: true,
  },

  {
    id: 16,
    link: `/common/mushinHelpDesk`,
    isLink: true,
    icon: <MdSupportAgent size="5rem" color="#1864ab" />,
    p: 'MUSHIN HELP',
    span: 'DESK',
    isIcon: true,
  },
];

const AdminHome = () => {
  return <AdminHomeRenderTiles tilesObj={adminTilesObj} height="100%" />;
};

export default AdminHome;
