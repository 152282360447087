import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { Checkbox } from '@mui/material';

interface IProps {
  moduleData: any;
  isView: boolean;
  setShift: any;
}

const theadData = [
  'S. No.',
  'Product Name',
  'Model Name',
  'Engineer Name',
  'Target',
  'Inspected QTY.',
  'Rejection QTY.',
  'OK QTY.',
  'Opening WIP Qty.',
  'Closing WIP Qty.',
  'Produced Qty',
  'Joint 1',
  'Joint 2',
  'Joint 3',
  'Moulder Name',
  'Rejection Details',
  'SPD / MKT',
  'Manpower',
  '(Breakdown) Down Time Details (time in mins)',
  'Breakdown Reasons',
  'Remarks',
  'Delete',
];

const THead = ({ moduleData, isView, setShift }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <thead>
      <TableCompHead
        colSpan={27}
        rowSpan={3}
        labels={['DOC NO.', 'REV.NO.', 'DATE']}
        values={['HO-F-PRD-11', '03', '15.07.2016']}
        heading="SHIFT PRODUCTION REPORT"
      />
      <tr>
        <th colSpan={3} align="left" style={{ width: '250px' }}>
          FINAL INSPECTION REPORT DATE :{' '}
          {isView ? (
            formatDate(moduleData.final_inspection_report_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.final_inspection_report_date)}
              onChange={(date: any) => {
                moduleData.final_inspection_report_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={23} align="left" style={{ width: '250px' }}>
          SHIFT :{'  A'}
          <Checkbox
            checked={moduleData.shift === 'A'}
            onChange={(e) => {
              moduleData.shift = 'A';
              setShift('A');
              setRender((prev) => !prev);
            }}
          />
          B{' '}
          <Checkbox
            checked={moduleData.shift === 'B'}
            onChange={(e) => {
              moduleData.shift = 'B';
              setShift('B');
              setRender((prev) => !prev);
            }}
          />
          C{' '}
          <Checkbox
            checked={moduleData.shift === 'C'}
            onChange={(e) => {
              moduleData.shift = 'C';
              setShift('C');
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr style={{ background: '#38d9a9' }}>
        {theadData.map((heading, index) => (
          <th
            key={heading + index}
            colSpan={index == 15 ? 5 : index == 17 ? 2 : 1}
            rowSpan={index == 15 || index == 17 ? 1 : 3}
            style={{ minWidth: '100px' }}
          >
            {heading}
          </th>
        ))}
      </tr>
      <tr style={{ background: '#38d9a9' }}>
        <th rowSpan={2}>Rework</th>
        <th colSpan={4}>Scrap</th>
        <th rowSpan={2}>Standard</th>
        <th rowSpan={2}>Actual</th>
      </tr>
      <tr style={{ background: '#38d9a9' }}>
        <th>AA Sec.</th>
        <th>BB Sec.</th>
        <th>CC Sec.</th>
        <th>DD Sec.</th>
      </tr>
    </thead>
  );
};

export default THead;
