export const listConstant = {
    childHeader: 'Predictive Maintenance Plan list',
    addButtonLable: 'New Plan',
    datePickerLabel: 'Search By Year',
    modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
    childHeader: 'Predictive Maintenance Plan list',
    theadHeader: 'Predictive Maintenance Plan',
    extracolSpan: 3,
};

export const apiConstant = {
    // list api url
    gettableListDataurl: 'predictiveMaintenancePlanRoute/monthlylist',
    deleteListurl: 'predictiveMaintenancePlanRoute',
    getListPlanByid: 'predictiveMaintenancePlanRoute',
    createListPlanByid: 'predictiveMaintenancePlanRoute',

    // -----plan--
    sync: 'predictiveMaintenancePlanRoute/handleSync',
    getAllPlanById: 'predictiveMaintenancePlanRoute/allplansbyid',
    upadateCreateAllPlan: 'predictiveMaintenancePlanRoute/allplansCreateUpdate',
    maintenancePlanDeleteBulkById:
        'predictiveMaintenancePlanRoute/maintenancePlanDeleteBulkById',
    forAutoComplete: 'machineRoutes/machine/ForAutocomplete',
};

export const settingPanelConstant = {
    // settingPanel setting for plan
    crudApi: 'predictiveMaintenancePlanSettingRoute',
    docType: 'predictiveMaintenancePlan',
};

export const tilesConstant = {
    childHeaderText: 'Predictive Maintenance Plan',
};

export const navigationConstant = {
    planRouter: '/common/predictiveMaintenancePlanCreate',
};
