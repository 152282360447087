import React from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { TableCustom } from '../../../../../components/common';
import formatDate from '../../../../../components/common/formatDate';
import {
  DeleteIconButton,
  EditIconButton,
  ViewIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import { useNavigate } from 'react-router-dom';
import CustomUploadButton from '../../../../../components/common/button/CustomUploadButton';
import { UpdateButton } from '../../../../../components/common/button/CustomButtons';

interface IProps {
  deleteDocument: (id: string) => void;
  uploadImage: (id: string) => void;
  setImages: React.Dispatch<React.SetStateAction<null>>;
  setRender: React.Dispatch<React.SetStateAction<boolean>>;
  tableData: {
    [key: string | number]: any;
  }[];
}

const Header = [
  [
    {
      text: '#',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'PART NAME',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'PART NUMBER',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'SUB PART NAME',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'SUB PART NUMBER',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: ' DATE',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'CHOOSE IMAGE',
      colspan: 1,
      rowspan: 1,
    },

    {
      text: 'ACTION',
      colspan: 1,
      rowspan: 1,
    },
  ],
];

const InspectionStandardList = ({
  uploadImage,
  deleteDocument,
  tableData,
  setImages,
  setRender,
}: IProps) => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <TableCustom tableHeaderObj={Header}>
        <TableBody>
          {tableData.map(
            (document: { [key: string]: any }, documentIndex: number) => (
              <TableRow>
                <TableCell align="center">{documentIndex + 1}</TableCell>
                <TableCell align="center">
                  {document?.ri_part_id?.part_name}
                </TableCell>
                <TableCell align="center">
                  {document?.ri_part_id?.part_number}
                </TableCell>
                <TableCell align="center">
                  {document?.ri_part_id?.part_name_2}
                </TableCell>
                <TableCell align="center">
                  {formatDate(document.moduleDate)}
                </TableCell>
                <TableCell align="center">
                  {document?.ri_part_id?.part_number_2}
                </TableCell>
                <TableCell align="center">
                  <CustomUploadButton
                    lable={'UPLOAD DRAWING'}
                    accept="image/png,image/jpeg,image/jpg,application/pdf"
                    onChange={(e) => setImages(e.target.files)}
                  />
                </TableCell>
                <TableCell align="center">
                  <ViewIconButton
                    tooltipTitle="view"
                    onClick={() =>
                      navigate(
                        `/common/inspectionStandard/${document._id}?isView=true`
                      )
                    }
                  />
                  <EditIconButton
                    tooltipTitle="edit"
                    onClick={() =>
                      navigate(
                        `/common/inspectionStandard/${document._id}?isView=false`
                      )
                    }
                  />
                  <DeleteIconButton
                    tooltipTitle="delete"
                    onClick={() => deleteDocument(document._id)}
                  />
                  <UpdateButton
                    label="update"
                    onClick={() => uploadImage(document._id)}
                  />
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </TableCustom>
    </React.Fragment>
  );
};

export default InspectionStandardList;
