import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import TController from './components/TController';

const CreateMouldHistory = () => {
  const [moduleData, setModuleData] = useState<any>({});
  const { id, joint } = useParams();
  const isView = useQuery('isView') === 'true' ? true : false;

  const calculateCumulative = (moduleData: any) => {
    const values = { ...moduleData };
    values.preventive_maintenace_done_freq = 40000;
    values.mould_change_freq = 500000;
    let tempForPm = 0;
    let tempForMould = 0;
    values?.list?.length > 0 &&
      values.list.forEach((item: any, index: number) => {
        // item.status = '';
        tempForPm += item.actual_prod;
        tempForMould += item.actual_prod;
        item.cummulative_prevantive_maintenance = tempForPm;
        item.cummulative_for_mould_change = tempForMould;

        if (tempForPm >= values?.preventive_maintenace_done_freq) {
          tempForPm = 0;
          item.status = 'PM Change';
        }
        if (tempForMould >= values?.mould_change_freq) {
          tempForMould = 0;
          item.status = 'Mould Change';
        }
      });

    setModuleData(values);
  };

  useEffect(() => {
    if (id && joint) {
      axios
        .get(`/api/productionMouldingRoutes/mouldHistory/${id}/${joint}`)
        .then((res) => {
          const data = res?.data;
          console.log(data);
          calculateCumulative(data);
          setModuleData(data);
        })
        .catch((err) => console.log(err));
    }
  }, [id, joint]);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?'
  );

  const onSubmit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    await axios
      .put(`/api/productionMouldingRoutes/${id}`, moduleData)
      .then((res) => {
        if (res.data) {
          alert('Updated Successfully');
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Box>
        <ChildHeader text="Mould History Card">
          {!isView && (
            <SubmitButton label="Submit" onClick={() => onSubmit()} />
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
        }}
      />
      <DialogSubmit />
    </>
  );
};

export default CreateMouldHistory;
