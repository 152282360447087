import React, { useState } from 'react';
import { TableInput } from '../../../../components/common';
import handleChangeSpec from '../../helpers/handleChangeSpec';
import { handleChangeColorOnRemarks } from '../../helpers/handleChangeColorOnRemarks';

interface IProps {
  spec: any;
  isView: boolean;
  checkType: string;
  obsIndex: number;
  obsNumber: number;
}

const ObservationInput = ({
  spec,
  isView,
  checkType,
  obsIndex,
  obsNumber,
}: IProps) => {
  const [_, setRender] = useState(false);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeSpec(e, spec, obsIndex, obsNumber);
    handleChangeColorOnRemarks(spec, spec.remarks);
    setRender((prev) => !prev);
  };

  const renderTextInput = () => (
    <TableInput
      name="observation"
      type="text"
      value={spec[`check_${obsNumber}_values`][obsIndex] || ''}
      onChange={handleInputChange}
      style={{ textAlign: 'center' }}
      isView={isView}
    />
  );

  const renderNumberInput = () => (
    <TableInput
      name="observation"
      type="number"
      value={spec[`check_${obsNumber}_values`][obsIndex] || ''}
      onChange={handleInputChange}
      style={{
        backgroundColor: spec[`check_${obsNumber}_colors`][obsIndex],
        textAlign: 'center',
      }}
      isView={isView}
    />
  );

  if (checkType === 'check3') {
    return (
      <td
        style={{
          textTransform: 'uppercase',
        }}
      >
        {isView
          ? spec[`check_${obsNumber}_values`][obsIndex] || ''
          : renderTextInput()}
      </td>
    );
  }

  if (['check1', 'check2', 'check4'].includes(checkType)) {
    return (
      <td
        style={{
          background: isView
            ? spec[`check_${obsNumber}_colors`][obsIndex]
            : 'inherit',
        }}
      >
        {renderNumberInput()}
      </td>
    );
  }

  return <></>;
};

export default ObservationInput;
