import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import CustomSelect from '../../../../components/common/SelectField';
import { Checkbox, IconButton, MenuItem, Stack, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SpeedDialCustom from '../common/ui/SpeedDialCustom';
import React from 'react';
import {
  CustomIconButton,
  DeleteIconButton,
} from '../../../../components/common/button/CustomIconButton';
import { HiDocumentDuplicate } from 'react-icons/hi';
import { GrEdit } from 'react-icons/gr';

interface IProps {
  moduleData: any;
  item: any;
  handleDeleteSpec: any;
  specIdx: number;
  tabValue: number;
  openSpecToleranceModal: any;
  isView: boolean;
}
const TBodySpecs = ({
  item,
  specIdx,
  moduleData,
  tabValue,
  isView,
  handleDeleteSpec,
  openSpecToleranceModal,
}: IProps) => {
  const [render, setRender] = useState(false);

  const gptDisable = () => {
    item.is_gpt_disable = false;
  };
  const handleSpecInputs = (e: any) => {
    if (e.target.name === 'is_gpt_disable') {
      gptDisable();
    }
    const value = e.target.value;
    item[e.target.name] = value;
    setRender((prev) => !prev);
  };

  return (
    <>
      <td style={{ minWidth: '150px' }}>
        <TableInput
          name="machine"
          type="text"
          value={item?.machine}
          isView={isView}
          onChange={(e) => handleSpecInputs(e)}
        />
      </td>
      <td>
        <TableInput
          name="s_no"
          type="number"
          value={item?.s_no}
          onChange={(e) => handleSpecInputs(e)}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="char_product"
          type="text"
          value={item?.char_product}
          onChange={(e) => handleSpecInputs(e)}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="char_process"
          type="text"
          value={item?.char_process}
          onChange={(e) => handleSpecInputs(e)}
          isView={isView}
        />
      </td>
      <td>
        <CustomSelect
          label=""
          name={'special_character'}
          value={item.special_character}
          onChange={(e) => {
            item.special_character = e.target.value;
            setRender((prev) => !prev);
          }}
        >
          <MenuItem value="">None</MenuItem>
          <MenuItem value="C">C = Critical</MenuItem>
          <MenuItem value="M">M = Major</MenuItem>
          <MenuItem value="Mi">Mi = Minor</MenuItem>
        </CustomSelect>
      </td>
      <td style={{ position: 'relative', minWidth: '200px' }}>
        <Stack direction="row" gap={1} alignItems="center">
          <TableInput
            name="control_product"
            type="text"
            value={item?.control_product}
            onChange={(e) => handleSpecInputs(e)}
            isView={isView}
          />
          <CustomIconButton
            sx={{ p: 0 }}
            tooltipTitle={'duplicate'}
            onClick={() => {
              const ans = window.confirm(
                'Are you certain you wish to manually override the numerical values? This action may yield unexpected results.☠️',
              );
              if (!ans) return;
              openSpecToleranceModal(tabValue, specIdx);
            }}
          >
            <GrEdit color="#343a40" />
          </CustomIconButton>
        </Stack>
      </td>
      <td>
        <TableInput
          name="methods_measure"
          type="text"
          value={item?.methods_measure}
          onChange={(e) => handleSpecInputs(e)}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="methods_size"
          type="text"
          value={item?.methods_size}
          onChange={(e) => handleSpecInputs(e)}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="methods_by"
          type="text"
          value={item?.methods_by}
          onChange={(e) => handleSpecInputs(e)}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="control_method"
          type="text"
          value={item?.methods_process}
          onChange={(e) => handleSpecInputs(e)}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="recording"
          type="text"
          value={item?.recording}
          onChange={(e) => handleSpecInputs(e)}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="reaction_plan"
          type="text"
          value={item?.reaction_plan}
          onChange={(e) => handleSpecInputs(e)}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="error_proofing"
          type="text"
          value={item?.error_proofing}
          onChange={(e) => handleSpecInputs(e)}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="corrective_action"
          type="text"
          value={item?.corrective_action}
          onChange={(e) => handleSpecInputs(e)}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="responsibility"
          type="text"
          value={item?.responsibility}
          onChange={(e) => handleSpecInputs(e)}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="process_capability"
          type="text"
          value={item?.process_capability}
          onChange={(e) => handleSpecInputs(e)}
          isView={isView}
        />
      </td>
      <td>
        <CustomSelect
          name={'control_incharge'}
          value={item.control_incharge}
          label={''}
          onChange={(e) => {
            item.control_incharge = e.target.value;
            setRender((prev) => !prev);
          }}
        >
          <MenuItem value="">None</MenuItem>
          <MenuItem value="R">R - PATROL INSPECTION</MenuItem>
          <MenuItem value="AM">AM -AUTOMATIC MEASUREMENT</MenuItem>
          <MenuItem value="W">W - OPERATOR</MenuItem>
          <MenuItem value="P">P- PROCESS APPROVAL</MenuItem>
          <MenuItem value="I">I - INSPECTOR</MenuItem>
        </CustomSelect>
      </td>
      <td>
        <TableInput
          name="submission_freq_data"
          type="text"
          value={item?.submission_freq_data}
          onChange={(e) => handleSpecInputs(e)}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="ppc_control_point"
          type="text"
          value={item?.ppc_control_point}
          onChange={(e) => handleSpecInputs(e)}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="ppc_control_value"
          type="text"
          value={item?.ppc_control_value}
          onChange={(e) => handleSpecInputs(e)}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="ppc_person_incharge"
          type="text"
          value={item?.ppc_person_incharge}
          onChange={(e) => handleSpecInputs(e)}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="ppc_check_method"
          type="text"
          value={item?.ppc_check_method}
          onChange={(e) => handleSpecInputs(e)}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="ppc_checking_freq"
          type="text"
          value={item?.ppc_checking_freq}
          onChange={(e) => handleSpecInputs(e)}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="ppc_data_record_form_no"
          type="text"
          value={item?.ppc_data_record_form_no}
          onChange={(e) => handleSpecInputs(e)}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="ppc_recording"
          type="text"
          value={item?.ppc_recording}
          onChange={(e) => handleSpecInputs(e)}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="responsibility_periodical"
          type="text"
          value={item?.responsibility_periodical}
          onChange={(e) => handleSpecInputs(e)}
          isView={isView}
        />
      </td>{' '}
      <td>
        <TableInput
          name="responsibility_primary"
          type="text"
          value={item?.responsibility_primary}
          onChange={(e) => handleSpecInputs(e)}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="operation_standard_reference"
          type="text"
          value={item?.operation_standard_reference}
          onChange={(e) => handleSpecInputs(e)}
          isView={isView}
        />
      </td>
      <td>
        <TableInput
          name="checked_by"
          type="text"
          value={item?.checked_by}
          onChange={(e) => handleSpecInputs(e)}
          isView={isView}
        />
      </td>
      <td style={{ minWidth: '150px' }}>
        <TableInput
          name="remarks"
          type="text"
          value={item?.remarks}
          onChange={(e) => handleSpecInputs(e)}
          isView={isView}
        />
      </td>
      <td style={{ minWidth: '50px' }}>
        <DeleteIconButton
          tooltipTitle={'delete'}
          onClick={() => handleDeleteSpec(specIdx)}
        />
      </td>
    </>
  );
};

export default React.memo(TBodySpecs);
