import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';

interface IProps {
  moduleData: any;
  isView: boolean;
  // handelDeleteRows: any;
}
const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      {moduleData?.list &&
        moduleData?.list?.map((item: any, index: number) => (
          <tr key={`${index}_row`}>
            <td style={{ minWidth: '120px', width: '120px' }}>
              {formatDate(item.date)}
            </td>
            <td style={{ minWidth: '140px', width: '140px' }}>
              <TableInput
                isView={isView}
                name="prod_incharge_sign"
                type="text"
                value={item.prod_incharge_sign}
                onChange={(e) => {
                  item.prod_incharge_sign = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '160px', width: '160px' }}>
              {isView ? (
                formatDate(item.unloading_date)
              ) : (
                <DatePickerMui
                  value={
                    item?.unloading_date ? moment(item.unloading_date) : null
                  }
                  onChange={(date) => {
                    item.unloading_date = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
            <td style={{ width: '150px' }}>{item?.actual_prod}</td>
            <td style={{ minWidth: '160px', width: '160px' }}>
              {item?.cummulative_prevantive_maintenance}
            </td>
            <td style={{ minWidth: '160px', width: '160px' }}>
              {item?.cummulative_for_mould_change}
            </td>
            <td style={{ minWidth: '200px', width: '200px' }}>
              <TableInput
                isView={isView}
                name="reason_for_unloading"
                type="text"
                value={item.reason_for_unloading}
                onChange={(e) => {
                  item.reason_for_unloading = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '150px', width: '150px' }}>
              <TableInput
                isView={isView}
                name="prod_incharge_sign_2"
                type="text"
                value={item.prod_incharge_sign_2}
                onChange={(e) => {
                  item.prod_incharge_sign_2 = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '150px', width: '150px' }}>
              <TableInput
                isView={isView}
                name="no_of_shots_since_last_pm"
                type="number"
                value={item.no_of_shots_since_last_pm}
                onChange={(e) => {
                  item.no_of_shots_since_last_pm = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '150px', width: '150px' }}>
              <TableInput
                isView={isView}
                name="no_of_months_since_last_pm"
                type="number"
                value={item.no_of_months_since_last_pm}
                onChange={(e) => {
                  item.no_of_months_since_last_pm = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '160px', width: '160px' }}>
              {isView ? (
                formatDate(item?.mould_receipt_date)
              ) : (
                <DatePickerMui
                  value={
                    item?.mould_receipt_date
                      ? moment(item.mould_receipt_date)
                      : null
                  }
                  onChange={(date) => {
                    item.mould_receipt_date = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
            <td style={{ minWidth: '200px', width: '200px' }}>
              <TableInput
                isView={isView}
                name="defect_observed_by_tool_room_work_done_action_taken"
                type="text"
                value={item.defect_observed_by_tool_room_work_done_action_taken}
                onChange={(e) => {
                  item.defect_observed_by_tool_room_work_done_action_taken =
                    e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '160px', width: '160px' }}>
              {isView ? (
                formatDate(item?.date_of_completion_or_date_of_trial)
              ) : (
                <DatePickerMui
                  value={
                    item?.date_of_completion_or_date_of_trial
                      ? moment(item.date_of_completion_or_date_of_trial)
                      : null
                  }
                  onChange={(date) => {
                    item.date_of_completion_or_date_of_trial = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
            <td style={{ minWidth: '150px', width: '150px' }}>
              <TableInput
                isView={isView}
                name="time_spent"
                type="number"
                value={item.time_spent}
                onChange={(e) => {
                  item.time_spent = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '150px', width: '150px' }}>
              <TableInput
                isView={isView}
                name="spare_part_used"
                type="text"
                value={item.spare_part_used}
                onChange={(e) => {
                  item.spare_part_used = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '150px', width: '150px' }}>
              <TableInput
                isView={isView}
                name="tr_incharge_sing"
                type="text"
                value={item.tr_incharge_sing}
                onChange={(e) => {
                  item.tr_incharge_sing = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '150px', width: '150px' }}>
              <TableInput
                isView={isView}
                name="tr_head_sign"
                type="text"
                value={item.tr_head_sign}
                onChange={(e) => {
                  item.tr_head_sign = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td
              style={{
                minWidth: '150px',
                width: '150px',
                ...(item.status === 'PM Change' && { background: '#ffec99' }),
                ...(item.status === 'Mould Change' && {
                  background: '#ffa8a8',
                }),
              }}
            >
              {item.status}
            </td>
            <td style={{ minWidth: '150px', width: '150px' }}>
              <TableInput
                isView={isView}
                name="remarks"
                type="text"
                value={item.remarks}
                onChange={(e) => {
                  item.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>
        ))}
    </tbody>
  );
};

export default TBody;
