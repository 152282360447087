import axios from 'axios';
import React, { useEffect, useState } from 'react';
import useConfirm from '../../components/common/useConfirm';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import GridInputs from '../../components/common/inputs/GridInputs';

const Create = ({
  id,
  setIsModal,
  setId,
}: {
  id?: string | null;
  setId?: any;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [moduleData, setModuleData] = useState<any>({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update' : 'Create'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/masterlistProcessParameterRoute/${id}`)
        .then((res) => setModuleData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleModuleData(e: React.ChangeEvent<HTMLInputElement>) {
    setModuleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    setIsSubmit(true);
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/masterlistProcessParameterRoute/${id}`, moduleData)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
          setId(null);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/masterlistProcessParameterRoute', moduleData)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
        })
        .catch((err) => {
          setIsSubmit(false);
          setId(null);
          console.log(err);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'process_parameter'}
        name={'process_parameter'}
        html_for={'process_parameter'}
        label_name={'Process Parameter'.toUpperCase()}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.process_parameter || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'method'}
        name={'method'}
        html_for={'method'}
        label_name={'METHOD'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.method || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'REMARKS'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.remarks || ''}
        onChange={handleModuleData}
      />

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
