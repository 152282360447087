import { MenuItem } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import ModalCustom from '../../../components/common/Modal';
import CustomSelect from '../../../components/common/SelectField';
import ForMSummaryForm from './ForMSummaryForm';

const months = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];
const Operator = ({
  machineId,
  process,
  field,
  onSubmit,
  dataObj,
  isView,
}: {
  machineId: string;
  process: any;
  field: string;
  onSubmit?: any;
  dataObj?: any;
  isView?: any;
}) => {
  const [operators, setOpeartors] = useState([]);
  const [render, setRender] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [data, setData] = useState<any>({});
  useEffect(() => {
    if (machineId) {
      axios
        .get(`/api/manMachineMatrix/operatorById/${machineId}`)
        .then((res) => {
          if (res.data) {
            setOpeartors(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [machineId]);
  const addTo4mSummary = () => {
    const month = new Date(dataObj.date).getMonth();
    const year = new Date(dataObj.date).getFullYear();
    const annualMonth = `${months[month]}-${year}`;
    if (!data.reason) {
      return alert('Please choose reason!');
    }
    const body = {
      date: dataObj.date,
      part_id: dataObj.part_id,
      operation_name: dataObj.operation_name,
      machine_id: machineId,
      ...data,
    };
    axios
      .put(
        `/api/forMSummaryRoute/insertFromProductionToSummary/${annualMonth}`,
        body
      )
      .then((res) => {
        if (res.data) {
          onSubmit(true, 'forSummary');
          setIsOpenPopup(false);
        }
      });
  };

  return (
    <>
      {isView ? (
        process[field]
      ) : (
        <>
          <div>
            <CustomSelect
              name="operator"
              value={process[field] || ''}
              onChange={(e) => {
                process[field] = e.target.value;
                if (e.target.value === 'Other') {
                  setIsOpenPopup(true);
                }
                setRender((prev) => !prev);
              }}
            >
              <MenuItem>None</MenuItem>
              <MenuItem value={process[field]}>{process[field]}</MenuItem>
              {operators.map((operator: any) => (
                <MenuItem value={operator.operator_name}>
                  {operator.operator_name}
                </MenuItem>
              ))}
              {/* <MenuItem value="Other">Other</MenuItem> */}
            </CustomSelect>
          </div>
          <ModalCustom
            title="SUMMARY DATA"
            openModal={isOpenPopup}
            closeModal={() => {
              setIsOpenPopup(false);
            }}
          >
            <ForMSummaryForm
              {...{ addTo4mSummary, process, field, setData, data }}
            />
          </ModalCustom>
        </>
      )}
    </>
  );
};
export default Operator;
