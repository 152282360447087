export const listConstant = {
  childHeader: 'Revalidation List',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'Revalidation List',
  theadHeader: 'Revalidation Plan',
  extracolSpan: 4,
};

export const checkListList = {
  childHeader: 'Revalidation STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'clipOutsertPlanRoute',
  gettableDataurl: 'clipOutsertPlanRoute/monthly',
  getPlanByid: 'clipOutsertPlanRoute',
  createPlanByid: 'clipOutsertPlanRoute',
  updatePlanByid: 'clipOutsertPlanRoute',
  sync: 'clipOutsertPlanRoute/handleSync',
  getAllCheckList: 'clipOutsertPlanRoute/getAllCheckList',
  checkListStatus: 'clipOutsertPlanRoute/handleStatus',
  checklistUpload: 'clipOutsertPlanRoute/handleUpload',
  checklistDeleteImage: 'clipOutsertPlanRoute/handleDeleteImage',
  checklistData: 'clipOutsertPlanRoute/checklistRoute',
  getMasterDataAutocomplete: 'riDepartment/riPart/riPartList/autoComplete',
};

export const tilesConstant = {
  childHeaderText: 'Revalidation',
};

export const navigationConstant = {
  createPlanSchedule: '/common/clipOutsertPlanCreate',
  createChecklist: '/common/clipOutsertPlanChecklistList',
};
