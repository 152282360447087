import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from '@mui/material';
import ModalCustom from '../../../../components/common/Modal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import axios from 'axios';

interface Attendee {
  employee_id: string;
  employee_name: string;
  presence: {
    [key: string]: boolean;
  };
}

interface AttendeesModalProps {
  open: boolean;
  handleClose: () => void;
  attendees: Attendee[];
  dueDate: string;
  docId: string;
  month: string;
  onPresenceChange: (employeeId: string, isPresent: boolean) => void;
  setRender: any
}

const AttendeesModal: React.FC<AttendeesModalProps> = ({ 
  open, 
  handleClose, 
  attendees, 
  dueDate, 
  docId, 
  month, 
  onPresenceChange 
}) => {
  const handlePresenceChange = async (employeeId: string, isPresent: boolean) => {
    try {
      await axios.put(`/api/trainingPlanRoute/updateAttendance/${docId}`, {
        employeeId,
        month,
        isPresent
      });
      onPresenceChange(employeeId, isPresent);
    } catch (error) {
      console.error('Error updating attendance:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  return (
    <ModalCustom
      title="Attendees"
      openModal={open}
      closeModal={handleClose}
      isModalCloseBtn={true}
    >
      <TableContainer sx={{minWidth:'500px'}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S.No.</TableCell>
              <TableCell>Employee Name</TableCell>
              <TableCell>Presence</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attendees.map((attendee, index) => (
              <TableRow key={attendee.employee_id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{attendee.employee_name}</TableCell>
                <TableCell>
                  <IconButton 
                    onClick={() => handlePresenceChange(attendee.employee_id, !attendee.presence[month])}
                  >
                    {attendee.presence[month] ? <CheckCircleIcon color="primary" /> : <RadioButtonUncheckedIcon />}
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ModalCustom>
  );
};

export default AttendeesModal;