import React from 'react';
import { GraphHead } from '../../../../../../components/common/GraphHeading';
import TController from './TController';
import ParetoChart from './ParetoChart';
import AutocompleteMuiCustom from '../../../../../../components/common/AutocompleteMuiCustom';
interface IProps {
  apiData: any;
  year: number;
  title1: any;
  vendors: any;
  vendorName: any;
  setRender: any;
  month: any;
}
const Type = ({
  apiData,
  year,
  title1,
  vendors,
  vendorName,
  setRender,
  month,
}: IProps) => {
  return (
    <div>
      <AutocompleteMuiCustom
        id="Vendor"
        label="Supplier"
        option_name="vender_name"
        arrayofObj={vendors}
        value={vendorName.vendor_id_temp || vendorName.vendor_name || ''}
        onChange={(e, value) => {
          vendorName.vendor_name = value?.vender_name;
          vendorName.vendor_id_temp = value;
          setRender((prev: any) => !prev);
        }}
        sx={{ width: 300, margin: 2 }}
      />
      {vendorName.vendor_name && (
        <>
          <TController apiData={apiData} year={year} month={month} />
          <GraphHead style={{ width: '80vw', margin: '14rem' }}>
            <p>{title1}</p>
            <ParetoChart apiData={apiData} />
          </GraphHead>
        </>
      )}
    </div>
  );
};

export default Type;
