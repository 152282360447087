import { useState } from 'react';
import { TableCompHead } from '../../../../../components/ui';
import { createConstant } from '../helpers/constant';
import { useQuery } from '../../../../../hooks/UseQuery';
import React from 'react';

const style1 = {
    backgroundColor: '#4F81BD',
    color: '#fff',
    minWidth: '150px',
};
const style2 = {
    backgroundColor: '#00B050',
    color: '#fff',
};
const stickStylesRow = {
    position: 'sticky',
    width: '100%',
    top: '-.5rem',
    border: '1px solid black',
    zIndex: '2',
    height: '50px',
} as const;
const stickStylesColumn = {
    position: 'sticky',
    border: '1px solid black',
    zIndex: '1',
    height: '50px',
    backgroundColor: '#4F81BD',
    color: '#fff',
} as const;

interface IProps {
    moduleData?: any;
}
const THead = ({ }: IProps) => {
    const yearRange = useQuery('yearRange');
    const plan_date = useQuery('plan_date');
    const isView = useQuery('isView') === 'true' ? true : false;
    const date = new Date(plan_date || '')?.getFullYear().toString().slice(-2);

    return (
        <thead>
            <TableCompHead
                colSpan={16 + createConstant.extracolSpan}
                rowSpan={3}
                labels={['Doc. No.-', 'Rev. No.-', 'Rev. Date:']}
                values={['F-29-04', ' 07', ' 01.11.2021']}
                heading={`${createConstant.theadHeader} (YEAR : ${yearRange})`}
            />
            <tr style={{ ...stickStylesRow }}>
                <th
                    align="center"
                    style={{
                        textAlign: 'center',
                        ...style1,
                        ...stickStylesColumn,
                        minWidth: '50px',
                        left: 0,
                    }}
                >
                    S.NO.
                </th>
                <th
                    align="center"
                    style={{
                        textAlign: 'center',
                        ...style1,
                        ...stickStylesColumn,
                        left: 50,
                        minWidth: '230px',
                    }}
                >
                    M/C CODE
                </th>
                <th
                    align="center"
                    style={{
                        textAlign: 'center',
                        ...style1,
                        ...stickStylesColumn,
                        left: 280,
                        minWidth: '200px',
                    }}
                >
                    MACHINE DESC.
                </th>
                <th
                    align="center"
                    style={{
                        textAlign: 'center',
                        ...style1,
                        ...stickStylesColumn,
                        left: 480,
                        minWidth: '100px',
                    }}
                >
                    FREQ.
                </th>
                <th
                    align="center"
                    style={{
                        textAlign: 'center',
                        ...style1,
                        ...stickStylesColumn,
                        left: 580,
                        minWidth: '80px',
                    }}
                >
                    STATUS
                </th>
                {[
                    'JAN',
                    'FEB',
                    'MAR',
                    'APR',
                    'MAY',
                    'JUN',
                    'JUL',
                    'AUG',
                    'SEP',
                    'OCT',
                    'NOV',
                    'DEC',
                ].map((el, index) => (
                    <th
                        key={`${index}_headcol`}
                        align="center"
                        style={{ textAlign: 'center', ...style2 }}
                    >
                        {el}-{date}
                    </th>
                ))}
                <th style={{ textAlign: 'center', ...style1 }}>Remarks</th>
                <th style={{ textAlign: 'center', ...style1, background: '#f03e3e' }}>
                    ACTION
                </th>
            </tr>
        </thead>
    );
};

export default React.memo(THead);
