import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import THead from './THead';
import TFoot from './TFoot';
interface IProps {
    moduleData: any;
    updateModuleData: any;
}

const TController = ({ moduleData, updateModuleData }: IProps) => {
    return (
        <Box>
            <RenderTable>
                <THead {...{ moduleData, updateModuleData }} />
                <TBody
                    {...{
                        moduleData,
                        updateModuleData,
                    }}
                />
                <TFoot
                {...{ moduleData, updateModuleData }}
                />
            </RenderTable>
        </Box>
    );
};

export default TController;
