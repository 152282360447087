import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChildHeader from '../../components/ui/ChildHeader';
import {
  SaveButton,
  CloseButton,
  CopyProcessButton,
} from '../../components/common/button/CustomButtons';
import { IModuleData, IProcess } from './helpers/Interface';
import { initialStat, spec } from './helpers/initialState';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomButton } from '../../components/common/button/CustomButtons';
import axios from 'axios';
import ECNModal from '../ecn/ECNModal';
import { useQuery } from '../../hooks/UseQuery';
import useConfirm from '../../components/common/useConfirm';
import { Loader } from '../../components/common/Loader';
import { IconButton, Paper, Stack, Tab, Tabs } from '@mui/material';
import TController from './components/create/TController';
import {
  AddProcessIconButton,
  CopyProcessIconButton,
  DeleteIconButton,
  UploadIconButton,
} from '../../components/common/button/CustomIconButton';
import ModalCustom from '../../components/common/Modal';
import CopyProcess from './CopyProcess';
import useGetData from './helpers/useGetData';
import ProcessOverview from './components/overview/TController';
import CustomUploadButton from '../../components/common/button/CustomUploadButton';
import Tolerance from './components/common/ui/Tolerance';

const Create = () => {
  const navigate = useNavigate();
  const { partId, controlPlanId } = useParams();
  const [tabValue, setTabValue] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const isView = useQuery('isView') === 'true' ? true : false;
  const [isECNModal, setIsECNModal] = useState(false);
  const [isSpecModal, setSpecModal] = useState(false);
  const [specModalData, setSpecModalData] = useState({
    processIdx: 0,
    specIdx: 0,
  });
  const master_format = useQuery('master_format');

  const {
    moduleData,
    isLoading,
    error,
    handleSubmitRevision,
    handleControlPlanImport,
    setRenderParent,
    setModuleData,
    setUseEffectRender,
    prevControlPlanData,
    setPrevControlPlanData,
    handleProcessChange,
    setIsLoading,
  }: any = useGetData();

  const [DialogRevision, confirmRevision] = useConfirm(
    'UPDATE PART',
    'Revision no. already exists! Are you sure you want to overwrite?'
  );
  const [DialogClose, confirmClose] = useConfirm(
    'CLOSE',
    'Are you sure you want to leave this page?'
  );

  const [DialogSave, confirmSave] = useConfirm(
    'SAVE',
    'Are you sure you want to save?'
  );

  const handleControlPlanSubmit = async () => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true);

      if (moduleData._id) {
        let isUpdate = false;
        let isPost = false;
        const isRev: any = await axios
          .get(
            `/api/revision/isrevno/controlplan/${partId}/${moduleData.revision_no}`
          )
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });

        if (isRev.data.status === 'success') {
          if (controlPlanId) {
            isUpdate = await confirmRevision();
          } else {
            isUpdate = true;
          }
        }
        if (isRev.data.status === 'fail') {
          isPost = true;
        }
        if (isUpdate) {
          let isSave = await confirmSave();

          if (isSave) {
            axios
              .put(`/api/controlPlan/${moduleData._id}`, moduleData)
              .then(() => {
                resolve(true);
                alert('Control Plan changes saved successfully');
                setIsLoading(false);
              })
              .catch((err) => {
                console.log(err);
                setIsLoading(false);
              });
          }
        }
        if (isPost) {
          setIsECNModal(true);
        }
      } else {
        let ans = await confirmSave();
        if (ans) {
          partId && (moduleData.part_id = partId);
          moduleData.revision_no = 0;
          axios
            .post('/api/controlPlan/', moduleData)
            .then(() => {
              alert('Control Plan submitted successfully');
              resolve(true);
              setIsLoading(false);
              setUseEffectRender((prev: any) => !prev);
            })
            .catch((err) => {
              console.log(err);
              setIsLoading(false);
            });
        }
      }
    });
  };

  const uploadImageFile = async (e: any, processIndex: number) => {
    await handleControlPlanSubmit();
    const files = e.target.files;
    if (!files) return;
    let formData = new FormData();
    formData.append('file', files[0]);
    setIsLoading(true);
    axios
      .patch(
        `/api/controlPlan/uploadFile/${processIndex}/${moduleData?._id}
      `,
        formData
      )
      .then((res) => {
        if (res.data) {
          alert('document upload successly');
          setIsLoading(false);
          setUseEffectRender((prev: any) => !prev);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const handleDeleteProcess = (processIndex: number) => {
    const process = moduleData?.processes?.[processIndex];
    if (
      window.confirm(
        `Are you sure you want to delete this ${process?.process_no}-${process?.process_name} process?`
      )
    ) {
      const newProcesses = [...moduleData.processes];
      newProcesses.splice(processIndex, 1);
      setModuleData({ ...moduleData, processes: newProcesses });

      // Update the tabValue to a safe index
      if (newProcesses.length === 0) {
        setTabValue(-1); // No tabs left
      } else {
        setTabValue(processIndex === 0 ? 0 : processIndex);
      }
    }
  };

  const openSpecToleranceModal = (processIdx: number, specIdx: number) => {
    setSpecModalData((prev) => ({ processIdx: processIdx, specIdx: specIdx }));
    setSpecModal(true);
  };

  return (
    <>
      <Loader loader={isLoading} />
      <Box>
        <ChildHeader text="CONTROL PLAN">
          <CopyProcessButton
            label="copy from pfd"
            sx={{ backgroundColor: '#fff', color: '#111' }}
            onClick={() => {}}
          />
          <CustomButton
            icon={<VisibilityIcon />}
            sx={{ margin: '10px', backgroundColor: '#fff', color: '#111' }}
            onClick={() =>
              navigate(
                `/admin/viewControlplan/${partId}/${moduleData._id}?master_format=${master_format}`
              )
            }
          >
            VIEW
          </CustomButton>
          <SaveButton label="Save" onClick={() => handleControlPlanSubmit()} />
          <CloseButton
            label="Close"
            onClick={async () => {
              const ans = await confirmClose();
              if (!ans) return;
              navigate(-1);
            }}
          />
        </ChildHeader>

        <Paper
          elevation={2}
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Tabs
            value={tabValue}
            onChange={(_, newValue) => {
              setTabValue(newValue);
            }}
            TabIndicatorProps={{ style: { backgroundColor: '#0ca678' } }}
            variant="scrollable"
            scrollButtons="auto"
            textColor="secondary"
          >
            {/* Separate tab for 'overview' */}
            <Tab label={`overview`} />

            {/* Process tabs starting from tabValue 1 */}
            {moduleData.processes &&
              moduleData.processes.map((process: any, tIdx: number) => (
                <Tab
                  label={`${process.process_no}-${process.process_name}`}
                  key={`${tIdx}-tabkey`}
                />
              ))}
          </Tabs>
          <Stack display="column" direction="row" alignItems="center" gap={1}>
            <UploadIconButton
              tooltipTitle="upload your excel"
              sx={{ mr: '10px' }}
              onChange={handleControlPlanImport}
            />
            <CopyProcessIconButton
              tooltipTitle="copy a process"
              sx={{ mr: '10px', color: '#343a40' }}
              onClick={() => {
                setOpenModal(true);
              }}
            />
            <AddProcessIconButton
              label="add new process"
              onClick={() => {
                const values: IModuleData = { ...moduleData };
                const newProcess: IProcess = {
                  s_no: moduleData.processes.length + 1,
                  process_no: '',
                  process_flow_no: '',
                  process_name: '',
                  files: [],
                  specs: [
                    {
                      ...spec,
                      s_no: 1,
                      key: Math.random(),
                    },
                  ],
                };
                moduleData.processes.push(newProcess);
                setModuleData(values);
                setTabValue(moduleData.processes.length);
              }}
            />
            {moduleData.processes.length > 0 && (
              <DeleteIconButton
                tooltipTitle={'Delete current process'}
                sx={{ mr: '10px', ml: -0.5, mt: 0, mb: 0 }}
                onClick={() => handleDeleteProcess(tabValue - 1)}
              />
            )}
          </Stack>
        </Paper>
      </Box>

      {tabValue === 0 ? (
        <ProcessOverview
          {...{
            moduleData,
            tabValue,
            setRenderParent,
            setModuleData,
            controlPlanId,
            isView,
          }}
        />
      ) : (
        <TController
          {...{
            isView,
            moduleData,
            setModuleData,
            controlPlanId,
            uploadImageFile,
            setRenderParent,
            handleProcessChange,
            openSpecToleranceModal,
          }}
          tabValue={tabValue - 1}
        />
      )}

      {/* Ecn Modal needs to fixed later */}
      {isECNModal && (
        <ECNModal
          openPopup={isECNModal}
          closeModal={() => setIsECNModal(false)}
          partData={moduleData}
          documentType="controlplan"
          ecnTitle="ECN - CONTROL PLAN CHANGES"
          partDataForRevision={prevControlPlanData}
          submit={handleSubmitRevision}
        />
      )}

      <ModalCustom
        title={'COPY FROM EXISTING PROCESSES'}
        openModal={openModal}
        closeModal={() => {
          setOpenModal(false);
        }}
      >
        <CopyProcess
          {...{ moduleData, setRenderParent, setOpenModal, setTabValue }}
        />
      </ModalCustom>

      <ModalCustom
        title={'Product/Process Specification/Tolerance'}
        openModal={isSpecModal}
        closeModal={() => {
          setSpecModal(false);
        }}
      >
        <Tolerance
          item={
            moduleData?.processes?.[specModalData?.processIdx]?.specs[
              specModalData?.specIdx
            ]
          }
        />
      </ModalCustom>

      <DialogSave isSubmitContent={true} />
      <DialogRevision />
      <DialogClose isSubmitContent={false} />
    </>
  );
};

export default Create;
