// TableRowComponent.jsx
import React from 'react';
import { Checkbox } from '@mui/material';
import TBodyRight from './TBodyRight';
import formatDate from '../../../../components/common/formatDate';
import { TableInput } from '../../../../components/common';
import { useQuery } from '../../../../hooks/UseQuery';
import AutocompleteMuiCustomRevised from '../../../../components/common/tableinputs/AutocompleteMuiCustomRevised';

export const TableRowComponent = React.memo(
  ({
    key,
    item,
    index,
    allmonth,
    updateModuleData,
    selectionArray,
    handleBulkDeleteFeature,
    partData,
    moulderData,
    settings,
  }: any) => {
    const isView = useQuery('isView') === 'true' ? true : false;
    const rowId = item?._id || item?._tempId;
    const isChecked = selectionArray[index] === rowId;

    const handleInputChange = (
      fieldName: string,
      value: string | number | null
    ) => {
      updateModuleData(index, fieldName, { [fieldName]: value });
    };

    const handleAutocompleteChange = (idName: any, newValue: any) => {
      // Update a1, a2, and row_id fields in the row
      if (idName == 'row_id') {
        updateModuleData(index, 'a1', { a1: newValue?.moulding_name });
        updateModuleData(index, 'a2', { a2: newValue?.moulding_id });
      } else {
        updateModuleData(index, 'a3', { a3: newValue?.part_number });
      }
      console.log('jggjkgk', idName, newValue);
      updateModuleData(index, idName, newValue);
    };

    const handleCheckboxChange = (event: any) => {
      handleBulkDeleteFeature(index, event.target.checked, rowId);
    };

    return (
      <React.Fragment key={key}>
        <tr>
          <td rowSpan={2}>{index + 1}</td>
          <td rowSpan={2}>
            {item?._id && item?.row_id === null ? (
              item?.a1
            ) : (
              <AutocompleteMuiCustomRevised
                id="moulding_name"
                label=""
                option_name="moulding_name"
                key={'moulding_name'}
                arrayofObj={moulderData}
                value={item?.row_id}
                onChange={(_, newValue: any) =>
                  handleAutocompleteChange('row_id', newValue)
                }
                sx={{ width: '200px' }}
              />
            )}
          </td>

          {/* {Array(createConstant.extracolSpan - 1 - 1)
            .fill(0)
            .map((_, pIdx) => (
              <td rowSpan={2}>{item?.[`a${pIdx + 1}`]}</td>
            ))} */}
          <td rowSpan={2}>{item?.[`a2`]}</td>
          <td rowSpan={2}>
            {item?._id && item?.part_id === null ? (
              item?.a3
            ) : (
              <AutocompleteMuiCustomRevised
                id="part_number"
                label=""
                option_name="part_number"
                key={'part_number'}
                arrayofObj={partData}
                value={item?.part_id}
                onChange={(_, newValue: any) =>
                  handleAutocompleteChange('part_id', newValue)
                }
                sx={{ width: '200px' }}
              />
            )}
          </td>

          <td>P</td>

          {/* always constant ⬇️*/}
          <TBodyRight
            item={item}
            isView={isView}
            index={index}
            allmonth={allmonth}
            updateModuleData={updateModuleData}
          />
          {/* 👉 */}

          <td rowSpan={2}>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="remarks"
              type="text"
              value={item.remarks}
              onChange={(e: any) =>
                handleInputChange('remarks', e.target.value)
              }
            />
          </td>
          <td rowSpan={2}>
            <Checkbox
              style={{ color: '#fa5252' }}
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
          </td>
        </tr>
        <tr>
          <td>A</td>
          {allmonth.map((month: any, mIdx: number) => (
            <td
              width="80px"
              height="45px"
              style={{
                backgroundColor: item[month].doneDate && '#37b24d',
                color: 'white',
                textTransform: 'capitalize',
                fontSize: '1.6rem',
              }}
            >
              {settings.show_A && item[month].isTask && 'A'}
              {(settings.show_date && formatDate(item[month].doneDate)) || null}
            </td>
          ))}
        </tr>
        {/* ---x---- */}
      </React.Fragment>
    );
  },
  (prevProps, nextProps) => {
    // Check if the relevant data for this row has changed
    return (
      prevProps.item === nextProps.item &&
      prevProps.selectionArray[prevProps.index] ===
        nextProps.selectionArray[nextProps.index]
    );
  }
);
