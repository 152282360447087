import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import moment from 'moment';
import { SubmitButton } from '../../../../components/common/button/CustomButtons';
import InputMuiCustom from '../../../../components/common/inputs/InputMuiCustom';
import FormControlMui, {
  StyledField,
} from '../../../../components/common/FormControlMui';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import useConfirm from '../../../../components/common/useConfirm';
interface Iprop {
  setIsOpenPopup: Dispatch<SetStateAction<boolean>>;
  standardId?: string | object | null;
  setStandardId: React.Dispatch<React.SetStateAction<null>>;
}

export interface InspectionPart {
  _id?: string;
  part_id?: string;
  drawing_no: any;
  drawing_rev_no: string | null;
  quality_plan_no: any;
  createdAt?: Date;
  moduleData_date: moment.Moment;
}

const CreatePDIStandardPart = ({
  setIsOpenPopup,
  standardId,
  setStandardId,
}: Iprop) => {
  const [moduleData, setModuleData] = useState<InspectionPart>({
    drawing_no: '',
    drawing_rev_no: '',
    quality_plan_no: '',
    moduleData_date: moment(),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [render, setRender] = useState(false);
  const [pdiStandardData, setPdiStandardData] = useState([]);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Incoming Inspection STANDARD',
    `Are you sure you want to'save' this ?`
  );

  const handlePartData = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setModuleData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    axios
      .get('/api/inspectionStandardNewRoute/pdiStandardList/autoComplete')
      .then((res) => {
        setPdiStandardData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  const onSubmit = async (e: any) => {
    e.preventDefault();

    const drawingNoIndex = pdiStandardData.findIndex(
      (item: any) =>
        item.drawing_no.toLowerCase() === moduleData.drawing_no.toLowerCase()
    );
    if (drawingNoIndex !== -1) {
      alert('Please enter different Drawing No.');
      return;
    }
    const qualityPlanNoIndex = pdiStandardData.findIndex(
      (item: any) =>
        item.quality_plan_no.toLowerCase() ===
        moduleData.quality_plan_no.toLowerCase()
    );
    if (qualityPlanNoIndex !== -1) {
      alert('Please enter different Quality Plan No.');
      return;
    }

    const ans = await confirmSubmit();
    if (!ans) return;
    setIsLoading(true);
    await axios
      .post('/api/inspectionStandardNewRoute', moduleData)
      .then((res) => {
        setIsLoading(false);
        setIsOpenPopup(false);
        setStandardId(null);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Box>
        <FormControlMui handleSubmit={onSubmit}>
          <div
            className="btn_save"
            style={{
              position: 'absolute',
              right: '140px',
              top: '-0.6px',
            }}
          >
            <SubmitButton label={'submit'} type="submit" disabled={isLoading} />
          </div>
          <StyledField>
            <InputMuiCustom
              type="text"
              name="drawing_no"
              placeholder="Drawing Number"
              required={true}
              onChange={handlePartData}
              value={moduleData?.drawing_no || ''}
            />
            <InputMuiCustom
              type="text"
              name="quality_plan_no"
              required={true}
              placeholder="Quality Plan Number"
              onChange={handlePartData}
              value={moduleData?.quality_plan_no || ''}
            />
            <InputMuiCustom
              type="text"
              name="drawing_rev_no"
              placeholder="Drawing Rev No."
              onChange={handlePartData}
              value={moduleData?.drawing_rev_no || ''}
            />

            <DatePickerMui
              label="Incoming Inspection Standard Date"
              onChange={(date) => {
                moduleData.moduleData_date = date;
                setRender((prev) => !prev);
              }}
              value={moment(moduleData.moduleData_date) || null}
            />
          </StyledField>
        </FormControlMui>
      </Box>
      <DialogSubmit />
    </>
  );
};

export default CreatePDIStandardPart;
