import React, { useEffect, useState } from 'react';
import GridInputs from '../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import useConfirm from '../../components/common/useConfirm';
import CustomUploadButton from '../../components/common/button/CustomUploadButton';

const Create = ({
  id,
  setIsModal,
  setCustomerId,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setCustomerId: any;
}) => {
  const [moduleData, setModuleData] = useState<any>({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Customer' : 'Create Customer'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/masterFormatRoute/${id}`)
        .then((res) => setModuleData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleCustomerData(e: React.ChangeEvent<HTMLInputElement>) {
    setModuleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    setIsSubmit(true);
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/masterFormatRoute/${id}`, moduleData)
        .then((res) => {
          setIsSubmit(false);
          setCustomerId(null);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/masterFormatRoute', moduleData)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
          setCustomerId(null);
        })
        .catch((err) => {
          setIsSubmit(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'format_name'}
        name={'format_name'}
        html_for={'format_name'}
        label_name={'Format Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.format_name || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remarks:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.remarks || ''}
        onChange={handleCustomerData}
        last_child={2}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
