import { TableInput } from '../../../../../components/common';

interface Ifoot {
  moduleData: any;
  setRender: any;
  isView: any;
}
const getTotalPlanQty = (processes: any) => {
  return processes.reduce(
    (sum: any, process: any) => sum + +process?.total_produced_qty,
    0
  );
};
const getTotalOkQty = (processes: any) => {
  return processes.reduce(
    (sum: any, process: any) => sum + +process?.total_ok_qty,
    0
  );
};
const getTotalRejectionQty = (processes: any) => {
  return processes.reduce(
    (sum: any, process: any) => sum + +process?.total_reason_qty,
    0
  );
};
const getProdAvg = (processes: any) => {
  const total_ok_qty = getTotalOkQty(processes);
  const total_plan_qty = getTotalPlanQty(processes);
  return ((total_ok_qty * 100) / total_plan_qty).toFixed(2);
};
const TFoot = ({ moduleData, setRender, isView }: Ifoot) => {
  return (
    <tfoot style={{ background: '#94d82d' }}>
      <tr>
      <th colSpan={4} align="left">
          Prepared By:{' '}
          <TableInput
            isView={isView}
            name="report_prepared_by"
            type="text"
            value={moduleData?.report_prepared_by}
            onChange={(e) => {
              moduleData.report_prepared_by = e.target.value;
              setRender((prev: any) => !prev);
            }}
          />
        </th>
      
        <th colSpan={4} align="left">
          Verified By:{' '}
          <TableInput
            isView={isView}
            name="report_verified_by"
            type="text"
            value={moduleData?.report_verified_by}
            onChange={(e) => {
              moduleData.report_verified_by = e.target.value;
              setRender((prev: any) => !prev);
            }}
          />
        </th>
        <th colSpan={4} align="left">
          Approved By:
          <TableInput
            isView={isView}
            name="report_approved_by"
            type="text"
            value={moduleData?.report_approved_by}
            onChange={(e) => {
              moduleData.report_approved_by = e.target.value;
              setRender((prev: any) => !prev);
            }}
          />
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
