import axios from 'axios';
import { useState, useEffect, useCallback } from 'react';
import useConfirm from '../../../../components/common/useConfirm';
import { useQuery } from '../../../../hooks/UseQuery';
import { useAuth } from '../../../../services/auth/AuthProvider';
import { apiConstant } from './constant';
import useDebounce from '../../../../hooks/useDebounce';

const useGetData = (id: any) => {
  const [moduleData, setModuleData] = useState<any[]>([]);
  const [headerTopicData, setHeaderTopicData] = useState<any[]>([]);
  const [selectionArray, setSelectionArray] = useState<any[]>([]);
  const [isSyncRequired, setIsSyncRequired] = useState<boolean>(false);
  const [empData, setEmpData] = useState<any[]>([]);
  const search = useQuery('search') || '';
  const date = useQuery('date');
  const year_range = useQuery('year_range');
  const department_id = useQuery('department_id');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [triggerRender, setTriggerRender] = useState(false);
  const [Sync, confirmSync] = useConfirm(
    'SYNC',
    'Are you sure you want to sync? Save any changes before sync!'
  );
  const [DeleteConfirm, handleDeleteConfirm] = useConfirm(
    'Delete!!!💀',
    'Are you sure you want to Delete? This action is not reversable!'
  );

  const handelSync = async () => {
    const ans = await confirmSync();
    if (!ans) return;
    setIsLoading(true);
    axios
      .patch(`/api/${apiConstant.patch}/${id}/${department_id}/${year_range}/${date}`)
      .then((res) => {
        window.alert('Sync Succesfully');
        setTriggerRender((prev) => !prev);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleBulkDeleteFeature = useCallback(
    (index: number, isSelected: boolean, rowId: string) => {
      setSelectionArray((currentSelectionArray: any) => {
        const newSelectionArray = [...currentSelectionArray];
        newSelectionArray[index] = isSelected ? rowId : null;
        return newSelectionArray;
      });
    },
    []
  );

  const updateModuleData = useCallback(
    (
      updateType: 'row-list' | 'row',
      rowIndex: number | null,
      key: string,
      newValues: any,
      list_idx: number | null
    ) => {
      setModuleData((prev: any) => {
        const updatedModuleData: any = [...prev];
        if (updateType === 'row') {
          //specific row that needs updating
          if (rowIndex === null) return;
          const updatedRow = { ...updatedModuleData[rowIndex] };
          updatedRow[key] = newValues?.[key] || null;
          updatedModuleData[rowIndex] = updatedRow;
        }
        //specific row list that needs updating
        else if (updateType === 'row-list') {
          if (rowIndex === null || list_idx === null) return;
          const updatedRow = { ...updatedModuleData[rowIndex] };
          if (!updatedRow.list) updatedRow.list = [];
          // Update the specific list [item]:value within the row
          updatedRow.list[list_idx] = {
            ...(updatedRow.list[list_idx] || {}),
            ...newValues,
          };
          updatedModuleData[rowIndex] = updatedRow;
        }

        return updatedModuleData;
      });
    },
    []
  );

  const addRow = useCallback(() => {
    setModuleData((prev: any) => {
      setSelectionArray(new Array(prev.length + 1).fill(null));
      return [
        ...prev,
        {
          employee_id: null,
          list: [],
          _tempId: `temp-id-${new Date().getTime()}`,
          year_range: year_range,
          date_range: date,
          tni_report_id: id,
        },
      ];
    });
  }, []);

  const handleDelete = async () => {
    const ans = await handleDeleteConfirm();
    if (!ans) return;
    try {
      if (!selectionArray.length) return;
      const excludeId = selectionArray.filter((id) => id);
      const res = await axios.put(
        `/api/${apiConstant.bulkDeleteEmpTniReport}`,
        excludeId
      );
      if (res.status !== 200) return;
      setModuleData((prev: any) => {
        const filteredData = prev.filter((item: any) => {
          const id = item?._id || item?._tempId;
          if (!id) return;
          return !excludeId.includes(id);
        });
        setSelectionArray(new Array(filteredData.length).fill(null));
        return filteredData;
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    axios
      .get(`/api/${apiConstant.empAutocomplete}/${department_id}`)
      .then((res) => {
        setEmpData(res.data.result);
      })
      .catch((err) => console.log(err));
  }, []);

  const fetchData = async () => {
    if (!id) return;
    setIsLoading(true);
    axios
      .get(
        `/api/${apiConstant.getTniTopicAndChildEmps}/${id}?search=${search}&department_id=${department_id}`
      )
      .then((response) => {
        setHeaderTopicData(response?.data?.headerList);
        setModuleData(response?.data?.employees);
        setIsSyncRequired(!response?.data?.isTniTopicAndChidEmpsUptoDate);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err);
      });
  };

  useDebounce(fetchData, 500, [search, triggerRender]);

  return {
    moduleData,
    isLoading,
    error,
    handelSync,
    Sync,
    updateModuleData,
    handleBulkDeleteFeature,
    selectionArray,
    setSelectionArray,
    addRow,
    handleDelete,
    setTriggerRender,
    DeleteConfirm,
    headerTopicData,
    empData,
    fetchData,
    isSyncRequired,
  };
};

export default useGetData;
