import TableCompHead from '../../../../../components/ui/TableCompHead';
import { useQuery } from '../../../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const theadData = [
  'Sr. No.',
  'Date',
  'TIME',
  'Module Name',
  'Abnormal Situation',
  'Produced Quantity',
  'Inspection Status',
  'Disposition',
  'Engineer Sign',
  'Delete',
];

const THead = ({ moduleData, isView }: IProps) => {
  const date = useQuery('annual_date');
  return (
    <thead>
      <TableCompHead
        colSpan={11}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dated']}
        values={['HO-F-QAS-102', '01', '26.08.2013']}
        heading="Abnormal Situation Rejection Report"
      />
      <tr>
        <th colSpan={12} align="left">
          Month : {date}
        </th>
      </tr>
      <tr style={{ background: '#38d9a9' }}>
        {theadData.map((heading, index) => (
          <th
            key={heading + index}
            colSpan={index === 6 ? 2 : 1}
            rowSpan={index === 6 ? 1 : 2}
          >
            {heading}
          </th>
        ))}
      </tr>
      <tr style={{ background: '#38d9a9' }}>
        <th>OK</th>
        <th>Reject</th>
      </tr>
    </thead>
  );
};

export default THead;
