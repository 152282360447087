import axios from 'axios';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const useGetData = () => {
  const { partId, setupId } = useParams();
  const [moduleData, setModuleData] = useState<any>({
    setup_date: new Date(),
    setups: [],
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mouldData, setMouldData] = useState([]);
  const [machineData, setMachineData] = useState([]);

  useEffect(() => {
    axios
      .get('/api/machineRoutes/machine/ForAutocomplete')
      .then((res) => {
        setMachineData(res.data);
      })
      .catch((err) => console.log(err));
    axios
      .get('/api/productionMouldingRoutes/moulderList/moulderForAutoComplete')
      .then((res) => {
        setMouldData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (!partId) return;
    axios
      .get(`/api/setup/${partId}/${setupId}`)
      .then((res) => {
        console.log("module data is",res.data);
        setModuleData(res.data);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }, []);

  return {
    moduleData,
    isLoading,
    error,
    machineData,
    mouldData,
  };
};

export default useGetData;
