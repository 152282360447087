interface Ifoot {
  moduleData: any;
}
const TFoot = ({ moduleData }: Ifoot) => {
  return (
    <tfoot>
      <tr>
        {['A', 'B', 'C', 'D', 'E', 'F', 'J', 'K', 'M'].map((heading, index) => (
          <th key={index + heading}>{heading}</th>
        ))}
        <th
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
          }}
        >
          {['O', 'P', 'S', 'PD', 'KT'].map((item, index) => (
            <div key={index + item} style={{ border: '1px solid black' }}>
              {item}
            </div>
          ))}
        </th>
      </tr>
      <tr>
        {[
          'AIR CUT',
          'AIR BUBBLE',
          'Chemical',
          'BREAKING',
          'EXCESSIVE BUFFING',
          'SHORT FILLING',
          'JOINT',
          'KNOT',
          'MOULDING',
        ].map((heading, index) => (
          <th key={index + heading}>{heading}</th>
        ))}
        <th
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
          }}
        >
          {[
            'OVER CURE',
            'SCRAP ON PART',
            'SEMI CURE',
            'SCREW MARK',
            'BLADE MARK',
          ].map((item, index) => (
            <div key={index + item} style={{ border: '1px solid black' }}>
              {item}
            </div>
          ))}
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
