import { useState } from 'react';
import { TableCompHead } from '../../../../../components/ui';
import { createConstant } from '../helpers/constant';
import { useQuery } from '../../../../../hooks/UseQuery';
import React from 'react';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};

const stickStylesColumn = {
  position: 'sticky',
  border: '1px solid black',
  zIndex: '1',
  height: '50px',
  backgroundColor: '#4F81BD',
  color: '#fff',
} as const;
interface IProps {
  moduleData?: any;
}
const THead = ({ }: IProps) => {
  const yearRange = useQuery('yearRange');
  const plan_date = useQuery('plan_date');
  const isView = useQuery('isView') === 'true' ? true : false;
  const date = new Date(plan_date || '')?.getFullYear().toString().slice(-2);

  return (
    <thead
      style={{
        position: 'sticky',
        width: '100%',
        top: '-.5rem',
        border: '1px solid black',
        zIndex: '10',
        backgroundColor: 'rgb(79, 129, 189)',
        color: '#fff',
      }}
    >
      <TableCompHead
        colSpan={20 + createConstant.extracolSpan}
        rowSpan={3}
        labels={['Doc. No.', 'Rev. No.', 'Rev. Date:']}
        values={['F-HR-02', ' 00', '01.04.2023']}
        heading={`${createConstant.theadHeader} (YEAR : ${yearRange})`}
      />
      {[
        'S. NO.',
        'Department',
        'Training Topic',
        'Faculty',
        'Duration',
        'List of Attendees'
        // 'FREQ',
        // 'STATUS',
      ].map((el, index) => (
        <th
          key={`${index}_head`}
          align="center"
          style={{
            textAlign: 'center',
            zIndex: '10',
            ...style1,
            minWidth: `${(index === 0 && '50px') ||
              (index === 2 && '160px') ||
              (index === 3 && '160px') ||
              (index === 4 && '100px') ||
              (index === 5 && '120px') ||
              // (index === 3 && '180px')
              //  ||
              style1.minWidth
              }`,
            left: `${(index === 0 && '0px') ||
              (index === 1 && '65px') ||
              (index === 2 && '214px') ||
              (index === 3 && '373px') ||
              (index === 4 && '577px') ||
              (index === 5 && '675px')
              // ||
              // (index === 3 && '416px')
              }`,
            ...(index < 6 ? stickStylesColumn : {})
          }}
        >
          {el}
        </th>
      ))}


      {[
        'APR',
        'MAY',
        'JUN',
        'JUL',
        'AUG',
        'SEP',
        'OCT',
        'NOV',
        'DEC',
        'JAN',
        'FEB',
        'MAR',
      ].map((el, index) => (
        <th
          key={`${index}_headcol`}
          align="center"
          style={{ textAlign: 'center', ...style2 }}
        >
          {el}-{index > 8 ? +date + 1 : date}
        </th>
      ))}
      <th style={{ textAlign: 'center', ...style1 }}>Remarks</th>
      <th style={{ textAlign: 'center', ...style1, background: '#f03e3e' }}>
        ACTION
      </th>
    </thead>
  );
};

export default React.memo(THead);
