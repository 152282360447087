import { createConstant } from '../helpers/constant';

interface Ifoot {
  preparedBy: string | null | undefined;
  approvedBy: string | null | undefined;
}
const TFoot = ({ preparedBy, approvedBy }: Ifoot) => {
  return (
    <tfoot>
      <tr>
        <th colSpan={createConstant.extracolSpan} rowSpan={2}>
          LEGENDS
        </th>
        <th rowSpan={2}>PLAN</th>
        <th rowSpan={1} style={{ background: '#ffd43b' }}>
          P
        </th>
        <th colSpan={2} rowSpan={2}>
          ACTUAL
        </th>
        <th rowSpan={1} style={{ background: '#ffd43b' }}>
          A
          {/* P */}
        </th>
        <th rowSpan={2}></th>
        <th colSpan={3} rowSpan={2}>
        RE-PLAN         {/* RE-PLAN */}
        </th>
        <th rowSpan={1} style={{ background: '#00B0F0' }}>
          R
        </th>
        <th rowSpan={2}></th>
        <th colSpan={4} rowSpan={2}>
        {/* RESHEDULED DONE */}
          RE-PLAN DONE
        </th>
        <th rowSpan={1} style={{ background: '#00B0F0' }}>
          R
        </th>
      </tr>
      <tr>
        <th rowSpan={1}></th>
        <th rowSpan={1} style={{ background: '#00B050' }}>
          A
        </th>
        <th colSpan={2} rowSpan={1}></th>
        <th rowSpan={1} style={{ background: '#00B050' }}>
          A
        </th>
      </tr>

      <tr>
        <th colSpan={(17 + createConstant.extracolSpan) / 2} align="left">
          Prepared By: {preparedBy}
        </th>
        <th colSpan={(17 + createConstant.extracolSpan) / 2} align="left">
          Approved By: {approvedBy}
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
