import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
interface IProps {
    moduleData: any;
    updateModuleData: any;
    handleDelete: any;
    uploadImageFile: any;
    moduleId: any;
}

const TController = ({
    handleDelete,
    moduleData,
    updateModuleData,
    moduleId,

    uploadImageFile,
}: IProps) => {
    return (
        <Box>
            <RenderTable>
                <THead {...{ moduleData, updateModuleData }} />
                <TBody
                    {...{
                        handleDelete,
                        moduleId,
                        moduleData,
                        updateModuleData,
                        uploadImageFile,
                    }}
                />
                <TFoot
                {...{ moduleData, updateModuleData }}
                />
            </RenderTable>
        </Box>
    );
};

export default TController;
