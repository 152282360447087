import React, { useEffect, useState } from 'react';
import TController from './components/view/TController';
import { IData } from './helpers/interface';
import { initialState } from './helpers/initialState';
import lodash from 'lodash';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import { useAuth } from '../../../services/auth/AuthProvider';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';

const View = () => {
  const [moduleData, setModuleData] = useState<any>(
    lodash.cloneDeep(initialState)
  );
  const navigate = useNavigate();
  const { id } = useParams();
  const isView = useQuery('isView') === 'true' ? true : false;
  const annual_month = useQuery('annual_date') || '';
  const [render, setRender] = useState(false);

  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?'
  );
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?'
  );
  const [DialogUpdate, confirmUpdate] = useConfirm(
    'Update',
    'Are you sure you want to update this document?'
  );
  const [DialogImport, confirmImport] = useConfirm(
    'Import',
    'Are you sure you want to import this document?'
  );

  useEffect(() => {
    axios
      .get(`/api/breakDownStatus/monthly?annualMonth=${annual_month}`)
      .then((res) => {
        if (res.data) setModuleData(res.data);
      })
      .catch((err) => console.log(err));
  }, [render]);

  useEffect(() => {
    if (moduleData?._id) {
      axios
        .get(`/api/breakDownStatus/${moduleData?._id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [moduleData?._id]);

  const handleSumbit = async (isSubmitted: boolean) => {
    if (moduleData?._id) {
      const ans = await confirmUpdate();
      if (!ans) return;
      await axios
        .put(`/api/breakDownStatus/${moduleData?._id}`, moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('Updated Successfully');
          }
        })
        .catch((err) => console.log(err));
    } else {
      const ans = await confirmSubmit();
      if (!ans) return;
      moduleData.annual_month = annual_month;
      await axios
        .post('/api/breakDownStatus', moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('created Successfully');
            navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleImport = async () => {
    const ans = await confirmImport();
    if (!ans) return;

    try {
      const response = await axios.get(
        `/api/shiftProductionReport/getMonthlyData/getData?annualMonth=${annual_month}`
      );

      response?.data?.forEach((subProcess: any) => {
        const targetId = subProcess?.part_id?._id;
        const finalInspectionReportDate =
          subProcess?.final_inspection_report_date;
        const shift = subProcess?.shift;
        const index = moduleData.processes.findIndex(
          (obj: any) =>
            obj.part_id &&
            obj.part_id._id === targetId &&
            obj.final_inspection_report_date === finalInspectionReportDate &&
            obj.shift === shift
        );

        if (index === -1) {
          moduleData.processes.push({
            ...subProcess,
            ...{ sign_prod_eng: '', sign_maint_eng: '', sign_qa_eng: '' },
          });
        } else {
          moduleData.processes[index] = {
            ...moduleData.processes[index],
            ...subProcess,
          };
        }
      });

      setModuleData({ ...moduleData }); // Update state with a new object reference
      alert('Imported Successfully');
    } catch (error) {
      alert('Shift Production Report Not exist !!!');
      console.error(error);
    }
  };
  const handelDeleteRows = async (rowIdx: number) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    let data = { ...moduleData };
    data.processes.splice(rowIdx, 1);
    setModuleData(data);
  };

  return (
    <>
      <Box>
        <ChildHeader text="BREAK DOWN STATUS">
          {!isView && (
            <>
              <SubmitButton
                label="Import Shift Production Report"
                onClick={() => {
                  handleImport();
                }}
              />
              <SubmitButton
                label={moduleData._id ? 'Update' : 'Submit'}
                onClick={() => handleSumbit(true)}
              />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
          handelDeleteRows,
        }}
      />
      <DialogDeleteRow isSubmitContent={false} />
      <DialogSubmit />
      <DialogImport />
      <DialogUpdate />
    </>
  );
};

export default View;
