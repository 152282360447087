import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
interface IProps {
  moduleData: any;
  isView: boolean;
}

const theadData = [
  'S. No.',
  'Module',
  'Model Name',
  'Plan Qty.',
  'Ok Qty.',
  'Rejection Qty.',
  'Productivity %',
  'Rejection Qty. %',
  'Remarks',
  'Delete',
];

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <thead>
      <TableCompHead
        colSpan={10}
        rowSpan={3}
        labels={['DOC NO.', 'REV.NO.', 'DATE']}
        values={['HO-F-PRD-37', '00', '01.09.2012']}
        heading="DAILY PRODUCTION REPORT"
      />
      <tr>
        <th colSpan={10} align="left" style={{ width: '250px' }}>
          FINAL INSPECTION REPORT DATE :{' '}
          {isView ? (
            formatDate(moduleData.final_inspection_report_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.final_inspection_report_date)}
              onChange={(date: any) => {
                moduleData.final_inspection_report_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr style={{ background: '#38d9a9', height: '60px' }}>
        {theadData.map((heading, index) => (
          <th key={heading + index} style={{ minWidth: '100px' }}>
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
