export const checkListList = {
    childHeader: 'Annual Preventive STATUS',
    addButtonLable: '',
    datePickerLabel: 'Search By Year',
    modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
    getAllStatus: 'annualPreventivePlanNewStatusRoute/getAllSatusByYear',
    upadateListStatus: 'annualPreventivePlanNewStatusRoute/handleStatusUpdate',
    checklistUpload: 'annualPreventivePlanNewStatusRoute/handleUpload',
    checklistDeleteImage: 'annualPreventivePlanNewStatusRoute/handleDeleteImage',
    forAutoComplete: 'machineRoutes/machine/ForAutocomplete',
};

export const navigationConstant = {
    checklistRouter: '/common/annualPreventivePlanNewStatusCheckListCreate',
};
