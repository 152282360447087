import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
import { IData } from '../../helpers/interface';

interface IProps {
  moduleData: IData;
  isView: boolean;
  handleSumbit: any;
  handelDeleteRows: any;
  isOpenPopup: any;
  setIsOpenPopup: any;
  setIsOpenPopupRejections: any;
  setRejectionsIndx: any;
  setPartIdx: any;
  employees: any;
  machineData: any;
  operators: any;
  employeesProd: any;
  employeesQA: any;
  operatorsFinalInspector: any;
}

const TController = ({
  moduleData,
  isView,
  handelDeleteRows,
  setIsOpenPopup,
  setIsOpenPopupRejections,
  setRejectionsIndx,
  setPartIdx,
  employees,
  machineData,
  operators,
  employeesProd,
  employeesQA,
  operatorsFinalInspector,
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
            setIsOpenPopup,
            employees,
            machineData,
            operators,
            employeesProd,
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
            handelDeleteRows,
            setIsOpenPopup,
            setPartIdx,
            setIsOpenPopupRejections,
            setRejectionsIndx,
            employees,
            operators,
            employeesProd,
            employeesQA,
            operatorsFinalInspector,
          }}
        />
        <TFoot moduleData={moduleData} />
      </RenderTable>
    </Box>
  );
};

export default TController;
