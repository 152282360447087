import { useState } from 'react';
import { Grid } from '@mui/material';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';

interface IProps {
  moduleData: any;
  customerData?: any;
  isView: boolean;
  setRender: any;
}

const getShiftHeader = (index: any) => {
  let shift = '';
  if (index == 0) shift = 'A';
  else if (index == 8) shift = 'B';
  else if (index == 16) shift = 'C';

  if (shift) {
    return (
      <tr>
        <th colSpan={13} align="left" style={{ background: '#38d9a9' }}>
          SHIFT {shift}
        </th>
      </tr>
    );
  }
};
const getTotalTargetQty = (processes: any) => {
  return processes.reduce((sum: any, process: any) => {
    return sum + +process.target_qty;
  }, 0);
};
const getTotalProducedQty = (processes: any) => {
  return processes.reduce((sum: any, process: any) => {
    return sum + +process.produced_qty;
  }, 0);
};

const TBody = ({ moduleData, isView, setRender }: IProps) => {
  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <>
              <tr>
                <td style={{ minWidth: '100px', textAlign: 'center' }}>
                  {index + 1}
                </td>
                <th
                  style={
                    moduleData.shift === 'A'
                      ? {
                          minWidth: '100px',
                          textAlign: 'center',
                          background: '#fff5f5',
                        }
                      : moduleData.shift === 'B'
                      ? {
                          minWidth: '100px',
                          textAlign: 'center',
                          background: '#fff9db',
                        }
                      : {
                          minWidth: '100px',
                          textAlign: 'center',
                          background: '#e9fac8',
                        }
                  }
                >
                  {process?.time}
                </th>
                <td>
                  <TableInput
                    isView={isView}
                    name="target_qty"
                    type="number"
                    value={process.target_qty}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      moduleData.total_target_qty = getTotalTargetQty(
                        moduleData.processes
                      );
                      setRender((prev: any) => !prev);
                    }}
                  />
                </td>
                <td>{process?.check_qty}</td>
                <td>{process?.ok_qty}</td>
                <td
                  style={{
                    width: '400px',
                    minWidth: '400px',
                    textAlign: 'center',
                  }}
                >
                  {process?.reasons_for_rej?.map((item: any) => {
                    return item?.reason ? (
                      <span>
                        {item?.reason} - {item?.rej_qty}
                        <br />
                        <hr />
                      </span>
                    ) : null;
                  })}
                </td>
              </tr>
            </>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
