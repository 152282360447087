import React, { useState } from 'react';
import { allmonth } from '../../helpers/utils';
import { TableRowComponent } from './TableRowComponent';

interface IProps {
  moduleData: any;
  handleDelete: any;
  updateModuleData: any;
  machineData?: any;
  uploadImageFile: any;
  moduleId: any;
}
const TBody = ({
  uploadImageFile,
  handleDelete,
  moduleData,
  updateModuleData,
  moduleId,
}: IProps) => {
  console.log("mosuledata is ",moduleData);
  return (
    <tbody>
      {moduleData?.list?.map((item: any, index: any) => (
        <TableRowComponent
          key={item?._id || item?._tempId}
          {...{
            item,
            index,
            moduleId,
            allmonth,
            updateModuleData,
            handleDelete,

            uploadImageFile,
          }}
        />
      ))}
    </tbody>
  );
};

export default React.memo(TBody);
