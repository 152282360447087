import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TFoot from './TFoot';
import THead from './THead';
import { IData } from '../../helpers/interface';
interface IProps {
  moduleData: IData;
  machineData: any;
  departmentData: any;
  isView: boolean;
}

const TController = ({
  moduleData,
  machineData,
  departmentData,
  isView,
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
            departmentData,
            machineData
          }}
        />
     
        <TFoot
          {...{
            moduleData,
            isView,
          }}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
