import { Stack } from '@mui/material';
import {
  CustomButton,
  UpdateButton,
} from '../../../components/common/button/CustomButtons';
import { PersitedDatePicker } from '../../../components/common';
import useConfirm from '../../../components/common/useConfirm';
import { useQuery } from '../../../hooks/UseQuery';
const HeaderCreate = ({ handleUpdate, setModuleData }: any) => {
  const date = useQuery('date');
  const [DialogAddRow, confirmAddRow] = useConfirm(
    'Add Row',
    'Are you sure you want to Add Row?'
  );
  return (
    <>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          pl: 2,
          pb: 1,
          pt: 2,
          gap: 2,
        }}
      >
        <Stack
          direction="row"
          sx={{
            justifyContent: 'start',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <PersitedDatePicker
            label="Search By Year"
            views={['year', 'month']}
          />
        </Stack>

        <Stack
          direction="row"
          sx={{
            gap: 2,
          }}
        >
          <CustomButton
            sx={{
              backgroundColor: '#1c7ed6',
            }}
            onClick={async () => {
              const ans = await confirmAddRow();
              if (!ans) return;
              setModuleData((prev: any) => {
                const value = [
                  ...prev,
                  {
                    month: date && new Date(date).getMonth() + 1,
                    year: date && new Date(date).getFullYear(),
                    date: new Date(),
                  },
                ];
                return value;
              });
            }}
          >
            ADD ROWS
          </CustomButton>

          <UpdateButton
            label="update"
            sx={{ mr: '37px', fontSize: '2rem' }}
            size="large"
            onClick={() => {
              handleUpdate();
            }}
          />
        </Stack>
      </Stack>
      <DialogAddRow />
    </>
  );
};

export default HeaderCreate;
