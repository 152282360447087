import { TableInput } from '../../../../../components/common';
import formatDate from '../../../../../components/common/formatDate';
interface Ifoot {
  preparedBy: string | null | undefined;
  approvedBy: string | null | undefined;
}
const TFoot = ({ preparedBy, approvedBy }: Ifoot) => {
  return (
    <tfoot>
      <tr>
        <th colSpan={4} align="left">
          Inspected By: {approvedBy}
        </th>
        <th colSpan={6} align="left">
          Verified By: {preparedBy}
        </th>
        <th colSpan={2} align="left">
          Store Copy
        </th>
      </tr>


    </tfoot>
  );
};

export default TFoot;
