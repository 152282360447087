// import moment from 'moment';
// import lodash, { forEach } from 'lodash';
// import { useState } from 'react';
// import TableCompHead from '../../../../../components/ui/TableCompHead';
// import DatePickerMui from '../../../../../components/common/DatePickerMui';
// import formatDate from '../../../../../components/common/formatDate';
// import { CustomButton } from '../../../../../components/common/button/CustomButtons';
// import { initialState } from '../../helpers/initialState';
// interface IProps {
//   moduleData: any;
//   isView: boolean;
//   setModuleData:any;
//   x:any;
// }

// const theadData = [
//   'S. No.',
//   'Part Name ',
//   'Part No.',
//   'Defect .',
//   'Qty.',
//   'Reason Of Defect',
//   'Corrective Action ',
//   'Preventive Action ',
//   'Responsibility ',
//   'Target Date ',
//   'Remarks',
//   'ADD ROWS',
// ];
// const theadData2 = [
//   'S. No.',
//   'Name ',
//   'Departmet ',
//   'Designation',
//   'Attendence ( P/ A ) ',
//   'Remarks',
//   'ADD ROWS',

// ];

// const THead = ({ moduleData, isView,setModuleData,x }: IProps) => {
//   const [render, setRender] = useState(false);

//   return (
//     <thead>
//       <TableCompHead
//         colSpan={12}
//         rowSpan={3}
//         labels={['F- PROD-', 'REV.NO.', 'REV.DATE']}
//         values={['0001', '02', '01.03.2022']}
//         heading="REDBIN ANALYSIS REPORT"
//       />
//       <tr>
//         <th colSpan={10} align="left" style={{ width: '250px' }}>
//            DATE :{' '}
//           {isView ? (
//             formatDate(moduleData?.final_inspection_report_date)
//           ) : (
//             <DatePickerMui
//               label=""
//               value={moment(moduleData?.final_inspection_report_date)}
//               onChange={(date: any) => {
//                 moduleData.final_inspection_report_date = date;
//                 setRender((prev) => !prev);
//               }}
//             />
//           )}
//         </th>
//       </tr>
//       <tr style={{ background: '#38d9a9', height: '60px' }}>
//         {theadData.map((heading, index) => (
//           <th key={heading + index} style={{ minWidth: '100px' }}>
//             {index == 11?(
//                   <CustomButton
//                   sx={{
//                     backgroundColor: '#1c7ed6',
//                   }}
//                   onClick={async () => {
//                     // const ans = await confirmAddRow();
//                     // if (!ans) return;
//                     setModuleData((prevModuleData: any) => {
//                       const updatedModuleData = lodash.cloneDeep(prevModuleData);
//                       updatedModuleData.processes.push(lodash.cloneDeep(initialState?.processes[0]));
//                       return updatedModuleData;
//                     });
//                     setRender((prev) => !prev);
//                   }}
//                 >
//                   ADD ROWS
//                 </CustomButton>
//             ):
//             (heading)
//             }
//           </th>
//         ))}
//       </tr>
//       {/* <tr style={{ background: '#38d9a9', height: '60px' }}>
//         {theadData2.map((heading, index) => (
//           <th key={heading + index} colSpan={(index==0 || index==6)?1:2} style={{ minWidth: '100px' }}>
//             {index == 6?(
//                   <CustomButton
//                   sx={{
//                     backgroundColor: '#1c7ed6',
//                   }}
//                   onClick={async () => {
//                     // const ans = await confirmAddRow();
//                     // if (!ans) return;
//                     setModuleData((prevModuleData: any) => {
//                       const updatedModuleData = lodash.cloneDeep(prevModuleData);
//                       updatedModuleData.processes.push(lodash.cloneDeep(initialState?.employees[0]));
//                       return updatedModuleData;
//                     });
//                     setRender((prev) => !prev);
//                   }}
//                 >
//                   ADD ROWS
//                 </CustomButton>
//             ):
//             (heading)
//             }
//           </th>
//         ))}
//       </tr> */}
//     </thead>
//   );
// };

// export default THead;
import moment from 'moment';
import lodash from 'lodash';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { CustomButton } from '../../../../../components/common/button/CustomButtons';
import { initialState } from '../../helpers/initialState';

interface IProps {
  moduleData: any;
  isView: boolean;
  setModuleData: any;
  x: any;
}

const theadData = [
  'S. No.',
  'Part Name ',
  'Part No.',
  'Defect .',
  'Qty.',
  'Reason Of Defect',
  'Corrective Action ',
  'Preventive Action ',
  'Responsibility ',
  'Target Date ',
  'Remarks',
  'ADD ROWS',
];

const theadData2 = [
  'S. No.',
  'Name ',
  'Departmet ',
  'Designation',
  'Attendance ( P/ A ) ',
  'Remarks',
  'ADD ROWS',
];

const THead = ({ moduleData, isView, setModuleData, x }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <thead>
     {x==1 && ( 
      <>
      <TableCompHead
        colSpan={12}
        rowSpan={3}
        labels={['F- PROD-', 'REV.NO.', 'REV.DATE']}
        values={['0001', '02', '01.03.2022']}
        heading="REDBIN ANALYSIS REPORT"
      />
      <tr>
        <th colSpan={2} align="left" style={{ width: '250px' }}>
          DATE :{' '}
          {isView ? (
            formatDate(moduleData?.dailyProduction_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData?.dailyProduction_date)}
              onChange={(date: any) => {
                moduleData.dailyProduction_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={4}  align="left" style={{ width: '250px' }}>
           
        </th>
        <th colSpan={2} align="center"  style={{ width: '250px',fontSize:'20px' }}>
           Countermeasure
        </th>
      </tr>
      </>
    )}
      {x === 1 && (
        <tr style={{ background: '#38d9a9', height: '60px' }}>
          {theadData.map((heading, index) => (
            <th key={heading + index} style={{ minWidth: '100px' }}>
              {index === 11 ? (
                <CustomButton
                  sx={{
                    backgroundColor: '#1c7ed6',
                  }}
                  onClick={async () => {
                    if(!isView){

                      setModuleData((prevModuleData: any) => {
                        const updatedModuleData = lodash.cloneDeep(prevModuleData);
                        updatedModuleData.processes.push(lodash.cloneDeep(initialState?.processes[0]));
                        return updatedModuleData;
                      });
                      setRender((prev) => !prev);
                    }
                  }}
                >
                  ADD ROWS
                </CustomButton>
              ) : (
                heading
              )}
            </th>
          ))}
        </tr>
      )}
      {x === 2 && (
        <tr style={{ background: '#38d9a9', height: '60px' }}>
          {theadData2.map((heading, index) => (
            <th key={heading + index} colSpan={(index === 0 || index === 6) ? 1 : 2} style={{ minWidth: '100px' }}>
              {index === 6 ? (
                <CustomButton
                  sx={{
                    backgroundColor: '#1c7ed6',
                  }}
                  onClick={async () => {
                    if(!isView){

                      setModuleData((prevModuleData: any) => {
                        const updatedModuleData = lodash.cloneDeep(prevModuleData);
                        updatedModuleData.employees.push(lodash.cloneDeep(initialState?.employees[0]));
                        return updatedModuleData;
                      });
                      setRender((prev) => !prev);
                    }
                  }}
                >
                  ADD ROWS
                </CustomButton>
              ) : (
                heading
              )}
            </th>
          ))}
        </tr>
      )}




    </thead>
  );
};

export default THead;
