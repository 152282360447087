export const moduleConstant = {
  apiConstant: `/api/setup`,
  heading: 'FPA / LPA Inspection',
};

export const generateUrl = (
  basePath: string,
  partId: string,
  docId: string,
  isView: boolean
) => {
  return `/${basePath}/setup/${partId}/${docId}?isView=${isView}`;
};

export const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '120px',
  border: '1px solid #343a40',
};
export const style2 = {
  backgroundColor: '#74c0fc',
  // color: '#fff',
  minWidth: '120px',
  border: '1px solid #343a40',
};

export const tableHeaderObj1: any = [
  [
    { text: 'FIRST OFF APPROVAL', style: style2, colspan: 8 },
    { text: 'MID OF Shift', style: style2, colspan: 8 },
    { text: 'LAST OFF APPROVAL', style: style2, colspan: 8 },
  ],
  [
    { text: '( Visual Report )', style: style2, colspan: 4 },
    {
      text: 'M/c Parameter ( 1st . Part Approval )',
      style: style2,
      colspan: 4,
    },
    { text: '( Visual Report )', style: style2, colspan: 4 },
    {
      text: 'M/c Parameter ( MID of Shifts )',
      style: style2,
      colspan: 4,
    },
    { text: '( Visual Report )', style: style2, colspan: 4 },
    {
      text: 'M/c Parameter ( Last Off ) ',
      style: style2,
      colspan: 4,
    },
  ],
  [
    { text: 'Visual Defects', style: style2, colspan: 1 },
    { text: '1st Shot', style: style2 },
    { text: '2nd Shot', style: style2 },
    { text: '3rd Shot', style: style2 },
    { text: 'Process Parameter', style: style2, colspan: 1 },
    { text: 'Method', style: style2, colspan: 1 },
    { text: 'Set Value', style: style2 },
    { text: 'Observed', style: style2 },

    { text: 'Visual Defects', style: style2, colspan: 1 },
    { text: '1st Shot', style: style2 },
    { text: '2nd Shot', style: style2 },
    { text: '3rd Shot', style: style2 },
    { text: 'Process Parameter', style: style2, colspan: 1 },
    { text: 'Method', style: style2, colspan: 1 },
    { text: 'Set Value', style: style2 },
    { text: 'Observed', style: style2 },

    { text: 'Visual Defects', style: style2, colspan: 1 },
    { text: '1st Shot', style: style2 },
    { text: '2nd Shot', style: style2 },
    { text: '3rd Shot', style: style2 },
    { text: 'Process Parameter', style: style2, colspan: 1 },
    { text: 'Method', style: style2, colspan: 1 },
    { text: 'Set Value', style: style2 },
    { text: 'Observed', style: style2 },
  ],
];

export const tableHeaderObj2: any = [
  [
    { text: 'S.No', style: style1, rowspan: 2, colspan: 2 },
    { text: 'Parameter', style: style1, rowspan: 2, colspan: 3 },
    { text: 'Spec', style: style1, rowspan: 2, colspan: 2 },
    { text: 'Checking Method ', style: style1, rowspan: 2, colspan: 2 },
    {
      text: 'OBSERVATION ( Ist Part Approval )',
      style: style1,
      colspan: 5,
    },
    {
      text: 'OBSERVATION ( MID of Shift  )',
      style: style1,
      colspan: 5,
    },
    {
      text: 'Observation ( Last Off )',
      style: style1,
      colspan: 5,
    },
  ],
  [
    ...Array.from({ length: 5 }, (_, i) => ({
      text: i + 1,
      style: style1,
    })),
    ...Array.from({ length: 5 }, (_, i) => ({
      text: i + 1,
      style: style1,
    })),
    ...Array.from({ length: 5 }, (_, i) => ({
      text: i + 1,
      style: style1,
    })),
  ],
];

export const getBackgroundColor = (value: string) => {
  if (value === 'ok') {
    return '#51cf66';
  } else if (value === 'ng') {
    return '#fa5252';
  }
  return 'inherit'; // default color
};
