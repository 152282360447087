import { Grid, MenuItem } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableInput } from '../../../../components/common';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import {
  AddIconButton,
  DeleteIconButton,
  EditIconButton,
  RemoveIconButton,
  ViewIconButton,
} from '../../../../components/common/button/CustomIconButton';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import CustomSelect from '../../../../components/common/SelectField';
import { useQuery } from '../../../../hooks/UseQuery';
import { convToTime } from '../../../../utils/helpers';
import { actionTakens, actionTakenTickMarks, reasons } from '../helpers/data';
import OperationSelect from './OperationSelect';
import ReportsButton from './ReportsButton';

interface IProps {
  process: any;
  moduleData: any;
  isView: boolean;
  handelDeleteRows: (index: number) => void;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setPartIdx: React.Dispatch<React.SetStateAction<number>>;
  onSubmit: () => void;
  pictorial: any;
  setPictorial: any;
  triningFileIdRef: any;
  setIsTrainingFileOpenPopup: any;
  index: number;
  machineData: any;
}
const TBodyRow = ({
  process,
  handelDeleteRows,
  moduleData,
  isView,
  setIsOpenPopup,
  onSubmit,
  setPartIdx,
  pictorial,
  setPictorial,
  setIsTrainingFileOpenPopup,
  triningFileIdRef,
  index,
  machineData,
}: IProps) => {
  const [render, setRender] = useState(false);
  const navigate = useNavigate();
  const annual_month = useQuery('annual_date') || '';
  return (
    <tr>
      <td>{index + 1}</td>
      <td>
        {process.change_type && (
          <DatePickerMui
            sx={{ width: '150px' }}
            onChange={(date) => {
              if (process.change_type) {
                let currentDate = new Date(date).getDate();
                let prevDate = new Date(process.date).getDate();
                let values: any = { ...pictorial };
                if (values[process.change_type][prevDate - 1]) {
                  values[process.change_type][prevDate - 1] = false;
                }
                values[process.change_type][currentDate - 1] = true;
                setPictorial(values);
              }
              process.date = date;
              setRender((prev) => !prev);
            }}
            value={moment(process.date || null)}
          />
        )}
      </td>
      <td>
        {isView ? (
          convToTime(moduleData.time)
        ) : (
          <TimePicker
            value={moment(process.time || null)}
            onChange={(date) => {
              process.time = date;
              setRender((prev) => !prev);
            }}
          />
        )}
      </td>
      <td>
        {isView ? (
          process.shift
        ) : (
          <CustomSelect
            name="shift"
            value={process.shift || ''}
            onChange={(e) => {
              process.shift = e.target.value;
              setRender((prev) => !prev);
            }}
          >
            <MenuItem value="A">A</MenuItem>
            <MenuItem value="B">B</MenuItem>
            <MenuItem value="C">C</MenuItem>
          </CustomSelect>
        )}
      </td>
      <td style={{ width: '250px', minWidth: '250px' }}>
        {isView ? (
          process?.part_id && process?.part_id?.part_name
        ) : (
          <Grid container xs={12} direction="row" alignItems={'center'}>
            <Grid item xs={10}>
              <p
                style={{
                  fontSize: '1.2rem',
                }}
              >
                {typeof process?.part_id === 'object' &&
                process?.part_id !== null
                  ? process?.part_id?.part_name
                  : process?.partName || ''}
                {!process?.part_id && 'Pick Part Name'}
              </p>
            </Grid>

            {process.part_id ? (
              <Grid item xs={2}>
                <RemoveIconButton
                  tooltipTitle="remove part"
                  onClick={() => {
                    process.part_id = null;
                    process.partNumber = null;
                    process.partName = null;
                    setRender((prev) => !prev);
                  }}
                />
              </Grid>
            ) : (
              <Grid item xs={2}>
                <AddIconButton
                  tooltipTitle="add part"
                  onClick={() => {
                    setIsOpenPopup(true);
                    setPartIdx(index);
                  }}
                />
              </Grid>
            )}
          </Grid>
        )}
      </td>
      <td>
        <CustomSelect
          name="change_type"
          key="change_type"
          value={process?.change_type}
          onChange={(e) => {
            if (process.date) {
              let prevDate = new Date(process.date).getDate();
              let values: any = { ...pictorial };
              if (values?.[process.change_type]?.[prevDate - 1]) {
                values[process.change_type][prevDate - 1] = false;
              }
              setPictorial(values);
            }
            process.change_type = e.target.value;
            process.date = null;
            setRender((prev) => !prev);
          }}
        >
          <MenuItem value="man">Man</MenuItem>
          <MenuItem value="machine">Machine</MenuItem>
          <MenuItem value="material">Material</MenuItem>
          <MenuItem value="method">Method</MenuItem>
        </CustomSelect>
      </td>
      <td style={{ minWidth: '200px' }}>
        <AutocompleteMuiCustom
          id="Machine"
          label=""
          option_name="machine_no"
          arrayofObj={machineData}
          value={process.machine_id || ''}
          onChange={(e, value) => {
            process.machine_id = value;
            setRender((prev) => !prev);
          }}
        />
      </td>
      <td style={{ minWidth: '200px' }}>
        <OperationSelect
          {...{ process, isView, setRender }}
          partId={
            typeof process.part_id === 'string'
              ? process?.part_id
              : process?.part_id?._id
          }
        />
      </td>
      <td style={{ minWidth: '150px' }}>
        <CustomSelect
          name="reason"
          value={process?.reason}
          onChange={(e) => {
            process.reason = e.target.value;
            process.action_taken = '';
            setRender((prev) => !prev);
          }}
        >
          {reasons[process.change_type] &&
            reasons[process.change_type].map((reason: any) => (
              <MenuItem value={reason.value}>{reason.name}</MenuItem>
            ))}
        </CustomSelect>
      </td>
      <td style={{ minWidth: '150px' }}>
        {actionTakens[process?.reason] && (
          <CustomSelect
            name="reason"
            key="reason"
            value={process?.action_taken}
            onChange={(e) => {
              process.action_taken = e.target.value;
              setRender((prev) => !prev);
            }}
          >
            <MenuItem>None</MenuItem>
            {actionTakens[process.reason].map((action: any) => (
              <MenuItem value={action.value}>{action.name}</MenuItem>
            ))}
          </CustomSelect>
        )}
      </td>
      <td>
        {process.reason && process.action_taken
          ? actionTakenTickMarks[`${process?.reason}${process?.action_taken}`]
              ?.planned
          : actionTakenTickMarks[`${process?.reason}`]?.planned}
      </td>
      <td>
        {process.reason && process.action_taken
          ? actionTakenTickMarks[`${process?.reason}${process?.action_taken}`]
              ?.unplanned
          : actionTakenTickMarks[`${process?.reason}`]?.unplanned}
      </td>
      <td>
        {process.reason && process.action_taken
          ? actionTakenTickMarks[`${process?.reason}${process?.action_taken}`]
              ?.change_situation
          : actionTakenTickMarks[`${process?.reason}`]?.change_situation}
      </td>
      <td>
        {process.reason && process.action_taken
          ? actionTakenTickMarks[`${process?.reason}${process?.action_taken}`]
              ?.checkpoint
          : actionTakenTickMarks[`${process?.reason}`]?.checkpoint}
      </td>
      <td>
        {process.reason && process.action_taken
          ? actionTakenTickMarks[`${process?.reason}${process?.action_taken}`]
              ?.checkpoint_status
          : actionTakenTickMarks[`${process?.reason}`]?.checkpoint_status}
      </td>
      <td>
        {process.reason && process.action_taken
          ? actionTakenTickMarks[`${process?.reason}${process?.action_taken}`]
              ?.abnormal
          : actionTakenTickMarks[`${process?.reason}`]?.abnormal}
      </td>
      <td>
        {process.reason && process.action_taken
          ? actionTakenTickMarks[`${process?.reason}${process?.action_taken}`]
              ?.procedure
          : actionTakenTickMarks[`${process?.reason}`]?.procedure}
      </td>
      <td>
        <TableInput
          isView={isView}
          style={{ width: '120px' }}
          name="total_parts_made"
          type="number"
          value={process.total_part_made}
          onChange={(e) => {
            process.total_part_made = e.target.value;
            setRender((prev) => !prev);
          }}
        />
      </td>
      <td>
        <TableInput
          isView={isView}
          style={{ width: '120px' }}
          name="retro_ok"
          type="number"
          value={process.retro_ok}
          onChange={(e) => {
            process.retro_ok = e.target.value;
            setRender((prev) => !prev);
          }}
        />
      </td>
      <td>
        <TableInput
          isView={isView}
          style={{ width: '120px' }}
          name="retro_ng"
          type="number"
          value={process.retro_ng}
          onChange={(e) => {
            process.retro_ng = e.target.value;
            setRender((prev) => !prev);
          }}
        />
      </td>
      <td>
        <TableInput
          isView={isView}
          style={{ width: '120px' }}
          name="suspected_ok"
          type="number"
          value={process.suspected_ok}
          onChange={(e) => {
            process.suspected_ok = e.target.value;
            setRender((prev) => !prev);
          }}
        />
      </td>
      <td>
        <TableInput
          isView={isView}
          style={{ width: '120px' }}
          name="suspected_ng"
          type="number"
          value={process.suspected_ng}
          onChange={(e) => {
            process.suspected_ng = e.target.value;
            setRender((prev) => !prev);
          }}
        />
      </td>
      <td>
        <TableInput
          isView={isView}
          style={{ width: '120px' }}
          name="batch_code"
          type="text"
          value={process.batch_code}
          onChange={(e) => {
            process.batch_code = e.target.value;
            setRender((prev) => !prev);
          }}
        />
      </td>
      <td style={{ minWidth: '100px' }}>
        {process.part_id && (
          <ReportsButton {...{ process, onSubmit }} reportKey="setup" />
        )}
      </td>
      <td style={{ minWidth: '100px' }}>
        {process.part_id && (
          <ReportsButton {...{ process, onSubmit }} reportKey="retro" />
        )}
      </td>
      <td style={{ minWidth: '100px' }}>
        {process.part_id && (
          <ReportsButton {...{ process, onSubmit }} reportKey="suspected" />
        )}
      </td>
      <td style={{ minWidth: '100px' }}>
        <EditIconButton
          tooltipTitle="edit"
          onClick={async () => {
            await onSubmit();
            navigate(
              `/common/trainingRecord/${process?.training_record_id?._id}?rowId=${process._id}&annual_month=${annual_month}`,
            );
          }}
        />
      </td>
      <td>
        {process?.training_record_id?.files?.length > 0 && (
          <>
            <ViewIconButton
              tooltipTitle="view"
              sx={{ marginLeft: '1.5rem' }}
              onClick={() => {
                triningFileIdRef.current =
                  process?.training_record_id?._id || '';
                setIsTrainingFileOpenPopup(true);
              }}
            />
          </>
        )}
      </td>
      <td>
        <TableInput
          isView={isView}
          style={{ width: '120px' }}
          name="prd_sign"
          type="text"
          value={process.prd_sign}
          onChange={(e) => {
            process.prd_sign = e.target.value;
            setRender((prev) => !prev);
          }}
        />
      </td>
      <td>
        <TableInput
          isView={isView}
          style={{ width: '120px' }}
          name="qa_sign"
          type="text"
          value={process.qa_sign}
          onChange={(e) => {
            process.qa_sign = e.target.value;
            setRender((prev) => !prev);
          }}
        />
      </td>
      <td>
        <TableInput
          isView={isView}
          style={{ width: '120px' }}
          name="am_sign"
          type="text"
          value={process.am_sign}
          onChange={(e) => {
            process.am_sign = e.target.value;
            setRender((prev) => !prev);
          }}
        />
      </td>
      <td style={{ minWidth: '100px' }}>
        {!isView && (
          <DeleteIconButton
            tooltipTitle={'delete'}
            onClick={() => handelDeleteRows(index)}
          />
        )}
      </td>
    </tr>
  );
};

export default TBodyRow;
