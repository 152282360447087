import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
import { IData } from '../../helpers/interface';
import { useState } from 'react';

interface IProps {
  moduleData: IData;
  isView: boolean;
  handleSumbit: any;
  autoCompleteMachineValue: any;
  handelDeleteRows: any;
  isOpenPopup: any;
  setIsOpenPopup: any;
  setPartIdx: any;
  def:any;
  setModuleData:any
  setIsOpenPopupRejections:any;
  setRejectionsIndx:any;
 
  
}
let x=1;
const TController = ({  moduleData,
  isView,
  handleSumbit,
  autoCompleteMachineValue,
  handelDeleteRows,
  isOpenPopup,
  setIsOpenPopup,
  setPartIdx,
  setModuleData,
  def,
  setIsOpenPopupRejections,
          setRejectionsIndx,
          
   }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
            setModuleData,
            x: 1,
          }}
        />
        <TBody
          {...{
            setRender,
            moduleData,
            isView,
            setModuleData,
            def,
            handleSumbit,
            setIsOpenPopupRejections,
            setRejectionsIndx,
            autoCompleteMachineValue,
            handelDeleteRows,
            isOpenPopup,
            setIsOpenPopup,
            setPartIdx,
            x:1
          }}
        />

        <THead
          {...{
            moduleData,
            isView,
            setModuleData,
            x: 2,
          }}
        />

        <TBody
          {...{
            setRender,
            moduleData,
            isView,
            setModuleData,
            def,
            handleSumbit,
            setIsOpenPopupRejections,
            setRejectionsIndx,
            autoCompleteMachineValue,
            handelDeleteRows,
            isOpenPopup,
            setIsOpenPopup,
            setPartIdx,
            x:2
          }}
        />
        <TFoot moduleData={moduleData} setRender={setRender} isView={isView} />
      </RenderTable>
    </Box>
  );
};

export default TController;
