import { Box } from '@mui/material';
import { RenderTable } from '../../../../components/ui';
import TBody from './TBody';
// import TFoot from './TFoot';
import THead from './THead';
import { IData } from '../../helpers/interface';
import TFoot from './TFoot';
// import { useEffect, useState } from 'react';
// import axios from 'axios';

interface IProps {
  moduleData: IData;
  isView: boolean;
  handleSumbit: any;
  customerData: any;
  handelDeleteRows: any;
  isOpenPopup: any;
  setIsOpenPopup: any;
  setIsOpenPopupRejections: any;
  setRejectionsIndx: any;
  setPartIdx: any;
}

const TController = ({
  moduleData,
  customerData,
  isView,
  handelDeleteRows,
  isOpenPopup,
  setIsOpenPopup,
  setIsOpenPopupRejections,
  setRejectionsIndx,
  setPartIdx,
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
          }}
        />
        <TBody
          {...{
            moduleData,
            customerData,
            isView,
            handelDeleteRows,
            // isOpenPopup,
            setIsOpenPopup,
            setPartIdx,
            setIsOpenPopupRejections,
            setRejectionsIndx,
          }}
        />
        <TFoot
          {...{
            moduleData,
            isView,
          }}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
