export const listConstant = {
  childHeader: 'Skill Evaluation plan list',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'Skill Evaluation plan list',
  theadHeader: 'Skill Evaluation Plan',
  extracolSpan: 0,
};

export const apiConstant = {
  // list api url
  gettableListDataurl: 'skillEvaluationPlanRoute/monthlylist',
  deleteListurl: 'skillEvaluationPlanRoute',
  getListPlanByid: 'skillEvaluationPlanRoute',
  createListPlanByid: 'skillEvaluationPlanRoute',

  // -----plan--
  sync: 'skillEvaluationPlanRoute/handleSync',
  getAllPlanById: 'skillEvaluationPlanRoute/allplansbyid',
  upadateCreateAllPlan: 'skillEvaluationPlanRoute/allplansCreateUpdate',
  maintenancePlanDeleteBulkById:
    'skillEvaluationPlanRoute/maintenancePlanDeleteBulkById',
  forAutoComplete:
    'operator/operatorListByDesignation/operatorForAutoComplete',
  forAutoComplete1:
    'productionWorkersRoutes/workersList/workersForAutoComplete',
    deptForAutoComplete:
    'department/departmentAll/ForAutocomplete',
  machineLineAutoComplete: 'lineRoute/forAutocomplete',
  machineAutoComplete: 'machineRoutes/machine/ForAutocomplete/line',
  plantLocationAutoComplete: 'plantLocationRoute/autoComplete',
};

export const settingPanelConstant = {
  // settingPanel setting for plan
  crudApi: 'skillEvaluationPlanSettingRoute',
  docType: 'skillEvaluationPlan',
};

export const tilesConstant = {
  childHeaderText: 'Skill Evaluation Plan',
};

export const navigationConstant = {
  planRouter: '/common/skillEvaluationPlanCreate',
};
