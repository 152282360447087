import { BsFillPeopleFill } from 'react-icons/bs';
import { CgUserList } from 'react-icons/cg';
import { FcDepartment } from 'react-icons/fc';
import { ImUserTie } from 'react-icons/im';
import { MdModelTraining, MdOutlineDomainAdd } from 'react-icons/md';
import { GrUserWorker, GrVmMaintenance } from 'react-icons/gr';
import { IoIosPaper } from 'react-icons/io';

const tiles = [
  {
    sNo: 1,
    link: '/admin/masterlistofdepartment?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    p: 'Master List Of',
    span: 'Department',
    icon: <MdOutlineDomainAdd size="5rem" color="#343a40" />,
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/admin/employee_list?filter=createdAt',
    isLink: true,
    p: 'MASTER LIST OF',
    span: 'EMPLOYEE',
    isIcon: true,
    icon: <ImUserTie size="5rem" color="#1864ab" />,
  },
  {
    sNo: 1,
    link: '/common/jobDescHome',
    isLink: true,
    clauseNo: 5,
    icon: <CgUserList size="5rem" color="#135089" />,
    p: 'JOB',
    span: 'DESCRIPTION',
    isIcon: true,
  },
  // {
  //   sNo: 1,
  //   link: '/common/employeeList',
  //   isLink: true,
  //   clauseNo: 5,
  //   icon: <BsFillPeopleFill size="5rem" color="#a12222" />,
  //   p: 'MASTER LIST OF',
  //   span: 'EMPLOYEE (Staff)',
  //   isIcon: true,
  // },
  // {
  //   sNo: 2,
  //   link: '/common/departmentList',
  //   isLink: true,
  //   clauseNo: 5,
  //   p: 'MASTER LIST OF',
  //   span: 'DEPARTMENT',
  //   icon: <FcDepartment size="5rem" color="#135089" />,
  //   isIcon: true,
  // },
  {
    sNo: 2,
    link: '/common/productionWorkersList?filter=createdAt',
    isLink: true,
    icon: <GrUserWorker size="5rem" color="#a12222" />,
    p: 'Master List of',
    span: 'Workers',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/masterlistoffaculty?filter=createdAt',
    isLink: true,
    p: 'Master List Of',
    span: 'FACULTY',
    isIcon: true,
    icon: <ImUserTie size="5rem" color="#135089" />,
  },
  {
    sNo: 4,
    link: '/common/skillEvaluationSheetList',
    isLink: true,
    p: 'SET QUESTION',
    span: 'PAPERS',
    isIcon: true,
    icon: <IoIosPaper size="5rem" color="#343a40" />,
  },
  {
    sNo: 5,
    link: '/common/tnihome',
    isLink: true,
    p: 'TRAINING',
    span: 'NEED IDENTIFICATION',
    isIcon: true,
    icon: <MdModelTraining size="5rem" color="#087f5b" />,
  },
  {
    sNo: 6,
    // link: '/common/operatorObservancePlanTiles',
    link: '/common/skillEvaluationPlanTiles',
    isLink: true,
    clauseNo: 4,
    icon: <GrVmMaintenance size="5rem" color="#343a40" />,
    p: 'Skill Evaluation',
    span: 'Plan',
    isIcon: true,
  },
  {
    sNo: 7,
    // link: '/common/operatorObservancePlanTiles',
    link: '/common/trainingPlanTiles',
    isLink: true,
    clauseNo: 4,
    icon: <GrVmMaintenance size="5rem" color="rgb(0 122 244)" />,
    p: 'Training',
    span: 'Plan',
    isIcon: true,
  },
];

export default tiles;
