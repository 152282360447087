import { IModule } from '../../interface';

interface IProps {
  isView: boolean;
  moduleData: IModule;
}
const TFoot = ({ isView, moduleData }: IProps) => {
  return (
    <tfoot>
      <tr>
        <th colSpan={6} align="left">
          PREPARED BY: {moduleData.report_prepared_by}
        </th>
        <th colSpan={6} align="left">
          APPROVED BY:{moduleData.report_approved_by}
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
