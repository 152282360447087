import { Box, Divider, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Loader } from '../../components/common/Loader';
import {
  CustomButton,
  SaveButton,
  CloseButton,
  SubmitButton,
} from '../../components/common/button/CustomButtons';
import ChildHeader from '../../components/ui/ChildHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '../../hooks/UseQuery';
import useConfirm from '../../components/common/useConfirm';
import PartDetailsHeader from '../../components/ui/PartDeatailsHeader';
import AutocompleteMuiCustom from '../../components/common/AutocompleteMuiCustom';
import axios from 'axios';
import InputMuiCustom from '../../components/common/inputs/InputMuiCustom';
import AlertMui from '../../components/common/AlertMui';
import TController from './components/TController';
import formatDate from '../../components/common/formatDate';
import moment from 'moment';
import { RiFileExcel2Line } from 'react-icons/ri';
import DatePickerMui from '../../components/common/DatePickerMui';

const CreateEdit = () => {
  let { partId, id } = useParams();
  const partName = useQuery('part_name');
  const partNumber = useQuery('part_number');
  const [loader, setLoader] = useState<boolean>(false);
  const navigate = useNavigate();

  const [DialogClose, confirmClose] = useConfirm(
    'CLOSE',
    'Are you sure you want to leave this page?'
  );
  const [spcData, setSpcData] = useState<any>({
    part_id: '',
    machine_id: null,
    process_id: '',
    supplier: '',
    spec_id: '',
    customer: '',
    specification: '',
    control_product: '',
    process_name: '',
    sample_size: '',
    instrument: '',
    l_count: '',
    no_of_dec: null,
    spc_array: Array.from({ length: 125 }, () => null),
  });
  const [process, setProcess] = useState([]);
  const [render, setRender] = useState(false);
  const [spec, setSpec] = useState([]);
  const [alert, setAlert] = useState(false);
  const [alertforSubmmit, setAlertforSubmmit] = useState(false);
  const [autoCompleteProcess, setAutoCompleteProcess] = useState<any>('');
  const [autoCompleteSpec, setAutoCompleteSpec] = useState<any>('');
  const [machineData, setMachineData] = useState<any>([]);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Supplier' : 'Create Supplier'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  useEffect(() => {
    axios
      .get('/api/machineRoutes/machine/ForAutocomplete')
      .then((res) => {
        setMachineData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await axios.get(`/api/spcRoute/controlplan/${partId}`);
        spcData.customer = res.data.customer;
        spcData.supplier = res.data.supplier;
        setProcess(res.data.processes);

        //
        if (id) {
          const dataspc: any = await axios.get(`/api/spcRoute/${id}`);
          setSpcData(dataspc.data);
          let getProcess = res.data.processes.filter((item: any) => {
            return item._id === dataspc.data.process_id;
          });
          getProcess = getProcess[0];
          const getSpec = getProcess?.specs.filter((item: any) => {
            return item._id === dataspc.data.spec_id;
          });
          setAutoCompleteProcess(getProcess);
          setSpec(getProcess.specs);
          setAutoCompleteSpec(getSpec[0]);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, [id]);

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;

    if (
      !autoCompleteSpec?.charecteristics ||
      !autoCompleteProcess?.process_name
    ) {
      setAlert(true);
      return;
    }

    spcData.specification = autoCompleteSpec?.charecteristics;
    spcData.control_product = autoCompleteSpec?.control_product;
    spcData.process_name = autoCompleteProcess?.process_name;
    spcData.part_id = partId || null;

    if (id) {
      await axios
        .put(`/api/spcRoute/${id}`, spcData)
        .then((res) => {
          window.alert('succesfully submitted');
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/spcRoute', spcData)
        .then((res) => {
          navigate(
            `/admin/createEditSpc/${partId}/${res.data.id}?part_name=${partName}&part_number=${partNumber}`
          );
          window.alert('succesfully submitted');
        })
        .catch((err) => console.log(err));
    }
  };

  const excelDownload = () => {
    if (
      !autoCompleteSpec?.charecteristics ||
      !autoCompleteProcess?.process_name
    ) {
      setAlert(true);
      return;
    }
    axios
      .post(
        `/api/spcRoute/editExcelRoute`,
        {
          details: {
            data: spcData,
            partName: partName,
            partNumber: partNumber,
            Date: formatDate(spcData?.date),
          },
        },
        { responseType: 'blob' }
      )
      .then((response) => {
        const blob = new Blob([response.data]);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `SPC ${partName} ${partNumber} ${
            spcData?.process_name
          } ${moment().format('dddd, MMMM Do YYYY, h:mm:ss a')}.xlsx`
        );
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <Box>
      <Loader loader={loader} />
      <Box>
        <ChildHeader text="STATISTICAL PROCESS CONTROL STUDY">
          <CustomButton
            color="primary"
            sx={{
              margin: '10px',
              backgroundColor: '#f8f9fa',
              color: '#343a40',
            }}
            icon={<RiFileExcel2Line />}
            disabled={false}
            size="large"
            onClick={() => excelDownload()}
          >
            Excel Download
          </CustomButton>
          <SubmitButton
            label="Sumbit"
            onClick={() => {
              handleSumbit();
            }}
          />
          <CloseButton
            label="Close"
            onClick={async () => {
              const ans = await confirmClose();
              if (!ans) return;
              navigate(-1);
            }}
          />
        </ChildHeader>
        <PartDetailsHeader
          partName={partName || ''}
          partNumber={partNumber || ''}
        />

        <Box sx={{ flexGrow: 1, width: '98%', margin: '2rem auto' }}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <DatePickerMui
                label="Date"
                value={moment(spcData.date || null)}
                onChange={(date) => {
                  spcData.date = date;
                  setRender((prev) => !prev);
                }}
              />
            </Grid>

            <Grid item xs={2}>
              <InputMuiCustom
                type="string"
                name="part_no"
                variant={'outlined'}
                placeholder={'Part No.'}
                value={partNumber || ''}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={2}>
              <InputMuiCustom
                type="string"
                name="part_name"
                variant={'outlined'}
                placeholder={'Part Name'}
                value={partName || ''}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <AutocompleteMuiCustom
                id={'process_name'}
                label="OPERATION/PROCESS NAME"
                option_name={'process_name'}
                value={autoCompleteProcess}
                arrayofObj={process}
                onChange={(e, value) => {
                  value?.process_name
                    ? (spcData.process_id = value._id)
                    : (spcData.process_id = '');
                  setAutoCompleteProcess(value);
                  value?.specs ? setSpec(value.specs) : setSpec([]);
                  setAutoCompleteSpec('');
                  setRender((prev) => !prev);
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <AutocompleteMuiCustom
                id={'charecteristics'}
                label={'SPECIFIC. / PARAMETER'}
                option_name={'charecteristics'}
                value={autoCompleteSpec}
                arrayofObj={spec}
                onChange={(e, value) => {
                  value?._id
                    ? (spcData.spec_id = value._id)
                    : (spcData.spec_id = '');
                  setAutoCompleteSpec(value);
                  spcData.instrument = value?.instrument;
                  spcData.usl = value?.utl;
                  spcData.lsl = value?.ltl;
                  spcData.control_product = value?.control_product;
                  spcData.sample_size = value?.sample_size;
                  setRender((prev) => !prev);
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 0.2 }}>
            <Grid item xs={3}>
              <InputMuiCustom
                type="string"
                name="part_name"
                variant={'outlined'}
                placeholder={'Tolerance'}
                value={autoCompleteSpec?.control_product}
                disabled={true}
              />
            </Grid>
            <Grid item xs={3}>
              <InputMuiCustom
                variant={'outlined'}
                type="text"
                name="instrument"
                placeholder={'Instrument'}
                value={spcData.instrument}
              />
            </Grid>
            <Grid item xs={3}>
              <InputMuiCustom
                variant={'outlined'}
                type="text"
                name="sample_size"
                placeholder={'Sample Size'}
                value={spcData.sample_size}
              />
            </Grid>
            <Grid item xs={3}>
              <AutocompleteMuiCustom
                id="Machine"
                label={'MACHINE'}
                option_name="machine_no"
                arrayofObj={machineData}
                value={spcData.machine_id || ''}
                onChange={(e, value) => {
                  spcData.machine_id = value;
                  setRender((prev) => !prev);
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 0.2 }}>
            <Grid item xs={4}>
              <InputMuiCustom
                variant={'outlined'}
                type="text"
                name="prepared_by"
                placeholder={'Prepared By'}
                value={spcData.prepared_by}
                onChange={(e) => {
                  spcData.prepared_by = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <InputMuiCustom
                variant={'outlined'}
                type="text"
                name="approved_by"
                placeholder={'Approved By'}
                value={spcData.approved_by}
                onChange={(e) => {
                  spcData.approved_by = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box sx={{ flexGrow: 1, width: '98%', margin: '2rem auto' }}>
          <TController {...{ spcData }} />
        </Box>
        <AlertMui
          alert={alert}
          message={'operarion and specification are empty'}
          setAlert={setAlert}
          severity={'warning'}
        />
        <AlertMui
          alert={alertforSubmmit}
          message={'Remember to submit any changes to see the updated sheet.'}
          setAlert={setAlertforSubmmit}
          severity={'info'}
        />
      </Box>
      <DialogClose isSubmitContent={false} />
      <DialogSubmit isSubmitContent={false} />
    </Box>
  );
};

export default CreateEdit;
