import { renderDateViewCalendar } from '@mui/x-date-pickers';
import InteractiveIcon from '../../../../../components/common/InteractiveIcon';
import useConfirm from '../../../../../components/common/useConfirm';
import React, { useState } from 'react';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import moment from 'moment';
import { useQuery } from '../../../../../hooks/UseQuery';

interface IProps {
  item: any;
  isView: boolean;
  index: number;
  allmonth: any;
  updateModuleData: any;
  moduleData: any;
}

const PlanType = {
  isPlan: 'P',
  isReplanPlan: 'R',
};

const TBodyRight = ({
  item,
  isView,
  index,
  allmonth,
  updateModuleData,
  moduleData,
}: IProps) => {
  const [DialogisCheck, setDialogisCheck] = useConfirm(
    'Chnage Plan',
    'Are you sure you want to Change Plan?'
  );
  // const [render, setRender] = useState(false);
  // const year = new Date(moduleData.date_range).getFullYear();
  const yearRange = useQuery('yearRange');
  const [startYear, endYear] = yearRange ? yearRange.split('-') : [2022, 2023];



  // const handleDoubleClick = async (event: any, month: string) => {
  //   if (event.detail === 2) {
  //     const ans = await setDialogisCheck();
  //     if (!ans) return;

  //     // Determine the new state for `p`
  //     let newP = '';
  //     if (item[month].p === '') {
  //       newP = PlanType.isPlan;
  //     } else if (item[month].p === PlanType.isPlan) {
  //       newP = PlanType.isReplanPlan;
  //     }

  //     updateModuleData(index, month, { p: newP });
  //   }
  // };
  const handleDateClick = async (date: any, month: any, idx: any) => {
    if (date && moment(date).isValid()) {
      console.log("date is ",date);
      updateModuleData(index, month, { p: true, created_date: date });
    } else {
      delete item[month].doneDate;
      updateModuleData(index, month, {
        p: false,
        created_date: date,
        isTask: false,
      });
    }
  };
  const getLastDayOfMonth = (year: number, month: number) => {
    return moment(new Date(year, month, 0)).date();
  };

  const allNewMonth = [
    ['january', 1, startYear],
    ['february', 2, startYear ],
    ['march', 3, startYear],
    ['april', 4, startYear],
    ['may', 5, startYear],
    ['june', 6, startYear],
    ['july', 7, startYear],
    ['august', 8, startYear],
    ['september', 9, startYear],
    ['october', 10, startYear],
    ['november', 11, startYear],
    ['december', 12, startYear],
  ];
  

  // console.log("year is and date is ",startYear,allNewMonth[0][1],allNewMonth[0][2])
  return (
    <>
      {/* {allmonth.map((month: string) => (
        <td
          style={{
            padding: '0px',
            margin: '0px',
          }}
          onClick={(e) => handleDoubleClick(e, month)}
        >
          <InteractiveIcon
            isYellow={item[month].p === PlanType.isPlan}
            isBlue={item[month].p === PlanType.isReplanPlan}
          >
            {item[month].p}
          </InteractiveIcon>
        </td>
      ))}
      <DialogisCheck isSubmitContent={false} /> */}
      {allNewMonth.map((month: any) => (
        // console.log()
        <td
          style={{
            padding: '0px',
            margin: '0px',
            backgroundColor: `${item[month[0]]?.created_date ? '#ffd43b' : '#f1f3f5'}`,
            // backgroundColor: `${item[month[0]]?.p ? '#ffd43b' : '#f1f3f5'}`,
          }}
        >
          <DatePickerMui
            label=""
            sx={{
              width: '150px',
              '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
            minDate={moment(`${month[2]}-${month[1]}-1`, 'YYYY-MM-DD')}
            maxDate={moment(`${month[2]}-${month[1]}-${getLastDayOfMonth(
              month[2],
              month[1]
            )}`, 'YYYY-MM-DD')}
            value={moment(item[month[0]]?.created_date || null)}
            onChange={(date: any) => {
              handleDateClick(date, month[0], index);
            }}
          />
        </td>
      ))}
      <DialogisCheck isSubmitContent={false} />
    </>
  );
};

export default React.memo(TBodyRight);
