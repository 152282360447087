import moment from 'moment';
import lodash from 'lodash';

export const processes = [
  {
    s_no: null,
    process_description: '',
    item: '',
    specification: '',
    observation: Array.from({ length: 5 }, () => ''),
    status: '',
    remarks: '',
  },
];

export const initialState = {
  customer_name: null,
  part_name: null,
  part_number: null,
  ec_level: null,
  drr_no: null,
  alp_code: null,
  date: moment(),
  model: null,
  vendor_code: null,
  processes: Array.from({ length: 5 }, () => ({
    ...lodash.cloneDeep(processes[0]),
  })),
  qty_checked: null,
  qty_ok: null,
  qty_rejected: null,
  disposition: null,
  checked_by: null,
  approved_by: null,
};
