export const initialState = {
  vender_name: '',
  mateiral_description: '',
  alternate_source_1: '',
  alternate_source_2: '',
  original_source: '',
  vender_code: '',
  product_service: '',
  address: '',
  contact_person: '',
  contact_no: '',
  email_id: '',
  lead_time: '',
  inducted_on_date: null,
  qms_certification_iso_iatf: '',
  qms_certification_valid_upto_date: null,
  remarks: '',
  audit_due_date: null,
  score_last_audit: null,
  status: '',
  files: [],
};
