import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from 'chart.js';

import { Bar, Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

interface IProps {
  apiData: any;
  // year: number;
}
const ParetoChart = ({ apiData }: IProps) => {
  const chartData: any = {
    labels: apiData.map((item: { defectName: any }) => item.defectName),
    datasets: [
      {
        label: 'Defects Count',
        data: apiData.map((item: { freq: any }) => item.freq),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
      {
        label: 'Cumulative Percentage',
        data: apiData.map((item: { cumPerc: any }) => item.cumPerc),
        type: 'line',
        borderColor: 'rgba(255, 99, 132, 0.6)',
        fill: false,
        yAxisID: 'y-axis-percentage',
      },
    ],
  };

  const chartOptions: any = {
    plugins: {
      maintainAspectRatio: false,
      title: {
        display: true,
        text: '',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Defects',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Defects Count',
        },
      },
      'y-axis-percentage': {
        beginAtZero: true,
        position: 'right',
        title: {
          display: true,
          text: 'Cumulative Percentage',
        },
        grid: {
          display: false,
        },
        max: 100,
      },
    },
  };

  return (
    <div>
      <Bar data={chartData} options={chartOptions} />
    </div>
  );
};

export default ParetoChart;
