import React, { useEffect, useState } from 'react';
import TController from './components/view/TController';
import { IData } from './helpers/interface';
import { initialState } from './helpers/initialState';
import lodash from 'lodash';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import { useQuery } from '../../../hooks/UseQuery';
import PartTable from './components/view/PartTable';
import ModalCustom from '../../../components/common/Modal';
import useConfirm from '../../../components/common/useConfirm';

const View = () => {
  const [moduleData, setModuleData] = useState<any>(
    lodash.cloneDeep(initialState)
  );

  const [partIdx, setPartIdx] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const isView = useQuery('isView') === 'true' ? true : false;
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [shift, setShift] = useState('');

  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?'
  );
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?'
  );
  const [DialogUpdate, confirmUpdate] = useConfirm(
    'Update',
    'Are you sure you want to update this document?'
  );
  const [DialogImport, confirmImport] = useConfirm(
    'Import',
    'Are you sure you want to import this document?'
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/twoHourlyProductionReport/${id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const handleSumbit = async (isSubmitted: boolean) => {
    if (id) {
      const ans = await confirmUpdate();
      if (!ans) return;
      await axios
        .put(`/api/twoHourlyProductionReport/${id}`, moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('Updated Successfully');
          }
        })
        .catch((err) => console.log(err));
    } else {
      const ans = await confirmSubmit();
      if (!ans) return;
      await axios
        .post('/api/twoHourlyProductionReport', moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('created Successfully');
            navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleImport = async (date: any, partId: any, shift: any) => {
    if (!partId) {
      alert('Please select part');
      return;
    }
    if (!date) {
      alert('Please select date');
      return;
    }

    const ans = await confirmImport();
    if (!ans) return;

    try {
      const response = await axios.get(
        `/api/finalInspectionReport/getTwoHourlyData/getReasonCount?date=${date}&partId=${partId}&shift=${shift}`
      );

      const {
        reasons,
        shift_engr_name,
        total_reason_qty,
        quantity,
        total_ok_qty,
        total_check_qty,
        total_rework_qty,
        total_scrap_aa_qty,
        total_scrap_bb_qty,
        total_scrap_cc_qty,
        total_scrap_dd_qty,
      } = response.data;
      // Assuming moduleData.processes is the correct property name
      moduleData.processes.forEach((process: any, index: any) => {
        if (reasons[index]) {
          process.reasons_for_rej = reasons[index];
          process.check_qty = quantity[index].check_qty;
          process.ok_qty = quantity[index].ok_qty;
          process.scrap_aa_qty = quantity[index].scrap_aa_qty;
          process.scrap_bb_qty = quantity[index].scrap_bb_qty;
          process.scrap_cc_qty = quantity[index].scrap_cc_qty;
          process.scrap_dd_qty = quantity[index].scrap_dd_qty;
        }
      });
      moduleData.shift_engr_name = shift_engr_name;
      moduleData.total_reason_qty = total_reason_qty;
      moduleData.total_ok_qty = total_ok_qty;
      moduleData.total_check_qty = total_check_qty;
      moduleData.total_rework_qty = total_rework_qty;
      moduleData.total_scrap_aa_qty = total_scrap_aa_qty;
      moduleData.total_scrap_bb_qty = total_scrap_bb_qty;
      moduleData.total_scrap_cc_qty = total_scrap_cc_qty;
      moduleData.total_scrap_dd_qty = total_scrap_dd_qty;
      setModuleData({ ...moduleData }); // Update state with a new object reference
      alert('Imported Successfully');
    } catch (error) {
      alert('Final Inspection Report Not exist !!!');
      console.error(error);
    }
  };
  const handelDeleteRows = async (rowIdx: number) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    let data = { ...moduleData };
    // data.processes.splice(rowIdx, 1);
    setModuleData(data);
  };

  return (
    <>
      <Box>
        <ChildHeader text="HOURLY PRODUCTION REPORT ">
          {!isView && (
            <>
              <SubmitButton
                label="Import Final Inspection Report"
                onClick={() => {
                  handleImport(
                    moduleData.final_inspection_report_date,
                    moduleData._id
                      ? moduleData?.part_id?._id
                      : moduleData?.part_id,
                    moduleData.shift
                  );
                }}
              />
              <SubmitButton
                label={moduleData._id ? 'Update' : 'Submit'}
                onClick={() => handleSumbit(true)}
              />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          customerData,
          isView,
          handleSumbit,
          isOpenPopup,
          setIsOpenPopup,
          setPartIdx,
          setShift,
        }}
      />

      <ModalCustom
        title="Part List"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <PartTable
          {...{
            moduleData,
          }}
        />
      </ModalCustom>
      <DialogDeleteRow isSubmitContent={false} />
      <DialogSubmit />
      <DialogImport />
      <DialogUpdate />
    </>
  );
};

export default View;
