import React, { useEffect, useState } from 'react';
import TController from './TController';
import StackedBarChart from './StackedBarChart';
import { GraphHead } from '../../../../../../components/common/GraphHeading';
import AutocompleteMuiCustom from '../../../../../../components/common/AutocompleteMuiCustom';
interface IProps {
  apiData: any;
  year: number;
  title1?: any;
}
const Summary = ({ apiData, year, title1 }: IProps) => {
  return (
    <div>
      <TController apiData={apiData} year={year} />
      <GraphHead style={{ width: '80vw', margin: '14rem' }}>
        <p>{title1}</p>
        <StackedBarChart apiData={apiData} year={year} />
      </GraphHead>
    </div>
  );
};

export default Summary;
