import axios from 'axios';
import { useState, useEffect } from 'react';
import useConfirm from '../../../../components/common/useConfirm';
import { useQuery } from '../../../../hooks/UseQuery';
import { useAuth } from '../../../../services/auth/AuthProvider';
import { moduleConstant } from '../constant';

const useGetData = () => {
  const date = useQuery('date');
  const [moduleData, setModuleData] = useState<any>({
    month: date && new Date(date).getMonth() + 1,
    year: date && new Date(date).getFullYear(),
    list: [],
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [render, setParentRender] = useState(false);
  const [renderforUpdate, setRenderforUpdate] = useState(false);
  const [firstAutoCompleteData, setFirstAutoCompleteData] = useState([]);

  useEffect(() => {
    axios
      .get('/api/masterlistinstrument/instrumentList/instumentForAutoComplete')
      .then((res) => {
        setFirstAutoCompleteData(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (!date) return;
    const handleApi = async () => {
      setIsLoading(true);
      axios
        .get(`${moduleConstant.apiConstant}/${date}`)
        .then((response) => {
          setModuleData(response?.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setModuleData({
            month: date && new Date(date).getMonth() + 1,
            year: date && new Date(date).getFullYear(),
            moduleDate: date && new Date(date),
            list: [],
          });
          setError(err);
        });
    };

    handleApi();
    setIsLoading(false);
  }, [date, renderforUpdate]);

  return {
    error,
    moduleData,
    setModuleData,
    isLoading,
    firstAutoCompleteData,
    setParentRender,
    setRenderforUpdate,
  };
};

export default useGetData;
