import React, { useCallback, useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';

import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
import { SearchBar } from '../../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  CustomIconButton,
} from '../../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import { IData } from './helpers/Interface';
import { GrWorkshop } from 'react-icons/gr';
import { TbHandClick } from 'react-icons/tb';
import { CircleIndicator } from '../../../components/ui/module.styled';
import { useNavigate } from 'react-router-dom';
import { navigationConstant } from './helpers/contant';
import TniModalTable from './components/TniModalTable';

const tableHeaderObj = [
  [
    { text: 'S.No.', rowspan: 1, colspan: 1 },
    { text: 'Operator Code', rowspan: 1, colspan: 1 },
    { text: 'Operator Name', rowspan: 1, colspan: 1 },
    { text: 'Father / Husband Name', rowspan: 1, colspan: 1 },
    { text: 'Gender', rowspan: 1, colspan: 1 },
    { text: 'Department', rowspan: 1, colspan: 1 },
    { text: 'Designation', rowspan: 1, colspan: 1 },
    { text: 'Floor', rowspan: 1, colspan: 1 },
    { text: 'TNI', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [
  { value: 'createdAt', name: 'A-Z' },
  { value: 'operator_name', name: 'Operator Name' },
];

const List = () => {
  const [isOpenTniModal, setIsOpenTniModal] = useState(false);
  const [departmentId, setDepartmentId] = useState<any>(null);
  const [data, setData] = useState<any[]>([]);
  const [moduleId, setModuleId] = useState<string | null>(null);

  // const [data, setData] = useState<IData[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [defectId, setDefectId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const search = useQuery('search') || '';
  const filter = useQuery('filter') || 'createdAt';
  const page = useQuery('page') || 1;
  const navigate = useNavigate();

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE WORKER',
    'Are you sure you want to delete this?'
  );

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/productionWorkersRoutes/?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteDefect = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/productionWorkersRoutes/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, search, render, isModal, filter]);

  const handleNavigateTniTopic = useCallback(
    (id: any, empId: any, isView: boolean) => {
      navigate(
        `${navigationConstant.empTniTopicCreateRoute}/${id}?operator_id=${empId}&isView=${isView}`,
      );
    },
    [navigate],
  );
  return (
    <>
      <ChildHeader text="WORKERS LIST">
        <AddButton onClick={() => setIsModal(true)} label="New Worker" />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search" />
        <FilterBy options={filterOptions} />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item?.operator_code}</TableCell>
                  <TableCell align="center">{item?.operator_name}</TableCell>
                  <TableCell align="center">
                    {item?.father_husband_name}
                  </TableCell>
                  <TableCell align="center">{item?.gender}</TableCell>
                  <TableCell align="center">
                    {item?.department_id?.department_name}
                  </TableCell>
                  <TableCell align="center">
                    {item?.designation_id?.designation}
                  </TableCell>
                  <TableCell align="center">{item?.floor}</TableCell>
                  <TableCell align="center">
          <Stack
            direction="row"
            gap={0.5}
            sx={{ justifyContent: 'center', alignItems: 'center' }}
          >
            {item?.emp_tni_topic_id ? (
              <CustomIconButton
                sx={{ p: 0, m: 1 }}
                tooltipTitle="TOPIC SHEET"
                onClick={() => {
                  handleNavigateTniTopic(
                    item?.emp_tni_topic_id,
                    item?._id,
                    false
                  );
                }}
              >
                <GrWorkshop style={{}} />
              </CustomIconButton>
            ) : (
              <CustomIconButton
                sx={{ p: 0, m: 1 }}
                tooltipTitle="pic topic"
                onClick={() => {
                  setModuleId(item?._id || '');
                  setDepartmentId(item?.department_id?._id);
                  setIsOpenTniModal(true);
                }}
              >
                <TbHandClick style={{ color: '#087f5b' }} />
              </CustomIconButton>
            )}

            {item?.emp_tni_topic_id ? (
              <CircleIndicator width="15px" height="15px"></CircleIndicator>
            ) : (
              <CircleIndicator
                width="15px"
                height="15px"
                opacity="0"
              ></CircleIndicator>
            )}
          </Stack>
        </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          setDefectId(item?._id || '');
                          setIsModal(true);
                        }}
                      />

                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          deleteDefect(item?._id || '');
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isOpenTniModal}
        closeModal={() => {
          setIsOpenTniModal(false);
        }}
        title="TNI"
      >
        <TniModalTable departmentId={departmentId} empId={moduleId} />
      </ModalCustom>
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setDefectId(null);
          setIsModal(false);
        }}
        title="WORKERS"
      >
        <Create id={defectId} setIsModal={setIsModal} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
