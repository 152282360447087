import moment from 'moment';
import lodash from 'lodash';

export const process = {
  check_qty:null,
  ok_qty:null,
  reject_qty:null,
  rework_qty:null,
  scrap_qty:null,
  inspector: null,
  prod_shift_engr:null,
  qa_shift_engr:null,
  cross_verified:null,
  inspector_id:null,
  prod_qa_eng_id:null,
  prod_shift_eng_id:null,
  reasons_for_rej: Array.from({ length: 10 }, () => lodash.cloneDeep({reason: null,rej_qty: null})),
}

export const processes = [
  {
    time:'8 AM - 9 AM',
    ...lodash.cloneDeep(process),
  },
  {
    time:'9 AM - 10 AM',
    ...lodash.cloneDeep(process),
  },
  {
    time:'10 AM - 11 AM',
    ...lodash.cloneDeep(process),
  },
  {
    time:'11 AM - 12 PM',
    ...lodash.cloneDeep(process),
  },
  {
    time:'12 PM - 1 PM',
    ...lodash.cloneDeep(process),
  },
  {
    time:'1 PM - 2 PM',
    ...lodash.cloneDeep(process),
  },
  {
    time:'2 PM - 3 PM',
    ...lodash.cloneDeep(process),
  },
  {
    time:'3 PM - 4 PM',
    ...lodash.cloneDeep(process),
  },
  {
    time:'4 PM - 5 PM',
    ...lodash.cloneDeep(process),
  },
  {
    time:'5 PM - 6 PM',
    ...lodash.cloneDeep(process),
  },
  {
    time:'6 PM - 7 PM',
    ...lodash.cloneDeep(process),
  },
  {
    time:'7 PM - 8 PM',
    ...lodash.cloneDeep(process),
  },
  {
    time:'8 PM - 9 PM',
    ...lodash.cloneDeep(process),
  },
  {
    time:'9 PM - 10 PM',
    ...lodash.cloneDeep(process),
  },
  {
    time:'10 PM - 11 PM',
    ...lodash.cloneDeep(process),
  },
  {
    time:'11 PM - 12 AM',
    ...lodash.cloneDeep(process),
  },
  {
    time:'12 AM - 1 AM',
    ...lodash.cloneDeep(process),
  },
  {
    time:'1 AM - 2 AM',
    ...lodash.cloneDeep(process),
  },
  {
    time:'2 AM - 3 AM',
    ...lodash.cloneDeep(process),
  },
  {
    time:'3 AM - 4 AM',
    ...lodash.cloneDeep(process),
  },
  {
    time:'4 AM - 5 AM',
    ...lodash.cloneDeep(process),
  },
  {
    time:'5 AM - 6 AM',
    ...lodash.cloneDeep(process),
  },
  {
    time:'6 AM - 7 AM',
    ...lodash.cloneDeep(process),
  },
  {
    time:'7 AM - 8 AM',
    ...lodash.cloneDeep(process),
  },
];

export const initialState = {
  part_id: null,
  module_date: null,
  shiftA:false,
  shiftB:false,
  shiftC:false,
  employee_id:null,
  machine_id:null,
  machine_two_id:null,
  machine_three_id:null,
  shift_engr_name:null,
  flag:null,
  operator_id: null,
  operator_shiftB_id: null,
  processes: processes,
};
