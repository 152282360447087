import { Production } from '../pages/ProductionPage';
import {
  InhouseTiles,
  ProcessRejectionTiles,
} from '../pages/processRejectionSheet';
import {
  DailyProduction,
  CreateDailyProduction,
} from '../pages/ProductionPage/dailyProductionRotor';
import {
  ProcessRejectionList,
  ProcessRejectionCreate,
} from '../pages/processRejectionSheet/processRejection';
import {
  DailyProductionStrokes,
  CreateDailyProductionStrokes,
} from '../pages/ProductionPage/dailyProductionStrokes';
import {
  DailyProductionRedBin,
  CreateDailyProductionRedBin,
} from '../pages/ProductionPage/dailyProductionRedBin';
import {
  HourlyProductionReportList,
  CreateHourlyProductionReport,
} from '../pages/ProductionPage/HourlyProductionReport';

import {
  DailyRejectionRotorList,
  CreateDailyRejectionRotor,
} from '../pages/ProductionPage/dailyProductionRotorDailyRejection';
import PDCSection from '../pages/ProductionPage/PDCSection';
import PressShop from '../pages/ProductionPage/PressShop';
import RotorSection from '../pages/ProductionPage/RotorSection';
import { Profile } from '../pages/profile';
import CNCSection from '../pages/ProductionPage/CNCSection';
import {
  CNCRedBinList,
  CreateViewCNCRedBin,
} from '../pages/ProductionPage/redBinCNC';

import {
  PressShopRedBinList,
  CreateViewPressShopRedBin,
} from '../pages/ProductionPage/redBinPressShop';
import {
  RotorSectionRedBinList,
  CreateViewRotorSectionRedBin,
} from '../pages/ProductionPage/redBinRotorSection';
import {
  DailyHourlyCNC,
  CreateDailyHourlyCNC,
} from '../pages/ProductionPage/dailyProdCNCMachining';
import UnderConstructionPage from '../underconstruction-page';
import { ClauseUploadList } from '../pages/clauseUpload';
import {
  PdcHome,
  PressShopHome,
  RotorHome,
  AbnormalRejectionReportList,
  AbnormalRejectionReportCreate,
} from '../pages/clause3';
// clause 8 modules
import {
  AbnormalityLogBook,
  CreateAbnormalityLogBook,
} from '../pages/abnormalityLogBook';
import {
  BopTableList,
  BopCreate,
  PirTiles,
  MigList,
  MigCreate,
  CreateWeldShopPir,
  WeldShopPirList,
  PressShopList,
  PressShopCreate,
  PlatingPowderCoatingList,
  PlatingPowderCoatingCreate,
} from '../pages/pir';
import { VendirList } from '../pages/RIDeparment/MasterListOfVendor';
import {
  ProcessControlStandardCreate,
  ProcessControlStandardList,
} from '../pages/processControlStandard';
import {
  CreateForMSetup,
  CreateForMSummary,
  SummaryTableList,
  CreateHoldCard,
  TrainingRecord,
} from '../pages/4m';
import {
  SpcPlanTiles,
  SpcPlanPlanList,
  SpcPlanPlanCreate,
  SpcPlanChecklistList,
  MasterListofSpcPlan,
} from '../pages/spcPlan';
import { CreateEditMsa, MSAList } from '../pages/MSA';
import { ScrapNoteList, CreateScrapNote } from '../pages/scrapNote';
import {
  ChecklistList,
  Clause15Home,
  CreateInstrumentVerificationSheet,
  InstrumentHistoryCard,
  InstrumentMasterList,
  MasterListLocationList,
  MsaChecklistList,
  MsaPlanCreate,
  MsaPlanList,
  PlanCreate,
  PlanList,
  TilesCallibration,
  TilesMsa,
  CalibrationPlanNewTiles,
  CalibrationPlanNewPlanList,
  CalibrationPlanNewPlanCreate,
  CalibrationPlanNewStatus,
  CalibrationPlanNewStatusCheckListCreate,
  CalibrationPlanMasterNewChecklist,
  CalibrationPlanMasterNewChecklistCreate,
} from '../pages/clause15';

import {
  CreateInpsectionStandard,
  InspectionStandardList,
  RIDeparmentHome,
  RIPart,
  SupplierChecklist,
  SupplierPlanCreate,
  SupplierPlanList,
  SupplierDocument,
  SupplierDocumentHome,
  VendorList,
  SupplierQualityRatingList,
  SupplierQualityRatingCreate,
  SupplierDefectMonitoringSheetList,
  ListOfDefects,
  SupplierDefectMonitoringSheetDashboardTabs,
  ProblemticPartInspectionReport,
  SupplierMonitoringVendorList,
  SupplierMonitoring,
  CreateInspectionStandardNew,
  InspectionStandardNewList,
} from '../pages/RIDeparment';
import {
  InspectionReport,
  InspectionReportList,
} from '../pages/inspectionReportImperial';
import ProcessRejectionDashboardTabs from '../pages/processRejectionSheet/rejectionNoteDashboard/RejectionAnalysisDashboardTabs';
import ProcessRejectionDashBoardList from '../pages/processRejectionSheet/rejectionNoteDashboard/TableList';
import { SamplingStd } from '../pages/RIDeparment/samplingStd';
import {
  SupplierAuditPlanTiles,
  SupplierAuditPlanPlanList,
  SupplierAuditPlanPlanCreate,
  SupplierAuditPlanStatus,
  SupplierAuditPlanChecklist,
  SupplierAuditPlanChecklistCreate,
  SupplierAuditPlanStatusCheckList,
} from '../pages/RIDeparment/supplierAuditPlan';
import {
  CallibrationPlanTiles,
  CallibrationPlanList,
  CallibrationPlanCreate,
  CallibrationPlanChecklistList,
  CallibrationPlanChecklistCreate,
} from '../pages/clause15/callibrationPlan';
import { MushinHelpDesk } from '../pages/Mushin_Help_Desk';

import ToolsList from '../pages/masterlisofTools/List';
import ToolsHistory from '../pages/masterlisofTools/toolsHistory/CreateToolHistory';
// import { BreakDownSlipList, CreateBreakDownSlip } from '../pages/breakDownSlip';

import DragTable from '../pages/cp/test/DragTable';
import ToolbarCustomTest from '../pages/cp/test/ToolbarCustomTest';
import {
  Clause5,
  DepartmentList,
  DesignationList,
  EmployeeList,
  JobDescHome,
  FacultyList,
  SkillEvaluationSheetList,
  JDCreate,
  JDList,
  SkillEvaluationSheetCreate,
  TniTiles,
  TraningTopicsList,
  TraningTopicsCreateEdit,
  TniReportList,
  TniReportCreate,
  SkillEvaluationPlanTiles,
  SkillEvaluationPlanList,
  SkillEvaluationPlanCreate,
  SkillEvaluationPlanStatus,
  SkillEvaluationPlanStatusCheckListCreate,
  SkillEvaluationPlanMasterChecklist,
  SkillEvaluationPlanMasterChecklistCreate,
  TrainingPlanTiles,
  TrainingPlanList,
  TrainingPlanCreate,
  TrainingPlanStatus,
  TrainingPlanStatusCheckListCreate,
  TrainingPlanMasterChecklist,
  TrainingPlanMasterChecklistCreate,
  EmpTraningTopicsCreateEdit,
} from '../pages/clause5page';
import {
  CreateEditLayoutInspectionReport,
  LayoutInspectionReportList,
} from '../pages/layoutInspectionReport';
import {
  CreateEditInprocessRejectionChallan,
  InprocessRejectionChallanList,
} from '../pages/inprocessRejectionChallan';
import {
  CreateDailyLineRejectionReport,
  CreateIncomingCompileInspectionReport,
  CreateRedTrollyRejectionReport,
  CreateRejectionChallanReport,
  ReworkLotRejectionReport,
} from '../pages/incomingCompileInspection';
import {
  ClipOutsertPlanTiles,
  ClipOutsertPlanList,
  ClipOutsertPlanCreate,
  ClipOutsertPlanChecklistList,
  ClipOutsertPlanChecklistCreate,
} from '../pages/RIDeparment/clipOutsertPlan';
import { MasterFormatExcel } from '../pages/masterFormat';
import {
  ProductionDeptHome,
  DailProdReportList,
  CreateDailProdReport,
  DailyProdReportDashboard,
  ProductionDefectsList,
  FinalInspectionReportList,
  CreateFinalInspectionReport,
  TwoHourlyProductionReportList,
  CreateTwoHourlyProductionReport,
  MouldingList,
  RedbinAnalysis,
  ShiftProductionReportList,
  CreateShiftProductionReport,
  CreateDailyProductionReport,
  CreateDailyProductionRedbinReport,
  DailyProductionReportList,
  DailyProductionReportDashboard,
  BreakDownStatusList,
  CreateBreakDownStatus,
  PrevantiveMaintenance_NEW_Tiles,
  PrevantiveMaintenance_NEW_PlanList,
  PrevantiveMaintenance_NEW_PlanCreate,
  PrevantiveMaintenance_NEW_Status,
  PrevantiveMaintenance_NEW_Checklist,
  PrevantiveMaintenanceStatusCheckList,
  PrevantiveMaintenance_NEW_ChecklistCreate,
  ProductionWorkersList,
  BreakDownSlipList, 
  CreateBreakDownSlip,
  MouldHistory
} from '../pages/productionDept';
import CreateMouldHistory from '../pages/productionDept/productionMoulding/CreateMouldHistory';
import {
  ProcessList,
  ModelsList,
  InprocessInspectionReportListkml,
  ScrapNoteReportList,
  CreateScrapNoteReport,
  CreateInprocessInspectionReportkml,
  DrawingManagementList
} from '../pages/clause4Page';

import {
  CreatePokaYokeChkSht,
  PokaYokeChkShtList,
} from '../pages/qualityDepartment/pokeYokeCheckSheet';
import {
  AnnualPreventivePlanMasterNewChecklist,
  AnnualPreventivePlanMasterNewChecklistCreate,
  AnnualPreventivePlanNewPlanCreate,
  AnnualPreventivePlanNewPlanList,
  AnnualPreventivePlanNewStatus,
  AnnualPreventivePlanNewStatusCheckListCreate,
  AnnualPreventivePlanNewTiles,
} from '../pages/clause10Page/annualPreventivePlan';
import {
  PredictiveMaintenancePlanTiles,
  PredictiveMaintenancePlanList,
  PredictiveMaintenancePlanCreate,
  PredictiveMaintenancePlanStatus,
  PredictiveMaintenancePlanStatusCheckListCreate,
  PredictiveMaintenancePlanMasterChecklist,
  PredictiveMaintenancePlanMasterChecklistCreate,
} from '../pages/clause10Page/predictiveMaintenancePlan';
import { KeyEquipment, CriticalSparePartsList } from '../pages/clause10Page';
import {
  QualityDeparmentHome,
  LuxMatrixCheckSheetList,
  LuxMatrixCheckSheetCreate,
  SupplierComplaintList,
} from '../pages/qualityDepartment';

import {
  SpcPlanNewChecklist,
  SpcPlanNewChecklistCreate,
  SpcPlanNewCreate,
  SpcPlanNewList,
  SpcPlanNewStatus,
  SpcPlanNewStatusCheckList,
  SpcPlanNewTiles,
} from '../pages/qualityDepartment/spcPlanNew';
import {
  LayoutPlanChecklist,
  LayoutPlanChecklistCreate,
  LayoutPlanCreate,
  LayoutPlanList,
  LayoutPlanStatus,
  LayoutPlanStatusCheckList,
  LayoutPlanTiles,
} from '../pages/qualityDepartment/layoutPlan';
import { MasterlistVisualDefects } from '../pages/masterlistVisualDefects';
import { MasterListProcessParameters } from '../pages/masterlisProcessParameters';
interface routes {
  path: string;
  element: JSX.Element;
  errorElement?: string;
}

// scrap Note

interface routes {
  path: string;
  element: JSX.Element;
  errorElement?: string;
}

export const other: routes[] = [
  {
    path: 'profile',
    element: <Profile />,
  },
  {
    path: 'pdc',
    element: <PDCSection />,
  },
  {
    path: 'rotorSection',
    element: <RotorSection />,
  },
  {
    path: 'pressShop',
    element: <PressShop />,
  },
  {
    path: 'cnc',
    element: <CNCSection />,
  },
];
export const clause4 = [
  {
    path: 'processList',
    element: <ProcessList />,
  },
  {
    path: 'modelsList',
    element: <ModelsList />,
  },
  {
    path: 'inprocessInspectionReportKMLList',
    element: <InprocessInspectionReportListkml />,
  },
  {
    path: 'scrapnoteReportList',
    element: <ScrapNoteReportList />,
  },
  {
    path: 'scrapnoteReport/:id?',
    element: <CreateScrapNoteReport />,
  },
  {
    path: 'createInprocessInspectionReportKML/:id?',
    element: <CreateInprocessInspectionReportkml />,
  },
  {
    path: 'drawingManagementList',
    element: <DrawingManagementList />,
  },
];
const productionDept = [
  {
    path: 'home/prductionDept',
    element: <ProductionDeptHome />,
  },
  {
    path: 'dailyProdReportList',
    element: <DailProdReportList />,
  },
  {
    path: 'createDailyProdReport/:id?',
    element: <CreateDailProdReport />,
  },
  {
    path: 'productionDefectsList',
    element: <ProductionDefectsList />,
  },
  {
    path: 'finalInspectionReportList',
    element: <FinalInspectionReportList />,
  },
  {
    path: 'createFinalInspectionReport/:id?',
    element: <CreateFinalInspectionReport />,
  },
  {
    path: 'twoHourlyProductionReportList',
    element: <TwoHourlyProductionReportList />,
  },
  {
    path: 'createTwoHourlyProductionReport/:id?',
    element: <CreateTwoHourlyProductionReport />,
  },
  {
    path: 'mouldingList',
    element: <MouldingList />,
  },
  {
    path: 'redbinAnalysis',
    element: <RedbinAnalysis />,
  },
  {
    path: 'createEditRedbinAnalysisReport/:id',
    element: <CreateDailyProductionRedbinReport />,
  },
  {
    path: 'createEditRedbinAnalysisReport',
    element: <CreateDailyProductionRedbinReport />,
  },
  {
    path: 'moulds/history/:id/:joint',
    element: <CreateMouldHistory />,
  },
  {
    path: 'shiftProductionReportList',
    element: <ShiftProductionReportList />,
  },
  {
    path: 'createShiftProductionReport/:id?',
    element: <CreateShiftProductionReport />,
  },
  {
    path: 'dailyProductionReportList',
    element: <DailyProductionReportList />,
  },
  {
    path: 'createDailyProductionReport/:id?',
    element: <CreateDailyProductionReport />,
  },
  {
    path: 'dailyProductionReport/dashboard',
    element: <DailyProductionReportDashboard />,
  },
  {
    path: 'breakDownStatusList',
    element: <BreakDownStatusList />,
  },
  {
    path: 'createEditBreakDownStatus/:id?',
    element: <CreateBreakDownStatus />,
  },

  // new plan
  {
    path: 'prevantiveMaintenance_NEW_Tiles',
    element: <PrevantiveMaintenance_NEW_Tiles />,
  },
  {
    path: 'prevantiveMaintenance_NEW_PlanList',
    element: <PrevantiveMaintenance_NEW_PlanList />,
  },
  {
    path: 'prevantiveMaintenance_NEW_PlanCreate/:id',
    element: <PrevantiveMaintenance_NEW_PlanCreate />,
  },
  {
    path: 'prevantiveMaintenance_NEW_Status',
    element: <PrevantiveMaintenance_NEW_Status />,
  },
  {
    path: 'prevantiveMaintenance_NEW_Checklist',
    element: <PrevantiveMaintenance_NEW_Checklist />,
  },
  {
    path: 'prevantiveMaintenance_NEW_ChecklistCreate/:id?',
    element: <PrevantiveMaintenance_NEW_ChecklistCreate />,
  },
  {
    path: 'prevantiveMaintenanceStatusCheckList/:id?',
    element: <PrevantiveMaintenanceStatusCheckList />,
  },
  {
    path: 'productionWorkersList',
    element: <ProductionWorkersList />,
  },
   // break down slip
   {
    path: 'breakDownSlipList',
    element: <BreakDownSlipList />,
  },
  {
    path: 'createEditBreakDownSlip/:id?',
    element: <CreateBreakDownSlip />,
  },
  {
    path: 'mouldsHistory/:id?',
    element: <MouldHistory />,
  },
];


export const processRejection = [
  {
    path: 'processRejection',
    element: <ProcessRejectionTiles />,
  },
  {
    path: 'inhouserejection/dashboardList',
    element: <InhouseTiles />,
  },
  {
    path: 'processRejection/dailyProductionList',
    element: <ProcessRejectionList />,
  },
  {
    path: 'createEditProcessRejection/:id?',
    element: <ProcessRejectionCreate />,
  },
  {
    path: 'processRejection/dashboard',
    element: <ProcessRejectionDashboardTabs />,
  },
  {
    path: 'processRejection/dashboardList',
    element: <ProcessRejectionDashBoardList />,
  },
];
export const productions = [
  {
    path: 'production',
    element: <Production />,
  },
  // {
  //   path: 'dailyProductionList',
  //   element: <DailyProduction />,
  // },

  // --------------------------- rotor section -------------------------------------------------------------------------
  // daily prod rotor report
  {
    path: 'rotorSection/dailyProductionList',
    element: <DailyProduction />,
  },
  {
    path: 'createEditDailyProduction/:id?',
    element: <CreateDailyProduction />,
  },

  // daily rotor rejection report
  {
    path: 'rotorSection/dailyProductionRotorDailyRejectionList',
    element: <DailyRejectionRotorList />,
  },
  {
    path: 'createEditDailyProductionRotorDailyRejection/:id?',
    element: <CreateDailyRejectionRotor />,
  },
  // daily production redbin list (doubt it is to be deleted or not)
  // {
  //   path: 'rotorSection/dailyProductionRedBinList',
  //   element: <DailyRejectionRotorList />,
  // },
  // {
  //   path: 'createEditDailyProductionRotorDailyRejection/:id?',
  //   element: <CreateDailyRejectionRotor />,
  // },
  // redbin prashant
  {
    path: 'rotorSection/redbinRotorSection',
    element: <RotorSectionRedBinList />,
  },
  {
    path: 'rotorSection/createEditRedbinRotorSection/:id?',
    element: <CreateViewRotorSectionRedBin />,
  },

  // ----------------------------------end of rotor section -----------------------------------------------------------------------

  // -------------------------------------------pdc section -------------------------------------------------------------------------
  // hourly report
  {
    path: 'pdc/rotorHourlyProductionList',
    element: <HourlyProductionReportList />,
  },
  {
    path: 'createHourlyProductionReports/:id?',
    element: <CreateHourlyProductionReport />,
  },

  // prodRed bin pdc section
  {
    path: 'pdc/dailyProductionRedBinList',
    element: <DailyProductionRedBin />,
  },
  {
    path: 'createEditDailyProductionRedBin/:id?',
    element: <CreateDailyProductionRedBin />,
  },

  // ---------------------------------------------- press shop section ----------------------------------------------
  // dailyprod strokes
  {
    path: 'pressShop/dailyProductionStrokesList',
    element: <DailyProductionStrokes />,
  },
  {
    path: 'createEditDailyProductionStrokes/:id?',
    element: <CreateDailyProductionStrokes />,
  },

  {
    path: 'pressShop/redbinPressShop',
    element: <PressShopRedBinList />,
  },
  {
    path: 'pressShop/createEditRedbinPressShop/:id?',
    element: <CreateViewPressShopRedBin />,
  },

  // redbin

  // ---------------------------------------------- end of press shop section ----------------------------------------------

  // ------------------------------------------------- cnc secction --------------------------------------------------------

  // hourly prod cnc
  {
    path: 'cnc/dailyHourlyProdCNCList',
    element: <DailyHourlyCNC />,
  },
  {
    path: 'cnc/createEditDailyHourlyProdCNC/:id?',
    element: <CreateDailyHourlyCNC />,
  },
  // redbin cnc
  {
    path: 'cnc/redbinCNC',
    element: <CNCRedBinList />,
  },
  {
    path: 'cnc/createEditRedbinCNC/:id?',
    element: <CreateViewCNCRedBin />,
  },
];

const clause3ForM = [
  {
    path: '4mPdc',
    element: <PdcHome />,
  },
  {
    path: '4mPressShop',
    element: <PressShopHome />,
  },
  {
    path: '4mRotor',
    element: <RotorHome />,
  },
  {
    path: 'abnormalRejectionReportList',
    element: <AbnormalRejectionReportList />,
  },
  {
    path: 'createEditAbnormalRejectionReport/:id?',
    element: <AbnormalRejectionReportCreate />,
  },
];

const clause8Modules = [
  // abnormality Log book
  {
    path: 'abnormalityLogBookList',
    element: <AbnormalityLogBook />,
  },
  {
    path: 'createAbnormalityLogBook/:id?',
    element: <CreateAbnormalityLogBook />,
  },
];

const pir = [
  {
    path: 'pirtiles',
    element: <PirTiles />,
  },
  {
    path: 'bopcreate/:partId/:pirId?',
    element: <BopCreate />,
  },
  {
    path: 'BopTableList',
    element: <BopTableList />,
  },
  {
    path: 'migList',
    element: <MigList />,
  },
  {
    path: 'migCreate/:partId/:pirId?',
    element: <MigCreate />,
  },
  // weld shop

  {
    path: 'weldShop/PirList',
    element: <WeldShopPirList />,
  },
  {
    path: 'weldShop/createPir/:id?',
    element: <CreateWeldShopPir />,
  },
  // press shop
  {
    path: 'pressShop/PirList',
    element: <PressShopList />,
  },
  {
    path: 'pressShop/createPir/:partId/:pirId?',
    element: <PressShopCreate />,
  },
  // plating and powdder coating
  {
    path: 'platingAndPowderCoating/PirList',
    element: <PlatingPowderCoatingList />,
  },
  {
    path: 'platingAndPowderCoating/createPir/:partId/:pirId?',
    element: <PlatingPowderCoatingCreate />,
  },
  {
    path: 'platinglist',
    element: <UnderConstructionPage />,
  },
];
const forMSummary = [
  {
    path: 'create4mSummary',
    element: <CreateForMSummary />,
  },
  {
    path: '4mSummaryList',
    element: <SummaryTableList />,
  },
  {
    path: 'create4mSetup/:partId/:rowId/:setupId?',
    element: <CreateForMSetup />,
  },
  {
    path: 'createHoldCard/:id?',
    element: <CreateHoldCard />,
  },
];

const SpcPlan = [
  {
    path: 'spcPlanTiles',
    element: <SpcPlanTiles />,
  },
  {
    path: 'spcPlanPlanList',
    element: <SpcPlanPlanList />,
  },
  {
    path: 'spcPlanPlanCreate/:id',
    element: <SpcPlanPlanCreate />,
  },
  {
    path: 'spcPlanChecklistList',
    element: <SpcPlanChecklistList />,
  },
  {
    path: 'MasterListofspcPlan',
    element: <MasterListofSpcPlan />,
  },
];
const MSA = [
  {
    path: 'MSAList',
    element: <MSAList />,
  },
  {
    path: 'CreateEditMsa/:partId/:id?',
    element: <CreateEditMsa />,
  },
];

// inspection qlty std ri dept
const inspectionStandard = [
  {
    path: 'inspectionStandardList',
    element: <InspectionStandardList />,
  },
  {
    path: 'inspectionStandard/:inspectionStandardId?',
    element: <CreateInpsectionStandard />,
  },
];

const instrumentClause15 = [
  {
    path: 'instrumentplanlist',
    element: <PlanList />,
  },
  {
    path: 'instrumentplancreate/:id',
    element: <PlanCreate />,
  },
  {
    path: 'msaplanlist',
    element: <MsaPlanList />,
  },
  {
    path: 'msaplancreate/:id',
    element: <MsaPlanCreate />,
  },
  {
    path: 'msachecklistList',
    element: <MsaChecklistList />,
  },
  {
    path: 'instrumentMasterlist',
    element: <InstrumentMasterList />,
  },
  {
    path: 'instrumentHistoryCard/:id',
    element: <InstrumentHistoryCard />,
  },
  {
    path: 'masterListLocationList',
    element: <MasterListLocationList />,
  },
  {
    path: 'instrumentVerificationSheet',
    element: <CreateInstrumentVerificationSheet />,
  },
  {
    path: 'checklistList',
    element: <ChecklistList />,
  },
   //  annual calibration plan new
   {
    path: 'calibrationPlanNewTiles',
    element: <CalibrationPlanNewTiles />,
  },
  {
    path: 'calibrationPlanNewPlanList',
    element: <CalibrationPlanNewPlanList />,
  },
  {
    path: 'calibrationPlanNewCreate/:id',
    element: <CalibrationPlanNewPlanCreate />,
  },
  {
    path: 'calibrationPlanNewStatus',
    element: <CalibrationPlanNewStatus />,
  },
  {
    path: 'calibrationPlanNewStatusCheckListCreate/:id?',
    element: <CalibrationPlanNewStatusCheckListCreate />,
  },
  {
    path: 'calibrationPlanMasterNewChecklist',
    element: <CalibrationPlanMasterNewChecklist />,
  },
  {
    path: 'calibrationPlanNewMasterChecklistCreate/:id?',
    element: <CalibrationPlanMasterNewChecklistCreate />,
  },
];
const inspectionReportRoute = [
  {
    path: 'inspectionReportList',
    element: <InspectionReportList />,
  },
  {
    path: 'inspectionReport/:partId/:inspectionReportId?',
    element: <InspectionReport />,
  },
];
const supplierAuditPlan = [
  // new plan
  {
    path: 'supplierAuditPlanTiles',
    element: <SupplierAuditPlanTiles />,
  },
  {
    path: 'supplierAuditPlanPlanList',
    element: <SupplierAuditPlanPlanList />,
  },
  {
    path: 'supplierAuditPlanPlanCreate/:id',
    element: <SupplierAuditPlanPlanCreate />,
  },
  {
    path: 'supplierAuditPlanStatus',
    element: <SupplierAuditPlanStatus />,
  },
  {
    path: 'supplierAuditPlanChecklist',
    element: <SupplierAuditPlanChecklist />,
  },
  {
    path: 'supplierAuditPlanChecklistCreate/:id?',
    element: <SupplierAuditPlanChecklistCreate />,
  },
  {
    path: 'supplierAuditPlanStatusCheckList/:id?',
    element: <SupplierAuditPlanStatusCheckList />,
  },
];

const clipOutsertPlan = [
  {
    path: 'clipOutsertPlanTiles',
    element: <ClipOutsertPlanTiles />,
  },
  {
    path: 'clipOutsertPlanList',
    element: <ClipOutsertPlanList />,
  },
  {
    path: 'clipOutsertPlanCreate/:id',
    element: <ClipOutsertPlanCreate />,
  },
  {
    path: 'clipOutsertPlanChecklistList',
    element: <ClipOutsertPlanChecklistList />,
  },
  {
    path: 'clipOutsertPlanChecklistCreate/:moduleId?',
    element: <ClipOutsertPlanChecklistCreate />,
  },
];

const callibrationPlanClause15 = [
  {
    path: 'calibrationPlanTiles',
    element: <CallibrationPlanTiles />,
  },
  {
    path: 'calibrationPlanList',
    element: <CallibrationPlanList />,
  },
  {
    path: 'calibrationPlanCreate/:id',
    element: <CallibrationPlanCreate />,
  },
  {
    path: 'calibrationPlanChecklistList',
    element: <CallibrationPlanChecklistList />,
  },
  {
    path: 'calibrationPlanChecklistCreate/:moduleId?',
    element: <CallibrationPlanChecklistCreate />,
  },
];
// Supplier Document
const supplierDocument = [
  {
    path: 'vendorDocumentList',
    element: <VendorList />,
  },
  {
    path: 'supplierDocumentHome/:vendorId',
    element: <SupplierDocumentHome />,
  },
  {
    path: 'supplierDocument/:vendorId',
    element: <SupplierDocument />,
  },
];
const clause5 = [
  {
    path: 'clause5',
    element: <Clause5 />,
  },
  // job desc module
  {
    path: 'jobDescHome',
    element: <JobDescHome />,
  },

  {
    path: 'designation',
    element: <DesignationList />,
  },

  {
    path: 'JdList',
    element: <JDList />,
  },
  {
    path: 'JdCreate/:moduleId?',
    element: <JDCreate />,
  },
  // end of job desc module
  {
    path: 'employeeList',
    element: <EmployeeList />,
  },
  {
    path: 'departmentList',
    element: <DepartmentList />,
  },
  {
    path: 'masterlistoffaculty',
    element: <FacultyList />,
  },
  {
    path: 'empTraningTopicsCreateEdit/:id?',
    element: <EmpTraningTopicsCreateEdit />,
  },
  {
    path: 'skillEvaluationSheetList',
    element: <SkillEvaluationSheetList />,
  },
  {
    path: 'skillEvaluationSheetCreate/:id?',
    element: <SkillEvaluationSheetCreate />,
  },
  {
    path: 'tnihome',
    element: <TniTiles />,
  },
  {
    path: 'traningtopicslist',
    element: <TraningTopicsList />,
  },
  {
    path: 'traningtopicscreateedit/:id?',
    element: <TraningTopicsCreateEdit />,
  },
  {
    path: 'tniReportList',
    element: <TniReportList />,
  },
  {
    path: 'tniReportCreate/:id?',
    element: <TniReportCreate />,
  },
  {
    path: 'skillEvaluationPlanTiles',
    element: <SkillEvaluationPlanTiles />,
  },
  {
    path: 'skillEvaluationPlanList',
    element: <SkillEvaluationPlanList />,
  },
  {
    path: 'skillEvaluationPlanCreate/:id',
    element: <SkillEvaluationPlanCreate />,
  },
  {
    path: 'skillEvaluationPlanStatus',
    element: <SkillEvaluationPlanStatus />,
  },
  {
    path: 'skillEvaluationPlanStatusCheckListCreate/:id?',
    element: <SkillEvaluationPlanStatusCheckListCreate />,
  },
  {
    path: 'skillEvaluationPlanMasterChecklist',
    element: <SkillEvaluationPlanMasterChecklist />,
  },
  {
    path: 'skillEvaluationPlanMasterChecklistCreate/:id?',
    element: <SkillEvaluationPlanMasterChecklistCreate />,
  },
  {
    path: 'trainingPlanTiles',
    element: <TrainingPlanTiles />,
  },
  {
    path: 'trainingPlanList',
    element: <TrainingPlanList />,
  },
  {
    path: 'trainingPlanCreate/:id',
    element: <TrainingPlanCreate />,
  },
  {
    path: 'trainingPlanStatus',
    element: <TrainingPlanStatus />,
  },
  {
    path: 'trainingPlanStatusCheckListCreate/:id?',
    element: <TrainingPlanStatusCheckListCreate />,
  },
  {
    path: 'trainingPlanMasterChecklist',
    element: <TrainingPlanMasterChecklist />,
  },
  {
    path: 'trainingPlanMasterChecklistCreate/:id?',
    element: <TrainingPlanMasterChecklistCreate />,
  },
];

export const riDepartment = [
  {
    path: 'supplierDefectMonitoringSheetList',
    element: <SupplierDefectMonitoringSheetList />,
  },
  {
    path: 'defect_list',
    element: <ListOfDefects />,
  },
  {
    path: 'supplierDefectMonitoringSheetDashboard',
    element: <SupplierDefectMonitoringSheetDashboardTabs />,
  },
  {
    path: 'problemticPartInspectionReport',
    element: <ProblemticPartInspectionReport />,
  },
  // ri dept supplier monitoring and supplier rating
  {
    path: 'supplierMonitoring/vendors',
    element: <SupplierMonitoringVendorList />,
  },
  {
    path: 'supplierMonitoring/create',
    element: <SupplierMonitoring />,
  },
  {
    path: 'InspectionStandardNewList',
    element: <InspectionStandardNewList />,
  },
  {
    path: 'createInspectionStandardNew/:moduleId',
    element: <CreateInspectionStandardNew />,
  },
];

const incomingCompileInpsection = [
  {
    path: 'createIncomingCompileInspectionReport',
    element: <CreateIncomingCompileInspectionReport />,
  },
  {
    path: 'createDailyLineRejectionReport',
    element: <CreateDailyLineRejectionReport />,
  },
  {
    path: 'createRedTrollyRejectionReport',
    element: <CreateRedTrollyRejectionReport />,
  },
  {
    path: 'createReworkLotRejectionReport',
    element: <ReworkLotRejectionReport />,
  },
  {
    path: 'createRejectionChallanReport',
    element: <CreateRejectionChallanReport />,
  },
];

export const layoutInspectionReport = [
  {
    path: 'layoutInspectionReportList',
    element: <LayoutInspectionReportList />,
  },
  {
    path: 'createEditLayoutInspectionReport/:id?',
    element: <CreateEditLayoutInspectionReport />,
  },
];

export const inprocessRejectionChallan = [
  {
    path: 'inprocessRejectionChallanList',
    element: <InprocessRejectionChallanList />,
  },
  {
    path: 'createEditInprocessRejectionChallan/:id?',
    element: <CreateEditInprocessRejectionChallan />,
  },
];

export const clause10 = [
  {
    path: 'keyEquipment/list',
    element: <KeyEquipment />,
  },
  {
    path: 'criticalSparePart/list',
    element: <CriticalSparePartsList />,
  },

  {
    path: 'predictiveMaintenancePlanTiles',
    element: <PredictiveMaintenancePlanTiles />,
  },
  {
    path: 'predictiveMaintenancePlanList',
    element: <PredictiveMaintenancePlanList />,
  },
  {
    path: 'predictiveMaintenancePlanCreate/:id',
    element: <PredictiveMaintenancePlanCreate />,
  },
  {
    path: 'predictiveMaintenancePlanStatus',
    element: <PredictiveMaintenancePlanStatus />,
  },
  {
    path: 'predictiveMaintenancePlanStatusCheckListCreate/:id?',
    element: <PredictiveMaintenancePlanStatusCheckListCreate />,
  },
  {
    path: 'predictiveMaintenancePlanMasterChecklist',
    element: <PredictiveMaintenancePlanMasterChecklist />,
  },
  {
    path: 'predictiveMaintenancePlanMasterChecklistCreate/:id?',
    element: <PredictiveMaintenancePlanMasterChecklistCreate />,
  },
  //  annual preventive plan new
  {
    path: 'annualPreventivePlanNewTiles',
    element: <AnnualPreventivePlanNewTiles />,
  },
  {
    path: 'annualPreventivePlanNewPlanList',
    element: <AnnualPreventivePlanNewPlanList />,
  },
  {
    path: 'annualPreventivePlanNewCreate/:id',
    element: <AnnualPreventivePlanNewPlanCreate />,
  },
  {
    path: 'annualPreventivePlanNewStatus',
    element: <AnnualPreventivePlanNewStatus />,
  },
  {
    path: 'annualPreventivePlanNewStatusCheckListCreate/:id?',
    element: <AnnualPreventivePlanNewStatusCheckListCreate />,
  },
  {
    path: 'annualPreventivePlanMasterNewChecklist',
    element: <AnnualPreventivePlanMasterNewChecklist />,
  },
  {
    path: 'annualPreventivePlanNewMasterChecklistCreate/:id?',
    element: <AnnualPreventivePlanMasterNewChecklistCreate />,
  },

  // annual preventive new  end
];
const qualityDepartment = [
  {
    path: 'qualityDepartmentHome',
    element: <QualityDeparmentHome />,
  },
  {
    path: 'luxMatrixCheckSheetList',
    element: <LuxMatrixCheckSheetList />,
  },
  {
    path: 'createEditLuxMatrixCheckSheet/:id?',
    element: <LuxMatrixCheckSheetCreate />,
  },
  {
    path: 'SupplierComplaintRegister',
    element: <SupplierComplaintList />,
  },
  {
    path: 'qualityDepartmentPokaYokeCheckSheetList',
    element: <PokaYokeChkShtList />,
  },
  {
    path: 'createQualityDepartmentPokaYokeCheckSheet',
    element: <CreatePokaYokeChkSht />,
  },

  // spc plan new
  {
    path: 'spcPlanNewTiles',
    element: <SpcPlanNewTiles />,
  },
  {
    path: 'spcPlanNewPlanList',
    element: <SpcPlanNewList />,
  },
  {
    path: 'spcPlanNewCreate/:id',
    element: <SpcPlanNewCreate />,
  },
  {
    path: 'spcPlanNewStatus',
    element: <SpcPlanNewStatus />,
  },
  {
    path: 'spcPlanNewChecklist',
    element: <SpcPlanNewChecklist />,
  },
  {
    path: 'spcPlanNewStatusCheckList/:id?',
    element: <SpcPlanNewStatusCheckList />,
  },
  {
    path: 'spcPlanNewChecklistCreate/:id?',
    element: <SpcPlanNewChecklistCreate />,
  },

  //spc  new end

  // layout plan
  {
    path: 'layoutPlanTiles',
    element: <LayoutPlanTiles />,
  },
  {
    path: 'layoutPlanList',
    element: <LayoutPlanList />,
  },
  {
    path: 'layoutPlanCreate/:id',
    element: <LayoutPlanCreate />,
  },
  {
    path: 'layoutPlanStatus',
    element: <LayoutPlanStatus />,
  },
  {
    path: 'layoutPlanChecklist',
    element: <LayoutPlanChecklist />,
  },
  {
    path: 'layoutPlanStatusCheckList/:id?',
    element: <LayoutPlanStatusCheckList />,
  },
  {
    path: 'layoutPlanChecklistCreate/:id?',
    element: <LayoutPlanChecklistCreate />,
  },

  //layout  new end
];

export const IndexCommon: routes[] = [
  {
    path: 'riDepartment',
    element: <RIDeparmentHome />,
  },
  {
    path: 'riPart',
    element: <RIPart />,
  },
  {
    path: 'samplingStandard',
    element: <SamplingStd />,
  },
  {
    path: 'clauseUploadList',
    element: <ClauseUploadList />,
  },
  {
    path: 'masterListOfVendor',
    element: <VendirList />,
  },
  // tools and critical spare parts
  // machine and tools
  {
    path: 'masterlistofTools',
    element: <ToolsList />,
  },
  {
    path: 'toolsHistory/:id',
    element: <ToolsHistory />,
  },
  {
    path: 'processControlStandardList/:partId',
    element: <ProcessControlStandardList />,
  },
  {
    path: 'processControlStandardCreate/:partId/:id?',
    element: <ProcessControlStandardCreate />,
  },
  {
    path: 'supplierplanlist',
    element: <SupplierPlanList />,
  },
  {
    path: 'supplierplancreate/:id',
    element: <SupplierPlanCreate />,
  },
  {
    path: 'supplierchecklist',
    element: <SupplierChecklist />,
  },

  // scrap note
  {
    path: 'scrapNoteList',
    element: <ScrapNoteList />,
  },
  {
    path: 'createEditScrapNote/:id?',
    element: <CreateScrapNote />,
  },
  {
    path: 'clause15',
    element: <Clause15Home />,
  },
  {
    path: 'tilesCallibrationplan',
    element: <TilesCallibration />,
  },
  {
    path: 'tilesMsaplan',
    element: <TilesMsa />,
  },

  //Mushin Help Desk
  {
    path: 'mushinHelpDesk',
    element: <MushinHelpDesk />,
  },

  // break down slip
  {
    path: 'breakDownSlipList',
    element: <BreakDownSlipList />,
  },
  {
    path: 'createEditBreakDownSlip/:id?',
    element: <CreateBreakDownSlip />,
  },
  {
    path: 'trainingRecord/:id?',
    element: <TrainingRecord />,
  },
  {
    path: 'ToolbarCustomTest',
    element: <ToolbarCustomTest />,
  },
  {
    path: 'masterFormatExcel',
    element: <MasterFormatExcel />,
  },
  {
    path: 'masterlistVisualDefectsList',
    element: <MasterlistVisualDefects />,
  },
  {
    path: 'masterListProcessParameters',
    element: <MasterListProcessParameters />,
  },

  ...clause3ForM,
  ...productions,
  ...processRejection,
  ...clause8Modules,
  ...other,
  ...pir,
  ...forMSummary,
  ...SpcPlan,
  ...MSA,
  ...instrumentClause15,
  ...inspectionStandard,
  ...inspectionReportRoute,
  ...supplierAuditPlan,
  ...callibrationPlanClause15,
  ...supplierDocument,
  ...riDepartment,
  ...clause5,
  ...layoutInspectionReport,
  ...inprocessRejectionChallan,
  ...incomingCompileInpsection,
  ...clipOutsertPlan,
  ...productionDept,
  ...clause4,
  ...clause5,
  ...clause10,
  ...qualityDepartment,
];
