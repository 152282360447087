import React, { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { Stack } from '@mui/material';
import {
    DeleteIconButton,
    UploadImageIconButton,
    ViewIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../../components/common/formatDate';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import SimpleSelect from '../../../../../components/common/SimpleSelect';

interface IProps {
    moduleData: any;
    isView: boolean;
    process: any;
    index: number;
    handelDeleteRows: any;
    handleSumbit: any;
    uploadImageFile: any;
    machineData: any;
}
const TBodyRow = ({
    isView,
    process,
    index,
    handelDeleteRows,
    handleSumbit,
    uploadImageFile,
    moduleData,
    machineData,
}: IProps) => {
    const [render, setRender] = useState(false);

    return (
        <React.Fragment key={index + 10.1 + 'heading '}>
            <tr>
                <th style={{ minWidth: '50px', textAlign: 'center' }}>{index + 1}</th>
                <td style={{ minWidth: '200px', textAlign: 'center' }}>
                    <TableInput
                        isView={isView}
                        name="poke_yoke_name"
                        type="text"
                        value={process.poke_yoke_name}
                        onChange={(e) => {
                            handleChangeModuleData(e, process);
                            setRender((prev) => !prev);
                        }}
                    />
                </td>
                <td style={{ minWidth: '200px', textAlign: 'center' }}>
                    <AutocompleteMuiCustom
                        id="Machine"
                        label=""
                        option_name="machine_no"
                        arrayofObj={machineData || []}
                        value={process.machine_id || ''}
                        onChange={(e, value) => {
                            process.machine_id = value;
                            setRender((prev) => !prev);
                        }}
                    />
                </td>
                <td style={{ minWidth: '200px', textAlign: 'center' }}>
                    <TableInput
                        isView={isView}
                        name="location"
                        type="text"
                        value={process.location}
                        onChange={(e) => {
                            handleChangeModuleData(e, process);
                            setRender((prev) => !prev);
                        }}
                    />
                </td>
                <td style={{ minWidth: '200px', textAlign: 'center' }}>
                    <TableInput
                        isView={isView}
                        name="method_of_verification"
                        type="text"
                        value={process.method_of_verification}
                        onChange={(e) => {
                            handleChangeModuleData(e, process);
                            setRender((prev) => !prev);
                        }}
                    />
                </td>
                <td style={{ minWidth: '200px', textAlign: 'center' }}>
                    {isView ? (
                        formatDate(process.installation_date)
                    ) : (
                        <DatePickerMui
                            label=""
                            value={moment(process.installation_date || null)}
                            onChange={(date) => {
                                process.installation_date = date;
                                setRender((prev) => !prev);
                            }}
                        />
                    )}
                </td>
                <td style={{ minWidth: '200px', textAlign: 'center' }}>
                    {moduleData._id ? (
                        <Stack
                            direction="row"
                            sx={{ justifyContent: 'center', alignItems: 'center' }}
                        >
                            <UploadImageIconButton
                                id={`${index}-process-upload`}
                                tooltipTitle="upload"
                                sx={{}}
                                onChange={(e) => {
                                    uploadImageFile(e, index);
                                }}
                            />
                            {process.files?.length > 0 ? (
                                <>
                                    <ViewIconButton
                                        tooltipTitle="view image"
                                        onClick={() => {
                                            window.open(process.files[0].url, '_blank', 'noreferrer');
                                        }}
                                    />
                                    <DeleteIconButton
                                        tooltipTitle={'delete'}
                                        onClick={() => {
                                            if (
                                                window.confirm(
                                                    'Are you sure u want to delete this image!!!',
                                                )
                                            ) {
                                                setRender((prev) => !prev);
                                                process.files = [];
                                            }
                                        }}
                                    />
                                </>
                            ) : (
                                <DeleteIconButton
                                    sx={{
                                        opacity: '0',
                                        PointerEvent: 'none',
                                        visibility: 'hidden',
                                    }}
                                    tooltipTitle=""
                                />
                            )}
                        </Stack>
                    ) : (
                        'To enable this field you have to submit first.'
                    )}
                </td>
                {Array.from({ length: 31 }, (_, indx) => (
                    <td
                        style={{
                            minWidth: '100px',
                            textAlign: 'center',
                            fontSize: '2rem',
                        }}
                        key={index + 2.2 + indx + 'after' + 111.33}
                    >
                        {isView ? (
                            (process.results[indx] === 'true' && '✓') ||
                            (process.results[indx] === 'false' && 'x')
                        ) : (
                            <SimpleSelect
                                name={`shift_A_results[${indx}]`}
                                value={process.results[indx]}
                                onChange={(e: any) => {
                                    process.results[indx] = e.target.value;
                                    setRender((prev) => !prev);
                                }}
                                options={[
                                    { value: '', label: 'none' },
                                    { value: 'true', label: '✓' },
                                    { value: 'false', label: 'x' },
                                ]}
                            />
                        )}
                    </td>
                ))}
                <td
                    style={{ minWidth: '100px', textAlign: 'center' }}
                    colSpan={isView ? 2 : 1}
                >
                    <TableInput
                        isView={isView}
                        name="remarks"
                        type="text"
                        value={process.remarks}
                        onChange={(e) => {
                            handleChangeModuleData(e, process);
                            setRender((prev) => !prev);
                        }}
                    />
                </td>
                {!isView && (
                    <td style={{ minWidth: '100px', textAlign: 'center' }}>
                        <DeleteIconButton
                            tooltipTitle={'delete'}
                            onClick={() => handelDeleteRows(index)}
                        />
                    </td>
                )}
            </tr>
        </React.Fragment>
    );
};

export default TBodyRow;
