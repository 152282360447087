import styled from 'styled-components';
import { TableRow, TableCell } from '@mui/material';

export const StyledTableRow: any = styled(TableRow)`
  background: ${(props: any) =>
    props?.index % 2 === 0 ? 'fefefe' : '#f1f3f5'};
  border: 1px dashed #495057 !important;
`;

export const StyledTableCell: any = styled(TableCell)`
  border: 1px dashed #495057 !important;
`;
