import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';
import { useQuery } from '../../../../hooks/UseQuery';
import React from 'react';
import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import { HeaderStandard, headerConstant } from '../helpers/constant';
// import AutocompleteMuiCustomRevised from '../../../../components/common/AutocompleteMuiCustomRevised';
import { getTotalColsSpan } from '../../../../utils/helpers/utils';
// import { getTotalColsSpan } from '../../../../utils/helpers/utils';
import CustomSelect from '../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';

interface IProps {
  moduleData: any;
  machineData?: any;
  updateModuleData?: any;
  department?: any;
}

const THead = ({
  machineData,
  moduleData,
  updateModuleData,
  department,
}: IProps) => {
  const isView = useQuery('isView') === 'true' ? true : false;

  const handleAutocompleteChangeDepartment = (newValue: any) => {
    updateModuleData('key-pair', null, 'department_name', {
      department_name: newValue?.department_name,
    });
    updateModuleData('key-pair', null, 'department_id', {
      department_id: newValue,
    });
  };

  const handleInputChange = (
    fieldName: string,
    value: string | number | null
  ) => {
    updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
  };

  return (
    <thead>
      <TableCompHead
        colSpan={getTotalColsSpan(HeaderStandard)}
        rowSpan={3}
        labels={['Doc. No:', 'Rev. No.:', 'Date']}
        values={['F-HR-17', '00', '01.04.2023']} //Changed 
        heading={`${headerConstant.tableCompHeadTitle}`}
      />

      <tr>
        <th colSpan={1}>NAME</th>
        <th colSpan={3}>
          <TableInput
            isView={isView}
            name="sheet_name"
            type="text"
            disabled={true}
            value={moduleData.employee_name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
        <th>Department</th>
        <th colSpan={2} align="left">
          {/* <AutocompleteMuiCustomRevised
            id="department_name"
            label=""
            option_name="department_name"
            key={'department_name'}
            arrayofObj={department}
            value={moduleData?.department_id || ''}
            onChange={(_, newValue: any) =>
              handleAutocompleteChangeDepartment(newValue)
            }
            sx={{ width: '200px' }}
          /> */}
          {moduleData?.department_id?.department_name}
        </th>
      </tr>
      <tr>
        <th colSpan={1}>Date</th>
        <th colSpan={3} align="left">
          {isView ? (
            formatDate(moduleData.date)
          ) : (
            <DatePickerMui
              label=""
              value={moduleData?.date ? moment(moduleData.date) : null}
              onChange={(date) => {
                handleInputChange('date', date);
              }}
            />
          )}
        </th>
        <th colSpan={1}>STATUS</th>
        <th colSpan={2}>
          <TableInput
            isView={isView}
            name="status"
            type="text"
            value={moduleData.status}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
      </tr>
      <tr>
        <th colSpan={1}>LEVEL</th>
        <th colSpan={3} style={{ minWidth: '150px' }}>
          {moduleData?.level}
        </th>
        <th colSpan={3}></th>
      </tr>

      <TableHeaderHtmlCustom tableHeaderObj={HeaderStandard} />
    </thead>
  );
};

export default React.memo(THead);
