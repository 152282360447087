import { AiOutlineCloudUpload } from 'react-icons/ai';
import { GrEject } from 'react-icons/gr';
import {
  MdFeaturedPlayList,
  MdSummarize,
  MdSupervisorAccount,
} from 'react-icons/md';
const tiles = [
  // {
  //   sNo: 1,
  //   link: '/common/clauseUploadList?documentType=masterProcedureClause3&heading=MASTER PROCEDURE',
  //   isLink: true,
  //   clauseNo: 3,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: 'MASTER',
  //   span: 'PROCEDURE',
  //   isIcon: true,
  // },
  // {
  //   sNo: 2,
  //   link: '/common/4mPdc',
  //   isLink: true,
  //   clauseNo: 3,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: '4M',
  //   span: 'PDC',
  //   isIcon: true,
  // },
  // {
  //   sNo: 3,
  //   link: '/common/4mPressShop',
  //   isLink: true,
  //   clauseNo: 3,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#a12222" />,
  //   p: '4M PRESS',
  //   span: 'SHOP',
  //   isIcon: true,
  // },
  // {
  //   sNo: 4,
  //   link: '/common/4mRotor',
  //   isLink: true,
  //   clauseNo: 3,
  //   icon: <AiOutlineCloudUpload size="5rem" color="#135089" />,
  //   p: '4M',
  //   span: 'ROTOR',
  //   isIcon: true,
  // },
  {
    sNo: 19,
    link: '/common/4mSummaryList',
    isLink: true,
    icon: <MdSummarize size="5rem" color="#003566" />,
    p: '4M',
    span: 'SUMMARY',
    isIcon: true,
  },
  {
    sNo: 9,
    link: '/common/abnormalRejectionReportList',
    isLink: true,
    icon: <GrEject size="5rem" color="indigo" />,
    p: 'ABNORMAL SITUATION',
    span: ' REJECTION REPORT',
    isIcon: true,
  },
  // {
  //   sNo: 6,
  //   link: 'https://uc.secure.aqua.mushinlabs.com',
  //   isLink: true,
  //   clauseNo: 3,
  //   icon: <MdFeaturedPlayList size="5rem" color="#135089" />,
  //   p: '4M MONITERNING',
  //   span: 'REPORTS',
  //   isIcon: true,
  // },
];

export default tiles;
