import { useState } from 'react';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import { Grid, MenuItem } from '@mui/material';
import CustomSelect from '../../../../../components/common/SelectField';
import { convToTime } from '../../../../../utils/helpers';
import { TimePicker } from '@mui/x-date-pickers';

interface IProps {
  moduleData: any;
  isView: boolean;
  handleSumbit: any;
  handelDeleteRows: any;
  isOpenPopup: any;
  setIsOpenPopup: any;
  setPartIdx: any;
}

const TBody = ({
  moduleData,
  isView,
  handleSumbit,
  handelDeleteRows,
  isOpenPopup,
  setIsOpenPopup,
  setPartIdx,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <tr key={index + 'module data'}>
              <td width={'100px'} style={{ fontWeight: 'bold' }}>
                {index + 1}
              </td>
              <td style={{ minWidth: '200px' }}>
                <DatePickerMui
                  label=""
                  value={moment(process.process_date || '')}
                  onChange={(date) => {
                    process.process_date = date;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>
                {isView ? (
                  convToTime(process.time)
                ) : (
                  <TimePicker
                    sx={{
                      width: '150px',
                    }}
                    value={moment(process.time || null)}
                    onChange={(date) => {
                      process.time = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td>
              <td
                style={{
                  width: '280px',
                  minWidth: '280px',
                  textAlign: 'center',
                }}
              >
                {isView ? (
                  process?.part_id && process?.part_id?.part_name
                ) : (
                  <Grid container xs={12} direction="row" alignItems={'center'}>
                    <Grid item xs={10}>
                      <p
                        style={{
                          fontSize: '1.2rem',
                        }}
                      >
                        {typeof process?.part_id === 'object' &&
                        process?.part_id !== null
                          ? process?.part_id?.part_name
                          : process?.partName || ''}
                        {!process?.part_id && 'Pick Part Name'}
                      </p>
                    </Grid>

                    {process.part_id ? (
                      <Grid item xs={2}>
                        <RemoveIconButton
                          tooltipTitle="remove part"
                          onClick={() => {
                            process.part_id = null;
                            process.partNumber = null;
                            process.partName = null;
                            setRender((prev) => !prev);
                          }}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={2}>
                        <AddIconButton
                          tooltipTitle="add part"
                          onClick={() => {
                            setIsOpenPopup(true);
                            setPartIdx(index);
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              </td>
              <td style={{ minWidth: '250px' }}>
                <TableInput
                  isView={isView}
                  name="abnormal_situation"
                  type="text"
                  value={process.abnormal_situation}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="produced_qty"
                  type="number"
                  value={process.produced_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    process.reject_qty = process.produced_qty - process.ok_qty;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="ok_qty"
                  type="number"
                  value={process.ok_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    process.reject_qty = process.produced_qty - process.ok_qty;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>{process.reject_qty}</td>
              <td style={{ minWidth: '150px' }}>
                <TableInput
                  isView={isView}
                  name="disposition"
                  type="text"
                  value={process.disposition}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '150px' }}>
                <TableInput
                  isView={isView}
                  name="eng_sign"
                  type="text"
                  value={process.eng_sign}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              {!isView && (
                <td style={{ minWidth: '100px' }}>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
