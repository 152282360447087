import React, { useRef, useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import { TableCustom } from '../../../../components/common';
import ApprovalSelect from './ApprovalSelect';
import formatDate from '../../../../components/common/formatDate';
import {
  DeleteIconButton,
  EditIconButton,
  ViewIconButton,
} from '../../../../components/common/button/CustomIconButton';
import { useNavigate } from 'react-router-dom';
import { UpdateButton } from '../../../../components/common/button/CustomButtons';
import CustomUploadButton from '../../../../components/common/button/CustomUploadButton';
import ModalCustom from '../../../../components/common/Modal';
import ViewFile from './ViewFile';
import { textInputs } from '../../../../utils/constants/constant';

interface IProps {
  handleChangeStatus: (
    e: React.ChangeEvent<HTMLSelectElement>,
    documentIndex: number
  ) => void;
  deleteDocument: (id: string) => void;
  updateStatus: (id: string, documentIndex: number) => void;
  statusObj: {
    pending: number;
    accepted: number;
    rejected: number;
  };
  uploadFile: (e: any, id: string, fileType: string) => void;
  tableData: {
    [key: string | number]: any;
  }[];
}

const Header = [
  [
    {
      text: '#',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'ITEM NAME',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'INSPECTION REPORT DATE',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'NAME',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'STATUS',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: `UPLOAD ${textInputs.companyAcronym} DOCUMENTS`,
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'UPLOAD VENDOR DOCUMENTS',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'ACTION',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'UPDATE STATUS',
      colspan: 1,
      rowspan: 1,
    },
  ],
];

const AdminTable = ({
  handleChangeStatus,
  deleteDocument,
  updateStatus,
  statusObj,
  tableData,
  uploadFile,
}: IProps) => {
  const navigate = useNavigate();
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const idRef = useRef('');
  const fileTypeRef = useRef('');
  return (
    <React.Fragment>
      <TableCustom tableHeaderObj={Header}>
        <TableBody>
          {tableData.map(
            (document: { [key: string]: any }, documentIndex: number) => (
              <TableRow>
                <TableCell align="center">{documentIndex + 1}</TableCell>
                <TableCell align="center">
                  {document.ri_part_id?.part_name}
                </TableCell>
                <TableCell align="center">
                  {formatDate(document.inspection_date)}
                </TableCell>
                <TableCell align="center">
                  {document.report_prepared_by}
                </TableCell>

                <TableCell align="center">
                  <ApprovalSelect
                    {...{
                      handleChangeStatus,
                      document,
                      documentIndex,
                      statusObj,
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  <Stack
                    direction={'row'}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <CustomUploadButton
                      accept="image/png,image/jpeg,image/jpg,application/pdf"
                      id="upload-iai-documents"
                      multiple={true}
                      onChange={(e) => {
                        uploadFile(e, document._id, 'file1');
                      }}
                    />
                    {document.file1?.length > 0 && (
                      <>
                        <ViewIconButton
                          tooltipTitle="view"
                          sx={{ marginLeft: '1.5rem' }}
                          onClick={() => {
                            idRef.current = document._id;
                            fileTypeRef.current = 'file1';
                            setIsOpenPopup(true);
                          }}
                        />
                      </>
                    )}
                  </Stack>
                </TableCell>
                <TableCell align="center">
                  <Stack
                    direction={'row'}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <CustomUploadButton
                      id="upload-vendor-documents"
                      accept="image/png,image/jpeg,image/jpg,application/pdf"
                      multiple={true}
                      onChange={(e) => {
                        uploadFile(e, document._id, 'file2');
                      }}
                    />
                    {document.file2?.length > 0 && (
                      <>
                        <ViewIconButton
                          tooltipTitle="view"
                          sx={{ marginLeft: '1.5rem' }}
                          onClick={() => {
                            idRef.current = document._id;
                            fileTypeRef.current = 'file2';
                            setIsOpenPopup(true);
                          }}
                        />
                      </>
                    )}
                  </Stack>
                </TableCell>
                <TableCell align="center">
                  <ViewIconButton
                    tooltipTitle="view"
                    onClick={() =>
                      navigate(
                        `/common/inspectionReport/${document.ri_part_id?._id}/${document._id}?isView=true&partName=${document.ri_part_id?.part_name}`
                      )
                    }
                  />
                  <EditIconButton
                    tooltipTitle="edit"
                    onClick={() =>
                      navigate(
                        `/common/inspectionReport/${document.ri_part_id?._id}/${document._id}?isView=false&partName=${document.ri_part_id?.part_name}`
                      )
                    }
                  />
                  <DeleteIconButton
                    tooltipTitle="delete"
                    onClick={() => deleteDocument(document._id)}
                  />
                </TableCell>
                <TableCell align="center">
                  <UpdateButton
                    label="update"
                    size="small"
                    onClick={() => updateStatus(document._id, documentIndex)}
                  />
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </TableCustom>
      <ModalCustom
        title="FILE LIST"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <ViewFile id={idRef.current} fileType={fileTypeRef.current} />
      </ModalCustom>
    </React.Fragment>
  );
};

export default AdminTable;
