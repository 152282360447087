import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import { AddIconButton, DeleteIconButton, RemoveIconButton } from '../../../../../components/common/button/CustomIconButton';
import moment from 'moment';
import { useEffect, useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import { Grid, MenuItem } from '@mui/material';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import axios from 'axios';
import CustomSelect from '../../../../../components/common/SelectField';
import formatDate from '../../../../../components/common/formatDate';

interface IProps {
  moduleData: any;
  isView: boolean;
  handleSumbit: any;
  autoCompleteMachineValue: any;
  handelDeleteRows: any;
  isOpenPopup: any;
  setIsOpenPopup: any;
  setPartIdx: any;
  setRender:any;
  def:any;
  setIsOpenPopupRejections:any;
  setRejectionsIndx:any;
  x:any
}


const TBody = ({   moduleData,
  isView,
  handleSumbit,
  autoCompleteMachineValue,
  handelDeleteRows,
  isOpenPopup,
  setIsOpenPopup,
  setPartIdx,
  def,
  setIsOpenPopupRejections,
  setRejectionsIndx,
  setRender,x }: IProps) => {

    console.log("moduleData is",moduleData);
const [emp,setEmp]=useState([]);
    const fetchData = async () => {
      const res = await axios
        .get(
          `/api/employee/employeeAll/ForAutocomplete`
        )
        .catch((err) => {
          console.log(err);
        });
        console.log("emp list is ",res?.data);
       setEmp(res?.data);
     
    };
    useEffect(() => {
      fetchData();
     
    }, [])
    
  return (
    
    <>
      <tbody>
        {moduleData && x==1 &&
          moduleData?.processes?.map((process: any, index: number) => (
            <>
              <tr>
                <td style={{ minWidth: '100px', textAlign: 'center' }}>
                  {index + 1}
                </td>
                <td style={{ minWidth: '250px' }}>
                {isView ? (
                  process?.part_id && process?.part_id?.part_name
                ) : (
                  <Grid container xs={12} direction="row" alignItems={'center'}>
                    <Grid item xs={10}>
                      <p
                        style={{
                          fontSize: '1.6rem',
                        }}
                      >
                        {typeof process?.part_id === 'object' &&
                        process?.part_id !== null
                          ? process?.part_id?.part_name
                          : process?.partName || ''}
                        {!process?.part_id && 'Pick Part Name'}
                      </p>
                    </Grid>

                    {process.part_id ? (
                      <Grid item xs={2}>
                        <RemoveIconButton
                          tooltipTitle="remove part"
                          onClick={() => {
                            process.part_id = null;
                            process.part_number = null;
                            process.part_name = null;
                            process.partName = null;
                            process.partNumber = null;
                            setRender((prev:any) => !prev);
                          }}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={2}>
                        <AddIconButton
                          tooltipTitle="add part"
                          onClick={() => {
                            setIsOpenPopup(true);
                            setPartIdx(index);
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
                </td>
                <td style={{ minWidth: '250px' }}>
                  { process?.partNumber || process?.part_id?.part_number}
                </td>
              
                <td
                  style={{
                    width: '300px',
                    minWidth: '300px',
                  }}
                >
                  <div
                    style={{
                      ...(!isView && { display: 'flex' }),
                      ...(!isView && { justifyContent: 'space-between' }),
                    }}
                  >
                    <div style={{ border: 'none' }}>
                      {process?.reasons_for_rej?.map((item: any) => {
                        return item?.reason ? (
                          <span>
                            {item?.reason}- {item?.rej_qty}
                            <br />
                            <hr />
                          </span>
                        ) : null;
                      })}
                    </div>
                    {!isView && (
                      <AddIconButton
                        tooltipTitle="add defects"
                        onClick={() => {
                          setIsOpenPopupRejections(true);
                          setRejectionsIndx(index);
                        }}
                      />
                    )}
                  </div>
                </td>
                
                <td style={{ minWidth: '250px' }}>
                  <TableInput
                    isView={true}
                    name="rej_qty"
                    type="Number"
                    value={process?.rej_qty}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev: any) => !prev);
                    }}
                  />
                </td>
                <td style={{ minWidth: '250px' }}>
                  <TableInput
                    isView={isView}
                    name="reason_of_defect"
                    type="text"
                    value={process?.reason_of_defect}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev: any) => !prev);
                    }}
                  />
                </td>
                <td style={{ minWidth: '250px' }}>
                  <TableInput
                    isView={isView}
                    name="corr_action"
                    type="text"
                    value={process?.corr_action}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev: any) => !prev);
                    }}
                  />
                </td>
                <td style={{ minWidth: '250px' }}>
                  <TableInput
                    isView={isView}
                    name="prev_action"
                    type="text"
                    value={process?.prev_action}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev: any) => !prev);
                    }}
                  />
                </td>
                <td style={{ minWidth: '250px' }}>
                  <TableInput
                    isView={isView}
                    name="resp"
                    type="text"
                    value={process?.resp}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev: any) => !prev);
                    }}
                  />
                </td>
                <td style={{ minWidth: '250px' }}>
                  {isView?(
               process?.target_date && formatDate(process?.target_date)        
                 ):(

                    <DatePickerMui
                      label=""
                      value={moment(process?.target_date) || null}
                      onChange={(date:any) => {
                        // handleChangeModuleData(e, process);
                        process.target_date=date;
                        setRender((prev: any) => !prev);
                      }}
                    />
                  )}
                </td>
                <td style={{ minWidth: '250px' }}>
                  <TableInput
                    isView={isView}
                    name="remarks"
                    type="text"
                    value={process?.remarks}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev: any) => !prev);
                    }}
                  />
                </td>

                {!isView && (
                  <td style={{ minWidth: '100px', textAlign: 'center' }}>
                    <DeleteIconButton
                      tooltipTitle={'delete'}
                      onClick={() => handelDeleteRows(index,1)}
                    />
                  </td>
                )}
              </tr>
            </>
          ))}
        {moduleData && x==2 &&
          moduleData?.employees?.map((process: any, index: number) => (
            <>
              <tr>
                <td colSpan={1} style={{ minWidth: '100px', textAlign: 'center' }}>
                  {index + 1}
                </td>
                <td colSpan={2} style={{ minWidth: '250px' }}>
                {isView?(process?.emp_id?.employee_name):(

                  <AutocompleteMuiCustom
                    id="employee_name"
                    label=""
                    option_name="employee_name"
                    
                    value={process?.emp_id || '' }
                    arrayofObj={emp}
                    onChange={(e,value:any) => {
                      // handleChangeModuleData(e, process);
                      process.emp_id=value;
                      setRender((prev: any) => !prev);
                    }}
                  />
                )}
                
                </td>
                <td colSpan={2} style={{ minWidth: '250px' }}>
                  {process?.emp_id?.department_id?.department_name}
                </td>
                <td  colSpan={2} style={{ minWidth: '250px' }}>
                  {process?.emp_id?.designation}
                </td>
              
                
                <td colSpan={2} style={{ minWidth: '250px' }}>
                  {isView?(
                    process?.attend
                  ):(

                    <CustomSelect
                     name="attend"
                     value={process?.attend || ''}
                     onChange={(e) => {
                       process.attend = e.target.value;
                       setRender((prev:any) => !prev);
                     }}
                     sx={{
                       textAlign: 'center',
                     }}
                   >
                     <MenuItem value="">NONE</MenuItem>
                     <MenuItem value="P">P</MenuItem>
                     <MenuItem value="A">A</MenuItem>
                   </CustomSelect>
                  )}
                </td>
                
                <td colSpan={2} style={{ minWidth: '250px' }}>
                  <TableInput
                    isView={isView}
                    name="remarks"
                    type="text"
                    value={process?.remarks}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev: any) => !prev);
                    }}
                  />
                </td>
                
               

                {!isView && (
                  <td style={{ minWidth: '100px', textAlign: 'center' }}>
                    <DeleteIconButton
                      tooltipTitle={'delete'}
                      onClick={() => handelDeleteRows(index,2)}
                    />
                  </td>
                )}
              </tr>
            </>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
