import lodash from 'lodash';
import moment from 'moment';

export const processes = {
    operation_stage:"",
    illuminance_level:null,
    observation:new Array(6).fill(""),
};

export const module_data = {
  module_name:"",
  // annual_month:"",
  checked_by: "",
  approved_by:"",
  date:lodash.cloneDeep(new Array(6).fill("")),
  processes:[
    {
      operation_stage:"INCOMING INSPECTION",
      illuminance_level:1000,
      observation: lodash.cloneDeep(new Array(6).fill("")),
    },
    {
      operation_stage:"RESIZING & DRILLING",
      illuminance_level:400,
      observation: lodash.cloneDeep(new Array(6).fill("")),
    },
    {
      operation_stage:"CLIPPING",
      illuminance_level:400,
      observation: lodash.cloneDeep(new Array(6).fill("")),
    },
    {
      operation_stage:"COMPOUND CUTTING",
      illuminance_level:400,
      observation: lodash.cloneDeep(new Array(6).fill("")),
    },
    {
      operation_stage:"MOULDING",
      illuminance_level:400,
      observation: lodash.cloneDeep(new Array(6).fill("")),
    },
    {
      operation_stage:"TRIMMING",
      illuminance_level:450,
      observation: lodash.cloneDeep(new Array(6).fill("")),
    },
    {
      operation_stage:"PADDING",
      illuminance_level:400,
      observation: lodash.cloneDeep(new Array(6).fill("")),
    },
    {
      operation_stage:"NOTCHING",
      illuminance_level:400,
      observation: lodash.cloneDeep(new Array(6).fill("")),
    },
    {
      operation_stage:"TAPPING",
      illuminance_level:400,
      observation: lodash.cloneDeep(new Array(6).fill("")),
    },
    {
      operation_stage:"NORSEAL APPLICATION",
      illuminance_level:400,
      observation:lodash.cloneDeep(new Array(6).fill("")),
    },
    {
      operation_stage:"SILICON COATING",
      illuminance_level:400,
      observation: lodash.cloneDeep(new Array(6).fill("")),
    },
    {
      operation_stage:"GLUEING APPLICATION",
      illuminance_level:400,
      observation: lodash.cloneDeep(new Array(6).fill("")),
    },
    {
      operation_stage:"PRIMER APPLICATION",
      illuminance_level:400,
      observation: lodash.cloneDeep(new Array(6).fill("")),
    },
    {
      operation_stage:"SILICON APPLICATION",
      illuminance_level:400,
      observation: lodash.cloneDeep(new Array(6).fill("")),
    },
    {
      operation_stage:"FINAL INSPECTION",
      illuminance_level:1000,
      observation: lodash.cloneDeep(new Array(6).fill("")),
    },
    {
      operation_stage:"PRE - DESPATCH INSPECTION",
      illuminance_level:1000,
      observation: lodash.cloneDeep(new Array(6).fill("")),
    },
    {
      operation_stage:"FLOCKING",
      illuminance_level:400,
      observation: lodash.cloneDeep(new Array(6).fill("")),
    },
    {
      operation_stage:"FIREWALL",
      illuminance_level:1000,
      observation: lodash.cloneDeep(new Array(6).fill("")),
    },
    
  ]  
}