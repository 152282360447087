import { MenuItem } from '@mui/material';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import {
  AddIconButton,
  DeleteIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import CustomSelect from '../../../../../components/common/SelectField';
import { useState, useRef } from 'react';
import ModalCustom from '../../../../../components/common/Modal';
import MouldView from './MouldView';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
  setRender: any;
  moulders: any;
  employees: any;
  operators: any;
}
const filterEmployees = (employees: any, employeeType: any) => {
  const newEmployees = employees.filter(
    (item: any) => item?.category === employeeType
  );
  return newEmployees;
};
const getTotalProducedQty = (process: any) => {
  return (
    +process?.total_ok_qty +
    +process?.total_reason_qty +
    +process?.spd_mkt +
    +process?.closing_wip_qty -
    +process?.opening_wip_qty
  );
};
const TBody = ({
  moduleData,
  isView,
  handelDeleteRows,
  setRender,
  moulders,
  employees,
  operators,
}: IProps) => {
  const [isMouldOpenPopup, setIsMouldOpenPopup] = useState(false);
  const [processIndex, setProcessIndex] = useState(0);
  const processObj = useRef({});
  const keyNo = useRef(1);
  const partId = useRef('');

  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <>
              <tr>
                <td style={{ minWidth: '100px', textAlign: 'center' }}>
                  {index + 1}
                </td>
                <td style={{ minWidth: '250px' }}>
                  {process?.part_id?.part_name}
                </td>
                <td style={{ minWidth: '250px' }}>
                  {process?.part_id?.model_id?.model_name}
                </td>
                <td style={{ minWidth: '200px' }}>
                  {process?.shift_engr_name}
                </td>
                <td style={{ minWidth: '100px' }}>
                  {process?.total_target_qty}
                </td>
                <td style={{ minWidth: '100px' }}>
                  {process?.total_check_qty}
                </td>
                <td style={{ minWidth: '100px' }}>
                  {process?.total_reason_qty}
                </td>
                <td style={{ minWidth: '100px' }}>{process?.total_ok_qty}</td>
                <td style={{ minWidth: '100px' }}>
                  <TableInput
                    isView={isView}
                    name="opening_wip_qty"
                    type="number"
                    value={process.opening_wip_qty}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      process.produced_qty = getTotalProducedQty(process);
                      setRender((prev: any) => !prev);
                    }}
                  />
                </td>
                <td style={{ minWidth: '100px' }}>
                  <TableInput
                    isView={isView}
                    name="closing_wip_qty"
                    type="number"
                    value={process.closing_wip_qty}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      process.produced_qty = getTotalProducedQty(process);
                      setRender((prev: any) => !prev);
                    }}
                  />
                </td>
                <td style={{ minWidth: '100px' }}>{process?.produced_qty}</td>
                <td
                  style={{
                    minWidth: '300px',
                  }}
                >
                  <div
                    style={{
                      ...(!isView && { display: 'flex' }),
                      ...(!isView && { justifyContent: 'space-between' }),
                    }}
                  >
                    <div style={{ border: 'none' }}>
                      {process?.mould_id_1?.map((item: any) => (
                        <span>
                          {item?.moulding_name}
                          <br />
                          <hr />
                        </span>
                      ))}
                    </div>
                    {!isView && (
                      <AddIconButton
                        tooltipTitle="Add Mould"
                        onClick={() => {
                          if (process?.mould_id_1?.length === 0) {
                            process.mould_id_1 = [];
                          }
                          keyNo.current = 1;
                          setProcessIndex(index);
                          setIsMouldOpenPopup(true);
                        }}
                      />
                    )}
                  </div>
                </td>
                <td
                  style={{
                    minWidth: '300px',
                  }}
                >
                  <div
                    style={{
                      ...(!isView && { display: 'flex' }),
                      ...(!isView && { justifyContent: 'space-between' }),
                    }}
                  >
                    <div style={{ border: 'none' }}>
                      {process?.mould_id_2?.map((item: any) => (
                        <span>
                          {item?.moulding_name}
                          <br />
                          <hr />
                        </span>
                      ))}
                    </div>
                    {!isView && (
                      <AddIconButton
                        tooltipTitle="Add Mould"
                        onClick={() => {
                          if (process?.mould_id_2?.length === 0) {
                            process.mould_id_2 = [];
                          }
                          keyNo.current = 2;
                          setProcessIndex(index);
                          setIsMouldOpenPopup(true);
                        }}
                      />
                    )}
                  </div>
                </td>
                <td
                  style={{
                    minWidth: '300px',
                  }}
                >
                  <div
                    style={{
                      ...(!isView && { display: 'flex' }),
                      ...(!isView && { justifyContent: 'space-between' }),
                    }}
                  >
                    <div style={{ border: 'none' }}>
                      {process?.mould_id_3?.map((item: any) => (
                        <span>
                          {item?.moulding_name}
                          <br />
                          <hr />
                        </span>
                      ))}
                    </div>
                    {!isView && (
                      <AddIconButton
                        tooltipTitle="Add Mould"
                        onClick={() => {
                          if (process?.mould_id_3?.length === 0) {
                            process.mould_id_3 = [];
                          }
                          keyNo.current = 3;
                          setProcessIndex(index);
                          setIsMouldOpenPopup(true);
                        }}
                      />
                    )}
                  </div>
                </td>
                <td align="left" style={{ minWidth: '200px' }}>
                  {isView ? (
                    process?.moulder_id?.operator_name
                  ) : (
                    <AutocompleteMuiCustom
                      id="operator_name"
                      label=""
                      option_name="operator_name"
                      option_name_2="floor"
                      value={process?.moulder_id || ''}
                      arrayofObj={operators}
                      onChange={(e: any, value: any) => {
                        process.moulder_id = value;
                        setRender((prev: any) => !prev);
                      }}
                    />
                  )}
                </td>
                {/* <td align="left" style={{ minWidth: '200px' }}>
                  {isView ? (
                    process?.moulder_id?.m_name
                  ) : (
                    <AutocompleteMuiCustom
                      id="employee_name"
                      label=""
                      option_name="employee_name"
                      value={process?.employee || process?.employee_id || ''}
                      arrayofObj={filterEmployees(employees, 'Operator')}
                      onChange={(e: any, value: any) => {
                        if (value) {
                          process.employee_id = value?._id;
                          process.employee = value;
                        }
                        setRender((prev: any) => !prev);
                      }}
                    />
                  )}
                </td> */}
                <td style={{ minWidth: '100px' }}>
                  {process?.total_rework_qty}
                </td>
                <td style={{ minWidth: '100px' }}>
                  {process?.total_scrap_aa_qty}
                </td>
                <td style={{ minWidth: '100px' }}>
                  {process?.total_scrap_bb_qty}
                </td>
                <td style={{ minWidth: '100px' }}>
                  {process?.total_scrap_cc_qty}
                </td>
                <td style={{ minWidth: '100px' }}>
                  {process?.total_scrap_dd_qty}
                </td>

                {/* <td style={{ minWidth: '100px' }}>
                  <TableInput
                    isView={isView}
                    name="scrap_aa_time"
                    type="number"
                    value={process.scrap_aa_time}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev: any) => !prev);
                    }}
                  />
                </td>
                <td style={{ minWidth: '100px' }}>
                  <TableInput
                    isView={isView}
                    name="scrap_bb_time"
                    type="number"
                    value={process.scrap_bb_time}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev: any) => !prev);
                    }}
                  />
                </td>
                <td style={{ minWidth: '100px' }}>
                  <TableInput
                    isView={isView}
                    name="scrap_cc_time"
                    type="number"
                    value={process.scrap_cc_time}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev: any) => !prev);
                    }}
                  />
                </td>
                <td style={{ minWidth: '100px' }}>
                  <TableInput
                    isView={isView}
                    name="scrap_dd_time"
                    type="number"
                    value={process.scrap_dd_time}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev: any) => !prev);
                    }}
                  />
                </td> */}
                <td style={{ minWidth: '100px' }}>
                  <TableInput
                    isView={isView}
                    name="spd_mkt"
                    type="number"
                    value={process.spd_mkt}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      process.produced_qty = getTotalProducedQty(process);
                      setRender((prev: any) => !prev);
                    }}
                  />
                </td>
                <td style={{ minWidth: '100px' }}>
                  <TableInput
                    isView={isView}
                    name="standard_qty"
                    type="number"
                    value={process.standard_qty}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev: any) => !prev);
                    }}
                  />
                </td>
                <td style={{ minWidth: '100px' }}>
                  <TableInput
                    isView={isView}
                    name="actual_qty"
                    type="number"
                    value={process.actual_qty}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev: any) => !prev);
                    }}
                  />
                </td>
                <td style={{ minWidth: '200px' }}>
                  <TableInput
                    isView={isView}
                    name="breakdown_time"
                    type="number"
                    value={process.breakdown_time}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev: any) => !prev);
                    }}
                  />
                </td>
                <td style={{ minWidth: '250px' }}>
                  {isView ? (
                    process?.breakdown_reasons
                  ) : (
                    <CustomSelect
                      name={'breakdown_reasons'}
                      value={process?.breakdown_reasons}
                      onChange={(e) => {
                        process.breakdown_reasons = e.target.value;
                        setRender((prev: any) => !prev);
                      }}
                    >
                      <MenuItem value="ELEC.">1-- ELEC.</MenuItem>
                      <MenuItem value="MECH.">2-- MECH.</MenuItem>
                      <MenuItem value="Mould Problem">
                        3-- Mould Problem
                      </MenuItem>
                      <MenuItem value="Compound Problem">
                        4-- Compound Problem
                      </MenuItem>
                      <MenuItem value="C/O">5-- C/O</MenuItem>
                      <MenuItem value="Process Problem">
                        6-- Process Problem
                      </MenuItem>
                      <MenuItem value="No Power">7-- No Power</MenuItem>
                      <MenuItem value="No Material">8-- No Material</MenuItem>
                      <MenuItem value="No Plan">9-- No Plan</MenuItem>
                      <MenuItem value="No Manpower">10-- No Manpower</MenuItem>
                      <MenuItem value="New Manpower">
                        11-- New Manpower
                      </MenuItem>
                      <MenuItem value="Late Manpower">
                        12-- Late Manpower
                      </MenuItem>
                      <MenuItem value="Utility">13-- Utility</MenuItem>
                      <MenuItem value="Late Start module">
                        14-- Late Start module
                      </MenuItem>
                      <MenuItem value="NPD Activity">
                        15-- NPD Activity
                      </MenuItem>
                      <MenuItem value="Mtr Quality Problem">
                        16-- Mtr Quality Problem
                      </MenuItem>
                      <MenuItem value="Tooling Breakdown">
                        17-- Tooling Breakdown
                      </MenuItem>
                      <MenuItem value="High Rejection">
                        18-- High Rejection
                      </MenuItem>
                    </CustomSelect>
                  )}
                </td>
                <td style={{ minWidth: '250px' }}>
                  <TableInput
                    isView={isView}
                    name="remarks"
                    type="test"
                    value={process.remarks}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev: any) => !prev);
                    }}
                  />
                </td>

                {!isView && (
                  <td style={{ minWidth: '100px', textAlign: 'center' }}>
                    <DeleteIconButton
                      tooltipTitle={'delete'}
                      onClick={() => handelDeleteRows(index)}
                    />
                  </td>
                )}
              </tr>
            </>
          ))}
      </tbody>
      <ModalCustom
        title="Mould List"
        openModal={isMouldOpenPopup}
        closeModal={() => {
          setIsMouldOpenPopup(false);
        }}
      >
        <MouldView
          process={moduleData?.processes[processIndex]}
          keyNo={keyNo.current}
        />
      </ModalCustom>
    </>
  );
};

export default TBody;
