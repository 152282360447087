import React, { useEffect, useState } from 'react';
import GridInputs from '../../components/common/inputs/GridInputs';
import { IData } from './Interface';
import axios from 'axios';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import useConfirm from '../../components/common/useConfirm';
import { SelectChangeEvent } from '@mui/material';
import moment from 'moment';
import DateGridMui from '../../components/common/dateCustom/DateGridMui';
import AutocompleteGridmui from '../../components/common/autocomplete/AutocompleteGridmui';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [machine, setMachine] = useState<IData>({});
  const [department, setDepartment] = useState([]);
  const [, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Machine' : 'Create Machine'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    axios
      .get(`/api/department/departmentAll/ForAutocomplete`)
      .then((res) => setDepartment(res.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/machineRoutes/${id}`)
        .then((res) => {
          setMachine(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleModuleData(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    setMachine((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/machineRoutes/${id}`, machine)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/machineRoutes', machine)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <GridInputs
        id={'machine_name'}
        name={'machine_name'}
        html_for={'machine_name'}
        label_name={'Machine Name :'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.machine_name}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'machine_no'}
        name={'machine_no'}
        html_for={'machine_no'}
        label_name={'Machine Number (Equipment No.):'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.machine_no}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'maker'}
        name={'maker'}
        html_for={'maker'}
        label_name={'Maker:'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.maker}
        onChange={handleModuleData}
      />
      <DateGridMui
        lable="Date of Installation:"
        onChange={(date: any) => {
          machine.date_of_install = date;
          setRender((prev) => !prev);
        }}
        value={
          (machine?.date_of_install && moment(machine?.date_of_install)) || null
        }
      />
      <GridInputs
        id={'service_freq'}
        name={'service_freq'}
        html_for={'service_freq'}
        label_name={'Service freq.:'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.service_freq}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'make'}
        name={'make'}
        html_for={'make'}
        label_name={'Make / Type of Machine or Fixture:'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.make}
        onChange={handleModuleData}
      />

      <GridInputs
        id={'condition'}
        name={'condition'}
        html_for={'condition'}
        label_name={'Condition S / R / UNSV:'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.condition}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'pressure_guage_range'}
        name={'pressure_guage_range'}
        html_for={'pressure_guage_range'}
        label_name={'Pressure Gauge Range:'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.pressure_guage_range}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'pressure_guage_make'}
        name={'pressure_guage_make'}
        html_for={'pressure_guage_make'}
        label_name={'Make of Pressure Gauge:'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.pressure_guage_make}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'pressure_guage_no'}
        name={'pressure_guage_no'}
        html_for={'pressure_guage_no'}
        label_name={'Pressure Gauge No.:'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.pressure_guage_no}
        onChange={handleModuleData}
      />
      <AutocompleteGridmui
        label={'Department'}
        id={'department_name'}
        option_name={'department_name'}
        value={machine.department_id_temp || machine.department_id || ''}
        arrayofObj={department}
        onChange={(e, value) => {
          machine.department_id = value?._id;
          machine.department_id_temp = value;
          setRender((prev) => !prev);
        }}
        last_child={2}
      />
      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remarks:'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.remarks}
        onChange={handleModuleData}
      />

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
