import React from 'react';
import { TableInput } from '../../../../../components/common';
import { useQuery } from '../../../../../hooks/UseQuery';
import {
    DeleteIconButton,
    UploadImageIconButton,
    ViewIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../../components/common/formatDate';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';

export const TableRowComponent = React.memo(
    ({
        key,
        item,
        index,
        allmonth,
        updateModuleData,
        handleDelete,
        uploadImageFile,
        moduleId,
    }: any) => {
        const isView = useQuery('isView') === 'true' ? true : false;
        const rowId = item?._id || item?._tempId;

        const handleInputChange = (
            fieldName: string,
            value: string | number | null,
        ) => {
            updateModuleData('row', index, fieldName, { [fieldName]: value });
        };

        return (
            <React.Fragment key={key}>
                <tr>
                    <td>{index + 1}</td>
                    <td>
                        <TableInput
                            isView={isView}
                            style={{ height: '80px' }}
                            name="checkpoint"
                            type="text"
                            value={item.checkpoint}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td>
                        <TableInput
                            isView={isView}
                            style={{ height: '80px' }}
                            name="method"
                            type="text"
                            value={item.method}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td>
                        <TableInput
                            isView={isView}
                            style={{ height: '80px' }}
                            name="judgement_criteria"
                            type="text"
                            value={item.judgement_criteria}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    {/*<td>
                        {moduleId && (
                            <Stack
                                direction="row"
                                sx={{ justifyContent: 'center', alignItems: 'center' }}
                            >
                                <UploadImageIconButton
                                    id={`${index}-process-upload`}
                                    tooltipTitle="upload"
                                    onChange={(e) => {
                                        uploadImageFile(e, index);
                                    }}
                                />
                                {item.files?.length > 0 && (
                                    <>
                                        <a target={'_blank'} href={item.files[0]?.url}>
                                            <ViewIconButton tooltipTitle={'view'} />
                                        </a>
                                        <DeleteIconButton
                                            tooltipTitle={'delete'}
                                            onClick={() => {
                                                updateModuleData('row', index, 'files', { files: [] });
                                            }}
                                        />
                                    </>
                                )}
                            </Stack>
                        )}
                    </td>*/}
                    <td>
                        <TableInput
                            isView={isView}
                            style={{ height: '80px' }}
                            name="before"
                            type="text"
                            value={item.before}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td>
                        <TableInput
                            isView={isView}
                            style={{ height: '80px' }}
                            name="after"
                            type="text"
                            value={item.after}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td>
                        <TableInput
                            isView={isView}
                            style={{ height: '80px' }}
                            name="done_by"
                            type="text"
                            value={item.done_by}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td>
                        <TableInput
                            isView={isView}
                            style={{ height: '80px' }}
                            name="history_card"
                            type="text"
                            value={item.history_card}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td>
                        <TableInput
                            isView={isView}
                            style={{ height: '80px' }}
                            name="history_card_no"
                            type="text"
                            value={item.history_card_no}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td>
                        <TableInput
                            isView={isView}
                            style={{ height: '80px' }}
                            name="verify_by"
                            type="text"
                            value={item.verify_by}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td style={{ minWidth: '150px' }}>
                        {isView ? (
                            formatDate(item.verify_date)
                        ) : (
                            <DatePickerMui
                                label=""
                                value={moment(item.verify_date || null)}
                                onChange={(date) => {
                                    handleInputChange('verify_date', date);
                                }}
                            />
                        )}
                    </td>
                    <td>
                        <TableInput
                            isView={isView}
                            style={{ height: '80px' }}
                            name="remark"
                            type="text"
                            value={item.remark}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    {!isView && (
                        <td style={{ minWidth: '50px' }}>
                            <DeleteIconButton
                                tooltipTitle={'delete'}
                                onClick={() => handleDelete(index)}
                            />
                        </td>
                    )}
                </tr>
            </React.Fragment>
        );
    },
    (prevProps, nextProps) => {
        // Check if the relevant data for this row has changed
        return (
            prevProps.item === nextProps.item && prevProps.index === nextProps.index
        );
    },
);
