import { Box, Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

import { PersitedDatePicker, TableCustom } from '../../../../components/common';
import { AddButton } from '../../../../components/common/button/CustomButtons';
import ModalCustom from '../../../../components/common/Modal';
import ChildHeader from '../../../../components/ui/ChildHeader';
import { useQuery } from '../../../../hooks/UseQuery';
import { useAuth } from '../../../../services/auth/AuthProvider';
import ModalInput from './ModalInput';
import {
    listConstant,
    apiConstant,
    navigationConstant,
} from './helpers/constant';
import React from 'react';
import {
    EditIconButton,
    DeleteIconButton,
    ViewIconButton,
} from '../../../../components/common/button/CustomIconButton';
import { useNavigate } from 'react-router-dom';
import SettingsPanel from './SettingsPanel'; // Import the new component

const getYearRange = (currentDate: any) => {
    if (!currentDate) return;
    const year = new Date(currentDate).getFullYear();
    return `${year} - ${year + 1}`;
};

const tableHeaderObj = [
    [
        { text: '#', rowspan: 1, colspan: 1 },
        { text: 'Title', rowspan: 1, colspan: 1 },
        { text: 'Date Range', rowspan: 1, colspan: 1 },
        // { text: 'Line', rowspan: 1, colspan: 1 },
        // { text: 'Machine', rowspan: 1, colspan: 1 },
        // { text: 'Plant Location', rowspan: 1, colspan: 1 },
        { text: 'Action', rowspan: 1, colspan: 1 },
    ],
];

const TableList = () => {
    const { state } = useAuth();
    const { user } = state;
    const date = useQuery('date');
    const [tableData, setTableData] = useState<any[]>([]);
    const [listId, setListId] = useState('');
    const [render, setRender] = useState(false);
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!date) return;
        axios
            .get(`/api/${apiConstant.gettableListDataurl}/${date}`)
            .then((res) => {
                if (res.data) setTableData(res.data);
            })
            .catch((err) => console.log(err));
    }, [render, date, isOpenPopup]);

    const handleDelete = async (id: string) => {
        let isDeleted = window.confirm(
            'Are you sure you want to delete this document?',
        );
        if (isDeleted) {
            axios
                .delete(`/api/${apiConstant.deleteListurl}/${id}`)
                .then(() => {
                    alert('document deleted successfully');
                    setRender((prev) => !prev);
                })
                .catch((err) => {
                    console.log(err);
                });
            return;
        }
    };

    const handleNavigate = useCallback(
        (id: any, dateRange: any) => {
            navigate(
                `${navigationConstant.planRouter}/${id}?yearRange=${getYearRange(
                    dateRange,
                )}&plan_date=${dateRange}`,
            );
        },
        [navigate],
    );

    const handleEdit = useCallback((id: any) => {
        setListId(id);
        setIsOpenPopup(true);
    }, []);

    return (
        <Box>
            <ChildHeader text={`${listConstant.childHeader}`}>
                <AddButton
                    label={`${listConstant.addButtonLable}`}
                    onClick={() => setIsOpenPopup(true)}
                />
            </ChildHeader>
            <Box sx={{ margin: 2 }}>
                <PersitedDatePicker
                    label={`${listConstant.datePickerLabel}`}
                    views={['year']}
                />
            </Box>
            <TableCustom tableHeaderObj={tableHeaderObj}>
                <TableBody>
                    {tableData &&
                        tableData.map((item: any, index: number) => (
                            <React.Fragment key={item._id}>
                                <TableRow>
                                    <TableCell align="center">{index + 1}</TableCell>
                                    <TableCell align="center">{item?.title}</TableCell>
                                    <TableCell align="center">
                                        {getYearRange(item.date_range)}
                                    </TableCell>
                                    {/* <TableCell align="center">{item?.line_id?.name}</TableCell>
                                    <TableCell align="center">{item?.machine_id?.machine_name + " " + item?.machine_id?.machine_no}</TableCell>
                                    <TableCell align="center">{item?.plant_location_id?.name}</TableCell> */}
                                    <TableCell>
                                        <Stack direction="row" sx={{ justifyContent: 'center' }}>
                                            <ViewIconButton
                                                tooltipTitle="create plan"
                                                onClick={() =>
                                                    handleNavigate(item?._id, item?.date_range)
                                                }
                                            />
                                            <EditIconButton
                                                tooltipTitle="EDIT"
                                                onClick={() => {
                                                    handleEdit(item?._id);
                                                }}
                                            />
                                            <DeleteIconButton
                                                tooltipTitle="DELETE"
                                                onClick={() => {
                                                    handleDelete(item?._id || '');
                                                }}
                                            />
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                </TableBody>
            </TableCustom>
            <ModalCustom
                title={`${listConstant.modalTile}`}
                openModal={isOpenPopup}
                closeModal={() => {
                    setIsOpenPopup(false);
                    setListId('');
                }}
            >
                <ModalInput
                    setIsModal={setIsOpenPopup}
                    id={listId}
                    setListId={setListId}
                />
            </ModalCustom>
            <SettingsPanel />
        </Box>
    );
};

export default TableList;
