import axios from 'axios';
import { useState, useEffect } from 'react';
import { useQuery } from '../../../../hooks/UseQuery';
import lodash from 'lodash';

const useGetData = (partId: string | undefined, pdirId: string | undefined) => {
  const [moduleData, setModuleData] = useState<any>({
    pdir_date: new Date(),
    pdirs: [],
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [machineData, setMachineData] = useState([]);
  const partName = useQuery('partName');
  const partNumber = useQuery('partNumber');
  const customerName = useQuery('customerName');
  const supplierName = useQuery('supplierName');
  useEffect(() => {
    axios
      .get('/api/machineRoutes/machine/ForAutocomplete')
      .then((res) => {
        setMachineData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    setIsLoading(true);
    if (pdirId) {
      axios
        .get(`/api/inspectionReportNewRoute/${pdirId}`)
        .then((res) => setModuleData(res.data))
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    } else {
      axios
        .get(`/api/inspectionReportNewRoute/getSpecFromPdiStd/${partId}`)
        .then((res) => {
          const response = res.data;
          setModuleData((item: any) => {
            item.part_id = partId;
            item.part_name = partName;
            item.part_number = partNumber;
            item.customer_name = customerName;
            item.supplier_name = supplierName;
            // for temp basic
            // item.revision_no = response.revision_no;
            // item.revision_date = response.revision_date;
            item.pdir_date = new Date();
            item.pdirs = response.map((process: any) => {
              const processObj = {
                quality_plan_no: process.quality_plan_no,
                drawing_no: process.drawing_no,
                drawing_rev_no: process.drawing_rev_no,
                drg_mod_no: '',
                report_no: '',
                qty: '',
                file: process.file,
                pdir_list: [],
              };
              processObj.pdir_list = process.specs.map((spec: any) => {
                return {
                  // start of spec in pdiStandard
                  parameter: spec.parameter,
                  specification: spec.specification,
                  methods: spec.methods,
                  methods_by: spec.freq,
                  check4_symbol: spec.check4_symbol,
                  checkbox4_num: spec.checkbox4_num,
                  appearance: spec.appearance,
                  mean: spec.mean,
                  utolerance: spec.utolerance,
                  ltolerance: spec.ltolerance,
                  utl: spec.utl,
                  ltl: spec.ltl,
                  check_0_colors: Array.from(Array(5)),
                  check_0_values: Array.from(Array(5)),
                };
              });
              return lodash.cloneDeep(processObj);
            });
            return { ...item };
          });
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err);
        });
    }
  }, []);
  return { moduleData, isLoading, error, machineData };
};

export default useGetData;
