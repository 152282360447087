import axios from 'axios';
import React, { useEffect, useState } from 'react';
import TBodyRow from './TBodyRow';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
  handleSumbit: any;
  uploadImageFile: any;
}

const TBody = ({
  moduleData,
  handleSumbit,
  isView,
  handelDeleteRows,
  uploadImageFile,
}: IProps) => {
  const [machineData, setMachineData] = useState({});
  useEffect(() => {
    axios
      .get('/api/machineRoutes/machine/ForAutocomplete')
      .then((res) => {
        setMachineData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <React.Fragment key={index + 1 + 'process'}>
              <TBodyRow
                {...{
                  machineData,
                  moduleData,
                  process,
                  index,
                  isView,
                  handelDeleteRows,
                  handleSumbit,
                  uploadImageFile,
                }}
              />
            </React.Fragment>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
