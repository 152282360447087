import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import { IModule } from '../../interface';

interface IProps {
  isView: boolean;
  moduleData: IModule;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={5}
        rowSpan={3}
        labels={["Doc No.", "Rev. No.", "Rev. Date"]}
        values={["F-31-01", "", ""]}
        heading="Incoming Inspection Standard"
      />
      <tr>
        <th colSpan={5} align="left">
          Date:{' '}
          {isView ? (
            formatDate(moduleData.moduleData_date)
          ) : (
            <DatePickerMui
              value={moment(moduleData.moduleData_date)}
              onChange={(date) => {
                moduleData.moduleData_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th colSpan={5} align="left">
          Quality Plan No:
          <TableInput
            name="quality_plan_no"
            type="text"
            value={moduleData.quality_plan_no}
            onChange={(e) => {
              moduleData.quality_plan_no = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={5} align="left">
          Drawing No.:
          <TableInput
            name="drawing_no"
            type="text"
            value={moduleData.drawing_no}
            onChange={(e) => {
              moduleData.drawing_no = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={5} align="left">
          Drawing Rev No.:
          <TableInput
            name="drawing_rev_no"
            type="text"
            value={moduleData.drawing_rev_no}
            onChange={(e) => {
              moduleData.drawing_rev_no = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        {[
          'S.NO',
          'Dimension',
          'SPECIFICATION',
          'Checking Method',
          // 'SAMPLE SIZE/ FREQUENCY',
          'ACTION',
        ].map((heading) => (
          <th key={heading}>{heading}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
