import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import axios from 'axios';
import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import {
  CustomPagination,
  PersitedDatePicker,
  SearchBar,
} from '../../../components/common';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../services/auth/AuthProvider';
import AdminTable from './components/tablelist/AdminTable';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import useDebounce from '../../../hooks/useDebounce';
import FilterBy from '../../../components/common/FilterBy';

const statusObj = {
  pending: 1,
  accepted: 2,
  rejected: 3,
};

const filterOptions = [
  { value: 'module_dateA', name: 'Most Oldest' },
  { value: 'part_id.model_id.model_name', name: 'Model Name' },
];

const List = () => {
  const { state } = useAuth();
  const date = useQuery('date');
  const [tableData, setTableData] = useState<any[]>([]);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const page = useQuery('page') || 1;
  const documentType = useQuery('documentType') || '';

  const search = useQuery('search') || '';
  const sortBy = useQuery('sortBy') || 'module_date';
  const { user, IS } = state;
  const navigate = useNavigate();
  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE ',
    'Are you sure you want to delete this document?'
  );

  // useEffect(() => {
  // }, [render, date, page]);
  const fetchData = async () => {
    axios
      .get(
        `/api/scrapNoteReport/monthly/${date}?page=${page}&perPage=${perPage}&search=${search}&sortBy=${sortBy}`
      )
      .then((res) => {
        console.log("table data is ",res?.data);
        // setTableData(res?.data?.results);
        setPerPage(res?.data?.perPage);
        setTotalPages(res?.data?.pages);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    let userType = '';
    user?.userType === IS.Admin
      ? (userType = 'admin')
      : (userType = 'supervisor');

      console.log("usertype is",userType);
    axios
      .get(
        `/api/scrapNoteReport/monthly/${userType}/${date}?documentType=${documentType}`
      )
      .then((res) => {
        if (res?.data) setTableData(res?.data);
        console.log("data is",res?.data);
      })
      .catch((err) => console.log(err));
  }, [render, date]);
  const deleteDocument = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    axios
      .delete(`/api/scrapNoteReport/${id}`)
      .then(() => {
        alert('document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => {
        console.log(err);
      });
    return;
  };

  const updateStatus = async (id: string, documentIndex: number) => {
    let isConfirm = window.confirm('Are you sure you want to update status');
    if (isConfirm && tableData.length > 0) {
      axios
        .patch(`/api/scrapNoteReport/updateStatus/${id}`, {
          is_admin_request_no: Number(tableData[documentIndex].is_admin_request),
          report_approved_by: user?.name,
        })
        .then((res) => {
          if (res.data.status === 'success') {
            alert('Status Change successfully');
          }
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleChangeStatus = (
    e: React.ChangeEvent<HTMLSelectElement>,
    documentIndex: number
  ) => {
    const values = [...tableData];
    values[documentIndex].is_admin_request = e.target.value;
    setTableData(values);
    console.log("tabledata is handle",tableData);
  };
  // useDebounce(fetchData, 500, [page, search, render, date, sortBy]);

  return (
    <>
      <ChildHeader text="SCRAP NOTE">
        <AddButton
          onClick={() => 
            navigate(`/common/scrapnoteReport?documentType=${documentType}`
            )
          }
          label="NEW REPORT"
        />
      </ChildHeader>
      {/* <Box sx={{ margin: 2 }}> */}

      {/* <SearchFilterContainer filter={true} style={{ display: 'flex' }}>
        <PersitedDatePicker label="Search By Month" views={['month', 'year']} />
        <SearchBar label="Search By Part" sx={{ width: 900 }} />
        <FilterBy
          label="Sort By"
          name="sortBy"
          options={filterOptions}
          sx={{ width: 300 }}
        />
      </SearchFilterContainer> */}
      {/* </Box> */}
      <Box sx={{ margin: 2 }}>
        <PersitedDatePicker label="Search By Month" views={['month', 'year']} />
      </Box>
      <AdminTable
        {...{
          handleChangeStatus,
          deleteDocument,
          updateStatus,
          statusObj,
          tableData,
          page,
          perPage,
        }}
      />
      {/* <CustomPagination totalPage={totalPages} /> */}
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
