import React, { useState } from 'react';
import { TableInput } from '../../../../components/common';
import { MenuItem } from '@mui/material';
import CustomSelect from '../../../../components/common/SelectField';

interface IProps {
  moduleData: any;
  tabValue: number;
  isView: boolean;
}

const TBodyVisualDefects = ({ isView, moduleData, tabValue }: IProps) => {
  const [render, setRender] = useState(false);

  const handleInputChange = (defect: any, colIdx: number, field: string, value: any) => {
    if (!defect[field][colIdx]) {
      defect[field][colIdx] = { val: '', dd: '' };
    }
    defect[field][colIdx].val = value;
    setRender((prev) => !prev);
  };

  const handleSelectChange = (defect: any, colIdx: number, field: string, value: any) => {
    if (!defect[field][colIdx]) {
      defect[field][colIdx] = { val: '', dd: '' };
    }
    defect[field][colIdx].dd = value;
    setRender((prev) => !prev);
  };

  return (
    <tbody>
      {moduleData?.visual_defects_list?.map((defect: any, index: number) => (
        <tr key={index + 'visual_defects_list'}>
          {Array(3)
            .fill(null)
            .map((_, colIdx) => (
              <React.Fragment key={colIdx}>
                <td>{defect?.visual_defect_id?.[colIdx]?.visual_defects}</td>
                <td>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      minWidth: '200px',
                    }}
                  >
                    <TableInput
                      name={`first_shot_${index}_${colIdx}`}
                      type="Number"
                      isView={isView}
                      value={defect.first_shot[colIdx]?.val || ''}
                      onChange={(e) => handleInputChange(defect, colIdx, 'first_shot', e.target.value)}
                      style={{ flex: '1 1 auto' }}
                    />
                    {isView ? (
                      defect?.first_shot[colIdx]?.dd
                    ) : (
                      <CustomSelect
                        name={`first_shot_${index}_${colIdx}`}
                        value={defect.first_shot[colIdx]?.dd || ''}
                        onChange={(e) => handleSelectChange(defect, colIdx, 'first_shot', e.target.value)}
                        sx={{
                          textAlign: 'center',
                          flex: '0 0 auto',
                          width:'70px'
                        }}
                      >
                        <MenuItem value="">NONE</MenuItem>
                        <MenuItem value="✓">✓</MenuItem>
                        <MenuItem value="✗">✗</MenuItem>
                      </CustomSelect>
                    )}
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      minWidth: '200px',
                    }}
                  >
                    <TableInput
                      name={`second_shot_${index}_${colIdx}`}
                      type="Number"
                      isView={isView}
                      value={defect.second_shot[colIdx]?.val || ''}
                      onChange={(e) => handleInputChange(defect, colIdx, 'second_shot', e.target.value)}
                      style={{ flex: '1 1 auto' }}
                    />
                    {isView ? (
                      defect?.second_shot[colIdx]?.dd
                    ) : (
                      <CustomSelect
                        name={`second_shot_${index}_${colIdx}`}
                        value={defect.second_shot[colIdx]?.dd || ''}
                        onChange={(e) => handleSelectChange(defect, colIdx, 'second_shot', e.target.value)}
                        sx={{
                          textAlign: 'center',
                          flex: '0 0 auto',
                          width:'70px'

                        }}
                      >
                        <MenuItem value="">NONE</MenuItem>
                        <MenuItem value="✓">✓</MenuItem>
                        <MenuItem value="✗">✗</MenuItem>
                      </CustomSelect>
                    )}
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      minWidth: '200px',
                    }}
                  >
                    <TableInput
                      name={`third_shot_${index}_${colIdx}`}
                      type="Number"
                      isView={isView}
                      value={defect.third_shot[colIdx]?.val || ''}
                      onChange={(e) => handleInputChange(defect, colIdx, 'third_shot', e.target.value)}
                      style={{ flex: '1 1 auto' }}
                    />
                    {isView ? (
                      defect?.third_shot[colIdx]?.dd
                    ) : (
                      <CustomSelect
                        name={`third_shot_${index}_${colIdx}`}
                        value={defect.third_shot[colIdx]?.dd || ''}
                        onChange={(e) => handleSelectChange(defect, colIdx, 'third_shot', e.target.value)}
                        sx={{
                          textAlign: 'center',
                          flex: '0 0 auto',
                          width:'70px'

                        }}
                      >
                        <MenuItem value="">NONE</MenuItem>
                        <MenuItem value="✓">✓</MenuItem>
                        <MenuItem value="✗">✗</MenuItem>
                      </CustomSelect>
                    )}
                  </div>
                </td>
                <td>
                  {defect?.process_parameter_id?.[colIdx]?.process_parameter}
                </td>
                <td>{defect?.method?.[colIdx]?.method}</td>
                <td>
                  <TableInput
                    isView={isView}
                    name={`setValue_${index}_${colIdx}`}
                    type="text"
                    value={defect.setValue[colIdx] || ''}
                    onChange={(e) => {
                      defect.setValue[colIdx] = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
                <td>
                  <TableInput
                    isView={isView}
                    name={`observed_${index}_${colIdx}`}
                    type="text"
                    value={defect.observed[colIdx] || ''}
                    onChange={(e) => {
                      defect.observed[colIdx] = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
              </React.Fragment>
            ))}
        </tr>
      ))}
    </tbody>
  );
};

export default TBodyVisualDefects;



// import React, { useState } from 'react';
// import { TableInput } from '../../../../components/common';
// import { MenuItem } from '@mui/material';
// import CustomSelect from '../../../../components/common/SelectField';

// interface IProps {
//   moduleData: any;
//   tabValue: number;
//   isView: boolean;
// }

// const TBodyVisualDefects = ({ isView, moduleData, tabValue }: IProps) => {
//   const [render, setRender] = useState(false);

//   return (
//     <tbody>
//       {moduleData?.visual_defects_list?.map((defect: any, index: number) => (
//         <tr key={index + 'visual_defects_list'}>
//           {Array(3)
//             .fill(null)
//             .map((_, colIdx) => (
//               <React.Fragment key={colIdx}>
//                 <td>{defect?.visual_defect_id?.[colIdx]?.visual_defects}</td>
//                 <td>
//                   {/* <TableInput
//                     isView={isView}
//                     name={`first_shot_${index}_${colIdx}`}
//                     type="text"
//                     value={defect.first_shot[colIdx] || ''}
//                     onChange={(e) => {
//                       defect.first_shot[colIdx] = e.target.value;
//                       setRender((prev) => !prev);
//                     }}
//                   /> */}
//                   <div
//                     style={{
//                       display: 'flex',
//                       alignItems: 'center',
//                       gap: '8px',
//                       minWidth: '200px',
//                     }}
//                   >
//                     <TableInput
//                       name={`first_shot_${index}_${colIdx}`}
//                       type="Number"
//                       isView={isView}
//                       value={defect.first_shot[colIdx]?.val || ''}
//                       onChange={(e) => {
//                         defect.first_shot[colIdx].val = e.target.value;
//                         setRender((prev) => !prev);
//                       }}
//                       style={{ flex: '1 1 auto' }}
//                     />
//                     {isView ? (
//                       defect?.first_shot[colIdx]?.dd
//                     ) : (
//                       <CustomSelect
//                         name={`first_shot_${index}_${colIdx}`}
//                         value={defect.first_shot[colIdx]?.dd || ''}
//                         onChange={(e) => {
//                           defect.first_shot[colIdx].dd = e.target.value;
//                           setRender((prev) => !prev);
//                         }}
//                         sx={{
//                           textAlign: 'center',
//                           flex: '0 0 auto',
//                         }}
//                       >
//                         <MenuItem value="">NONE</MenuItem>
//                         <MenuItem value="✓">✓ </MenuItem>
//                         <MenuItem value="✗">✗</MenuItem>
//                       </CustomSelect>
//                     )}
//                   </div>
//                 </td>
//                 <td>
//                   {/* <TableInput
//                     isView={isView}
//                     name={`second_shot_${index}_${colIdx}`}
//                     type="text"
//                     value={defect.second_shot[colIdx] || ''}
//                     onChange={(e) => {
//                       defect.second_shot[colIdx] = e.target.value;
//                       setRender((prev) => !prev);
//                     }}
//                   /> */}

//                   <div
//                     style={{
//                       display: 'flex',
//                       alignItems: 'center',
//                       gap: '8px',
//                       minWidth: '200px',
//                     }}
//                   >
//                     <TableInput
//                       name={`second_shot_${index}_${colIdx}`}
//                       type="Number"
//                       isView={isView}
//                       value={defect.second_shot[colIdx]?.val || ''}
//                       onChange={(e) => {
//                         defect.second_shot[colIdx].val = e.target.value;
//                         setRender((prev) => !prev);
//                       }}
//                       style={{ flex: '1 1 auto' }}
//                     />
//                     {isView ? (
//                       defect?.second_shot[colIdx]?.dd
//                     ) : (
//                       <CustomSelect
//                         name={`second_shot_${index}_${colIdx}`}
//                         value={defect.second_shot[colIdx]?.dd || ''}
//                         onChange={(e) => {
//                           defect.second_shot[colIdx].dd = e.target.value;
//                           setRender((prev) => !prev);
//                         }}
//                         sx={{
//                           textAlign: 'center',
//                           flex: '0 0 auto',
//                         }}
//                       >
//                         <MenuItem value="">NONE</MenuItem>
//                         <MenuItem value="✓">✓ </MenuItem>
//                         <MenuItem value="✗">✗</MenuItem>
//                       </CustomSelect>
//                     )}
//                   </div>
//                 </td>
//                 <td>
//                   {/* <TableInput
//                     isView={isView}
//                     name={`third_shot_${index}_${colIdx}`}
//                     type="text"
//                     value={defect.third_shot[colIdx] || ''}
//                     onChange={(e) => {
//                       defect.third_shot[colIdx] = e.target.value;
//                       setRender((prev) => !prev);
//                     }}
//                   /> */}
//                   <div
//                     style={{
//                       display: 'flex',
//                       alignItems: 'center',
//                       gap: '8px',
//                       minWidth: '200px',
//                     }}
//                   >
//                     <TableInput
//                       name={`third_shot_${index}_${colIdx}`}
//                       type="Number"
//                       isView={isView}
//                       value={defect.third_shot[colIdx]?.val || ''}
//                       onChange={(e) => {
//                         defect.third_shot[colIdx].val = e.target.value;
//                         setRender((prev) => !prev);
//                       }}
//                       style={{ flex: '1 1 auto' }}
//                     />
//                     {isView?(
//                       defect?.third_shot[colIdx]?.dd 
//                     ):(

//                       <CustomSelect
//                       name={`third_shot_${index}_${colIdx}`}
//                       value={defect.third_shot[colIdx]?.dd || ''}
//                       onChange={(e) => {
//                         defect.third_shot[colIdx].dd = e.target.value;
//                         setRender((prev) => !prev);
//                       }}
//                       sx={{
//                         textAlign: 'center',
//                         flex: '0 0 auto',
//                       }}
//                     >
//                       <MenuItem value="">NONE</MenuItem>
//                       <MenuItem value="✓">✓ </MenuItem>
//                       <MenuItem value="✗">✗</MenuItem>
//                     </CustomSelect>
//                     )}
                   
//                   </div>
//                 </td>
//                 <td>
//                   {defect?.process_parameter_id?.[colIdx]?.process_parameter}
//                 </td>
//                 <td>{defect?.method?.[colIdx]?.method}</td>
//                 <td>
//                   <TableInput
//                     isView={isView}
//                     name={`setValue_${index}_${colIdx}`}
//                     type="text"
//                     value={defect.setValue[colIdx] || ''}
//                     onChange={(e) => {
//                       defect.setValue[colIdx] = e.target.value;
//                       setRender((prev) => !prev);
//                     }}
//                   />
//                 </td>
//                 <td>
//                   <TableInput
//                     isView={isView}
//                     name={`observed_${index}_${colIdx}`}
//                     type="text"
//                     value={defect.observed[colIdx] || ''}
//                     onChange={(e) => {
//                       defect.observed[colIdx] = e.target.value;
//                       setRender((prev) => !prev);
//                     }}
//                   />
//                 </td>
//               </React.Fragment>
//             ))}
//         </tr>
//       ))}
//     </tbody>
//   );
// };

// export default TBodyVisualDefects;
