const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};

export const HeaderStandard = [
  [
    { text: 'S.No.', style: style1 },
    { text: 'Checkpoint', style: style1 },
    { text: 'Judgement Criteria', style: style1 },
    { text: 'Method', style: style1 },
    { text: 'Picture', style: style1 },
    { text: 'Observation Before', style: style1 },
    { text: 'After', style: style1 },
    { text: 'REMARKS', style: style1 },
    { text: 'Observation Before', style: style1 },
    { text: 'After', style: style1 },
    { text: 'Remark', style: style1 },
    { text: 'Action', style: { ...style1, backgroundColor: '#c92a2a' } },
  ],
];

export const listConstant = {
  childHeader: 'SUPPLIER AUDIT PLAN Checksheet list',
  addButtonLable: 'New Checksheet',
};

export const headerConstant = {
  tableCompHeadTitle: 'MACHINE PREVENTIVE MAINTENANCE CHECKSHEET ',
};

export const apiConstant = {
  get: 'masterCheckListRouteSupplierAuditPlan',
  getById: 'masterCheckListRouteSupplierAuditPlan',
  post: 'masterCheckListRouteSupplierAuditPlan',
  update: 'masterCheckListRouteSupplierAuditPlan',
  delete: 'masterCheckListRouteSupplierAuditPlan',
  machineDataForAutoComplete: 'machineRoutes/machine/ForAutocomplete',
};

export const tilesConstant = {
  childHeaderText: 'SUPPLIER AUDIT PLAN',
};

export const navigationConstant = {
  createEditChecklist: '/common/supplierAuditPlanChecklistCreate',
};
