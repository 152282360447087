import moment from 'moment';
import lodash from 'lodash';

export const processes = [
  {
    date: moment(),
    shift: 'A',
    mc_no: null,
    part_id: null,
    operator: null,
    operator_2: null,
    total_qty: null,
    ok_qty: null,
    rej_qty: null,
    reasons_for_rej: Array.from({ length: 10 }, () =>
      lodash.cloneDeep({ reason: null, rej_qty: null })
    ),
    type_of_b_down: [null, null, null, null, null, null],
    total_b_down: null,
    remarks: null,
  },
];

export const initialState = {
  isSubmitted: false,
  is_admin_request_no: null,
  is_admin_request: null,
  report_prepared_by: null,
  report_approved_by: null,
  dailyProduction_date: new Date(),
  processes: Array.from({ length: 1 }, () => lodash.cloneDeep(processes[0])),
};
