import moment from 'moment';
import lodash from 'lodash';


export const initialState = {
  processes: [
    {
      part_name: "",
      part_number: '',
      total_qty: '',
      rej_qty:0,
      rej_per:'',
      remarks: '',
      rate:'',
      val:'',
      reasons_for_rej: Array.from({ length: 10 }, () =>
        lodash.cloneDeep({ reason: null,rej_qty: 0})
      ),
    }
  ],
  employees: [
    {
      emp_name: "",
      dept: '',
      desg:'',
      attend:'',
      remarks: '',
     
    }
  ]
};
