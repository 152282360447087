import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { Checkbox, Grid } from '@mui/material';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import { TableInput } from '../../../../../components/common';
import { shiftA, shiftB, shiftC } from '../../helpers/initialState';
interface IProps {
  moduleData: any;
  isView: boolean;
  setIsOpenPopup: any;
  setShift: any;
}

const theadData = [
  'S. No.',
  'Shots/Hr.',
  'Target Qty.',
  'Inspected Qty.',
  'OK Qty.',
  'REASON OF REJECTION',
];
const filterEmployees = (employees: any, employeeType: any) => {
  const newEmployees = employees.filter(
    (item: any) => item?.sub_category === employeeType
  );
  return newEmployees;
};

const THead = ({ moduleData, isView, setIsOpenPopup, setShift }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <thead>
      <TableCompHead
        colSpan={6}
        rowSpan={3}
        labels={['DOC NO.', 'REV.NO.', 'DATE']}
        values={['HO-F-PRD-01', '02', '01.09.2015']}
        heading="HOURLY PRODUCTION REPORT"
      />
      <tr>
        <th colSpan={2} align="left" style={{ width: '200px' }}>
          PART NAME :{' '}
          {isView ? (
            moduleData?.part_id && moduleData?.part_id?.part_name
          ) : (
            <Grid container xs={12} direction="row" alignItems={'center'}>
              <Grid item xs={10}>
                <p
                  style={{
                    fontSize: '1.2rem',
                  }}
                >
                  {typeof moduleData?.part_id === 'object' &&
                  moduleData?.part_id !== null
                    ? moduleData?.part_id?.part_name
                    : moduleData?.partName || ''}
                </p>
              </Grid>

              {moduleData.part_id ? (
                <Grid item xs={2}>
                  <RemoveIconButton
                    tooltipTitle="remove part"
                    onClick={() => {
                      moduleData.part_id = null;
                      moduleData.partNumber = null;
                      moduleData.partName = null;
                      moduleData.customerName = null;
                      setRender((prev) => !prev);
                    }}
                  />
                </Grid>
              ) : (
                <Grid item xs={2}>
                  <AddIconButton
                    tooltipTitle="add part"
                    onClick={() => {
                      setIsOpenPopup(true);
                    }}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </th>
        <th colSpan={1} align="left" style={{ width: '250px' }}>
          FINAL INSPECTION REPORT DATE :{' '}
          {isView ? (
            formatDate(moduleData.final_inspection_report_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.final_inspection_report_date)}
              onChange={(date: any) => {
                moduleData.final_inspection_report_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={1} align="left" style={{ width: '250px' }}>
          SHIFT :{'  A'}
          <Checkbox
            checked={moduleData.shift === 'A'}
            onChange={(e) => {
              moduleData.shift = 'A';
              setShift('A');
              if (moduleData.processes.length === 0)
                moduleData.processes = shiftA;
              moduleData.processes[0].time = '8 AM - 10 AM';
              moduleData.processes[1].time = '10 AM - 12 PM';
              moduleData.processes[2].time = '12 PM - 2 PM';
              moduleData.processes[3].time = '2 PM - 4 PM';
              setRender((prev) => !prev);
            }}
          />
          B{' '}
          <Checkbox
            checked={moduleData.shift === 'B'}
            onChange={(e) => {
              moduleData.shift = 'B';
              setShift('B');
              moduleData.processes[0].time = '4 PM - 6 PM';
              moduleData.processes[1].time = '6 PM - 8 PM';
              moduleData.processes[2].time = '8 PM - 10 PM';
              moduleData.processes[3].time = '10 PM - 12 AM';
              setRender((prev) => !prev);
            }}
          />
          C{' '}
          <Checkbox
            checked={moduleData.shift === 'C'}
            onChange={(e) => {
              moduleData.shift = 'C';
              setShift('C');
              moduleData.processes[0].time = '12 AM - 2 AM';
              moduleData.processes[1].time = '2 AM - 4 AM';
              moduleData.processes[2].time = '4 AM - 6 AM';
              moduleData.processes[3].time = '6 AM - 8 AM';
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={2} align="left" style={{ width: '200px' }}>
          PRD. SHIFT ENGR. NAME : {moduleData?.shift_engr_name}
        </th>
      </tr>
      <tr style={{ background: '#38d9a9', height: '60px' }}>
        {theadData.map((heading, index) => (
          <th key={heading + index} colSpan={1}>
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
