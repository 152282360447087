import moment from 'moment';
import lodash from 'lodash';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { Checkbox, Grid } from '@mui/material';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import { TableInput } from '../../../../../components/common';
import { CustomButton } from '../../../../../components/common/button/CustomButtons';
import { initialState } from '../../helpers/initialState';
interface IProps {
  moduleData: any;
  isView: boolean;
  setIsOpenPopup: any;
  setModuleData: any;
  x: any;
}

const theadData = [
  'S.No',
  'Part Name ',
  'Part No.',
  'Total Qty. Checked ',
  'Total QTY. Rejected',
  'Reason Of Defect',
  '% Rej ',
  'Rate',
  'Value',
  'Remarks',
  'ADD ROWS',
];

const theadData2 = [
  'S. No.',
  'Name ',
  'Departmet ',
  'Designation',
  'Attendance ( P/ A ) ',
  'Remarks',
  'ADD ROWS',
];

const THead = ({
  moduleData,
  isView,
  setIsOpenPopup,
  setModuleData,
  x,
}: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <thead>
      {x == 1 && (
        <>
          <TableCompHead
            colSpan={11}
            rowSpan={3}
            labels={['F- PROD-.', 'Rev. No.:', 'Rev. Date:']}
            values={[' 0002', '02', '01.03.2022']}
            heading="SCRAP NOTE REPORT"
          />
          <tr>
            <th colSpan={2} align="left" style={{ width: '250px' }}>
              DATE :{' '}
              {isView ? (
                formatDate(moduleData?.dailyProduction_date)
              ) : (
                <DatePickerMui
                  label=""
                  value={moment(moduleData?.dailyProduction_date)}
                  onChange={(date: any) => {
                    moduleData.dailyProduction_date = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </th>
          </tr>
        </>
      )}

      {x == 1 && (
        <tr style={{ background: '#38d9a9', height: '60px' }}>
          {theadData.map((heading, index) => (
            <th key={heading + index} colSpan={1}>
              {index === 10 ? (
                <CustomButton
                  sx={{
                    backgroundColor: '#1c7ed6',
                  }}
                  onClick={async () => {
                    if(!isView){

                      setModuleData((prevModuleData: any) => {
                        const updatedModuleData =
                          lodash.cloneDeep(prevModuleData);
                        updatedModuleData.processes.push(
                          lodash.cloneDeep(initialState?.processes[0])
                        );
                        return updatedModuleData;
                      });
                      setRender((prev) => !prev);
                    }
                  }}
                >
                  ADD ROWS
                </CustomButton>
              ) : (
                heading
              )}
            </th>
          ))}
        </tr>
      )}

      {x === 2 && (
        <tr style={{ background: '#38d9a9', height: '60px' }}>
          {theadData2.map((heading, index) => (
            <th
              key={heading + index}
              colSpan={index === 1 || index==2 || index == 3? 2 : (index==6?1:(index==5?2:1))}
              style={{ minWidth: '100px' }}
            >
              {index === 6 ? (
                <CustomButton
                  sx={{
                    backgroundColor: '#1c7ed6',
                  }}
                  onClick={async () => {
                    if(!isView){

                      setModuleData((prevModuleData: any) => {
                        const updatedModuleData =
                          lodash.cloneDeep(prevModuleData);
                        updatedModuleData.employees.push(
                          lodash.cloneDeep(initialState?.employees[0])
                        );
                        return updatedModuleData;
                      });
                      setRender((prev) => !prev);
                    }}
                    }
                >
                  ADD ROWS
                </CustomButton>
              ) : (
                heading
              )}
            </th>
          ))}
        </tr>
      )}
    </thead>
  );
};

export default THead;
