import axios from 'axios';
import { useState, useEffect } from 'react';
import { useQuery } from '../../../hooks/UseQuery';

const useGetData = (partId: string | undefined, pdirId: string | undefined) => {
  const [moduleData, setModuleData] = useState<any>({
    inspection_date: new Date(),
    processes: [],
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const partName = useQuery('partName');
  const partNumber = useQuery('partNumber');
  const supplierName = useQuery('supplierName');
  useEffect(() => {
    setIsLoading(true);
    if (pdirId) {
      axios
        .get(`/api/inspectionReport/${pdirId}`)
        .then((res) => setModuleData(res.data))
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    } else {
      axios
        .get(`/api/inspectionReport/getSpecFromInspectionStd/${partId}`)
        .then((res) => {
          const response = res.data;
          setModuleData((item: any) => {
            item.ri_part_id = partId;
            item.inspection_date = new Date();
            item.part_number = partNumber;
            item.part_name = partName;
            item.supplierName = supplierName;
            item.file = response.file;
            item.processes = response?.processes?.map((spec: any) => {
              return {
                // start of spec in inspection
                parameter: spec.parameter,
                specification: spec?.specification,
                measurement_value: spec?.measurement_value,
                check4_symbol: spec.check4_symbol,
                checkbox4_num: spec.checkbox4_num,
                methods: spec.methods_measure,
                appearance: spec.appearance,
                mean: spec.mean,
                utolerance: spec.utolerance,
                ltolerance: spec.ltolerance,
                utl: spec.utl,
                ltl: spec.ltl,
                check_0_colors: Array.from(Array(5)),
                check_0_values: Array.from(Array(5)),
              };
            });
            return { ...item };
          });
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err);
        });
    }
  }, []);
  return { moduleData, isLoading, error };
};

export default useGetData;
