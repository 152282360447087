import React, { useEffect, useState } from 'react';
import GridInputs from '../../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../../components/common/button/CustomButtons';
import useConfirm from '../../../../components/common/useConfirm';
import GridDatePicker from '../../../../components/common/inputs/GridDatePicker';
import { apiConstant } from './helpers/constant';
import moment from 'moment';
import AutocompleteGridmui from '../../../../components/common/autocomplete/AutocompleteGridmui';

const ModalInput = ({
  id,
  setIsModal,
  setListId,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setListId: any;
}) => {
  const [moduleData, setMoudleData] = useState<any>({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [render, setRender] = useState(false);
  const [lineData, setLineData] = useState([]);
  const [plantLocationData, setPlantLocationData] = useState([]);
  const [machineData, setMachineData] = useState([]);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update' : 'Create'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  useEffect(() => {
    axios
      .get(`/api/${apiConstant.machineLineAutoComplete}`)
      .then((res) => {
        setLineData(res.data);
      })
      .catch((err) => console.log(err));
    axios
      .get(`/api/${apiConstant.plantLocationAutoComplete}`)
      .then((res) => {
        setPlantLocationData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get(
        `/api/${apiConstant.machineAutoComplete}/${moduleData?.line_id?._id}`
      )
      .then((res) => {
        setMachineData(res.data);
      })
      .catch((err) => console.log(err));
  }, [moduleData.line_id]);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/${apiConstant.getListPlanByid}/${id}`)
        .then((res) => setMoudleData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleData(e: React.ChangeEvent<HTMLInputElement>) {
    setMoudleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    setIsSubmit(true);
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/${apiConstant.getListPlanByid}/${id}`, moduleData)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
          setListId('');
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post(`/api/${apiConstant.createListPlanByid}`, moduleData)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
          setListId('');
        })
        .catch((err) => {
          setIsSubmit(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'title'}
        name={'title'}
        html_for={'title'}
        label_name={'Title'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.title || ''}
        onChange={handleData}
      />
      <GridDatePicker
        label_name="Date"
        html_for={'date_range'}
        views={['year']}
        value={moduleData?.date_range || null}
        onChange={(date) => {
          moduleData.date_range = moment(date._d).add(20, 'hours');
          setRender((prev) => !prev);
        }}
      />
      {/* <AutocompleteGridmui
        id={'name'}
        label={'Line : '}
        option_name={'name'}
        arrayofObj={lineData}
        value={moduleData.line_id || ''}
        onChange={(e, value) => {
          moduleData.line_id = value;
          setRender((prev) => !prev);
        }}
      />
      <AutocompleteGridmui
        id={'machine_name'}
        label={'Machine : '}
        option_name={'machine_name'}
        option_name_2={'machine_no'}
        arrayofObj={machineData}
        value={moduleData.machine_id || ''}
        onChange={(e, value) => {
          moduleData.machine_id = value;
          setRender((prev) => !prev);
        }}
      />
      <AutocompleteGridmui
        id={'plant_location'}
        label={'Plant Location : '}
        option_name={'name'}
        arrayofObj={plantLocationData}
        value={moduleData.plant_location_id || ''}
        onChange={(e, value) => {
          moduleData.plant_location_id = value;
          setRender((prev) => !prev);
        }}
      />

      <GridInputs
        id={'responsibility'}
        name={'responsibility'}
        html_for={'responsibility'}
        label_name={'Responsibility'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.responsibility || ''}
        onChange={handleData}
      /> */}
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default ModalInput;
