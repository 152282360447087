// import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../components/ui/TableCompHead';
// import { TableInput } from '../../../../components/common';
// import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableInput } from '../../../../components/common';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { IndexAdmin } from '../../../../routes/IndexAdmin';
// import { convToTime } from '../../../../utils/helpers';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const theadData = [
  'S. NO.',
  'PART NAME',
  'PRODUCTION SCRAP (A)',
  'INCOMING REJ. (B)',
  'TOTAL C = (A+B)',
  'F I REPORT REJ. (D)',
  'VARIATION (C-D)',

  'REMARKS',
];

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <thead>
      <TableCompHead
        colSpan={9}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading="INPROCESS - REJECTION CHALLAN (CROSS VERIFICATION AGAINEST FI V/S ACTUAL)"
      />
      <tr>
        <th colSpan={6}> </th>
        <th>Date:</th>
        <th colSpan={2}>
          {isView ? (
            formatDate(moduleData.date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.date)}
              onChange={(date) => {
                moduleData.date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>

      <tr>
        {theadData.map((heading, index) => (
          <th key={heading + index} colSpan={heading === 'REMARKS' ? 2 : 1}>
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
