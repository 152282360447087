import React, { useEffect, useState } from 'react';
import TController from './components/view/TController';
import { IData } from './helpers/interface';
import lodash, { forEach } from 'lodash';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import {
  CustomButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import { useQuery } from '../../../hooks/UseQuery';
import { processes } from './helpers/initialState';
import useConfirm from '../../../components/common/useConfirm';
import ModalCustom from '../../../components/common/Modal';
import PartTable from './components/view/PartTable';

const View = () => {
  const [moduleData, setModuleData] = useState<any>({
    processes: Array.from({ length: 1 }, () => ({ ...processes[0] })),
  });
  const [render, setRender] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [partIdx, setPartIdx] = useState(false);
  const navigate = useNavigate();
  const annual_month = useQuery('annual_date') || '';
  const isView = useQuery('isView') === 'true' ? true : false;

  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?'
  );
  const [DialogAddRow, confirmAddRow] = useConfirm(
    'Add Row',
    'Are you sure you want to Add Row?'
  );
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?'
  );
  const [DialogSummary, confirmSummary] = useConfirm(
    'Submit',
    'Are you sure you want to add this to 4M summary?'
  );
  useEffect(() => {
    axios
      .get(`/api/abnormalRejectionReport/monthly?annualMonth=${annual_month}`)
      .then((res) => {
        if (res.data) setModuleData(res.data);
      })
      .catch((err) => console.log(err));
  }, [render]);

  useEffect(() => {
    if (moduleData?._id) {
      axios
        .get(`/api/abnormalRejectionReport/${moduleData?._id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [moduleData?._id]);

  const handleSumbit = async () => {
    if (moduleData?._id) {
      await axios
        .put(`/api/abnormalRejectionReport/${moduleData?._id}`, moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('Updated Successfully');
            setRender((prev) => !prev);
          }
        })
        .catch((err) => console.log(err));
    } else {
      if (!annual_month) {
        return alert('Please pick annual year?');
      }
      moduleData.annual_month = annual_month;
      await axios
        .post('/api/abnormalRejectionReport', moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('created Successfully');
            setRender((prev) => !prev);
            // navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handelDeleteRows = async (rowIdx: number) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    let data = { ...moduleData };
    data.processes.splice(rowIdx, 1);
    setModuleData(data);
  };

  return (
    <>
      <Box>
        <ChildHeader text={'Abnormal Situation Rejection Report'}>
          {!isView && (
            <>
              <CustomButton
                sx={{
                  backgroundColor: '#1c7ed6',
                }}
                onClick={async () => {
                  const ans = await confirmAddRow();
                  if (!ans) return;
                  Array(1)
                    .fill(null)
                    .forEach(() => {
                      moduleData.processes.push(lodash.cloneDeep(processes[0]));
                    });
                }}
              >
                ADD ROWS
              </CustomButton>
              <SubmitButton
                label={moduleData?._id ? `UPDATE` : `SUBMIT`}
                onClick={() => handleSumbit()}
              />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
          handleSumbit,
          handelDeleteRows,
          isOpenPopup,
          setIsOpenPopup,
          setPartIdx,
        }}
      />

      <ModalCustom
        title="Part List"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <PartTable
          {...{
            moduleData,
            partIdx,
          }}
        />
      </ModalCustom>
      <DialogDeleteRow isSubmitContent={false} />
      <DialogAddRow />
      <DialogSubmit />
      <DialogSummary />
    </>
  );
};

export default View;
