import { TbAspectRatio } from 'react-icons/tb';
import { MdOutlineCalendarMonth } from 'react-icons/md';
import { BsReverseListColumnsReverse } from 'react-icons/bs';
import { RenderTiles } from '../../../components/common';
import ChildHeader from '../../../components/ui/ChildHeader';
import { tilesConstant } from './helpers/constant';

const tiles = [
  {
    sNo: 1,
    link: '/common/supplierAuditPlanPlanList',
    isLink: true,
    icon: <MdOutlineCalendarMonth size="5rem" color="#343a40" />,
    p: 'SUPPLIER AUDIT',
    span: 'PLAN',
    isIcon: true,
  },

  {
    sNo: 2,
    link: '/common/supplierAuditPlanStatus',
    isLink: true,
    icon: <BsReverseListColumnsReverse size="5rem" color="#087f5b" />,
    p: 'SUPPLIER AUDIT',
    span: 'STATUS',
    isIcon: true,
  },
  // {
  //   sNo: 2,
  //   link: '/common/supplierAuditPlanChecklist',
  //   isLink: true,
  //   icon: <TbAspectRatio size="5rem" color="#343a40" />,
  //   p: 'Plan',
  //   span: 'Checklists',
  //   isIcon: true,
  // },
];

const Tiles = () => {
  return (
    <>
      <ChildHeader text={tilesConstant.childHeaderText} />
      <RenderTiles
        tilesObj={tiles}
        height="100vh"
        justify="start"
        columns="0"
      />
    </>
  );
};

export default Tiles;
