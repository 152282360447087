import { Clause5, 
  SkillEvaluationSheetCreate,
   SkillEvaluationSheetList, 
   TniTiles ,
   TraningTopicsList,
   TraningTopicsCreateEdit,
   TniReportList,
   TniReportCreate,
   SkillEvaluationPlanTiles,
   SkillEvaluationPlanList,
   SkillEvaluationPlanCreate,
   SkillEvaluationPlanStatus,
   SkillEvaluationPlanStatusCheckListCreate,
   SkillEvaluationPlanMasterChecklist,
   SkillEvaluationPlanMasterChecklistCreate,
   TrainingPlanTiles,
   TrainingPlanList,
   TrainingPlanCreate,
   TrainingPlanStatus,
   TrainingPlanStatusCheckListCreate,
   TrainingPlanMasterChecklist,
   TrainingPlanMasterChecklistCreate,
   EmpTraningTopicsCreateEdit,
  } from '../../pages/clause5page';
import List from '../../pages/clause5page/employee/List';
import FacultyList from '../../pages/clause5page/faculty/List';
// import StaffList from '../../pages/clause5page/employeeStaff/List';
import MasterlistOfDepartment from '../../pages/clause5page/department/List';


const skillEvaluationPlan = [
  //  operator observance plan
  {
    path: 'skillEvaluationPlanTiles',
    element: <SkillEvaluationPlanTiles />,
  },
  {
    path: 'skillEvaluationPlanList',
    element: <SkillEvaluationPlanList />,
  },
  {
    path: 'skillEvaluationPlanCreate/:id',
    element: <SkillEvaluationPlanCreate />,
  },
  {
    path: 'skillEvaluationPlanStatus',
    element: <SkillEvaluationPlanStatus />,
  },
  {
    path: 'skillEvaluationPlanStatusCheckListCreate/:id?',
    element: <SkillEvaluationPlanStatusCheckListCreate />,
  },
  {
    path: 'skillEvaluationPlanMasterChecklist',
    element: <SkillEvaluationPlanMasterChecklist />,
  },
  {
    path: 'skillEvaluationPlanMasterChecklistCreate/:id?',
    element: <SkillEvaluationPlanMasterChecklistCreate />,
  },
];

const trainingPlan = [
  //  operator observance plan
  {
    path: 'trainingPlanTiles',
    element: <TrainingPlanTiles />,
  },
  {
    path: 'trainingPlanList',
    element: <TrainingPlanList />,
  },
  {
    path: 'trainingPlanCreate/:id',
    element: <TrainingPlanCreate />,
  },
  {
    path: 'trainingPlanStatus',
    element: <TrainingPlanStatus />,
  },
  {
    path: 'trainingPlanStatusCheckListCreate/:id?',
    element: <TrainingPlanStatusCheckListCreate />,
  },
  {
    path: 'trainingPlanMasterChecklist',
    element: <TrainingPlanMasterChecklist />,
  },
  {
    path: 'trainingPlanMasterChecklistCreate/:id?',
    element: <TrainingPlanMasterChecklistCreate />,
  },
  // {
  //   path: 'masterListPlantList',
  //   element: <MasterListPlantList />,
  // },
];
export const clause5 = [
  {
    path: 'clause5',
    element: <Clause5 />,
  },
  {
    path: 'employee_list',
    element: <List />,
  },
  {
    path: 'empTraningTopicsCreateEdit/:id?',
    element: <EmpTraningTopicsCreateEdit />,
  },
  {
    path: 'masterlistoffaculty',
    element: <FacultyList />,
  },
  {
    path: 'skillEvaluationSheetList',
    element: <SkillEvaluationSheetList />,
  },
  {
    path: 'skillEvaluationSheetCreate/:id?',
    element: <SkillEvaluationSheetCreate />,
  },
  {
    path: 'tnihome',
    element: <TniTiles />,
  },
  {
    path: 'traningtopicslist',
    element: <TraningTopicsList />,
  },
  {
    path: 'traningtopicscreateedit/:id?',
    element: <TraningTopicsCreateEdit />,
  },
  {
    path: 'tniReportList',
    element: <TniReportList />,
  },
  {
    path: 'tniReportCreate/:id?',
    element: <TniReportCreate />,
  },
  // {
  //   path: 'employeeStaff_list',
  //   element: <StaffList />,
  // },
  {
    path: 'masterlistofdepartment',
    element: <MasterlistOfDepartment />,
  },
  ...skillEvaluationPlan,
  ...trainingPlan,
];
