import React, { useState,useEffect } from 'react';
import { Box, Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';

import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
import { SearchBar } from '../../../components/common/SearchBar';
import { CustomPagination ,PersitedDatePicker,TableCustom} from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  CardIconButton,
} from '../../../components/common/button/CustomIconButton';
// import Create from './Create';
import ModalCustom from '../../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../services/auth/AuthProvider';
import AdminTable from './components/tablelist/AdminTable';
import SupervisorTable from './components/tablelist/SupervisorTable';
import { IData } from './helpers/interface';

const tableHeaderObj = [
    [
      { text: '#', rowspan: 1, colspan: 1 },
      { text: 'CREATED AT', rowspan: 1, colspan: 1 },
      { text: 'STATUS', rowspan: 1, colspan: 1 },
      { text: 'ACTION', rowspan: 1, colspan: 1 },
      { text: 'UPDATE STATUS', rowspan: 1, colspan: 1 },
    ],
  ];


const List = () => {
  const [data, setData] = useState<any>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [defectId, setDefectId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const search = useQuery('search') || '';
  const filter = useQuery('filter') || 'createdAt';
  const page = useQuery('page') || 1;
  const navigate = useNavigate();
  const { state } = useAuth();
  const { user, IS } = state;
  const heading = useQuery('heading') || '';
  const documentType = useQuery('documentType') || '';
  const date = useQuery('date');
  const [tableData, setTableData] = useState<IData[]>([]);
  const statusObj = {
    pending: 1,
    accepted: 2,
    rejected: 3,
  };

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE Moulder',
    'Are you sure you want to delete this?'
  );

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/productionMouldingRoutes/?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  useEffect(() => {
    let userType = '';
    user?.userType === IS.Admin
      ? (userType = 'admin')
      : (userType = 'supervisor');

      console.log("usertype is",userType);
    axios
      .get(
        `/api/redbinPartRoutes/monthly/${userType}/${date}?documentType=${documentType}`
      )
      .then((res) => {
        if (res?.data) setTableData(res?.data);
        console.log("data is",res?.data);
      })
      .catch((err) => console.log(err));
  }, [render, date]);

  const deleteDefect = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/productionMouldingRoutes/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  // useDebounce(fetchData, 500, [page, search, render, isModal, filter]);

  const deleteDocument = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    axios
      .delete(`/api/redbinPartRoutes/${id}`)
      .then(() => {
        alert('document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => {
        console.log(err);
      });
    return;
  };


  const updateStatus = async (id: string, documentIndex: number) => {
    let isConfirm = window.confirm('Are you sure you want to update status');
    if (isConfirm && tableData.length > 0) {
      axios
        .patch(`/api/redbinPartRoutes/updateStatus/${id}`, {
          is_admin_request_no: tableData[documentIndex].is_admin_request_no,
          report_approved_by: user?.name,
        })
        .then((res) => {
          if (res.data.status === 'success') {
            alert('Status Change successfully');
          }
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleChangeStatus = (
    e: React.ChangeEvent<HTMLSelectElement>,
    documentIndex: number
  ) => {
    const values = [...tableData];
    values[documentIndex].is_admin_request = null;
    values[documentIndex].is_admin_request_no = +e.target.value;
    setTableData(values);
  };

  return (
    <>
      <ChildHeader text="REDBIN ANALYSIS">
        <AddButton onClick={() =>
            navigate(
              `/common/createEditRedbinAnalysisReport?documentType=${documentType}`
            )
          } label="New Report" />
      </ChildHeader>
      <Box sx={{ margin: 2 }}>
        <PersitedDatePicker label="Search By Month" views={['month', 'year']} />
      </Box>
      {user?.userType === IS.Admin && (
        <AdminTable
          {...{
            handleChangeStatus,
            deleteDocument,
            updateStatus,
            statusObj,
            tableData,
          }}
        />
      )}
      {user?.userType === IS.Supervisor && (
        <SupervisorTable {...{ deleteDocument, tableData }} />
      )}
      {/* <CustomPagination totalPage={totalPages} /> */}
      {/* <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setDefectId(null);
          setIsModal(false);
        }}
        title="MOULD"
      > */}
        {/* <Create id={defectId} setIsModal={setIsModal} /> */}
      {/* </ModalCustom> */}
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
