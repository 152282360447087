const calculateTotal = (item: any) => {
  const total = item.received_qty.reduce((accumulator: any, current: any) => {
    return (+accumulator || 0) + (+current || 0);
  }, 0);
  item.total_qty_received = total;
  item.total_ok_qty_received = total;
};

const calculateDeliveryRating = (item: any) => {
  item.delivery_rating = (
    ((+item.total_qty_received || 0) * 100) /
    (+item.order_qty || 1)
  ).toFixed(2);
};

const calcqualityRating = (item: any) => {
  item.quality_Rating = (
    (+item.total_ok_qty_received || 0 * 100) / (+item.total_qty_received || 1)
  ).toFixed(2);
};

const calcOveralRating = (item: any) => {
  item.overall_rating = (
    0.5 * (+item.quality_Rating || 0) +
    0.3 * (+item.delivery_rating || 0) +
    0.2 * (+item.sr || 0)
  ).toFixed(2);
};

const calculateTotalRating = (
  moduleData: any,
  sum_key: string,
  update_key: string
) => {
  const total = moduleData.list.reduce((accumulator: any, current: any) => {
    return (+accumulator || 0) + (+current?.[sum_key] || 0);
  }, 0);

  moduleData[update_key] = (
    (+total || 0) / (moduleData?.list?.length || 1)
  ).toFixed(2);
};

export function calcRow(item: any, moduleData: any) {
  calculateTotal(item);
  calculateDeliveryRating(item);
  calcqualityRating(item);
  calcOveralRating(item);
  calculateTotalRating(moduleData, 'delivery_rating', 'sum_of_delivery_rating');
  calculateTotalRating(moduleData, 'quality_Rating', 'sum_of_quality_Rating');
  calculateTotalRating(moduleData, 'overall_rating', 'sum_of_overall_rating');
}
