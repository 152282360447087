import { Box } from '@mui/material';
import { RenderTable } from '../../../../components/ui';
import TBody from './TBody';
import THead from './THead';

interface IProps {
  moduleData: any;
  handleDelete: any;
  setModuleData: any;
  isView: boolean;
  setRenderforUpdate: any;
}

const TController = ({
  moduleData,
  isView,
  handleDelete,
  setModuleData,
  setRenderforUpdate,
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            setRenderforUpdate,
            isView,
            setModuleData,
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
            handleDelete,
          }}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
