import React from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { TableCustom } from '../../../../../components/common';
import {
  BlockIconButton,
  DeleteIconButton,
  EditIconButton,
  ViewIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../../components/common/formatDate';
import { useAuth } from '../../../../../services/auth/AuthProvider';

interface IProps {
  deleteDocument: (id: string) => void;
  tableData: any[];
}

const Header = [
  [
    {
      text: '#',
      colspan: 1,
      rowspan: 1,
    },

    // {
    //   text: 'NAME',
    //   colspan: 1,
    //   rowspan: 1,
    // },
    {
      text: 'Created At',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'STATUS',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'ACTION',
      colspan: 1,
      rowspan: 1,
    },
  ],
];

const SupervisorTable = ({ deleteDocument, tableData }: IProps) => {
  const navigate = useNavigate();
  const { state } = useAuth();
  const { user, IS } = state;
  return (
    <React.Fragment>
      <TableCustom tableHeaderObj={Header}>
        <TableBody>
          {tableData.map(
            (document: { [key: string]: any }, documentIndex: number) => (
              <TableRow>
                <TableCell align="center">{documentIndex + 1}</TableCell>
                {/* <TableCell align="center">
                  {document.report_prepared_by}
                </TableCell> */}
                <TableCell align="center">
                  {formatDate(document.dailyProduction_date)}
                </TableCell>
                <TableCell align="center">
                  {document.isSubmitted ? (
                    <div
                      style={{
                        fontSize: '1.4rem',
                        outline: 'none',
                        padding: '5px',
                        textAlign: 'center',
                        width: '200px',
                        margin: 'auto',
                        textTransform: 'uppercase',
                        ...(document.is_admin_request === 'pending' && {
                          backgroundColor: '#FDD835',
                        }),
                        ...(document.is_admin_request === 'accepted' && {
                          backgroundColor: '#2b8a3e',
                          color: '#fff',
                        }),
                        ...(document.is_admin_request === 'rejected' && {
                          backgroundColor: '#f03e3e',
                          color: '#fff',
                        }),
                      }}
                    >
                      {document.is_admin_request === 'pending' &&
                        'pending for approval'}
                      {document.is_admin_request === 'accepted' && 'accepted'}
                      {document.is_admin_request === 'rejected' && 'rejected'}
                    </div>
                  ) : (
                    <div
                      style={{
                        backgroundColor: '#FDD835',
                        fontSize: '1.4rem',
                        outline: 'none',
                        padding: '5px',
                        textAlign: 'center',
                        width: '200px',
                        margin: 'auto',
                      }}
                    >
                      {document.isSubmitted ? 'SUBMITTED' : 'DRAFT'}
                    </div>
                  )}
                </TableCell>
                <TableCell align="center">
                  <ViewIconButton
                    tooltipTitle="view"
                    onClick={() =>
                      navigate(
                        `/common/createEditDailyProduction/${document._id}?isView=true&partName=${document.part_id?.part_name}&partNumber=${document.part_id?.part_number}`
                      )
                    }
                  />

                  {!document.isSubmitted &&
                    document.is_admin_request !== 'rejected' && (
                      <>
                        <EditIconButton
                          tooltipTitle="edit"
                          onClick={() =>
                            navigate(
                              `/common/createEditDailyProduction/${document._id}?isView=false&partName=${document.part_id?.part_name}&partNumber=${document.part_id?.part_number}`
                            )
                          }
                        />
                        <DeleteIconButton
                          tooltipTitle="delete"
                          onClick={() => deleteDocument(document._id)}
                        />
                      </>
                    )}
                  {document.is_admin_request === 'rejected' && (
                    <>
                      <EditIconButton
                        tooltipTitle="edit"
                        onClick={() =>
                          navigate(
                            `/common/createEditDailyProduction/${document._id}?isView=false&partName=${document.part_id?.part_name}&partNumber=${document.part_id?.part_number}`
                          )
                        }
                      />
                      <DeleteIconButton
                        tooltipTitle="delete"
                        onClick={() => deleteDocument(document._id)}
                      />
                    </>
                  )}
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </TableCustom>
    </React.Fragment>
  );
};

export default SupervisorTable;
