const style1 = {
    backgroundColor: '#4F81BD',
    color: '#fff',
    minWidth: '150px',
};
const style2 = {
    backgroundColor: '#00B050',
    color: '#fff',
};

export const HeaderStandard = [
    [
        { text: 'S.No.', style: style1 },
        { text: 'Check Points', style: style1 },
        { text: 'Limits', style: style1 },
        { text: 'Frequency', style: style1 },
        { text: 'OBSERVATION', style: style1 },
    ],
];

export const headerConstant = {
    tableCompHeadTitle: 'PREDICTIVE Maintenance CHECKSHEET ',
    addButtonLable: '',
    datePickerLabel: 'Search By Year',
    modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
    getById: 'predictiveMaintenancePlanStatusCheckListRoute',
    post: 'predictiveMaintenancePlanStatusCheckListRoute',
    update: 'predictiveMaintenancePlanStatusCheckListRoute',
    delete: 'predictiveMaintenancePlanStatusCheckListRoute',
    // below this master checklist api
    masterCheckListAutoComplete:
        'predictiveMaintenancePlanMasterChecklistRoute/masterCheckListAutoComplete',
    getMasterCheckListById: 'predictiveMaintenancePlanMasterChecklistRoute',
};

export const navigationConstant = {};
