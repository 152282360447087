import { Box } from '@mui/system';
import axios from 'axios';
import { useState } from 'react';
import { useQuery } from '../../../hooks/UseQuery';
import TController from './create/TController';
import useGetData from './helpers/useGetData';
import { Loader } from '../../../components/common/Loader';
import HeaderCreate from './HeaderCreate';
import { moduleConstant } from './constant';
import useConfirm from '../../../components/common/useConfirm';
import ModalCustom from '../../../components/common/Modal';
import PickRiPart from './create/PickRiPart';

interface MyData {
  moduleData: any;
  isLoading: boolean;
  setModuleData: any;
  setRenderforUpdate: any;
  setParentRender: React.Dispatch<React.SetStateAction<boolean>>;
  error?: string | null;
}
const Create = () => {
  const date = useQuery('date');
  // const isView = useQuery('isView') === 'true' ? true : false;
  const [isView, setisView] = useState<boolean>(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [partIdx, setPartIdx] = useState<number>(0);
  const [DialogUpdate, confirmUpdate] = useConfirm(
    'Save',
    'Are you sure you want to save?',
  );

  const {
    error,
    moduleData,
    setModuleData,
    isLoading,
    setParentRender,
    setRenderforUpdate,
  }: MyData = useGetData();

  const handleUpdate = async () => {
    if (!date) {
      alert('date required');
      return;
    }
    const ans = await confirmUpdate();
    if (!ans) return;
    if (moduleData?._id) {
      axios
        .put(`${moduleConstant.apiConstant}/${moduleData?._id}`, moduleData)
        .then((res) => {
          setRenderforUpdate((prev: any) => !prev);
          alert('successfully updated');
        })
        .catch((err) => console.error(err));
    } else {
      axios
        .post(`${moduleConstant.apiConstant}`, moduleData)
        .then((res) => {
          setRenderforUpdate((prev: any) => !prev);
          alert('successfully updated');
        })
        .catch((err) => console.error(err));
    }
  };

  const handleDelete = (list_index: number) => {
    setModuleData((prev: any) => ({
      ...prev,
      list: prev.list.filter((_: any, index: number) => index !== list_index),
    }));
  };

  return (
    <Box>
      <Loader loader={isLoading} />
      <HeaderCreate
        {...{
          handleUpdate,
          moduleData,
          setModuleData,
        }}
      />
      <TController
        {...{
          moduleData,
          isView,
          handleDelete,
          setModuleData,
          setParentRender,
          setIsOpenPopup,
          setPartIdx,
        }}
      />
      <ModalCustom
        title="Part List"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <PickRiPart moduleData={moduleData?.list?.[partIdx]} />
      </ModalCustom>
      <DialogUpdate isSubmitContent={false} />
    </Box>
  );
};

export default Create;
