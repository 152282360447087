import { Box, Paper, Tab, Tabs } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { PersitedDatePicker } from '../../../components/common';
import MonthlyPartWise from './dashBoard/MonthlyPartWise/MonthlyPartWise';
import { useQuery } from '../../../hooks/UseQuery';
import ChildHeader from '../../../components/ui/ChildHeader';
import AutocompleteMuiCustom from '../../../components/common/AutocompleteMuiCustom';
import InputMuiCustom from '../../../components/common/inputs/InputMuiCustom';
import { UpdateButton } from '../../../components/common/button/CustomButtons';

const DailyProductionReportDashboardTabs = () => {
  const date = useQuery('date') || '';
  const [tabValue, setTabValue] = useState(0);
  const [tableData, setTableData] = useState<any[]>([]);
  const [data, setData] = useState<any>({});
  const [render, setRender] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [productivities, setProductivities] = useState({
    productivityPerc: [],
    target: 0,
  });
  const [partData, setPartData] = useState([]);
  const [partId, setPartId] = useState({ _id: '', part_name: '' });
  const [target, setTarget] = useState(0);

  const fetchData = async () => {
    if (date && partId._id) {
      if (tabValue == 0) {
        const res = await axios
          .get(
            `/api/dailyProductionReport/dashboard/getProductivityByMonthAndPartName/?date=${date}&partId=${partId?._id}`
          )
          .catch((err) => {
            console.log(err);
          });
        setProductivities(res?.data);
      }
    }
  };
  const fetchPartData = () => {
    axios
      .get(`/api/addPartRoute/partList/autoComplete`)
      .then((res) => {
        setPartData(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, [date, tabValue, partId._id]);

  useEffect(() => {
    fetchPartData();
  }, []);

  const updateTarget = async () => {
    let isConfirm = window.confirm('Are you sure you want to update target');
    if (isConfirm) {
      axios
        .patch(`/api/dailyProductionReport/updateTarget`, {
          target: target,
        })
        .then((res) => {
          if (res.data.status === 'success') {
            alert('target Change successfully');
          }
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Box>
      <ChildHeader text="DAILY PRODUCTIVITY GRAPH">
        <InputMuiCustom
          type="number"
          name="target"
          placeholder="Target"
          autoComplete="true"
          required={true}
          onChange={(e: any) => {
            setTarget(e.target.value);
          }}
          value={productivities?.target || target || 0}
          sx={{ width: 300, marginLeft: 2, background: 'white' }}
        />
        <UpdateButton
          label="UPDATE TARGET "
          size="large"
          onClick={() => updateTarget()}
          sx={{ marginLeft: 2 }}
        />
      </ChildHeader>
      <Paper elevation={2}>
        <Tabs
          value={tabValue}
          onChange={(_, newValue) => setTabValue(+newValue)}
          indicatorColor="primary"
          scrollButtons="auto"
          textColor="secondary"
          variant="fullWidth"
        >
          <Tab label="PRODUCTIVITY %AGE MONTH AND PART NAME WISE" />
        </Tabs>
      </Paper>
      <Box sx={{ margin: 2, display: 'flex', marginLeft: '25rem' }}>
        <PersitedDatePicker label="Search By Month" views={['month', 'year']} />
        <Box sx={{ marginLeft: 2, minWidth: 600 }}>
          <AutocompleteMuiCustom
            id="part_name"
            label="Select Part Name"
            option_name="part_name"
            arrayofObj={partData}
            value={partId || ''}
            onChange={(e, value) => {
              if (value) setPartId(value);
            }}
          />
        </Box>
      </Box>

      <section>
        {tabValue === 0 && (
          <MonthlyPartWise
            apiData={productivities}
            partName={partId?.part_name}
            target={productivities?.target || target}
          />
        )}
      </section>
    </Box>
  );
};

export default DailyProductionReportDashboardTabs;
