import moment from 'moment';

export const initialState = {
  isSubmitted: false,
  is_admin_request_no: null,
  is_admin_request: null,
  report_prepared_by: null,
  report_approved_by: null,
  slip_no:'',
  raised_by:'',
  attended_by:'',
  department_id:null,
  breakdown_date:moment(),
  machine_id:null,
  breakdown_start_time:null,
  breakdown_end_time:null,
  problem_nature:'',
  problem_desc:'',
  action:''
};
