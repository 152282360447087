import React, { useEffect, useState } from 'react';
import CustomSelect from '../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import { TableInput } from '../../../../../components/common';
import { RenderTable } from '../../../../../components/ui';
import { Table } from '../../../../../components/ui/renderTable/styled';
import axios from 'axios';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';

const getReworkQuantity = (reasons_for_rej: any) => {
  return reasons_for_rej?.reduce((sum: any, reason: any) => {
    if (reason?.reason?.substring(reason?.reason?.length - 3) === '(R)')
      return sum + +reason.rej_qty;
    return sum;
  }, 0);
};
const getScrapQuantity = (reasons_for_rej: any) => {
  return reasons_for_rej?.reduce((sum: any, reason: any) => {
    if (reason?.reason?.substring(reason?.reason?.length - 3) === '(S)')
      return sum + +reason.rej_qty;
    return sum;
  }, 0);
};
const RejectionTable = (props: any) => {
  const { process, isView } = props;
  const [render, setRender] = useState(false);
  const [rejectionReasons, setRejectionReasons] = useState([]);
  const [reason, setReason] = useState('');

  useEffect(() => {
    axios
      .get('/api/productionDefectRoutes/defectList/defectForAutoComplete')
      .then((res) => {
        if (res?.data) {
          setRejectionReasons(res?.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Table style={{ width: '80vh' }}>
      <thead>
        <tr>
          <th>S.No.</th>
          <th>Reasons</th>
          <th>Rejection Qty</th>
        </tr>
      </thead>
      <tbody>
        {process?.reasons_for_rej?.map((item: any, index: number) => (
          <tr>
            <th>{index + 1}</th>
            <td style={{ minWidth: '300px', textAlign: 'center' }}>
              {isView ? (
                item.reason
              ) : (
                <CustomSelect
                  name="reason"
                  value={item.reason}
                  onChange={(e) => {
                    item.reason = e.target.value;
                    setRender((prev) => !prev);
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  {rejectionReasons.map((reason: any) => (
                    <MenuItem
                      value={
                        reason.type_of_defect === 'Scrap'
                          ? `${reason.defect_name} (S)`
                          : `${reason.defect_name} (R)`
                      }
                    >
                      {reason.type_of_defect === 'Scrap'
                        ? `${reason.defect_name} (S)`
                        : `${reason.defect_name} (R)`}
                    </MenuItem>
                  ))}
                </CustomSelect>
              )}
            </td>
            <td style={{ minWidth: '100px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="rej_qty"
                key="rej_qty"
                type="number"
                value={item.rej_qty}
                onChange={(e) => {
                  item.rej_qty = e.target.value;
                  if (
                    item?.reason?.substring(item?.reason?.length - 3) === '(R)'
                  )
                    process.rework_qty = getReworkQuantity(
                      process.reasons_for_rej
                    );
                  else
                    process.scrap_qty = getScrapQuantity(
                      process.reasons_for_rej
                    );
                  process.reject_qty = process.rework_qty + process.scrap_qty;
                  process.ok_qty = process.check_qty - process.reject_qty;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th colSpan={2}>Total Rejction</th>
          <th>{process.reject_qty}</th>
        </tr>
      </tfoot>
    </Table>
  );
};

export default RejectionTable;
