import { TableInput } from '../../../../../components/common';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import { useQuery } from '../../../../../hooks/UseQuery';
import React, { useState } from 'react';
import TableHeaderHtmlCustom from '../../../../../components/common/customTable/TableHeaderHtmlCustom';
import { HeaderStandard, headerConstant } from '../helpers/constant';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';

interface IProps {
    moduleData: any;
    machineData?: any;
    updateModuleData?: any;
}

const THead = ({ machineData, moduleData, updateModuleData }: IProps) => {
    const isView = useQuery('isView') === 'true' ? true : false;
    const [render, setRender] = useState(false);
    const operatorName = useQuery('operatorName');
    const empCode = useQuery('empCode');
    const freq = useQuery('freq');
    const machine = useQuery('machine');
    const line = useQuery('line');
    const plantLocation = useQuery('plantLocation');


    const handleInputChange = (
        fieldName: string,
        value: string | number | null,
    ) => {
        updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
    };

    return (
        <thead>
            <TableCompHead
                colSpan={10}
                rowSpan={3}
                labels={['Doc. No:', 'Rev. No.:', 'Eff. Date:']}
                values={['F-HR-22', '00', '01.04.2023']}
                heading={`${headerConstant.tableCompHeadTitle}`}
            />
            {/* <tr style={{ height: '50px' }}>
                <th colSpan={1}>CHECKSHEET NAME</th>
                <th colSpan={2}>{moduleData.checkList_name}</th>
                <th colSpan={12}></th>
            </tr> */}
            <tr>
                <th colSpan={1}>Checksheet Name </th>
                <th colSpan={1}>
                    <TableInput
                        isView={true}
                        style={{ height: '80px' }}
                        name="checkList_name"
                        type="text"
                        value={moduleData.checkList_name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleInputChange(e.target.name, e.target.value)
                        }
                    />
                </th>
                <th colSpan={1}>Operator Name </th>
                <th colSpan={1}>
                    <TableInput
                        isView={true}
                        style={{ height: '80px' }}
                        name="operator_name"
                        type="text"
                        value={moduleData.operator_name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleInputChange(e.target.name, e.target.value)
                        }
                    />
                </th>
                <th colSpan={1}>Observer Name </th>
                <th colSpan={1}>
                    <TableInput
                        isView={true}
                        style={{ height: '80px' }}
                        name="observer_name"
                        type="text"
                        value={moduleData.observer_name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleInputChange(e.target.name, e.target.value)
                        }
                    />
                </th>
                <th colSpan={1}>Date </th>
                <th colSpan={1}>
                    {isView?
                    (
                       formatDate(moduleData?.date_of_training)
                    //    { formatDate(moduleData.date_of_training)}
                    ):(

                        <DatePickerMui
                      value={moduleData.date_of_training ? moment(moduleData.date_of_training) : null}
                      onChange={(date) => {
                        moduleData.date_of_training = date;
                        handleInputChange('date', date);
                      }}
                    />
                    )
                    }
                    {/* <TableInput
                        isView={isView}
                        style={{ height: '80px' }}
                        name="checkList_name"
                        type="text"
                        value={moduleData.checkList_name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleInputChange(e.target.name, e.target.value)
                        }
                    /> */}
                </th>
                <th colSpan={1}>Shift </th>
                <th colSpan={1}>
                    <TableInput
                        isView={isView}
                        style={{ height: '80px' }}
                        name="shift"
                        type="text"
                        value={moduleData.shift}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleInputChange(e.target.name, e.target.value)
                        }
                    />
                </th>
                {/* <th colSpan={13}></th> */}
            </tr>
            {/* <tr>
                <th colSpan={2} align="left">Observation Date :


                    <DatePickerMui
                        value={moment(moduleData.observation_date)}
                        onChange={(date) => {
                            moduleData.observation_date = date;
                            setRender((prev) => !prev);
                        }}
                    />
                </th>
                <th colSpan={2} align="left">Plant Location: {plantLocation}</th>
                <th colSpan={2} align="left">Line Name : {line}</th>
                <th colSpan={2} align="left">Emp Code : {empCode}</th>
                <th colSpan={2} align="left">Operator Name : {operatorName}</th>
                <th colSpan={2} align="left">Freq : {freq}</th>
                <th colSpan={2} align="left">Machine : {machine}</th>
                <th colSpan={2} align="left">
                    X = NOT OK √ = OK
                </th>
            </tr> */}
            <TableHeaderHtmlCustom tableHeaderObj={HeaderStandard} />
        </thead>
    );
};

export default React.memo(THead);
