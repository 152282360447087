import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import axios from 'axios';
import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import {
  CustomPagination,
  PersitedDatePicker,
  SearchBar,
} from '../../../components/common';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import { IData } from './helpers/interface';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../services/auth/AuthProvider';
import AdminTable from './components/tablelist/AdminTable';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import useDebounce from '../../../hooks/useDebounce';
import FilterBy from '../../../components/common/FilterBy';

const statusObj = {
  pending: 1,
  accepted: 2,
  rejected: 3,
};

const filterOptions = [
  { value: 'module_dateA', name: 'Most Oldest' },
  { value: 'part_id.model_id.model_name', name: 'Model Name' },
];

const List = () => {
  const { state } = useAuth();
  const date = useQuery('date');
  const [tableData, setTableData] = useState<IData[]>([]);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const page = useQuery('page') || 1;
  const search = useQuery('search') || '';
  const sortBy = useQuery('sortBy') || 'module_date';
  const { user, IS } = state;
  const navigate = useNavigate();
  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE ',
    'Are you sure you want to delete this document?'
  );

  // useEffect(() => {
  // }, [render, date, page]);
  const fetchData = async () => {
    axios
      .get(
        `/api/finalInspectionReport/monthly/${date}?page=${page}&perPage=${perPage}&search=${search}&sortBy=${sortBy}`
      )
      .then((res) => {
        setTableData(res?.data?.results);
        setPerPage(res?.data?.perPage);
        setTotalPages(res?.data?.pages);
      })
      .catch((err) => console.log(err));
  };

  const deleteDocument = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    axios
      .delete(`/api/finalInspectionReport/${id}`)
      .then(() => {
        alert('document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => {
        console.log(err);
      });
    return;
  };

  const updateStatus = async (id: string, documentIndex: number) => {
    let isConfirm = window.confirm('Are you sure you want to update status');
    if (isConfirm && tableData.length > 0) {
      axios
        .patch(`/api/finalInspectionReport/updateStatus/${id}`, {
          is_admin_request_no: tableData[documentIndex].is_admin_request_no,
          report_approved_by: user?.name,
        })
        .then((res) => {
          if (res.data.status === 'success') {
            alert('Status Change successfully');
          }
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleChangeStatus = (
    e: React.ChangeEvent<HTMLSelectElement>,
    documentIndex: number
  ) => {
    const values = [...tableData];
    setTableData(values);
  };
  useDebounce(fetchData, 500, [page, search, render, date, sortBy]);

  return (
    <>
      <ChildHeader text="FINAL INSPECTION REPORT">
        <AddButton
          onClick={() => navigate(`/common/createfinalInspectionReport`)}
          label="NEW REPORT"
        />
      </ChildHeader>
      {/* <Box sx={{ margin: 2 }}> */}

      <SearchFilterContainer filter={true} style={{ display: 'flex' }}>
        <PersitedDatePicker label="Search By Month" views={['month', 'year']} />
        <SearchBar label="Search By Part" sx={{ width: 900 }} />
        <FilterBy
          label="Sort By"
          name="sortBy"
          options={filterOptions}
          sx={{ width: 300 }}
        />
      </SearchFilterContainer>
      {/* </Box> */}
      <AdminTable
        {...{
          handleChangeStatus,
          deleteDocument,
          updateStatus,
          statusObj,
          tableData,
          page,
          perPage,
        }}
      />
      <CustomPagination totalPage={totalPages} />
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
