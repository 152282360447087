import React, { useRef, useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
import { SearchBar } from '../../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
} from '../../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import { IData } from './helpers/Interface';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import ViewFile from './ViewFile';
import formatDate from '../../../components/common/formatDate';
import TableScroll from '../../../components/ui/renderTable/TableScroll';

function wrapText(text: string, wordsPerLine: number) {
  if (!text) return '';
  // Split the text into an array of words
  const words = text.split(/\s+/);
  let result = '';

  // Iterate through the words and create lines with the specified number of words
  for (let i = 0; i < words.length; i += wordsPerLine) {
    // Slice the array to get the segment of words for the current line
    const line = words.slice(i, i + wordsPerLine);
    // Join the segment into a string and add a newline character
    result += line.join(' ') + '\n';
  }

  return result;
}

const tableHeaderObj = [
  [
    { text: 'S.No.', rowspan: 1, colspan: 1 },
    { text: 'SUPPLIER NAME', rowspan: 1, colspan: 1 },
    { text: 'MATERIAL DESCRIPTION', rowspan: 1, colspan: 1 },
    { text: 'ALTERNATE SOURCE  - I', rowspan: 1, colspan: 1 },
    { text: 'ALTERNATE SOURCE  - II', rowspan: 1, colspan: 1 },
    { text: 'ORIGNIAL SOURCE', rowspan: 1, colspan: 1 },
    { text: 'SUPPLIER CODE', rowspan: 1, colspan: 1 },
    { text: 'PRODUCT / SERVICE', rowspan: 1, colspan: 1 },
    { text: 'CONTACT PERSON', rowspan: 1, colspan: 1 },
    { text: 'ADDRESS', rowspan: 1, colspan: 1 },
    { text: 'LEAD TIME', rowspan: 1, colspan: 1 },
    { text: 'INDUCTED ON DATE', rowspan: 1, colspan: 1 },
    { text: 'QMS CERTIFICATION (VALID UPTO)', rowspan: 1, colspan: 1 },
    { text: 'Customer Audit Due Date', rowspan: 1, colspan: 1 },
    { text: 'Score of Last Audit', rowspan: 1, colspan: 1 },
    { text: 'Previous Audit Status', rowspan: 1, colspan: 1 },

    {
      text: 'UPLOAD VENDOR DOCUMENTS',
      colspan: 1,
      rowspan: 1,
    },
    { text: 'REMARKS', rowspan: 1, colspan: 1 },
    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [
  { value: 'inducted_on_date', name: 'Most Recent First' },
  { value: 'inducted_on_date_old', name: 'Most Oldest First' },
];

const List = () => {
  const [data, setData] = useState<IData[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [vendorId, setVendorId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const search = useQuery('search') || '';
  const filter = useQuery('filter') || 'createdAt';
  const page = useQuery('page') || 1;
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const idRef = useRef('');
  const fileTypeRef = useRef('');
  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE ',
    'Are you sure you want to delete this?'
  );

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/vendorRoute/?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteDocument = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/vendorRoute/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, search, render, isModal, filter]);

  const uploadFile = async (e: any, id: string, fileType: string) => {
    if (!window.confirm('Are you sure you want upload this Files')) return;
    const files = e.target.files;
    if (!files) return;
    let formData = new FormData();
    for (const key of Object.keys(files)) {
      formData.append('files', files[key]);
    }
    setIsLoading(true);
    axios
      .patch(`/api/vendorRoute/uploadFile/${fileType}/${id}`, formData)
      .then((res) => {
        if (res.data) {
          alert('document upload successly');
          setIsLoading(false);
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <ChildHeader text="LIST OF APPROVED SUPPLIER">
        <AddButton onClick={() => setIsModal(true)} label="New Supplier" />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search" />
        <FilterBy label="Sort" options={filterOptions} />
      </SearchFilterContainer>
      <TableScroll>
        <TableCustom tableHeaderObj={tableHeaderObj}>
          <TableBody>
            {data &&
              data.map((item: { [key: string]: any }, index: number) => (
                <React.Fragment key={item._id}>
                  <TableRow>
                    <TableCell align="center">
                      {perPage * (+page - 1) + index + 1}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: 'capitalize', width: '200px' }}
                    >
                      {item.vender_name}
                    </TableCell>
                    <TableCell align="center" sx={{ width: '180px' }}>
                      {item.mateiral_description}
                    </TableCell>
                    <TableCell align="center" sx={{ width: '180px' }}>
                      {item.alternate_source_1}
                    </TableCell>
                    <TableCell align="center" sx={{ width: '180px' }}>
                      {item.alternate_source_2}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ width: '330px', minWidth: '330px' }}
                    >
                      {item.original_source}
                    </TableCell>
                    <TableCell align="center">{item.vender_code}</TableCell>
                    <TableCell align="center" sx={{ width: '180px' }}>
                      {item.product_service}
                    </TableCell>
                    <TableCell align="center">{item.contact_person}</TableCell>
                    <TableCell
                      align="center"
                      sx={{ width: '250px', minWidth: '250px' }}
                    >
                      {item.address}
                    </TableCell>
                    <TableCell align="center">{item.lead_time}</TableCell>
                    <TableCell align="center">
                      {formatDate(item.inducted_on_date)}
                    </TableCell>
                    <TableCell align="center">
                      {formatDate(item.qms_certification_valid_upto_date)}
                    </TableCell>
                    <TableCell align="center">
                      {formatDate(item.audit_due_date)}
                    </TableCell>
                    <TableCell align="center">{item.score_last_audit}</TableCell>
                    <TableCell align="center" style={item.status === "NC" ? { background: "yellow" } : (item.status === "CLOSED" ? { background: "green" } : (item.status === "NOT" ? { background: "red" } : {}))}>{item.status}</TableCell>
                    <TableCell align="center">
                      <Stack
                        direction={'row'}
                        alignItems="center"
                        justifyContent="start"
                      >
                        <CustomUploadButton
                          id={item?._id || index + 'id'}
                          accept="image/png,image/jpeg,image/jpg,application/pdf"
                          multiple={true}
                          onChange={(e) => {
                            uploadFile(e, item?._id, 'files');
                          }}
                        />
                        {item.files?.length > 0 && (
                          <>
                            <ViewIconButton
                              tooltipTitle="view"
                              sx={{ marginLeft: '1.5rem' }}
                              onClick={() => {
                                idRef.current = item._id;
                                fileTypeRef.current = 'files';
                                setIsOpenPopup(true);
                              }}
                            />
                          </>
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell align="center">{item.remarks}</TableCell>
                    <TableCell>
                      <Stack direction="row" sx={{ justifyContent: 'center' }}>
                        <EditIconButton
                          tooltipTitle="EDIT"
                          onClick={() => {
                            setVendorId(item?._id || '');
                            setIsModal(true);
                          }}
                        />

                        <DeleteIconButton
                          tooltipTitle="DELETE"
                          onClick={() => {
                            deleteDocument(item?._id || '');
                          }}
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </TableCustom>
      </TableScroll>

      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        title="FILE LIST"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <ViewFile id={idRef.current} fileType={fileTypeRef.current} />
      </ModalCustom>

      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setVendorId(null);
          setIsModal(false);
        }}
        title="LIST OF APPROVED SUPPLIER"
      >
        <Create id={vendorId} setIsModal={setIsModal} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
