import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import { TableCompHead } from '../../../../components/ui';
import { useQuery } from '../../../../hooks/UseQuery';
import { moduleConstant } from '../constant';
interface IProps {
  moduleData: any;
  setModuleData: any;
  isView: boolean;
  defects: any;
}
const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '100px',
  height: '50px',
};

const THead = ({ defects, moduleData, isView }: IProps) => {
  const date = useQuery('date');
  const year = date && new Date(date).getFullYear();
  const tableHeaderObj = [
    [
      { text: 'S.NO', style: style1, rowspan: 2 },
      { text: 'LOCATION', style: style1, rowspan: 2 },
      { text: 'DATE', style: style1, rowspan: 2 },
      { text: 'INVOICE NO.', style: style1, rowspan: 2 },
      { text: 'BATCH CODE', style: style1, rowspan: 2 },
      { text: 'PART NO.', style: style1, rowspan: 2 },
      { text: 'INVOICE QTY.', style: style1, rowspan: 2 },
      { text: 'CUSTOMER', style: style1, rowspan: 2 },
      { text: 'SOURCE', style: style1, rowspan: 2 },
      { text: 'HOLD', style: style1, rowspan: 2 },
      { text: 'ACCEPTED QTY.', style: style1, rowspan: 2 },
      { text: 'REJECT QTY', style: style1, rowspan: 2 },
      defects.length && {
        text: 'DEFECT LIST',
        style: style1,
        colspan: defects.length,
      },
      !isView && { text: 'ACTION', style: style1, rowspan: 2 },
    ],
  ];

  return (
    <thead
      style={{
        position: 'sticky',
        width: '100%',
        top: '-.5rem',
        border: '1px solid black',
        zIndex: '1',
        backgroundColor: '#38d9a9',
        color: 'black',
      }}
    >
      <TableCompHead
        colSpan={12 + defects.length + (!isView && 1)}
        rowSpan={3}
        labels={['Doc No.:', 'Rev No.:', 'Date:']}
        values={['HO-F-QAS-76', '02', '10-07-2014']}
        heading={`${moduleConstant?.heading} - ${year}`}
      />
      <TableHeaderHtmlCustom tableHeaderObj={tableHeaderObj} />
      <tr>
        {defects.map((el: any, index: number) => (
          <th
            key={`${index}_headcol`}
            align="center"
            style={{ textAlign: 'center', ...style1 }}
          >
            {el.defect_name}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
