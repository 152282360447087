import moment from 'moment';

export const processes = [
  {
    process_date: moment(),
    vendor_id: null,
    no_qty_recieved: 0,
    no_qty_rejected: 0,
    no_lot_rejected: 0,
    no_lot_segregation: 0,
    no_lot_under_deviation: 0,
    score_response: 0,
  },
];