import { useRef, useState } from 'react';
import CustomSelect from '../../../../components/common/SelectField';
import { Grid, MenuItem, Tooltip } from '@mui/material';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import moment from 'moment';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../components/common/formatDate';

interface IProps {
  moduleData: any;
  customerData: any;
  isView: boolean;
  handelDeleteRows: any;
  setIsOpenPopup: any;
  setPartIdx: any;
  setIsOpenPopupRejections: any;
  setRejectionsIndx: any;
}

const TBody = ({
  moduleData,
  customerData,
  isView,
  handelDeleteRows,
  setIsOpenPopup,
  setPartIdx,
  setIsOpenPopupRejections,
  setRejectionsIndx,
}: IProps) => {
  const [render, setRender] = useState(false);
  // console.log('moduleData', moduleData, isView);
  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <tr>
              <td>{index + 1}</td>
              <td>
                <TableInput
                  isView={isView}
                  name="part_name"
                  type="text"
                  value={process.part_name}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>
                <TableInput
                  isView={isView}
                  name="production"
                  type="number"
                  value={process.production}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>{' '}
              <td>
                <TableInput
                  isView={isView}
                  name="incoming"
                  type="number"
                  value={process.incoming}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>
                <TableInput
                  isView={true}
                  name="total"
                  type="number"
                  value={
                    parseInt(process.production) + parseInt(process.incoming)
                  }
                />
              </td>
              <td>
                <TableInput
                  isView={isView}
                  name="fi_report"
                  type="number"
                  value={process.fi_report}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>
                <TableInput
                  isView={true}
                  name="variation"
                  type="number"
                  value={
                    parseInt(process.production) +
                    parseInt(process.incoming) -
                    parseInt(process.fi_report)
                  }
                />
              </td>
              <td
                style={{ minWidth: '80px', textAlign: 'center' }}
                colSpan={isView ? 2 : 1}
              >
                <TableInput
                  isView={isView}
                  name="remarks"
                  type="text"
                  value={process.remarks}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              {!isView && (
                <td style={{ minWidth: '100px', textAlign: 'center' }}>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
