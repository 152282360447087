import { useState } from 'react';
import TBodyRow from './TBodyRow';

interface IProps {
  moduleData: any;
  handleDelete: any;
  isView: boolean;
}

const TBody = ({ moduleData, isView, handleDelete }: IProps) => {
  const [, setTbodyrender] = useState(false);
  return (
    <tbody>
      {moduleData?.list?.map((item: any, index: number) => (
        <TBodyRow
          {...{
            item,
            index,
            isView,
            moduleData,
            handleDelete,
            setTbodyrender,
          }}
        />
      ))}
      <tr>
        <td colSpan={14}></td>
        <td>{moduleData?.sum_of_delivery_rating}</td>
        <td>{moduleData?.sum_of_quality_Rating}</td>
        <td colSpan={4}></td>
        <td>{moduleData?.sum_of_overall_rating}</td>
      </tr>
      <tr>
        <th align="left">NOTE: </th>
        <th align="left" colSpan={21}>
          {
            'PREM FRIGHT  RAT  :   IF ANY FRIEGHT, RAT : 00, IF NO FRIGHT, RAT : 100%,  IF ANY LINE STOPAGE, AT KRI  OR CUST, RAT : 00, IF NO, RAT : 100.'
          }{' '}
          <br />
          <hr />
          {
            'IF RSPONSE FOR DELIVERY OR QUALITY NOT GOOD, RAT : 00, IF GOOD, RAT : 100 %'
          }
        </th>
      </tr>
    </tbody>
  );
};

export default TBody;
