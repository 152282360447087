const style1 = {
  backgroundColor: '#4F81BD',
  // backgroundColor: '#00B050',
  color: '#fff',
  minWidth: '80px',
  border: '1px solid #343a40',
};

export const tableHeaderObj = [
  [
    { text: '#', style: { ...style1 } },
    { text: 'EMPLOYEE NAME', style: { ...style1, minWidth: '150px' } },
    { text: 'DESIGNATION', style: { ...style1, minWidth: '150px' } },
    { text: 'DEPARTMENT', style: { ...style1 } },
    { text: 'EMPLOYEE TYPE', style: { ...style1 } },
    { text: 'JOINING DATE', style: { ...style1 } },
    { text: 'QUALIFICATION', style: { ...style1 } },
    { text: 'WORK EXPRERIENCE', style: { ...style1, minWidth: '140px' } },
    { text: 'PLANT', style: { ...style1 } },
    { text: 'POISON TEST', style: { ...style1 } },
    { text: 'OPERATOR OBSERVANCE TEST', style: { ...style1 } },
    { text: 'EMPLOYEE INDUCTION', style: { ...style1 } },
    { text: 'TEST', style: { ...style1 } },
    // { text: 'OJT', style: { ...style1 } },
    { text: 'OJT DAY 1', style: { ...style1 } },
    { text: 'OJT DAY 2', style: { ...style1 } },
    { text: 'OJT DAY 3', style: { ...style1 } },
    // { text: 'L2 TEST', style: { ...style1 } },
    { text: 'SKILL LEVEL', style: { ...style1 } },
    { text: 'TNI', style: { ...style1 } },
    { text: 'TRAINING HISTORY', style: { ...style1 } },
    { text: 'TRAINING EFFECTIVENESS', style: { ...style1 } },
    { text: 'EMPLOYEE DOCS', style: { ...style1 } },
    { text: 'STATUS', style: { ...style1 } },
    { text: 'ACTION', style: { ...style1 } },
  ],
];

export const apiConstant = {
  getAllStatus: 'annualPmPlanStatusRoute/getAllSatusByYear',
  upadateListStatus: 'annualPmPlanStatusRoute/handleStatusUpdate',
  checklistUpload: 'annualPmPlanStatusRoute/handleUpload',
  checklistDeleteImage: 'annualPmPlanStatusRoute/handleDeleteImage',
  machineDataForAutoComplete: 'machineRoutes/machine/ForAutocomplete',
  getTniTopic: 'tniTopicRoute',
  uploadFileRoute: 'uploadFile',
  deleteFileRoute: 'deleteFile',
};

export const navigationConstant = {
  skillEvaluationTestSheetListRouter:
    '/common/employeeSkillEvaluationSheetList',
  skillEvaluationTestSheetRouter: '/common/employeeSkillEvaluationSheetCreate',
  empOjtCreateRoute: '/common/onJobTraining',
  empOjtCreateRoute1: '/common/onJobTraining1',
  empOjtCreateRoute2: '/common/onJobTraining2',
  empTniTopicCreateRoute: '/common/empTraningTopicsCreateEdit',
};
