import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

interface IProps {
  value: any;
  style: any;
  year: any;
  month: string;
  onChange: any;
}

const getMonthDateRange = (year: number, month: number) => {
  // Create a date object at the first of the month
  if ([0, 1, 2].includes(month)) {
    year = year + 1;
  }
  const startDate = moment([year, month]);
  // Clone the date object for consistent results
  const endDate = moment(startDate).endOf('month');
  return {
    start: startDate.format('YYYY-MM-DD'),
    end: endDate.format('YYYY-MM-DD'),
  };
};

const StyledDateInput = styled.input`
  width: 150px;
  height: 50px;
  border: none; // Removed border
  padding: 8px 12px;
  font-size: 16px; // Bigger font size
  font-weight: bold; // Bolder font weight
  text-transform: uppercase; // Uppercase text
  color: #333;
  background: #fff; // White background

  &::-webkit-calendar-picker-indicator {
    filter: invert(10%); // Adjusts the color of the calendar icon
    cursor: pointer;
  }

  &:focus {
    outline: none; // Remove focus outline
  }
`;

const HtmlDateCustom = (props: IProps) => {
  const { value, onChange, month, year, style } = props;
  const currentYear = new Date(year).getFullYear();
  // Convert month name to month number (0-based, as expected by JavaScript Date)
  const monthNumber: any = moment().month(month).month();
  // Get the first and last day of the month
  const { start, end } = getMonthDateRange(currentYear, monthNumber);
  return (
    <>
      <StyledDateInput
        type="date"
        style={{ ...style, backgroundColor: value && '#ffd43b' }}
        value={value}
        min={start}
        max={end}
        onChange={onChange}
      />
    </>
  );
};

export default HtmlDateCustom;
