import lodash from 'lodash';


const processes=[
  {
    inspec_items:'TOTAL LENGTH (in case of drill machine/Pokayoke)',
    method:'Measuring Tape',
    spec:'',
    insp_qty:'',
    ok_qty:'',
    ng_qty:'',
    remarks:'',
  },
  {
    inspec_items:'',
    method:'',
    spec:'',
    insp_qty:'',
    ok_qty:'',
    ng_qty:'',
    remarks:'',
  },
  {
    inspec_items:'CLIP Pitch',
    method:'Guage',
    spec:'',
    insp_qty:'',
    ok_qty:'',
    ng_qty:'',
    remarks:'',
  },
  {
    inspec_items:'',
    method:'',
    spec:'',
    insp_qty:'',
    ok_qty:'',
    ng_qty:'',
    remarks:'',
  },
  {
    inspec_items:'',
    method:'',
    spec:'',
    insp_qty:'',
    ok_qty:'',
    ng_qty:'',
    remarks:'',
  },
];
export const initialState = {
  moduleDate: new Date(),
  module: '',
  shift: '',
  part_id:'',
  change_details:'',
  opt_name:'',
  insp_qty:'',
  ok_qty:'',
  ng_qty:'',
  remarks:'',
  judge:'',
  result:'',
  accept:'',
  reject:'',
  prepared_by:'',
  approved_by:'',
  process:processes,
 
 
};
