import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';
import CustomSelect from '../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={13}
        rowSpan={3}
        labels={['Doc. No:', 'Rev. No : ', 'Eff. Date:']}
        values={['F-29-02', '01', '1.7.2019']}
        heading="Machine History CARD"
      />
      {/* <tr> */}
        {/* <th colSpan={4}>Machine Details </th> */}
        {/* <th
          colSpan={2}
          rowSpan={4}
          style={{
            fontSize: '2rem',
            textAlign: 'center',
            fontWeight: 600,
          }}
        >
          Machine Maintenance Record
        </th> */}
        {/* <th colSpan={2} align="left">
          Prev. Maint. Frequency
        </th> */}
        {/* <th colSpan={2} align="left">
          <TableInput
            name="prev_maintenance_freq"
            type="text"
            value={moduleData.prev_maintenance_freq}
            onChange={(e) => {
              moduleData.prev_maintenance_freq = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th> */}
        {/* <th colSpan={2} align="left">
          Month
        </th> */}
      {/* </tr> */}
      <tr>
        <th colSpan={4} align="left">
          Machine Name :
        </th>
        <th colSpan={3} align="left">
          {moduleData.machine_name}
        </th>
        {/* <th colSpan={2} align="left">
          Machine Maker
        </th> */}
        {/* <th colSpan={2} align="left">
          {moduleData?.make} */}
          {/* <TableInput
            name="machine_maker"
            type="text"
            value={moduleData.machine_maker}
            onChange={(e) => {
              moduleData.machine_maker = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          /> */}
        {/* </th> */}
        <th colSpan={3}>
        Machine Specific Detail :
        </th>
        <th colSpan={3} >
          <TableInput
            name="machine_detail"
            type="text"
            value={moduleData.machine_detail}
            onChange={(e) => {
              moduleData.machine_detail = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Machine No :
        </th>
        <th colSpan={2} align="left">
          {moduleData.machine_no}
        </th>
        <th colSpan={2} align="left">
          Location :
        </th>
        <th colSpan={2} align="left">
          <TableInput
            name="location"
            type="text"
            value={moduleData.location}
            onChange={(e) => {
              moduleData.location = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
         <th colSpan={2} align="left">
           Make :
        </th>
         <th colSpan={3} align="left">
         {moduleData?.maker}
        </th>
      </tr>
      <tr>
        <th colSpan={4} align='left'>Date of Installation :
        <DatePickerMui
                value={
                  (moduleData?.date_of_install && moment(moduleData.date_of_install)) ||
                  null
                }
                onChange={(date) => {
                  moduleData.date_of_install = date;
                  setRender((prev) => !prev);
                }}
              />
        </th>
        <th colSpan={2}>Machine Classification ( critical / non critical) : 

        </th>
        <td colSpan={2}>
        <CustomSelect
            label=""
            name="machine_class"
            value={moduleData?.machine_class ||""}
            onChange={(e: any) => {
              moduleData.machine_class = e.target.value;
              setRender((prev) => !prev);
            }}
           
                
              >
                <MenuItem value="NONE">NONE</MenuItem>
                <MenuItem value="CRITICAL">CRITICAL</MenuItem>
                <MenuItem value="NON CRITICAL">NON CRITICAL</MenuItem>
              </CustomSelect>
        </td>
        <th colSpan={2} align='left'>Tonnage : 

        </th>
        <td colSpan={3}>
        <TableInput
            name="tonnage"
            type="text"
            value={moduleData.tonnage}
            onChange={(e) => {
              moduleData.tonnage = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </td>
      </tr>
      {/* <tr>
        <th colSpan={2} align="left">
          Capacity
        </th>
        <th colSpan={2} align="left">
          {moduleData.capacity}
        </th>
        <th colSpan={2} align="left">
          Mfg. Date
        </th>
        <th colSpan={2} align="left">
          {formatDate(moduleData?.mfg_date)}
        </th>
      </tr>
      <tr>
        <th
          colSpan={12}
          style={{
            fontSize: '2rem',
            textAlign: 'center',
            fontWeight: 600,
            border: '1px solid black',
            textTransform: 'uppercase',
          }}
        >
          Maintenance Details
        </th>
      </tr> */}
      <tr style={{ textTransform: 'uppercase' }}>
        {[
          'S. No',
          'DATE',
          'MAINTENANCE DETAIL',
          'DOWN TIME',
          'TOTAL TIME',
          'Spare Parts Used( if any)',
          'BREAKDOWN/PM',
          'SIGN. SUP. PROD.',
          'SIGN. Maintenance',
          'Verified By',
          'REMARK',
          'ACTION',
        ].map((head, index) => (
          <th
            key={head}
            style={{ textTransform: 'uppercase' }}
            colSpan={index==3?2:1}
            rowSpan={index==3?1:2}
          >
            {head}
          </th>
        ))}
      </tr>
      <tr>
      
        <th  rowSpan={1}>FROM
        </th>
        <th  rowSpan={1}>TO</th>
      </tr>
      {/* <tr style={{ textTransform: 'uppercase' }}>
        {['B/D', 'Prev.', 'Date', 'Time'].map((head, index) => (
          <th key={head + index} style={{ textTransform: 'uppercase' }}>
            {head}
          </th>
        ))}
      </tr> */}
    </thead>
  );
};

export default THead;
