import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import { IData } from './helpers/Interface';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import CustomSelect from '../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import SelectGridMui from '../../../components/common/selectscustom/SelectGridMui';
import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [defectData, setDefectData] = useState<any>({
    defect_name: null,
    type_of_defect: null,
  });
  const [departmentData, setDepartmentData] = useState<any>([]);
  const [designationData, setDesignationtData] = useState<any>([]);
  const [render, setRender] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Worker' : 'Create Worker'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    axios
      .get(`/api/department/departmentAll/ForAutocomplete`)
      .then((res) => {
        setDepartmentData(res?.data);
        // console.log(res?.data);
      })
      .catch((err) => console.log(err));
    axios
      .get(`/api/autocompleteRoute/getDesignation`)
      .then((res) => {
        setDesignationtData(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/productionWorkersRoutes/${id}`)
        .then((res) => setDefectData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleDefectData(e: React.ChangeEvent<HTMLInputElement>) {
    setDefectData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    setIsSubmit(true);
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/productionWorkersRoutes/${id}`, defectData)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/productionWorkersRoutes', defectData)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
        })
        .catch((err) => {
          setIsSubmit(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'operator_code'}
        name={'operator_code'}
        html_for={'operator_code'}
        label_name={'Operator Code: '}
        input_type={'text'}
        focusType={'onblur'}
        value={defectData?.operator_code || ''}
        onChange={handleDefectData}
      />
      <GridInputs
        id={'operator_name'}
        name={'operator_name'}
        html_for={'operator_name'}
        label_name={'Operator Name: '}
        input_type={'text'}
        focusType={'onblur'}
        value={defectData?.operator_name || ''}
        onChange={handleDefectData}
      />
      <GridInputs
        id={'father_husband_name'}
        name={'father_husband_name'}
        html_for={'father_husband_name'}
        label_name={'Father/Husband Name: '}
        input_type={'text'}
        focusType={'onblur'}
        value={defectData?.father_husband_name || ''}
        onChange={handleDefectData}
      />
      <SelectGridMui
        name="gender"
        value={defectData?.gender || ''}
        lable={'Gender : '}
        onChange={(e) => {
          defectData.gender = e.target.value;
          setRender((prev: any) => !prev);
        }}
      >
        <MenuItem value="Female">Female</MenuItem>
        <MenuItem value="Male">Male</MenuItem>
      </SelectGridMui>
      <AutocompleteGridmui
        id={'department_name'}
        label={'Department : '}
        option_name={'department_name'}
        arrayofObj={departmentData}
        value={defectData.department_name || defectData.department_id || ''}
        onChange={(e, value) => {
          defectData.department_id = value._id;
          defectData.department_name = value;
          setRender((prev) => !prev);
        }}
      />
      <AutocompleteGridmui
        id={'designation'}
        label={'Designation : '}
        option_name={'designation'}
        arrayofObj={designationData}
        value={defectData.designation_name || defectData.designation_id || ''}
        onChange={(e, value) => {
          defectData.designation_id = value;
          defectData.designation_name = value;
          setRender((prev) => !prev);
        }}
      />
      <SelectGridMui
        name="floor"
        value={defectData?.floor || ''}
        lable={'Floor : '}
        onChange={(e) => {
          defectData.floor = e.target.value;
          setRender((prev: any) => !prev);
        }}
      >
        <MenuItem value="B">B</MenuItem>
        <MenuItem value="GF">GF</MenuItem>
        <MenuItem value="FF">FF</MenuItem>
        <MenuItem value="TF">TF</MenuItem>
      </SelectGridMui>
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
