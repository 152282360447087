import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
// import { useEffect, useState } from 'react';
// import axios from 'axios';

interface IProps {
    moduleData: any;
    isView: boolean;
    handleSumbit: any;
    handelDeleteRows: any;
    uploadImageFile: any;
}

const TController = ({
    moduleData,
    handleSumbit,
    uploadImageFile,
    isView,
    handelDeleteRows,
}: IProps) => {
    return (
        <Box>
            <RenderTable>
                <THead
                    {...{
                        moduleData,
                        isView,
                    }}
                />
                <TBody
                    {...{
                        moduleData,
                        isView,
                        handelDeleteRows,
                        handleSumbit,
                        uploadImageFile,
                    }}
                />
                <TFoot
                    preparedBy={moduleData.report_prepared_by}
                    approvedBy={moduleData.report_approved_by}
                />
            </RenderTable>
        </Box>
    );
};

export default TController;
