import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import { useQuery } from '../../../../hooks/UseQuery';
import { moduleConstant } from '../constant';
import { TableCompHead } from '../../../../components/ui';
import moment from 'moment';
interface IProps {
  moduleData: any;
  setModuleData: any;
  isView: boolean;
  setRenderforUpdate: any;
}
const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '100px',
  height: '50px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
  minWidth: '80px',
};

const tableHeaderObj = [
  [
    { text: 'S.NO', style: style1, rowspan: 2 },
    { text: 'Part Name', style: style1, rowspan: 2 },
    { text: 'Part No.', style: style1, rowspan: 2 },
    { text: 'Ordered Qty.', style: style1, rowspan: 2 },
    { text: 'Schedule Qty.', style: style1, rowspan: 1, colspan: 4 },
    { text: 'Received Qty.', style: style1, rowspan: 1, colspan: 4 },
    { text: 'Total Qty. Received', style: style1, rowspan: 2 },
    { text: 'Total Ok Qty. Received', style: style1, rowspan: 2 },
    { text: 'Dely. Ratg.', style: style1, rowspan: 2 },
    { text: 'Qly. Ratg.', style: style1, rowspan: 2 },
    { text: 'Service Rating', style: style1, rowspan: 1, colspan: 4 },
    { text: 'Overall Rating', style: style1 },
    { text: 'Action', style: style1, rowspan: 2 },
  ],
  [
    { text: 'WK-01', style: style1 },
    { text: 'WK-02', style: style1 },
    { text: 'WK-03', style: style1 },
    { text: 'WK-04', style: style1 },
    { text: 'WK-01', style: style1 },
    { text: 'WK-02', style: style1 },
    { text: 'WK-03', style: style1 },
    { text: 'WK-04', style: style1 },
    { text: 'Prem. Frit.', style: style1 },
    { text: "KRI & KRI'S CUST.LINE STOPG", style: style1 },
    { text: 'QUALY.&DELY ISSUE', style: style1 },
    { text: 'SR', style: style1 },
    { text: '(0.5QR+0.3DR+0.2SR)', style: style1 },
  ],
];

const THead = ({ moduleData, setRenderforUpdate }: IProps) => {
  const date = useQuery('date');
  const formatDateMonth = date && moment(date).format('MMMM YYYY');
  const supplierName = useQuery('supplierName');

  return (
    <thead>
      <TableCompHead
        colSpan={22}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Date']}
        values={['F - 17 - 02', '02', '01.11.2021']}
        heading={`${moduleConstant?.heading}`}
      />

      <tr>
        <th colSpan={10} align="left">
          Supplier Name: {supplierName}
        </th>
        <th colSpan={12} align="left">
          For The Month Of: {formatDateMonth || null}
        </th>
      </tr>
      <TableHeaderHtmlCustom tableHeaderObj={tableHeaderObj} />
    </thead>
  );
};

export default THead;
