// TableRowComponent.jsx
import React from 'react';
import { TableInput } from '../../../../components/common';
import { useQuery } from '../../../../hooks/UseQuery';
import { DeleteIconButton } from '../../../../components/common/button/CustomIconButton';
import {
  StyledCheckbox,
  StyledDiv,
  StyledSpan,
  StyledTableRow,
} from '../helpers/module.styled';
import TableTextArea from '../../../../components/common/tableinputs/TableTextArea';

const options = {
  option_a: false,
  option_b: false,
  option_c: false,
  option_d: false,
};

export const TableRowComponent = React.memo(
  ({ key, item, index, updateModuleData, handleDelete }: any) => {
    const isView = useQuery('isView') === 'true' ? true : false;

    const handleInputChange = (
      fieldName: string,
      value: string | number | null,
      ishandleCalc: boolean = false
    ) => {
      updateModuleData(
        'row',
        index,
        fieldName,
        { [fieldName]: value },
        ishandleCalc
      );
    };

    return (
      <React.Fragment key={key}>
        <StyledTableRow index={index}>
          <td rowSpan={2}>
            <TableInput
              rowSpan={2}
              isView={isView}
              style={{}}
              name="s_no"
              type="text"
              value={item.s_no}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td rowSpan={2}>
            <TableTextArea
              rowSpan={2}
              isView={isView}
              name="question"
              type="text"
              value={item.question}
              onChange={(e: any) =>
                handleInputChange(e.target.name, e.target.value)
              }
            />
          </td>
          <td>
            <StyledDiv>
              <StyledSpan>a)</StyledSpan>
              <TableInput
                isView={isView}
                // divStyle={{ width: '200px', flex: 1 }}
                name="option_a"
                type="text"
                value={item.option_a}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(e.target.name, e.target.value)
                }
              />
              <StyledCheckbox
                checked={item.correct_answer === 'option_a'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange('correct_answer', 'option_a')
                }
              />
            </StyledDiv>
          </td>
          <td>
            <StyledDiv>
              <StyledSpan>b)</StyledSpan>
              <TableInput
                isView={isView}
                // divStyle={{ width: '200px', flex: 1 }}
                name="option_b"
                type="text"
                value={item.option_b}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(e.target.name, e.target.value)
                }
              />
              <StyledCheckbox
                checked={item.correct_answer === 'option_b'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange('correct_answer', 'option_b')
                }
              />
            </StyledDiv>
          </td>
          <td rowSpan={2}>
            {/* <TableInput
              rowSpan={2}
              isView={isView}
              style={{}}
              name="obtained_marks"
              type="number"
              value={item.obtained_marks}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e.target.name, e.target.value)
              }
            /> */}
          </td>
          <td rowSpan={2}>
            <TableInput
              rowSpan={2}
              isView={isView}
              style={{}}
              name="max_marks"
              type="number"
              value={item.max_marks}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleInputChange(e.target.name, e.target.value, true);
              }}
            />
          </td>
          {!isView && (
            <td style={{ minWidth: '50px' }} rowSpan={2}>
              <DeleteIconButton
                tooltipTitle={'delete'}
                onClick={() => handleDelete(index)}
              />
            </td>
          )}
        </StyledTableRow>
        <StyledTableRow index={index}>
          <td>
            <StyledDiv>
              <StyledSpan>c)</StyledSpan>
              <TableInput
                isView={isView}
                // divStyle={{ width: '200px', flex: 1 }}
                name="option_c"
                type="text"
                value={item.option_c}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(e.target.name, e.target.value)
                }
              />
              <StyledCheckbox
                checked={item.correct_answer === 'option_c'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange('correct_answer', 'option_c')
                }
              />
            </StyledDiv>
          </td>
          <td>
            <StyledDiv>
              <StyledSpan>d)</StyledSpan>
              <TableInput
                isView={isView}
                // divStyle={{ width: '200px', flex: 1 }}
                name="option_d"
                type="text"
                value={item.option_d}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(e.target.name, e.target.value)
                }
              />
              <StyledCheckbox
                checked={item.correct_answer === 'option_d'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange('correct_answer', 'option_d')
                }
              />
            </StyledDiv>
          </td>
        </StyledTableRow>
      </React.Fragment>
    );
  },
  (prevProps, nextProps) => {
    // Check if the relevant data for this row has changed
    return (
      prevProps.item === nextProps.item && prevProps.index === nextProps.index
    );
  }
);
