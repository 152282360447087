import { useEffect, useState } from 'react';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import { AddIconButton, DeleteIconButton, RemoveIconButton } from '../../../../../components/common/button/CustomIconButton';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';
import formatDate from '../../../../../components/common/formatDate';
import CustomSelect from '../../../../../components/common/SelectField';
import { Grid, MenuItem } from '@mui/material';
import axios from 'axios';

interface IProps {
  moduleData: any;
  isView: boolean;
  setIsOpenPopupRejections: any;
  setRejectionsIndx: any;
  operators: any;
  machineData: any;
  setIsOpenPopup: any;
  setPartIdx: any;
  x:any
  handelDeleteRows:any
}

const TBody = ({
  moduleData,
  isView,
  setIsOpenPopupRejections,
  setRejectionsIndx,
  operators,
  machineData,
  handelDeleteRows,
  setIsOpenPopup,
  setPartIdx,
  x
}: IProps) => {
  const [render, setRender] = useState(false);
  const [totalScrap, setTotalScrap] = useState(0);
 console.log("moduledata is ",moduleData)
  const [emp,setEmp]=useState([]);
    const fetchData = async () => {
      const res = await axios
        .get(
          `/api/employee/employeeAll/ForAutocomplete`
        )
        .catch((err) => {
          console.log(err);
        });
        // console.log("emp list is ",res?.data);
       setEmp(res?.data);
     
    };
    const formatToTwoDecimalPlaces = (value:Number) => {
      const formattedValue = value.toFixed(2); // Convert to string with two decimal places
      return formattedValue.substring(0, 4);
    };
    useEffect(() => {
      fetchData();
     
    }, [])

  return (
    <>
      <tbody>
        {moduleData && x==1 &&
          moduleData?.processes.map((process: any, index: number) => (
            <>
               <tr>
                <td style={{ minWidth: '100px', textAlign: 'center' }}>
                  {index + 1}
                </td>
                <td style={{ minWidth: '250px' }}>
                {isView ? (
                  process?.part_id && process?.part_id?.part_name
                ) : (
                  <Grid container xs={12} direction="row" alignItems={'center'}>
                    <Grid item xs={10}>
                      <p
                        style={{
                          fontSize: '1.6rem',
                        }}
                      >
                        {typeof process?.part_id === 'object' &&
                        process?.part_id !== null
                          ? process?.part_id?.part_name
                          : process?.partName || ''}
                        {!process?.part_id && 'Pick Part Name'}
                      </p>
                    </Grid>

                    {process.part_id ? (
                      <Grid item xs={2}>
                        <RemoveIconButton
                          tooltipTitle="remove part"
                          onClick={() => {
                            process.part_id = null;
                            process.part_number = null;
                            process.part_name = null;
                            process.partName = null;
                            process.partNumber = null;
                            setRender((prev:any) => !prev);
                          }}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={2}>
                        <AddIconButton
                          tooltipTitle="add part"
                          onClick={() => {
                            setIsOpenPopup(true);
                            setPartIdx(index);
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
                </td>
                <td style={{ minWidth: '250px' }}>
                  { process?.partNumber || process?.part_id?.part_number}
                </td>
                <td style={{ minWidth: '250px' }}>
                <TableInput
                    isView={isView}
                    name="total_qty"
                    type="number"
                    value={process?.total_qty}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev: any) => !prev);
                    }}
                  />
                </td>
                <td style={{ minWidth: '250px' }}>
                <TableInput
                    isView={true}
                    name="rej_qty"
                    type="number"
                    value={process?.rej_qty}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev: any) => !prev);
                    }}
                  />
                </td>
              
                <td
                  style={{
                    width: '300px',
                    minWidth: '300px',
                  }}
                >
                  <div
                    style={{
                      ...(!isView && { display: 'flex' }),
                      ...(!isView && { justifyContent: 'space-between' }),
                    }}
                  >
                    <div style={{ border: 'none' }}>
                      {process?.reasons_for_rej?.map((item: any) => {
                        return item?.reason ? (
                          <span>
                            {item?.reason} - {item?.rej_qty}
                            <br />
                            <hr />
                          </span>
                        ) : null;
                      })}
                    </div>
                    {/* changes */}
                    {!isView && (
                      <AddIconButton
                        tooltipTitle="add defects"
                        onClick={() => {
                          setIsOpenPopupRejections(true);
                          setRejectionsIndx(index);
                        }}
                      />
                    )}
                  </div>
                </td>
                
                <td style={{ minWidth: '250px' }}>
                  <TableInput
                    isView={true}
                    name="rej_per"
                    type="Number"
                    value={formatToTwoDecimalPlaces((process?.rej_qty *100)/process?.total_qty)}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev: any) => !prev);
                    }}
                  />
                </td>
                <td style={{ minWidth: '250px' }}>
                  <TableInput
                    isView={isView}
                    name="rate"
                    type="Number"
                    value={process?.rate}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev: any) => !prev);
                    }}
                  />
                </td>
                <td style={{ minWidth: '250px' }}>
                  <TableInput
                    isView={true}
                    name="val"
                    type="Number"
                    value={(process?.rate * process?.rej_qty)}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev: any) => !prev);
                    }}
                  />
                </td>
                
                <td style={{ minWidth: '250px' }}>
                  <TableInput
                    isView={isView}
                    name="remarks"
                    type="text"
                    value={process?.remarks}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev: any) => !prev);
                    }}
                  />
                </td>

                {!isView && (
                  <td style={{ minWidth: '100px', textAlign: 'center' }}>
                    <DeleteIconButton
                      tooltipTitle={'delete'}
                      onClick={() => handelDeleteRows(index,1)}
                    />
                  </td>
                )}
              </tr>
            </>
          ))}

{moduleData && x==2 &&
          moduleData?.employees?.map((process: any, index: number) => (
            <>
              <tr>
                <td colSpan={1} style={{ minWidth: '100px', textAlign: 'center' }}>
                  {index + 1}
                </td>
                <td colSpan={2} style={{ minWidth: '250px' }}>
                {isView?(process?.emp_id?.employee_name):(

                  <AutocompleteMuiCustom
                    id="employee_name"
                    label=""
                    option_name="employee_name"
                    
                    value={process?.emp_id || '' }
                    arrayofObj={emp}
                    onChange={(e,value:any) => {
                      // handleChangeModuleData(e, process);
                      process.emp_id=value;
                      setRender((prev: any) => !prev);
                    }}
                  />
                )}
                
                </td>
                <td colSpan={2} style={{ minWidth: '250px' }}>
                  {process?.emp_id?.department_id?.department_name}
                </td>
                <td  colSpan={2} style={{ minWidth: '250px' }}>
                  {process?.emp_id?.designation}
                </td>
              
                
                <td colSpan={1} style={{ minWidth: '250px' }}>
                  {isView?(
                    process?.attend
                  ):(

                    <CustomSelect
                     name="attend"
                     value={process?.attend || ''}
                     onChange={(e) => {
                       process.attend = e.target.value;
                       setRender((prev:any) => !prev);
                     }}
                     sx={{
                       textAlign: 'center',
                     }}
                   >
                     <MenuItem value="">NONE</MenuItem>
                     <MenuItem value="P">P</MenuItem>
                     <MenuItem value="A">A</MenuItem>
                   </CustomSelect>
                  )}
                </td>
                
                <td colSpan={2} style={{ minWidth: '250px' }}>
                  <TableInput
                    isView={isView}
                    name="remarks"
                    type="text"
                    value={process?.remarks}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev: any) => !prev);
                    }}
                  />
                </td>
                
               

                {!isView && (
                  <td colSpan={1} style={{ minWidth: '100px', textAlign: 'center' }}>
                    <DeleteIconButton
                      tooltipTitle={'delete'}
                      onClick={() => handelDeleteRows(index,2)}
                    />
                  </td>
                )}
              </tr>
            </>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
