import { GrUser, GrVmMaintenance } from 'react-icons/gr';
import { HiDocumentReport } from 'react-icons/hi';
import { TbFaceIdError, TbNotebook, TbReportAnalytics } from 'react-icons/tb';
import { VscFeedback } from 'react-icons/vsc';
import { FaDrumSteelpan, FaSuitcaseRolling } from 'react-icons/fa';
import { IoDocuments } from 'react-icons/io5';
import { GoPaperclip } from 'react-icons/go';

// import { GiRing } from 'react-icons/gi';
// import { SiMaterialdesignicons } from 'react-icons/si';
// import { GiUnbalanced } from 'react-icons/gi';
// import { GiTeePipe } from 'react-icons/gi';
import { TbReport } from 'react-icons/tb';
// import { GrServices } from 'react-icons/gr';
import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import { MdDashboard } from 'react-icons/md';
import { MdOutlinePersonPin } from 'react-icons/md';
import { FaCalendarAlt } from 'react-icons/fa';
import { FcInspection, FcProcess, FcRating } from 'react-icons/fc';
import { TbReportOff } from 'react-icons/tb';
import { SiCompilerexplorer } from 'react-icons/si';
import { TbFileReport } from 'react-icons/tb';
import { IoMdPaper } from 'react-icons/io';
import { MdRepartition } from 'react-icons/md';
import { AiOutlineMonitor } from 'react-icons/ai';
import { useAuth } from '../../services/auth/AuthProvider';


const RIDeparment = () => {
  const { state } = useAuth();
  const { user } = state;
  const tiles = [
    {
      sNo: 1,
      link: '/common/masterListOfVendor',
      isLink: true,
      icon: <MdOutlinePersonPin size="5rem" color="#064a29" />,
      p: 'Approved Vendor',
      span: 'List',
      isIcon: true,
    },
    {
      sNo: 2,
      link: '/common/riPart',
      isLink: true,
      icon: <FaDrumSteelpan size="5rem" color="#343a40" />,
      p: 'RI PART',
      span: 'LIST',
      isIcon: true,
    },
    // {
    //   sNo: 3,
    //   link: `/common/inspectionStandardList`,
    //   isLink: true,
    //   icon: <TbReport size="5rem" color="#c92a2a" />,
    //   p: 'INSPECTION QUALITY',
    //   span: 'STANDARD',
    //   isIcon: true,
    // },
    // {
    //   sNo: 4,
    //   link: `/common/inspectionReportList`,
    //   isLink: true,
    //   icon: <TbReportAnalytics size="5rem" color="#a12222" />,
    //   p: 'INCOMING INSPECTION',
    //   span: 'Report',
    //   isIcon: true,
    // },
    {
      sNo: 4,
      link: `/common/InspectionStandardNewList`,
      isLink: true,
      icon: <TbReport size="5rem" color="#a12222" />,
      p: 'Incoming Inspection',
      span: 'STANDARD',
      isIcon: true,
    },
    {
      sNo: 1,
      link: `/${user?.userType === 2 ? 'admin' : 'supervisor'}/inspectionReportNewRouteList`,
      isLink: true,
      icon: <FcInspection size="5rem" color="#a12222" />,
      p: 'Incoming Inspection',
      span: 'Report',
      isIcon: true,
    },
    {
      sNo: 1,
      link: `/admin/SupplierComplaintRegister`,
      isLink: true,
      clauseNo: 8,
      icon: <VscFeedback size="5rem" color="#e03131" />,
      p: 'SUPPLIER COMPLAINT',
      span: 'REGISTER',
      isIcon: true,
    },
    {
      sNo: 6,
      link: '/common/supplierAuditPlanTiles',
      isLink: true,
      clauseNo: 4,
      icon: <FaCalendarAlt size="5rem" color="#495057" />,
      p: 'Supplier Audit',
      span: 'Plan',
      isIcon: true,
    },
    {
      sNo: 21,
      link: '/common/supplierMonitoring/vendors',
      isLink: true,
      clauseNo: 4,
      icon: <AiOutlineMonitor size="5rem" color="#a12222" />,
      p: 'Supplier Monitoring',
      span: 'Report',
      isIcon: true,
    },
    // {
    //   sNo: 1,
    //   link: '/common/layoutInspectionReportList',
    //   isLink: true,
    //   icon: <FcInspection size="5rem" color="#a12222" />,
    //   p: 'LAYOUT INSPECTION',
    //   span: 'REPORT',
    //   isIcon: true,
    // },
    // {
    //   sNo: 1,
    //   link: '/common/inprocessRejectionChallanList',
    //   isLink: true,
    //   icon: <FcProcess size="5rem" color="#a12222" />,
    //   p: 'INPROCESS REJECTION',
    //   span: 'CHALLAN',
    //   isIcon: true,
    // },
    // {
    //   sNo: 6,
    //   link: `/common/samplingStandard`,
    //   isLink: true,
    //   icon: <BsFillDeviceSsdFill size="5rem" color="#a12222" />,
    //   p: 'SAMPLING',
    //   span: 'STANDARD',
    //   isIcon: true,
    // },
    // {
    //   sNo: 3,
    //   link: '/common/supplierplanlist',
    //   isLink: true,
    //   icon: <MdOutlineCalendarMonth size="5rem" color="#343a40" />,
    //   p: 'SUPPLIER',
    //   span: 'PLAN',
    //   isIcon: true,
    // },
    // {
    //   sNo: 2,
    //   link: '/common/supplierchecklist',
    //   isLink: true,
    //   icon: <BsReverseListColumnsReverse size="5rem" color="#087f5b" />,
    //   p: 'SUPPLIER',
    //   span: 'STATUS',
    //   isIcon: true,
    // },
    // {
    //   sNo: 4,
    //   link: `/common/vendorDocumentList`,
    //   isLink: true,
    //   icon: <IoDocuments size="5rem" color="#1864ab" />,
    //   p: 'SUPPLIER',
    //   span: 'DOCUMENTS',
    //   isIcon: true,
    // },

    //   {
    //     sNo: 4,
    //     link: `/common/vendorDocumentList`,
    //     isLink: true,
    //     icon: <TbReport size="5rem" color="#a12222" />,
    //     p: 'SUPPLIER',
    //     span: 'DOCUMENTS',
    //     isIcon: true,
    //   },

    //   {
    //     sNo: 7,
    //     link: `/common/riDocumentUpload/?documentType=iaiSSTPlan`,
    //     isLink: true,
    //     icon: <HiDocumentReport size="5rem" color="#003566" />,
    //     p: 'IAI SST',
    //     span: 'PLAN',
    //     isIcon: true,
    //   },
    //   {
    //     sNo: 8,
    //     link: `/common/riDocumentUpload/?documentType=iaiMtcPlanList`,
    //     isLink: true,
    //     icon: <HiDocumentReport size="5rem" color="#003566" />,
    //     p: 'IAI MTC',
    //     span: 'PLAN',
    //     isIcon: true,
    //   },
    //   {
    //     sNo: 9,
    //     link: `/common/riDocumentUpload/?documentType=riDocuments`,
    //     isLink: true,
    //     icon: <HiDocumentReport size="5rem" color="#003566" />,
    //     p: 'RI',
    //     span: 'DOCUMENTS',
    //     isIcon: true,
    //   },
    //   {
    //     sNo: 10,
    //     link: `/common/RawMaterialPlanTiles`,
    //     isLink: true,
    //     icon: <SiMaterialdesignicons size="4rem" color="#343a40" />,
    //     p: 'RAW MATERIAL',
    //     span: 'TEST PLAN (Child Parts)',
    //     isIcon: true,
    //   },
    //   {
    //     sNo: 10,
    //     link: `/common/RubberoringPartsTiles`,
    //     isLink: true,
    //     icon: <GiRing size="5rem" color="#343a40" />,
    //     p: 'RAW MATERIAL',
    //     span: "TEST PLAN (RUBBER & O'RING  PARTS)",
    //     isIcon: true,
    //   },
    //   {
    //     sNo: 10,
    //     link: `/common/SSTPlanTiles`,
    //     isLink: true,
    //     icon: <GrServices size="5rem" color="#343a40" />,
    //     p: 'SST',
    //     span: 'PLAN',
    //     isIcon: true,
    //   },
    //   {
    //     sNo: 10,
    //     link: `/common/TubesTiles`,
    //     isLink: true,
    //     icon: <GiTeePipe size="5rem" color="#343a40" />,
    //     p: 'Tube',
    //     span: 'PLAN',
    //     isIcon: true,
    //   },
    //   {
    //     sNo: 10,
    //     link: `/common/BOPPlanTiles`,
    //     isLink: true,
    //     icon: <GiUnbalanced size="5rem" color="#343a40" />,
    //     p: 'BOP',
    //     span: 'PLAN',
    //     isIcon: true,
    //   },
    // {
    //   sNo: 1,
    //   link: '/common/supplierQualityRatingList',
    //   isLink: true,
    //   icon: <FcRating size="5rem" color="#1864ab" />,
    //   p: 'SUPPLIER QUALITY',
    //   span: 'RATING',
    //   isIcon: true,
    // },
    {
      sNo: 2,
      link: '/common/defect_list?filter=createdAt',
      isLink: true,
      icon: <TbFaceIdError size="5rem" color="#a12222" />,
      p: 'Master List of',
      span: 'Supplier DEFECTS',
      isIcon: true,
    },
    // {
    //   sNo: 1,
    //   link: `/common/supplierDefectMonitoringSheetList`,
    //   isLink: true,
    //   icon: <GrUser size="5rem" color="#003566" />,
    //   p: 'SUPPLIER DEFECT',
    //   span: 'MONITORING SHEET',
    //   isIcon: true,
    // },
    // {
    //   sNo: 4,
    //   link: '/common/supplierDefectMonitoringSheetDashboard',
    //   isLink: true,
    //   icon: <MdDashboard size="5rem" color="#135089" />,
    //   p: 'SUPPLIER DEFECT MONITORING',
    //   span: 'SHEET DASHBOARD',
    //   isIcon: true,
    // },
    // {
    //   sNo: 5,
    //   link: '/common/createRedTrollyRejectionReport',
    //   isLink: true,
    //   icon: <FaSuitcaseRolling size="5rem" color="#064a29" />,
    //   p: 'RED TROLLY',
    //   span: 'REJECTION REPORT',
    //   isIcon: true,
    // },
    // {
    //   sNo: 5,
    //   link: '/common/createDailyLineRejectionReport',
    //   isLink: true,
    //   icon: <TbReportOff size="5rem" color="#a12222" />,
    //   p: 'Daily Line',
    //   span: 'Rejection Report',
    //   isIcon: true,
    // },
    // {
    //   sNo: 5,
    //   link: '/common/createReworkLotRejectionReport',
    //   isLink: true,
    //   icon: <TbFileReport size="5rem" color="#a12222" />,
    //   p: 'LOT REJECTION/REWORK/',
    //   span: 'SEGGREGATION REPORT',
    //   isIcon: true,
    // },
    // {
    //   sNo: 5,
    //   link: '/common/createIncomingCompileInspectionReport',
    //   isLink: true,
    //   icon: <SiCompilerexplorer size="5rem" color="#064a29" />,
    //   p: 'INCOMING COMPILE',
    //   span: 'INSPECTION REPORT',
    //   isIcon: true,
    // },
    // {
    //   sNo: 5,
    //   link: '/common/createRejectionChallanReport',
    //   isLink: true,
    //   icon: <IoMdPaper size="5rem" color="#064a29" />,
    //   p: 'REJECTION',
    //   span: 'CHALLAN REPORT',
    //   isIcon: true,
    // },
    // {
    //   sNo: 14,
    //   link: '/common/clipOutsertPlanTiles',
    //   isLink: true,
    //   icon: <GoPaperclip size="5rem" color="#343a40" />,
    //   p: 'Revalidation',
    //   span: 'Plan',
    //   isIcon: true,
    // },
    // {
    //   sNo: 14,
    //   link: '/common/problemticPartInspectionReport',
    //   isLink: true,
    //   icon: <MdRepartition size="5rem" color="#a12222" />,
    //   p: 'INITIAL SUPPLY/PROBLEMATIC',
    //   span: 'PART INSPECTION REPORT',
    //   isIcon: true,
    // },
  ];
  return (
    <>
      <ChildHeader text="RI DEPARTMENT" />
      <RenderTiles tilesObj={tiles} />
    </>
  );
};

export default RIDeparment;
