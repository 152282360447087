import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
import { IData } from '../../helpers/interface';
import { useState } from 'react';

interface IProps {
  moduleData: IData;
  isView: boolean;
  handelDeleteRows: any;
}

const TController = ({ moduleData, isView, handelDeleteRows }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
            handelDeleteRows,
            setRender,
          }}
        />
        <TFoot moduleData={moduleData} setRender={setRender} isView={isView} />
      </RenderTable>
    </Box>
  );
};

export default TController;
