import moment from 'moment';
import lodash from 'lodash';


export const initialState = {
  processes: [
    {
      part_name: "",
      part_no: '',
      defect: '',
      rej_qty: 0,
      reason_of_defect: '',
      corr_action: '',
      prev_action: '',
      resp: '',
      target_date: '',
      remarks: '',
      reasons_for_rej: Array.from({ length: 10 }, () =>
        lodash.cloneDeep({ reason: null,rej_qty:0})
      ),
    }
  ],
  employees: [
    {
      emp_name: "",
      dept: '',
      desg:'',
      attend:'',
      remarks: '',
     
    }
  ]
};
