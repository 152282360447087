import { Box, Paper, Tab, Tabs } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { PersitedDatePicker } from '../../../components/common';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import Summary from './components/dashboard/summary/Summary';
import Type from './components/dashboard/typeOfComplaint/Type';
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const SupplierDefectMonitoringSheetDashboardTabs = () => {
  const date = useQuery('date') || '';
  const [tabValue, setTabValue] = useState(0);
  const [tableData, setTableData] = useState<any[]>([]);
  const [render, setRender] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [vendorName, setVendorName] = useState({
    vendor_name: '',
    vendor_id_temp: {
      vender_name: '',
    },
  });
  const [data, setData] = useState({
    dashBoardMonthCounts: [],
    totalOfMonth: {},
  });
  const [type, setType] = useState([]);
  const [year, setYear] = useState(0);
  const [month, setMonth] = useState('');
  useEffect(() => {
    axios
      .get('/api/vendorRoute/vendor/forAutoComplete')
      .then((res) => {
        if (res.data) {
          setVendors(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  const fetchData = async () => {
    if (date) {
      if (tabValue == 0 && !vendorName.vendor_name) {
        const res = await axios
          .get(
            `/api/supplierDefectMonitoringSheetRoutes/dashboard/getByMonth?date=${date}`
          )
          .catch((err) => {
            console.log(err);
          });

        setData(res?.data);
        setYear(new Date(date).getFullYear());
      } else if (tabValue == 1 && vendorName.vendor_name) {
        const res = await axios
          .get(
            `/api/supplierDefectMonitoringSheetRoutes/dashboard/getByTypeOfComplaint?date=${date}&vendorName=${vendorName?.vendor_name}`
          )
          .catch((err) => {
            console.log(err);
          });

        setType(res?.data);
        setYear(new Date(date).getFullYear());
        setMonth(monthNames[new Date(date).getMonth()]);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [date, tabValue, vendorName.vendor_name]);
  console.log('kkk', vendorName);
  return (
    <Box>
      <ChildHeader text="SUPPLIER DEFECT MONITORING DASHBOARD"></ChildHeader>
      <Paper elevation={2}>
        <Tabs
          value={tabValue}
          onChange={(_, newValue) => setTabValue(+newValue)}
          indicatorColor="primary"
          scrollButtons="auto"
          textColor="secondary"
          variant="fullWidth"
        >
          <Tab label="SUPPLIERS DEFECTS COUNT YEARLY Wise" />
          <Tab label="SUPPLIER DEFECTS MONTHLY Wise" />
        </Tabs>
      </Paper>
      <Box sx={{ margin: 2 }}>
        <PersitedDatePicker
          label="Search By Year"
          views={tabValue == 1 ? ['month', 'year'] : ['year']}
        />
      </Box>
      {
        <section>
          {tabValue === 0 && (
            <Summary
              apiData={data}
              year={year}
              title1="SUPPLIERS DEFECTS COUNT YEARLY STATUS"
            />
          )}
          {tabValue === 1 && (
            <Type
              apiData={type}
              year={year}
              title1="SUPPLIER DEFECTS MONTHLY STATUS"
              vendors={vendors}
              vendorName={vendorName}
              setRender={setRender}
              month={month}
            />
          )}
        </section>
      }
    </Box>
  );
};

export default SupplierDefectMonitoringSheetDashboardTabs;
