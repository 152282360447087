import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import { TableCompHead } from '../../../../components/ui';
import { useQuery } from '../../../../hooks/UseQuery';
import { moduleConstant } from '../constant';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '100px',
  height: '50px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
  minWidth: '80px',
};

interface IProps {
  isView: boolean;
  defects: any;
}

const THead = ({ defects, isView }: IProps) => {
  const date = useQuery('date');
  const year = date && new Date(date).getFullYear();
  const tableHeaderObj = [
    [
      { text: 'Date', style: style1, rowspan: 2 },
      { text: 'Shift', style: style1, rowspan: 2 },
      { text: 'Part Name', style: style1, rowspan: 2 },
      { text: 'Part No.', style: style1, rowspan: 2 },
      { text: 'Problematic Qty.', style: style1, rowspan: 2 },
      defects.length && {
        text: 'Problem',
        style: style1,
        colspan: defects.length,
      },
      { text: 'Validation of Problem', style: style1, rowspan: 2 },
      { text: 'Return By', style: style1, rowspan: 2 },
      { text: 'Received BY', style: style1, rowspan: 2 },
      { text: 'Remarks', style: style1, rowspan: 2 },
      !isView && { text: 'ACTION', style: style1, rowspan: 2 },
    ],

    defects?.map((defect: any) => ({
      text: defect.defect_name,
      style: style1,
    })),
  ];
  return (
    <thead
      style={{
        position: 'sticky',
        width: '100%',
        top: '-.5rem',
        border: '1px solid black',
        zIndex: '1',
        backgroundColor: '#38d9a9',
        color: 'black',
      }}
    >
      <TableCompHead
        colSpan={9 + defects?.length + (!isView && 1)}
        rowSpan={3}
        labels={['F.NO.:', 'REV. NO.', 'Date']}
        values={['HO-F-QAS-54', '00', '14.08.2012']}
        heading={`${moduleConstant?.heading} - ${year}`}
      />
      <tr>
        <th align="left" colSpan={9 + defects?.length + (!isView && 1)}>
          Area: Production to Incoming Quality
        </th>
      </tr>
      <TableHeaderHtmlCustom tableHeaderObj={tableHeaderObj} />
    </thead>
  );
};

export default THead;
