

import React, { useEffect, useState } from 'react';
import CustomSelect from '../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import { TableInput } from '../../../../../components/common';
import { Table } from '../../../../../components/ui/renderTable/styled';
import axios from 'axios';

const RejectionTable = (props:any) => {
  const calculateTotalRejection = (reasonsForRej: any) => {
    return reasonsForRej.reduce((total: any, item: any) => {
      if (item.reason) {  // Check if reason is not null, not empty, and not undefined
        return Number(total) + Number(item.rej_qty);
      }
      return total;
    }, 0);
  };
  const { process, isView } = props;
  const [rejectionReasons, setRejectionReasons] = useState([]);
  const [x,setX]=useState(calculateTotalRejection(process.reasons_for_rej));
  const [reasonsForRej, setReasonsForRej] = useState(process.reasons_for_rej);

  useEffect(() => {
    axios
      .get('/api/productionDefectRoutes/defectList/defectForAutoComplete')
      .then((res) => {
        if (res?.data) {
          setRejectionReasons(res?.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

 
  const handleReasonChange = (index:any, value:any) => {
    const updatedReasons = [...reasonsForRej];
    updatedReasons[index].reason = value;
    if( updatedReasons[index].reason == '' || updatedReasons[index].reason == null ){
      process.rej_qty -= updatedReasons[index].rej_qty;
      updatedReasons[index].rej_qty=0;
    }
    if (updatedReasons[index].reason !== null && updatedReasons[index].reason !== '') {
      const newTotal = calculateTotalRejection(updatedReasons);
      process.rej_qty = newTotal;
    }


    setReasonsForRej(updatedReasons);
  };
  const handleQtyChange = (index:any, value:any) => {
    const updatedReasons = [...reasonsForRej];
    updatedReasons[index].rej_qty = value;
    if (updatedReasons[index].reason !== null  && updatedReasons[index].reason !== '') {
      const newTotal = calculateTotalRejection(updatedReasons);
      process.rej_qty = newTotal;
    }
    setReasonsForRej(updatedReasons);
  };

  return (
    <Table style={{ width: '80vh' }}>
      <thead>
        <tr>
          <th>S.No.</th>
          <th>Reasons</th>
          <th>Rejection Qty</th>
        </tr>
      </thead>
      <tbody>
        {reasonsForRej.map((item:any, index:any) => (
          <tr key={index}>
            <th>{index + 1}</th>
            <td style={{ minWidth: '300px', textAlign: 'center' }}>
              {isView ? (
                item.reason
              ) : (
                <CustomSelect
                  name="reason"
                  value={item.reason}
                  onChange={(e) => handleReasonChange(index, e.target.value)}
                >
                  <MenuItem value="">None</MenuItem>
                  {rejectionReasons.map((reason:any) => (
                    <MenuItem key={reason.defect_name} value={reason.defect_name}>
                      {reason.defect_name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              )}
            </td>
            <td style={{ minWidth: '100px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="rej_qty"
                type="Number"
                value={item.rej_qty}
                onChange={(e) => handleQtyChange(index, e.target.value)}
              />
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th colSpan={2}>Total Rejection</th>
          <th>{process.rej_qty}</th>
        </tr>
      </tfoot>
    </Table>
  );
};

export default RejectionTable;
