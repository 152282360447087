import { CgPlayListSearch } from 'react-icons/cg';

import { SiInfluxdb } from 'react-icons/si';
import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import { VscFeedback } from 'react-icons/vsc';
import { BsCalendar2CheckFill } from 'react-icons/bs';
import { BsCalendar3RangeFill } from 'react-icons/bs';

const tiles = [
  // {
  //   sNo: 1,
  //   link: '/common/qualityDepartmentPokaYokeCheckSheetList',
  //   isLink: true,
  //   icon: <CgPlayListSearch size="5rem" color="#003566" />,
  //   p: 'POKA YOKA ',
  //   span: 'CHECK SHEET',
  //   isIcon: true,
  // },
  // {
  //   sNo: 9,
  //   link: '/common/luxMatrixChecksheetList',
  //   isLink: true,
  //   icon: <SiInfluxdb size="5rem" color="indigo" />,
  //   p: 'LUX MATRIX',
  //   span: 'CHECKSHEET',
  //   isIcon: true,
  // },
  {
    sNo: 5,
    link: '/common/spcPlanNewTiles',
    isLink: true,
    clauseNo: 4,
    icon: <BsCalendar2CheckFill size="5rem" color="#a12222" />,
    p: 'SPC Audit',
    span: 'Plan',
    isIcon: true,
  },
  {
    sNo: 5,
    link: '/common/layoutPlanTiles',
    isLink: true,
    clauseNo: 4,
    icon: <BsCalendar3RangeFill size="5rem" color="#a12222" />,
    p: 'Layout',
    span: 'Plan',
    isIcon: true,
  },
  // {
  //   sNo: 1,
  //   link: `/common/SupplierComplaintRegister`,
  //   isLink: true,
  //   clauseNo: 8,
  //   icon: <VscFeedback size="5rem" color="#e03131" />,
  //   p: 'SUPPLIER COMPLAINT',
  //   span: 'REGISTER',
  //   isIcon: true,
  // },
];

const QualityDeparmentHome = () => {
  return (
    <>
      <ChildHeader text="QUALITY DEPARTMENT" />
      <RenderTiles
        height="100vh"
        justify="start"
        columns="0fr"
        tilesObj={tiles}
      />
    </>
  );
};

export default QualityDeparmentHome;
