import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CheckIconButton,
  EditIconButton,
} from '../../../../components/common/button/CustomIconButton';
import { useQuery } from '../../../../hooks/UseQuery';
import { actionTakenTickMarks } from '../helpers/data';

const ReportsButton = ({
  process,
  reportKey,
  onSubmit,
}: {
  process: any;
  reportKey: string;
  onSubmit: any;
}) => {
  const annual_month = useQuery('annual_date') || '';
  const navigate = useNavigate();
  const redirect = async (
    process: any,
    reportId: any,
    documentType: string,
  ) => {
    await onSubmit();
    let y = documentType == 'retro' ? 'create4mretro' : 'create4msetup';
    if (reportId) {
      if (process.part_id?._id) {
        navigate(
          `/common/${y}/${process.part_id?._id}/${process._id}/${
            reportId._id
          }?annual_date=${annual_month}&documentType=${documentType}&machine_name=${
            process.machine_id?.machine_name
          }&operation_name=${encodeURIComponent(
            process.operation_name,
          )}&summary_date=${process.date}`,
        );
        return;
      }
      navigate(
        `/common/${y}/${process.part_id}/${process._id}/${
          reportId._id
        }?annual_date=${annual_month}&documentType=${documentType}&machine_name=${
          process.machine_id?.machine_name
        }&operation_name=${encodeURIComponent(
          process.operation_name,
        )}&summary_date=${process.date}`,
      );
      return;
    }
    if (process.part_id?._id) {
      navigate(
        `/common/${y}/${process.part_id?._id}/${
          process._id
        }?annual_date=${annual_month}&documentType=${documentType}&machine_name=${
          process.machine_id?.machine_name
        }&operation_name=${encodeURIComponent(
          process.operation_name,
        )}&summary_date=${process.date}`,
      );
      return;
    }
    navigate(
      `/common/${y}/${process.part_id}/${
        process._id
      }?annual_date=${annual_month}&documentType=${documentType}&machine_name=${
        process.machine_id?.machine_name
      }&operation_name=${encodeURIComponent(
        process.operation_name,
      )}&summary_date=${process.date}`,
    );
  };

  if (process.reason && process.action_taken) {
    if (
      actionTakenTickMarks[`${process?.reason}${process?.action_taken}`]?.[
        reportKey
      ]
    ) {
      return process[`${reportKey}_id`] ? (
        <CheckIconButton
          tooltipTitle="edit"
          onClick={() => {
            redirect(process, process[`${reportKey}_id`], reportKey);
          }}
        />
      ) : (
        <EditIconButton
          tooltipTitle="edit"
          onClick={() => {
            redirect(process, process[`${reportKey}_id`], reportKey);
          }}
        />
      );
    }
  }
  if (process.reason) {
    if (actionTakenTickMarks[`${process?.reason}`]?.[reportKey]) {
      return process[`${reportKey}_id`] ? (
        <CheckIconButton
          tooltipTitle="edit"
          onClick={() => {
            redirect(process, process[`${reportKey}_id`], reportKey);
          }}
        />
      ) : (
        <EditIconButton
          tooltipTitle="edit"
          onClick={() => {
            redirect(process, process[`${reportKey}_id`], reportKey);
          }}
        />
      );
    }
  }
  return <React.Fragment></React.Fragment>;
};
export default ReportsButton;
