import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import { useQuery } from '../../../../../hooks/UseQuery';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const theadData = [
  'S.NO.',
  'OPERATION / STAGE',
  'ILLUMINANCE LEVEL (MIN.) IN LUX',
  'DATE & OBSERAVTION',
  'Delete',
];

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const date = useQuery('annual_date');
  return (
    <thead>
      <TableCompHead
        colSpan={10}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dated']}
        values={['HO-F-QAS-108', '02', '09.02.2023']}
        heading="LUX MATRIX  CHECK SHEET"
      />
      <tr>
        <th colSpan={10} align="left">
          Month : {date}
        </th>
      </tr>
      <tr>
        <th colSpan={10} align="left">
          Module Name :
          <TableInput
            isView={isView}
            name="module_name"
            type="text"
            value={moduleData?.module_name}
            onChange={(e) => {
              moduleData.module_name = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr style={{ background: '#38d9a9' }}>
        {theadData.map((heading, index) => (
          <th
            key={heading + index}
            colSpan={index == 3 ? 6 : 1}
            rowSpan={index <= 2 || index == 9 ? 2 : 1}
          >
            {heading}
          </th>
        ))}
      </tr>
      <tr>
        {moduleData?.date?.map((d: any, index: any) => {
          return (
            <th style={{ minWidth: '170px' }}>
              <DatePickerMui
                value={moment(moduleData?.date?.[index])}
                onChange={(date) => {
                  moduleData.date[index] = date;
                  setRender((prev) => !prev);
                }}
              />
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default THead;
