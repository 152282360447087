import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import { Grid, MenuItem } from '@mui/material';
import { DivStyled } from '../../../assets/styles/components/DivStyledLableInputs';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../../components/common/button/CustomIconButton';
import ModalCustom from '../../../components/common/Modal';
import PartTable from './PartTable';
import GridSelect from '../../../components/common/inputs/GridSelect';
import lodash from 'lodash';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [defectData, setDefectData] = useState<any>({
    defect_name: null,
    type_of_defect: null,
    revision_history: Array.from({ length: 5 }, () => ({
      ...lodash.cloneDeep({}),
    })),
  });
  const [jointData, setJointData] = useState<any>([]);
  const [render, setRender] = useState(false);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Mould' : 'Create Mould'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  const [isPartModal, setIsPartModal] = useState(false);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/productionMouldingRoutes/${id}`)
        .then((res) => setDefectData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);
  useEffect(() => {
    const partId =
      typeof defectData?.part_id === 'string' && defectData?.part_id !== null
        ? defectData?.part_id
        : defectData?.part_id?._id;

    if (partId) {
      axios
        .get(`/api/productionMouldingRoutes/jointsFromPart/${partId}`)
        .then((res) => setJointData(res?.data))
        .catch((err) => console.log(err));
    }
  }, [defectData?.part_id]);

  function handleDefectData(e: React.ChangeEvent<HTMLInputElement>) {
    setDefectData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    // if (!defectData?.joint_name) {
    //   alert('joint name is required');
    //   return;
    // }
    if (id) {
      await axios
        .put(`/api/productionMouldingRoutes/${id}`, defectData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/productionMouldingRoutes', defectData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'moulding_name'}
        name={'moulding_name'}
        html_for={'moulding_name'}
        label_name={'Moulding Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={defectData?.moulding_name || ''}
        onChange={handleDefectData}
      />

      <GridInputs
        id={'moulding_detail'}
        name={'moulding_detail'}
        html_for={'moulding_detail'}
        label_name={'Moulding Detail:'}
        input_type={'text'}
        focusType={'onblur'}
        value={defectData?.moulding_detail || ''}
        onChange={handleDefectData}
      />

      <GridInputs
        id={'moulding_id'}
        name={'moulding_id'}
        html_for={'moulding_id'}
        label_name={'Moulding Id:'}
        input_type={'text'}
        focusType={'onblur'}
        value={defectData?.moulding_id || ''}
        onChange={handleDefectData}
      />
      <DivStyled style={{ alignItems: 'center' }}>
        <label>{'Part Number'}</label>
        <div
          style={{
            borderLeft: '2px solid #ced4da',
            paddingLeft: '1rem',
            width: '100%',
          }}
        >
          <Grid container xs={12} direction="row" alignItems={'center'}>
            <Grid item xs={10}>
              <p
                style={{
                  fontSize: '1.6rem',
                }}
              >
                {typeof defectData?.part_id === 'object' &&
                defectData?.part_id !== null
                  ? defectData?.part_id?.part_number
                  : defectData?.partNumber || ''}
              </p>
            </Grid>

            {defectData.part_id ? (
              <Grid item xs={2}>
                <RemoveIconButton
                  tooltipTitle="remove part"
                  onClick={() => {
                    defectData.part_id = null;
                    defectData.partNumber = null;
                    defectData.partName = null;
                    setRender((prev) => !prev);
                  }}
                />
              </Grid>
            ) : (
              <Grid item xs={2}>
                <AddIconButton
                  tooltipTitle="add part"
                  onClick={() => {
                    setIsPartModal(true);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </DivStyled>
      <DivStyled style={{ alignItems: 'center' }}>
        <label>{'Part Name'}</label>
        <div
          style={{
            borderLeft: '2px solid #ced4da',
            paddingLeft: '1rem',
            width: '100%',
          }}
        >
          <Grid container xs={12} direction="row" alignItems={'center'}>
            <Grid item xs={10}>
              <p
                style={{
                  fontSize: '1.6rem',
                }}
              >
                {typeof defectData?.part_id === 'object' &&
                defectData?.part_id !== null
                  ? defectData?.part_id?.part_name
                  : defectData?.partName || ''}
              </p>
            </Grid>
          </Grid>
        </div>
      </DivStyled>
      <GridSelect
        name="joint_name"
        label_name="Joint:"
        html_for={'joint_name'}
        value={defectData?.joint_name || ''}
        onChange={(e) => {
          defectData.joint_name = e.target.value;
          setRender((prev) => !prev);
        }}
      >
        {jointData?.map((item: any) => (
          <MenuItem
            style={{ textTransform: 'uppercase' }}
            value={item?.joint_name}
          >
            {item?.joint_name?.replace('_', ' ')}
          </MenuItem>
        ))}
      </GridSelect>
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
      <ModalCustom
        openModal={isPartModal}
        closeModal={() => {
          setIsPartModal(false);
        }}
        title="PART"
      >
        <PartTable moduleData={defectData} />
      </ModalCustom>
    </>
  );
};

export default Create;
