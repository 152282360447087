export const checkListList = {
  childHeader: 'SUPPLIER AUDIT PLAN STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  getAllStatus: 'statusAudtiPlanStatusRoute/getAllSatusByYear',
  upadateListStatus: 'statusAudtiPlanStatusRoute/handleStatusUpdate',
  checklistUpload: 'statusAudtiPlanStatusRoute/handleUpload',
  checklistDeleteImage: 'statusAudtiPlanStatusRoute/handleDeleteImage',
  machineDataForAutoComplete: '/vendorRoute/vendor/forAutoComplete',
};

export const navigationConstant = {
  checklistRouter: '/common/supplierAuditPlanChecklist',
};
