export const listConstant = {
    childHeader: 'Annual Preventive Plan list',
    addButtonLable: 'New Plan',
    datePickerLabel: 'Search By Year',
    modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
    childHeader: 'ANNUAL PREVENTIVE Plan list',
    theadHeader: 'ANNUAL PREVENTIVE Plan',
    extracolSpan: 3,
};

export const apiConstant = {
    // list api url
    gettableListDataurl: 'annualPreventivePlanNewRoute/monthlylist',
    deleteListurl: 'annualPreventivePlanNewRoute',
    getListPlanByid: 'annualPreventivePlanNewRoute',
    createListPlanByid: 'annualPreventivePlanNewRoute',

    // -----plan--
    sync: 'annualPreventivePlanNewRoute/handleSync',
    getAllPlanById: 'annualPreventivePlanNewRoute/allplansbyid',
    upadateCreateAllPlan: 'annualPreventivePlanNewRoute/allplansCreateUpdate',
    maintenancePlanDeleteBulkById:
        'annualPreventivePlanNewRoute/maintenancePlanDeleteBulkById',
    forAutoComplete: 'machineRoutes/machine/ForAutocomplete',
};

export const settingPanelConstant = {
    // settingPanel setting for plan
    crudApi: 'annualPreventivePlanNewSettingRoute',
    docType: 'annualPreventivePlanNew',
};

export const tilesConstant = {
    childHeaderText: 'Annual Preventive Plan',
};

export const navigationConstant = {
    planRouter: '/common/annualPreventivePlanNewCreate',
};
