import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { calculateDueDate } from '../../utils';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
}

const TBody = ({ moduleData, isView, handelDeleteRows }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      {moduleData.histories &&
        moduleData.histories.map((item: any, index: number) => (
          <tr>
            <td>{index + 1}</td>
            <td>
              <TableInput
                isView={true}
                name="instrument_type"
                type="text"
                value={moduleData.instrument_type}
              />
            </td>

            <td>
              <TableInput
                isView={true}
                name="id_no"
                type="text"
                value={moduleData.id_no}
              />
            </td>

            <td>
              <TableInput
                isView={true}
                name="make"
                type="text"
                value={moduleData.make}
              />
            </td>

            <td>
              <TableInput
                isView={isView}
                name="sr_no"
                type="text"
                value={item.serial_no}
                onChange={(e) => {
                  item.serial_no = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>

            <td>
              <TableInput
                isView={true}
                name="range"
                type="text"
                value={moduleData.range}
              />
            </td>

            <td>
              <TableInput
                isView={true}
                name="least_count"
                type="text"
                value={moduleData.least_count}
              />
            </td>

            <td>
              {isView ? (
                formatDate(item.calibration_done_date)
              ) : (
                <DatePickerMui
                  value={
                    item.calibration_done_date
                      ? moment(item.calibration_done_date)
                      : null
                  }
                  onChange={(date) => {
                    item.calibration_done_date = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>

            <td>
              {calculateDueDate(
                item?.calibration_done_date,
                Number(moduleData?.frequency_in_year)
              )}
            </td>

            <td>
              {isView ? (
                formatDate(item.re_cal_date)
              ) : (
                <DatePickerMui
                  value={item.re_cal_date ? moment(item.re_cal_date) : null}
                  onChange={(date) => {
                    item.re_cal_date = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>

            <td>
              {isView ? (
                formatDate(item.repair_date)
              ) : (
                <DatePickerMui
                  value={item.repair_date ? moment(item.repair_date) : null}
                  onChange={(date) => {
                    item.repair_date = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>

            <td>
              {isView ? (
                formatDate(item.obsolete_date)
              ) : (
                <DatePickerMui
                  value={item.obsolete_date ? moment(item.obsolete_date) : null}
                  onChange={(date) => {
                    item.obsolete_date = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>

            {!isView && (
              <td style={{ minWidth: '100px', textAlign: 'center' }}>
                <DeleteIconButton
                  tooltipTitle={'delete'}
                  onClick={() => handelDeleteRows(index)}
                />
              </td>
            )}
          </tr>
        ))}
    </tbody>
  );
};

export default TBody;
