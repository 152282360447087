import { TableBody, Stack } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { SearchFilterContainer } from '../../assets/styles/layout/searchFilter.styled';
import {
  SearchBar,
  TableCustom,
  CustomPagination,
} from '../../components/common';
import { AddButton } from '../../components/common/button/CustomButtons';
import {
  EditIconButton,
  DeleteIconButton,
} from '../../components/common/button/CustomIconButton';
import FilterBy from '../../components/common/FilterBy';
import ModalCustom from '../../components/common/Modal';
import useConfirm from '../../components/common/useConfirm';
import ChildHeader from '../../components/ui/ChildHeader';
import {
  StyledTableRow,
  StyledTableCell,
} from '../../components/ui/module.styled';
import useDebounce from '../../hooks/useDebounce';
import { useQuery } from '../../hooks/UseQuery';
import Create from './Create';

export const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
  border: '1px solid #343a40',
};
const tableHeaderObj = [
  [
    { text: 'S.No.', style: { ...style1 } },
    { text: 'VISUAL DEFECTS', style: { ...style1 } },
    { text: 'Action', style: { ...style1 } },
  ],
];

const filterOptions = [{ value: 'createdAt', name: 'A-Z' }];

const List = () => {
  const [data, setData] = useState<any[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [id, setId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const search = useQuery('search') || '';
  const filter = useQuery('filter') || 'createdAt';
  const page = useQuery('page') || 1;

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?'
  );

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/masterlistVisualDefectsRoute/?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const handleDelete = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/masterlistVisualDefectsRoute/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, search, render, isModal, filter]);

  return (
    <>
      <ChildHeader text="VISUAL DEFECTS LIST">
        <AddButton onClick={() => setIsModal(true)} label="New Entry" />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search" />
        <FilterBy label="Sort By" options={filterOptions} />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <StyledTableRow index={index}>
                  <StyledTableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item?.visual_defects}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          setId(item?._id || '');
                          setIsModal(true);
                        }}
                      />
                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          handleDelete(item?._id || '');
                        }}
                      />
                    </Stack>
                  </StyledTableCell>
                </StyledTableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setId(null);
          setIsModal(false);
        }}
        title="CREATE VISUAL DEFECTS TYPES"
      >
        <Create id={id} setIsModal={setIsModal} setId={setId} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
