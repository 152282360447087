import { useState } from 'react';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import { useQuery } from '../../../../hooks/UseQuery';
import { moduleConstant } from '../constant';
interface IProps {
  moduleData?: any;
  setModuleData?: any;
  isView?: boolean;
}
const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '100px',
  height: '50px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
  minWidth: '80px',
};

const tableHeaderObj = [
  [
    { text: 'DATE', style: style1, rowspan: 2 },
    { text: 'ITEM DESCRIPTION', style: style1, rowspan: 2 },
    { text: 'RCIEVED QTY', style: style1, rowspan: 2 },
    { text: 'SECTION', style: style1, colspan: 3, rowspan: 1 },
    { text: 'MOULDING', style: style1, colspan: 3, rowspan: 1 },
    { text: 'SAMPLING PLAN', style: style1, rowspan: 2 },
    { text: 'STATUS', style: style1, rowspan: 2 },
    { text: 'OK QTY', style: style1, rowspan: 2 },
    { text: 'REJ.QTY', style: style1, rowspan: 2 },
    { text: 'REASON OF REJ.', style: style1, rowspan: 2 },
    { text: 'Q.A INCOMING/PROCESS ENGIN SIGN', style: style1, rowspan: 2 },
    { text: 'PRD ENGG./OPERATOR SIGN', style: style1, rowspan: 2 },
    { text: 'REMARKS', style: style1, colspan: 2, rowspan: 2 },
  ],
  [
    { text: '1', style: style1, colspan: 1 },
    { text: '2', style: style1, colspan: 1 },
    { text: '3', style: style1, colspan: 1 },
    { text: '1', style: style1, colspan: 1 },
    { text: '2', style: style1, colspan: 1 },
    { text: '3', style: style1, colspan: 1 },
  ],
];

const THead = ({ moduleData }: IProps) => {
  const date = useQuery('date');
  const year = date && new Date(date).getFullYear();

  return (
    <thead>
      <tr>
        <th style={{ background: '#495057', color: '#f8f9fa' }} colSpan={18}>
          {`${moduleConstant?.heading} - ${year}`}
        </th>
      </tr>
      <TableHeaderHtmlCustom tableHeaderObj={tableHeaderObj} />
    </thead>
  );
};

export default THead;
