import { useState } from 'react';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import { AddIconButton } from '../../../../../components/common/button/CustomIconButton';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';

interface IProps {
  moduleData: any;
  isView: boolean;
  setIsOpenPopupRejections: any;
  setRejectionsIndx: any;
  employees: any;
  operators: any;
  employeesProd: any;
  employeesQA: any;
  operatorsFinalInspector: any;
}

const getShiftHeader = (index: any) => {
  let shift = '';
  if (index == 0) shift = 'A';
  else if (index == 8) shift = 'B';
  else if (index == 16) shift = 'C';

  if (shift) {
    return (
      <tr>
        <th
          colSpan={16}
          align="left"
          style={{ background: '#38d9a9', height: '60px' }}
        >
          SHIFT {shift}
        </th>
      </tr>
    );
  }
};

const TBody = ({
  moduleData,
  isView,
  setIsOpenPopupRejections,
  setRejectionsIndx,
  employees,
  operators,
  employeesProd,
  employeesQA,
  operatorsFinalInspector,
}: IProps) => {
  const [render, setRender] = useState(false);
  const [totalScrap, setTotalScrap] = useState(0);

  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <>
              {getShiftHeader(index)}
              <tr>
                <td style={{ minWidth: '100px' }}>{index + 1}</td>
                <th
                  style={
                    index < 8
                      ? {
                          minWidth: '100px',

                          background: '#fff5f5',
                        }
                      : index < 16
                      ? {
                          minWidth: '100px',

                          background: '#fff9db',
                        }
                      : {
                          minWidth: '100px',

                          background: '#e9fac8',
                        }
                  }
                >
                  {process?.time}
                </th>
                <td style={{ minWidth: '100px' }}>
                  <TableInput
                    isView={isView}
                    name="check_qty"
                    type="number"
                    value={process.check_qty}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      process.ok_qty = process.check_qty - process.reject_qty;
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
                <td style={{ minWidth: '100px' }}>{process.ok_qty}</td>
                <td style={{ minWidth: '100px' }}>{process.reject_qty}</td>
                <td style={{ minWidth: '100px' }}>{process.rework_qty}</td>
                <td style={{ minWidth: '100px' }}>{process.scrap_qty}</td>
                <td style={{ minWidth: '100px' }}>
                  <TableInput
                    isView={isView}
                    name="scrap_aa_qty"
                    type="number"
                    value={process.scrap_aa_qty}
                    onChange={(e) => {
                      process.total_scrap_qty =
                        (+process.scrap_bb_qty || 0) +
                        (+e.target.value || 0) +
                        (+process.scrap_cc_qty || 0) +
                        (+process.scrap_dd_qty || 0);
                      if (+process.total_scrap_qty > +process.scrap_qty)
                        alert("Sorry you can't add more scrap Qty. !!!");
                      else {
                        handleChangeModuleData(e, process);
                        setRender((prev) => !prev);
                      }
                    }}
                  />
                </td>
                <td style={{ minWidth: '100px' }}>
                  <TableInput
                    isView={isView}
                    name="scrap_bb_qty"
                    type="number"
                    value={process.scrap_bb_qty}
                    onChange={(e) => {
                      process.total_scrap_qty =
                        (+process.scrap_aa_qty || 0) +
                        (+e.target.value || 0) +
                        (+process.scrap_cc_qty || 0) +
                        (+process.scrap_dd_qty || 0);

                      if (+process.total_scrap_qty > +process.scrap_qty)
                        alert("Sorry you can't add more scrap Qty. !!!");
                      else {
                        handleChangeModuleData(e, process);
                        setRender((prev) => !prev);
                      }
                    }}
                  />
                </td>
                <td style={{ minWidth: '100px' }}>
                  <TableInput
                    isView={isView}
                    name="scrap_cc_qty"
                    type="number"
                    value={process.scrap_cc_qty}
                    onChange={(e) => {
                      process.total_scrap_qty =
                        (+process.scrap_aa_qty || 0) +
                        (+e.target.value || 0) +
                        (+process.scrap_bb_qty || 0) +
                        (+process.scrap_dd_qty || 0);
                      if (+process.total_scrap_qty > +process.scrap_qty)
                        alert("Sorry you can't add more scrap Qty. !!!");
                      else {
                        handleChangeModuleData(e, process);
                        setRender((prev) => !prev);
                      }
                    }}
                  />
                </td>
                <td style={{ minWidth: '100px' }}>
                  <TableInput
                    isView={isView}
                    name="scrap_dd_qty"
                    type="number"
                    value={process.scrap_dd_qty}
                    onChange={(e) => {
                      process.total_scrap_qty =
                        (+process.scrap_aa_qty || 0) +
                        (+e.target.value || 0) +
                        (+process.scrap_cc_qty || 0) +
                        (+process.scrap_bb_qty || 0);
                      if (+process.total_scrap_qty > +process.scrap_qty)
                        alert("Sorry you can't add more scrap Qty. !!!");
                      else {
                        handleChangeModuleData(e, process);
                        setRender((prev) => !prev);
                      }
                    }}
                  />
                </td>
                <td
                  style={{
                    width: '300px',
                    minWidth: '300px',
                  }}
                >
                  <div
                    style={{
                      ...(!isView && { display: 'flex' }),
                      ...(!isView && { justifyContent: 'space-between' }),
                    }}
                  >
                    <div style={{ border: 'none' }}>
                      {process?.reasons_for_rej?.map((item: any) => {
                        return item?.reason ? (
                          <span>
                            {item?.reason} - {item?.rej_qty}
                            <br />
                            <hr />
                          </span>
                        ) : null;
                      })}
                    </div>
                    {!isView && (
                      <AddIconButton
                        tooltipTitle="add reasons and qty"
                        onClick={() => {
                          setIsOpenPopupRejections(true);
                          setRejectionsIndx(index);
                        }}
                      />
                    )}
                  </div>
                </td>

                <td style={{ minWidth: '200px' }}>
                  {isView ? (
                    process?.inspector_id?.operator_name
                  ) : (
                    <AutocompleteMuiCustom
                      id="operator_name"
                      label=""
                      option_name="operator_name"
                      value={process?.inspector_id || ''}
                      arrayofObj={operatorsFinalInspector}
                      onChange={(e: any, value: any) => {
                        process.inspector_id = value;
                        setRender((prev) => !prev);
                      }}
                    />
                  )}
                </td>
                <td style={{ minWidth: '200px' }}>
                  {isView ? (
                    process?.prod_shift_eng_id?.employee_name
                  ) : (
                    <AutocompleteMuiCustom
                      id="employee_name"
                      label=""
                      option_name="employee_name"
                      value={process?.prod_shift_eng_id || ''}
                      arrayofObj={employeesProd}
                      onChange={(e: any, value: any) => {
                        process.prod_shift_eng_id = value;

                        setRender((prev) => !prev);
                      }}
                    />
                  )}
                  {/* <TableInput
                    isView={isView}
                    name="prod_shift_engr"
                    type="text"
                    value={process.prod_shift_engr}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  /> */}
                </td>
                <td style={{ minWidth: '200px' }}>
                  {isView ? (
                    process?.prod_qa_eng_id?.employee_name
                  ) : (
                    <AutocompleteMuiCustom
                      id="employee_name"
                      label=""
                      option_name="employee_name"
                      value={process?.prod_qa_eng_id || ''}
                      arrayofObj={employeesQA}
                      onChange={(e: any, value: any) => {
                        process.prod_qa_eng_id = value;

                        setRender((prev) => !prev);
                      }}
                    />
                  )}
                </td>
                <td style={{ minWidth: '200px' }}>
                  {isView ? (
                    process?.cross_verified_id?.employee_name
                  ) : (
                    <AutocompleteMuiCustom
                      id="employee_name"
                      label=""
                      option_name="employee_name"
                      value={process?.cross_verified_id || ''}
                      arrayofObj={employees}
                      onChange={(e: any, value: any) => {
                        process.cross_verified_id = value;

                        setRender((prev) => !prev);
                      }}
                    />
                  )}
                </td>
              </tr>
            </>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
