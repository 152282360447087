import axios from 'axios';
import { useState, useEffect, useCallback } from 'react';
import { apiConstant } from './constant';
import useConfirm from '../../../../../components/common/useConfirm';
import { useQuery } from '../../../../../hooks/UseQuery';
import { isPlainObject } from '../../helpers/utils';
import useDebounce from '../../../../../hooks/useDebounce';

const useGetData = (id: string | undefined) => {
  const [moduleData, setModuleData] = useState<any[]>([]);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  // const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const [isLoading, setIsLoading] = useState(false);
  const [triggerRender, setTriggerRender] = useState(false);
  const [autoCompleteData, setAutoCompleteData] = useState([{}]);
  const [autoCompleteData1, setAutoCompleteData1] = useState([]);
  const plan_date = useQuery('plan_date');
  const [settings, setSettings] = useState({});
  const [selectionArray, setSelectionArray] = useState<any[]>([]);
  const [Sync, confirmSync] = useConfirm(
    'SYNC',
    'Are you sure you want to sync? Save any changes before sync!',
  );
  const [DeleteConfirm, handleDeleteConfirm] = useConfirm(
    'Delete!!!💀',
    'Are you sure you want to Delete? This action is not reversable!',
  );

  const page = useQuery('page') || 1;
  const search = useQuery('search');
  const filter = useQuery('filter');


  const handelSync = async () => {
    const ans = await confirmSync();
    if (!ans) return;
    setIsLoading(true);
    axios
      .get(`/api/${apiConstant.sync}/${id}/${plan_date}`)
      .then((res) => {
        window.alert('Sync Succesfully');
        setTriggerRender((prev) => !prev);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleBulkDeleteFeature = useCallback(
    (index: number, isSelected: boolean, rowId: string) => {
      setSelectionArray((currentSelectionArray: any) => {
        const newSelectionArray = [...currentSelectionArray];
        newSelectionArray[index] = isSelected ? rowId : null;
        return newSelectionArray;
      });
    },
    [],
  );
  const updateModuleData = useCallback(
    (rowIndex: number, key: string, newValues: any) => {
      setModuleData((prev) => {
        const updatedModuleData = [...prev];
        //specific row that needs updating
        const updatedRow = { ...updatedModuleData[rowIndex] };

        if (newValues === null || Array.isArray(newValues)) {
          updatedRow[key] = newValues;
        } else if (typeof newValues === 'object') {
          updatedRow[key] =
            isPlainObject(updatedRow[key]) && isPlainObject(newValues)
              ? { ...updatedRow[key], ...newValues }
              : newValues;
        } else {
          updatedRow[key] = newValues;
        }

        updatedModuleData[rowIndex] = updatedRow;
        return updatedModuleData;
      });
    },
    [],
  );

  const addRow = useCallback(() => {
    setModuleData((prev: any) => {
      setSelectionArray(new Array(prev.length + 1).fill(null));
      return [
        ...prev,
        {
          april: { p: '' },
          may: { p: '' },
          june: { p: '' },
          july: { p: '' },
          august: { p: '' },
          september: { p: '' },
          october: { p: '' },
          november: { p: '' },
          december: { p: '' },
          january: { p: '' },
          february: { p: '' },
          march: { p: '' },
          a1: null,
          a2: null,
          a3: null,
          a4: null,
          row_id: null,
          _tempId: `temp-id-${new Date().getTime()}`,
          date_range: plan_date,
          plan_id: id,
        },
      ];
    });
  }, []);

  const handleDelete = async () => {
    const ans = await handleDeleteConfirm();
    if (!ans) return;
    try {
      if (!selectionArray.length) return;
      const excludeId = selectionArray.filter((id) => id);
      const res = await axios.put(
        `/api/${apiConstant.maintenancePlanDeleteBulkById}`,
        excludeId,
      );
      if (res.status !== 200) return;
      setModuleData((prev) => {
        const filteredData = prev.filter((item) => {
          const id = item?._id || item?._tempId;
          if (!id) return;
          return !excludeId.includes(id);
        });
        setSelectionArray(new Array(filteredData.length).fill(null));
        return filteredData;
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    axios
      .get(`/api/${apiConstant.forAutoComplete}`)
      .then((res) => {
        if (res?.data) {
          const formattedData = res.data.map((id: any) => ({
            name: id?.id_no,
            value: id?.id_no,
          }));
          setAutoCompleteData1(formattedData);
          setAutoCompleteData(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (id) {
      axios
        .get(`/api/${apiConstant.getAllPlanById}/${id}`)
        .then((res) => {
          setModuleData(res?.data?.result);
          setSettings(res?.data?.settings);
          setSelectionArray(new Array(res?.data?.result?.length).fill(null));
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  }, [triggerRender]);


  const fetchData = async () => {
    axios
        .get(`/api/${apiConstant.getAllPlanById}/${id}?page=${page}&pageSize=15`)
        .then((res) => {
            const newData = res?.data?.result;
            console.log("newData is",newData);
            if (newData.length === 0 || newData.length < 15) {
              setHasMore(false);
              setIsLoading(false);
            }else{
              
              setModuleData([...moduleData, ...newData]);
              // setPage(page + 1);
            }

            setSettings(res?.data?.settings);
            setSelectionArray(new Array(res?.data?.result?.length).fill(null));
            setIsLoading(false);
        })
        .catch((err) => {
            setError(err);
            setIsLoading(false);
        });
};


const fetchData1= async () => {
  const res: any = await axios
  .get(`/api/${apiConstant.getAllPlanById}/${id}?page=${page}&filter=${filter}&search=${search}&perPage=${10}`)
    .catch((err) => {
      console.log(err);
    });
  console.log('data is ', res?.data?.result);
  setModuleData(res.data?.result);
  setSettings(res?.data?.settings);
  setSelectionArray(new Array(res?.data?.result?.length).fill(null));
  
  setPerPage(res.data?.perPage);
  setTotalPages(res.data?.pages);
};


useDebounce(fetchData1, 1000, [
  page,
  triggerRender,
  search,
  filter,

  
]);
//   useEffect(() => {
//     // setIsLoading(true);
//     if (id && hasMore) {
//         fetchData();
//     }
// }, [triggerRender,page]);

  return {
    moduleData,
    isLoading,
    error,
    handelSync,
    Sync,
    updateModuleData,
    handleBulkDeleteFeature,
    selectionArray,
    setSelectionArray,
    autoCompleteData,
    addRow,
    handleDelete,
    setTriggerRender,
    DeleteConfirm,
    settings,
    fetchData,
    hasMore,
    totalPages,
    perPage,
    page,
    autoCompleteData1,
  };
};

export default useGetData;
