export const checkListList = {
  childHeader: 'Calibration plan STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  getAllStatus: 'calibrationPlanNewStatusRoute/getAllSatusByYear',
  upadateListStatus: 'calibrationPlanNewStatusRoute/handleStatusUpdate',
  checklistUpload: 'calibrationPlanNewStatusRoute/handleUpload',
  checklistDeleteImage: 'calibrationPlanNewStatusRoute/handleDeleteImage',
  forAutoComplete:
    'masterlistinstrument/instrumentList/instumentForAutoComplete',
};

export const navigationConstant = {
  checklistRouter: '/common/calibrationPlanNewStatusCheckListCreate',
};
