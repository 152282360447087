import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
import { IData } from '../../helpers/interface';
import { useState } from 'react';

interface IProps {
  moduleData: IData;
  isView: boolean;
  handleSumbit: any;
  customerData: any;
  isOpenPopup: any;
  setIsOpenPopup: any;
  setShift: any;
}

const TController = ({
  moduleData,
  customerData,
  isView,
  setIsOpenPopup,
  setShift,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
            setIsOpenPopup,
            setShift,
          }}
        />
        <TBody
          {...{
            moduleData,
            customerData,
            isView,
            setRender,
          }}
        />
        <TFoot moduleData={moduleData} setRender={setRender} />
      </RenderTable>
    </Box>
  );
};

export default TController;
