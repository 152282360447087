import { Box, Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';

import { SearchBar, TableCustom } from '../../../../components/common';
import { AddButton } from '../../../../components/common/button/CustomButtons';
import ChildHeader from '../../../../components/ui/ChildHeader';
import { useQuery } from '../../../../hooks/UseQuery';
import { useAuth } from '../../../../services/auth/AuthProvider';
import {
    listConstant,
    apiConstant,
    navigationConstant,
} from './helpers/constant';
import React from 'react';
import {
    EditIconButton,
    DeleteIconButton,
} from '../../../../components/common/button/CustomIconButton';
import { useNavigate } from 'react-router-dom';
import useDebounce from '../../../../hooks/useDebounce';
import useConfirm from '../../../../components/common/useConfirm';
import FilterBy from '../../../../components/common/FilterBy';
import { SearchFilterContainer } from '../../../../assets/styles/layout/searchFilter.styled';
import formatDate from '../../../../components/common/formatDate';

const tableHeaderObj = [
    [
        { text: '#', rowspan: 1, colspan: 1 },
        { text: 'CHECK LIST NAME', rowspan: 1, colspan: 1 },
        { text: 'Action', rowspan: 1, colspan: 1 },
    ],
];

const filterOptions = [{ value: 'createdAt', name: 'A-Z' }];

const TableList = () => {
    const { state } = useAuth();
    const { user } = state;
    const [tableData, setTableData] = useState<any[]>([{}]);
    const [render, setRender] = useState(false);
    const [perPage, setPerPage] = useState<number>(10);
    const [totalPages, setTotalPages] = useState(0);
    const search = useQuery('search') || '';
    const filter = useQuery('filter') || 'createdAt';
    const page = useQuery('page') || 1;
    const navigate = useNavigate();
    const [DialogDelete, confirmDelete] = useConfirm(
        'DELETE CUSTOMER',
        'Are you sure you want to delete this?',
    );

    const fetchData = async () => {
        const res = await axios
            .get(
                `/api/${apiConstant.get}?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}`,
            )
            .catch((err) => {
                console.log(err);
            });
        setTableData(res?.data?.results);
        setPerPage(res?.data?.perPage);
        setTotalPages(res?.data?.pages);
    };

    const handleDelete = async (id: string) => {
        const ans = await confirmDelete();
        if (!ans) return;
        await axios
            .delete(`/api/${apiConstant.delete}/${id}`)
            .then((res) => {
                alert('Document deleted successfully');
                setRender((prev) => !prev);
            })
            .catch((err) => console.log(err));
    };

    useDebounce(fetchData, 500, [page, search, render, filter]);

    const handleNavigate = useCallback(
        (id: any) => {
            navigate(`${navigationConstant.createEditChecklist}/${id}`);
        },
        [navigate],
    );

    return (
        <Box>
            <ChildHeader text={`${listConstant.childHeader}`}>
                <AddButton
                    onClick={() => navigate(`${navigationConstant.createEditChecklist}`)}
                    label={`${listConstant.addButtonLable}`}
                />
            </ChildHeader>
            <SearchFilterContainer filter={true}>
                <SearchBar label="Search" />
                <FilterBy options={filterOptions} />
            </SearchFilterContainer>
            <TableCustom tableHeaderObj={tableHeaderObj}>
                <TableBody>
                    {tableData &&
                        tableData.map((item: any, index: number) => (
                            <React.Fragment key={item._id}>
                                <TableRow>
                                    <TableCell align="center">{index + 1}</TableCell>
                                    <TableCell align="center">
                                        {/* {formatDate(item?.date_of_training)} */}
                                        {item?.checkList_name}
                                        </TableCell>
                                    <TableCell>
                                        <Stack direction="row" sx={{ justifyContent: 'center' }}>
                                            <EditIconButton
                                                tooltipTitle="create plan"
                                                onClick={() => handleNavigate(item?._id)}
                                            />
                                            <DeleteIconButton
                                                tooltipTitle="DELETE"
                                                onClick={() => {
                                                    handleDelete(item?._id || '');
                                                }}
                                            />
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                </TableBody>
            </TableCustom>
            <DialogDelete isSubmitContent={false} />
        </Box>
    );
};

export default TableList;
