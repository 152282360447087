import React, { useState, useEffect } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { convToTime } from '../../../utils/helpers';
import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
import { SearchBar } from '../../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
} from '../../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import { IData } from './helpers/interfaces';
import ViewFile from './components/ViewFile';
import formatDate from '../../../components/common/formatDate';
import TableScroll from '../../../components/ui/renderTable/TableScroll';
import TextAreaInput from '../../../components/common/TextAreaInput';

const tableHeaderObj = [
  [
    {
      text: 'Plan',
      rowspan: 1,
      colspan: 12,
      style: {
        minWidth: '100px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Do',
      rowspan: 1,
      colspan: 6,
      style: {
        minWidth: '100px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Horizontal Deployment Planned',
      rowspan: 3,
      colspan: 1,
      style: {
        minWidth: '200px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Act',
      rowspan: 1,
      colspan: 8,
      style: {
        minWidth: '100px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Date Planned',
      rowspan: 3,
      colspan: 1,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Actual date of completion',
      rowspan: 3,
      colspan: 1,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Status',
      rowspan: 3,
      colspan: 1,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Technical know how',
      rowspan: 3,
      colspan: 1,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: '8D',
      rowspan: 3,
      colspan: 1,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Check',
      rowspan: 1,
      colspan: 6,
      style: {
        minWidth: '100px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Closed On',
      rowspan: 3,
      colspan: 1,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Monthly Verification of CAPA',
      rowspan: 3,
      colspan: 1,
      style: {
        minWidth: '200px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },

    {
      text: 'DEFECT PHOTO',
      rowspan: 3,
      colspan: 1,
      style: {
        minWidth: '100px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'SUPPORTING DOCUMENTS',
      rowspan: 3,
      colspan: 1,
      style: {
        minWidth: '100px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'ACTION',
      rowspan: 3,
      colspan: 1,
      style: {
        minWidth: '116px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
  ],
  [
    {
      text: 'S.NO.',
      rowspan: 2,
      colspan: 1,
      style: {
        minWidth: '100px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Reference no. (PTDB / Issue log)',
      rowspan: 2,
      colspan: 1,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Date of Reciept',
      rowspan: 2,
      colspan: 1,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Supplier',
      rowspan: 2,
      colspan: 1,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Part Name',
      rowspan: 2,
      colspan: 1,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'REJECT QTY',
      rowspan: 2,
      colspan: 1,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'REJECTION DETAIL',
      rowspan: 2,
      colspan: 1,
      style: {
        minWidth: '271px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Containment Action Report Submission',
      rowspan: 2,
      colspan: 1,
      style: {
        minWidth: '270px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Done On',
      rowspan: 2,
      colspan: 1,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Status',
      rowspan: 2,
      colspan: 1,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Quality Alert',
      rowspan: 2,
      colspan: 1,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'ROOT CAUSE FOR REJECTION',
      rowspan: 2,
      colspan: 1,
      style: {
        minWidth: '270px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Counter Measure',
      rowspan: 2,
      colspan: 1,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Capa report Planned for submission',
      rowspan: 2,
      colspan: 1,
      style: {
        minWidth: '200px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Actual date of submission',
      rowspan: 2,
      colspan: 1,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'CAPA Planned',
      rowspan: 2,
      colspan: 1,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Actual Completion date',
      rowspan: 2,
      colspan: 1,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Status',
      rowspan: 2,
      colspan: 1,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Quality documents Planned for change',
      rowspan: 1,
      colspan: 8,
      style: {
        minWidth: '200px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },

    {
      text: 'Effectiveness Monitoring',
      rowspan: 1,
      colspan: 6,
      style: {
        minWidth: '600px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
  ],
  [
    {
      text: 'SOP',
      rowspan: 1,
      colspan: 1,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'PFMEA',
      rowspan: 1,
      colspan: 1,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'CP',
      rowspan: 1,
      colspan: 1,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'PFD',
      rowspan: 1,
      colspan: 1,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Process Audit Checklist',
      rowspan: 1,
      colspan: 1,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'PDI Report',
      rowspan: 1,
      colspan: 1,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'System Procedure',
      rowspan: 1,
      colspan: 1,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Poka Yoka list',
      rowspan: 1,
      colspan: 1,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: '1',
      rowspan: 1,
      colspan: 1,
      style: {
        minWidth: '100px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: '2',
      rowspan: 1,
      colspan: 1,
      style: {
        minWidth: '100px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: '3',
      rowspan: 1,
      colspan: 1,
      style: {
        minWidth: '100px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: '4',
      rowspan: 1,
      colspan: 1,
      style: {
        minWidth: '100px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: '5',
      rowspan: 1,
      colspan: 1,
      style: {
        minWidth: '100px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: '6',
      rowspan: 1,
      colspan: 1,
      style: {
        minWidth: '100px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
  ],
];

const List = () => {
  const [data, setData] = useState<IData[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [complaintId, setComplaintId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const [id, setId] = useState<string | null>(null);
  const [isFileOpenPopup, setIsFileOpenPopup] = useState(false);
  const [contentType, setContentType] = useState('');
  const [filterCustomer, setFilterCustomer] = useState<any>([]);
  const search = useQuery('search') || '';
  const filter = useQuery('filter') || 'createdAt';
  const page = useQuery('page') || 1;
  const customer = useQuery('customer');
  const defect = useQuery('defects');
  const [all, setAll] = useState(false);

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?'
  );

  useEffect(() => {
    axios
      .get('/api/vendorRoute/vendor/forAutoComplete')
      .then((res) => {
        if (res?.data) {
          const formattedData = res.data.map((customer: any) => ({
            name: customer.vender_name,
            value: customer.vender_name,
          }));
          setFilterCustomer([...formattedData]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/supplierComplaintRoutes/?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}&customer=${customer}&defects=${defect}&all=${all}`
      )
      .catch((err) => {
        console.log(err);
      });

    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteCustomerConcern = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/supplierComplaintRoutes/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [
    page,
    search,
    render,
    isModal,
    filter,
    customer,
    defect,
    all,
  ]);
  return (
    <>
      <ChildHeader text="SUPPLIER COMPLAINT TRACKER">
        <AddButton
          onClick={() => {
            setAll((value) => !value);
          }}
          label={all ? 'Hide' : 'Save'}
        />
        <AddButton
          onClick={() => setIsModal(true)}
          label="NEW SUPPLIER COMPLAINT"
        />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search By Referenece No." />
        <FilterBy
          name="customer"
          label="Filter By Supplier"
          options={filterCustomer}
        />
      </SearchFilterContainer>

      <TableScroll>
        <TableCustom tableHeaderObj={tableHeaderObj}>
          <TableBody>
            {data &&
              data.map((item: IData, index: number) => (
                <React.Fragment key={item._id}>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {perPage * (+page - 1) + index + 1}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item.ref_no}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item.reciept_date && formatDate(item.reciept_date)}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {' '}
                      {item.vendor_id?.vender_name}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {' '}
                      {item.ri_part_id?.part_name}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item?.reject_qty}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      <TextAreaInput
                        value={item.rejection_detail}
                        style={{ minWidth: '250px', minHeight: '50px' }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      <TextAreaInput
                        value={item.containment_action_report}
                        style={{ minWidth: '250px', minHeight: '50px' }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item.done_on && formatDate(item.done_on)}
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item.status_one}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item.quality_alert}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      <TextAreaInput
                        value={item.root_cause_rejection}
                        style={{ minWidth: '250px', minHeight: '50px' }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item.counter_measure_one}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item.capa_report_planned &&
                        formatDate(item.capa_report_planned)}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item.actual_date_submission &&
                        formatDate(item.actual_date_submission)}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item.capa_planned && formatDate(item.capa_planned)}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item.actual_completion_date &&
                        formatDate(item.actual_completion_date)}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item.status_two}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item.horizontal_deployment_planned}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item.sop}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item.pfmea}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item.control_plan}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item.pfd}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item.process_audit_checklist}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item.pdi_report}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item.system_procedure}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item.poka_yoke_list}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item.date_planned && formatDate(item.date_planned)}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item.actual_date_completion &&
                        formatDate(item.actual_date_completion)}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item.status_three}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item.technical_know_how}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item.eight_d}
                    </TableCell>
                    {['jan', 'feb', 'mar', 'apr', 'may', 'jun'].map(
                      (month: any) => {
                        return (
                          <TableCell
                            style={{
                              ...(item[month] &&
                                item[month].toLowerCase() === 'repeated' && {
                                  background: '#e03131',
                                }),
                              ...(item[month] &&
                                item[month].toLowerCase() ===
                                  'not repeated' && {
                                  background: '#2f9e44',
                                }),
                              width: '50px',
                              border: '1px solid black',
                            }}
                            align="center"
                          ></TableCell>
                        );
                      }
                    )}
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item.closed_on && formatDate(item.closed_on)}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item.monthly_verf_capa}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {
                        <ViewIconButton
                          tooltipTitle="view"
                          onClick={() => {
                            setId(item._id || null);
                            setIsFileOpenPopup(true);
                            setContentType('image');
                          }}
                        />
                      }
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {
                        <ViewIconButton
                          tooltipTitle="view"
                          onClick={() => {
                            setId(item._id || null);
                            setIsFileOpenPopup(true);
                            setContentType('file');
                          }}
                        />
                      }
                    </TableCell>
                    <TableCell sx={{ border: '1px solid black' }}>
                      <Stack direction="row" sx={{ justifyContent: 'center' }}>
                        <EditIconButton
                          tooltipTitle="EDIT"
                          onClick={() => {
                            setComplaintId(item?._id || '');
                            setIsModal(true);
                          }}
                        />

                        <DeleteIconButton
                          tooltipTitle="DELETE"
                          onClick={() => {
                            deleteCustomerConcern(item?._id || '');
                          }}
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </TableCustom>
      </TableScroll>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setComplaintId(null);
          setIsModal(false);
        }}
        title="SUPPLIER COMPLAINT TRACKER"
      >
        <Create id={complaintId} setIsModal={setIsModal} />
      </ModalCustom>
      <ModalCustom
        title="FILE LIST"
        openModal={isFileOpenPopup}
        closeModal={() => {
          setId(null);
          setIsFileOpenPopup(false);
        }}
      >
        <ViewFile id={id} contentType={contentType} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
