export const tableHeaderObj = [
  [
    {
      text: 'S. No.',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black' },
    },
    {
      text: 'MATERIAL RECIEPT DATE',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black' },
    },

    {
      text: 'SUPPLIER NAME',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black' },
    },
    {
      text: 'ITEM DESCRIPTION',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black' },
    },
    {
      text: 'PROCESS',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black' },
    },
    {
      text: 'Invoice Detail',
      rowspan: 1,
      colspan: 3,
      style: { background: '#38d9a9', border: '1px solid black' },
    },
    {
      text: 'PROBLEM DESCRIPTION',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black' },
    },
    {
      text: 'ACTION TAKEN',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black' },
    },
    {
      text: 'ACTION TAKEN BY SUPPLIER',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black' },
    },
    {
      text: 'JAN',
      rowspan: 2,
      colspan: 1,
      style: {
        background: '#38d9a9',
        border: '1px solid black',
        minWidth: '70px',
      },
    },
    {
      text: 'FEB',
      rowspan: 2,
      colspan: 1,
      style: {
        background: '#38d9a9',
        border: '1px solid black',
        minWidth: '70px',
      },
    },
    {
      text: 'MAR',
      rowspan: 2,
      colspan: 1,
      style: {
        background: '#38d9a9',
        border: '1px solid black',
        minWidth: '70px',
      },
    },
    {
      text: 'APR',
      rowspan: 2,
      colspan: 1,
      style: {
        background: '#38d9a9',
        border: '1px solid black',
        minWidth: '70px',
      },
    },
    {
      text: 'MAY',
      rowspan: 2,
      colspan: 1,
      style: {
        background: '#38d9a9',
        border: '1px solid black',
        minWidth: '70px',
      },
    },
    {
      text: 'JUN',
      rowspan: 2,
      colspan: 1,
      style: {
        background: '#38d9a9',
        border: '1px solid black',
        minWidth: '70px',
      },
    },
    {
      text: 'JUL',
      rowspan: 2,
      colspan: 1,
      style: {
        background: '#38d9a9',
        border: '1px solid black',
        minWidth: '70px',
      },
    },
    {
      text: 'AUG',
      rowspan: 2,
      colspan: 1,
      style: {
        background: '#38d9a9',
        border: '1px solid black',
        minWidth: '70px',
      },
    },
    {
      text: 'SEP',
      rowspan: 2,
      colspan: 1,
      style: {
        background: '#38d9a9',
        border: '1px solid black',
        minWidth: '70px',
      },
    },
    {
      text: 'OCT',
      rowspan: 2,
      colspan: 1,
      style: {
        background: '#38d9a9',
        border: '1px solid black',
        minWidth: '70px',
      },
    },
    {
      text: 'NOV',
      rowspan: 2,
      colspan: 1,
      style: {
        background: '#38d9a9',
        border: '1px solid black',
        minWidth: '70px',
      },
    },
    {
      text: 'DEC',
      rowspan: 2,
      colspan: 1,
      style: {
        background: '#38d9a9',
        border: '1px solid black',
        minWidth: '70px',
      },
    },
    {
      text: 'REMARKS',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black' },
    },
    {
      text: 'SIGN.',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black' },
    },
    {
      text: 'Problem Photo',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black' },
    },
    {
      text: 'Supporiting Photo',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black' },
    },
    {
      text: '8D/CAPA',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black' },
    },
    {
      text: 'Action',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black' },
    },
  ],

  [
    {
      text: 'Invoice Date',
      rowspan: 2,
      colspan: 1,
      style: {
        background: '#38d9a9',
        minWidth: '150px',
        border: '1px solid black',
      },
    },
    {
      text: 'Invoice No.',
      rowspan: 2,
      colspan: 1,
      style: {
        background: '#38d9a9',
        minWidth: '150px',
        border: '1px solid black',
      },
    },
    {
      text: 'Invoice Qty.',
      rowspan: 2,
      colspan: 1,
      style: {
        background: '#38d9a9',
        minWidth: '100px',
        border: '1px solid black',
      },
    },
  ],
];
