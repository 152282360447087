import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import { useQuery } from '../../../../../hooks/UseQuery';
import React from 'react';
import TableHeaderHtmlCustom from '../../../../../components/common/customTable/TableHeaderHtmlCustom';
import { HeaderStandard, headerConstant } from '../helpers/constant';
import AutocompleteMuiCustomRevised from '../../../../../components/common/tableinputs/AutocompleteMuiCustomRevised';

interface IProps {
    moduleData: any;
    updateModuleData?: any;
}

const THead = ({ moduleData, updateModuleData }: IProps) => {
    const isView = useQuery('isView') === 'true' ? true : false;

    const handleAutocompleteChange = (newValue: any) => {
        updateModuleData('key-pair', null, 'machine_name', {
            machine_name: newValue?.machine_name,
        });
        updateModuleData('key-pair', null, 'machine_no', {
            machine_no: newValue?.machine_no,
        });
        updateModuleData('key-pair', null, 'machine_id', { machine_id: newValue });
        // updateModuleData('key-pair', null, 'machine_id', newValue);
    };
    const handleInputChange = (
        fieldName: string,
        value: string | number | null,
    ) => {
        updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
    };

    return (
        <thead>
            <TableCompHead
                colSpan={10}
                rowSpan={3}
                labels={['Doc. No:', 'Rev. No.:', 'Eff. Date:']}
                values={['F-HR-22', '00', '01.04.2023']}
                heading={`${headerConstant.tableCompHeadTitle}`}
            />
            <tr>
                <th colSpan={1}>Checksheet Name </th>
                <th colSpan={1}>
                    <TableInput
                        isView={isView}
                        style={{ height: '80px' }}
                        name="checkList_name"
                        type="text"
                        value={moduleData.checkList_name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleInputChange(e.target.name, e.target.value)
                        }
                    />
                </th>
                <th colSpan={1}>Operator Name </th>
                <th colSpan={1}>
                    <TableInput
                        isView={isView}
                        style={{ height: '80px' }}
                        name="operator_name"
                        type="text"
                        value={moduleData.operator_name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleInputChange(e.target.name, e.target.value)
                        }
                    />
                </th>
                <th colSpan={1}>Observer Name </th>
                <th colSpan={1}>
                    <TableInput
                        isView={isView}
                        style={{ height: '80px' }}
                        name="observer_name"
                        type="text"
                        value={moduleData.observer_name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleInputChange(e.target.name, e.target.value)
                        }
                    />
                </th>
                <th colSpan={1}>Date </th>
                <th colSpan={1}>
                <DatePickerMui
              value={moduleData.date_of_training ? moment(moduleData.date_of_training) : null}
              onChange={(date) => {
                moduleData.date_of_training = date;
                handleInputChange('date', date);
              }}
            />
                    {/* <TableInput
                        isView={isView}
                        style={{ height: '80px' }}
                        name="checkList_name"
                        type="text"
                        value={moduleData.checkList_name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleInputChange(e.target.name, e.target.value)
                        }
                    /> */}
                </th>
                <th colSpan={1}>Shift </th>
                <th colSpan={1}>
                    <TableInput
                        isView={isView}
                        style={{ height: '80px' }}
                        name="shift"
                        type="text"
                        value={moduleData.shift}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleInputChange(e.target.name, e.target.value)
                        }
                    />
                </th>
                {/* <th colSpan={13}></th> */}
            </tr>
            {/* <tr>
                <th colSpan={2} align="left">Observation Date :</th>
                <th colSpan={2} align="left">Plant Location: </th>
                <th colSpan={2} align="left">Line Name :</th>
                <th colSpan={2} align="left">Emp Code : </th>
                <th colSpan={2} align="left">Operator Name :</th>
                <th colSpan={2} align="left">Freq :</th>

                <th colSpan={2} align="left">Machine No. :</th>
                <th colSpan={2} align="left">
                    X = NOT OK √ = OK
                </th>
            </tr> */}
            <TableHeaderHtmlCustom tableHeaderObj={HeaderStandard} />
        </thead>
    );
};

export default React.memo(THead);
