import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import GridDatePicker from '../../../components/common/inputs/GridDatePicker';
import { Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import { Loader } from '../../../components/common/Loader';
import ModalCustom from '../../../components/common/Modal';
import PartTable from './components/PartTable';
import { Checkbox } from '@mui/material';
import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';
import { DivStyled } from '../../../assets/styles/components/DivStyledLableInputs';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../../components/common/button/CustomIconButton';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [data, setData] = useState<any>({
    exclude_report: false,
  });
  const [files, setFiles] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Supplier Defect' : 'Create Supplier Defect'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  const [defectData, setDefectData] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [riParts, setRiParts] = useState([]);
  const [isPartModal, setIsPartModal] = useState(false);
  const [render, setRender] = useState(false);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/supplierDefectMonitoringSheetRoutes/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);
  useEffect(() => {
    axios
      .get('/api/defectRoutes/defectList/defectForAutoComplete')
      .then((res) => {
        setDefectData(res.data);
      })
      .catch((err) => console.log(err));
    axios
      .get('/api/vendorRoute/vendor/forAutoComplete')
      .then((res) => {
        if (res.data) {
          setVendors(res.data);
        }
      })
      .catch((err) => console.log(err));
    axios
      .get('/api/riDepartment/riPart/riPartList/autoComplete')
      .then((res) => {
        if (res.data) {
          setRiParts(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  function handleCustomerData(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }
  const handleSumbit = async () => {
    if (!data.vendor_id) {
      alert('Please enter vendor name');
      return;
    }
    if (!data.defect_id) {
      alert('Please enter defect name');
      return;
    }
    const ans = await confirmSubmit();
    if (!ans) return;

    setIsLoading(true);
    const formData = new FormData();

    if (typeof data.vendor_id === 'object') {
      data.vendor_id = data.vendor_id._id;
    }
    if (typeof data.defect_id === 'object') {
      data.defect_id = data.defect_id._id;
    }
    if (typeof data.ri_part_id === 'object') {
      data.ri_part_id = data.ri_part_id._id;
    }
    for (const key in data) {
      if (data[key] || typeof data[key] == 'boolean') {
        if (key !== 'files' && key !== 'images') {
          formData.append(key, data[key]);
        }
      }
    }

    if (files) {
      for (const key of Object.keys(files)) {
        formData.append('files', files[key]);
      }
    }

    if (id) {
      await axios
        .put(`/api/supplierDefectMonitoringSheetRoutes/${id}`, formData)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/supplierDefectMonitoringSheetRoutes', formData)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <Loader loader={isLoading} />
      <GridDatePicker
        label_name="Issue Reported Date"
        html_for={'issue_reported_date'}
        value={data?.issue_reported_date || null}
        onChange={(date) => {
          data.issue_reported_date = date;
        }}
      />
      <DivStyled style={{ alignItems: 'center' }}>
        <label>{'Part Name'}</label>
        <div
          style={{
            borderLeft: '2px solid #ced4da',
            paddingLeft: '1rem',
            width: '100%',
          }}
        >
          <Grid container xs={12} direction="row" alignItems={'center'}>
            <Grid item xs={10}>
              <p
                style={{
                  fontSize: '1.6rem',
                }}
              >
                {typeof data?.ri_part_id === 'object' &&
                data?.ri_part_id !== null
                  ? data?.ri_part_id?.part_name
                  : data?.partName || ''}
              </p>
            </Grid>

            {data.ri_part_id ? (
              <Grid item xs={2}>
                <RemoveIconButton
                  tooltipTitle="remove part"
                  onClick={() => {
                    data.ri_part_id = null;
                    data.partName = null;
                    setRender((prev) => !prev);
                  }}
                />
              </Grid>
            ) : (
              <Grid item xs={2}>
                <AddIconButton
                  tooltipTitle="Add Ri Part"
                  onClick={() => {
                    setIsPartModal(true);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </DivStyled>
      <AutocompleteGridmui
        id="Vendor"
        label="Supplier"
        option_name="vender_name"
        arrayofObj={vendors}
        value={data.vendor_id_temp || data.vendor_id || ''}
        onChange={(e, value) => {
          data.vendor_id = value?._id;
          data.vendor_id_temp = value;
          setRender((prev) => !prev);
        }}
      />
      <GridInputs
        id={'invoice_no'}
        name={'invoice_no'}
        html_for={'invoice_no'}
        label_name={'Invoice No.:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.invoice_no || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'total_qty'}
        name={'total_qty'}
        html_for={'total_qty'}
        label_name={'Total qty.:'}
        input_type={'number'}
        focusType={'onblur'}
        value={data?.total_qty || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'reject_qty'}
        name={'reject_qty'}
        html_for={'reject_qty'}
        label_name={'Reject qty.:'}
        input_type={'number'}
        focusType={'onblur'}
        value={data?.reject_qty || ''}
        onChange={handleCustomerData}
      />
      <AutocompleteGridmui
        id={'defect_name'}
        label={'Reason of Rejection/ Defect Reported'}
        option_name={'defect_name'}
        arrayofObj={defectData}
        value={data.defect_name || data.defect_id || ''}
        onChange={(e, value) => {
          data.defect_id = value._id;
          data.defect_name = value;
          setRender((prev) => !prev);
        }}
      />
      <GridInputs
        id={'iqc_by'}
        name={'iqc_by'}
        html_for={'iqc_by'}
        label_name={'IQC by:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.iqc_by || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remarks:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.remarks || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'status'}
        name={'status'}
        html_for={'status'}
        label_name={'Status:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.status || ''}
        onChange={handleCustomerData}
      />
      <CustomUploadButton
        lable={'Attach Supporting Doucments'}
        multiple
        accept="application/pdf,application/doc,application/excel/,application/ppt"
        onChange={(e) => {
          if (files) {
            setFiles([...files, ...e.target.files]);
          } else {
            setFiles(e.target.files);
          }
        }}
      />{' '}
      <CustomUploadButton
        lable={'Defect photo'}
        multiple
        accept="image/png,image/jpeg,image/jpg"
        onChange={(e) => {
          if (files) {
            setFiles([...files, ...e.target.files]);
          } else {
            setFiles(e.target.files);
          }
        }}
      />{' '}
      <div>
        <Checkbox
          name="exclude_report"
          checked={data?.exclude_report}
          onChange={(e) => {
            setData((prev: any) => ({
              ...prev,
              [e.target.name]: e.target.checked,
            }));
          }}
        />

        <span
          style={{
            fontWeight: '500',
            fontSize: '20px',
          }}
        >
          Exclude from report
        </span>
      </div>
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
      <ModalCustom
        openModal={isPartModal}
        closeModal={() => {
          setIsPartModal(false);
        }}
        title="PART"
      >
        <PartTable moduleData={data} />
      </ModalCustom>
    </>
  );
};

export default Create;
