import { Box } from '@mui/system';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

import { IoMdAdd } from 'react-icons/io';

import {
  CustomButton,
  SubmitButton,
} from '../../../../components/common/button/CustomButtons';
import ChildHeader from '../../../../components/ui/ChildHeader';
import { useQuery } from '../../../../hooks/UseQuery';
import { useAuth } from '../../../../services/auth/AuthProvider';
import { GoSync } from 'react-icons/go';
import useConfirm from '../../../../components/common/useConfirm';
import { Loader } from '../../../../components/common/Loader';
import { CustomIconButton } from '../../../../components/common/button/CustomIconButton';
import { MdDeleteForever } from 'react-icons/md';
import { Checkbox, Stack, Tooltip, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import TController from './components/TController';
import useGetData from './helpers/useGetData';
import { apiConstant } from './helpers/constant';
import { CustomTypography } from './helpers/StyledElements.styled';
import FilterBy from '../../../../components/common/FilterBy';
import { CustomPagination, SearchBar } from '../../../../components/common';
import useDebounce from '../../../../hooks/useDebounce';

interface MyData {
  moduleData: any;
  Sync: any;
  isLoading: boolean;
  error?: string | null;
  handelSync: any;
  updateModuleData: any;
  selectionArray: any;
  setSelectionArray: any;
  addRow: any;
  autoCompleteData: any;
  handleDelete: any;
  setTriggerRender: any;
  DeleteConfirm: any;
  handleBulkDeleteFeature: any;
  settings: any;
  fetchData: any;
  hasMore: any;
  totalPages: any;
  perPage: any;
  page: any;
  autoCompleteData1: any;
}

const Create = () => {
  const { id } = useParams();
  const { state } = useAuth();
  const navigate = useNavigate();
  const isView = useQuery('isView') === 'true' ? true : false;
  const [DialogSave, confirmSave] = useConfirm(
    'SAVE',
    'Are you sure you want to Update this?'
  );
  const {
    moduleData,
    isLoading,
    handelSync,
    Sync,
    updateModuleData,
    selectionArray,
    handleBulkDeleteFeature,
    setSelectionArray,
    autoCompleteData,
    handleDelete,
    addRow,
    setTriggerRender,
    DeleteConfirm,
    settings,
    fetchData,
    hasMore,
    totalPages,
    perPage,
    page,
    autoCompleteData1,
  }: MyData = useGetData(id);

  const onSubmit = async (isSubmitted: boolean) => {
    const ans = await confirmSave();
    if (!ans) return;
    const hasDuplicateRowId = () => {
      const ids = new Set();
      for (const item of moduleData) {
        if (item?.row_id?._id) {
          if (ids.has(item.row_id._id)) {
            return true;
          }
          ids.add(item.row_id._id);
        }
      }
      return false;
    };

    if (hasDuplicateRowId()) {
      alert('Duplicate row found.!!!🛑');
      return;
    }

    const isAllRowFiled = moduleData.every(
      (item: any) =>
        (item?._id && item?.a1 !== null && item?.row_id === null) ||
        item.row_id !== null
    );

    if (!isAllRowFiled) {
      alert('All the rows must me filled first!!! ❌');
      return;
    }

    axios
      .post(`/api/${apiConstant.upadateCreateAllPlan}/${id}`, moduleData)
      .then((res) => {
        alert('updated successfully ');
        setTriggerRender((prev: any) => !prev);
      })
      .catch((err) => console.log(err));
  };

  const isSelected = useMemo(() => {
    // return array of null or id's
    return selectionArray?.filter((item: any) => item !== null);
  }, [selectionArray]);

  return (
    <Box>
      <Box>
        <Loader loader={isLoading} />
        <ChildHeader text="">
          {/* {!isView && (
            <Stack
              direction="row"
              justifyContent="center"
              justifyItems= 'center'
              alignItems="center"
              spacing={1}
              sx={{}}
            >
               <SearchBar
          label="Search Part"
          
        />
               <FilterBy
                sx={{ minWidth: '190px', maxWidth: '190px' }}
                name="customer"
                label="Filter By Customer"
                options={autoCompleteData1}
                variant={'outlined'}
              />
              {isSelected.length > 0 && (
                <>
                  <CustomTypography variant="caption">
                    {`${isSelected.length} ${
                      isSelected.length === 1
                        ? 'part on this page is selected'
                        : 'parts on this page are selected'
                    }`}
                  </CustomTypography>
                  <div>
                    <CustomIconButton
                      sx={{ p: 0 }}
                      tooltipTitle={'Delete'}
                      onClick={handleDelete}
                    >
                      <MdDeleteForever color="#f03e3e" size={'3.5rem'} />
                    </CustomIconButton>
                    <Tooltip title="SELECT ALL" arrow>
                      <Checkbox
                        aria-label="check"
                        sx={{ color: '#c92a2a' }}
                        onClick={(e: any) => {
                          let getIds = [];
                          if (e.target.checked) {
                            getIds = moduleData.map((item: any) => item._id);
                          } else {
                            getIds = moduleData.map((item: any) => null);
                          }
                          setSelectionArray(getIds);
                        }}
                      />
                    </Tooltip>
                  </div>
                </>
              )}
              {settings?.show_sync && (
                <CustomButton
                  sx={{
                    backgroundColor: '#228be6',
                    color: '#e7f5ff',
                  }}
                  icon={<GoSync />}
                  size="large"
                  onClick={() => handelSync()}
                >
                  Sync
                </CustomButton>
              )}
              {settings?.show_add_row && (
                <CustomButton
                  sx={{
                    backgroundColor: '#228be6',
                    color: '#e7f5ff',
                  }}
                  icon={<IoMdAdd />}
                  size="large"
                  onClick={addRow}
                >
                  ADD ROW
                </CustomButton>
              )}
              <SubmitButton label="Update" onClick={() => onSubmit(true)} />
            </Stack>
          )} */}
        </ChildHeader>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          sx={{
            width: '100%',
            flexWrap: 'wrap',
            marginTop: '15px',
            marginBottom: '5px',
          }}
        >
          <SearchBar
            label="Search "
            sx={{
              width: '54%',
            }}
          />

          <FilterBy
            sx={{
              minWidth: '200px',
              backgroundColor: '#ffffff', // Set the background to white
              color: '#000000', // Set the text color to black for contrast
            }}
            name="filter"
            label="Filter By Instrument No"
            options={autoCompleteData1}
            variant={'outlined'}
          />

          {isSelected.length > 0 && (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ flexGrow: 1, justifyContent: 'center' }}
            >
              <CustomTypography variant="caption">
                {`${isSelected.length} ${
                  isSelected.length === 1
                    ? 'part on this page is selected'
                    : 'parts on this page are selected'
                }`}
              </CustomTypography>
              <CustomIconButton
                sx={{ p: 0 }}
                tooltipTitle="Delete"
                onClick={handleDelete}
              >
                <MdDeleteForever color="#f03e3e" size="3.5rem" />
              </CustomIconButton>
              <Tooltip title="SELECT ALL" arrow>
                <Checkbox
                  aria-label="check"
                  sx={{ color: '#c92a2a' }}
                  onClick={(e: any) => {
                    let getIds = [];
                    if (e.target.checked) {
                      getIds = moduleData.map((item: any) => item._id);
                    } else {
                      getIds = moduleData.map((item: any) => null);
                    }
                    setSelectionArray(getIds);
                  }}
                />
              </Tooltip>
            </Stack>
          )}

          <Stack
            direction="row"
            spacing={1}
            sx={{
              flexGrow: 1,
              justifyContent: 'flex-end', // Align the buttons to the right
              alignItems: 'center',
            }}
          >
            {settings?.show_sync && (
              <CustomButton
                sx={{
                  backgroundColor: '#228be6',
                  color: '#e7f5ff',
                  padding: '15px',
                }}
                icon={<GoSync />}
                size="large"
                onClick={handelSync}
              >
                Sync
              </CustomButton>
            )}
            {settings?.show_add_row && (
              <CustomButton
                sx={{
                  backgroundColor: '#228be6',
                  color: '#e7f5ff',
                }}
                icon={<IoMdAdd />}
                size="large"
                onClick={addRow}
              >
                ADD ROW
              </CustomButton>
            )}
            <SubmitButton
              label="Update"
              sx={{ padding: '15px' }}
              onClick={() => onSubmit(true)}
            />
          </Stack>
        </Stack>
      </Box>
      {/* <InfiniteScroll
                dataLength={moduleData.length}
                next={fetchData}
                hasMore={hasMore}
                loader={
                    <Stack alignItems="center" fontSize={'2rem'}>
                        Loading...
                    </Stack>
                }
            > */}
      <TController
        {...{
          moduleData,
          selectionArray,
          updateModuleData,
          handleBulkDeleteFeature,
          autoCompleteData,
          settings,
          perPage,
          page,
        }}
      />
      <CustomPagination totalPage={totalPages} />
      {/* </InfiniteScroll> */}
      <Sync isSubmitContent={false} />
      <DialogSave isSubmitContent={false} />
      <DeleteConfirm isSubmitContent={false} />
    </Box>
  );
};

export default Create;
