import React from 'react';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';

export default function FloatingActionMenu() {
  const [open, setOpen] = React.useState(false);

  const actions = [
    { icon: '±', name: '±' },
    { icon: '~', name: '~' },
    { icon: 'Min', name: 'Min' },
    { icon: 'Max', name: 'Max' },
  ];

  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleAction = (actionName) => {
    console.log(`Action ${actionName} clicked`);
    setOpen(false); // Close the dial on action click
  };

  return (
    <SpeedDial
      ariaLabel="SpeedDial tooltip example"
      sx={{ position: 'absolute', bottom: 16, right: 16 }}
      icon={<SpeedDialIcon />}
      onClick={handleClick} // Toggle the dial on click
      open={open}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          sx={{
            fontSize: '2rem',
            backgroundColor: '#e9ecef',
            color: '#c92a2a',
            width: '100px',
            borderRadius: '5px',
          }}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={() => handleAction(action.name)}
        />
      ))}
    </SpeedDial>
  );
}
