const style1 = {
    backgroundColor: '#4F81BD',
    color: '#fff',
    minWidth: '150px',
};
const style2 = {
    backgroundColor: '#00B050',
    color: '#fff',
};

export const HeaderStandard = [
    [
        { text: 'S.No.', style: style1 },
        { text: 'Check Points', style: style1 },
        { text: 'Limits', style: style1 },
        { text: 'Frequency', style: style1 },
        { text: 'OBSERVATION', style: style1 },
        { text: 'Action', style: { ...style1, backgroundColor: '#c92a2a' } },
    ],
];

export const listConstant = {
    childHeader: 'Predictive Maintenance Checksheet list',
    addButtonLable: 'New Checksheet',
};

export const headerConstant = {
    tableCompHeadTitle: 'Predictive Maintenance CHECKSHEET ',
};

export const apiConstant = {
    get: 'predictiveMaintenancePlanMasterChecklistRoute',
    getById: 'predictiveMaintenancePlanMasterChecklistRoute',
    post: 'predictiveMaintenancePlanMasterChecklistRoute',
    update: 'predictiveMaintenancePlanMasterChecklistRoute',
    delete: 'predictiveMaintenancePlanMasterChecklistRoute',
    upload: 'predictiveMaintenancePlanMasterChecklistRoute/uploadFile',
    forAutoComplete: 'machineRoutes/machine/ForAutocomplete',
};

export const tilesConstant = {
    childHeaderText: 'Predictive Maintenance',
};

export const navigationConstant = {
    createEditChecklist: '/common/predictiveMaintenancePlanMasterChecklistCreate',
};
