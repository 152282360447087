import React from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { TableCustom } from '../../../../components/common';
import {
  // BlockIconButton,
  DeleteIconButton,
  EditIconButton,
  ViewIconButton,
} from '../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../components/common/formatDate';
import { useAuth } from '../../../../services/auth/AuthProvider';
import { IData } from '../../helpers/interface';

interface IProps {
  deleteDocument: (id: string) => void;
  tableData: IData[];
}

const Header = [
  [
    {
      text: '#',
      colspan: 1,
      rowspan: 1,
    },

    {
      text: 'CUSTOMER NAME',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'PART NAME & PART NUMBER',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'CREATED AT',
      colspan: 1,
      rowspan: 1,
    },

    {
      text: 'ACTION',
      colspan: 1,
      rowspan: 1,
    },
  ],
];

const SupervisorTable = ({ deleteDocument, tableData }: IProps) => {
  const navigate = useNavigate();
  const { state } = useAuth();
  const { user, IS } = state;
  return (
    <React.Fragment>
      <TableCustom tableHeaderObj={Header}>
        <TableBody>
          {tableData.map(
            (document: { [key: string]: any }, documentIndex: number) => (
              <TableRow key={documentIndex + 'superVisor'}>
                <TableCell align="center">{documentIndex + 1}</TableCell>
                <TableCell align="center">{document.customer_name}</TableCell>
                <TableCell align="center">{`${document.part_name} & ${document?.part_number}`}</TableCell>
                <TableCell align="center">
                  {formatDate(document.date)}
                </TableCell>

                <TableCell align="center">
                  <ViewIconButton
                    tooltipTitle="view"
                    onClick={() =>
                      navigate(
                        `/common/createEditLayoutInspectionReport/${document._id}?isView=true`,
                      )
                    }
                  />

                  {
                    <>
                      <EditIconButton
                        tooltipTitle="edit"
                        onClick={() =>
                          navigate(
                            `/common/createEditLayoutInspectionReport/${document._id}?isView=false`,
                          )
                        }
                      />
                      <DeleteIconButton
                        tooltipTitle="delete"
                        onClick={() => deleteDocument(document._id)}
                      />
                    </>
                  }
                </TableCell>
              </TableRow>
            ),
          )}
        </TableBody>
      </TableCustom>
    </React.Fragment>
  );
};

export default SupervisorTable;
