// import { Grid } from '@mui/material';
import TableCompHead from '../../../../../components/ui/TableCompHead';
// import { cpImg } from '../../../img/imgConstant';
import formatDate from '../../../../../components/common/formatDate';
import { getTotalColsSpan } from '../../../helpers/utils';
import TableHeaderHtmlCustom from '../../../../../components/common/customTable/TableHeaderHtmlCustom';
import { cpHeaderFormat1 } from '../../../constant';
import CheckBoxCustomMui from '../../common/ui/CheckBox';

interface IProps {
  moduleData: any;
}
const THead = ({ moduleData }: IProps) => {
  return (
    <thead>
      <TableCompHead
        colSpan={getTotalColsSpan(cpHeaderFormat1)}
        rowSpan={3}
        labels={['', 'REV. NO.', 'DATE']}
        values={['HO-F-DDP-23', '00', '01.09.2012']}
        heading="CONTROL PLAN"
      />
      <tr>
        <th
          data-val={
            moduleData.part_type === 'prototype' ? '✔ Proto Type' : 'Proto Type'
          }
          colSpan={2}
        >
          <CheckBoxCustomMui
            name="prototype"
            label="Proto Type"
            checked={moduleData.part_type === 'prototype'}
          />
        </th>
        <th
          data-val={
            moduleData.part_type === 'pre-Launch'
              ? '✔ Pre-Launch'
              : 'Pre-Launch'
          }
          colSpan={2}
        >
          <CheckBoxCustomMui
            name="change_type"
            label="Pre-Launch"
            checked={moduleData.part_type === 'pre-Launch'}
          />
        </th>
        <th
          data-val={
            moduleData.part_type === 'production'
              ? '✔ Production'
              : 'Production'
          }
          colSpan={2}
        >
          <CheckBoxCustomMui
            name="change_type"
            label="Production"
            checked={moduleData.part_type === 'production'}
          />
        </th>
        <th colSpan={2} align="left">
          Key Contact/Phone.: {moduleData?.part_id?.key_contact}{' '}
          {moduleData?.part_id?.phone &&
            moduleData?.part_id?.key_contact &&
            '/'}
          {moduleData?.part_id?.phone}
        </th>
        <th colSpan={3} align="left">
          Date ( Orig.){formatDate(moduleData?.part_id?.date_orig)}
        </th>
        <th colSpan={3} align="left">
          Date ( Rev.) {formatDate(moduleData?.revision_date)}
        </th>
      </tr>
      <tr>
        <th colSpan={5} align="left">
          Control Plan Number: {moduleData?.control_plan_no}
        </th>
        <th colSpan={4}></th>
        <th colSpan={5}></th>
      </tr>
      <tr>
        <th colSpan={5} align="left">
          Part Number/Largest Change Level. : {moduleData?.part_id?.part_number}{' '}
          {moduleData?.part_id?.part_number &&
            moduleData?.latest_change_level &&
            ' / '}
          {moduleData?.latest_change_level}
        </th>

        <th colSpan={4} align="left">
          Core Team : {moduleData?.part_id?.core_team}
        </th>
        <th colSpan={5} align="left">
          Customer Engineering Approval/Date(If Req'd)
          {formatDate(moduleData?.customer_engg_approval_date)}
        </th>
      </tr>
      <tr>
        <th colSpan={5} align="left">
          Part Name/ Description : {moduleData?.part_id?.part_name}
        </th>
        <th colSpan={4} align="left">
          Supplier/Plant Approval/Date :
          {formatDate(moduleData?.supplier_approval_date)}
        </th>
        <th colSpan={5} align="left">
          Customer Quality Approval/Date(If Req'd)
          {formatDate(moduleData?.customer_quality_approval_date)}
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Supplier / Plant : {moduleData?.part_id?.supplier_id?.supplier_name}
        </th>
        <th colSpan={3} align="left">
          Supplier Code :{moduleData?.part_id?.supplier_id?.supplier_code}
        </th>
        <th colSpan={4} align="left">
          Other Approval / Date ( If Reqd ):
          {formatDate(moduleData?.supplier_other_approval_date)}
        </th>
        <th colSpan={5} align="left">
          Other Approval/Date(If Req'd)
          {formatDate(moduleData?.customer_other_approval_date)}
        </th>
      </tr>

      <TableHeaderHtmlCustom
        tableHeaderObj={cpHeaderFormat1}
        // customStyle={{
        //   position: 'sticky',
        //   zIndex: '10',
        //   top: '0',
        // }}
      />
    </thead>
  );
};

export default THead;
