import { MdOutlineCalendarMonth, MdLocationOn } from 'react-icons/md';
import { SiInstructure } from 'react-icons/si';
import { BsReverseListColumnsReverse } from 'react-icons/bs';
import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import { TbRulerMeasure } from 'react-icons/tb';
import { GrVmMaintenance } from 'react-icons/gr';
import { GiThermometerScale } from 'react-icons/gi';

import { FaCalendarAlt } from 'react-icons/fa';
import { MdIntegrationInstructions } from 'react-icons/md';

const tiles = [
  {
    sNo: 1,
    link: '/common/instrumentMasterlist',
    isLink: true,
    icon: <GiThermometerScale size="5rem" color="#343a40" />,
    p: 'Master list of',
    span: 'Gauges',
    isIcon: true,
  },
  // {
  //   sNo: 2,
  //   link: '/common/calibrationPlanTiles',
  //   isLink: true,
  //   clauseNo: 4,
  //   icon: <FaCalendarAlt size="5rem" color="#495057" />,
  //   p: 'Calibration',
  //   span: 'Plan',
  //   isIcon: true,
  // },
  {
    sNo: 2,
    link: '/common/calibrationPlanNewTiles',
    isLink: true,
    clauseNo: 4,
    icon: <FaCalendarAlt size="5rem" color="#495057" />,
    p: 'Calibration',
    span: 'Plan',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/masterListLocationList',
    isLink: true,
    icon: <MdLocationOn size="5rem" color="#343a40" />,
    p: 'MANAGE',
    span: 'LOCATION',
    isIcon: true,
  },

  // {
  //   sNo: 1,
  //   link: '/common/instrumentVerificationSheet',
  //   isLink: true,
  //   icon: <MdIntegrationInstructions size="5rem" color="#343a40" />,
  //   p: 'Varification Check Sheet',
  //   span: 'Instrument',
  //   isIcon: true,
  // },

  // {
  //   sNo: 1,
  //   link: '/common/tilesCallibrationplan',
  //   isLink: true,
  //   icon: <MdOutlineCalendarMonth size="5rem" color="#343a40" />,
  //   p: 'CALLIBRATION',
  //   span: 'PLAN',
  //   isIcon: true,
  // },
  // {
  //   sNo: 1,
  //   link: '/common/tilesMsaplan',
  //   isLink: true,
  //   icon: <MdOutlineCalendarMonth size="5rem" color="#343a40" />,
  //   p: 'MSA',
  //   span: 'PLAN',
  //   isIcon: true,
  // },

  // {
  //   sNo: 2,
  //   link: '/common/instrumentMasterlist',
  //   isLink: true,
  //   icon: <SiInstructure size="5rem" color="#343a40" />,
  //   p: 'Master list of',
  //   span: 'Instrument',
  //   isIcon: true,
  // },
  // {
  //   sNo: 2,
  //   link: '/common/MSAList',
  //   isLink: true,
  //   icon: <TbRulerMeasure size="5rem" color="#343a40" />,
  //   p: 'MSA',
  //   span: '',
  //   isIcon: true,
  // },
];

const Clause = () => {
  return (
    <>
      <ChildHeader text="ADEQUATE TESTING FACILITY" />
      <RenderTiles
        tilesObj={tiles}
        height="100vh"
        justify="start"
        columns="0"
      />
    </>
  );
};

export default Clause;
