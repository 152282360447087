import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
  setRender: any;
}
const TBody = ({ moduleData, isView, handelDeleteRows, setRender }: IProps) => {
  return (
    <>
      <tbody>
        {moduleData &&
          moduleData?.processes?.map((process: any, index: number) => (
            <>
              <tr>
                <td style={{ minWidth: '100px', textAlign: 'center' }}>
                  {index + 1}
                </td>
                <td style={{ minWidth: '250px' }}>
                  {process?.part_id?.part_name}
                </td>
                <td style={{ minWidth: '250px' }}>
                  {process?.part_id?.model_id?.model_name}
                </td>
                <td style={{ minWidth: '200px' }}>
                  {process?.total_target_qty}
                </td>
                <td style={{ minWidth: '100px' }}>{process?.total_ok_qty}</td>
                <td style={{ minWidth: '100px' }}>
                  {process?.total_reason_qty}
                </td>
                <td style={{ minWidth: '100px' }}>{process?.prod_perc}%</td>
                <td style={{ minWidth: '100px' }}>{process?.rej_perc}%</td>
                <td style={{ minWidth: '250px' }}>
                  <TableInput
                    isView={isView}
                    name="remarks"
                    type="text"
                    value={process.remarks}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev: any) => !prev);
                    }}
                  />
                </td>

                {!isView && (
                  <td style={{ minWidth: '100px', textAlign: 'center' }}>
                    <DeleteIconButton
                      tooltipTitle={'delete'}
                      onClick={() => handelDeleteRows(index)}
                    />
                  </td>
                )}
              </tr>
            </>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
