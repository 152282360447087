import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import { DeleteIconButton } from '../../../../../../components/common/button/CustomIconButton';
import { CustomButton } from '../../../../../../components/common/button/CustomButtons';
interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
}
const TBody1 = ({ moduleData, isView, handelDeleteRows }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      <tr style={{ background: '#fff2cc' }}>
        <th colSpan={5} align="left">
          Authority
        </th>
        <th>
          {!isView && (
            <CustomButton
              sx={{
                backgroundColor: '#1c7ed6',
              }}
              onClick={async () => {
                // const ans = await confirmAddRow();
                // if (!ans) return;
                moduleData.process_2.push({});
                setRender((prev) => !prev);
              }}
            >
              ADD ROWS
            </CustomButton>
          )}
        </th>
      </tr>
      {moduleData?.process_2 &&
        moduleData.process_2.map((item: any, index: number) => (
          <tr key={index + 'specs'}>
            <td>{index + 1}</td>
            <td colSpan={4}>
              <TableInput
                isView={isView}
                name="responsibility"
                type="text"
                value={item.responsibility}
                onChange={(e) => {
                  item.responsibility = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '100px', textAlign: 'center' }}>
              <DeleteIconButton
                tooltipTitle={'delete'}
                onClick={() => handelDeleteRows('process_2', index)}
              />
            </td>
          </tr>
        ))}
    </tbody>
  );
};

export default TBody1;
