// import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { TableCompHead } from '../../../../../components/ui';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={13}
        rowSpan={3}
        labels={["Doc No.","Rev No.","Rev. Date"]}
        values={["F-13-04","04","01.07.2020"]}
        heading="Tool History Card"
      />
      <tr>
      <th colSpan={6} align="left">
      Part Name:- {moduleData?.part_id?.part_name}
      </th>
      <th colSpan={4} align="left">
      Mould Name:- {moduleData?.moulding_name}
      </th>
      <th colSpan={3} align="left">
      Tool Expected life:
          <TableInput
            name="tool_expected_life"
            type="number"
            value={moduleData.tool_expected_life}
            onChange={(e) => {
              moduleData.tool_expected_life = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
      </th>
      </tr>
      <tr>
      <th colSpan={6} align="left">
      Part No:- {moduleData?.part_id?.part_number}
      </th>
      <th colSpan={4} align="left">
      Mould Id:- {moduleData?.moulding_id}
      </th>
      <th colSpan={3} align="left">
      Previous Total Shots :
          <TableInput
            name="prev_total_shots"
            type="number"
            value={moduleData.prev_total_shots}
            onChange={(e) => {
              moduleData.prev_total_shots = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
      </th>
      </tr>
      <tr>
      <th colSpan={6} align="left">
      PREV. MAINT. FREQ. :
          <TableInput
            name="prev_maint_freq"
            type="number"
            value={moduleData.prev_maint_freq}
            onChange={(e) => {
              moduleData.prev_maint_freq = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
      </th>
      <th colSpan={2} align="left">
      Mould Detail:- {moduleData?.moulding_detail}
      </th>
      <th colSpan={2} align="left">
          C.No. :-
          <TableInput
            name="c_no"
            type="text"
            value={moduleData.c_no}
            onChange={(e) => {
              moduleData.c_no = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={3} align="left">
        Previous Balance tool life :
          <TableInput
            name="prev_balance_tool_life"
            type="number"
            value={moduleData.prev_balance_tool_life}
            onChange={(e) => {
              moduleData.prev_balance_tool_life = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
      </th>
      </tr>
      <tr style={{ textTransform: 'uppercase' }}>
        {[
          'Loading Date',
          'UnLoading Date',
          'M/c No.',
          'Total Number Of Shots',
          'Commulative Shots',
          'Balance Life Of Tool',
          'Reason for Unloading(Planing completed/Tool B/D, M/c /B/D, Tool P.M.)',
          'Maint. Start Date',
          'Maint. Start time',
          'Maint. Completion Date',
          'Maint. Completion Time',
          'Total Time',
          'Action Taken',
        ].map((head) => (
          <th
            key={head}
            style={{
              textTransform: 'uppercase',
              minWidth: `${head === 'Reason (If not ok)' && '300px'}`,
            }}
          >
            {head}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
