import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import GridDatePicker from '../../../components/common/inputs/GridDatePicker';
import { apiConstant } from './helpers/constant';
import moment from 'moment';
import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';
import SelectGridMui from '../../../components/common/selectscustom/SelectGridMui';
import { MenuItem } from '@mui/material';

const ModalInput = ({
  id,
  setIsModal,
  setModuleId,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setModuleId: any;
}) => {
  const [department, setDepartment] = useState([{}]);
  const [moduleData, setMoudleData] = useState<any>({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [render, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Customer' : 'Create Customer'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/${apiConstant.getById}/${id}`)
        .then((res) => setMoudleData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  useEffect(() => {
    axios
      .get(`/api/${apiConstant.departmentAutoComplete}`)
      .then((res) => setDepartment(res.data.results))
      .catch((err) => console.log(err));
  }, []);

  function handleData(e: React.ChangeEvent<HTMLInputElement>) {
    setMoudleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    setIsSubmit(true);
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/${apiConstant.getById}/${id}`, moduleData)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
          setModuleId(null);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post(`/api/${apiConstant.post}`, moduleData)
        .then((res) => {
          setIsSubmit(false);
          setModuleId(null);
          setIsModal(false);
        })
        .catch((err) => {
          if (err.response.data.error.message.includes('E11000')) {
            alert(
              'A Topic for this department has already been created. Each department can only have one Topic to ensure data uniqueness and integrity. Please review the existing Topics.'
            );
          }
          setIsSubmit(false);
          console.error(err);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'topic_name'}
        name={'topic_name'}
        html_for={'topic_name'}
        label_name={'TOPIC NAME'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.topic_name || ''}
        onChange={handleData}
      />
      <AutocompleteGridmui
        label={'DEPARTMENT'}
        id="department_name"
        option_name="department_name"
        key={'department_name'}
        arrayofObj={department}
        value={moduleData?.department_id || ''}
        onChange={(_, newValue: any) =>
          setMoudleData((prev: any) => ({ ...prev, department_id: newValue }))
        }
        last_child={2}
        // locked={moduleData?._id}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default ModalInput;
