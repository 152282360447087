import TBodyRow from './TBodyRow';

interface IProps {
  moduleData: any;
  handleDelete: any;
  isView: boolean;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setPartIdx: React.Dispatch<React.SetStateAction<number>>;
}

const TBody = ({
  moduleData,
  isView,
  handleDelete,
  setIsOpenPopup,
  setPartIdx,
}: IProps) => {
  return (
    <tbody>
      {moduleData?.list?.map((item: any, index: number) => (
        <TBodyRow
          {...{
            item,
            index,
            isView,
            handleDelete,
            setIsOpenPopup,
            setPartIdx,
          }}
        />
      ))}
    </tbody>
  );
};

export default TBody;
