import moment from 'moment';
import React from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { useQuery } from '../../../../../hooks/UseQuery';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';

export const TableRowComponent = React.memo(
    ({ key, item, index, allmonth, updateModuleData }: any) => {
        const isView = useQuery('isView') === 'true' ? true : false;
        const rowId = item?._id || item?._tempId;

        const handleInputChange = (
            fieldName: string,
            value: string | number | null,
        ) => {
            updateModuleData('row', index, fieldName, { [fieldName]: value });
        };

        return (
            <React.Fragment key={key}>
                <tr>
                    <td>{index + 1}</td>
                    <td>
                        <TableInput
                            isView={isView}
                            style={{ height: '80px', minWidth: "600px" }}
                            name="check_points"
                            type="text"
                            value={item.check_points}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td>
                        <TableInput
                            isView={isView}
                            style={{ height: '80px', minWidth: "100px"  }}
                            name="veri_method"
                            type="text"
                            value={item.veri_method}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td>
                        <TableInput
                            isView={isView}
                            style={{ height: '80px' }}
                            name="judgement"
                            type="text"
                            value={item.judgement}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td>
                        <TableInput
                            isView={isView}
                            style={{ height: '80px' }}
                            name="obs"
                            type="text"
                            value={item.obs}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td>
                        <TableInput
                            isView={isView}
                            style={{ height: '80px' }}
                            name="action"
                            type="text"
                            value={item.action}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td>
                        <TableInput
                            isView={isView}
                            style={{ height: '80px' }}
                            name="effectiveness"
                            type="text"
                            value={item.effectiveness}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td>
                        <TableInput
                            isView={isView}
                            style={{ height: '80px' }}
                            name="status"
                            type="text"
                            value={item.status}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    {/* <td>
                        <TableInput
                            isView={isView}
                            style={{ height: '80px' }}
                            name="m7"
                            type="text"
                            value={item.m7}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td>
                        <TableInput
                            isView={isView}
                            style={{ height: '80px' }}
                            name="m8"
                            type="text"
                            value={item.m8}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td>
                        <TableInput
                            isView={isView}
                            style={{ height: '80px' }}
                            name="m9"
                            type="text"
                            value={item.m9}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td>
                        <TableInput
                            isView={isView}
                            style={{ height: '80px' }}
                            name="m10"
                            type="text"
                            value={item.m10}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td>
                        <TableInput
                            isView={isView}
                            style={{ height: '80px' }}
                            name="m11"
                            type="text"
                            value={item.m11}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td>
                        <TableInput
                            isView={isView}
                            style={{ height: '80px' }}
                            name="m12"
                            type="text"
                            value={item.m12}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td> */}

                    {/*<td>
                        {moduleId && (
                            <Stack
                                direction="row"
                                sx={{ justifyContent: 'center', alignItems: 'center' }}
                            >
                                <UploadImageIconButton
                                    id={`${index}-process-upload`}
                                    tooltipTitle="upload"
                                    onChange={(e) => {
                                        uploadImageFile(e, index);
                                    }}
                                />
                                {item.files?.length > 0 && (
                                    <>
                                        <a target={'_blank'} href={item.files[0]?.url}>
                                            <ViewIconButton tooltipTitle={'view'} />
                                        </a>
                                        <DeleteIconButton
                                            tooltipTitle={'delete'}
                                            onClick={() => {
                                                updateModuleData('row', index, 'files', { files: [] });
                                            }}
                                        />
                                    </>
                                )}
                            </Stack>
                        )}
                    </td>*/}
                    <td colSpan={2}>
                        <TableInput
                            isView={isView}
                            style={{ height: '80px' }}
                            name="remark"
                            type="text"
                            value={item.remark}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    {/* {!isView && (
                        <td style={{ minWidth: '50px' }}>
                            <DeleteIconButton
                                tooltipTitle={'delete'}
                                onClick={() => handleDelete(index)}
                            />
                        </td>
                    )} */}
                </tr>
                {/* <tr>
                    <td>{index + 1}</td>
                    <td>
                        <TableInput
                            isView={false}
                            style={{ height: '80px' }}
                            name="checkpoint"
                            type="text"
                            value={item.checkpoint}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td>
                        <TableInput
                            isView={false}
                            style={{ height: '80px' }}
                            name="m1"
                            type="text"
                            value={item.m1}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td>
                        <TableInput
                            isView={false}
                            style={{ height: '80px' }}
                            name="m2"
                            type="text"
                            value={item.m2}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td>
                        <TableInput
                            isView={false}
                            style={{ height: '80px' }}
                            name="m3"
                            type="text"
                            value={item.m3}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td>
                        <TableInput
                            isView={false}
                            style={{ height: '80px' }}
                            name="m4"
                            type="text"
                            value={item.m4}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td>
                        <TableInput
                            isView={false}
                            style={{ height: '80px' }}
                            name="m5"
                            type="text"
                            value={item.m5}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td>
                        <TableInput
                            isView={false}
                            style={{ height: '80px' }}
                            name="m6"
                            type="text"
                            value={item.m6}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td>
                        <TableInput
                            isView={false}
                            style={{ height: '80px' }}
                            name="m7"
                            type="text"
                            value={item.m7}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td>
                        <TableInput
                            isView={false}
                            style={{ height: '80px' }}
                            name="m8"
                            type="text"
                            value={item.m8}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td>
                        <TableInput
                            isView={false}
                            style={{ height: '80px' }}
                            name="m9"
                            type="text"
                            value={item.m9}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td>
                        <TableInput
                            isView={false}
                            style={{ height: '80px' }}
                            name="m10"
                            type="text"
                            value={item.m10}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td>
                        <TableInput
                            isView={false}
                            style={{ height: '80px' }}
                            name="m11"
                            type="text"
                            value={item.m11}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                    <td>
                        <TableInput
                            isView={false}
                            style={{ height: '80px' }}
                            name="m12"
                            type="text"
                            value={item.m12}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>

                    <td>
                        <TableInput
                            isView={false}
                            style={{ height: '80px' }}
                            name="remark"
                            type="text"
                            value={item.remark}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleInputChange(e.target.name, e.target.value)
                            }
                        />
                    </td>
                </tr> */}
            </React.Fragment>
        );
    },
    (prevProps, nextProps) => {
        // Check if the relevant data for this row has changed
        return (
            prevProps.item === nextProps.item && prevProps.index === nextProps.index
        );
    },
);
