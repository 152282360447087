import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import { IModule } from '../../interface';

interface IProps {
  isView: boolean;
  moduleData: IModule;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={12}
        rowSpan={3}
        labels={['Doc No:', 'Rev. No.:', 'Rev. Date:']}
        values={['F-31-03', '02', ' 26.09.2022']}
        heading="INCOMMING INSPECTION STANDARD"
      />
      <tr>
        <th colSpan={12} align="left">
          Date:
          {isView ? (
            formatDate(moduleData.moduleDate)
          ) : (
            <DatePickerMui
              value={
                (moduleData?.moduleDate && moment(moduleData?.moduleDate)) ||
                null
              }
              onChange={(date) => {
                moduleData.moduleDate = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        {[
          'S.NO.',
          'PART NO.',
          'PART NAME',
          'CHARECTERISTIC',
          'SPECIFIED VALUE',
          'MEASUREMENT VALUE',
          'SAMPLE',
          'CONTROL METHOD',
          'REACTION PLAN',
          'REMARKS',
          'ACTION',
        ].map((heading, index) => (
          <th
            key={heading}
            style={{ textTransform: 'uppercase' }}
            colSpan={index === 6 ? 2 : 1}
            rowSpan={index === 6 ? 1 : 2}
          >
            {heading}
          </th>
        ))}
      </tr>
      <tr>
        <th>SIZE</th>
        <th>FREQ.</th>
      </tr>
    </thead>
  );
};

export default THead;
