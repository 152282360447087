import moment from 'moment';
import lodash from 'lodash';

export const processes = [
  {
    s_no: null,
    part_name: null,
    production: null,
    incoming: null,
    fi_report: null,
    remarks: null,
  },
];

export const initialState = {
  verified_by: null,
  received_by: null,
  checked_by: null,
  pdi_qa: null,
  incoming_qa: null,
  prd: null,
  store: null,
  approved_by: null,
  date: moment(),

  processes: Array.from({ length: 5 }, () => ({
    ...lodash.cloneDeep(processes[0]),
  })),
};
