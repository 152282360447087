import moment, { Moment } from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import { DeleteIconButton } from '../../../../components/common/button/CustomIconButton';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TimePicker } from '@mui/x-date-pickers';
import CustomSelect from '../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import TextAreaInput from '../../../../components/common/TextAreaInput';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
}
const TBody = ({ moduleData, isView, handelDeleteRows }: IProps) => {
  const [render, setRender] = useState(false);

  
  const calculateDuration = (from: any, to: any) => {
    // const durationInMilliseconds = moment(to).diff(moment(from));
    // const durationInMinutes = Math.ceil(durationInMilliseconds / (1000 * 60)); // Round up to avoid losing any minute
    // const hours = Math.floor(durationInMinutes / 60);
    // const minutes = durationInMinutes % 60;
    // return `${hours}h ${minutes}m`;
    const start = moment(from).startOf('minute');
  const end = moment(to).startOf('minute');
  const durationInMinutes = end.diff(start, 'minutes'); // Use diff with 'minutes' directly
  const hours = Math.floor(durationInMinutes / 60);
  const minutes = durationInMinutes % 60;
  return `${hours}h ${minutes}m`;
  };
  
  
  return (
    <tbody>
      {moduleData.histories &&
        moduleData.histories.map((spec: any, index: number) => (
          <tr>
            <td style={{minWidth:'50px'}}>{index+1}</td>
            <td style={{ minWidth: '150px' }}>
              {isView ? (
                formatDate(spec.date)
              ) : (
                <DatePickerMui
                  value={moment(spec.date)}
                  onChange={(date) => {
                    spec.date = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
            <td style={{ minWidth: '150px' }}>
            {/* <TextAreaInput
                isView={isView}
                name="maintenance_detail"
                value={spec.maintenance_detail}
                onChange={(e) => {
                  spec.maintenance_detail = e.target.value;
                  setRender((prev) => !prev);
                }}
              /> */}
               <TableInput
                isView={isView}
                name="maintenance_detail"
                type="text"
                value={spec.maintenance_detail}
                onChange={(e) => {
                  spec.maintenance_detail = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
              {/* {isView ? (
                formatDate(spec.time)
              ) : (
                <TimePicker
                  value={moment(spec.time)}
                  onChange={(date) => {
                    spec.time = date;
                    setRender((prev) => !prev);
                  }}
                />
              )} */}
            </td>
            <td style={{ minWidth: '150px' }}>
               {isView ? (
                formatDate(spec.from)
              ) : (
                <TimePicker
                  value={moment(spec.from)}
                  onChange={(date) => {
                    spec.from = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
            <td style={{ minWidth: '150px' }}>
              {isView ? (
                formatDate(spec.to)
              ) : (
                <TimePicker
                  value={moment(spec.to)}
                  onChange={(date) => {
                    spec.to = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
            <td style={{ minWidth: '300px' }}>
              <TextAreaInput
                isView={true}
                name="reason_for_maintenance"
                value={calculateDuration(spec.from, spec.to)}
                onChange={(e) => {
                  spec.reason_for_maintenance = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '300px' }}>
              {/* <TextAreaInput
                isView={isView}
                name="spare_parts"
                value={spec.spare_parts}
                onChange={(e) => {
                  spec.spare_parts = e.target.value;
                  setRender((prev) => !prev);
                }}
              /> */}
               <TableInput
                isView={isView}
                name="spare_parts"
                type="text"
                value={spec.spare_parts}
                onChange={(e) => {
                  spec.spare_parts = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
              
               
            </td>
            <td style={{ minWidth: '150px' }}>
            {isView ? (
                spec.maintenance_prev
              ) : (
                <CustomSelect
                  label=""
                  name="breakdown"
                  value={spec.breakdown || ""}
                  onChange={(e: any) => {
                    spec.breakdown = e.target.value;
                    setRender((prev) => !prev);
                  }}
                >
                  <MenuItem value="NONE">NONE</MenuItem>
                  <MenuItem value="P/M">P/M</MenuItem>
                  <MenuItem value="BREAKDOWN">BREAKDOWN</MenuItem>
                </CustomSelect>
              )}
            </td>
            <td style={{ minWidth: '150px' }}>
            <TableInput
                isView={isView}
                name="sign_prod"
                type="text"
                value={spec.sign_prod}
                onChange={(e) => {
                  spec.sign_prod = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '150px' }}>
              <TableInput
                isView={isView}
                name="sign_maintenance"
                type="text"
                value={spec.sign_maintenance}
                onChange={(e) => {
                  spec.sign_maintenance = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '150px' }}>
              <TableInput
                isView={isView}
                name="verified_by"
                type="text"
                value={spec.verified_by}
                onChange={(e) => {
                  spec.verified_by = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '150px' }}>
              <TableInput
                isView={isView}
                name="remarks"
                type="text"
                value={spec.remarks}
                onChange={(e) => {
                  spec.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            {!isView && (
              <td style={{ minWidth: '100px', textAlign: 'center' }}>
                <DeleteIconButton
                  tooltipTitle={'delete'}
                  onClick={() => handelDeleteRows(index,spec._id)}
                />
              </td>
            )}
          </tr>
        ))}
    </tbody>
  );
};

export default TBody;
