import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';

interface IProps {
  moduleData: any;
  isView: boolean;
  tabValue: number;
  machineData: any;
}
const THead = ({ moduleData, isView, tabValue, machineData }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={12}
        rowSpan={3}
        labels={["Doc No.", "Rev. No.", "Rev. Date"]}
        values={["F-31-01", "", ""]}
        heading="Incoming Inspection Report"
      />
      <tr>
        <th colSpan={5} align="left">
          Quality Plan No. :{moduleData?.pdirs[tabValue]?.quality_plan_no}
        </th>
        <th
          colSpan={1}
          align="left"

        >

        </th>
        <th colSpan={6} align="left">
          Drawing Rev. No. :{moduleData?.pdirs[tabValue]?.drawing_rev_no}
        </th>
      </tr>
      <tr>
        <th colSpan={5} align="left">
          Part Name : {' '}
          {moduleData.part_name
            ? moduleData.part_name
            : moduleData.part_id?.part_name}
        </th>
        <th colSpan={1} align="left">

        </th>
        <th colSpan={6} align="left">
          Customer : {' '}
          {moduleData.customer_name
            ? moduleData?.customer_name
            : moduleData?.part_id?.customer_id?.customer_name}
        </th>
      </tr>
      <tr>
        <th colSpan={5} align="left">
          Drawing No. :{moduleData?.pdirs[tabValue]?.drawing_no}
        </th>
        <th colSpan={1} align="left">

        </th>
        <th colSpan={6} align="left">
          M/C No. : {' '}
          {isView ? (
            moduleData?.pdirs[tabValue]?.machine_id?.machine_no
          ) : (
            <AutocompleteMuiCustom
              id="Machine"
              label=""
              key={tabValue + 'machine'}
              option_name="machine_no"
              arrayofObj={machineData}
              value={
                moduleData?.pdirs[tabValue]?.machine_id ||
                ''
              }
              onChange={(e: any, value: any) => {
                moduleData.pdirs[tabValue].machine_id = value;
                setRender((prev) => !prev);
              }}
            />
          )}

        </th>
      </tr>
      <tr>
        <th colSpan={5} align="left">
          Operator Name : {' '}
          <TableInput
            name="operator_name"
            type="text"
            value={moduleData?.pdirs[tabValue]?.operator_name}
            onChange={(e) => {
              moduleData.pdirs[tabValue].operator_name = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={1} align="left">

        </th>
        <th colSpan={6} align="left">
          Date : {' '}
          {isView ? (
            formatDate(moduleData.pdir_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.pdir_date || null)}
              onChange={(date) => {
                moduleData.pdir_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}

        </th>
      </tr>
      <tr>
        <th colSpan={5} align="left">
          Shift : {' '}
          <TableInput
            name="shift"
            type="text"
            value={moduleData?.pdirs[tabValue]?.shift}
            onChange={(e) => {
              moduleData.pdirs[tabValue].shift = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />

        </th>
        <th colSpan={1} align="left">

        </th>
        <th colSpan={6} align="left">
          MRR No. : {' '}
          <TableInput
            name="mrr_no"
            type="text"
            value={moduleData?.pdirs[tabValue]?.mrr_no}
            onChange={(e) => {
              moduleData.pdirs[tabValue].mrr_no = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />


        </th>
      </tr>
      <tr>
        <th colSpan={5} align="left">
          Sub-Contractor:- M/S : {' '}
          <TableInput
            name="sub_contractor"
            type="text"
            value={moduleData?.pdirs[tabValue]?.sub_contractor}
            onChange={(e) => {
              moduleData.pdirs[tabValue].sub_contractor = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />

        </th>
        <th colSpan={1} align="left">

        </th>
        <th colSpan={6} align="left">
          Quantity As Per Invoice : {' '}
          <TableInput
            name="qty"
            type="number"
            value={moduleData?.pdirs[tabValue]?.qty}
            onChange={(e) => {
              moduleData.pdirs[tabValue].qty = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />


        </th>
      </tr>
      <tr>
        <th colSpan={5} align="left" >
          Invoice No. & Date:- : {' '}
          <TableInput
            name="invoice_no"
            type="text"
            value={moduleData?.pdirs[tabValue]?.invoice_no}
            onChange={(e) => {
              moduleData.pdirs[tabValue].invoice_no = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
          {isView ? (
            formatDate(moduleData?.pdirs[tabValue]?.invoice_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData?.pdirs[tabValue]?.invoice_date || null)}
              onChange={(date) => {
                moduleData.pdirs[tabValue].invoice_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}

        </th>
        <th colSpan={1} align="left">

        </th>
        <th colSpan={6} align="left">
          Actual Quantity (Lot Size) : {' '}
          <TableInput
            name="lot_size"
            type="number"
            value={moduleData?.pdirs[tabValue]?.lot_size}
            onChange={(e) => {
              moduleData.pdirs[tabValue].lot_size = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />


        </th>
      </tr>
      <tr>
        <th colSpan={5} align="left">
          Part Name : {' '}
          {moduleData.part_name
            ? moduleData.part_name
            : moduleData.part_id?.part_name}
        </th>
        <th colSpan={1} align="left">

        </th>
        <th colSpan={6} align="left">
          No. Of Packets : {' '}
          <TableInput
            name="no_of_packets"
            type="number"
            value={moduleData?.pdirs[tabValue]?.no_of_packets}
            onChange={(e) => {
              moduleData.pdirs[tabValue].no_of_packets = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={5} align="left">
          Part Number : {' '}
          {moduleData.part_number
            ? moduleData.part_number
            : moduleData.part_id?.part_number}
        </th>
        <th colSpan={1} align="left">

        </th>
        <th colSpan={6} align="left">
          Sample Size : {' '}
          <TableInput
            name="sample_size"
            type="number"
            value={moduleData?.pdirs[tabValue]?.sample_size}
            onChange={(e) => {
              moduleData.pdirs[tabValue].sample_size = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={5} align="left" >
          P.O. NO. & Date : {' '}
          <TableInput
            name="po_no"
            type="text"
            value={moduleData?.pdirs[tabValue]?.po_no}
            onChange={(e) => {
              moduleData.pdirs[tabValue].po_no = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
          {isView ? (
            formatDate(moduleData?.pdirs[tabValue]?.po_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData?.pdirs[tabValue]?.po_date || null)}
              onChange={(date) => {
                moduleData.pdirs[tabValue].po_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}

        </th>
        <th colSpan={1} align="left">

        </th>
        <th colSpan={6} align="left">
          Quantity Acepted : {' '}
          <TableInput
            name="quantity_accepted"
            type="number"
            value={moduleData?.pdirs[tabValue]?.quantity_accepted}
            onChange={(e) => {
              moduleData.pdirs[tabValue].quantity_accepted = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />


        </th>
      </tr>
      <tr>
        <th colSpan={5} align="left" >
        </th>
        <th colSpan={1} align="left">

        </th>
        <th colSpan={6} align="left">
          Quantity Rejected : {' '}
          <TableInput
            name="quantity_rejected"
            type="number"
            value={moduleData?.pdirs[tabValue]?.quantity_rejected}
            onChange={(e) => {
              moduleData.pdirs[tabValue].quantity_rejected = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />


        </th>
      </tr>


      <tr>
        <th colSpan={12}>Drawing Pic :</th>
      </tr>
      <tr>
        <th colSpan={12} data-img={moduleData.pdirs[tabValue]?.file}>
          <img
            src={moduleData.pdirs[tabValue]?.file}
            alt="image"
            height={400}
            width={'70%'}
          />
        </th>
      </tr>
      <tr>
        {['S.NO', 'Dimension', 'specification', 'Checking Method'].map(
          (head) => (
            <th style={{ textTransform: 'uppercase' }}>{head}</th>
          )
        )}
        {Array.from({ length: 5 }, (_, index) => (
          <th style={{ width: '90px' }} key={index + 'Heading'}>
            {index + 1}
          </th>
        ))}

        <th style={{ textTransform: 'uppercase' }}>Judgement Criteria( OK / NG ) </th>
        <th style={{ textTransform: 'uppercase' }} colSpan={2}>
          Remarks
        </th>
      </tr>
    </thead>
  );
};

export default THead;
