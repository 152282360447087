import { Checkbox } from '@mui/material';
import { useState } from 'react';

interface Ifoot {
  moduleData: any;
}
const TFoot = ({ moduleData }: Ifoot) => {
  const [render, setRender] = useState(false);
  return (
    <tfoot style={{ background: '#94d82d' }}>
      <tr>
        <th colSpan={4} align="left">
          Note:- Is there any risk for rework as per(RA-PRD-01)?
        </th>
        <th colSpan={1} align="left">
          Yes
          <Checkbox
            checked={moduleData.flag === 'Yes'}
            onChange={(e) => {
              moduleData.flag = 'Yes';
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={11} align="left">
          No
          <Checkbox
            checked={moduleData.flag === 'No'}
            onChange={(e) => {
              moduleData.flag = 'No';
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
