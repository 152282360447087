import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import moment from 'moment';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../components/common/button/CustomIconButton';
import CustomSelect from '../../../../components/common/SelectField';
import { Grid, MenuItem } from '@mui/material';

interface IProps {
  item: any;
  isView: any;
  handleDelete: any;
  index: number;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setPartIdx: React.Dispatch<React.SetStateAction<number>>;
}

const TBodyRow = ({
  item,
  index,
  isView,
  handleDelete,
  setIsOpenPopup,
  setPartIdx,
}: IProps) => {
  const [rowRender, setRowRender] = useState(false);

  const handleChange = (e: any) => {
    item[e.target.name] = e.target.value;
  };

  return (
    <>
      <tr>
        <td style={{ textAlign: 'center', width: '180px', minWidth: '180px' }}>
          <DatePickerMui
            label=""
            onChange={(date) => {
              item.date = moment(date || null);
              setRowRender((prev: any) => !prev);
            }}
            value={(item?.date && moment(item?.date)) || null}
          />
        </td>
        <td style={{ width: '280px', minWidth: '280px' }}>
          {isView ? (
            item?.ri_part_id && item.ri_part_id?.part_name
          ) : (
            <Grid container xs={12} direction="row" alignItems={'center'}>
              <Grid item xs={10}>
                <p
                  style={{
                    fontSize: '1.2rem',
                  }}
                >
                  {typeof item?.ri_part_id === 'object' &&
                  item?.ri_part_id !== null
                    ? item.ri_part_id?.part_name
                    : item?.partName}
                  {!item?.ri_part_id && 'Pick Part No.'}
                </p>
              </Grid>

              {item.ri_part_id ? (
                <Grid item xs={2}>
                  <RemoveIconButton
                    tooltipTitle="remove part"
                    onClick={() => {
                      item.ri_part_id = null;
                      item.partNumber = null;
                      item.partName = null;
                      setRowRender((prev: any) => !prev);
                    }}
                  />
                </Grid>
              ) : (
                <Grid item xs={2}>
                  <AddIconButton
                    tooltipTitle="add part"
                    onClick={() => {
                      setIsOpenPopup(true);
                      setPartIdx(index);
                    }}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </td>
        <td style={{ textAlign: 'center', width: '150px', minWidth: '150px' }}>
          <TableInput
            isView={isView}
            type="number"
            name="received_qty"
            value={item?.received_qty ?? null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        {['heading1', 'heading1', 'heading1'].map((heading, index) => (
          <td
            key={heading + index}
            style={{ minWidth: '150px', width: '150px' }}
          >
            <TableInput
              isView={isView}
              type="text"
              name="section_obs"
              value={item?.section_obs?.[index] ?? null}
              onChange={(e) => {
                item.section_obs[index] = e.target.value;
                setRowRender((prev) => !prev);
              }}
            />
          </td>
        ))}
        {['heading2', 'heading2', 'heading2'].map((heading, index) => (
          <td
            key={heading + index}
            style={{ minWidth: '150px', width: '150px' }}
          >
            <TableInput
              isView={isView}
              type="text"
              name="moulding_obs"
              value={item?.moulding_obs?.[index] ?? null}
              onChange={(e) => {
                item.moulding_obs[index] = e.target.value;
                setRowRender((prev) => !prev);
              }}
            />
          </td>
        ))}
        <td style={{ textAlign: 'center', width: '150px', minWidth: '150px' }}>
          <TableInput
            isView={isView}
            type="text"
            name="sampling_plan"
            value={item?.sampling_plan || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center', width: '150px', minWidth: '150px' }}>
          <CustomSelect
            name={'status'}
            value={item?.status}
            sx={{ width: '150px' }}
            onChange={(e) => {
              item.status = e.target.value;
              setRowRender((prev) => !prev);
            }}
          >
            <MenuItem value="">NONE</MenuItem>
            <MenuItem value="OK">OK</MenuItem>
            <MenuItem value="NOT OK">NOT OK</MenuItem>
          </CustomSelect>
        </td>
        <td style={{ textAlign: 'center', width: '150px', minWidth: '150px' }}>
          <TableInput
            isView={isView}
            type="number"
            name="ok_qty"
            value={item?.ok_qty ?? null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center', width: '150px', minWidth: '150px' }}>
          <TableInput
            isView={isView}
            type="number"
            name="rej_qty"
            value={item?.rej_qty ?? null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center', width: '150px', minWidth: '150px' }}>
          <TableInput
            isView={isView}
            type="text"
            name="reason_of_rej"
            value={item?.reason_of_rej || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center', width: '150px', minWidth: '150px' }}>
          <TableInput
            isView={isView}
            type="text"
            name="qa_sign"
            value={item?.qa_sign || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center', width: '150px', minWidth: '150px' }}>
          <TableInput
            isView={isView}
            type="text"
            name="prd_or_op_sign"
            value={item?.prd_or_op_sign || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td
          style={{ textAlign: 'center', width: '150px', minWidth: '150px' }}
          colSpan={isView ? 2 : 1}
        >
          <TableInput
            isView={isView}
            type="text"
            name="remarks"
            value={item?.remarks || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        {!isView && (
          <td style={{ textAlign: 'center' }}>
            <DeleteIconButton
              tooltipTitle="DELETE"
              onClick={() => {
                handleDelete(index);
              }}
            />
          </td>
        )}
      </tr>
    </>
  );
};

export default TBodyRow;
