import { TableInput } from '../../../../../components/common';

interface Ifoot {
  moduleData: any;
  setRender: any;
  isView: any;
}
const TFoot = ({ moduleData, setRender, isView }: Ifoot) => {
  return (
    <tfoot style={{ background: '#94d82d' }}>
      <tr>
        <th colSpan={6} align="left">
          Prepared By:{' '}
          <TableInput
            isView={isView}
            name="report_prepared_by"
            type="text"
            value={moduleData.report_prepared_by}
            onChange={(e) => {
              moduleData.report_prepared_by = e.target.value;
              setRender((prev: any) => !prev);
            }}
          />
        </th>
        <th colSpan={6} align="left">
          Approved By:
          <TableInput
            isView={isView}
            name="report_approved_by"
            type="text"
            value={moduleData.report_approved_by}
            onChange={(e) => {
              moduleData.report_approved_by = e.target.value;
              setRender((prev: any) => !prev);
            }}
          />
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
