const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};

export const HeaderStandard = [
  [
    { text: 'S.No.', style: style1 },
    { text: 'Check Points', style: style1 },
    { text: 'Verification Method', style: style1 },
    { text: 'Judgement (OK/NG)', style: style1 },
    { text: 'Observation', style: style1 },
    { text: 'Action Taken', style: style1 },
    { text: 'Effectiveness ', style: style1 },
    { text: 'Status ', style: style1 },
    // { text: '', style: style1 },
    // { text: '', style: style1 },
    // { text: '', style: style1 },
    // { text: '', style: style1 },
    // { text: '', style: style1 },
    // { text: '', style: style1 },
    { text: 'Remark', style: style1 },
    { text: 'Action', style: { ...style1, backgroundColor: '#c92a2a' } },
  ],
];

export const listConstant = {
  childHeader: 'Training plan Checksheet list',
  addButtonLable: 'New Checksheet',
};

export const headerConstant = {
  tableCompHeadTitle: 'Training plan report ',
  // tableCompHeadTitle: 'Operator Observance plan CHECKSHEET ',
};

export const apiConstant = {
  get: 'trainingPlanMasterChecklistRoute',
  getById: 'trainingPlanMasterChecklistRoute',
  post: 'trainingPlanMasterChecklistRoute',
  update: 'trainingPlanMasterChecklistRoute',
  delete: 'trainingPlanMasterChecklistRoute',
  upload: 'trainingPlanMasterChecklistRoute/uploadFile',
  forAutoComplete: 'machineRoutes/machine/ForAutocomplete',
};

export const tilesConstant = {
  childHeaderText: 'Training plan',
};

export const navigationConstant = {
  createEditChecklist: '/common/trainingPlanMasterChecklistCreate',
};


export const initialState=[
  {
    check_points:'Does Operator Follow the Operating Guidelines provided in Work Instruction in sequential manner?',
    veri_method:' Visual',
    judgement:'',
    obs:'',
    action:'',
    effectiveness:'',
    status:'',
  },
  {
    check_points:'Is the Operator maintaining  1S , 2S on his work station ?',
    veri_method:' Visual',
    judgement:'',
    obs:'',
    action:'',
    effectiveness:'',
    status:'',
  },
  {
    check_points:'Is the operator filling  the m/c check points before starting  the process ?',
    veri_method:' Daily Machine Check sheet',
    judgement:'',
    obs:'',
    action:'',
    effectiveness:'',
    status:'',
  },
  {
    check_points:'Does Operator Separate the Ok & Reject part?',
    veri_method:' Visual',
    judgement:'',
    obs:'',
    action:'',
    effectiveness:'',
    status:'',
  },
  {
    check_points:'Is the  operator following the Personal  Safety guidelines?',
    veri_method:' Gloves, Shoes, Ear plug etc.',
    judgement:'',
    obs:'',
    action:'',
    effectiveness:'',
    status:'',
  },
  {
    check_points:'Is the Operator marking the reject part before putting it in Red bin ?',
    veri_method:'  verify some part',
    judgement:'',
    obs:'',
    action:'',
    effectiveness:'',
    status:'',
  },
  {
    check_points:'In case of abnormal situation does the operator inform the supervisor . ',
    veri_method:'Visual',
    judgement:'',
    obs:'',
    action:'',
    effectiveness:'',
    status:'',
  },
  {
    check_points:'Is the operator taking set up approval before starting machine .  ',
    veri_method:'Visual',
    judgement:'',
    obs:'',
    action:'',
    effectiveness:'',
    status:'',
  },
]
