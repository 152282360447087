import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
import { useState } from 'react';

interface IProps {
  moduleData: any;
  isView: boolean;
  handleSumbit: any;
  handelDeleteRows: any;
  isOpenPopup: any;
  setIsOpenPopup: any;
  setIsOpenPopupRejections: any;
  setRejectionsIndx: any;
  setPartIdx: any;
  machineData: any;
  operators: any;
  setModuleData:any
}
let x=1;

const TController = ({
  moduleData,
  isView,
  handelDeleteRows,
  setIsOpenPopup,
  setIsOpenPopupRejections,
  setRejectionsIndx,
  setPartIdx,
  machineData,
  setModuleData,
  operators,
}: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
            setIsOpenPopup,
            setModuleData,
             x:1
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
            handelDeleteRows,
            setIsOpenPopup,
            setPartIdx,
            setIsOpenPopupRejections,
            setRejectionsIndx,
            machineData,
            operators,
            x:1
          }}
        />
         <THead
          {...{
            moduleData,
            isView,
            setIsOpenPopup,
            setModuleData,
             x:2
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
            handelDeleteRows,
            setIsOpenPopup,
            setPartIdx,
            setIsOpenPopupRejections,
            setRejectionsIndx,
            machineData,
            operators,
            x:2
          }}
        />
        <TFoot moduleData={moduleData} setRender={setRender} isView={isView} />
        </RenderTable>
    </Box>
  );
};

export default TController;
