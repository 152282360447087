import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={12}
        rowSpan={3}
        labels={['Doc No:', 'Rev No.:', 'Eff. Date:']}
        values={['FM-QAD-01', '00', '01-04-2023']}
        heading="INCOMING INSPECTION REPORT"
      />
      <tr>
        <th colSpan={6} align="left">
          Part Name:
          {moduleData.part_name
            ? moduleData.part_name
            : moduleData.ri_part_id?.part_name}{' '}
        </th>
        <th colSpan={6} align="left">
          Supplier Name:{' '}
          {moduleData.supplierName
            ? moduleData.supplierName
            : moduleData.ri_part_id?.vendor_id?.vender_name}
        </th>
      </tr>
      <tr>
        <th colSpan={6} align="left">
          Date :{' '}
          {isView ? (
            formatDate(moduleData.inspection_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.inspection_date)}
              onChange={(date) => {
                moduleData.inspection_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={6} align="left">
          Invoice No.:{' '}
          <TableInput
            isView={isView}
            name="invoice_no"
            type="text"
            value={moduleData?.invoice_no}
            onChange={(e) => {
              moduleData.invoice_no = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={6} align="left">
          Received Qty.:{' '}
          <TableInput
            isView={isView}
            name="recieved_qty"
            type="number"
            value={moduleData?.recieved_qty}
            onChange={(e) => {
              moduleData.recieved_qty = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={6} align="left"></th>
      </tr>
      <tr>
        <th colSpan={4}>Standard Parameter</th>
        <th colSpan={8}>Observation</th>
      </tr>
      <tr>
        {['S.No.', 'Characterstic', 'Specification', 'Measurement Value'].map(
          (head) => (
            <th>{head}</th>
          )
        )}
        {Array.from({ length: 5 }, (_, index) => (
          <th style={{ width: '110px' }} key={index + 'Heading'}>
            {index + 1}
          </th>
        ))}
        <th>Status</th>
        <th colSpan={2}>Remark</th>
      </tr>
    </thead>
  );
};

export default THead;
