import { useState } from 'react';
import { TableInput } from '../../../../../components/common';

interface Ifoot {
  moduleData: any;
  isView: any;
}
const TFoot = ({ moduleData, isView }: Ifoot) => {
  const [render, setRender] = useState(false);
  return (
    <tfoot>
     <tr>
      <th align="left" style={{ minWidth: '150px' }} colSpan={2}>
      Maintenance Sign:---
      <TableInput
            isView={isView}
            name="maintenance_sign"
            type="text"
            value={moduleData?.maintenance_sign}
            onChange={(e) => {
              moduleData.maintenance_sign = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th align="left" style={{ minWidth: '150px' }} colSpan={2}>
        Production Sign:---
      <TableInput
            isView={isView}
            name="production_sign"
            type="text"
            value={moduleData?.production_sign}
            onChange={(e) => {
              moduleData.production_sign = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
