import { Box } from '@mui/system';
import axios from 'axios';
import { AiFillEye } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CustomButton,
  SaveButton,
  SubmitButton,
} from '../../components/common/button/CustomButtons';
import formatDate from '../../components/common/formatDate';
import ChildHeader from '../../components/ui/ChildHeader';
import { useQuery } from '../../hooks/UseQuery';
import { useAuth } from '../../services/auth/AuthProvider';
import TController from './components/create/TController';
import useGetData from './helpers/useGetData';
interface MyData {
  moduleData: any; // replace PdirType with the type of your `inspectionReport` data
  isLoading: boolean;
  error?: string | null;
}
const Create = () => {
  const { partId, inspectionReportId } = useParams();
  const { state } = useAuth();
  const navigate = useNavigate();
  const isView = useQuery('isView') === 'true' ? true : false;
  const partName = useQuery('partName');
  const { moduleData, isLoading, error }: MyData = useGetData(
    partId,
    inspectionReportId
  );

  const onSubmit = async (isSubmitted: boolean) => {
    moduleData.isSubmitted = isSubmitted;
    moduleData.is_admin_request = 'pending';
    moduleData.report_prepared_by = state.user?.name;
    moduleData.ri_part_id = partId;
    isSubmitted && (moduleData.report_prepared_by_date = new Date());
    let response: any;
    if (moduleData._id) {
      response = await axios
        .put(`/api/inspectionReport/${moduleData._id}`, moduleData)
        .catch((err) => console.log(err));
    } else {
      response = await axios
        .post(`/api/inspectionReport`, moduleData)
        .catch((err) => console.log(err));
    }
    if (response.data && state.user?.userType === 3 && isSubmitted) {
      const messageData = {
        category: 'Inspection Report',
        title: 'Inspection Report CREATED',
        user: 'admin',
        body: `Inspection Report of "${partName}" ${
          moduleData._id ? 'updated' : 'submitted'
        } by "${state.user?.name}" on ${formatDate(new Date())}`,
        link: `/common/inspectionReport/${partId}/${response.data._id}?partName=${partName}&isView=true`,
      };
      axios
        .post(`/api/notification`, messageData)
        .then(() => {
          if (moduleData._id) {
            alert('updated successfully ');
            return;
          }
          alert('created successfully');
          navigate(-1);
        })
        .catch((err) => console.log(err));
      return;
    }
    if (moduleData._id && !isSubmitted) {
      alert('updated successfully ');
      return;
    }
    alert('created successfully');
    navigate(-1);
  };

  console.log(moduleData);
  return (
    <Box>
      <Box>
        <ChildHeader text="Incoming Inspection Report">
          {moduleData && (
            <a target={'_blank'} href={moduleData && moduleData.file}>
              <CustomButton icon={<AiFillEye />}>VIEW DRAWING</CustomButton>
            </a>
          )}

          {!isView && moduleData && (
            <>
              {state.user?.userType === 3 && (
                <SaveButton
                  label="Save & Continue"
                  onClick={() => onSubmit(false)}
                />
              )}
              <SubmitButton label="Submit" onClick={() => onSubmit(true)} />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
        }}
      />
    </Box>
  );
};

export default Create;
