export const checkListList = {
  childHeader: 'Training plan STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  getAllStatus: 'trainingPlanStatusRoute/getAllSatusByYear',
  upadateListStatus: 'trainingPlanStatusRoute/handleStatusUpdate',
  checklistUpload: 'trainingPlanStatusRoute/handleUpload',
  checklistDeleteImage: 'trainingPlanStatusRoute/handleDeleteImage',
  forAutoComplete:
    'masterlistinstrument/instrumentList/instumentForAutoComplete',
};

export const navigationConstant = {
  checklistRouter: '/common/trainingPlanStatusCheckListCreate',
};
