import React, { useState } from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { TableCustom } from '../../../../../components/common';
import useDebounce from '../../../../../hooks/useDebounce';
import { moduleConstant } from '../../helpers/constant';

const tableHeaderObj = [
  [
    { text: 'S.No.', rowspan: 1, colspan: 1 },
    { text: 'Key Spares', rowspan: 1, colspan: 1 },
    { text: 'Consumption (6Months)', rowspan: 1, colspan: 1 },
    { text: 'UOM', rowspan: 1, colspan: 1 },
    { text: 'Points', rowspan: 1, colspan: 1 },
    { text: 'Cost (Rs)', rowspan: 1, colspan: 1 },
    { text: 'Points', rowspan: 1, colspan: 1 },
    { text: 'Lead time for Spares Procurement', rowspan: 1, colspan: 1 },
    { text: 'Points', rowspan: 1, colspan: 1 },
    { text: 'score', rowspan: 1, colspan: 1 },
    { text: 'Critical', rowspan: 1, colspan: 1 },
    { text: 'Remarks', rowspan: 1, colspan: 1 },
  ],
];

const ViewSparePartList = ({ moduleId }: { moduleId?: any }) => {
  const [partData, setPartData] = useState<any>([]);

  const fetchPartData = () => {
    if (!moduleId) return;
    axios
      .get(`${moduleConstant.crudApi}/spareParts/${moduleId}`)
      .then((res) => {
        setPartData(res.data);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchPartData, 300, []);
  return (
    <>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {partData &&
            partData.map((item: any, index: number) => (
              <React.Fragment key={item?._id}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{item?.spare_part_name}</TableCell>
                  <TableCell align="center">{item?.consumption}</TableCell>
                  <TableCell align="center">{item?.uom}</TableCell>
                  <TableCell align="center">{item?.points_1}</TableCell>
                  <TableCell align="center">{item?.cost}</TableCell>
                  <TableCell align="center">{item?.points_2}</TableCell>
                  <TableCell align="center">
                    {item?.lead_time_for_spare_procurment}
                  </TableCell>
                  <TableCell align="center">{item?.points_3}</TableCell>
                  <TableCell align="center">{item?.score}</TableCell>
                  <TableCell align="center">{item?.critical}</TableCell>
                  <TableCell align="center">{item?.remarks}</TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
    </>
  );
};

export default ViewSparePartList;
