import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import GridDatePicker from '../../../components/common/inputs/GridDatePicker';
import { Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import { Loader } from '../../../components/common/Loader';
import ModalCustom from '../../../components/common/Modal';
import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';
import { DivStyled } from '../../../assets/styles/components/DivStyledLableInputs';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../../components/common/button/CustomIconButton';
import PartTable from './components/PartTable';
import { Checkbox } from '@mui/material';
import GridTimePicker from '../../../components/common/inputs/GridTimePicker';
import DateGridMui from '../../../components/common/dateCustom/DateGridMui';
import moment from 'moment';
import GridSelect from '../../../components/common/inputs/GridSelect';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [data, setData] = useState<any>({
    exclude_report: false,
    files: [],
    images: [],
  });
  const [files, setFiles] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Supplier Complaint' : 'Create Supplier Complaint'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  const [isPartModal, setIsPartModal] = useState(false);
  const [, setRender] = useState(false);
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/supplierComplaintRoutes/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);
  useEffect(() => {
    axios
      .get(`/api/vendorRoute/vendor/forAutoComplete`)
      .then((res) => {
        setVendors(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  function handleCustomerData(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }
  const handleSumbit = async () => {
    if (!data.vendor_id) {
      alert('Please enter Supplier name');
      return;
    }
    const ans = await confirmSubmit();
    if (!ans) return;

    setIsLoading(true);
    const formData = new FormData();

    if (typeof data.vendor_id === 'object') {
      data.vendor_id = data.vendor_id._id;
    }
    if (typeof data.ri_part_id === 'object') {
      data.ri_part_id = data.ri_part_id._id;
    }
    for (const key in data) {
      if (data[key] || typeof data[key] == 'boolean') {
        if (key !== 'files' && key !== 'images' && key !== 'd8_capa_files') {
          formData.append(key, data[key]);
        }
      }
    }

    if (files) {
      for (const key of Object.keys(files)) {
        formData.append('files', files[key]);
      }
    }

    if (id) {
      await axios
        .put(`/api/supplierComplaintRoutes/${id}`, formData)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/supplierComplaintRoutes', formData)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <Loader loader={isLoading} />
      <DateGridMui
        lable="Material Receipt Date:"
        onChange={(date: any) => {
          data.material_receipt_date = date;
          setRender((prev) => !prev);
        }}
        value={
          (data.material_receipt_date && moment(data.material_receipt_date)) ||
          null
        }
      />
      <AutocompleteGridmui
        id={'vendor_name'}
        label={'Supplier Name'}
        option_name={'vender_name'}
        value={data.vendor_id || ''}
        arrayofObj={vendors}
        onChange={(e, value) => {
          data.vendor_id = value;
          setRender((prev) => !prev);
        }}
      />
      <DivStyled style={{ alignItems: 'center' }}>
        <label>{'Item Description'}</label>
        <div
          style={{
            borderLeft: '2px solid #ced4da',
            paddingLeft: '1rem',
            width: '100%',
          }}
        >
          <Grid container xs={12} direction="row" alignItems={'center'}>
            <Grid item xs={10}>
              <p
                style={{
                  fontSize: '1.6rem',
                }}
              >
                {typeof data?.ri_part_id === 'object' &&
                data?.ri_part_id !== null
                  ? data?.ri_part_id?.part_name
                  : data?.partName || ''}
              </p>
            </Grid>

            {data.ri_part_id ? (
              <Grid item xs={2}>
                <RemoveIconButton
                  tooltipTitle="remove part"
                  onClick={() => {
                    data.ri_part_id = null;
                    data.partNumber = null;
                    data.partName = null;

                    setRender((prev) => !prev);
                  }}
                />
              </Grid>
            ) : (
              <Grid item xs={2}>
                <AddIconButton
                  tooltipTitle="add part"
                  onClick={() => {
                    setIsPartModal(true);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </DivStyled>
      <GridInputs
        id={'process'}
        name={'process'}
        html_for={'process'}
        label_name={'Process:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.process || ''}
        onChange={handleCustomerData}
      />
      <DateGridMui
        lable="Invoice Date:"
        onChange={(date: any) => {
          data.invoice_date = date;
          setRender((prev) => !prev);
        }}
        value={(data?.invoice_date && moment(data?.invoice_date)) || null}
      />
      <GridInputs
        id={'invoice_no'}
        name={'invoice_no'}
        html_for={'invoice_no'}
        label_name={'Invoice No.:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.invoice_no || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'invoice_qty'}
        name={'invoice_qty'}
        html_for={'invoice_qty'}
        label_name={'Invoice Qty.:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.invoice_qty || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'problem_desc'}
        name={'problem_desc'}
        html_for={'problem_desc'}
        label_name={'Problem Description:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.problem_desc || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'action_taken'}
        name={'action_taken'}
        html_for={'action_taken'}
        label_name={'Action Taken:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.action_taken || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'action_taken_by_supplier'}
        name={'action_taken_by_supplier'}
        html_for={'action_taken_by_supplier'}
        label_name={'Action Taken By Supplier:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.action_taken_by_supplier || ''}
        onChange={handleCustomerData}
      />

      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remarks:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.remarks || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'sign'}
        name={'sign'}
        html_for={'sign'}
        label_name={'Sign:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.sign || ''}
        onChange={handleCustomerData}
      />

      <h1>Effectiveness Monitoring</h1>
      {[
        'jan',
        'feb',
        'mar',
        'apr',
        'may',
        'jun',
        'jul',
        'aug',
        'sep',
        'oct',
        'nov',
        'dec',
      ].map((month, index) => (
        <GridSelect
          key={index + month}
          name={month}
          label_name={month?.toUpperCase()}
          html_for={month}
          value={data?.[month] || ''}
          onChange={handleCustomerData}
        >
          <MenuItem value="not repeated">Not Repeated</MenuItem>
          <MenuItem value="repeated">Repeated</MenuItem>
        </GridSelect>
      ))}

      <Grid item xs={3}>
        <CustomUploadButton
          lable={'Defect photo'}
          multiple
          accept="image/png,image/jpeg,image/jpg"
          onChange={(e) => {
            if (files) {
              setFiles([...files, ...e.target.files]);
            } else {
              setFiles(e.target.files);
            }
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <CustomUploadButton
          lable={'Attach Supporting Doucments'}
          multiple
          accept="application/pdf,application/doc,application/excel/,application/ppt"
          onChange={(e) => {
            if (files) {
              setFiles([...files, ...e.target.files]);
            } else {
              setFiles(e.target.files);
            }
          }}
        />
      </Grid>

      <div>
        <Checkbox
          name="exclude_report"
          checked={data?.exclude_report}
          onChange={(e) => {
            setData((prev: any) => ({
              ...prev,
              [e.target.name]: e.target.checked,
            }));
          }}
        />

        <span
          style={{
            fontWeight: '500',
            fontSize: '20px',
          }}
        >
          Exclude from report
        </span>
      </div>

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
      <ModalCustom
        openModal={isPartModal}
        closeModal={() => {
          setIsPartModal(false);
        }}
        title="PART"
      >
        <PartTable moduleData={data} />
      </ModalCustom>
    </>
  );
};

export default Create;
