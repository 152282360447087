import moment from 'moment';
import { useCallback, useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import CustomSelect from '../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import { CustomIconButton } from '../../../../../components/common/button/CustomIconButton';
import { MdChecklist } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { TimePicker } from '@mui/x-date-pickers';

interface IProps {
  moduleData: any;
  isView: boolean;
}
function totalRuntime(
  startTime: string | null | undefined,
  endTime: string | null | undefined
): number | null {
  if (
    startTime === undefined ||
    startTime === null ||
    endTime === undefined ||
    endTime === null
  ) {
    return null;
  }
  const start:any = new Date(startTime);
  const end:any = new Date(endTime);

  if (isNaN(start.getTime()) || isNaN(end.getTime())) {
    throw new Error('Invalid time format');
  }

  const diffInMilliseconds = Math.abs(end.getTime() - start.getTime());
  const diffInHours:any = diffInMilliseconds / (1000 * 60 * 60);

  return diffInHours.toFixed(2);
}

const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const navigate = useNavigate();

  // const navigateCheckList = useCallback(
  //   (item: any) => {
  //     navigate(
  //       `/common/tool/status/checklist/create?toolId=${moduleData._id}&list_id=${item?._id}&tool_name=${moduleData?.tool_name}&tool_no=${moduleData?.tool_no}`
  //     );
  //   },
  //   [moduleData]
  // );

  return (
    <tbody>
      {moduleData.list &&
        moduleData.list.map((item: any, index: number) => (
          <tr key={`${index}_row`}>
            <td style={{ minWidth: '100px', width: '100px' }}>
              {formatDate(item.date)}
            </td>
            <td style={{ minWidth: '180px', width: '180px' }}>
              {isView ? (
                formatDate(item?.tool_unloading_date)
              ) : (
                <DatePickerMui
                  value={
                    item?.tool_unloading_date
                      ? moment(item.tool_unloading_date)
                      : null
                  }
                  onChange={(date) => {
                    item.tool_unloading_date = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
            <td style={{ minWidth: '160px', width: '160px' }}>
              <TableInput
                isView={true}
                name="machine_no"
                type="text"
                value={item.machine_no}
              />
            </td>
            <td style={{ minWidth: '160px', width: '160px' }}>
              <TableInput
                isView={true}
                name="actual_prod"
                type="number"
                value={item.actual_prod}
              />
            </td>
            <td style={{ minWidth: '160px', width: '160px' }}>
              <TableInput
                isView={true}
                name="comulative"
                type="text"
                value={item.comulative}
              />
            </td>
            <td style={{ minWidth: '160px', width: '160px' }}>
             {item?.balance_life_tool}
            </td>
            {/* <td
              style={{
                ...(item.status === 'RED (R)' && { background: '#e03131' }),
                minWidth: '160px',
                width: '160px',
              }}
            >
              {isView ? (
                item.status
              ) : (
                <CustomSelect
                  name="status"
                  value={item.status}
                  sx={{
                    background: `
                      ${
                        (item.status === 'GREEN (G)' && '#40c057') ||
                        (item.status === 'YELLOW (Y)' && '#ffe066') ||
                        (item.status === 'RED (R)' && '#e03131')
                      }
                    `,
                  }}
                  onChange={(e) => {
                    item.status = e.target.value;
                    setRender((prev) => !prev);
                  }}
                >
                  <MenuItem value="">NONE</MenuItem>
                  <MenuItem value="GREEN (G)">GREEN (G)</MenuItem>
                  <MenuItem value="YELLOW (Y)">YELLOW (Y)</MenuItem>
                  <MenuItem value="RED (R)">RED (R)</MenuItem>
                </CustomSelect>
              )}
            </td> */}
            {/* <td>
            
            </td>
            <td>
              {item?.isCheckListNeeded && (
                <CustomIconButton
                  sx={{ p: 0 }}
                  tooltipTitle={'CREATE CHECKLIST'}
                  onClick={() => {
                    navigateCheckList(item);
                  }}
                >
                  <MdChecklist style={{ color: '#343a40' }} />
                </CustomIconButton>
              )}
            </td> */}
            <td style={{minWidth:"200px"}}>
              <TableInput
                isView={isView}
                name="reason"
                type="text"
                value={item.reason}
                onChange={(e) => {
                  item.reason = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '180px', width: '180px' }}>
              {isView ? (
                formatDate(item?.maint_start_date)
              ) : (
                <DatePickerMui
                  value={
                    item?.maint_start_date
                      ? moment(item.maint_start_date)
                      : null
                  }
                  onChange={(date) => {
                    item.maint_start_date = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
            <td>
            <TimePicker
              sx={{
                width: '150px',
              }}
              value={moment(item?.maint_start_time || null)}
              onChange={(date) => {
                item.maint_start_time = date;
                setRender((prev: any) => !prev);
              }}
            />
            </td>
            <td style={{ minWidth: '180px', width: '180px' }}>
              {isView ? (
                formatDate(item?.maint_completion_date)
              ) : (
                <DatePickerMui
                  value={
                    item?.maint_completion_date
                      ? moment(item.maint_completion_date)
                      : null
                  }
                  onChange={(date) => {
                    item.maint_completion_date = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
            <td>
            <TimePicker
              sx={{
                width: '150px',
              }}
              value={moment(item?.maint_completion_time || null)}
              onChange={(date) => {
                item.maint_completion_time = date;
                setRender((prev: any) => !prev);
              }}
            />
            </td>
            <td style={{minWidth:"100px"}}>
            {totalRuntime(item?.maint_start_time, item?.maint_completion_time)}
              </td>
            <td style={{minWidth:"400px"}}>
              <TableInput
                isView={isView}
                name="action_taken"
                type="text"
                value={item.action_taken}
                onChange={(e) => {
                  item.action_taken = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>
        ))}
         {moduleData?.revision_history?.map((revData: any, index: number) => (
        <tr>
          <td style={{ width: '120px' }}>
            <TableInput
              name="rev_no"
              type="number"
              value={revData.rev_no}
              onChange={(e) => {
                revData.rev_no = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
          <td style={{ width: '250px' }} colSpan={2}>
            {isView ? (
              formatDate(revData.rev_date || null)
            ) : (
              <DatePickerMui
                value={moment(revData.rev_date)}
                onChange={(date) => {
                  revData.rev_date = date;
                  setRender((prev) => !prev);
                }}
              />
            )}
          </td>
          <td colSpan={7}>
            <TableInput
              name="rev_desc"
              type="text"
              value={revData.rev_desc}
              onChange={(e) => {
                revData.rev_desc = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
          <td colSpan={2}>
            <TableInput
              name="revised_by"
              type="text"
              value={revData.revised_by}
              onChange={(e) => {
                revData.revised_by = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
          <td>
            <TableInput
              name="verified_by"
              type="text"
              value={revData.verified_by}
              onChange={(e) => {
                revData.verified_by = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
        </tr>
      ))}
        <tr>
        {['S.NO.', 'DATE', 'Revision Detail', 'Revise By', 'Verified By'].map(
          (heading) => (
            <th key={heading} colSpan={heading === 'Revision Detail' ? 7 : (heading === 'DATE'||heading === 'Revise By' ?2:1)}>
              {heading}
            </th>
          )
        )}
      </tr>
    </tbody>
  );
};

export default TBody;
