import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import SelectGridMui from '../../../components/common/selectscustom/SelectGridMui';
import { MenuItem } from '@mui/material';

const Create = ({
  id,
  setIsModal,
}: {
  id?: null | string;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [moduleData, setModuleData] = useState<any>({
    spare_part_name: '',
    consumption: Number,
    spare_part_desc: '', // extra in alp
    uom: '',
    points_1: null,
    cost: null,
    points_2: null,
    lead_time_for_spare_procurment: '', //
    points_3: null,
    score: '',
    critical: '',
  });
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update ' : 'Create '}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/criticalSparePartsRoute/${id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleModuleData(e: React.ChangeEvent<HTMLInputElement>) {
    setModuleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/criticalSparePartsRoute/${id}`, moduleData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/criticalSparePartsRoute', moduleData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'spare_part_name'}
        name={'spare_part_name'}
        html_for={'spare_part_name'}
        label_name={'Key Spares:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.spare_part_name || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'consumption'}
        name={'consumption'}
        html_for={'consumption'}
        label_name={'Consumption (6Months):'}
        input_type={'number'}
        focusType={'onblur'}
        value={moduleData?.consumption || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'uom'}
        name={'uom'}
        html_for={'uom'}
        label_name={'UOM:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.uom || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'points_1'}
        name={'points_1'}
        html_for={'points_1'}
        label_name={'Points 1:'}
        input_type={'number'}
        focusType={'onblur'}
        value={moduleData?.points_1 || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'cost'}
        name={'cost'}
        html_for={'cost'}
        label_name={'Cost:'}
        input_type={'number'}
        focusType={'onblur'}
        value={moduleData?.cost || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'points_2'}
        name={'points_2'}
        html_for={'points_2'}
        label_name={'Points 2:'}
        input_type={'number'}
        focusType={'onblur'}
        value={moduleData?.points_2 || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'lead_time_for_spare_procurment'}
        name={'lead_time_for_spare_procurment'}
        html_for={'lead_time_for_spare_procurment'}
        label_name={'Lead time for Spares Procurement  :'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.lead_time_for_spare_procurment || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'points_3'}
        name={'points_3'}
        html_for={'points_3'}
        label_name={'Points 3:'}
        input_type={'number'}
        focusType={'onblur'}
        value={moduleData?.points_3 || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'score'}
        name={'score'}
        html_for={'score'}
        label_name={'Score:'}
        input_type={'number'}
        focusType={'onblur'}
        value={moduleData?.score || ''}
        onChange={handleModuleData}
      />
      <SelectGridMui
        name="critical"
        value={moduleData?.critical || ''}
        lable={'Critical'}
        onChange={(e: any) => {
          setModuleData((prev: any) => ({ ...prev, critical: e.target.value }));
        }}
      >
        <MenuItem value="">NONE</MenuItem>
        <MenuItem value="yes">YES</MenuItem>
        <MenuItem value="no">NO</MenuItem>
      </SelectGridMui>
      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remarks:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.remarks || ''}
        onChange={handleModuleData}
        last_child={2}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
