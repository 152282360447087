import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import GridDatePicker from '../../../components/common/inputs/GridDatePicker';
import { Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import GridSelect from '../../../components/common/inputs/GridSelect';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import { Loader } from '../../../components/common/Loader';
import ModalCustom from '../../../components/common/Modal';
import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';
import { DivStyled } from '../../../assets/styles/components/DivStyledLableInputs';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../../components/common/button/CustomIconButton';
import PartTable from './components/PartTable';
import { Checkbox } from '@mui/material';
import { StyledNotesField } from '../../../components/common/FormControlMui';
import DescriptionTextfield from '../../../components/common/DescriptionNotes';
import GridTimePicker from '../../../components/common/inputs/GridTimePicker';
import PickRiPart from '../../RIDeparment/problematicPartInspectionReport/create/PickRiPart';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [data, setData] = useState<any>({
    exclude_report: false,
  });
  const [files, setFiles] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Supplier Complaint' : 'Create Supplier Complaint'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  const [isPartModal, setIsPartModal] = useState(false);
  const [render, setRender] = useState(false);
  const [vendorData, setVendorData] = useState([]);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/supplierComplaintRoutes/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);
  useEffect(() => {
    axios
      .get('/api/vendorRoute/vendor/forAutoComplete')
      .then((res) => {
        setVendorData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  function handleCustomerData(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }
  const handleSumbit = async () => {
    if (!data.vendor_id) {
      alert('Please enter Supplier name');
      return;
    }
    const ans = await confirmSubmit();
    if (!ans) return;

    setIsLoading(true);
    const formData = new FormData();

    if (typeof data.vendor_id === 'object') {
      data.vendor_id = data.vendor_id._id;
    }
    if (typeof data.ri_part_id === 'object') {
      data.ri_part_id = data.ri_part_id._id;
    }
    for (const key in data) {
      if (data[key] || typeof data[key] == 'boolean') {
        if (key !== 'files' && key !== 'images') {
          formData.append(key, data[key]);
        }
      }
    }

    if (files) {
      for (const key of Object.keys(files)) {
        formData.append('files', files[key]);
      }
    }

    if (id) {
      await axios
        .put(`/api/supplierComplaintRoutes/${id}`, formData)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/supplierComplaintRoutes', formData)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <Loader loader={isLoading} />
      <GridInputs
        id={'ref_no'}
        name={'ref_no'}
        html_for={'ref_no'}
        label_name={'Reference No. (PTDB / Issue log)'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.ref_no || ''}
        onChange={handleCustomerData}
      />
      <GridDatePicker
        label_name="Date of Reciept"
        html_for={'reciept_date'}
        value={data?.reciept_date || null}
        onChange={(date) => {
          data.reciept_date = date;
        }}
      />
      <AutocompleteGridmui
        id={'vendor_name'}
        label={'Supplier'}
        option_name={'vender_name'}
        arrayofObj={vendorData}
        value={data.vendor_name || data.vendor_id || ''}
        onChange={(e, value) => {
          if (value._id) data.vendor_id = value._id;
          if (value) data.vendor_name = value;
          setRender((prev) => !prev);
        }}
      />
      <DivStyled style={{ alignItems: 'center' }}>
        <label>{'Part No.'}</label>
        <div
          style={{
            borderLeft: '2px solid #ced4da',
            paddingLeft: '1rem',
            width: '100%',
          }}
        >
          <Grid container xs={12} direction="row" alignItems={'center'}>
            <Grid item xs={10}>
              <p
                style={{
                  fontSize: '1.6rem',
                }}
              >
                {typeof data?.ri_part_id === 'object' &&
                data?.ri_part_id !== null
                  ? data?.ri_part_id?.part_number
                  : data?.partNumber || ''}
              </p>
            </Grid>

            {data.ri_part_id ? (
              <Grid item xs={2}>
                <RemoveIconButton
                  tooltipTitle="remove part"
                  onClick={() => {
                    data.ri_part_id = null;
                    data.partNumber = null;
                    data.partName = null;

                    setRender((prev) => !prev);
                  }}
                />
              </Grid>
            ) : (
              <Grid item xs={2}>
                <AddIconButton
                  tooltipTitle="add part"
                  onClick={() => {
                    setIsPartModal(true);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </DivStyled>
      <DivStyled style={{ alignItems: 'center' }}>
        <label>{'Part Name'}</label>
        <div
          style={{
            borderLeft: '2px solid #ced4da',
            paddingLeft: '1rem',
            width: '100%',
          }}
        >
          <Grid container xs={12} direction="row" alignItems={'center'}>
            <Grid item xs={10}>
              <p
                style={{
                  fontSize: '1.6rem',
                }}
              >
                {typeof data?.ri_part_id === 'object' &&
                data?.ri_part_id !== null
                  ? data?.ri_part_id?.part_name
                  : data?.partName || ''}
              </p>
            </Grid>
          </Grid>
        </div>
      </DivStyled>
      <GridInputs
        id={'reject_qty'}
        name={'reject_qty'}
        html_for={'reject_qty'}
        label_name={'Reject Qty.'}
        input_type={'number'}
        focusType={'onblur'}
        value={data?.reject_qty || ''}
        onChange={handleCustomerData}
      />
      <StyledNotesField>
        <DescriptionTextfield
          name="rejection_detail"
          onChange={handleCustomerData}
          value={data?.rejection_detail || ''}
          label={'Rejection Detail :'}
        />
      </StyledNotesField>
      <StyledNotesField>
        <DescriptionTextfield
          name="containment_action_report"
          onChange={handleCustomerData}
          value={data?.containment_action_report || ''}
          label={'Containment Action Report Submission :'}
        />
      </StyledNotesField>
      <GridDatePicker
        label_name="Done On"
        html_for={'done_on'}
        value={data?.done_on || null}
        onChange={(date) => {
          data.done_on = date;
        }}
      />
      <GridSelect
        name="status_one"
        label_name="Status"
        html_for={'status_one'}
        value={data?.status_one || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Done">Done</MenuItem>
        <MenuItem value="Pending">Pending</MenuItem>
      </GridSelect>
      <GridSelect
        name="quality_alert"
        label_name="Quality Alert"
        html_for={'quality_alert'}
        value={data?.quality_alert || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </GridSelect>
      <StyledNotesField>
        <DescriptionTextfield
          name="root_cause_rejection"
          onChange={handleCustomerData}
          value={data?.root_cause_rejection || ''}
          label={'Root Cause For Rejection :'}
        />
      </StyledNotesField>
      <StyledNotesField>
        <DescriptionTextfield
          name="counter_measure_one"
          onChange={handleCustomerData}
          value={data?.counter_measure_one || ''}
          label={'Counter Measure :'}
        />
      </StyledNotesField>
      <GridDatePicker
        label_name="Capa report Planned for submission"
        html_for={'capa_report_planned'}
        value={data?.capa_report_planned || null}
        onChange={(date) => {
          data.capa_report_planned = date;
        }}
      />
      <GridDatePicker
        label_name="Actual Date of Submission"
        html_for={'actual_date_submission'}
        value={data?.actual_date_submission || null}
        onChange={(date) => {
          data.actual_date_submission = date;
        }}
      />
      <GridDatePicker
        label_name="Capa Planned"
        html_for={'capa_planned'}
        value={data?.capa_planned || null}
        onChange={(date) => {
          data.capa_planned = date;
        }}
      />
      <GridDatePicker
        label_name="Actual Completion date"
        html_for={'actual_completion_date'}
        value={data?.actual_completion_date || null}
        onChange={(date) => {
          data.actual_completion_date = date;
        }}
      />
      <GridSelect
        name="status_two"
        label_name="Status"
        html_for={'status_two'}
        value={data?.status_two || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Done">Done</MenuItem>
        <MenuItem value="Pending">Pending</MenuItem>
      </GridSelect>
      <GridSelect
        name="horizontal_deployment_planned"
        label_name="Horizontal Deployment Planned"
        html_for={'horizontal_deployment_planned'}
        value={data?.horizontal_deployment_planned || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </GridSelect>
      <h1>Quality documents Planned for change</h1>
      <GridSelect
        name="sop"
        label_name="SOP"
        html_for={'sop'}
        value={data?.sop || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </GridSelect>
      <GridSelect
        name="pfmea"
        label_name="PFMEA"
        html_for={'pfmea'}
        value={data?.pfmea || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </GridSelect>
      <GridSelect
        name="control_plan"
        label_name="CP"
        html_for={'control_plan'}
        value={data?.control_plan || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </GridSelect>
      <GridSelect
        name="pfd"
        label_name="PFD"
        html_for={'pfd'}
        value={data?.pfd || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </GridSelect>
      <GridSelect
        name="process_audit_checklist"
        label_name="Process Audit Checklist"
        html_for={'process_audit_checklist'}
        value={data?.process_audit_checklist || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </GridSelect>
      <GridSelect
        name="pdi_report"
        label_name="PDI Report"
        html_for={'pdi_report'}
        value={data?.pdi_report || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </GridSelect>
      <GridSelect
        name="system_procedure"
        label_name="System Procedure"
        html_for={'system_procedure'}
        value={data?.system_procedure || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </GridSelect>
      <GridSelect
        name="poka_yoke_list"
        label_name="Poka-Yoke List"
        html_for={'poka_yoke_list'}
        value={data?.poka_yoke_list || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </GridSelect>
      <h1></h1>
      <GridDatePicker
        label_name="Date Planned"
        html_for={'date_planned'}
        value={data?.date_planned || null}
        onChange={(date) => {
          data.date_planned = date;
        }}
      />
      <GridDatePicker
        label_name="Actual Date of Completion"
        html_for={'actual_date_completion'}
        value={data?.actual_date_completion || null}
        onChange={(date) => {
          data.actual_date_completion = date;
        }}
      />
      <GridSelect
        name="status_three"
        label_name="Status"
        html_for={'status_three'}
        value={data?.status_three || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Done">Done</MenuItem>
        <MenuItem value="Pending">Pending</MenuItem>
      </GridSelect>
      <GridSelect
        name="technical_know_how"
        label_name="Technical Know How"
        html_for={'technical_know_how'}
        value={data?.technical_know_how || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </GridSelect>
      <GridSelect
        name="eight_d"
        label_name="8D"
        html_for={'eight_d'}
        value={data?.eight_d || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </GridSelect>
      <h1>Effectiveness Monitoring</h1>
      <GridSelect
        name="jan"
        label_name="1"
        html_for={'jan'}
        value={data?.jan || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Not Repeated">Not Repeated</MenuItem>
        <MenuItem value="Repeated">Repeated</MenuItem>
      </GridSelect>
      <GridSelect
        name="feb"
        label_name="2"
        html_for={'feb'}
        value={data?.feb || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Not Repeated">Not Repeated</MenuItem>
        <MenuItem value="Repeated">Repeated</MenuItem>
      </GridSelect>
      <GridSelect
        name="mar"
        label_name="3"
        html_for={'mar'}
        value={data?.mar || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Not Repeated">Not Repeated</MenuItem>
        <MenuItem value="Repeated">Repeated</MenuItem>
      </GridSelect>
      <GridSelect
        name="apr"
        label_name="4"
        html_for={'apr'}
        value={data?.apr || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Not Repeated">Not Repeated</MenuItem>
        <MenuItem value="Repeated">Repeated</MenuItem>
      </GridSelect>
      <GridSelect
        name="may"
        label_name="5"
        html_for={'may'}
        value={data?.may || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Not Repeated">Not Repeated</MenuItem>
        <MenuItem value="Repeated">Repeated</MenuItem>
      </GridSelect>
      <GridSelect
        name="jun"
        label_name="6"
        html_for={'jun'}
        value={data?.jun || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Not Repeated">Not Repeated</MenuItem>
        <MenuItem value="Repeated">Repeated</MenuItem>
      </GridSelect>
      <h1></h1>
      <GridDatePicker
        label_name="Closed On"
        html_for={'closed_on'}
        value={data?.closed_on || null}
        onChange={(date) => {
          data.closed_on = date;
        }}
      />
      <GridInputs
        id={'monthly_verf_capa'}
        name={'monthly_verf_capa'}
        html_for={'monthly_verf_capa'}
        label_name={'Monthly Verification of Capa'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.monthly_verf_capa || ''}
        onChange={handleCustomerData}
      />
      <Grid item xs={3}>
        <CustomUploadButton
          lable={'Defect photo'}
          multiple
          accept="image/png,image/jpeg,image/jpg"
          onChange={(e) => {
            if (files) {
              setFiles([...files, ...e.target.files]);
            } else {
              setFiles(e.target.files);
            }
          }}
        />{' '}
      </Grid>
      <Grid item xs={3}>
        <CustomUploadButton
          lable={'Attach Supporting Doucments'}
          multiple
          accept="application/pdf,application/doc,application/excel/,application/ppt"
          onChange={(e) => {
            if (files) {
              setFiles([...files, ...e.target.files]);
            } else {
              setFiles(e.target.files);
            }
          }}
        />{' '}
      </Grid>
      <div>
        <Checkbox
          name="exclude_report"
          checked={data?.exclude_report}
          onChange={(e) => {
            setData((prev: any) => ({
              ...prev,
              [e.target.name]: e.target.checked,
            }));
          }}
        />

        <span
          style={{
            fontWeight: '500',
            fontSize: '20px',
          }}
        >
          Exclude from report
        </span>
      </div>

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
      <ModalCustom
        openModal={isPartModal}
        closeModal={() => {
          setIsPartModal(false);
        }}
        title="RI PART LIST"
      >
        <PickRiPart moduleData={data} />
      </ModalCustom>
    </>
  );
};

export default Create;
