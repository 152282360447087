// interface Ifoot {
//   preparedBy: string | null | undefined;
//   approvedBy: string | null | undefined;

import { useState } from "react";
import { TableInput } from "../../../../../components/common";

// }
const TFoot = ({ moduleData,isView}:any) => {
  const [render, setRender] = useState(false);
  return (
    <tfoot>
      <tr>
      <th colSpan={7} align="left">
      Prepared By:
            <TableInput
              name="prepared_by"
              type="text"
              value={moduleData.prepared_by}
              onChange={(e) => {
                moduleData.prepared_by = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </th>

          <th colSpan={6}  align="left">
          Verified By:
            <TableInput
              name="approved_by"
              type="text"
              value={moduleData.approved_by}
              onChange={(e) => {
                moduleData.approved_by = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </th>
      </tr>
      {/* <tr>
        <th colSpan={10} align="left">
          Revision History:- Tool Loading & un-loading date mention, Re-sharping
          Freq. added.
        </th>
      </tr> */}
      <tr>
        {/* <th colSpan={5} align="left">
          Prepared By: {preparedBy}
        </th>
        <th colSpan={5} align="left">
          Approved By: {approvedBy}
        </th> */}
      </tr>
      {/* <tr>
        <th style={{ background: '#40c057' }} colSpan={4}>
          GREEN (G)
        </th>
        <th style={{ background: '#ffe066' }} colSpan={3}>
          YELLOW (Y)
        </th>
        <th style={{ background: '#e03131' }} colSpan={3}>
          RED (R)
        </th>
      </tr> */}
      {/* <tr>
        <th colSpan={4}>Last time ok</th>
        <th colSpan={3}>Send for correction</th>
        <th colSpan={3}>Production stop and tool send for repair</th>
      </tr> */}
    </tfoot>
  );
};

export default TFoot;
