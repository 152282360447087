import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import {  MenuItem, SelectChangeEvent } from '@mui/material';
import { Loader } from '../../../components/common/Loader';
import DateGridMui from '../../../components/common/dateCustom/DateGridMui';
import moment from 'moment';
import GridSelect from '../../../components/common/inputs/GridSelect';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [data, setData] = useState<any>({
    exclude_report: false,
    files: [],
    images: [],
    retention_period:"Part Life + 11 Years for Maru - A Parts & Part Life +5 Years for General Parts"
  });
  const [files, setFiles] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Drawing Management' : 'Create Drawing Management'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  const [isPartModal, setIsPartModal] = useState(false);
  const [, setRender] = useState(false);
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/drawingManagementRoutes/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);
  useEffect(() => {
    axios
      .get(`/api/vendorRoute/vendor/forAutoComplete`)
      .then((res) => {
        setVendors(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  function handleCustomerData(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }
  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;

    setIsLoading(true);

    if (id) {
      await axios
        .put(`/api/drawingManagementRoutes/${id}`, data)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/drawingManagementRoutes', data)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <Loader loader={isLoading} />
      <GridInputs
        id={'doc_name'}
        name={'doc_name'}
        html_for={'doc_name'}
        label_name={'Document Name : '}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.doc_name || ''}
        onChange={handleCustomerData}
      />
        <GridInputs
        id={'doc_no'}
        name={'doc_no'}
        html_for={'doc_no'}
        label_name={'Document No. : '}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.doc_no || ''}
        onChange={handleCustomerData}
      />
         <GridInputs
        id={'issue_no'}
        name={'issue_no'}
        html_for={'issue_no'}
        label_name={'Latest Issue No. : '}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.issue_no || ''}
        onChange={handleCustomerData}
      />
      
      <GridInputs
        id={'retention_period'}
        name={'retention_period'}
        html_for={'retention_period'}
        label_name={'Retention Period  : '}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.retention_period || ''}
        onChange={handleCustomerData}
      />
       <h1>Issue No. / Issue date</h1>
       {[
       {label: '00',name:"zero"},
       {label: '01',name:"one"},
       {label: '02',name:"two"},
       {label: '03',name:"three"},
       {label: '04',name:"four"},
       {label: '05',name:"five"},
       {label: '06',name:"six"},
       {label: '07',name:"seven"},
       {label: '08',name:"eight"},
       {label: '09',name:"nine"},
       {label: '10',name:"ten"}
      ].map((issue, index) => (
        <DateGridMui
        lable={issue.label}
        onChange={(date: any) => {
          data[`issue_date_${issue.name}`] = date;
          setRender((prev) => !prev);
        }}
        value={(data[`issue_date_${issue.name}`] && moment(data[`issue_date_${issue.name}`])) || null}
      />
      ))}
  
      <h1>Distribution</h1>
      {[
       {label: '0',name:"zero"},
       {label: '1',name:"one"},
       {label: '2',name:"two"},
       {label: '3',name:"three"},
       {label: '4',name:"four"},
       {label: '5',name:"five"},
       {label: '6',name:"six"},
       {label: '7',name:"seven"},
       {label: '8',name:"eight"},
       {label: '9',name:"nine"},
       {label: '10',name:"ten"}
      ].map((dist_num, index) => (
        <GridSelect
          key={index + dist_num.label}
          name={`distribution_${dist_num.name}`}
          label_name={dist_num.label}
          html_for={`distribution_${dist_num.name}`}
          value={data?.[`distribution_${dist_num.name}`] || ''}
          onChange={handleCustomerData}
        >
          <MenuItem value="master copies">Master Copies</MenuItem>
          <MenuItem value="control copies">Control  Copies </MenuItem>
        </GridSelect>
      ))}

     

      {/* <div>
        <Checkbox
          name="exclude_report"
          checked={data?.exclude_report}
          onChange={(e) => {
            setData((prev: any) => ({
              ...prev,
              [e.target.name]: e.target.checked,
            }));
          }}
        />

        <span
          style={{
            fontWeight: '500',
            fontSize: '20px',
          }}
        >
          Exclude from report
        </span>
      </div> */}

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
