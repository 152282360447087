import { useState } from 'react';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import { AddIconButton } from '../../../../../components/common/button/CustomIconButton';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';
import formatDate from '../../../../../components/common/formatDate';

interface IProps {
  moduleData: any;
  isView: boolean;
  setIsOpenPopupRejections: any;
  setRejectionsIndx: any;
  operators: any;
  machineData: any;
}

const TBody = ({
  moduleData,
  isView,
  setIsOpenPopupRejections,
  setRejectionsIndx,
  operators,
  machineData,
}: IProps) => {
  const [render, setRender] = useState(false);
  const [totalScrap, setTotalScrap] = useState(0);

  const updateQuantities = (startIndex:number) => {
    for (let i = startIndex; i < moduleData.processes.length-1; i++) {
      const currProcess = moduleData.processes[i];
      const nextProcess = moduleData.processes[i + 1];

      // Update the subsequent moduleData.processes
        nextProcess.produce_qty = currProcess.ok_qty;
        nextProcess.ok_qty = nextProcess.produce_qty - (nextProcess.reject_qty || 0);
    }
    //setRender((prev) => !prev);
  };

  return (
    <>
      <tbody>
        {moduleData &&
          moduleData?.processes.map((process: any, index: number) => (
            <>
              <tr>
                <td style={{ minWidth: '160px' }}>{process?.dept}</td>
                <td style={{ width: '170px', minWidth: '170px' }}>
                  {isView ? (
                    process.date && formatDate(process.date)
                  ) : (
                    <DatePickerMui
                      label=""
                      value={(process.date && moment(process.date)) || null}
                      onChange={(date: any) => {
                        process.date = date;
                        setRender((prev) => !prev);
                      }}
                    />
                  )}
                </td>
                <td style={{ minWidth: '200px' }}>
                  {isView ? (
                    process?.machine_id?.machine_no
                  ) : (
                    <AutocompleteMuiCustom
                      id="machine_no"
                      label=""
                      option_name="machine_no"
                      value={process?.machine_id || ''}
                      arrayofObj={machineData}
                      onChange={(e: any, value: any) => {
                        process.machine_id = value;
                        setRender((prev) => !prev);
                      }}
                    />
                  )}
                </td>
                <td style={{ minWidth: '200px' }}>
                  {isView ? (
                    process?.operator_id?.operator_name
                  ) : (
                    <AutocompleteMuiCustom
                      id="operator_name"
                      label=""
                      option_name="operator_name"
                      value={process?.operator_id || ''}
                      arrayofObj={operators}
                      onChange={(e: any, value: any) => {
                        process.operator_id = value;
                        setRender((prev) => !prev);
                      }}
                    />
                  )}
                </td>
                <td>
                  {index === 0 ? (
                    !isView ? (
                      <TableInput
                        isView={isView}
                        name="produce_qty"
                        type="number"
                        value={process.produce_qty}
                        onChange={(e) => {
                          handleChangeModuleData(e, process);
                          process.ok_qty =
                            (process.produce_qty || 0) - (process.reject_qty || 0);
                            updateQuantities(index);
                          setRender((prev) => !prev);
                        }}
                      />
                    ) : (
                        process.produce_qty
                    )
                  ) : (
                    process.produce_qty
                  )}
                </td>
                <td>{process.reject_qty}</td>
                <td>{process.ok_qty}</td>
                <td
                  style={{
                    width: '300px',
                    minWidth: '300px',
                  }}
                >
                  <div
                    style={{
                      ...(!isView && { display: 'flex' }),
                      ...(!isView && { justifyContent: 'space-between' }),
                    }}
                  >
                    <div style={{ border: 'none' }}>
                      {process?.reasons_for_rej?.map((item: any) => {
                        return item?.reason ? (
                          <span>
                            {item?.reason} - {item?.rej_qty}
                            <br />
                            <hr />
                          </span>
                        ) : null;
                      })}
                    </div>
                    {!isView && (
                      <AddIconButton
                        tooltipTitle="add reasons and qty"
                        onClick={() => {
                          setIsOpenPopupRejections(true);
                          setRejectionsIndx(index);
                        }}
                      />
                    )}
                  </div>
                </td>
                <td style={{ minWidth: '100px' }}>
                  <TableInput
                    isView={isView}
                    name="supervisor_sign"
                    type="text"
                    value={process.supervisor_sign}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
                <td style={{ minWidth: '100px' }}>
                  <TableInput
                    isView={isView}
                    name="remarks"
                    type="text"
                    value={process.remarks}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
              </tr>
            </>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
