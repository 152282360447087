import React, { useState } from 'react';
import { Checkbox, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// import Add from '@mui/icons-material/Add';
import StateCustomTable from '../../../../../components/common/StateCustomTable';
import {
  CustomPagination,
  // StatePagination,
  TableCustom,
} from '../../../../../components/common';
// import formatDate from '../../../../../components/common/formatDate';
// import { CustomIconButton } from '../../../../../components/common/button/CustomIconButton';
import useDebounce from '../../../../../hooks/useDebounce';
// import { useQuery } from '../../../../../hooks/UseQuery';

const tableHeaderObj = [
  [
    { text: 'S.No.', rowspan: 1, colspan: 1 },
    { text: 'Key Spares', rowspan: 1, colspan: 1 },
    { text: 'Consumption (6Months)', rowspan: 1, colspan: 1 },
    { text: 'UOM', rowspan: 1, colspan: 1 },
    { text: 'Points', rowspan: 1, colspan: 1 },
    { text: 'Cost (Rs)', rowspan: 1, colspan: 1 },
    { text: 'Points', rowspan: 1, colspan: 1 },
    { text: 'Lead time for Spares Procurement', rowspan: 1, colspan: 1 },
    { text: 'Points', rowspan: 1, colspan: 1 },
    { text: 'score', rowspan: 1, colspan: 1 },
    { text: 'Critical', rowspan: 1, colspan: 1 },
    { text: 'Remarks', rowspan: 1, colspan: 1 },
    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [
  { value: 'createdAt', name: 'Most Recent' },
  { value: 'spare_part_desc', name: 'A-Z' },
];

const SparePartList = ({
  link,
  type,
  sparePartIds,
  handleUpdateSparePart,
}: {
  link?: string;
  type?: string;
  handleUpdateSparePart?: (id: string) => void;
  sparePartIds?: string[];
}) => {
  const [page, setPage] = useState(1);
  const [partData, setPartData] = useState<any>([]);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [filter, setFilter] = useState<string>('createdAt');
  const navigate = useNavigate();

  const fetchPartData = () => {
    axios
      .get(
        `/api/criticalSparePartsRoute/?page=${page}&sortBy=${filter}&search=${search}&perPage=${10}`
      )
      .then((res) => {
        setPerPage(res.data?.perPage);
        setPartData(res.data?.results);
        setTotalPages(res.data?.pages);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchPartData, 300, [page, filter, search]);
  return (
    <>
      <StateCustomTable
        filterOptions={filterOptions}
        {...{ search, setSearch, filter, setFilter }}
        sx={{ width: '100%', maxWidth: '100%' }}
        label="Search Spare Part "
      >
        <TableCustom tableHeaderObj={tableHeaderObj}>
          <TableBody>
            {partData &&
              partData.map((item: any, index: number) => (
                <React.Fragment key={item?._id}>
                  <TableRow>
                    <TableCell align="center">
                      {perPage * (+page - 1) + index + 1}
                    </TableCell>
                    <TableCell align="center">
                      {item?.spare_part_name}
                    </TableCell>
                    <TableCell align="center">{item?.consumption}</TableCell>
                    <TableCell align="center">{item?.uom}</TableCell>
                    <TableCell align="center">{item?.points_1}</TableCell>
                    <TableCell align="center">{item?.cost}</TableCell>
                    <TableCell align="center">{item?.points_2}</TableCell>
                    <TableCell align="center">
                      {item?.lead_time_for_spare_procurment}
                    </TableCell>
                    <TableCell align="center">{item?.points_3}</TableCell>
                    <TableCell align="center">{item?.score}</TableCell>
                    <TableCell align="center">{item?.critical}</TableCell>
                    <TableCell align="center">{item?.remarks}</TableCell>
                    <TableCell align="center">
                      {sparePartIds && handleUpdateSparePart && (
                        <Checkbox
                          checked={sparePartIds.includes(item._id || '')}
                          onChange={() => handleUpdateSparePart(item._id || '')}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </TableCustom>
        <CustomPagination totalPage={totalPages} />
      </StateCustomTable>
    </>
  );
};

export default SparePartList;
