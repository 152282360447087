import lodash from 'lodash';

const common = {
  status: '',
  specification: '',
  observation: [...lodash.cloneDeep(Array.from({ length: 6 }).map(() => ''))],
};

export const processes_three = [
  'TEMP. UPPER',
  'TEMP. LOWER',
  'CLIP SENSOR POSITION(as pr std.)',
  'CURING TIME',
  'PRESSURE',
  'NO MOULDING DEFECTS',
  'COMPOUND GRADE',
  'NO FLASHES',
].map((parameter) => {
  return { ...lodash.cloneDeep(common), parameter, machine: '' };
});

export const processes_four = [
  'NO DEEP TRIMMING, NO FLASHES',
  'ENSURE SCISSOR COLOUR  , LIMIT SAMPLE & 5S STATUS',
].map((specification) => {
  return { ...lodash.cloneDeep(common), specification };
});

export const processes_five = [
  'OUTSERT',
  'NO TRIMMING, CLIPPING, MOULDING DEFECTS',
  'FITTMENT/LENGTH INSPECTION ON GAUGE',
  'LIMIT SAMPLE , MASTER SAMPLE , TAGS , 5S & REPORT AVAILABLE / STATUS',
].map((specification) => {
  return { ...lodash.cloneDeep(common), specification };
});

export const processes_six = [
  'PADDING',
  'JOINT CLIPPING',
  'NORSEAL',
  'CORNER CLIPING',
  'SILICON COATING',
  'TAPING',
].map((heading, index) => {
  return { ...lodash.cloneDeep(common), heading, status: '' };
});

export const processes_seven = ['PUNCHING / BUFFING'].map((heading, index) => {
  return {
    ...lodash.cloneDeep(common),
    heading,
    status: '',
    specification: '1.NO TAPER, NO BURR 2.DIMENSION',
  };
});

export const initialState = {
  moduleDate: new Date(),
  module: '',
  shift: '',
  part_id:'',
  // part_id:{
  //   part_number:'',
  //   model_id:{
  //     model_name:'',
  //   },
  //   customer_id:{
  //     customer_name:'',
  //   }
  // },
  report_prepared_by: [
    ...lodash.cloneDeep(Array.from({ length: 7 }).map(() => '')),
  ],
  report_approved_by: [
    ...lodash.cloneDeep(Array.from({ length: 7 }).map(() => '')),
  ],

  processes_one: {
    heading: 'RESIZING/PUNCHING',
    date: [...lodash.cloneDeep(Array.from({ length: 6 }).map(() => null))],
    time: [...lodash.cloneDeep(Array.from({ length: 6 }).map(() => null))],
    processes: Array.from({ length: 5 }).map((_, index) => {
      if (index == 4) {
        return {
          ...lodash.cloneDeep(common),
          section: '',
          specification: 'ENSURE PENCIL (WHITE/ YELLOW), CLIP HOLDER,5S',
        };
      }
      return {
        ...lodash.cloneDeep(common),
        section: '',
      };
    }),
  },
  processes_two: {
    heading: 'CLIPPING',
    date: [...lodash.cloneDeep(Array.from({ length: 6 }).map(() => null))],
    time: [...lodash.cloneDeep(Array.from({ length: 6 }).map(() => null))],
    processes: Array.from({ length: 5 }).map((_, index) => {
      if (index == 4) {
        return {
          ...lodash.cloneDeep(common),
          section: '',
          specification:
            'ENSURE CLIP , CLIP HOLDER , SOAP SOLUTION & 5S STATUS',
        };
      }
      return {
        ...lodash.cloneDeep(common),
        section: '',
        specification: 'CLIP QUANTITY & COLOUR AS PER WI',
      };
    }),
  },
  processes_three: {
    heading: 'MOULDING',
    date: [...lodash.cloneDeep(Array.from({ length: 6 }).map(() => null))],
    time: [...lodash.cloneDeep(Array.from({ length: 6 }).map(() => null))],
    processes: [...lodash.cloneDeep(processes_three)],
  },
  processes_four: {
    heading: 'TRIMMING',
    date: [...lodash.cloneDeep(Array.from({ length: 6 }).map(() => null))],
    time: [...lodash.cloneDeep(Array.from({ length: 6 }).map(() => null))],
    processes: [...lodash.cloneDeep(processes_four)],
  },
  processes_five: {
    heading: 'FINAL INSPECTION',
    date: [...lodash.cloneDeep(Array.from({ length: 6 }).map(() => null))],
    time: [...lodash.cloneDeep(Array.from({ length: 6 }).map(() => null))],
    processes: [...lodash.cloneDeep(processes_five)],
  },
  processes_six: {
    processes: [...lodash.cloneDeep(processes_six)],
  },
  processes_seven: {
    processes: [...lodash.cloneDeep(processes_seven)],
  },
  image: [],
};
