import moment from 'moment';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { useState } from 'react';
import { TimePicker } from '@mui/x-date-pickers';
import { convToTime } from '../../../../../utils/helpers';

interface IProps {
  headings: any;
  process: any;
  isView: any;
  x: Number;
}

const THeadRow = ({ headings, process, isView,x }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <>
      {headings?.map((subHeading: any, index: any) => {
        // console.log("subHeading is",subHeading);
        return (
          <tr style={{ background: '#748ffc' }} key={index + 'subHeading'}>
            {subHeading?.map((head: any, index: number) => {
              if (head.text === 'DATES' && x === 1) {
                return process?.date.map((d: any, dateIndex: any) => (
                  <th key={dateIndex} style={{ minWidth: '150px' }}>
                    {isView ? (
                      formatDate(process?.date[dateIndex])
                    ) : (
                      <DatePickerMui
                        // value={moment(process?.date[dateIndex])}
                        value={
                          dateIndex === 0
                            ? process?.date?.[dateIndex] || moment()
                            : moment(process?.date?.[dateIndex])
                        }
                        onChange={(date) => {
                          process.date[dateIndex] = date;
                          setRender((prev) => !prev);
                        }}
                      />
                    )}
                  </th>
                ));
              } else if (head.text === 'TIMES') {
                return process?.time.map((t: any, timeIndex: any) => (
                  <th key={timeIndex} style={{ minWidth: '150px' }}>
                    {isView ? (
                     process?.time?.[timeIndex] && convToTime(process?.time?.[timeIndex])
                    ) : (
                      <TimePicker
                        value={
                          (process?.time?.[timeIndex] &&
                            moment(process?.time?.[timeIndex])) ||
                          null
                        }
                        onChange={(date) => {
                          process.time[timeIndex] = date;
                          setRender((prev: any) => !prev);
                        }}
                      />
                    )}
                  </th>
                ));
              } 
              else if (head.text !== 'DATES' && head.text !== 'TIMES') {
                return (
                  <th
                    key={index}
                    rowSpan={head?.rowspan}
                    colSpan={head?.colspan}
                  >
                    {head?.text}
                  </th>
                );
              }
              // else {
              //   return (
                 
              //     <th
              //       key={index}
              //       rowSpan={head?.rowspan}
              //       colSpan={head?.colspan}
              //     >
              //       {head?.text}
              //     </th>
              //   );
              // }
            })}
          </tr>
        );
      })}
    </>
  );
};


export default THeadRow;
