import moment from 'moment';
import lodash from 'lodash';

export const process = {
  target_qty:null,
  // produced_qty:null,
  check_qty:null,
  ok_qty:null,
  reasons_for_rej: [],
  scrap_aa_qty:null,
  scrap_bb_qty:null,
  scrap_cc_qty:null,
  scrap_dd_qty:null,
}

export const shiftA = [
  {
    time:'8 AM - 10 AM',
    ...process
  },
  {
    time:'10 AM - 12 PM',
    ...process
  },
  {
    time:'12 PM - 2 PM',
    ...process
  },
  {
    time:'2 PM - 4 PM',
    ...process
  },
]
export const shiftB = [
  {
    time:'4 PM - 6 PM',
  },
  {
    time:'6 PM - 8 PM',
  },
  {
    time:'8 PM - 10 PM',
  },
  {
    time:'10 PM - 12 AM',
  },
]

export const shiftC = [
  {
    time:'12 AM - 2 AM',
  },
  {
    time:'2 AM - 4 AM',
  },
  {
    time:'4 AM - 6 AM',
  },
  {
    time:'6 AM - 8 AM',
  },
];


export const initialState = {
  part_id: null,
  final_inspection_report_date: null,
  shift:"A",
  shift_engr_name:null,
  processes: shiftA,
  total_reason_qty:null,
  // total_produced_qty:null,
  total_target_qty:null,
  total_ok_qty:null,
  total_check_qty:null,
  total_rework_qty:null
};
