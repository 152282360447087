// TableRowComponent.jsx
import React from 'react';
import { Checkbox } from '@mui/material';
import lodash from 'lodash';
import TBodyRight from './TBodyRight';
import formatDate from '../../../../../components/common/formatDate';
import { TableInput } from '../../../../../components/common';
import { useQuery } from '../../../../../hooks/UseQuery';
import AutocompleteMuiCustomRevised from '../../../../../components/common/tableinputs/AutocompleteMuiCustomRevised';
const areEqual = (prevProps: any, nextProps: any) => {
  // Check if the item prop has changed using a deep comparison
  const itemEqual = lodash.isEqual(prevProps.item, nextProps.item);

  // Check if the selection state for the current index has changed
  const selectionEqual =
    prevProps.selectionArray[prevProps.index] ===
    nextProps.selectionArray[nextProps.index];

  // Check if the index has changed
  const indexEqual = prevProps.index === nextProps.index;

  // Only return true (indicating the component should NOT update)
  // if all conditions are met (item, selection state, and index are equal)
  return itemEqual && selectionEqual && indexEqual;
};

export const TableRowComponent = React.memo(
  ({
    key,
    item,
    index,
    allmonth,
    updateModuleData,
    selectionArray,
    handleBulkDeleteFeature,
    autoCompleteData,
    settings,
    perPage,
    page,
  }: any) => {
    const isView = useQuery('isView') === 'true' ? true : false;
    const rowId = item?._id || item?._tempId;
    const isChecked = selectionArray[index] === rowId;

    const handleInputChange = (
      fieldName: string,
      value: string | number | null
    ) => {
      updateModuleData(index, fieldName, value);
    };

    const handleAutocompleteChange = (newValue: any) => {
      // Update a1, a2, and row_id fields in the row use null always
      updateModuleData(index, 'a1', newValue?.id_no || null);
      updateModuleData(index, 'a2', newValue?.instrument_type?.name || null);
      updateModuleData(index, 'a3', newValue?.location?.name || null);
      updateModuleData(index, 'a4', newValue?.frequency_in_year || null);
      updateModuleData(index, 'a5', newValue?.calibration_done_date || null);

      // row id should  never be changed and it is populated id and you can assign value to a1 a2 from this row id and it is fallback value
      updateModuleData(index, 'row_id', newValue);
    };

    const handleCheckboxChange = (event: any) => {
      handleBulkDeleteFeature(index, event.target.checked, rowId);
    };

    const stickStylesColumn = {
      position: 'sticky',
      border: '1px solid black',
      zIndex: '1',
      backgroundColor: '#ffec99',
      // color: '#fff',
  } as const;
    return (
      <React.Fragment key={key}>
        <tr>
          <td rowSpan={2}
            style={{
              ...stickStylesColumn,
              left: 0,
              width: '50px',
              minWidth: '50px',
          }}
          >{perPage * (+page - 1) + index + 1}</td>
          {/* <td rowSpan={2}>{index + 1}</td> */}
          <td rowSpan={2}
          
          style={{
            ...stickStylesColumn,
            left: '50px',
            width: '50px',
            minWidth: '50px',
        }}
          >
            {item?._id && item?.row_id === null && item?.a1 ? (
              item?.a1
            ) : (
              <AutocompleteMuiCustomRevised
                id="instrument id"
                label=""
                option_name="id_no"
                key={'id_no'}
                arrayofObj={autoCompleteData}
                value={item?.row_id}
                onChange={(_, newValue: any) =>
                  handleAutocompleteChange(newValue)
                }
                sx={{ width: '200px' }}
              />
            )}
          </td>

          <td rowSpan={2}
            style={{
              ...stickStylesColumn,
              left: '256px',
              width: '50px',
              minWidth: '50px',
          }}
          >{item?.[`a2`]}</td>
          <td rowSpan={2}

style={{
  ...stickStylesColumn,
  left: '416px',
  width: '50px',
  minWidth: '50px',
}}
          >{item?.[`a3`]}</td>
          <td rowSpan={2}>{item?.[`a4`]}</td>
          <td rowSpan={2}>{formatDate(item?.[`a5`])}</td>
          <td rowSpan={2}>{formatDate(item?.[`a6`])}</td>
          <td>P</td>

          {/* always constant ⬇️*/}
          <TBodyRight
            item={item}
            isView={isView}
            index={index}
            allmonth={allmonth}
            updateModuleData={updateModuleData}
          />
          {/* 👉 */}

          <td rowSpan={2}>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="remarks"
              type="text"
              value={item.remarks}
              onChange={(e: any) =>
                handleInputChange('remarks', e.target.value)
              }
            />
          </td>
          <td rowSpan={2}>
            <Checkbox
              style={{ color: '#fa5252' }}
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
          </td>
        </tr>
        <tr>
          <td>A</td>
          {allmonth.map((month: any, mIdx: number) => (
            <td
              width="80px"
              height="45px"
              style={{
                backgroundColor: item[month].doneDate && '#37b24d',
                color: 'white',
                textTransform: 'capitalize',
                fontSize: '1.6rem',
              }}
            >
              {settings.show_A && item[month].isTask && 'A'}
              {(settings.show_date && formatDate(item[month].doneDate)) || null}
            </td>
          ))}
        </tr>
        {/* ---x---- */}
      </React.Fragment>
    );
  },
  (prevProps, nextProps) => areEqual(prevProps, nextProps)
);
