import React, { useState } from 'react';
import { allmonth } from '../../helpers/utils';
import { TableRowComponent } from './TableRowComponent';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';

interface IProps {
  moduleData: any;

  updateModuleData: any;
  machineData?: any;
}
const TBody = ({ moduleData, updateModuleData }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      {moduleData?.list?.map((item: any, index: any) => (
        <TableRowComponent
          key={item?._id || item?._tempId}
          {...{
            item,
            index,
            allmonth,
            updateModuleData,
          }}
        />
      ))}
      {/* <tr style={{ background: "brown", color: "white" }}>
        <th colSpan={16} align="center" >
          ACTION PLAN FOR OBSERVATION
        </th>
      </tr>
      <tr style={{ background: "rgb(79, 129, 189)", color: "white" }}>
        {['S.NO.', 'DATE', 'OBSERVATION', 'COUNTER MEASURE', 'RESP.', 'TARGET DATE', 'STATUS'].map(
          (heading, headIndx) => (
            <th key={heading} colSpan={headIndx == 6 ? 3 : 2}>
              {heading}
            </th>
          )
        )}
      </tr> */}

      {/* {moduleData?.action_plan?.map((actionPlan: any, index: number) => (
        <tr>
          <td colSpan={2}>{index + 1}</td>
          <td colSpan={2}>

            <DatePickerMui
              value={moment(actionPlan.rev_date)}
              onChange={(date) => {
                actionPlan.date = date;
                setRender((prev) => !prev);
              }}
            />

          </td>
          <td colSpan={2} style={{ width: '120px' }}>
            <TableInput
              name="observation"
              type="text"
              value={actionPlan.observation}
              onChange={(e) => {
                actionPlan.observation = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={false}
            />
          </td>

          <td colSpan={2} style={{ width: '120px' }}>
            <TableInput
              name="countermeasure"
              type="text"
              value={actionPlan.countermeasure}
              onChange={(e) => {
                actionPlan.countermeasure = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={false}
            />
          </td>
          <td colSpan={2} style={{ width: '120px' }}>
            <TableInput
              name="resp"
              type="text"
              value={actionPlan.resp}
              onChange={(e) => {
                actionPlan.resp = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={false}
            />
          </td>
          <td colSpan={2} style={{ width: '250px' }}>

            <DatePickerMui
              value={moment(actionPlan.target_date)}
              onChange={(date) => {
                actionPlan.target_date = date;
                setRender((prev) => !prev);
              }}
            />

          </td>
          <td colSpan={3} style={{ width: '120px' }}>
            <TableInput
              name="status"
              type="text"
              value={actionPlan.status}
              onChange={(e) => {
                actionPlan.status = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={false}
            />
          </td>
        </tr>
      ))} */}

    </tbody>
  );
};

export default React.memo(TBody);
