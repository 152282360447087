import { Send } from '@mui/icons-material';
import { useState } from 'react';
import { CustomButton } from '../../../../components/common/button/CustomButtons';
import GridDatePicker from '../../../../components/common/inputs/GridDatePicker';

const FromAndToDate = ({ sendToRC }: any) => {
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  return (
    <div>
      <GridDatePicker
        label_name="From Date:"
        html_for={'from'}
        value={from || null}
        onChange={(date: any) => {
          setFrom(date);
        }}
      />
      <GridDatePicker
        label_name="To Date:"
        html_for={'to'}
        value={to || null}
        onChange={(date: any) => {
          setTo(date);
        }}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '150px',
          top: '1rem',
        }}
      >
        <CustomButton
          sx={{
            backgroundColor: '#1c7ed6',
          }}
          onClick={() => {
            if (!from || !to) return alert('please choose from and to date');
            sendToRC(from, to);
          }}
          icon={<Send />}
        >
          Send to RC
        </CustomButton>
      </div>
    </div>
  );
};

export default FromAndToDate;
