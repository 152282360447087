// import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../components/ui/TableCompHead';
// import { TableInput } from '../../../../components/common';
// import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableInput } from '../../../../components/common';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { IndexAdmin } from '../../../../routes/IndexAdmin';
// import { convToTime } from '../../../../utils/helpers';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const theadData = [
  'S. NO.',
  'PROCESS DESCRIPTION',
  'ITEM',
  'SPECIFICATION',
  'OBSERVAION',
  'STATUS',

  'REMARKS',
];

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <thead>
      <TableCompHead
        colSpan={12}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading="SAMPLE/ LAYOUT/REVALIDATION  INSPECTION REPORT"
      />
      <tr>
        <th colSpan={1}>CUSTOMER NAME: </th>
        <th colSpan={3}>
          {
            <TableInput
              isView={isView}
              name="customer_name"
              type="text"
              value={moduleData.customer_name}
              onChange={(e) => {
                moduleData.customer_name = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          }
        </th>
        <th colSpan={1}>E/C LEVEL: </th>
        <th colSpan={3}>
          {
            <TableInput
              isView={isView}
              name="ec_level"
              type="text"
              value={moduleData.ec_level}
              onChange={(e) => {
                moduleData.ec_level = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          }
        </th>

        <th colSpan={1}>Date: </th>
        <th colSpan={3}>
          {' '}
          {isView ? (
            formatDate(moduleData.date)
          ) : (
            <DatePickerMui
              label="Date"
              value={moment(moduleData.date)}
              onChange={(date) => {
                moduleData.date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th>PART NAME:</th>
        <th colSpan={3}>
          {
            <TableInput
              isView={isView}
              name="stage"
              type="text"
              value={moduleData.part_name}
              onChange={(e) => {
                moduleData.part_name = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          }
        </th>
        <th>DRR. NO.:</th>
        <th colSpan={3}>
          {
            <TableInput
              isView={isView}
              name="stage"
              type="text"
              value={moduleData.drr_no}
              onChange={(e) => {
                moduleData.drr_no = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          }
        </th>{' '}
        <th>MODEL:</th>
        <th colSpan={3}>
          {
            <TableInput
              isView={isView}
              name="stage"
              type="text"
              value={moduleData.model}
              onChange={(e) => {
                moduleData.model = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          }
        </th>
      </tr>
      <tr>
        <th>PART NO.:</th>
        <th colSpan={3}>
          {
            <TableInput
              isView={isView}
              name="part_number"
              type="text"
              value={moduleData.part_number}
              onChange={(e) => {
                moduleData.part_number = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          }
        </th>
        <th>CODE:</th>
        <th colSpan={3}>
          {
            <TableInput
              isView={isView}
              name="alp_code"
              type="text"
              value={moduleData.alp_code}
              onChange={(e) => {
                moduleData.alp_code = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          }
        </th>{' '}
        <th>VENDOR CODE:</th>
        <th colSpan={3}>
          {
            <TableInput
              isView={isView}
              name="vendor_code"
              type="text"
              value={moduleData.vendor_code}
              onChange={(e) => {
                moduleData.vendor_code = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          }
        </th>
      </tr>
      <tr>
        {theadData.map((heading, index) => (
          <th
            key={heading + index}
            colSpan={
              heading === 'REMARKS' ? 2 : heading === 'OBSERVAION' ? 5 : 1
            }
            rowSpan={heading === 'OBSERVAION' ? 1 : 2}
          >
            {heading}
          </th>
        ))}
      </tr>

      <tr>
        {Array.from({ length: 5 }).map((_, index) => (
          <th key={'OBSERVATION' + index}>{index + 1}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
