import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { useQuery } from '../../../../../hooks/UseQuery';
interface IProps {
  moduleData: any;
  isView: boolean;
}

const theadData = [
  'S. No.',
  'Final Inspection Date',
  'Shift',
  'Product Name',
  'Reason',
  'Remarks',
  'Break Down Mins',
  'SIGN. PROD. ENGG.',
  'SIGN . MAINT. ENGG.',
  'SIGN QA ENGG.',
  'Delete',
];

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const date = useQuery('annual_date');

  return (
    <thead>
      <TableCompHead
        colSpan={12}
        rowSpan={3}
        labels={['DOC NO.', 'REV.NO.', 'DATE']}
        values={['HO-F-PRD-04', '02', '01.06.2014']}
        heading={`BREAK DOWN STATUS (MONTH: ${date})`}
        style={{ background: '#a9e34b' }}
      />
      <tr style={{ background: '#38d9a9' }}>
        {theadData.map((heading, index) => (
          <th
            key={heading + index}
            style={{ minWidth: '100px' }}
            colSpan={index == 6 ? 2 : 1}
            rowSpan={index == 6 ? 1 : 2}
          >
            {heading}
          </th>
        ))}
      </tr>
      <tr style={{ background: '#38d9a9' }}>
        <th>Today</th>
        <th>MTD</th>
      </tr>
    </thead>
  );
};

export default THead;
