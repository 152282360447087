import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import ObservationInput from './ObservationInput';
import CustomSelect from '../../../../components/common/SelectField';
import { Checkbox, MenuItem } from '@mui/material';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      {moduleData.processes &&
        moduleData.processes.map((spec: any, index: number) => (
          <tr key={index + 'specs'}>
            <td>{index + 1}</td>

            <td>{spec.parameter}</td>
            <td>{spec.specification}</td>
            <td>{spec.measurement_value}</td>
            {Array.from({ length: 5 }, (_, obsIndex) => (
              <td
                key={obsIndex + 'obs'}
                style={{
                  textTransform: 'uppercase',
                  // background: obsIndex % 2 ? 'white' : '#63c4ef4d',
                }}
              >
                <ObservationInput
                  {...{ spec, setRender, isView, obsIndex }}
                  obsNumber={0}
                  checkType={spec.appearance}
                />
              </td>
            ))}
            <td style={{ textTransform: 'uppercase', width: '150px' }}>
              {spec.appearance === 'check3' ? (
                isView ? (
                  spec.result
                ) : (
                  <CustomSelect
                    name="dropdown"
                    value={spec.result || ''}
                    onChange={(e) => {
                      spec.result = e.target.value;
                      setRender((prev) => !prev);
                    }}
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <MenuItem value="">NONE</MenuItem>
                    <MenuItem value="OK">OK</MenuItem>
                    <MenuItem value="NOT OK">NOT OK</MenuItem>
                  </CustomSelect>
                )
              ) : (
                spec.result
              )}
            </td>
            <td colSpan={isView ? 2 : 1} style={{ width: '100px' }}>
              <TableInput
                isView={isView}
                name="remarks"
                type="text"
                value={spec.remarks}
                onChange={(e) => {
                  spec.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            {!isView && (
              <td style={{ width: '100px' }}>
                <Checkbox
                  name="addToRevisionSummary"
                  checked={spec.appearance === 'check3'}
                  onChange={() => {
                    if (spec.prev_appearance) {
                      spec.appearance = spec.prev_appearance;
                      spec.prev_appearance = null;
                    } else {
                      spec.prev_appearance = spec.appearance;
                      spec.appearance = 'check3';
                    }
                    setRender((prev) => !prev);
                  }}
                  style={{ color: '#064a29' }}
                />
              </td>
            )}
          </tr>
        ))}
      <tr>
        <th colSpan={6} align="left">
          Inspected By: {moduleData.report_prepared_by}
        </th>
        <th colSpan={6} align="left">
          Approved By:
          {moduleData.report_approved_by}
        </th>
      </tr>
    </tbody>
  );
};

export default TBody;
