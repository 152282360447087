import moment from 'moment';
import lodash from 'lodash';

export const processes = [
  {
    part_id: null,
    plan_qty: null,
    ok_qty: null,
    productivity_perc:null,
    remarks: null,
  },
];

export const initialState = {
  isSubmitted: false,
  is_admin_request_no: null,
  is_admin_request: null,
  report_prepared_by: null,
  report_approved_by: null,
  rejectionNote_date: moment(),
  total_rej_date_wise: null,
  processes: Array.from({ length: 1 }, () => ({
    ...lodash.cloneDeep(processes[0]),
  })),
};
