// TableRowComponent.jsx
import React, {useState} from 'react';
import { Checkbox, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import lodash from 'lodash';
import TBodyRight from './TBodyRight';
import formatDate from '../../../../../components/common/formatDate';
import { TableInput } from '../../../../../components/common';
import { useQuery } from '../../../../../hooks/UseQuery';
import AutocompleteMuiCustomRevised from '../../../../../components/common/tableinputs/AutocompleteMuiCustomRevised';
import AttendeesModal from './AttendeesModal';

const areEqual = (prevProps: any, nextProps: any) => {
  // Check if the item prop has changed using a deep comparison
  const itemEqual = lodash.isEqual(prevProps.item, nextProps.item);

  // Check if the selection state for the current index has changed
  const selectionEqual =
    prevProps.selectionArray[prevProps.index] ===
    nextProps.selectionArray[nextProps.index];

  // Check if the index has changed
  const indexEqual = prevProps.index === nextProps.index;

  // Only return true (indicating the component should NOT update)
  // if all conditions are met (item, selection state, and index are equal)
  return itemEqual && selectionEqual && indexEqual;
};

export const TableRowComponent = React.memo(
  ({
    key,
    item,
    index,
    allmonth,
    updateModuleData,
    selectionArray,
    handleBulkDeleteFeature,
    autoCompleteData,
    settings,
    perPage,
    page,
    autoCompleteData1,
  }: any) => {
    const isView = useQuery('isView') === 'true' ? true : false;
    const rowId = item?._id || item?._tempId;
    const isChecked = selectionArray[index] === rowId;
    const [modalOpen, setModalOpen] = useState(false);

    const handleInputChange = (
      fieldName: string,
      value: string | number | null
    ) => {
      updateModuleData(index, fieldName, value);
    };

    const handleAutocompleteChange = (newValue: any) => {
      // Update a1, a2, and row_id fields in the row use null always
      console.log("newValue is",newValue);
      // updateModuleData(index, 'a1', newValue?.row_id?.topic_name);
      // updateModuleData(index, 'a2', newValue?.row_id?.faculty_name);
      // updateModuleData(index, 'a1', newValue?.row_id1?.topic_name);
      updateModuleData(index, 'a2', newValue?.row_id1?.faculty_name);
      // updateModuleData(index, 'a3', newValue?.frequency || null);


      // row id should  never be changed and it is populated id and you can assign value to a1 a2 from this row id and it is fallback value
    
        updateModuleData(index, 'row_id', newValue);
     
        // updateModuleData(index, 'row_id1', newValue);
      
    };
    const handleAutocompleteChange1 = (newValue: any) => {
      // Update a1, a2, and row_id fields in the row use null always
      console.log("newValue is",newValue);
      updateModuleData(index, 'a1', newValue?.row_id1?.topic_name);
      // updateModuleData(index, 'a2', newValue?.row_id?.faculty_name);
      // updateModuleData(index, 'a1', newValue?.row_id1?.topic_name);
      // updateModuleData(index, 'a2', newValue?.row_id1?.faculty_name);
      // updateModuleData(index, 'a3', newValue?.frequency || null);


      // row id should  never be changed and it is populated id and you can assign value to a1 a2 from this row id and it is fallback value
    
        // updateModuleData(index, 'row_id', newValue);
     
        updateModuleData(index, 'row_id1', newValue);
      
    };

    const handleCheckboxChange = (event: any) => {
      handleBulkDeleteFeature(index, event.target.checked, rowId);
    };

    const stickStylesColumn = {
      position: 'sticky',
      border: '1px solid black',
      zIndex: '1',
      backgroundColor: '#ffec99',
      // color: '#fff',
    } as const;


    return (
      <React.Fragment key={key}>
        <tr>
          <td rowSpan={2}
            style={{
              ...stickStylesColumn,
              left: 0,
              width: '50px',
              minWidth: '50px',
            }}
          >{perPage * (+page - 1) + index + 1}</td>
          {/* <td rowSpan={2}>{index + 1}</td> */}

          <td rowSpan={2}
            style={{
              ...stickStylesColumn,
              left: '65px',
              width: '50px',
              minWidth: '50px',
            }}
          >
            {item?.row_id1?.department_id?.department_name || null}
            {/* Since every topic group  -  training topic pair is being preloaded, autocomplete is not rquired
            {item?._id && item?.row_id1 === null && item?.a1 ? (
              item?.a1
            ) : (
              <AutocompleteMuiCustomRevised
              id=" id"
              label=""
              option_name="traning_topic"
              // option_name="topic_name"
              key={'traning_topic'}
              arrayofObj={autoCompleteData1}
              value={item?.row_id1}
              // value={item?.row_id}
              onChange={(_, newValue: any) =>
                handleAutocompleteChange1(newValue)
                // handleAutocompleteChange(newValue)
              }
              sx={{ width: '200px' }}
            />
            )}
            */}
          </td>

          <td rowSpan={2}
            style={{
              ...stickStylesColumn,
              left: '214px',
              width: '50px',
              minWidth: '50px',
            }}
          >
            {item?.individual_training_topic || null}
            {/*
            item?._id && item?.row_id1 === null && item?.a1 ? (
              item?.a1
            ) : (
              <AutocompleteMuiCustomRevised
              id=" id"
              label=""
              option_name="topic_name"
              key={'topic_name'}
              arrayofObj={autoCompleteData1}
              value={item?.row_id1}
              // value={item?.row_id}
              onChange={(_, newValue: any) =>
                handleAutocompleteChange1(newValue)
                // handleAutocompleteChange(newValue)
              }
              sx={{ width: '200px' }}
            />
            )
            */}
          </td>

          <td rowSpan={2}
            style={{
              ...stickStylesColumn,
              left: '373px',
              width: '50px',
              minWidth: '50px',
            }}
          >

              <AutocompleteMuiCustomRevised
                id="faculty id"
                label=""
                option_name="faculty_name"
                key={'faculty_name'}
                arrayofObj={autoCompleteData}
                value={item?.row_id}
                onChange={(_, newValue: any) =>
                  handleAutocompleteChange(newValue)
                }
                sx={{ width: '200px' }}
              />
            {/* {item?.[`a2`]  || item?.row_id?.designation } */}
          
          </td>

          <td rowSpan={2}
            style={{
              ...stickStylesColumn,
              left: '579px',
              width: '50px',
              minWidth: '50px',
              paddingLeft: '10px',
              paddingRight: '10px'
            }}
          >
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="duration"
              type="number"
              value={item.duration || ''}
              onChange={(e: any) =>
                handleInputChange('duration', e.target.value)
              }
            />
          </td>
          <td rowSpan={2}
            style={{
              ...stickStylesColumn,
              left: '676px',
              width: '50px',
              minWidth: '50px',
            }}
          >
            <IconButton onClick={() => setModalOpen(true)}>
              <VisibilityIcon />
            </IconButton>
          </td>

          {/* <td rowSpan={2}

            style={{
              ...stickStylesColumn,
              left: '416px',
              width: '50px',
              minWidth: '50px',
            }}
          >
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="a3"
              type="text"
              value={item?.a3}
              onChange={(e: any) =>
                handleInputChange('a3', e.target.value)
              }
            />
          </td> */}


          {/* <td>P</td> */}

          {/* always constant ⬇️*/}
          <TBodyRight
            item={item}
            isView={isView}
            index={index}
            allmonth={allmonth}
            updateModuleData={updateModuleData}
          />
          {/* 👉 */}

          <td rowSpan={2}>
            <TableInput
              isView={isView}
              style={{ height: '80px' }}
              name="remarks"
              type="text"
              value={item.remarks}
              onChange={(e: any) =>
                handleInputChange('remarks', e.target.value)
              }
            />
          </td>
          <td rowSpan={2}>
            <Checkbox
              style={{ color: '#fa5252' }}
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
          </td>
        </tr>
        <tr>
          {/* <td>A</td> */}
          {allmonth.map((month: any, mIdx: number) => (
            <td
              width="80px"
              height="45px"
              style={{
                backgroundColor: item[month].doneDate && '#37b24d',
                color: 'white',
                textTransform: 'capitalize',
                fontSize: '1.6rem',
              }}
            >
              {settings?.show_A && item[month].isTask && 'A'}
              {(settings?.show_date && formatDate(item[month].doneDate)) || null}
            </td>
          ))}
        </tr>
        <AttendeesModal
          open={modalOpen}
          handleClose={() => setModalOpen(false)}
          attendees={item.attendees || []}
        />
        {/* ---x---- */}
      </React.Fragment>
    );
  },
  (prevProps, nextProps) => areEqual(prevProps, nextProps)
);
