import { useState } from 'react';
import { TableInput } from '../../../../components/common';

const TFoot = ({
  isView,
  rejectionChallanHead,
}: {
  isView: any;
  rejectionChallanHead: any;
}) => {
  const [rowRender, setRowRender] = useState(false);
  const handleChange = (e: any) => {
    rejectionChallanHead[e.target.name] = e.target.value;
    setRowRender((prev) => !prev);
  };
  return (
    <tfoot>
      <tr>
        <th colSpan={4} align="left">
          Prepared By:
          <TableInput
            isView={isView}
            type="text"
            name="prepared_by"
            value={rejectionChallanHead?.prepared_by || null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </th>
        <th colSpan={4} align="left">
          Recieved By.:
          <TableInput
            isView={isView}
            type="text"
            name="received_by"
            value={rejectionChallanHead?.received_by || null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
