import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import { DeleteIconButton } from '../../../../components/common/button/CustomIconButton';
import { calcRow } from '../helpers/handleCalcualte';

interface IProps {
  item: any;
  isView: any;
  handleDelete: any;
  moduleData: any;
  index: number;
  setTbodyrender: any;
}

const TBodyRow = ({
  item,
  index,
  isView,
  handleDelete,
  moduleData,
  setTbodyrender,
}: IProps) => {
  const [rowRender, setRowRender] = useState(false);

  const handleChange = (e: any) => {
    item[e.target.name] = e.target.value;
  };

  return (
    <tr>
      <td style={{ textAlign: 'center' }}>{index + 1}</td>
      <td style={{ textAlign: 'center', minWidth: '200px' }}>
        {item?.ri_part_id?.part_name || item?.part_name}
      </td>
      <td style={{ textAlign: 'center', minWidth: '200px' }}>
        {item?.ri_part_id?.part_number || item?.part_number}
      </td>
      <td style={{ textAlign: 'center', minWidth: '150px' }}>
        <TableInput
          isView={isView}
          type="number"
          name="order_qty"
          value={item?.order_qty ?? null}
          onChange={(e) => {
            handleChange(e);
            calcRow(item, moduleData);
            setTbodyrender((prev: any) => !prev);
          }}
        />
      </td>
      {[1, 2, 3, 4].map((_, index: number) => (
        <td key={index + 'schedule'} style={{ minWidth: '150px' }}>
          <TableInput
            isView={isView}
            type="number"
            name="scheduled_qty"
            value={item?.scheduled_qty?.[index] || null}
            onChange={(e) => {
              item.scheduled_qty[index] = e.target.value;
              setRowRender((prev: any) => !prev);
            }}
          />
        </td>
      ))}
      {[1, 2, 3, 4].map((_, index: number) => (
        <td key={index + 'received'} style={{ minWidth: '150px' }}>
          <TableInput
            isView={isView}
            type="number"
            name="received_qty"
            value={item?.received_qty?.[index] || null}
            onChange={(e) => {
              item.received_qty[index] = e.target.value;
              calcRow(item, moduleData);
              setTbodyrender((prev: any) => !prev);
            }}
          />
        </td>
      ))}
      <td style={{ textAlign: 'center', minWidth: '150px' }}>
        <TableInput
          isView={true}
          type="number"
          name="total_qty_received"
          value={item?.total_qty_received ?? null}
          onChange={(e) => {
            handleChange(e);
            setRowRender((prev: any) => !prev);
          }}
        />
      </td>
      <td style={{ textAlign: 'center', minWidth: '150px' }}>
        <TableInput
          isView={true}
          type="number"
          name="total_ok_qty_received"
          value={item?.total_ok_qty_received ?? null}
          onChange={(e) => {
            handleChange(e);
            setRowRender((prev: any) => !prev);
          }}
        />
      </td>
      <td style={{ minWidth: '150px' }}>{item?.delivery_rating}</td>
      <td style={{ minWidth: '150px' }}>{item?.quality_Rating}</td>
      <td style={{ textAlign: 'center', minWidth: '150px' }}>
        <TableInput
          isView={true}
          type="number"
          name="prem_freight"
          value={item?.prem_freight ?? null}
          onChange={(e) => {
            handleChange(e);
            setTbodyrender((prev: any) => !prev);
          }}
        />
      </td>
      <td style={{ textAlign: 'center', minWidth: '150px' }}>
        <TableInput
          isView={true}
          type="number"
          name="kri_crust_line_stop"
          value={item?.kri_crust_line_stop ?? null}
          onChange={(e) => {
            handleChange(e);
            setTbodyrender((prev: any) => !prev);
          }}
        />
      </td>
      <td style={{ textAlign: 'center', minWidth: '150px' }}>
        <TableInput
          isView={true}
          type="number"
          name="quality_delay_issue"
          value={item?.quality_delay_issue ?? null}
          onChange={(e) => {
            handleChange(e);
            setTbodyrender((prev: any) => !prev);
          }}
        />
      </td>
      <td style={{ textAlign: 'center', minWidth: '150px' }}>
        <TableInput
          isView={true}
          type="number"
          name="sr"
          value={item?.sr ?? null}
          onChange={(e) => {
            handleChange(e);
            setTbodyrender((prev: any) => !prev);
          }}
        />
      </td>
      <td>{item?.overall_rating}</td>
      <td>
        <DeleteIconButton
          tooltipTitle="DELETE"
          onClick={() => {
            handleDelete(index);
          }}
        />
      </td>
    </tr>
  );
};

export default TBodyRow;
