import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import { useQuery } from '../../../../../hooks/UseQuery';
import React from 'react';
import TableHeaderHtmlCustom from '../../../../../components/common/customTable/TableHeaderHtmlCustom';
import { HeaderStandard, headerConstant } from '../helpers/constant';
import AutocompleteMuiCustomRevised from '../../../../../components/common/tableinputs/AutocompleteMuiCustomRevised';

interface IProps {
    moduleData: any;
    updateModuleData?: any;
}

const THead = ({ moduleData, updateModuleData }: IProps) => {
    const isView = useQuery('isView') === 'true' ? true : false;

    const handleAutocompleteChange = (newValue: any) => {
        updateModuleData('key-pair', null, 'machine_name', {
            machine_name: newValue?.machine_name,
        });
        updateModuleData('key-pair', null, 'machine_no', {
            machine_no: newValue?.machine_no,
        });
        updateModuleData('key-pair', null, 'machine_id', { machine_id: newValue });
        // updateModuleData('key-pair', null, 'machine_id', newValue);
    };
    const handleInputChange = (
        fieldName: string,
        value: string | number | null,
    ) => {
        updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
    };

    return (
        <thead>
            <TableCompHead
                colSpan={13}
                rowSpan={3}
                labels={['Doc. No:', 'Rev. No.:', 'Eff. Date:']}
                values={['F-29-04', '07', '01.11.2021']}
                heading={`${headerConstant.tableCompHeadTitle}`}
            />
            <tr>
                <th colSpan={1}>CHECKSHEET NAME</th>
                <th colSpan={2}>
                    <TableInput
                        isView={isView}
                        style={{ height: '80px' }}
                        name="checkList_name"
                        type="text"
                        value={moduleData.checkList_name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleInputChange(e.target.name, e.target.value)
                        }
                    />
                </th>
                <th colSpan={10}></th>
            </tr>
            <tr>
                <th>Machine No.</th>
                <th colSpan={2}></th>
                <th>PM Date:</th>
                <th colSpan={2}></th>
                <th colSpan={7} align="left">
                    X = NOT OK √ = OK
                </th>
            </tr>
            <TableHeaderHtmlCustom tableHeaderObj={HeaderStandard} />
        </thead>
    );
};

export default React.memo(THead);
