import { Box } from '@mui/material';
import { RenderTable } from '../../../../components/ui';
import TBody from './TBody';
import THead from './THead';

interface IProps {
  moduleData: any;
  handleDelete: any;
  setModuleData: any;
  isView: boolean;
  setPartIdx: React.Dispatch<React.SetStateAction<number>>;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  defects: any;
  vendors: any;
}

const TController = ({
  moduleData,
  isView,
  handleDelete,
  setModuleData,
  setPartIdx,
  setIsOpenPopup,
  defects,
  vendors,
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            isView,
            defects,
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
            handleDelete,
            setPartIdx,
            setIsOpenPopup,
            defects,
            vendors,
          }}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
