import React, { useEffect, useState } from 'react';
import CustomSelect from '../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import { TableInput } from '../../../../../components/common';
import { RenderTable } from '../../../../../components/ui';
import { Table } from '../../../../../components/ui/renderTable/styled';
import axios from 'axios';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';

const getReworkQuantity = (reasons_for_rej: any) => {
  return reasons_for_rej?.reduce((sum: any, reason: any) => {
    if (reason?.reason?.substring(reason?.reason?.length - 3) === '(R)')
      return sum + +reason.rej_qty;
    return sum;
  }, 0);
};
const getScrapQuantity = (reasons_for_rej: any) => {
  return reasons_for_rej?.reduce((sum: any, reason: any) => {
    if (reason?.reason?.substring(reason?.reason?.length - 3) === '(S)')
      return sum + +reason.rej_qty;
    return sum;
  }, 0);
};
const RejectionTable = (props: any) => {
  const { process, isView, processes, rejectionsIndx } = props;
  const [render, setRender] = useState(false);
  const [rejectionReasons, setRejectionReasons] = useState([]);
  const [reason, setReason] = useState('');

  useEffect(() => {
    axios
      .get('/api/productionDefectRoutes/defectList/defectForAutoComplete')
      .then((res) => {
        if (res?.data) {
          setRejectionReasons(res?.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const updateQuantities = (startIndex:number) => {
    for (let i = startIndex; i < processes.length-1; i++) {
      const currProcess = processes[i];
      const nextProcess = processes[i + 1];

      // Update the subsequent processes
        nextProcess.produce_qty = currProcess.ok_qty;
        nextProcess.ok_qty = nextProcess.produce_qty - (nextProcess.reject_qty || 0);
    }
    //setRender((prev) => !prev);
  };
  

  return (
    <Table style={{ width: '80vh' }}>
      <thead>
        <tr>
          <th>S.No.</th>
          <th>Reasons</th>
          <th>Rejection Qty</th>
        </tr>
      </thead>
      <tbody>
        {process?.reasons_for_rej?.map((item: any, index: number) => (
          <tr>
            <th>{index + 1}</th>
            <td style={{ minWidth: '300px', textAlign: 'center' }}>
              {isView ? (
                item.reason
              ) : (
                <CustomSelect
                  name="reason"
                  value={item.reason}
                  onChange={(e) => {
                    item.reason = e.target.value;
                    setRender((prev) => !prev);
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  {rejectionReasons.map((reason: any) => (
                    <MenuItem value={reason.defect_name}>
                      {reason.defect_name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              )}
            </td>
            <td style={{ minWidth: '100px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="rej_qty"
                key="rej_qty"
                type="number"
                value={item.rej_qty}
                onChange={(e) => {
                  const newValue = Number(e.target.value);
                  const oldValue = item.rej_qty;
                  process.reject_qty = process.reject_qty - oldValue + newValue;
                  item.rej_qty = e.target.value;
                  process.ok_qty =
                        (process.produce_qty || 0) - (process.reject_qty || 0);
                  updateQuantities(rejectionsIndx);
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th colSpan={2}>Total Rejction</th>
          <th>{process.reject_qty}</th>
        </tr>
      </tfoot>
    </Table>
  );
};

export default RejectionTable;
