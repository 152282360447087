import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';
import SelectGridMui from '../../../components/common/selectscustom/SelectGridMui';
import { MenuItem } from '@mui/material';
import moment from 'moment';
import GridDatePicker from '../../../components/common/inputs/GridDatePicker';
import { calculateDueDate } from './utils';

const Create = ({
  id,
  setIsModal,
  setListId,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setListId: any;
}) => {
  const [data, setData] = useState<any>({
    histories: [],
  });
  const [categoryData, setCategoryData] = useState([]);
  const [locations, setLocations] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [render, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Customer' : 'Create Customer'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  const [department, setDepartment] = useState([]);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/masterlistinstrument/${id}`)
        .then((res) => setData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  useEffect(() => {
    axios
      .get(`/api/masterListLocationRoute/locationList/categoryForAutoComplete`)
      .then((res) => setLocations(res.data))
      .catch((err) => console.log(err));
    axios
      .get(`/api/department`)
      .then((res) => setDepartment(res.data.results))
      .catch((err) => console.log(err));

    axios
      .get('/api/masterlistinstrument/instrumentcategory')
      .then((res) => {
        if (res.data.result) {
          setCategoryData(res?.data?.result);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  function handleData(e: React.ChangeEvent<HTMLInputElement>) {
    setData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    setIsSubmit(true);
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/masterlistinstrument/${id}`, data)
        .then((res) => {
          setIsSubmit(false);
          setListId(null);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      const makeFirstRow = {
        calibration_done_date: data?.calibration_done_date,
        due_date_cal: calculateDueDate(
          data?.calibration_done_date,
          Number(data?.frequency_in_year)
        ),
        repair_date: null,
        obsolete_date: null,
        re_cal_date: null,
      };
      data.histories[0] = makeFirstRow;
      await axios
        .post('/api/masterlistinstrument', data)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
          setListId(null);
        })
        .catch((err) => {
          setIsSubmit(false);
          console.log(err);
        });
    }
  };
  return (
    <>
      <GridInputs
        id={'id_no'}
        name={'id_no'}
        html_for={'id_no'}
        label_name={'GAUGE CODE NO. :'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.id_no || ''}
        onChange={handleData}
      />
      <AutocompleteGridmui
        id={'instrument_type'}
        label={'GAUGE DESCRIPTION :'}
        option_name={'name'}
        arrayofObj={categoryData}
        value={data.instrument_type || ''}
        onChange={(e, value) => {
          data.instrument_type = value;
          setRender((prev) => !prev);
        }}
      />
      <GridInputs
        id={'make'}
        name={'make'}
        html_for={'make'}
        label_name={'MAKE :'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.make || ''}
        onChange={handleData}
      />
      <AutocompleteGridmui
        label={'LOCATION :'}
        id="location"
        option_name={'name'}
        value={data?.location || ''}
        arrayofObj={locations}
        onChange={(e, value) => {
          data.location = value;
          setRender((prev) => !prev);
        }}
      />
      <GridInputs
        id={'range'}
        name={'range'}
        html_for={'range'}
        label_name={'Range:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.range || ''}
        onChange={handleData}
      />
      <GridInputs
        id={'acceptence_criteria'}
        name={'acceptence_criteria'}
        html_for={'acceptence_criteria'}
        label_name={'ACCEPTENCE CRITERIA :'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.acceptence_criteria || ''}
        onChange={handleData}
        last_child={2}
      />
      <GridInputs
        id={'frequency_in_year'}
        name={'frequency_in_year'}
        html_for={'frequency_in_year'}
        label_name={'CALIBRATION FREQUENCY :'}
        input_type={'number'}
        focusType={'onblur'}
        value={data?.frequency_in_year || ''}
        onChange={handleData}
      />
      <GridInputs
        id={'calibration_agency'}
        name={'calibration_agency'}
        html_for={'calibration_agency'}
        label_name={'CALIBRATION AGENCY :'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.calibration_agency || ''}
        onChange={handleData}
      />
      <GridDatePicker
        last_child={2}
        label_name="CALIBRATION DONE ON DATE :"
        html_for={'CALIBRATION DONE ON DATE'}
        value={data?.calibration_done_date || null}
        onChange={(date) => {
          data.calibration_done_date = moment(date._d);
          setRender((prev) => !prev);
        }}
      />
      <SelectGridMui
        name="source"
        value={data?.source || ''}
        lable={'SOURCE :'}
        onChange={(e: any) => handleData(e)}
      >
        <MenuItem value="">
          <em>NONE</em>
        </MenuItem>
        <MenuItem value="I H">I H</MenuItem>
        <MenuItem value="O S">O S</MenuItem>
      </SelectGridMui>

      <AutocompleteGridmui
        label={'DEPARTMENT:'}
        id="department_name"
        option_name={'department_name'}
        value={data?.department || ''}
        arrayofObj={department}
        onChange={(e, value) => {
          data.department = value;
          setRender((prev) => !prev);
        }}
      />
      <GridInputs
        id={'least_count'}
        name={'least_count'}
        html_for={'least_count'}
        label_name={'LEAST COUNT :'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.least_count || ''}
        onChange={handleData}
      />

      <GridInputs
        id={'delay'}
        name={'delay'}
        html_for={'delay'}
        label_name={'DELAY IF ANY :'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.delay || ''}
        onChange={handleData}
        last_child={2}
      />
      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'REMARKS :'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.remarks || ''}
        onChange={handleData}
        last_child={2}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
