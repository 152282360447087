import axios from 'axios';
import { useState, useEffect } from 'react';
import { useQuery } from '../../../../hooks/UseQuery';
import { moduleConstant } from '../constant';

const useGetData = () => {
  const date = useQuery('date');
  const supplierId = useQuery('supplier_id');
  const [moduleData, setModuleData] = useState<any>({
    moduleDate: date && new Date(date),
    vendor_id: supplierId,
    list: [],
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [render, setParentRender] = useState(false);
  const [renderforUpdate, setRenderforUpdate] = useState(false);

  const handelSync = async () => {
    // const ans = await confirmSync();
    // if (!ans) return;
    setIsLoading(true);
    axios
      .put(`${moduleConstant.apiConstant}/sync/${moduleData._id}`)
      .then((res) => {
        window.alert('Sync Succesfully');
        setRenderforUpdate((prev: any) => !prev);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!date) return;
    const handleApi = async () => {
      setIsLoading(true);
      axios
        .get(`${moduleConstant.apiConstant}/${date}/${supplierId}`)
        .then((response) => {
          setModuleData(response?.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setModuleData({
            moduleDate: date && new Date(date),
            vendor_id: supplierId,
            list: [],
          });
          setError(err);
        });
    };

    handleApi();
    setIsLoading(false);
  }, [date, renderforUpdate]);

  return {
    error,
    moduleData,
    setModuleData,
    isLoading,
    setParentRender,
    handelSync,
    setRenderforUpdate,
  };
};

export default useGetData;
