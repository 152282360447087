import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [moduleData, setModuleData] = useState<any>({
    department_name: '',
    hod: '',
    responsibility: '',
    remarks: '',
  });

  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Supplier' : 'Create Supplier'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/department/${id}`)
        .then((res) => setModuleData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleDepartment(e: React.ChangeEvent<HTMLInputElement>) {
    setModuleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/department/${id}`, moduleData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/department', moduleData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <GridInputs
        id={'department_name'}
        name={'department_name'}
        html_for={'department_name'}
        label_name={'Department Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.department_name}
        onChange={handleDepartment}
      />
      <GridInputs
        id={'responsibility'}
        name={'responsibility'}
        html_for={'responsibility'}
        label_name={'Responsibilty:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.responsibility}
        onChange={handleDepartment}
      />
      <GridInputs
        id={'hod'}
        name={'hod'}
        html_for={'hod'}
        label_name={'Head Of Department:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.hod}
        onChange={handleDepartment}
      />
      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remarks:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.remarks}
        onChange={handleDepartment}
      />

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
