export const moduleConstant = {
  crudApi: '/api/keyEquipmentRoute',
  childHeader: 'List Of Key Equipement Equipment',
  btnName: 'New Key Equipement',
  searchLabel: 'Equipment Name / Number',
  moduleName: 'Key Equipement Equipment',
  autoComplete1: '/api/department/department/departmentForAutoComplete',
};

export const tableHeaderObj = [
  [
    { text: '#', style: { border: '1px solid black' } },
    { text: 'Equipment Name', style: { border: '1px solid black' } },
    {
      text: 'Equipment Number',
      style: { border: '1px solid black' },
    },
    {
      text: 'Location',
      style: { border: '1px solid black' },
    },
    {
      text: 'Remarks',
      style: { border: '1px solid black' },
    },
    {
      text: 'Assign Spare Parts',
      style: { border: '1px solid black' },
    },
    {
      text: 'Action',
      style: { border: '1px solid black' },
    },
  ],
];
