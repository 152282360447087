import BarLineChart from '../../../../../components/common/chart/BarLineChart';
const lodash = require('lodash');

interface IProps {
  apiData: any;
  partName: any;
  target: any;
}
const labels = lodash.range(1, 32);
const MonthlyPartWise = ({ apiData, partName, target }: IProps) => {
  const { productivityPerc } = apiData;
  return (
    <div>
      <BarLineChart
        datas={productivityPerc}
        yTitle={'PRODUCTIVITY    %AGE'}
        xTitle={'DAYS'}
        labels={labels}
        type={'line'}
        borderTitle={'DAILY PRODUCTIVITY GRAPH'}
        headTitle={`${partName}`}
        borderColor={'#38d9a9'}
        borderWidth={2}
        fill={true}
        width={'80vw'}
        margin={'1rem 14rem 5rem 14rem'}
        otherType={'line'}
        otherLabel={`Target : ${target}`}
        otherData={new Array(31).fill(target).map((target: any) => target)}
        otherBorderColor={'#c92a2a'}
        otherFill={false}
        otherBorderWidth={2}
      />
    </div>
  );
};

export default MonthlyPartWise;
