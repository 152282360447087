import React, { useState } from 'react';
import { allmonth } from '../../helpers/utils';
import { TableRowComponent } from './TableRowComponent';

interface IProps {
  moduleData: any;
  handleBulkDeleteFeature: any;
  selectionArray: any;
  updateModuleData: any;
  settings: any;
  partData: any;
  moulderData: any;
}
const TBody = ({
  moduleData,
  selectionArray,
  updateModuleData,
  handleBulkDeleteFeature,
  partData,
  moulderData,
  settings,
}: IProps) => {
  return (
    <tbody>
      {moduleData.map((item: any, index: any) => (
        <TableRowComponent
          key={item?._id || `${index}_table_row`}
          item={item}
          index={index}
          allmonth={allmonth}
          updateModuleData={updateModuleData}
          handleBulkDeleteFeature={handleBulkDeleteFeature}
          selectionArray={selectionArray}
          partData={partData}
          moulderData={moulderData}
          settings={settings}
        />
      ))}
    </tbody>
  );
};

export default React.memo(TBody);
