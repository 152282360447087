export const tableHeaderObj = [
  [
    {
      text: 'S. No.',
      rowspan: 3,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black' },
    },
    {
      text: 'Document Name',
      rowspan: 3,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black',minWidth:"200px" },
    },

    {
      text: 'Document No.',
      rowspan: 3,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black',minWidth:"150px" },
    },
    {
      text: 'Latest Issue No.',
      rowspan: 3,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black' ,minWidth:"150px"},
    },
    {
      text: 'Retention Period',
      rowspan: 3,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black' ,minWidth:"250px"},
    },
    {
      text: 'Issue No. / Issue date',
      rowspan: 2,
      colspan: 11,
      style: { background: '#38d9a9', border: '1px solid black' },
    },
    {
      text: 'MR OFFICE',
      rowspan: 1,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black',minWidth:"100px" },
    },
    {
      text: 'M.D.',
      rowspan: 1,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black',minWidth:"100px" },
    },
    {
      text: 'Q.A',
      rowspan: 1,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black',minWidth:"100px" },
    },
    {
      text: 'PROD.',
      rowspan: 1,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black' ,minWidth:"100px"},
    },
    {
      text: 'HR',
      rowspan: 1,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black',minWidth:"100px" },
    },
    {
      text: 'Store',
      rowspan: 1,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black' ,minWidth:"100px"},
    },
    {
      text: 'Maintainance',
      rowspan: 1,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black',minWidth:"100px" },
    },
    {
      text: 'PURCHASE',
      rowspan: 1,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black' ,minWidth:"100px"},
    },
    {
      text: 'NPD',
      rowspan: 1,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black' ,minWidth:"100px"},
    },
    {
      text: 'Account',
      rowspan: 1,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black',minWidth:"100px"},
    },
    {
      text: 'Finance',
      rowspan: 1,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black',minWidth:"100px" },
    },
    {
      text: 'Drawing Upload',
      rowspan: 3,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black' },
    },
    {
      text: 'Action',
      rowspan: 3,
      colspan: 1,
      style: { background: '#38d9a9', border: '1px solid black' },
    },
  ],

  [
    {
      text: 'Distribution',
      rowspan: 1,
      colspan: 11,
      style: {background: '#38d9a9',border: '1px solid black',},
    },
  ],
  [
    {
      text: '00',
      rowspan: 1,
      colspan: 1,
      style: {background: '#38d9a9',border: '1px solid black'},
    },
    {
      text: '01',
      rowspan: 1,
      colspan: 1,
      style: {background: '#38d9a9',border: '1px solid black'},
    },
    {
      text: '02',
      rowspan: 1,
      colspan: 1,
      style: {background: '#38d9a9',border: '1px solid black'},
    },
    {
      text: '03',
      rowspan: 1,
      colspan: 1,
      style: {background: '#38d9a9',border: '1px solid black'},
    },
    {
      text: '04',
      rowspan: 1,
      colspan: 1,
      style: {background: '#38d9a9',border: '1px solid black'},
    },
    {
      text: '05',
      rowspan: 1,
      colspan: 1,
      style: {background: '#38d9a9',border: '1px solid black'},
    },
    {
      text: '06',
      rowspan: 1,
      colspan: 1,
      style: {background: '#38d9a9',border: '1px solid black'},
    },
    {
      text: '07',
      rowspan: 1,
      colspan: 1,
      style: {background: '#38d9a9',border: '1px solid black'},
    },
    {
      text: '08',
      rowspan: 1,
      colspan: 1,
      style: {background: '#38d9a9',border: '1px solid black'},
    },
    {
      text: '09',
      rowspan: 1,
      colspan: 1,
      style: {background: '#38d9a9',border: '1px solid black'},
    },
    {
      text: '10',
      rowspan: 1,
      colspan: 1,
      style: {background: '#38d9a9',border: '1px solid black'},
    },
    {
      text: '0',
      rowspan: 1,
      colspan: 1,
      style: {background: '#38d9a9',border: '1px solid black',},
    },
    {
      text: '1',
      rowspan: 1,
      colspan: 1,
      style: {background: '#38d9a9',border: '1px solid black',},
    },
    {
      text: '2',
      rowspan: 1,
      colspan: 1,
      style: {background: '#38d9a9',border: '1px solid black',},
    },
    {
      text: '3',
      rowspan: 1,
      colspan: 1,
      style: {background: '#38d9a9',border: '1px solid black',},
    },
    {
      text: '4',
      rowspan: 1,
      colspan: 1,
      style: {background: '#38d9a9',border: '1px solid black',},
    },
    {
      text: '5',
      rowspan: 1,
      colspan: 1,
      style: {background: '#38d9a9',border: '1px solid black',},
    },
    {
      text: '6',
      rowspan: 1,
      colspan: 1,
      style: {background: '#38d9a9',border: '1px solid black',},
    },
    {
      text: '7',
      rowspan: 1,
      colspan: 1,
      style: {background: '#38d9a9',border: '1px solid black',},
    },
    {
      text: '8',
      rowspan: 1,
      colspan: 1,
      style: {background: '#38d9a9',border: '1px solid black',},
    },
    {
      text: '9',
      rowspan: 1,
      colspan: 1,
      style: {background: '#38d9a9',border: '1px solid black',},
    },
    {
      text: '10',
      rowspan: 1,
      colspan: 1,
      style: {background: '#38d9a9',border: '1px solid black',},
    },
  ]
];
