import { useEffect, useState } from 'react';
import axios from 'axios';
import TBodyRow from './TBodyRow';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: (index: number) => void;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setPartIdx: React.Dispatch<React.SetStateAction<number>>;
  onSubmit: () => void;
  pictorial: any;
  setPictorial: any;
  triningFileIdRef: any;
  setIsTrainingFileOpenPopup: any;
}
const TBody = ({
  handelDeleteRows,
  moduleData,
  isView,
  setIsOpenPopup,
  onSubmit,
  setPartIdx,
  pictorial,
  setPictorial,
  setIsTrainingFileOpenPopup,
  triningFileIdRef,
}: IProps) => {
  const [machineData, setMachineData] = useState([]);
  useEffect(() => {
    axios
      .get('/api/machineRoutes/machine/ForAutocomplete')
      .then((res) => {
        setMachineData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <tbody>
      {moduleData.processes.map((process: any, index: number) => (
        <TBodyRow
          {...{
            moduleData,
            process,
            handelDeleteRows,
            isView,
            setIsOpenPopup,
            onSubmit,
            setPartIdx,
            pictorial,
            setPictorial,
            triningFileIdRef,
            setIsTrainingFileOpenPopup,
            machineData,
            index,
          }}
        />
      ))}
    </tbody>
  );
};

export default TBody;
