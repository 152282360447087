import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import { CustomButton } from '../../../../../../components/common/button/CustomButtons';
import { DeleteIconButton } from '../../../../../../components/common/button/CustomIconButton';
interface IProps {
  moduleData: any;
  handelDeleteRows: any;
  isView: boolean;
}
const TBody = ({ moduleData, isView, handelDeleteRows }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      <tr style={{ background: '#fff2cc' }}>
        <th colSpan={1}>Role</th>
        <th colSpan={4}>
          Responsible for setting & meeting of daily & monthly targets for
          monthly schedule completion as per customer requirement .
        </th>
        <th>
          {!isView && (
            <CustomButton
              sx={{
                backgroundColor: '#1c7ed6',
              }}
              onClick={async () => {
                // const ans = await confirmAddRow();
                // if (!ans) return;
                moduleData.process_1.push({});
                setRender((prev) => !prev);
              }}
            >
              ADD ROWS
            </CustomButton>
          )}
        </th>
      </tr>
      {moduleData?.process_1 &&
        moduleData.process_1.map((item: any, index: number) => (
          <tr key={index + 'specs'}>
            <td>{index + 1}</td>
            <td colSpan={4}>
              <TableInput
                isView={isView}
                name="responsibility"
                type="text"
                value={item.responsibility}
                onChange={(e) => {
                  item.responsibility = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '100px', textAlign: 'center' }}>
              <DeleteIconButton
                tooltipTitle={'delete'}
                onClick={() => handelDeleteRows('process_1', index)}
              />
            </td>
          </tr>
        ))}
    </tbody>
  );
};

export default TBody;
