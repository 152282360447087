export const checkListList = {
    childHeader: 'Predictive Maintenance STATUS',
    addButtonLable: '',
    datePickerLabel: 'Search By Year',
    modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
    getAllStatus: 'predictiveMaintenancePlanStatusRoute/getAllSatusByYear',
    upadateListStatus: 'predictiveMaintenancePlanStatusRoute/handleStatusUpdate',
    checklistUpload: 'predictiveMaintenancePlanStatusRoute/handleUpload',
    checklistDeleteImage: 'predictiveMaintenancePlanStatusRoute/handleDeleteImage',
    forAutoComplete: 'machineRoutes/machine/ForAutocomplete',
};

export const navigationConstant = {
    checklistRouter: '/common/predictiveMaintenancePlanStatusCheckListCreate',
};
