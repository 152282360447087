import { TableCompHead } from '../../../../components/ui';
interface IProps {
  moduleData: any;
  isView: boolean;
}
const THead = ({ moduleData, isView }: IProps) => {
  return (
    <thead
      style={{
        position: 'sticky',
        width: '100%',
        top: '-.5rem',
        border: '1px solid black',
        zIndex: '1',
        backgroundColor: 'rgb(79, 129, 189)',
        color: '#fff',
      }}
    >
      <TableCompHead
        colSpan={32}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Eff. Date']}
        values={['HO-F-PRD-03', '05', '12.09.2020']}
        heading="4M CHANGE  TRACKING   SHEET"
      />
      <tr style={{ border: '1px solid black' }}>
        <th rowSpan={2} style={{ minWidth: '100px' }}>
          S.NO.
        </th>
        <th rowSpan={2} style={{ minWidth: '150px' }}>
          DATE
        </th>
        <th rowSpan={2} style={{ minWidth: '150px' }}>
          TIME
        </th>
        <th rowSpan={2} style={{ minWidth: '150px' }}>
          SHIFT
        </th>
        <th rowSpan={2}>PART NAME</th>
        <th rowSpan={2} style={{ minWidth: '150px' }}>
          TYPE OF CHANGE
        </th>
        <th rowSpan={2} style={{ minWidth: '150px' }}>
          MACHINE NO.
        </th>
        <th rowSpan={2}>OPERATION</th>
        <th rowSpan={2}>DETAIL OF CHANGE</th>
        <th rowSpan={2}>ACTION TAKEN</th>
        <th rowSpan={2}>PLANNED</th>
        <th rowSpan={2}>UNPLANNED</th>
        <th rowSpan={2} style={{ minWidth: '180px' }}>
          CHANGE SITUATION
        </th>
        <th rowSpan={2} style={{ minWidth: '180px' }}>
          CHECKPOINT
        </th>
        <th rowSpan={2}>CHECKPOINT STATUS</th>
        <th rowSpan={2}>ABNORMAL</th>
        <th rowSpan={2} style={{ minWidth: '300px' }}>
          PROCEDURE
        </th>
        <th rowSpan={2}>TOTAL PARTS MADE</th>
        <th rowSpan={2}>RETROACTIVE INSPECTION OK</th>
        <th rowSpan={2}>RETROACTIVE INSPECTION NG</th>
        <th rowSpan={2}>CONTAINMENT INSPECTION OK</th>
        <th rowSpan={2}>CONTAINMENT INSPECTION NG</th>
        <th rowSpan={2}>BATCH</th>
        <th rowSpan={2}>SETUP</th>
        <th rowSpan={2}>RETRO</th>
        <th rowSpan={2}>SUSPECTED</th>
        <th rowSpan={2}>TRAINING</th>
        <th rowSpan={2}>TRAINING ATTACH PROOF</th>
        <th rowSpan={2}>ENGG. PRD SIGN</th>
        <th rowSpan={2}>ENGG QA SIGN</th>
        <th rowSpan={2}>AM PRODUCTION.(SIGN)-DAILY</th>
        <th rowSpan={2}>ACTION</th>
      </tr>
      <tr></tr>
    </thead>
  );
};

export default THead;
