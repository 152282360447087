import { useState } from 'react';
import { TableInput } from '../../../../../components/common';

interface Ifoot {
  moduleData: any;
  isView: boolean;
}
const TFoot = ({ moduleData, isView }: Ifoot) => {
  const [render, setRender] = useState(false);

  return (
    <tfoot>
      <tr>
        <th colSpan={2}></th>
        <th colSpan={4}>Glossary for Defect </th>
        <th></th>
        <th colSpan={2}>Glossary For B/Down Time </th>
        <th colSpan={12}></th>
      </tr>
      <tr>
        <th colSpan={2}></th>
        <th>A</th>
        <th>Air Cut </th>
        <th>K</th>
        <th>Knot </th>
        <th colSpan={1}></th>
        <th>1</th>
        <th>No Operator </th>
        <th colSpan={12}></th>
      </tr>
      <tr>
        <th colSpan={2}></th>
        <th>B</th>
        <th>Air Bubble</th>
        <th>M</th>
        <th>Moulding </th>
        <th colSpan={1}></th>
        <th>2</th>
        <th>No Material </th>
        <th colSpan={12}></th>
      </tr>
      <tr>
        <th colSpan={2}></th>
        <th>C</th>
        <th>Chemical </th>
        <th>O</th>
        <th>Over Cure </th>
        <th colSpan={1}></th>
        <th>3</th>
        <th>Electronics / Electric Fault </th>
        <th colSpan={12}></th>
      </tr>
      <tr>
        <th colSpan={2}></th>
        <th>D</th>
        <th>Breaking </th>
        <th>P</th>
        <th>Scrap On Part </th>
        <th colSpan={1}></th>
        <th>4</th>
        <th>Mould B/d</th>
        <th colSpan={12}></th>
      </tr>
      <tr>
        <th colSpan={2}></th>
        <th>E</th>
        <th>Excess Buffing </th>
        <th>S</th>
        <th>Semi Cure </th>
        <th colSpan={1}></th>
        <th>5</th>
        <th>No Electricity </th>
        <th colSpan={12}></th>
      </tr>
      <tr>
        <th colSpan={2}></th>
        <th>F</th>
        <th>Short Filling </th>
        <th>PD </th>
        <th>Screw Mark </th>
        <th colSpan={1}></th>
        <th>6</th>
        <th>Mechnical Fault </th>
        <th colSpan={12}></th>
      </tr>
      <tr>
        <th colSpan={2}></th>
        <th>J</th>
        <th>Joint </th>
        <th>KT</th>
        <th>Blade Mark </th>
        <th colSpan={1}></th>
        <th></th>
        <th></th>
        <th colSpan={12}></th>
      </tr>
      <tr>
        <th colSpan={9} align="left">
          Prepared By:{' '}
          <TableInput
            isView={true}
            name="report_prepared_by"
            type="text"
            value={moduleData.report_prepared_by}
            onChange={(e) => {
              moduleData.report_prepared_by = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={12} align="left">
          Approved By:
          <TableInput
            isView={true}
            name="report_approved_by"
            type="text"
            value={moduleData.report_approved_by}
            onChange={(e) => {
              moduleData.report_approved_by = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
