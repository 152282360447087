import { Box, Checkbox, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { TableCustom } from '../../../../../components/common';
const tableHeaderObj = [
  [
    { text: 'S.NO.', rowspan: 1, colspan: 1 },
    { text: 'NAME', rowspan: 1, colspan: 1 },
    { text: 'Id', rowspan: 1, colspan: 1 },
    { text: 'ACTION', rowspan: 1, colspan: 1 },
    { text: 'JOINT NAME', rowspan: 1, colspan: 1 },
  ],
];

const MouldView = ({ process, keyNo }: { process: any; keyNo: number }) => {
  const [moulds, setMoulds] = useState([]);
  const [render, setRender] = useState(false);
  const partId =
    typeof process?.part_id === 'string' && process?.part_id == !null
      ? process.part_id
      : process?.part_id?._id;
  useEffect(() => {
    const partId =
      typeof process?.part_id === 'string' && process?.part_id == !null
        ? process.part_id
        : process?.part_id?._id;
    if (partId) {
      console.log(keyNo, partId);
      axios
        .get(
          `/api/shiftProductionReport/getMouldsByPartId/${partId}/joint_${keyNo}`
        )
        .then((res) => {
          if (res?.data) {
            setMoulds(res?.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [partId, keyNo]);
  const handleChange = (item: any, keyNo: number) => {
    if (!Array.isArray(process?.[`mould_id_${keyNo}`])) {
      process[`mould_id_${keyNo}`] = [];
    } // ie for if mould_id_$ not exist
    const indexOfMould = process?.[`mould_id_${keyNo}`]?.findIndex(
      (mould: any) => mould?._id === item?._id
    );

    if (indexOfMould === -1) {
      process?.[`mould_id_${keyNo}`]?.push(item);
    } else {
      process?.[`mould_id_${keyNo}`]?.splice(indexOfMould, 1);
    }
    setRender((prev) => !prev);
  };
  return (
    <Box width={600}>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {moulds?.map((item: any, index: number) => (
            <React.Fragment key={item?._id}>
              <TableRow>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center">{item?.moulding_name}</TableCell>
                <TableCell align="center">{item?.moulding_id}</TableCell>
                <TableCell align="center">
                  {item?.joint_name?.replace('_', ' ')}
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={process?.[`mould_id_${keyNo}`]?.some(
                      (obj: any) => obj?._id === item?._id
                    )}
                    onChange={() => handleChange(item, keyNo)}
                  />
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
        '{' '}
      </TableCustom>
    </Box>
  );
};
export default MouldView;
