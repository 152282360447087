import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { Box, Checkbox, Divider, Stack } from '@mui/material';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import { TimePicker } from '@mui/x-date-pickers';
import { TableInput, TextAreaInput } from '../../../../../components/common';
import { convToTime } from '../../../../../utils/helpers';

interface IProps {
  moduleData: any;
  isView: boolean;
  departmentData: any;
  machineData: any;
}


const THead = ({ moduleData, isView, departmentData, machineData }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={4}
        rowSpan={3}
        labels={['Doc No.:', 'Rev No.:', 'Eff. Date:']}
        values={['KRI - F - Maint - 29', '01', '01.08.2024']}
        heading="BreakDown Intimation Slip"
      />
      <tr>
      <th align="left" style={{ minWidth: '150px' }} colSpan={4}>
      Slip No:---
      <TableInput
            isView={isView}
            name="slip_no"
            type="text"
            value={moduleData.slip_no}
            onChange={(e) => {
              moduleData.slip_no = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
      <th align="left" style={{ minWidth: '150px' }} colSpan={2}>
      Raised by:---
      <TableInput
            isView={isView}
            name="raised_by"
            type="text"
            value={moduleData.raised_by}
            onChange={(e) => {
              moduleData.raised_by = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th align="left" style={{ minWidth: '150px' }} colSpan={2}>
        Attended by:---
      <TableInput
            isView={isView}
            name="attended_by"
            type="text"
            value={moduleData.attended_by}
            onChange={(e) => {
              moduleData.attended_by = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
      <th align="left" style={{ minWidth: '150px' }} colSpan={2}>
      Department:---
          {isView ? (
            moduleData?.department_id?.department_name
          ) : (
            <AutocompleteMuiCustom
              id="Department"
              label=""
              option_name="department_name"
              key={'departments'}
              arrayofObj={departmentData}
              value={moduleData?.department_id || ''}
              onChange={(e:any, value:any) => {
                moduleData.department_id = value;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th align="left" style={{ minWidth: '150px' }} colSpan={2}>
        Break down date:---
          {isView ? (
            formatDate(moduleData.breakdown_date)
          ) : (
            <DatePickerMui
              value={moment(moduleData.breakdown_date || null)}
              onChange={(date:any) => {
                moduleData.breakdown_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
      <th align="left" style={{ minWidth: '150px' }} colSpan={2}>
      Machine/ Tool Name:---
      {moduleData.machine_id?.machine_name}
      </th>
      <th align="left" style={{ minWidth: '150px' }} colSpan={2}>
      Break down start:---
      {isView ? (
            convToTime(moduleData?.breakdown_start_time)
          ) : (
            <TimePicker
              value={moment(moduleData?.breakdown_start_time || '')}
              onChange={(date) => {
                moduleData.breakdown_start_time = date;
                setRender((prev) => !prev);
              }}
            />
          )}
      </th>
      </tr>
      <tr>
        <th style={{ minWidth: '150px' }} colSpan={2} align="left">
        Machine/ Tool No:---
        {isView ? (
            moduleData?.machine_id?.machine_no
          ) : (
            <AutocompleteMuiCustom
              id="Machine"
              label=""
              option_name="machine_no"
              key={'Machine'}
              arrayofObj={machineData}
              value={moduleData?.machine_id || ''}
              onChange={(e:any, value:any) => {
                moduleData.machine_id = value;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th align="left" style={{ minWidth: '150px' }} colSpan={2}>
        Break down end:---
        {isView ? (
            convToTime(moduleData?.breakdown_end_time)
          ) : (
            <TimePicker
              value={moment(moduleData?.breakdown_end_time || '')}
              onChange={(date) => {
                moduleData.breakdown_end_time = date;
                setRender((prev) => !prev);
              }}
            />
          )}
      </th>
      </tr>
      <tr>
      <th colSpan={4} align="left" style={{ width: '150px' }}>
      Problem Nature :--- {' Mechanical: '}
          {isView ? (
            moduleData.problem_nature === 'MECHANICAL' ? (
              ' ✔ '
            ) : (
              ' ✘ '
            )
          ) : (
            <Checkbox
              checked={moduleData.problem_nature === 'MECHANICAL'}
              onChange={(e) => {
                moduleData.problem_nature = 'MECHANICAL';
                setRender((prev: any) => !prev);
              }}
            />
          )}
          {' Electrical: '}
          {isView ? (
            moduleData.problem_nature === 'ELECTRICAL' ? (
              ' ✔ '
            ) : (
              ' ✘ '
            )
          ) : (
            <Checkbox
              checked={moduleData.problem_nature === 'ELECTRICAL'}
              
              onChange={(e) => {
                moduleData.problem_nature = 'ELECTRICAL';
                setRender((prev: any) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th align="left" style={{ minWidth: '150px' }} colSpan={4}>
        Problem description:--- 
        <TextAreaInput
                isView={isView}
                name="problem_desc"
                value={moduleData?.problem_desc}
                onChange={(e) => {
                  moduleData.problem_desc = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
        </th>
      </tr>
      <tr>
        <th align="left" style={{ minWidth: '150px' }} colSpan={4}>
        Action:---
        <TextAreaInput
                isView={isView}
                name="action"
                value={moduleData?.action}
                onChange={(e) => {
                  moduleData.action = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
        </th>
      </tr>
    </thead>
  );
};

export default THead;
