import { useEffect, useState } from 'react';
import { SearchBar } from '../../components/common/SearchBar';
import FilterBy from '../../components/common/FilterBy';
import { SearchFilterContainer } from '../../assets/styles/layout/searchFilter.styled';
import ChildHeader from '../../components/ui/ChildHeader';
import { TableBody, TableCell, TableRow, TextField } from '@mui/material';
import axios from 'axios';
import Box from '@mui/material/Box';
import ModalCustom from '../../components/common/Modal';
import DescriptionIcon from '@mui/icons-material/Description';
import AutocompleteMuiCustom from '../../components/common/AutocompleteMuiCustom';
import {
  AIButton,
  AddButton,
  CustomButton,
  UpdateButton,
} from '../../components/common/button/CustomButtons';
import { HiDocumentDuplicate } from 'react-icons/hi';
import TableCustom from '../../components/common/customTable/TableCustom';
import AddEditPart from './AddEditPart';
import { useAuth } from '../../services/auth/AuthProvider';
import useDebounce from '../../hooks/useDebounce';
import React from 'react';
import formatDate from '../../components/common/formatDate';
import { SelectStatus } from './Select';
import { AiOutlinePartition } from 'react-icons/ai';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import {
  DeleteIconButton,
  EditIconButton,
  ViewIconButton,
} from '../../components/common/button/CustomIconButton';
import Stack from '@mui/material/Stack';
import CustomTileButton from '../../components/common/button/CustomTileButton';
import { IPart } from './AddEditPart';
import ModalSearchFilterCustomised from '../../components/common/Modals/TableCustomisedForModal';
import DulicatePart from './DulicatePart';
import { useQuery } from '../../hooks/UseQuery';
import CustomPagination from '../../components/common/CustomPagination';
import useConfirm from '../../components/common/useConfirm';
import { useNavigate } from 'react-router-dom';
import ConfirmDialogueForInput from '../../components/common/ConfirmDialogueForInput';
import Aimodal from './Aimodal';

const statusObj: any = {
  draft: 1,
  publish: 2,
};
const statusOptions = [
  { value: '1', label: 'DRAFT', bgColor: '#FDD835', textColor: 'inherit' },
  { value: '2', label: 'PUBLISH', bgColor: '#2b8a3e', textColor: '#fff' },
];
const filterOptions = [
  { value: 'part_number', name: 'A-Z' },
  { value: 'createdAt', name: 'Most Recent First' },
  { value: 'createdAt_old', name: 'Oldest First' },
];

const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'Item Number', rowspan: 1, colspan: 1 },
    { text: 'Item Name/Description', rowspan: 1, colspan: 1 },
    { text: 'Model Name', rowspan: 1, colspan: 1 },
    { text: ' Code', rowspan: 1, colspan: 1 },
    { text: 'Created At', rowspan: 1, colspan: 1 },
    { text: 'status', rowspan: 1, colspan: 1 },
    { text: 'Documents List', rowspan: 1, colspan: 1 },
    { text: 'AI Analysis', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const Partlist = () => {
  const { state } = useAuth();
  const [data, setData] = useState<IPart[]>([]);
  const [isNewPartModal, setIsNewPartModal] = useState(false);
  const [render, setRender] = useState(false);
  const [dropDownRender, setDropDownRender] = useState(false);
  const [duplicateOpenModal, setDuplicateOpenModal] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [partId, setPartId] = useState<string | null>(null);
  const [itemPartNumber, setItemPartNumber] = useState<string | null>(null);
  const [confirmOpenForDelete, setConfirmOpenForDelete] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState<string | null>(null);
  const [aiModal, setAiModal] = useState(false);
  const navigate = useNavigate();
  const [DialogConfirm, confirmSubmit] = useConfirm(
    'UPDATE STATUS',
    'Are you sure you want to UPATE the status?'
  );
  const [DialogConfirmAi, confirmSubmitAi] = useConfirm(
    'AI ✨',
    'Do you want to confirm using AI for analysis?'
  );

  const search = useQuery('search');
  const filter = useQuery('filter');
  const page = useQuery('page') || 1;
  const fetchData = async () => {
    const res: any = await axios
      .get(
        `/api/addPartRoute/byDocType/draft?page=${page}&sortBy=${
          filter || 'part_number'
        }&search=${search}&perPage=${10}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res.data?.result);
    setPerPage(res.data?.perPage);
    setTotalPages(res.data?.pages);
  };

  const handleChangeRequestStatus = (e: any, index: number) => {
    data[index].isAdminRequestNo = e.target.value;
    setDropDownRender((prev) => !prev);
  };

  const deletePart = async (id: string) => {
    await axios.delete(`/api/addPartRoute/${id}`);
    setRender((prev) => !prev);
  };

  const updateRequestForm = async (partId: string, index: number) => {
    const ans = await confirmSubmit();
    if (!ans) return;
    axios
      .patch(`/api/addPartRoute/updateStatus/${partId}`, {
        isAdminRequestNo: data[index].isAdminRequestNo,
      })
      .then((res) => {
        if (res.data.status === 'success') {
          alert('Status Change successfully');
        }
        setRender((prev) => !prev);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useDebounce(fetchData, 1000, [
    page,
    search,
    render,
    filter,
    duplicateOpenModal,
    isNewPartModal,
  ]);

  const handleDialogWithInput = () => {
    if (!partId) return;
    if (itemPartNumber === textFieldValue) {
      deletePart(partId);
      setPartId(null);
      setTextFieldValue(null);
      setConfirmOpenForDelete(false);
    }
  };

  return (
    <>
      <ChildHeader text="ADD / PUBLISH PARTS">
        <CustomButton
          color="primary"
          sx={{ margin: '10px' }}
          icon={<HiDocumentDuplicate />}
          disabled={false}
          size="large"
          onClick={() => setDuplicateOpenModal(true)}
        >
          Duplicate
        </CustomButton>
        <AddButton onClick={() => setIsNewPartModal(true)} label="New Part" />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search Part Name" />
        <FilterBy options={filterOptions} />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: IPart, index: number) => (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell align="center" width={10}>
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      overflow: 'auto',
                      maxWidth: '15rem',
                      width: '15rem',
                    }}
                  >
                    {item?.part_number}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      overflow: 'auto',
                      width: '20rem',
                      maxWidth: '20rem',
                    }}
                  >
                    {item?.part_name}
                  </TableCell>
                  <TableCell align="center">
                    {item?.model_id?.model_name}
                  </TableCell>
                  <TableCell align="center">{item?.alp_code}</TableCell>
                  <TableCell align="center">
                    {formatDate(item?.createdAt || '')}
                  </TableCell>
                  <TableCell align="center">
                    <SelectStatus
                      item={{
                        isSubmitted: item.isSubmitted || '',
                        isAdminRequestNo:
                          item.isAdminRequestNo ||
                          statusObj[item?.isSubmitted || 1],
                      }}
                      index={1}
                      handleChangeRequestStatus={(e: any) =>
                        handleChangeRequestStatus(e, index)
                      }
                      statusOptions={statusOptions}
                    />
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{ whiteSpace: 'nowrap', width: '420px' }}
                  >
                    <Stack
                      direction="row"
                      spacing={0.5}
                      sx={{ justifyContent: 'center' }}
                    >
                      <CustomTileButton
                        url={`/admin/pfd?part_name=ffsff`}
                        label={'Edit PFD'}
                        icon={<AiOutlinePartition />}
                        sx={{
                          background: '#064a29',
                          color: '#f8f9fa',
                          fontWeight: 500,
                        }}
                      />
                      <CustomTileButton
                        url={`/admin/fmea`}
                        label={'Edit Fmea'}
                        icon={<SmsFailedIcon />}
                        sx={{
                          background: '#931435',
                          color: '#fafafa',
                        }}
                      />
                      <CustomTileButton
                        url={`/admin/controlplan/${item?._id}?part_number${item.part_name}&part_number${item.part_number}&master_format=${item?.master_format?.format_name}`}
                        label={'Edit CP'}
                        icon={<DescriptionIcon />}
                        sx={{
                          background: '#003566',
                          color: '#fafafa',
                        }}
                      />
                    </Stack>
                  </TableCell>

                  <TableCell align="center">
                    <AIButton
                      label="AI"
                      onClick={async () => {
                        const ans = await confirmSubmitAi();
                        if (!ans) return;
                        setPartId(item?._id!);
                        setAiModal(true);
                      }}
                    />
                  </TableCell>

                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          setPartId(item?._id!);
                          setIsNewPartModal(true);
                        }}
                      />
                      <ViewIconButton
                        tooltipTitle="VIEW"
                        onClick={() => {
                          navigate(
                            `quick_view_documents/${item?._id}?&master_format=${item?.master_format?.format_name}`
                          );
                        }}
                      />
                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          // deletePart();
                          setItemPartNumber(item?.part_number);
                          setPartId(item?._id!);
                          setConfirmOpenForDelete(true);
                        }}
                      />
                      <div>
                        <UpdateButton
                          label="update"
                          sx={{ margin: '10px' }}
                          size="small"
                          onClick={() => {
                            updateRequestForm(item._id!, index);
                          }}
                        />
                      </div>
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />

      <ModalCustom
        openModal={isNewPartModal}
        closeModal={() => {
          setPartId(null);
          setIsNewPartModal(false);
        }}
        title="CREATE NEW PART"
      >
        <AddEditPart
          setIsNewPartModal={setIsNewPartModal}
          id={partId}
          setPartId={setPartId}
        />
        {/* <AddEditPart
          setIsNewPartModal={setIsNewPartModal}
          id={partId}
          setPartId={setPartId}
        /> */}
      </ModalCustom>
      <ModalCustom
        title={'DUPLICATE PART'}
        openModal={duplicateOpenModal}
        closeModal={() => {
          setDuplicateOpenModal(false);
        }}
      >
        <DulicatePart setDuplicateOpenModal={setDuplicateOpenModal} />
      </ModalCustom>
      <ModalCustom
        title={'AI ANALYSIS'}
        dialogTitleCustomBackground={'#f8f9fa'}
        dialogTitleCustomColor={'#343a40'}
        isRequireCloseButton={false}
        openModal={aiModal}
        closeModal={() => {
          setAiModal(false);
        }}
      >
        <Aimodal partId={partId} setAiModal={setAiModal} />
      </ModalCustom>
      {/* <DialogDelete isSubmitContent={false} /> */}
      <ConfirmDialogueForInput
        title="DELETE PART"
        open={confirmOpenForDelete}
        setOpen={setConfirmOpenForDelete}
        onConfirm={handleDialogWithInput}
        isSubmitContent={false}
      >
        <p style={{ margin: 0, fontSize: '1.6rem' }}>
          Are you sure you want to delete this part?
        </p>
        <p style={{ margin: 0, fontSize: '1.6rem' }}>
          Enter the <b>Part Number :</b> "{`${itemPartNumber}`}" to confirm part
          deletion:
        </p>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          autoComplete="false"
          label="Item Number"
          type="text"
          fullWidth
          variant="standard"
          onChange={(e) => setTextFieldValue(e.target.value)}
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
        />
      </ConfirmDialogueForInput>
      <DialogConfirm />
      <DialogConfirmAi />
    </>
  );
};

export default Partlist;
