import { useState } from 'react';
import { TableInput } from '../../../../components/common';

interface IProps {
  item: any;
  isView: any;
  index: number;
}

const PartTableCell = ({ item }: { item: any }) => {
  if (
    item.red_trolly_rejection_id ||
    item.lot_rejection_id ||
    item.daily_line_rejection_id
  ) {
    return (
      <td>
        {item?.red_trolly_rejection_id?.part_id?.part_number ||
          item?.lot_rejection_id?.part_id?.part_number ||
          item?.daily_line_rejection_id?.part_id?.part_number}
      </td>
    );
  }
  return (
    <td style={{ textAlign: 'center', minWidth: '200px' }}>
      {item?.compilation_id?.part_id?.part_number}
    </td>
  );
};

const ReturnQtyTableCell = ({ item }: { item: any }) => {
  if (
    item.red_trolly_rejection_id ||
    item.lot_rejection_id ||
    item.daily_line_rejection_id
  ) {
    return (
      <td>
        {item?.red_trolly_rejection_id?.[item.defect_id._id] ||
          item?.lot_rejection_id?.[item.defect_id._id] ||
          item?.daily_line_rejection_id?.[item.defect_id._id]}
      </td>
    );
  }
  return (
    <td style={{ textAlign: 'center', minWidth: '200px' }}>
      {item?.compilation_id?.[item.defect_id._id]}
    </td>
  );
};

const TBodyRow = ({ item, index, isView }: IProps) => {
  const [rowRender, setRowRender] = useState(false);

  const handleChange = (e: any) => {
    item[e.target.name] = e.target.value;
  };

  return (
    <>
      <tr>
        <td style={{ textAlign: 'center' }}>{index + 1}</td>
        <PartTableCell {...{ item }} />
        <td>{item.defect_id?.defect_name}</td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="uom"
            value={item?.uom || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <ReturnQtyTableCell {...{ item }} />
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="reason_of_return"
            value={item?.reason_of_return || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="total_scrap"
            value={item?.total_scrap || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
      </tr>
    </>
  );
};

export default TBodyRow;
