interface Ifoot {
  moduleData: any;
  setRender: any;
}
const getTotalProducedQty = (processes: any) => {
  return processes.reduce(
    (sum: any, process: any) => sum + +process.produced_qty,
    0
  );
};
const TFoot = ({ moduleData, setRender }: Ifoot) => {
  return (
    <tfoot style={{ background: '#94d82d' }}>
      <tr>
        <th colSpan={27} align="left">
          Break Down Details
        </th>
      </tr>
      <tr>
        <th colSpan={8} align="left">
          1--ELEC.
        </th>
        <th colSpan={8} align="left">
          7--No Power
        </th>
        <th colSpan={11} align="left">
          13-- Utility
        </th>
      </tr>
      <tr>
        <th colSpan={8} align="left">
          2--MECH.
        </th>
        <th colSpan={8} align="left">
          8-- No Material
        </th>
        <th colSpan={11} align="left">
          14--Late Start module
        </th>
      </tr>
      <tr>
        <th colSpan={8} align="left">
          3--Mould Problem
        </th>
        <th colSpan={8} align="left">
          9-- No Plan
        </th>
        <th colSpan={11} align="left">
          15-- NPD Activity
        </th>
      </tr>
      <tr>
        <th colSpan={8} align="left">
          4--Compound Problem
        </th>
        <th colSpan={8} align="left">
          10--No Manpower
        </th>
        <th colSpan={11} align="left">
          16--Mtr Quality Problem
        </th>
      </tr>
      <tr>
        <th colSpan={8} align="left">
          5--C/O
        </th>
        <th colSpan={8} align="left">
          11-- New Manpower
        </th>
        <th colSpan={11} align="left">
          17--Tooling Breakdown
        </th>
      </tr>
      <tr>
        <th colSpan={8} align="left">
          6--Process Problem
        </th>
        <th colSpan={8} align="left">
          12-- Late manpower
        </th>
        <th colSpan={11} align="left">
          18--High Rejection
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
