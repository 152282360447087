import React, { useState } from 'react';
import { IProcess } from '../../../helpers/Interface';
import { spclChar } from '../../../helpers/SpecialCharacters';
import '../../common/ui/rowHoverstyle.css';

interface IProps {
  moduleData: any;
}

const TBody = ({ moduleData }: IProps) => {
  return (
    <tbody>
      {moduleData.processes &&
        moduleData.processes.map((process: IProcess, index: number) => (
          <React.Fragment key={index + 'process'}>
            {process.specs.map((spec, indx) => (
              <React.Fragment key={indx + 'spec'}>
                <tr className={'addHover'}>
                  {indx === 0 && (
                    <>
                      <td
                        rowSpan={process.specs.length}
                        style={{ minWidth: '100px', width: '100px' }}
                      >
                        {process.process_no}
                      </td>
                      <td
                        rowSpan={process.specs.length}
                        style={{ minWidth: '150px', width: '150px' }}
                      >
                        {process.process_name}
                      </td>
                    </>
                  )}
                  {spec?.machine_include && (
                    <td
                      style={{ border: '1px solid black' }}
                      rowSpan={(spec?.machine_rowSpan as number) || 1}
                    >
                      {spec?.machine}
                      {spec?.machine && spec?.tool_no ? '/' : ''}
                      {spec?.tool_no}
                    </td>
                  )}

                  <td>{indx + 1}</td>

                  {/* product or process */}
                  {spec?.char_product_include && (
                    <td
                      align="center"
                      style={{ border: '1px solid black' }}
                      rowSpan={(spec?.char_product_rowSpan as number) || 1}
                    >
                      {spec?.char_product}
                    </td>
                  )}
                  {spec?.char_process_include && (
                    <td
                      align="center"
                      style={{ border: '1px solid black' }}
                      rowSpan={(spec?.char_process_rowSpan as number) || 1}
                    >
                      {spec?.char_process}
                    </td>
                  )}
                  <td
                    align="center"
                    style={{
                      border: '1px solid black',
                      minWidth: '100px',
                      width: '100px',
                    }}
                  >
                    {spec.special_character && spclChar[spec.special_character]}
                  </td>
                  {/* specification and tolerance values */}
                  <td align="center">
                    {spec.control_product}
                    {spec.control_process && spec.control_product ? '/' : ''}
                    {spec.control_process}
                  </td>
                  {/* Check method or evaluation measurement technique */}
                  <td
                    align="center"
                    style={{
                      border: '1px solid black',
                      minWidth: '150px',
                      width: '150px',
                    }}
                  >
                    {spec.methods_measure}
                  </td>

                  {/* Sample Size	 */}
                  {spec?.methods_size_include && (
                    <td
                      align="center"
                      style={{ border: '1px solid black' }}
                      rowSpan={(spec?.methods_size_rowSpan as number) || 1}
                    >
                      {spec?.methods_size}
                    </td>
                  )}

                  {/* FREQ.. */}
                  {spec?.methods_by_include && (
                    <td
                      align="center"
                      style={{ border: '1px solid black' }}
                      rowSpan={(spec?.methods_by_rowSpan as number) || 1}
                    >
                      {spec?.methods_by}
                    </td>
                  )}
                  {/* FREQ.. */}
                  {spec?.methods_process_include && (
                    <td
                      align="center"
                      style={{ border: '1px solid black' }}
                      rowSpan={(spec?.methods_process_rowSpan as number) || 1}
                    >
                      {spec?.methods_process}
                    </td>
                  )}

                  {spec?.responsibility_include && (
                    <td
                      style={{
                        border: '1px solid black',
                        minWidth: '150px',
                        width: '150px',
                      }}
                      rowSpan={(spec?.responsibility_rowSpan as number) || 1}
                    >
                      {spec?.responsibility}
                    </td>
                  )}
                  {spec?.reaction_plan_include && (
                    <td
                      align="center"
                      style={{
                        border: '1px solid black',
                      }}
                      rowSpan={(spec?.reaction_plan_rowSpan as number) || 1}
                    >
                      {spec?.reaction_plan}
                    </td>
                  )}
                </tr>
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
    </tbody>
  );
};

export default TBody;
