import moment from 'moment';
export const process = 
  {
    part_id:null,
    shift_engr_name:null,
    total_target_qty:null,
    produced_qty:null,
    total_check_qty:null,
    total_reason_qty:null,
    total_scrap_aa_qty:null,
    total_scrap_bb_qty:null,
    total_scrap_cc_qty:null,
    total_scrap_dd_qty:null,
    total_ok_qty:null,
    opening_wip_qty:null,
    closing_wip_qty:null,
    moulder_id:null,
    moulder_one_id:null,
    moulder_two_id:null,
    moulder_three_id:null,
    moulder_four_id:null,
    employee_id:null,
    total_rework_qty:null,
    scrap_aa_time:null,
    scrap_bb_time:null,
    scrap_cc_time:null,
    scrap_dd_time:null,
    spd_mkt:null,
    standard_qty:null,
    actual_qty:null,
    breakdown_time:null,
    breakdown_reasons:null,
    remarks:null,
  };

export const initialState = {
  final_inspection_report_date:null,
  hourly_production_report_date: null,
  shift:"A",
  processes: [],
};
