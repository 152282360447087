import axios from 'axios';
import { useState, useEffect } from 'react';
import { useQuery } from '../../../../hooks/UseQuery';
import { moduleConstant } from '../constant';

const useGetData = () => {
  const date = useQuery('date');
  const [moduleData, setModuleData] = useState<any>([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [render, setParentRender] = useState(false);
  const [renderforUpdate, setRenderforUpdate] = useState(false);
  const [defects, setDefects] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    axios
      .get('/api/customerRoutes/customerList/customerForAutoComplete')
      .then((res) => {
        setCustomers(res.data);
      })
      .catch((err) => console.log(err));
    axios
      .get('/api/vendorRoute/vendor/forAutoComplete')
      .then((res) => {
        if (res.data) {
          setVendors(res.data);
        }
      })
      .catch((err) => console.log(err));
    axios
      .get('/api/defectRoutes/defectList/defectForAutoComplete')
      .then((res) => {
        if (res.data) {
          setDefects(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (!date) return;
    const handleApi = async () => {
      setIsLoading(true);
      axios
        .get(`${moduleConstant.apiConstant}/${date}`)
        .then((res) => {
          if (res.data) {
            setModuleData(res?.data);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err);
        });
    };

    handleApi();
    setIsLoading(false);
  }, [date, renderforUpdate]);

  return {
    error,
    moduleData,
    setModuleData,
    isLoading,
    defects,
    vendors,
    setParentRender,
    customers,
    setRenderforUpdate,
  };
};

export default useGetData;
