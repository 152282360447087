import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { Checkbox, Grid } from '@mui/material';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import { TableInput } from '../../../../../components/common';
interface IProps {
  moduleData: any;
  isView: boolean;
  setIsOpenPopup: any;
  employees: any;
  machineData: any;
  operators: any;
  employeesProd: any;
}

const theadData = [
  'S. No.',
  'TIME',
  'CHECK QTY.',
  'OK QTY.',
  'REJECT QTY.',
  'REWORK QTY.',
  'SCRAP QTY.',
  'AA Sec.',
  'BB Sec.',
  'CC Sec.',
  'DD Sec.',
  'REASON OF REJECTION',
  'INSPECTOR',
  'PRD.SHIFT ENGR',
  'QA SHIFT ENGR',
  'CROSS VERIFIED',
];
const filterEmployees = (employees: any, employeeType: any) => {
  const newEmployees = employees.filter(
    (item: any) => item?.category === employeeType
  );
  return newEmployees;
};

const THead = ({
  moduleData,
  isView,
  setIsOpenPopup,
  employees,
  machineData,
  operators,
  employeesProd,
}: IProps) => {
  const [render, setRender] = useState(false);
  const [employee, setEmployee] = useState('');
  const [operator, setOperator] = useState('');
  return (
    <thead>
      <TableCompHead
        colSpan={16}
        rowSpan={3}
        labels={['DOC NO.', 'REV.NO.', 'DATE']}
        values={['HO-F-QAS-08', '03', '07.01.2021']}
        heading="FINAL PRODUCT & PROCESS CHECK SHEET"
      />
      <tr>
        <th colSpan={4} align="left">
          PART NAME :{' '}
          {isView ? (
            moduleData?.part_id && moduleData?.part_id?.part_name
          ) : (
            <Grid container xs={12} direction="row" alignItems={'center'}>
              <Grid item xs={10}>
                <p
                  style={{
                    fontSize: '1.2rem',
                  }}
                >
                  {typeof moduleData?.part_id === 'object' &&
                  moduleData?.part_id !== null
                    ? moduleData?.part_id?.part_name
                    : moduleData?.partName || ''}
                  {/* {!moduleData?.part_id && 'PART NAME'} */}
                </p>
              </Grid>

              {moduleData.part_id ? (
                <Grid item xs={2}>
                  <RemoveIconButton
                    tooltipTitle="remove part"
                    onClick={() => {
                      moduleData.part_id = null;
                      moduleData.partNumber = null;
                      moduleData.partName = null;
                      moduleData.customerName = null;
                      setRender((prev) => !prev);
                    }}
                  />
                </Grid>
              ) : (
                <Grid item xs={2}>
                  <AddIconButton
                    tooltipTitle="add part"
                    onClick={() => {
                      setIsOpenPopup(true);
                    }}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </th>
        <th colSpan={2} align="left">
          DATE :{' '}
          {isView ? (
            formatDate(moduleData.module_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.module_date)}
              onChange={(date: any) => {
                moduleData.module_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={10} align="left">
          SHIFT :{'  A'}
          <Checkbox
            checked={moduleData.shiftA}
            onChange={(e) => {
              if (moduleData.shiftA) moduleData.shiftA = false;
              else moduleData.shiftA = true;
              setRender((prev) => !prev);
            }}
          />
          B{' '}
          <Checkbox
            checked={moduleData.shiftB}
            onChange={(e) => {
              if (moduleData.shiftB) moduleData.shiftB = false;
              else moduleData.shiftB = true;
              setRender((prev) => !prev);
            }}
          />
          C{' '}
          <Checkbox
            checked={moduleData.shiftC}
            onChange={(e) => {
              if (moduleData.shiftC) moduleData.shiftC = false;
              else moduleData.shiftC = true;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={4} align="left">
          CUSTOMER NAME :{' '}
          {moduleData?.customerName ||
            moduleData?.part_id?.customer_id?.customer_name}
        </th>
        <th colSpan={2} align="left">
          Operator Shift A (8 AM - 8 PM) :{' '}
          {isView ? (
            moduleData?.operator_id?.operator_name
          ) : (
            <AutocompleteMuiCustom
              id="operator_name"
              label=""
              option_name="operator_name"
              option_name_2="floor"
              value={moduleData?.operator_id || ''}
              arrayofObj={operators}
              onChange={(e: any, value: any) => {
                moduleData.operator_id = value;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={3} align="left">
          Operator Shift B (8 AM - 8 PM) :{' '}
          {isView ? (
            moduleData?.operator_shiftB_id?.operator_name
          ) : (
            <AutocompleteMuiCustom
              id="operator_name"
              label=""
              option_name="operator_name"
              option_name_2="floor"
              value={moduleData?.operator_shiftB_id || ''}
              arrayofObj={operators}
              onChange={(e: any, value: any) => {
                // if (value) {
                moduleData.operator_shiftB_id = value;
                setRender((prev) => !prev);
                // }
                // setOperator(value);
              }}
            />
          )}
        </th>
        <th colSpan={3} align="left">
          PRD. SHIFT ENGR. NAME :{' '}
          {isView ? (
            moduleData?.employee_id?.employee_name
          ) : (
            <AutocompleteMuiCustom
              id="employee_name"
              label=""
              option_name="employee_name"
              value={moduleData?.employee_id || ''}
              arrayofObj={employeesProd}
              onChange={(e: any, value: any) => {
                moduleData.employee_id = value;
                setEmployee(value);
              }}
            />
          )}
        </th>
        <th colSpan={5}></th>
      </tr>
      <tr>
        <th colSpan={2} align="left" style={{ minWidth: '300px' }}>
          MACHINE 1:{' '}
          {isView ? (
            moduleData?.machine_id?.machine_no
          ) : (
            <AutocompleteMuiCustom
              id="machine_no"
              label=""
              option_name="machine_no"
              arrayofObj={machineData}
              value={moduleData.machine_id || ''}
              onChange={(e, value) => {
                moduleData.machine_id = value;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={2} align="left" style={{ minWidth: '300px' }}>
          MACHINE 2 :{' '}
          {isView ? (
            moduleData?.machine_two_id?.machine_no
          ) : (
            <AutocompleteMuiCustom
              id="machine_no"
              label=""
              option_name="machine_no"
              arrayofObj={machineData}
              value={moduleData.machine_two_id || ''}
              onChange={(e, value) => {
                moduleData.machine_two_id = value;
                setRender((prev) => !prev);
              }}
            />
          )}
          {/* <TableInput
            isView={isView}
            name="shift_engr_name"
            type="text"
            value={moduleData?.shift_engr_name}
            onChange={(e) => {
              moduleData.shift_engr_name = e.target.value;
              setRender((prev) => !prev);
            }}
          />{' '} */}
        </th>
        <th colSpan={2} align="left" style={{ minWidth: '300px' }}>
          MACHINE 3 :{' '}
          {isView ? (
            moduleData?.machine_three_id?.machine_no
          ) : (
            <AutocompleteMuiCustom
              id="machine_no"
              label=""
              option_name="machine_no"
              arrayofObj={machineData}
              value={moduleData.machine_three_id || ''}
              onChange={(e, value) => {
                moduleData.machine_three_id = value;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={3} align="left">
          MACHINE 4 :{' '}
          {isView ? (
            moduleData?.machine_four_id?.machine_no
          ) : (
            <AutocompleteMuiCustom
              id="machine_no"
              label=""
              option_name="machine_no"
              arrayofObj={machineData}
              value={moduleData.machine_four_id || ''}
              onChange={(e, value) => {
                moduleData.machine_four_id = value;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={3} align="left">
          MACHINE 5 :{' '}
          {isView ? (
            moduleData?.machine_five_id?.machine_no
          ) : (
            <AutocompleteMuiCustom
              id="machine_no"
              label=""
              option_name="machine_no"
              arrayofObj={machineData}
              value={moduleData.machine_five_id || ''}
              onChange={(e, value) => {
                moduleData.machine_five_id = value;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={2} align="left">
          MACHINE 6 :{' '}
          {isView ? (
            moduleData?.machine_six_id?.machine_no
          ) : (
            <AutocompleteMuiCustom
              id="machine_no"
              label=""
              option_name="machine_no"
              arrayofObj={machineData}
              value={moduleData.machine_six_id || ''}
              onChange={(e, value) => {
                moduleData.machine_six_id = value;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr style={{ background: '#38d9a9', height: '60px' }}>
        {theadData.map((heading, index) => (
          <th key={heading + index} colSpan={1}>
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
