import Admin from '../pages/adminHome/AdminHome';
import { clause4 } from './admin/clause4';
import { clause10 } from './admin/clause10';
import { clause7 } from './admin/clause7';
import { other } from './admin/other';
import { clause18 } from './admin/clause18';
import { clause1 } from './admin/clause1';
import { clause8 } from './admin/clause8';
import { clause3 } from './admin/clause3';
import { clause15 } from './admin/clause15';
import { clause5 } from './admin/clause5';

// import { productions } from './admin/production';
import { SupplierComplaintList } from '../pages/RIDeparment';

interface routes {
  path: string;
  element: JSX.Element;
  errorElement?: string;
}

export const IndexAdmin: routes[] = [
  {
    path: 'home',
    element: <Admin />,
  },
  {
    path: 'SupplierComplaintRegister',
    element: <SupplierComplaintList />,
  },
  // ...productions,
  ...clause1,
  ...clause3,
  ...clause4,
  ...clause7,
  ...clause8,
  ...clause10,
  ...clause18,
  ...clause15,
  ...clause5,
  ...other,
];
