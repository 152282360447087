export const checkListList = {
  childHeader: 'Skill Evaluation plan STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  getAllStatus: 'skillEvaluationPlanStatusRoute/getAllSatusByYear',
  upadateListStatus: 'skillEvaluationPlanStatusRoute/handleStatusUpdate',
  checklistUpload: 'skillEvaluationPlanStatusRoute/handleUpload',
  checklistDeleteImage: 'skillEvaluationPlanStatusRoute/handleDeleteImage',
  forAutoComplete:
    'masterlistinstrument/instrumentList/instumentForAutoComplete',
};

export const navigationConstant = {
  checklistRouter: '/common/skillEvaluationPlanStatusCheckListCreate',
};
