import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import CustomSelect from '../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';

interface Ifoot {
  moduleData: any;
  isView: boolean;
}
const TFoot = ({ moduleData, isView }: Ifoot) => {
  const [render, setRender] = useState(false);

  return (
    <tfoot>
      <tr>
        <th colSpan={3}>QTY. CHECKED</th>
        <th colSpan={3}>QTY. OK</th>
        <th colSpan={3}>QTY. REJECT</th>
        <th colSpan={3}>DISPOSITION</th>
      </tr>
      <tr>
        <th colSpan={3}>
          {
            <TableInput
              isView={isView}
              name="qty_checked"
              type="number"
              value={moduleData.qty_checked}
              onChange={(e) => {
                moduleData.qty_checked = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          }
        </th>
        <th colSpan={3}>
          {' '}
          {
            <TableInput
              isView={isView}
              name="qty_ok"
              type="number"
              value={moduleData.qty_ok}
              onChange={(e) => {
                moduleData.qty_ok = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          }
        </th>
        <th colSpan={3}>
          {' '}
          {
            <TableInput
              isView={isView}
              name="qty_rejected"
              type="number"
              value={moduleData.qty_rejected}
              onChange={(e) => {
                moduleData.qty_rejected = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          }
        </th>
        <th colSpan={3} style={{ minWidth: '100px', textAlign: 'center' }}>
          {isView ? (
            moduleData.disposition
          ) : (
            <CustomSelect
              name="status"
              value={moduleData.disposition}
              onChange={(e) => {
                moduleData.disposition = e.target.value;
                setRender((prev) => !prev);
              }}
            >
              <MenuItem value="">NONE</MenuItem>
              <MenuItem value="ACCEPTED">ACCEPTED</MenuItem>
              <MenuItem value="REJECTED">REJECTED</MenuItem>
            </CustomSelect>
          )}
        </th>
      </tr>
      <tr>
        <th colSpan={12} style={{ fontSize: '20px' }}>
          NOTE: All dimensions/product requirements/functions/property/apperance
          shall be verified for each process w.r.t drawing/ control plan
        </th>
      </tr>
      <tr>
        <th>CHECKED BY:</th>
        <th colSpan={5}>
          {' '}
          {
            <TableInput
              isView={isView}
              name="checked_by"
              type="text"
              value={moduleData.checked_by}
              onChange={(e) => {
                moduleData.checked_by = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          }
        </th>
        <th> APPROVED BY:</th>
        <th colSpan={6} align="left">
          {' '}
          {
            <TableInput
              isView={isView}
              name="approved_by"
              type="text"
              value={moduleData.approved_by}
              onChange={(e) => {
                moduleData.approved_by = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          }
        </th>
      </tr>
      <tr>
        <th colSpan={4}>FORMAT NO: HO-F-QAS-28</th>
        <th colSpan={4}>REV NO: 03</th>
        <th colSpan={4}>DATE:- 15.01.2015</th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
