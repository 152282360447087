import { TableCompHead } from '../../../../components/ui';
import React from 'react';
import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import { HeaderStandard, headerConstant } from '../helpers/constant';
import { getTotalColsSpan } from '../../../../utils/helpers/utils';
import { CustomIconButton } from '../../../../components/common/button/CustomIconButton';
import { PiSortAscendingLight } from 'react-icons/pi';

interface IProps {
  moduleData: any;
  updateModuleData?: any;
  handleSort: any;
}

const THead = ({ moduleData, updateModuleData, handleSort }: IProps) => {
  HeaderStandard[0][0]['component'] = (
    <CustomIconButton
      sx={{}}
      tooltipTitle="sort data"
      onClick={() => {
        handleSort();
      }}
    >
      <PiSortAscendingLight style={{ color: '#f8f9fa', fontSize: '2rem' }} />
    </CustomIconButton>
  );

  return (
    <thead> 
       <TableCompHead //Added
        colSpan={getTotalColsSpan(HeaderStandard)} //Kept
        rowSpan={4} 
        labels={['Doc. No:','Rev. No.:','Eff. Date','Rev. Date']}
        values={['F-HR-09','01','01.04.2023','01.04.2024']}
        heading={`${headerConstant.tableCompHeadTitle}`}
      /> 
      

      <TableHeaderHtmlCustom tableHeaderObj={HeaderStandard} />
    </thead>
  );
};

export default React.memo(THead);
