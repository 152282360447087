import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import { IData } from './Interface';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import moment from 'moment';
import DateGridMui from '../../../components/common/dateCustom/DateGridMui';
import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';
import { DivStyled } from '../../../assets/styles/components/DivStyledLableInputs';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [moduleData, setModuleData] = useState<IData>({
    employee_name: '',
    employee_code: '',
    department_id: null,
    date_of_leaving: null,
    date_of_joining: new Date(),
  });

  const [render, setRender] = useState(false);
  const [image, setImage] = useState(null);
  const [department, setDepartment] = useState([]);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Employee' : 'Create Employee'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/employee/${id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleModulData(e: React.ChangeEvent<HTMLInputElement>) {
    setModuleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    if (!moduleData.department_id) {
      return alert('Please! also pick department');
    }
    const ans = await confirmSubmit();
    if (!ans) return;
    const formData = new FormData();
    if (
      typeof moduleData.department_id === 'object' &&
      moduleData.department_id
    ) {
      moduleData.department_id = moduleData.department_id?._id;
    }

    for (const key in moduleData) {
      if (moduleData[key]) {
        formData.append(key, moduleData[key]);
      } else {
        delete moduleData[key];
      }
    }
    if (image) {
      formData.append('image', image);
    }
    if (id) {
      await axios
        .put(`/api/employee/${id}`, formData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/employee', formData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    axios
      .get(`/api/department/departmentAll/ForAutocomplete`)
      .then((res) => setDepartment(res?.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <GridInputs
        id={'employee_name'}
        name={'employee_name'}
        html_for={'employee_name'}
        label_name={'Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.employee_name}
        onChange={handleModulData}
      />
      <GridInputs
        id={'employee_code'}
        name={'employee_code'}
        html_for={'employee_code'}
        label_name={'Employee Code:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.employee_code}
        onChange={handleModulData}
      />
      <AutocompleteGridmui
        label={'Department:'}
        id={'department_name'}
        option_name={'department_name'}
        arrayofObj={department}
        value={moduleData?.department_id || ''}
        onChange={(e, value) => {
          if (value === null) value = null;
          moduleData.department_id = value;
          setRender((prev) => !prev);
        }}
        last_child={2}
      />
      <GridInputs
        id={'designation'}
        name={'designation'}
        html_for={'designation'}
        label_name={'Designation:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.designation}
        onChange={handleModulData}
      />
      <GridInputs
        id={'level'}
        name={'level'}
        html_for={'level'}
        label_name={'Level:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.level}
        onChange={handleModulData}
      />
      <GridInputs
        id={'father_or_hus_name'}
        name={'father_or_hus_name'}
        html_for={'father_or_hus_name'}
        label_name={'Father / Husband Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.father_or_hus_name}
        onChange={handleModulData}
      />
      <GridInputs
        id={'qualification'}
        name={'qualification'}
        html_for={'qualification'}
        label_name={'Qualification'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.qualification}
        onChange={handleModulData}
      />
      <GridInputs
        id={'exp_years'}
        disabled={true}
        name={'exp_years'}
        html_for={'exp_years'}
        label_name={'Total Experience'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.exp_years}
        onChange={handleModulData}
      />
      <DateGridMui
        lable="Date Of Joining:"
        onChange={(date: any) => {
          moduleData.date_of_joining = date;
          setRender((prev) => !prev);
        }}
        value={
          (moduleData.date_of_joining && moment(moduleData.date_of_joining)) ||
          null
        }
      />
      <DateGridMui
        lable="Date Of Leaving:"
        onChange={(date: any) => {
          moduleData.date_of_leaving = date;
          setRender((prev) => !prev);
        }}
        value={
          (moduleData.date_of_leaving && moment(moduleData.date_of_leaving)) ||
          null
        }
      />
      <GridInputs
        id={'remarks'}
        disabled={true}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remarks:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.remarks}
        onChange={handleModulData}
      />

      <DivStyled>
        <CustomUploadButton
          lable={'UPLOAD DOCUMENT'}
          accept="image/png,image/jpeg,image/jpg"
          onChange={(e) => setImage(e.target.files[0])}
        />
      </DivStyled>

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
