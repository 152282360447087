import React, { useEffect, useState } from 'react';
import { Box, Grid, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import useDebounce from '../../../../../hooks/useDebounce';
import {
  TableCustom,
  StatePagination,
  StateSearchBar,
  StateFilter,
} from '../../../../../components/common';
import formatDate from '../../../../../components/common/formatDate';
import { CustomIconButton } from '../../../../../components/common/button/CustomIconButton';
import { useNavigate } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import StateCustomTable from '../../../../../components/common/StateCustomTable';

interface Customer {
  customer_name: string;
}
interface IPart {
  _id?: string;
  part_number: string | null;
  isSubmitted: 'draft' | 'publish';
  isAdminRequestNo: number | null;
  part_name: string | null;
  customer_id: string | null | Customer;
  key_contact: string | null;
  core_team: string | null;
  supplier: string | null;
  supplier_code: string | null;
  notes: string | null;
  date_orig: moment.Moment;
  createdAt?: Date;
  supplier_approval_date: moment.Moment;
  Other_approval_date: moment.Moment;
}
const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'Item Number', rowspan: 1, colspan: 1 },
    { text: 'Item Name/Description', rowspan: 1, colspan: 1 },
    { text: 'Created At', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const filterCategoryOptions = [
  { value: 'CASTING', name: 'CASTING' },
  { value: 'MACHINING', name: 'MACHINING' },
  { value: 'PRESS SHOP', name: 'PRESS SHOP' },
  { value: 'ROTOR', name: 'ROTOR' },
  { value: 'STACKING', name: 'STACKING' },
];

const PartTable = (props: any) => {
  const { moduleData, partIdx } = props;
  const [page, setPage] = useState(1);
  const [partData, setPartData] = useState<IPart[]>([]);
  const [perPage, setPerPage] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [customer, setCustomer] = useState<string>('null');
  const navigate = useNavigate();
  const [render, setRender] = useState(false);
  const [category, setCategory] = useState('null');
  const [filterCustomer, setFilterCustomer] = useState([]);
  useEffect(() => {
    axios
      .get('/api/customerRoutes/customerList/customerForAutoComplete')
      .then((res) => {
        if (res.data) {
          const formattedData = res.data.map((customer: any) => ({
            name: customer.customer_name,
            value: customer.customer_name,
          }));
          setFilterCustomer(formattedData);
        }
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const fetchPartData = () => {
    axios
      .get(
       
        `/api/addPartRoute/viewPpapPart/publish?page=${page}&category=${category}&customer=${customer}&search=${search}&perPage=${10}`
        // `/api/addPartRoute/byDocType/draft?page=${page}&category=${category}&customer=${customer}&search=${search}&perPage=${10}`
      )
      .then((res) => {
        console.log("part data is,",res?.data);
        setPerPage(res?.data?.perPage);
        setPartData(res?.data?.result);
        setTotalPages(res?.data?.pages);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchPartData, 300, [page, category, customer, search]);

  return (
    <Box>
      <Grid
        container
        spacing={2}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          width: '60vw',
          maxWidth: '65vw',
          margin: '0 10px 14px 10px',
        }}
      >
        <Grid xs={8}>
          <StateSearchBar label="Search Part" {...{ search, setSearch }} />
        </Grid>
        {/* <Grid xs={2} marginLeft={2}>
          <StateFilter
            options={filterCustomer}
            filter={customer}
            label="Filter By Customer"
            setFilter={setCustomer}
          />
        </Grid>
        <Grid xs={2} marginLeft={2}>
          <StateFilter
            options={filterCategoryOptions}
            label="Filter By Category"
            filter={category}
            setFilter={setCategory}
          />
        </Grid> */}
      </Grid>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {partData &&
            partData.map((item: IPart, index: number) => (
              <React.Fragment key={item?._id}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{item.part_number}</TableCell>
                  <TableCell align="center">{item.part_name}</TableCell>
                  <TableCell align="center">
                    {formatDate(item?.createdAt || '')}
                  </TableCell>
                  <TableCell align="center">
                    {moduleData.processes[partIdx].part_id === item._id ? (
                      <CustomIconButton
                        sx={{ p: 0 }}
                        tooltipTitle={'remove'}
                        onClick={() => {
                          moduleData.processes[partIdx].part_id = null;
                          moduleData.processes[partIdx].partName = null;
                          moduleData.processes[partIdx].partNumber = null;
                          setRender((prev) => !prev);
                        }}
                      >
                        <RemoveIcon style={{ color: '#c92a2a' }} />
                      </CustomIconButton>
                    ) : (
                      <CustomIconButton
                        sx={{ p: 0 }}
                        tooltipTitle={'add'}
                        onClick={() => {
                          moduleData.processes[partIdx].part_id = item?._id;
                          moduleData.processes[partIdx].partName =
                            item?.part_name;
                          moduleData.processes[partIdx].partNumber =
                            item?.part_number;
                          setRender((prev) => !prev);
                        }}
                      >
                        <Add style={{ color: '#343a40' }} />
                      </CustomIconButton>
                    )}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <StatePagination page={page} totalPage={totalPages} setPage={setPage} />
    </Box>
  );
};

export default PartTable;
