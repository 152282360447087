import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import SimpleSelect from '../../../../../components/common/SimpleSelect';
import CustomSelect from '../../../../../components/common/SelectField';
import { MenuItem, Stack } from '@mui/material';

interface IProps {
  item: any;
  isView: any;
  index: number;
  process_no?: any;
  machineData?: any;
}


const TBodyRow = ({ item, index, isView, process_no, machineData }: IProps) => {
  const [rowRender, setRowRender] = useState(false);
  const handleChange = (e: any) => {
    item[e.target.name] = e.target.value;
    setRowRender((prev: any) => !prev);
  };
// console.log("data is",item);
  return (
    <>
      <tr key={index}>
        {process_no >= 6 && (
          <th style={{ textAlign: 'center', background: '#748ffc' }}>
            <TableInput
              isView={isView}
              type="text"
              name="heading"
              value={item?.heading ?? null}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </th>
        )}
        {process_no == 3 && index == 0 && (
          <td style={{ textAlign: 'center', minWidth: '150px' }} rowSpan={8}>
            {isView ? (
              item.machine_id?.machine_no
            ) : (
              <AutocompleteMuiCustom
                id="machine_no"
                label=""
                option_name="machine_no"
                arrayofObj={machineData}
                value={item.machine_id || ''}
                onChange={(e, value) => {
                  item.machine_id = value;
                  setRowRender((prev: any) => !prev);
                }}
              />
            )}
          </td>
        )}
        {process_no == 3 && (
          <td style={{ textAlign: 'center', minWidth: '150px' }}>
            <TableInput
              isView={isView}
              type="text"
              name="parameter"
              value={item?.parameter ?? null}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </td>
        )}
        {process_no <= 2 && index <= 3 && (
          <td style={{ textAlign: 'center', minWidth: '150px' }} colSpan={2}>
            <TableInput
              isView={isView}
              type="text"
              name="section"
              value={item?.section ?? null}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </td>
        )}

        <td
          style={{ textAlign: 'center', minWidth: '400px' }}
          colSpan={process_no >= 4 || (process_no <= 2 && index == 4) ? 3 : 1}
        >
          
          <TableInput
            isView={ process_no ==6 ?true:isView}
            type="text"
            name="specification"
            // value={item?.specification ?? null}
            value={process_no === 6 ? "Visual" : item?.specification ?? null}

            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>
        {/* {item?.observation?.map((obs: any, obsIndex: any) => {
          if (
            (process_no == 1 && index <= 3) ||
            (process_no == 2 && index <= 3) ||
            (process_no == 3 &&
              (index <= 2 || index == 3 || index == 4 || index == 6)) ||
            (process_no == 5 && index == 0)
          ) {
            return (
              <td style={{ textAlign: 'center', minWidth: '100px' }}>
               
               
                <TableInput
                  isView={isView}
                  type="text"
                  name={`observation${obsIndex}`}
                  value={item?.observation[obsIndex] ?? null}
                  onChange={(e) => {
                    item.observation[obsIndex] = e.target.value;
                    setRowRender((prev) => !prev);
                  }}
                />
              </td>
            );
          } else {
            return (
              <td>
                {isView ? (
                  item.observation[obsIndex]
                ) : (
                  <SimpleSelect
                    name={`observation${obsIndex}`}
                    value={item?.observation[obsIndex]}
                    onChange={(e: any) => {
                      item.observation[obsIndex] = e.target.value;
                      setRowRender((prev) => !prev);
                    }}
                    options={[
                      { value: '', label: 'NONE' },
                      { value: 'OK', label: 'OK' },
                      { value: 'NOT OK', label: 'NOT OK' },
                    ]}
                  />
                )}
              </td>
            );
          }
        })} */}

        {/* added for Visual Text */}

        {item?.observation?.map((obs: any, obsIndex: any) => {
          if (process_no == 6 && index===10) {
            // Static Visual text for process 5 and index 0
            return (
              <td style={{ textAlign: 'center', minWidth: '100px' }}>Visual</td>
            );
          } else if (
            (process_no == 1 && index <= 3) ||
            (process_no == 2 && index <= 3) ||
            (process_no == 3 &&
              (index <= 2 || index == 3 || index == 4 || index == 6))
          ) {
            return (
              <td style={{ textAlign: 'center', minWidth: '100px' }}>
                {/* Visual */}
                <TableInput
                  isView={isView}
                  type="text"
                  name={`observation${obsIndex}`}
                  value={item?.observation[obsIndex] ?? null}
                  onChange={(e) => {
                    item.observation[obsIndex] = e.target.value;
                    setRowRender((prev) => !prev);
                  }}
                />
              </td>
            );
          } else {
            return (
              <td>
                {isView ? (
                  item.observation[obsIndex]
                ) : (
                  <SimpleSelect
                    name={`observation${obsIndex}`}
                    value={item?.observation[obsIndex]}
                    onChange={(e: any) => {
                      item.observation[obsIndex] = e.target.value;
                      setRowRender((prev) => !prev);
                    }}
                    options={[
                      { value: '', label: 'NONE' },
                      { value: 'OK', label: 'OK' },
                      { value: 'NOT OK', label: 'NOT OK' },
                    ]}
                  />
                )}
              </td>
            );
          }
        })}

        {process_no == 1 && index == 0 && (
          <td style={{ textAlign: 'center', minWidth: '200px' }} rowSpan={4}>
            {/* <TableInput
              isView={isView}
              type="text"
              name="status"
              value={item?.status ?? null}
              onChange={(e) => {
                handleChange(e);
              }}
            /> */}
            <CustomSelect
              name="status"
              value={item?.status || ''}
              onChange={(e) => {
                handleChange(e);
              }}
            >
              <MenuItem value="OK">OK</MenuItem>
              <MenuItem value="NOT OK">NOT OK</MenuItem>
            </CustomSelect>
          </td>
        )}
        {process_no == 4 && index == 0 && (
          <td style={{ textAlign: 'center', minWidth: '200px' }} rowSpan={2}>
            {/* <TableInput
              isView={isView}
              type="text"
              name="status"
              value={item?.status ?? null}
              onChange={(e) => {
                handleChange(e);
              }}
            /> */}
            <CustomSelect
              name="status"
              value={item?.status || ''}
              onChange={(e) => {
                handleChange(e);
              }}
            >
              <MenuItem value="OK">OK</MenuItem>
              <MenuItem value="NOT OK">NOT OK</MenuItem>
            </CustomSelect>
          </td>
        )}
        {process_no == 5 && index == 1 && (
          <td style={{ textAlign: 'center', minWidth: '200px' }} rowSpan={3}>
            {/* <TableInput
              isView={isView}
              type="text"
              name="status"
              value={item?.status ?? null}
              onChange={(e) => {
                handleChange(e);
              }}
            /> */}
            <CustomSelect
              name="status"
              value={item?.status || ''}
              onChange={(e) => {
                handleChange(e);
              }}
            >
              <MenuItem value="OK">OK</MenuItem>
              <MenuItem value="NOT OK">NOT OK</MenuItem>
            </CustomSelect>
          </td>
        )}
        {(process_no == 2 ||
          process_no == 3 ||
          process_no == 6 ||
          process_no == 7 ||
          (process_no == 1 && index == 4) ||
          (process_no == 5 && index == 0)) && (
          <td style={{ textAlign: 'center', minWidth: '200px' }}>
            {/* <TableInput
              isView={isView}
              type="text"
              name="status"
              value={item?.status ?? null}
              onChange={(e) => {
                handleChange(e);
              }}
            /> */}
            <CustomSelect
              name="status"
              value={item?.status || ''}
              onChange={(e) => {
                handleChange(e);
              }}
            >
              <MenuItem value="OK">OK</MenuItem>
              <MenuItem value="NOT OK">NOT OK</MenuItem>
            </CustomSelect>
          </td>
        )}
      </tr>
    </>
  );
};

export default TBodyRow;
