import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import { Checkbox } from '@mui/material';
import { CustomButton } from '../../../../../../components/common/button/CustomButtons';
import { DeleteIconButton } from '../../../../../../components/common/button/CustomIconButton';
interface IProps {
  moduleData: any;
  handelDeleteRows: any;
  isView: boolean;
}
const TBody4 = ({ moduleData, isView, handelDeleteRows }: IProps) => {
  const [render, setRender] = useState(false);

  const options = ['Begginer', 'Practitioner', 'Experts'];
  return (
    <tbody>
      <tr style={{ background: '#fff2cc' }}>
        <th colSpan={5}>
          Ideal evaluation of the role in terms of behavioural /leadership
          competencies
        </th>
        <th>
          {!isView && (
            <CustomButton
              sx={{
                backgroundColor: '#1c7ed6',
              }}
              onClick={async () => {
                // const ans = await confirmAddRow();
                // if (!ans) return;
                moduleData.process_5.push({});
                setRender((prev) => !prev);
              }}
            >
              ADD ROWS
            </CustomButton>
          )}
        </th>
      </tr>
      <tr>
        <th rowSpan={2}>S. NO</th>
        <th rowSpan={2}>Behavioural Competencies::</th>
        <th colSpan={4}>Ideal Proficiency Level</th>
      </tr>
      <tr>
        <th>Beginner</th>
        <th>Practitioner</th>
        <th>Experts</th>
        <th>Action</th>
      </tr>
      {moduleData?.process_5 &&
        moduleData.process_5.map((item: any, index: number) => (
          <tr key={index + 'specs'}>
            <td>{index + 1}</td>
            <td>
              <TableInput
                isView={isView}
                name="technical_competencies"
                type="text"
                value={item.technical_competencies}
                onChange={(e) => {
                  item.technical_competencies = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            {options.map((option) => (
              <td>
                <Checkbox
                  checked={item.proficiency_level === option}
                  onChange={() => {
                    item.proficiency_level = option;
                    setRender((prev) => !prev);
                  }}
                  disabled={isView}
                />
              </td>
            ))}
            <td style={{ minWidth: '100px', textAlign: 'center' }}>
              <DeleteIconButton
                tooltipTitle={'delete'}
                onClick={() => handelDeleteRows('process_5', index)}
              />
            </td>
          </tr>
        ))}
    </tbody>
  );
};

export default TBody4;
