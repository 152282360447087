import React, { useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { GraphHead } from '../GraphHeading';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController
);

interface IProps {
  xTitle?: any;
  yTitle?: any;
  title?: any;
  datas?: any;
  labels?: any;
  type?: any;
  headTitle?: any;
  borderColor?: any;
  borderWidth?: any;
  fill?: any;
  stepSize?: any;
  borderTitle?: any;
  width?: any;
  margin?: any;
  otherType?: any;
  otherLabel?: any;
  otherData?: any;
  otherFill?: any;
  otherBorderColor?: any;
  otherBorderWidth?: any;
}
const BarLineChart = ({
  xTitle,
  yTitle,
  datas,
  labels,
  type,
  headTitle,
  borderColor,
  borderWidth,
  fill,
  stepSize,
  borderTitle,
  width,
  margin,
  otherType,
  otherLabel,
  otherData,
  otherFill,
  otherBorderColor,
  otherBorderWidth,
}: IProps) => {
  const options: any = {
    plugins: {
      maintainAspectRatio: false,
      title: {
        display: true,
        text: '',
      },
    },
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: xTitle,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: stepSize,
        },
        title: {
          display: true,
          text: yTitle,
        },
      },
    },
  };
  const datasets = [
    {
      type: type,
      label: headTitle,
      fill: fill,
      borderColor: borderColor,
      borderWidth: borderWidth,
      data: datas,
    },
  ];
  if (otherType) {
    datasets.push({
      type: otherType,
      label: otherLabel,
      fill: otherFill,
      borderColor: otherBorderColor,
      data: otherData,
      borderWidth: otherBorderWidth,
    });
  }
  const data: any = {
    labels: labels,
    datasets: datasets,
  };

  return (
    <div>
      <GraphHead style={{ width, margin }}>
        <p>{borderTitle}</p>
        <Bar data={data} options={options} />
      </GraphHead>
    </div>
  );
};

export default BarLineChart;
