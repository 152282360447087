import { Box } from '@mui/system';
import axios from 'axios';
import { useState } from 'react';
import { useQuery } from '../../../hooks/UseQuery';
import TController from './create/TController';
import useGetData from './helpers/useGetData';
import { Loader } from '../../../components/common/Loader';
import HeaderCreate from './HeaderCreate';
import { moduleConstant } from './constant';
import useConfirm from '../../../components/common/useConfirm';
import ModalCustom from '../../../components/common/Modal';
import PickPart from '../../../components/ui/PickPart';
import { PickRIpart } from '../../../components/ui';

interface MyData {
  moduleData: any;
  isLoading: boolean;
  setModuleData: any;
  setRenderforUpdate: any;
  setParentRender: React.Dispatch<React.SetStateAction<boolean>>;
  error?: string | null;
  defects: any;
}
const Create = () => {
  const date = useQuery('date');
  // const isView = useQuery('isView') === 'true' ? true : false;
  const [isView, setisView] = useState<boolean>(false);
  const [partIdx, setPartIdx] = useState<number>(0);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [DialogUpdate, confirmUpdate] = useConfirm(
    'Save',
    'Are you sure you want to save?'
  );
  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this row?'
  );

  const {
    error,
    moduleData,
    setModuleData,
    isLoading,
    setParentRender,
    setRenderforUpdate,
    defects,
  }: MyData = useGetData();

  const handleUpdate = async () => {
    if (!date) {
      alert('date required');
      return;
    }
    const ans = await confirmUpdate();
    if (!ans) return;

    axios
      .post(`${moduleConstant.apiConstant}?date=${date}`, moduleData)
      .then((res) => {
        setRenderforUpdate((prev: any) => !prev);
        alert('successfully updated');
      })
      .catch((err) => console.error(err));
  };

  const handleDelete = async (id: string, rowIndex: number) => {
    const ans = await confirmDelete();
    if (!ans) return;
    if (!id) {
      const values = [...moduleData];
      values.splice(rowIndex, 1);
      setModuleData(values);
      alert('row deleted successfully');
      return;
    }
    await axios
      .delete(`${moduleConstant.apiConstant}/${id}`)
      .then((res) => {
        if (res.data) {
          const values = [...moduleData];
          values.splice(rowIndex, 1);
          setModuleData(values);
          alert('row deleted successfully');
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Box>
      <Loader loader={isLoading} />
      <HeaderCreate
        {...{
          handleUpdate,
          moduleData,
          setModuleData,
        }}
      />
      <TController
        {...{
          moduleData,
          isView,
          handleDelete,
          setModuleData,
          setParentRender,
          setPartIdx,
          setIsOpenPopup,
          defects,
        }}
      />
      <ModalCustom
        title="RI Part List"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <PickRIpart moduleData={moduleData?.[partIdx]} />
      </ModalCustom>
      <DialogUpdate isSubmitContent={false} />
      <DialogDelete isSubmitContent={false} />
    </Box>
  );
};

export default Create;
