// import criticalParameters from '../../img/criticalParameters.png';
// import minorParameters from '../../img/minorParmeters.png';
// import outSourceProcess from '../../img/outSourceProcess.png';
const TFoot = () => {
  return (
    <tfoot>
      {/* <tr>
        <th rowSpan={2}>Legends:</th>
        <th>Supplier End</th>
        <th>Customer End</th>
        <th colSpan={2}>Minor Parameters</th>
        <th colSpan={2}>Critical Parmeters </th>
        <th colSpan={2}>Outsource Process </th>
        <th colSpan={4}> </th>
        <th colSpan={4}> </th>
      </tr>
      <tr>
        <th>SE</th>
        <th>CE</th>
        <th colSpan={2}>
          <img
            src={minorParameters}
            alt="minor parameteres"
            height={30}
            width={50}
          />
        </th>
        <th colSpan={2}>
          {' '}
          <img
            src={criticalParameters}
            alt="critical parameteres"
            height={30}
            width={50}
          />
        </th>
        <th colSpan={2}>
          {' '}
          <img
            src={outSourceProcess}
            alt="outsource process"
            height={30}
            width={50}
          />
        </th>
        <th colSpan={4}> Prepared By:</th>
        <th colSpan={4}> Approved By:</th>
      </tr> */}
      <tr>
        <th colSpan={1} align="left">
          {' '}
          Legend
        </th>
        <th colSpan={6} align="left">
          {' '}
          C = Critical , M = Major , Mi = Minor
        </th>
        <th colSpan={9 + 3}></th>
      </tr>
      <tr>
        <th colSpan={7 + 2}> Prepared By:</th>
        <th colSpan={9 + 1}> Approved By:</th>
      </tr>
      <tr>
        <th colSpan={7 + 2}>FM-QAD-16 -A</th>
        <th colSpan={9 + 1}> All Dim. In mm Unless Specifide Other wise </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
