import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
import { IData } from '../../helpers/interface';
import { useState } from 'react';

interface IProps {
  moduleData: IData;
  isView: boolean;
  setPartIdx: any;
  moulders: any;
  setShift: any;
  employees: any;
  handelDeleteRows: any;
  operators: any;
}

const TController = ({
  moduleData,
  isView,
  handelDeleteRows,
  moulders,
  setShift,
  employees,
  operators,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
            setShift,
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
            handelDeleteRows,
            setRender,
            moulders,
            employees,
            operators,
          }}
        />
        <TFoot moduleData={moduleData} setRender={setRender} />
      </RenderTable>
    </Box>
  );
};

export default TController;
