import { useState } from 'react';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import TBodySpec from './TBodySpec';

interface IProps {
  moduleData: any;
  process: any;
  pIdx: number;
  setRenderParent: any;
  tabValue: number;
  isView: boolean;
}
const TBody = ({
  moduleData,
  process,
  pIdx,
  tabValue,
  isView,
  setRenderParent,
}: IProps) => {
  const [render, setRender] = useState(false);

  const handleAllCheck = (e: any, pIdx: number) => {
    let value = e.target.checked;
    moduleData.processes[pIdx][`all_${[e.target.name]}`] = value;
    moduleData.processes[pIdx].specs.forEach((specs: any) => {
      specs[e.target.name] = value;
    });
    setRender((prev) => !prev);
  };

  const handleSortingProcess = () => {
    moduleData.processes?.sort(
      (a: any, b: any) => Number(a.s_no) - Number(b.s_no)
    );
    setRenderParent((prev: any) => !prev);
  };

  const handleSortingSpec = () => {
    moduleData?.processes?.[pIdx]?.specs?.sort(
      (a: any, b: any) => Number(a.s_no) - Number(b.s_no)
    );
    setRender((prev: any) => !prev);
  };

  return (
    <>
      <tr style={{ background: '#74c0fc' }} key={`${pIdx}-process`}>
        <th
          style={{
            width: '100px',
          }}
        >
          <TextField
            label=""
            variant="outlined"
            type="number"
            size="small"
            value={process?.s_no || ''}
            onChange={(e) => {
              process.s_no = +e.target.value;
              handleSortingProcess();
            }}
            sx={{ marginRight: 2, flex: 'none', width: '100px' }}
          />
        </th>
        <th style={{ minWidth: '60px' }}>{process?.process_no}.</th>
        <th style={{ minWidth: '150px' }}>{process?.process_name}</th>
        <th>
          <FormControlLabel
            control={
              <Checkbox
                checked={process?.all_spec_to_pir === true}
                onChange={(e) => handleAllCheck(e, pIdx)}
              />
            }
            name="spec_to_pir"
            label="Add All To Line Inspection"
            sx={{ marginRight: 2 }}
          />
        </th>
        <th>
          <FormControlLabel
            control={
              <Checkbox
                checked={process?.all_spec_to_pdir === true}
                onChange={(e) => handleAllCheck(e, pIdx)}
              />
            }
            label="Add All To Final Inspection"
            name="spec_to_pdir"
            sx={{ marginRight: 2 }}
          />
        </th>
        <th>
          <FormControlLabel
            control={
              <Checkbox
                checked={process?.all_spec_to_setup === true}
                onChange={(e) => handleAllCheck(e, pIdx)}
              />
            }
            name="spec_to_setup"
            label="Add All To FPA / LPA"
            sx={{ marginRight: 2 }}
          />
        </th>
      </tr>
      {process?.specs.map((spec: any, sIdx: number) => (
        <TBodySpec {...{ moduleData, spec, sIdx, pIdx, handleSortingSpec }} />
      ))}
    </>
  );
};

export default TBody;
