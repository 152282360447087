import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { Checkbox, Grid } from '@mui/material';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import { TableInput } from '../../../../../components/common';
interface IProps {
  moduleData: any;
  isView: boolean;
  setIsOpenPopup: any;
}

const theadData = [
  'DEPT',
  'DATE',
  'M/c No.',
  'Operator / Inspector Name ',
  'QTY PRODUCED / RECEIVED',
  'QTY. Rejected',
  'OK Qty. Processed',
  'Defect',
  'Sign of Supervisor ',
  'Remarks',
];

const THead = ({ moduleData, isView, setIsOpenPopup }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <thead>
      <TableCompHead
        colSpan={10}
        rowSpan={2}
        labels={['DOC NO.', 'Rev. no. & Dt']}
        values={['F - 20 - 01', '02/01.04.2024']}
        heading="JOB CARD"
      />
      <tr>
        <th colSpan={5} align="left">
          PART NAME :{' '}
          {isView ? (
            moduleData?.part_id && moduleData?.part_id?.part_name
          ) : (
            <Grid container xs={12} direction="row" alignItems={'center'}>
              <Grid item xs={10}>
                <p
                  style={{
                    fontSize: '1.2rem',
                  }}
                >
                  {typeof moduleData?.part_id === 'object' &&
                  moduleData?.part_id !== null
                    ? moduleData?.part_id?.part_name
                    : moduleData?.partName || ''}
                  {/* {!moduleData?.part_id && 'PART NAME'} */}
                </p>
              </Grid>

              {moduleData.part_id ? (
                <Grid item xs={2}>
                  <RemoveIconButton
                    tooltipTitle="remove part"
                    onClick={() => {
                      moduleData.part_id = null;
                      moduleData.partNumber = null;
                      moduleData.partName = null;
                      moduleData.customerName = null;
                      setRender((prev) => !prev);
                    }}
                  />
                </Grid>
              ) : (
                <Grid item xs={2}>
                  <AddIconButton
                    tooltipTitle="add part"
                    onClick={() => {
                      setIsOpenPopup(true);
                    }}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </th>
        <th colSpan={5} align="left">
          Mix No:-
          <TableInput
            isView={isView}
            name="mix_no"
            type="text"
            value={moduleData?.mix_no}
            onChange={(e) => {
              moduleData.mix_no = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={5} align="left">
          DATE :{' '}
          {isView ? (
            formatDate(moduleData.module_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.module_date)}
              onChange={(date: any) => {
                moduleData.module_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={5} align="left">
          Batch No.:-
          <TableInput
            isView={isView}
            name="batch_no"
            type="text"
            value={moduleData?.batch_no}
            onChange={(e) => {
              moduleData.batch_no = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>

      <tr style={{ background: '#38d9a9', height: '60px' }}>
        {theadData.map((heading, index) => (
          <th key={heading + index} colSpan={1}>
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
