export const listConstant = {
  childHeader: 'SUPPLIER AUDIT list',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'SUPPLIER AUDIT list',
  theadHeader: ' SUPPLIER AUDIT',
  extracolSpan: 1,
};

export const apiConstant = {
  // list api url
  gettableListDataurl: 'supplierAuditPlanRoute/monthlylist',
  deleteListurl: 'supplierAuditPlanRoute',
  getListPlanByid: 'supplierAuditPlanRoute',
  createListPlanByid: 'supplierAuditPlanRoute',

  // -----plan--
  sync: 'supplierAuditPlanRoute/handleSync',
  getAllPlanById: 'supplierAuditPlanRoute/allplansbyid',
  upadateCreateAllPlan: 'supplierAuditPlanRoute/allplansCreateUpdate',
  maintenancePlanDeleteBulkById:
    'supplierAuditPlanRoute/maintenancePlanDeleteBulkById',
};

export const tilesConstant = {
  childHeaderText: 'SUPPLIER AUDIT',
};

export const navigationConstant = {
  planRouter: '/common/supplierAuditPlanPlanCreate',
  statusPageLink: `/common/supplierAuditPlanStatus`,
};
