import React, { useEffect, useState } from 'react';
import TController from './components/view/TController';
import { IData } from './helpers/interface';
import { initialState } from './helpers/initialState';
import PartTable from './components/view/PartTable';
import lodash, { forEach } from 'lodash';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import {
  CustomButton,
  SaveButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import { useAuth } from '../../../services/auth/AuthProvider';
import { useQuery } from '../../../hooks/UseQuery';
// import { processes } from './helpers/initialState';
// import PartTable from './components/view/PartTable';
import ModalCustom from '../../../components/common/Modal';
import useConfirm from '../../../components/common/useConfirm';
import RejectionTable from './components/view/RejectionTable';

const View = () => {
  const [moduleData, setModuleData] = useState<any>(
    lodash.cloneDeep(initialState)
  );
  const [rejectionsIndx, setRejectionsIndx] = useState(0);
  const [isOpenPopupForRejections, setIsOpenPopupRejections] = useState(false);
  const [render, setRender] = useState(false);
  const [def,setDef]=useState([]);
  const [partIdx, setPartIdx] = useState(false);
  const navigate = useNavigate();
  const { state } = useAuth();
  const { id } = useParams();
  const isView = useQuery('isView') === 'true' ? true : false;
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const documentType = useQuery('documentType') || '';
  const [autoCompleteMachineValue, setAutoCompleteMachineValue] = useState<any>(
    Array.from({ length: 5 }, () => lodash.cloneDeep({}))
  );
  const heading = useQuery('heading') || '';

  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?'
  );
  const [DialogAddRow, confirmAddRow] = useConfirm(
    'Add Row',
    'Are you sure you want to Add Row?'
  );
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?'
  );
  const [DialogSummary, confirmSummary] = useConfirm(
    'Submit',
    'Are you sure you want to add this to 4M summary?'
  );
  console.log("inside the view.tsx page ");
  useEffect(() => {
     
    axios.get('/api/productionDefectRoutes/defectList/defectForAutoComplete')
    .then((res)=>{
      console.log(res?.data);
      setDef(res?.data);
    })
    .catch((err)=>{
      console.log(err);
    })
   
    

  }, [])
  useEffect(() => {
    console.log("id is",id);
    if (id) {
      axios
        .get(`/api/redbinPartRoutes/${id}`)
        .then((res) => {
          console.log("res.data is",res?.data);

          setModuleData(res.data);
          const machine = res?.data?.processes.map(
            (process: any, index: number) => process.mc_no || {}
          );

          setAutoCompleteMachineValue(machine);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const handleSumbit = async (isSubmitted: boolean, submitType: string) => {
    const ans = await confirmSubmit();
    if (!ans) return;
    moduleData.isSubmitted = isSubmitted;
    moduleData.is_admin_request = 'pending';
    if (id) {
      await axios
        .put(`/api/redbinPartRoutes/${id}`, moduleData)
        .then((res) => {
          console.log("res.data is",res?.data);
          if (res.data.status === 'success') {
            alert('Updated Successfully');
            navigate(-1);

          }
        })
        .catch((err) => console.log(err));
    } else {
      moduleData.document_type = documentType;
      await axios
        .post('/api/redbinPartRoutes', moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('created Successfully');
            console.log("data is",res?.data);
            navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handelDeleteRows = async (rowIdx: number,x:any) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    let data = { ...moduleData };
    
   x==2? data.employees.splice(rowIdx, 1) : data.processes.splice(rowIdx, 1);
    autoCompleteMachineValue.splice(rowIdx, 1);
    setModuleData(data);
  };

  return (
    <>
      <Box>
        <ChildHeader text="
REDBIN ANALYSIS REPORT">
          {!isView && (
            <>
              
              {state.user?.userType === state.IS.Supervisor && (
                <SaveButton
                  label="Save & Continue"
                  onClick={() => handleSumbit(false, 'forDailyProduction')}
                />
              )}
              <SubmitButton
                label="Submit"
                onClick={() => handleSumbit(true, 'forDailyProduction')}
              />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
          setModuleData,
          def,
          handleSumbit,
          autoCompleteMachineValue,
          handelDeleteRows,
          setIsOpenPopupRejections,
          setRejectionsIndx,
          isOpenPopup,
          setIsOpenPopup,
          setPartIdx,
        }}
      />

      <ModalCustom
        title="Part List"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <PartTable
          {...{
            moduleData,
            partIdx,
          }}
        />
      </ModalCustom>
      <ModalCustom
        title="Rejections List"
        openModal={isOpenPopupForRejections}
        closeModal={() => {
          setIsOpenPopupRejections(false);
        }}
      >
        <RejectionTable
          process={moduleData.processes[rejectionsIndx]}
          isView={isView}
        />
      </ModalCustom>
      <DialogDeleteRow isSubmitContent={false} />
      <DialogAddRow />
      <DialogSubmit />
      <DialogSummary />
    </>
  );
};

export default View;
