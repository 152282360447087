import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import moment from 'moment';
import { DeleteIconButton } from '../../../../components/common/button/CustomIconButton';
import CustomSelect from '../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';

import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';

interface IProps {
  item: any;
  isView: any;
  handleDelete: any;
  index: number;
  firstAutoCompleteData: any;
}

const TBodyRow = ({
  item,
  index,
  isView,
  handleDelete,
  firstAutoCompleteData,
}: IProps) => {
  const [rowRender, setRowRender] = useState(false);

  const handleChange = (e: any) => {
    item[e.target.name] = e.target.value;
  };

  return (
    <>
      <tr>
        <td style={{ textAlign: 'center', width: '180px' }}>
          <DatePickerMui
            label=""
            onChange={(date) => {
              item.date = moment(date || null);
              setRowRender((prev: any) => !prev);
            }}
            value={(item?.date && moment(item?.date)) || null}
          />
        </td>
        <td style={{ width: '250px', minWidth: '250px' }}>
          <AutocompleteMuiCustom
            id="instrument_id"
            option_name="name"
            arrayofObj={firstAutoCompleteData || []}
            value={item?.instrument_id || ''}
            onChange={(e, value) => {
              item.instrument_id = value;
              setRowRender((prev: any) => !prev);
            }}
          />
        </td>
        <td>{item?.instrument_id?.id_no}</td>
        <td>{item?.instrument_id?.range}</td>
        <td style={{ textAlign: 'center', width: '150px' }}>
          <TableInput
            isView={isView}
            type="number"
            name="checking_gauage_master_reading"
            value={item?.checking_gauage_master_reading ?? null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center', width: '150px' }}>
          <TableInput
            isView={isView}
            type="number"
            name="master_reading"
            value={item?.master_reading ?? null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center', width: '150px' }}>
          <TableInput
            isView={isView}
            type="number"
            name="actual_reading"
            value={item?.actual_reading ?? null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center', width: '150px' }}>
          <CustomSelect
            name={'status'}
            value={item?.status}
            sx={{ width: '150px' }}
            onChange={(e) => {
              item.status = e.target.value;
              setRowRender((prev) => !prev);
            }}
          >
            <MenuItem value="">NONE</MenuItem>
            <MenuItem value="COMPLETE">COMPLETE</MenuItem>
            <MenuItem value="PENDING">PENDING</MenuItem>
          </CustomSelect>
        </td>
        <td style={{ textAlign: 'center', width: '150px' }}>
          <TableInput
            isView={isView}
            type="text"
            name="observer_name"
            value={item?.observer_name || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td
          style={{ textAlign: 'center', width: '150px' }}
          colSpan={isView ? 2 : 1}
        >
          <TableInput
            isView={isView}
            type="text"
            name="remarks"
            value={item?.remarks || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        {!isView && (
          <td style={{ textAlign: 'center' }}>
            <DeleteIconButton
              tooltipTitle="DELETE"
              onClick={() => {
                handleDelete(index);
              }}
            />
          </td>
        )}
      </tr>
    </>
  );
};

export default TBodyRow;
