import { useState } from 'react';
import { TableInput } from '../../../../../components/common';

interface Ifoot {
  moduleData: any;
  isView: boolean;
}
const TFoot = ({ moduleData, isView }: Ifoot) => {
  const [render, setRender] = useState(false);

  return <tfoot></tfoot>;
};

export default TFoot;
