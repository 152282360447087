import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ModalCustom from '../../../../../components/common/Modal'; // Adjust the import path as needed
import VisibilityIcon from '@mui/icons-material/Visibility'; // Import the view icon

interface Attendee {
  employee_id: string;
  employee_name: string;
}

interface AttendeesModalProps {
  open: boolean;
  handleClose: () => void;
  attendees: Attendee[];
}

const AttendeesModal: React.FC<AttendeesModalProps> = ({ open, handleClose, attendees }) => {
  return (
    <ModalCustom
      title="Attendees"
      openModal={open}
      closeModal={handleClose}
      isModalCloseBtn={true}
    >
      <TableContainer sx={{minWidth:'500px'}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S.No.</TableCell>
              <TableCell>Employee Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attendees.map((attendee, index) => (
              <TableRow key={attendee.employee_id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{attendee.employee_name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ModalCustom>
  );
};

export default AttendeesModal;