import { useState } from 'react';
import { TableInput } from '../../../../../components/common';

interface Ifoot {
  moduleData: any;
  isView: boolean;
}
const TFoot = ({ moduleData, isView }: Ifoot) => {
  const [render, setRender] = useState(false);

  return (
    <tfoot>
      <tr>
        <th align="left" colSpan={5}>
          Checked By :
          <TableInput
            isView={isView}
            name="checked_by"
            type="text"
            value={moduleData?.checked_by}
            onChange={(e) => {
              moduleData.checked_by = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th align="left" colSpan={5}>
          Approved By :
          <TableInput
            isView={isView}
            name="approved_by"
            type="text"
            value={moduleData?.approved_by}
            onChange={(e) => {
              moduleData.approved_by = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={10} align="left">
          NOTE : CHECKING FREQUENCY ONCE IN A MONTH.
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
