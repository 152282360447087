import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import { IData } from './helpers/Interface';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [moduleData, setModuleData] = useState<IData>({
    model_name: '',
    customer_id: {
      customer_name: '',
    },
  });
  const [customerData, setCustomerData] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [autoCompleteCustomerValue, setAutoCompleteCustomerValue] = useState();

  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Model' : 'Create Model'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  useEffect(() => {
    axios
      .get('/api/customerRoutes/')
      .then((res) => {
        setCustomerData(res.data.results);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/modelsRoutes/${id}`)
        .then((res) => setModuleData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleModuleData(e: React.ChangeEvent<HTMLInputElement>) {
    setModuleData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleSumbit = async () => {
    setIsSubmit(true);
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/modelsRoutes/${id}`, moduleData)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/modelsRoutes', moduleData)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
        })
        .catch((err) => {
          setIsSubmit(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'model_name'}
        name={'model_name'}
        html_for={'model_name'}
        label_name={'Model Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.model_name || ''}
        onChange={handleModuleData}
      />
      <AutocompleteGridmui
        id="customer_name"
        label="Customer"
        option_name="customer_name"
        arrayofObj={customerData}
        value={autoCompleteCustomerValue || moduleData.customer_id}
        onChange={(e, value) => {
          if (value) {
            moduleData.customer_id = value?._id;
          }
          setAutoCompleteCustomerValue(value);
        }}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
