import { useState } from 'react';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import { Grid, MenuItem } from '@mui/material';
import CustomSelect from '../../../../../components/common/SelectField';
import { convToTime } from '../../../../../utils/helpers';
import { TimePicker } from '@mui/x-date-pickers';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
}

const TBody = ({ moduleData, isView, handelDeleteRows }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <tr key={index + 'module data'}>
              <td width={'100px'} style={{ fontWeight: 'bold' }}>
                {index + 1}
              </td>
              <td style={{ minWidth: '250px' }}>
                <TableInput
                  isView={isView}
                  name="operation_stage"
                  type="text"
                  value={process?.operation_stage}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '150px' }}>
                <TableInput
                  isView={isView}
                  name="illuminance_level"
                  type="number"
                  value={process?.illuminance_level}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              {process?.observation?.map((obs: any, obsIndex: any) => {
                return (
                  <td style={{ minWidth: '100px' }}>
                    <TableInput
                      isView={isView}
                      name={`obs${obsIndex}`}
                      type="text"
                      value={process?.observation?.[obsIndex]}
                      onChange={(e) => {
                        process.observation[obsIndex] = e.target.value;
                        setRender((prev) => !prev);
                      }}
                    />
                  </td>
                );
              })}
              {!isView && (
                <td style={{ minWidth: '100px' }}>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
