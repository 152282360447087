import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../../components/ui';
import { headFirst, headSecond, headThird } from '../../helpers/headData';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={6}
        rowSpan={2}
        labels={['Doc No', 'Issued On :']}
        values={['FM/XLV/TR026', '06.01.2022']}
        heading="JOB DESCRIPTION"
      />
      <tr>
        <th colSpan={3}>NAME</th>
        <th colSpan={3}></th>
      </tr>
      <tr>
        <th align="left">Man Profile</th>
        <th align="left">Designation</th>
        <th align="left">
          {moduleData?.designation_id?.designation || moduleData?.designation}
        </th>
        <th align="left">Qualification</th>
        <th align="left" colSpan={2}></th>
      </tr>
      <tr>
        <th align="left">Department</th>
        <th colSpan={2} align="left"></th>
        <th align="left">Experience</th>
        <th align="left" colSpan={2}></th>
      </tr>
      <tr>
        <th align="left">Level</th>
        <th colSpan={2} align="left"></th>
        <th align="left">Preferred age</th>
        <th align="left" colSpan={2}></th>
      </tr>
      <tr>
        <th colSpan={6}>Reporting to whom: </th>
      </tr>
      <tr style={{ height: '40px' }}>
        <th colSpan={6}></th>
      </tr>
    </thead>
  );
};

export default THead;
