import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
import { useQuery } from '../../../../../hooks/UseQuery';
import TheadChange4m from '../../component4mChange/create/TheadChange4m';
import TbodyChange4m from '../../component4mChange/create/TbodyChange4m';
import TfootChange4m from '../../component4mChange/create/TfootChange4m';

interface IProps {
  moduleData: any;
  isView: boolean;
  operation: string[];
  getProcess: any;
  machineData:any;
  moduleData1:any;
}

const TController = ({ moduleData, isView, operation, getProcess,machineData,moduleData1 }: IProps) => {
  const documentType = useQuery('documentType') || '';
  return (
    <Box>
      <RenderTable>
        {documentType === 'change4m' ? (
          <TheadChange4m
            {...{
              moduleData,
              getProcess,
              isView,
              operation,
            }}
          />
        ) : (
          <THead
            {...{
              moduleData,
              moduleData1,
              getProcess,
              isView,
              operation,
            }}
          />
        )}
        {documentType === 'change4m' ? (
          <TbodyChange4m
            {...{
              moduleData,
              isView,
            }}
          />
        ) : (
          <TBody
            {...{
              moduleData,
              machineData,
              isView,
            }}
          />
        )}

        {documentType === 'change4m' ? (
          <TfootChange4m
            {...{
              moduleData,
              isView,
            }}
          />
        ) : (
          <TFoot
            {...{
              moduleData,
              isView,
            }}
          />
        )}
      </RenderTable>
    </Box>
  );
};

export default TController;
