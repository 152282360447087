import { useState } from 'react';
import CustomSelect from '../../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import { TableInput } from '../../../../../../components/common';

interface IProps {
  apiData: any;
}
const TBody = ({ apiData }: IProps) => {
  const [render, setRender] = useState(false);
  const { dashBoardMonthCounts, totalOfMonth } = apiData;
  return (
    <tbody>
      {dashBoardMonthCounts.map((data: any) => {
        return (
          <tr>
            <td>{data.customerName}</td>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => (
              <td style={{ color: data[month] > 0 ? 'red' : 'green' }}>
                {data[month]}
              </td>
            ))}
          </tr>
        );
      })}

      <tr>
        <td>Total</td>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => (
          <td>{totalOfMonth[month]}</td>
        ))}
      </tr>
    </tbody>
  );
};

export default TBody;
