import { useState } from 'react';
import { TableInput } from '../../../../../components/common';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const TFoot = ({ isView, moduleData }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tfoot style={{ background: '#a9e34b' }}>
      <tr>
        <th align="left" colSpan={11}>
          NOTE : * FIRST PCS APPROVAL / SETUP TO BE COMPELETED / DONE BEFORE
          9.30 AM / PM ( 1. 5 HRS ) * PUNCHING DETAIL SHOULD BE MADE IN SEPARATE
          REPORT/SHEET (HO-F-QAS-173)
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={11}>
          THE MACHINE IS CLEARED FOR REGULAR PRODUCTION/RESET THE MACHINE &
          OFFER FRESH SAMPLE
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={4}>
          PREPARED BY :
        </th>
        {moduleData.report_prepared_by?.map((prepBy: any, prepByIndex: any) => {
          return (
            <th style={{ textAlign: 'center' }}>
              <TableInput
                isView={isView}
                type="text"
                name={`report_prepared_by${prepByIndex}`}
                value={moduleData.report_prepared_by[prepByIndex] ?? null}
                onChange={(e) => {
                  moduleData.report_prepared_by[prepByIndex] = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </th>
          );
        })}
      </tr>
      <tr>
        <th align="left" colSpan={4}>
          APPROVED BY :
        </th>
        {moduleData.report_approved_by?.map((apprBy: any, apprByIndex: any) => {
          return (
            <th style={{ textAlign: 'center' }}>
              <TableInput
                isView={isView}
                type="text"
                name={`report_approved_by${apprByIndex}`}
                value={moduleData?.report_approved_by[apprByIndex] ?? null}
                onChange={(e) => {
                  moduleData.report_approved_by[apprByIndex] = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </th>
          );
        })}
      </tr>
    </tfoot>
    // <tfoot>
      
    //   <tr>
    //     <th align="left" colSpan={18}>
    //       Status:
    //       <TableInput
    //         isView={isView}
    //         name="status"
    //         type="text"
    //         value={moduleData?.status}
    //         onChange={(e) => {
    //           moduleData.status = e.target.value;
    //           setRender((prev) => !prev);
    //         }}
    //       />
    //     </th>
    //   </tr>
    //   <tr>
    //     <th colSpan={8} align="left">
    //       Inspected By:
    //       <TableInput
    //         isView={isView}
    //         name="batch_no"
    //         type="text"
    //         value={moduleData?.inspected_by}
    //         onChange={(e) => {
    //           moduleData.inspected_by = e.target.value;
    //           setRender((prev) => !prev);
    //         }}
    //       />
    //     </th>
    //     <th colSpan={10} align="left">
    //       Approved By:
    //       <TableInput
    //         isView={isView}
    //         name="approved_by"
    //         type="text"
    //         value={moduleData?.approved_by}
    //         onChange={(e) => {
    //           moduleData.approved_by = e.target.value;
    //           setRender((prev) => !prev);
    //         }}
    //       />
    //     </th>
    //   </tr>
    // </tfoot>
  );
};

export default TFoot;
