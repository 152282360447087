import React, { useState } from 'react';
import { IconButton, MenuItem, Tooltip } from '@mui/material';
import CustomSelect from '../../../../components/common/SelectField';
import ObservationInput from './ObservationInput';
import { handleChangeColorOnRemarks } from '../../helpers/handleChangeColorOnRemarks';
import DeleteIcon from '@mui/icons-material/Delete';
import { TableInput } from '../../../../components/common';
import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import { getBackgroundColor, tableHeaderObj2 } from '../../constant';
import { convToTime } from '../../../../utils/helpers';
import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';

interface IProps {
  moduleData: any;
  tabValue: number;
  isView: boolean;
}
const TBody = ({ isView, moduleData, tabValue }: IProps) => {
  const [_, setRender] = useState(false);

  tableHeaderObj2[0][4]['component'] = isView ? (
    moduleData?.setups?.[tabValue]?.times_1 ? (
      convToTime(moduleData?.setups?.[tabValue]?.times_1)
    ) : (
      ''
    )
  ) : (
    <TimePicker
      sx={{ width: '140px', background: '#f8f9fa' }}
      value={
        moduleData?.setups?.[tabValue]?.times_1
          ? moment(moduleData?.setups?.[tabValue]?.times_1)
          : null
      }
      onChange={(date) => {
        moduleData.setups[tabValue].times_1 = date;
        setRender((prev) => !prev);
      }}
    />
  );
  tableHeaderObj2[0][5]['component'] = isView ? (
    moduleData?.setups?.[tabValue]?.times_2 ? (
      convToTime(moduleData?.setups?.[tabValue]?.times_2)
    ) : (
      ''
    )
  ) : (
    <TimePicker
      sx={{ width: '140px', background: '#f8f9fa' }}
      value={
        moduleData?.setups?.[tabValue]?.times_2
          ? moment(moduleData?.setups?.[tabValue]?.times_2)
          : null
      }
      onChange={(date) => {
        moduleData.setups[tabValue].times_2 = date;
        setRender((prev) => !prev);
      }}
    />
  );

  tableHeaderObj2[0][6]['component'] = isView ? (
    moduleData?.setups?.[tabValue]?.times_3 ? (
      convToTime(moduleData?.setups?.[tabValue]?.times_3)
    ) : (
      ''
    )
  ) : (
    <TimePicker
      sx={{ width: '140px', background: '#f8f9fa' }}
      value={
        moduleData?.setups?.[tabValue]?.times_3
          ? moment(moduleData?.setups?.[tabValue]?.times_3)
          : null
      }
      onChange={(date) => {
        moduleData.setups[tabValue].times_3 = date;
        setRender((prev) => !prev);
      }}
    />
  );

  return (
    <>
      <TableHeaderHtmlCustom tableHeaderObj={tableHeaderObj2} />
      <tbody>
        {moduleData.setups[tabValue] &&
          moduleData.setups[tabValue].setup_list &&
          moduleData.setups[tabValue].setup_list.map(
            (spec: any, index: number) => (
              <tr>
                <td colSpan={2}>{index + 1}</td>
                <td colSpan={3}>{spec.parameter}</td>
                <td colSpan={2}>{spec.specification}</td>
                <td colSpan={2}>{spec.methods}</td>

                {Array.from({ length: 5 }, (_, obsIndex) => (
                  <ObservationInput
                    {...{ spec, isView, obsIndex }}
                    obsNumber={0}
                    checkType={spec.appearance}
                  />
                ))}
                {Array.from({ length: 5 }, (_, obsIndex) => (
                  <ObservationInput
                    {...{ spec, isView, obsIndex }}
                    obsNumber={1}
                    checkType={spec.appearance}
                  />
                ))}
                {Array.from({ length: 5 }, (_, obsIndex) => (
                  <ObservationInput
                    {...{ spec, isView, obsIndex }}
                    obsNumber={2}
                    checkType={spec.appearance}
                  />
                ))}
              </tr>
            )
          )}
        <tr>
          <td colSpan={9}>JUDGEMENT (OK / NG) :</td>

          {['obs_1_judgement', 'obs_2_judgement', 'obs_3_judgement'].map(
            (params) =>
              moduleData?.setups?.[tabValue]?.[params]?.map(
                (item: any, paramIdx: number) => (
                  <td
                    key={params + paramIdx}
                    style={{
                      backgroundColor: isView ? getBackgroundColor(item) : '',
                    }}
                  >
                    {isView ? (
                      <span>{item?.toUpperCase()}</span>
                    ) : (
                      <CustomSelect
                        name="dropdown"
                        value={item || ''}
                        disabled={isView}
                        onChange={(e) => {
                          moduleData.setups[tabValue][params][paramIdx] =
                            e.target.value;
                          setRender((prev) => !prev);
                        }}
                        sx={{
                          minWidth: '90px',
                          textAlign: 'center',
                          backgroundColor: getBackgroundColor(item),
                        }}
                      >
                        <MenuItem value="">NONE</MenuItem>
                        <MenuItem value="ok">OK</MenuItem>
                        <MenuItem value="ng">NG</MenuItem>
                      </CustomSelect>
                    )}
                  </td>
                )
              )
          )}
        </tr>
        <tr>
          <th colSpan={4} align="left">
            INSP BY :
          </th>
          <th colSpan={4} align="left">
            <TableInput
              name="insp_by_1"
              type="text"
              value={moduleData?.setups?.[tabValue]?.insp_by_1}
              onChange={(e) => {
                moduleData.setups[tabValue].insp_by_1 = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </th>
          <th colSpan={4} align="left">
            INSP BY :
          </th>
          <th colSpan={4} align="left">
            <TableInput
              name="insp_by_2"
              type="text"
              value={moduleData?.setups?.[tabValue]?.insp_by_2}
              onChange={(e) => {
                moduleData.setups[tabValue].insp_by_2 = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </th>
          <th colSpan={4} align="left">
            INSP BY :
          </th>
          <th colSpan={4} align="left">
            <TableInput
              name="insp_by_3"
              type="text"
              value={moduleData?.setups?.[tabValue]?.insp_by_3}
              onChange={(e) => {
                moduleData.setups[tabValue].insp_by_3 = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </th>
        </tr>
        <tr>
          <th colSpan={4} align="left">
            REMARKS
          </th>
          <th colSpan={4} align="left">
            <TableInput
              name="remarks_1"
              type="text"
              value={moduleData?.setups?.[tabValue]?.remarks_1}
              onChange={(e) => {
                moduleData.setups[tabValue].remarks_1 = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </th>
          <th colSpan={4} align="left">
            REMARKS
          </th>
          <th colSpan={4} align="left">
            <TableInput
              name="remarks_2"
              type="text"
              value={moduleData?.setups?.[tabValue]?.remarks_2}
              onChange={(e) => {
                moduleData.setups[tabValue].remarks_2 = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </th>
          <th colSpan={4} align="left">
            REMARKS
          </th>
          <th colSpan={4} align="left">
            <TableInput
              name="remarks_3"
              type="text"
              value={moduleData?.setups?.[tabValue]?.remarks_3}
              onChange={(e) => {
                moduleData.setups[tabValue].remarks_3 = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </th>
        </tr>
      </tbody>
    </>
  );
};

export default TBody;
