import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
interface IProps {
  moduleData: any;
  isView: boolean;
}

const theadData = ['Module', 'Plan', 'Ok Qty.', 'Productivity %', 'Remarks'];

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={6}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading="DAILY PRODUCTION REPORT "
      />
      <tr>
        <th colSpan={1} align="left">
          Date:{' '}
        </th>
        <th colSpan={5} align="left">
          {isView ? (
            formatDate(moduleData.rejectionNote_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.rejectionNote_date)}
              onChange={(date: any) => {
                moduleData.rejectionNote_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        {theadData.map((heading, index) => (
          <th key={heading + index} colSpan={heading === 'REMARKS' ? 2 : 1}>
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
