import { useState } from 'react';
import { TableInput } from '../../../../components/common';

interface Ifoot {
  moduleData: any;
  isView: boolean;
}
const TFoot = ({ moduleData, isView }: Ifoot) => {
  const [render, setRender] = useState(false);

  return (
    <tfoot>
      <tr>
        <th>VERIFIED BY :</th>
        <th colSpan={2}>
          {
            <TableInput
              isView={isView}
              name="verified_by"
              type="text"
              value={moduleData.verified_by}
              onChange={(e) => {
                moduleData.verified_by = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          }
        </th>
        <th>RECEIVED BY :</th>
        <th colSpan={2}>
          {
            <TableInput
              isView={isView}
              name="received_by"
              type="text"
              value={moduleData.received_by}
              onChange={(e) => {
                moduleData.received_by = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          }
        </th>
        <th>CHECKED BY :</th>
        <th colSpan={2}>
          {
            <TableInput
              isView={isView}
              name="checked_by"
              type="text"
              value={moduleData.checked_by}
              onChange={(e) => {
                moduleData.checked_by = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          }
        </th>
      </tr>
      <tr>
        <th>PDI QA:</th>
        <th colSpan={3}>
          {
            <TableInput
              isView={isView}
              name="pdi_qa"
              type="text"
              value={moduleData.pdi_qa}
              onChange={(e) => {
                moduleData.pdi_qa = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          }
        </th>
        <th colSpan={2}>INCOMING QA:</th>
        <th colSpan={3}>
          {
            <TableInput
              isView={isView}
              name="incoming_qa"
              type="text"
              value={moduleData.incoming_qa}
              onChange={(e) => {
                moduleData.incoming_qa = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          }
        </th>
      </tr>
      <tr>
        <th>PRD:</th>
        <th colSpan={3}>
          {
            <TableInput
              isView={isView}
              name="prd"
              type="text"
              value={moduleData.prd}
              onChange={(e) => {
                moduleData.prd = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          }
        </th>
        <th colSpan={2}>STORE :</th>
        <th colSpan={3}>
          {
            <TableInput
              isView={isView}
              name="store"
              type="text"
              value={moduleData.store}
              onChange={(e) => {
                moduleData.store = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          }
        </th>
      </tr>
      <tr>
        <th colSpan={9} style={{ fontSize: '20px' }}>
          NOTE : Disposition : --------- No. of Pcs weighed and weight ……………..KG
          sent to scrap yard and ………… pcs sent to incoming rejection store then
          to LLR.
        </th>
      </tr>
      <tr>
        <th colSpan={2}>APPROVED BY:</th>
        <th colSpan={7}>
          {
            <TableInput
              isView={isView}
              name="approved_by"
              type="text"
              value={moduleData.approved_by}
              onChange={(e) => {
                moduleData.approved_by = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          }
        </th>
      </tr>
      <tr>
        <th colSpan={3}>F.NO.: HO-F-QAS-12</th>
        <th colSpan={3}>REV. NO. 02</th>
        <th colSpan={3}>DATE : 24.09.2013</th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
