import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
// import { IModule } from '../../interface';

interface IProps {
  process_no: any;
}
const TFootRow = ({ process_no }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tr style={{ background: '#a9e34b' }}>
      {process_no == 1 && (
        <>
          <th colSpan={3}>CHECKING METHOD</th>
          <th colSpan={7}>MEASURING TAPE</th>
        </>
      )}
      {process_no == 4 && (
        <>
          <th colSpan={3}>CHECKING METHOD</th>
          <th colSpan={7}>VISUAL</th>
        </>
      )}
      {process_no == 5 && (
        <>
          <th colSpan={3}>CHECKING METHOD</th>
          <th colSpan={7}>VISUAL / INSPECTION GAUGE</th>
        </>
      )}
    </tr>
  );
};

export default TFootRow;
