interface Ifoot {
  moduleData: any;
  setRender: any;
}

const TFoot = ({ moduleData, setRender }: Ifoot) => {
  return (
    <tfoot style={{ background: '#94d82d' }}>
      <tr>
        <th colSpan={2}></th>
        <th align="left">
          Total Target Qty / Shift : {moduleData?.total_target_qty}{' '}
        </th>
        <th colSpan={1} align="left">
          Total Inspected Qty / Shift : {moduleData?.total_check_qty}
        </th>
        <th colSpan={1} align="left">
          Total Ok Qty / Shift : {moduleData?.total_ok_qty}
        </th>
        <th align="left">
          Total Reason Of Rejection Qty / Shift :{' '}
          {'' + moduleData?.total_reason_qty}
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
