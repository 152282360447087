import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import { TableCompHead } from '../../../../components/ui';
import { useQuery } from '../../../../hooks/UseQuery';
import { moduleConstant } from '../constant';
interface IProps {
  rejectionChallanHead: any;
  isView: boolean;
}
const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '100px',
  height: '50px',
};

const THead = ({ rejectionChallanHead, isView }: IProps) => {
  const date = useQuery('date');
  const year = date && new Date(date).getFullYear();
  const [rowRender, setRowRender] = useState(false);
  const tableHeaderObj = [
    [
      { text: 'S.NO', style: style1 },
      { text: 'ITEM CODE', style: style1 },
      { text: 'DESCRIPTION', style: style1 },
      { text: 'UOM', style: style1 },
      { text: 'QTY. RETURNED', style: style1 },
      { text: 'REASON FOR RETURN', style: style1 },
      { text: 'TOTAL SCRAP', style: style1 },
    ],
  ];

  const handleChange = (e: any) => {
    rejectionChallanHead[e.target.name] = e.target.value;
    setRowRender((prev) => !prev);
  };
  return (
    <thead>
      <TableCompHead
        colSpan={7}
        rowSpan={3}
        labels={['F.NO.:', 'Rev No.:', 'Date:']}
        values={['HO-F-QAS-09', '01', '14-08-2012']}
        heading={`${moduleConstant?.heading} - ${year}`}
      />
      <tr>
        <th colSpan={4} align="left">
          Dept. Code:
          <TableInput
            isView={isView}
            type="text"
            name="dept_code"
            value={rejectionChallanHead?.dept_code || null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </th>
        <th colSpan={4} align="left">
          Dept.:
          <TableInput
            isView={isView}
            type="text"
            name="dept"
            value={rejectionChallanHead?.dept || null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={7} align="left">
          Date.:
          <DatePickerMui
            label=""
            onChange={(date) => {
              rejectionChallanHead.date = moment(date || null);
              setRowRender((prev: any) => !prev);
            }}
            value={
              (rejectionChallanHead?.date &&
                moment(rejectionChallanHead?.date)) ||
              null
            }
          />
        </th>
      </tr>

      <TableHeaderHtmlCustom tableHeaderObj={tableHeaderObj} />
    </thead>
  );
};

export default THead;
