import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import { IData } from './helpers/Interface';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import lodash from 'lodash';
import { initialState } from './helpers/initialState';
import DateGridMui from '../../../components/common/dateCustom/DateGridMui';
import moment from 'moment';
import GridSelect from '../../../components/common/inputs/GridSelect';
import { MenuItem } from '@mui/material';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [moduleData, setModuleData] = useState<IData>({
    ...lodash.cloneDeep(initialState),
  });
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Supplier' : 'Create Supplier'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  const [, setRender] = useState(false);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/vendorRoute/${id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleModuleData(e: React.ChangeEvent<HTMLInputElement>) {
    setModuleData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/vendorRoute/${id}`, moduleData)
        .then((res: any) => {
          if (res?.data?.error?.message === 'vendor must be unique') {
            return alert('same vendor name exists');
          }
          setIsModal(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await axios
        .post('/api/vendorRoute', moduleData)
        .then((res: any) => {
          if (res?.data?.error?.message === 'vendor must be unique') {
            return alert('same vendor name exists');
          }
          setIsModal(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'vender_name'}
        name={'vender_name'}
        html_for={'vender_name'}
        label_name={'Supplier Name(Main Source) :'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.vender_name || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'mateiral_description'}
        name={'mateiral_description'}
        html_for={'mateiral_description'}
        label_name={'Material Description:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.mateiral_description || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'alternate_source_1'}
        name={'alternate_source_1'}
        html_for={'alternate_source_1'}
        label_name={'Alternate Source 1:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.alternate_source_1 || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'alternate_source_2'}
        name={'alternate_source_2'}
        html_for={'alternate_source_2'}
        label_name={'Alternate Source 2:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.alternate_source_2 || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'original_source'}
        name={'original_source'}
        html_for={'original_source'}
        label_name={'Original Source:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.original_source || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'vender_code'}
        name={'vender_code'}
        html_for={'vender_code'}
        label_name={'Supplier Code :'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.vender_code || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'product_service'}
        name={'product_service'}
        html_for={'product_service'}
        label_name={'Product / Service:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.product_service || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'address'}
        name={'address'}
        html_for={'address'}
        label_name={'Address: '}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.address || ''}
        onChange={handleModuleData}
      />

      <GridInputs
        id={'contact_person'}
        name={'contact_person'}
        html_for={'contact_person'}
        label_name={'Contact Person:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.contact_person || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'contact_no'}
        name={'contact_no'}
        html_for={'contact_no'}
        label_name={'Contact No.:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.contact_no || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'email_id'}
        name={'email_id'}
        html_for={'email_id'}
        label_name={'Email ID:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.email_id || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'lead_time'}
        name={'lead_time'}
        html_for={'lead_time'}
        label_name={'Lead Time:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.lead_time || ''}
        onChange={handleModuleData}
      />
      <DateGridMui
        lable="Inducted on Date:"
        onChange={(date: any) => {
          moduleData.inducted_on_date = date;
          setRender((prev) => !prev);
        }}
        value={moment(moduleData.inducted_on_date || null)}
      />

      <GridInputs
        id={'qms_certification_iso_iatf'}
        name={'qms_certification_iso_iatf'}
        html_for={'qms_certification_iso_iatf'}
        label_name={'QMS Certification (ISO / IATF ):'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.qms_certification_iso_iatf || ''}
        onChange={handleModuleData}
      />

      <DateGridMui
        lable="QMS Certification
        (Valid Upto):"
        onChange={(date: any) => {
          moduleData.qms_certification_valid_upto_date = date;
          setRender((prev) => !prev);
        }}
        value={moment(moduleData.qms_certification_valid_upto_date) || null}
      />
      <DateGridMui
        lable="Customer Audit Due Date:"
        onChange={(date: any) => {
          moduleData.audit_due_date = date;
          setRender((prev) => !prev);
        }}
        value={moment(moduleData.audit_due_date || null)}
      />
      <GridInputs
        id={'score_last_audit'}
        name={'score_last_audit'}
        html_for={'score_last_audit'}
        label_name={'Score of Last Audit :'}
        input_type={'number'}
        focusType={'onblur'}
        value={moduleData?.score_last_audit || ''}
        onChange={handleModuleData}
      />
      <GridSelect
        name="status"
        label_name="Previous Audit Status:"
        html_for={'status'}
        value={moduleData?.status || ''}
        onChange={(e) => {
          moduleData.status = e.target.value;
          setRender((prev) => !prev);
        }}
      >
        <MenuItem value="NC">
          NC
        </MenuItem>
        <MenuItem value="CLOSED">
          CLOSED
        </MenuItem>
        <MenuItem value="NOT">
          NOT
        </MenuItem>
      </GridSelect>

      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remarks:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.remarks || ''}
        onChange={handleModuleData}
        last_child={2}
      />

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
