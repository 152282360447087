import React, { useState, useEffect } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { convToTime } from '../../../utils/helpers';
import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
import { SearchBar } from '../../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
} from '../../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import ViewFile from './components/ViewFile';
import formatDate from '../../../components/common/formatDate';
import TableScroll from '../../../components/ui/renderTable/TableScroll';
import { tableHeaderObj } from './helpers/listTableHeader';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import ViewFileImage from './helpers/ViewFileImage';

const List = () => {
  const [data, setData] = useState<any>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [complaintId, setComplaintId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const [id, setId] = useState<string | null>(null);
  const [isFileOpenPopup, setIsFileOpenPopup] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [contentType, setContentType] = useState('');
  const [filterCustomer, setFilterCustomer] = useState<any>([]);
  const search = useQuery('search') || '';
  const filter = useQuery('filter') || 'createdAt';
  const page = useQuery('page') || 1;
  const customer = useQuery('customer');
  const defect = useQuery('defects');
  const [all, setAll] = useState(false);
  const [docFiles, setDocFiles] = useState<any>({});

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?'
  );

  useEffect(() => {
    axios
      .get('/api/vendorRoute/vendor/forAutoComplete')
      .then((res) => {
        if (res?.data) {
          const formattedData = res.data.map((customer: any) => ({
            name: customer.vender_name,
            value: customer.vender_name,
          }));
          setFilterCustomer([...formattedData]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/supplierComplaintRoutes/?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}&customer=${customer}&defects=${defect}&all=${all}`
      )
      .catch((err) => {
        console.log(err);
      });

    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteCustomerConcern = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/supplierComplaintRoutes/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  const upload8dCapaFile = async (e: any, id: any) => {
    if (!window.confirm('Are you sure you want upload this Files')) return;
    const files = e.target.files;
    if (!files) return;
    let formData = new FormData();
    for (const key of Object.keys(files)) {
      formData.append('files', files[key]);
    }

    axios
      .patch(`/api/supplierComplaintRoutes/handleUpload/${id}`, formData)
      .then((res) => {
        if (res.data) {
          alert('document upload successly');
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useDebounce(fetchData, 500, [
    page,
    search,
    render,
    isModal,
    filter,
    customer,
    defect,
    all,
    openPopup,
  ]);
  return (
    <>
      <ChildHeader text="SUPPLIER COMPLAINT REGISTER">
        <AddButton
          onClick={() => {
            setAll((value) => !value);
          }}
          label={all ? 'Hide' : 'Save'}
        />
        <AddButton
          onClick={() => setIsModal(true)}
          label="NEW SUPPLIER COMPLAINT"
        />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search By Problem Desc." />
        <FilterBy
          name="customer"
          label="Filter By Supplier"
          options={filterCustomer}
        />
      </SearchFilterContainer>

      <TableScroll>
        <TableCustom tableHeaderObj={tableHeaderObj}>
          <TableBody>
            {data &&
              data.map((item: any, index: number) => (
                <React.Fragment key={item?._id}>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {perPage * (+page - 1) + index + 1}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item?.material_receipt_date &&
                        formatDate(item.material_receipt_date)}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item?.vendor_id?.vender_name}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item.ri_part_id?.part_name}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item?.process}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item?.invoice_date && formatDate(item.invoice_date)}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item?.invoice_no}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item?.invoice_qty}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item?.problem_desc}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item?.action_taken}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item?.action_taken_by_supplier}
                    </TableCell>

                    {[
                      'jan',
                      'feb',
                      'mar',
                      'apr',
                      'may',
                      'jun',
                      'jul',
                      'aug',
                      'sep',
                      'oct',
                      'nov',
                      'dec',
                    ].map((month: any) => (
                      <TableCell
                        style={{
                          ...(item[month] === 'repeated' && {
                            background: '#fa5252',
                            border: '1px solid #f8f9fa',
                            borderBottom: '1px solid #212529',
                          }),
                          ...(item[month] === 'not repeated' && {
                            background: '#51cf66',
                            border: '1px solid #f8f9fa',
                            borderBottom: '1px solid #212529',
                          }),
                          width: '50px',
                        }}
                        align="center"
                      ></TableCell>
                    ))}

                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item?.remarks}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {item?.sign}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {
                        <ViewIconButton
                          tooltipTitle="view problem photo"
                          onClick={() => {
                            setId(item._id || null);
                            setIsFileOpenPopup(true);
                            setContentType('image');
                          }}
                        />
                      }
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      {
                        <ViewIconButton
                          tooltipTitle="view supporting doc"
                          onClick={() => {
                            setId(item._id || null);
                            setIsFileOpenPopup(true);
                            setContentType('file');
                          }}
                        />
                      }
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ border: '1px solid black' }}
                    >
                      <Stack
                        direction="row"
                        sx={{ justifyContent: 'center', alignItems: 'center' }}
                      >
                        <CustomUploadButton
                          accept="image/png,image/jpeg,image/jpg,application/pdf"
                          id={`${index}_files_`}
                          multiple={true}
                          onChange={(e) => {
                            upload8dCapaFile(e, item._id);
                          }}
                        />
                        <ViewIconButton
                          tooltipTitle="view 8D/CAPA"
                          onClick={() => {
                            setId(item._id || null);
                            setOpenPopup(true);
                            setDocFiles(item.d8_capa_files);
                          }}
                        />
                      </Stack>
                    </TableCell>
                    <TableCell sx={{ border: '1px solid black' }}>
                      <Stack direction="row" sx={{ justifyContent: 'center' }}>
                        <EditIconButton
                          tooltipTitle="EDIT"
                          onClick={() => {
                            setComplaintId(item?._id || '');
                            setIsModal(true);
                          }}
                        />

                        <DeleteIconButton
                          tooltipTitle="DELETE"
                          onClick={() => {
                            deleteCustomerConcern(item?._id || '');
                          }}
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </TableCustom>
      </TableScroll>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setComplaintId(null);
          setIsModal(false);
        }}
        title="SUPPLIER COMPLAINT REGISTER"
      >
        <Create id={complaintId} setIsModal={setIsModal} />
      </ModalCustom>
      <ModalCustom
        title="FILE LIST"
        openModal={isFileOpenPopup}
        closeModal={() => {
          setId(null);
          setIsFileOpenPopup(false);
        }}
      >
        <ViewFile id={id} contentType={contentType} />
      </ModalCustom>
      <ModalCustom
        title="FILE LIST"
        openModal={openPopup}
        closeModal={() => {
          setId(null);
          setOpenPopup(false);
        }}
      >
        <ViewFileImage
          id={id}
          docFiles={docFiles}
          setIsFileOpenPopup={setOpenPopup}
        />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
