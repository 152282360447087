import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import axios from 'axios';

import { AddButton } from '../../components/common/button/CustomButtons';
import ChildHeader from '../../components/ui/ChildHeader';
// import { SearchFilterContainer } from '../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../components/common/FilterBy';
// import { SearchBar } from '../../components/common/SearchBar';
import {
  CustomPagination,
  PersitedDatePicker,
  // TableCustom,
} from '../../components/common';
// import useDebounce from '../../hooks/useDebounce';
// import {
//   EditIconButton,
//   DeleteIconButton,
// } from '../../components/common/button/CustomIconButton';
// import ModalCustom from '../../components/common/Modal';
import { useQuery } from '../../hooks/UseQuery';
import useConfirm from '../../components/common/useConfirm';
import { IData } from './helpers/interface';
import { useNavigate } from 'react-router-dom';
// import ApprovalSelect from './components/tablelist/ApprovalSelect';
import SupervisorTable from './components/tablelist/SupervisorTable';

const List = () => {
  const date = useQuery('date');
  const [tableData, setTableData] = useState<IData[]>([]);
  const [render, setRender] = useState(false);
  const navigate = useNavigate();
  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE ',
    'Are you sure you want to delete this document?',
  );

  useEffect(() => {
    axios
      .get(`/api/inprocessRejectionChallan`)
      .then((res) => {
        if (res?.data?.results) setTableData(res.data?.results);
      })
      .catch((err) => console.log(err));
  }, [render, date]);

  const deleteDocument = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    axios
      .delete(`/api/inprocessRejectionChallan/${id}`)
      .then(() => {
        alert('document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => {
        console.log(err);
      });
    return;
  };
  console.log('tableData', tableData);

  return (
    <>
      <ChildHeader text="INPROCESS REJECTION CHALLAN">
        <AddButton
          onClick={() =>
            navigate(`/common/createEditInprocessRejectionChallan`)
          }
          label="NEW REPORT"
        />
      </ChildHeader>

      <SupervisorTable {...{ deleteDocument, tableData }} />
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
