import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
interface IProps {
  moduleData: any;
  tabValue: number;
  isView: boolean;
  machineData: any;
}

const TController = ({ moduleData, tabValue, isView, machineData }: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead {...{ moduleData, tabValue, isView, machineData }} />
        <TBody {...{ moduleData, tabValue, isView }} />
        <TFoot
          preparedBy={moduleData.report_prepared_by}
          approvedBy={moduleData.report_approved_by}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
