import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import { TbFaceIdError, TbNotebook } from 'react-icons/tb';
import { RiCalendarCheckFill, RiDeleteBin4Fill } from 'react-icons/ri';
import { TiArrowLoopOutline } from 'react-icons/ti';
import { HiDocumentReport } from 'react-icons/hi';
import {
  MdOutlineProductionQuantityLimits,
  MdReceiptLong,
  MdRoomPreferences,
} from 'react-icons/md';
import { BsHourglassSplit, BsJournalBookmarkFill } from 'react-icons/bs';
import { FaCalculator } from 'react-icons/fa';
import { GiBreakingChain, GiRingMould } from 'react-icons/gi';
import { IoTodaySharp } from 'react-icons/io5';
import { MdFilterTiltShift } from 'react-icons/md';
import { GrUserWorker, GrVmMaintenance } from 'react-icons/gr';

const tiles = [
  // {
  //   sNo: 9,
  //   link: '/common/dailyProdReportList',
  //   isLink: true,
  //   icon: <TbNotebook size="5rem" color="#a12222" />,
  //   p: 'DAILY PRODUCTION',
  //   span: 'REPORT',
  //   isIcon: true,
  // },
  {
    sNo: 2,
    link: '/common/productionDefectsList?filter=createdAt',
    isLink: true,
    icon: <TbFaceIdError size="5rem" color="#a12222" />,
    p: 'Master List of',
    span: 'Production Defects',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/rotorSection/dailyProductionList?documentType=pressShop1&heading=Press Shop 1',
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#003566" />,
    p: 'Daily Production', // from ipcl daily production
    span: 'Report',
    isIcon: true,
  },

  // {
  //   sNo: 9,
  //   link: '/common/finalInspectionReportList',
  //   isLink: true,
  //   icon: <RiCalendarCheckFill size="5rem" color="purple" />,
  //   p: 'FINAL INSPECTION ',
  //   span: 'REPORT',
  //   isIcon: true,
  // },
  // {
  //   sNo: 9,
  //   link: '/common/twoHourlyProductionReportList',
  //   isLink: true,
  //   icon: <BsHourglassSplit size="5rem" color="orange" />,
  //   p: 'HOURLY PRODUCTION',
  //   span: 'REPORT',
  //   isIcon: true,
  // },
  {
    sNo: 2,
    link: '/common/mouldingList?filter=createdAt',
    isLink: true,
    icon: <GiRingMould size="5rem" color="blue" />,
    p: 'Master List of',
    span: 'Mould',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/redbinAnalysis',
    isLink: true,
    icon: <GiRingMould size="5rem" color="blue" />,
    p: 'REDBIN ',
    span: 'ANALYSIS',
    isIcon: true,
  },
  // {
  //   sNo: 9,
  //   link: '/common/shiftProductionReportList',
  //   isLink: true,
  //   icon: <MdFilterTiltShift size="5rem" color="#5f3dc4" />,
  //   p: 'SHIFT PRODUCTION',
  //   span: 'REPORT',
  //   isIcon: true,
  // },
  // {
  //   sNo: 9,
  //   link: '/common/dailyProductionReportList',
  //   isLink: true,
  //   icon: <IoTodaySharp size="5rem" color="indigo" />,
  //   p: 'DAILY PRODUCTION',
  //   span: 'REPORT',
  //   isIcon: true,
  // },
  // {
  //   sNo: 27,
  //   link: '/common/dailyProductionReport/dashboard',
  //   isLink: true,
  //   icon: <MdRoomPreferences size="5rem" color="#003566" />,
  //   p: 'DAILY PRODUCTION REPORT',
  //   span: 'DASHBOARD',
  //   isIcon: true,
  // },
  // {
  //   sNo: 9,
  //   link: '/common/breakDownStatusList',
  //   isLink: true,
  //   icon: <GiBreakingChain size="5rem" color="brown" />,
  //   p: 'BREAK DOWN',
  //   span: 'STATUS',
  //   isIcon: true,
  // },
  // {
  //   sNo: 5,
  //   link: '/common/prevantiveMaintenance_NEW_Tiles',
  //   isLink: true,
  //   icon: <GrVmMaintenance size="5rem" color="#087f5b" />,
  //   p: 'Mould Preventive Maintenance',
  //   span: 'Plan',
  //   isIcon: true,
  // },
  // {
  //   sNo: 2,
  //   link: '/common/productionWorkersList?filter=createdAt',
  //   isLink: true,
  //   icon: <GrUserWorker size="5rem" color="#a12222" />,
  //   p: 'Master List of',
  //   span: 'Workers',
  //   isIcon: true,
  // },
  {
    sNo: 5,
    link: '/common/breakDownSlipList',
    isLink: true,
    icon: <MdReceiptLong size="5rem" color="#a12222" />,
    p: 'BREAK DOWN',
    span: 'SLIP',
    isIcon: true,
  },
];

const Home = () => {
  return (
    <>
      <ChildHeader text="Production Department" />
      <RenderTiles
        height="100%"
        tilesObj={tiles}
        justify="start"
        columns="0fr"
      />
    </>
  );
};

export default Home;
