import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import moment from 'moment';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../components/common/button/CustomIconButton';
import CustomSelect from '../../../../components/common/SelectField';
import { Grid, MenuItem } from '@mui/material';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';

interface IProps {
  item: any;
  isView: any;
  handleDelete: any;
  index: number;
  setPartIdx: React.Dispatch<React.SetStateAction<number>>;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  defects: any;
}
const getTotalProblematicQty = (defects: any, item: any) => {
  return defects.reduce((sum: any, defect: any) => {
    if (item[defect._id]) return sum + +item[defect._id];
    return sum;
  }, 0);
};

const TBodyRow = ({
  item,
  index,
  isView,
  handleDelete,
  setPartIdx,
  setIsOpenPopup,
  defects,
}: IProps) => {
  const [rowRender, setRowRender] = useState(false);

  const handleChange = (e: any) => {
    item[e.target.name] = e.target.value;
    setRowRender((prev: any) => !prev);
  };

  return (
    <>
      <tr>
        <td style={{ textAlign: 'center', minWidth: '200px' }}>
          <DatePickerMui
            label=""
            onChange={(date) => {
              item.date = moment(date || null);
              setRowRender((prev: any) => !prev);
            }}
            value={(item?.date && moment(item?.date)) || null}
          />
        </td>
        <td>
          <CustomSelect
            name={'shift'}
            value={item?.shift}
            sx={{ width: '95px' }}
            onChange={(e) => {
              item.shift = e.target.value;
              setRowRender((prev: any) => !prev);
            }}
          >
            <MenuItem value="">NONE</MenuItem>
            <MenuItem value="A">A</MenuItem>
            <MenuItem value="B">B</MenuItem>
            <MenuItem value="C">C</MenuItem>
            <MenuItem value="A+B+C">A+B+C</MenuItem>
          </CustomSelect>
        </td>
        <td style={{ width: '250px', minWidth: '250px' }}>
          {isView ? (
            item?.ri_part_id && item.ri_part_id?.part_name
          ) : (
            <Grid container xs={12} direction="row" alignItems={'center'}>
              <Grid item xs={10}>
                <p
                  style={{
                    fontSize: '1.2rem',
                  }}
                >
                  {typeof item?.ri_part_id === 'object' &&
                  item?.ri_part_id !== null
                    ? item.ri_part_id?.part_name
                    : item?.partName}
                  {!item?.ri_part_id && 'Pick Part Name'}
                </p>
              </Grid>

              {item.ri_part_id ? (
                <Grid item xs={2}>
                  <RemoveIconButton
                    tooltipTitle="remove part"
                    onClick={() => {
                      item.ri_part_id = null;
                      item.partNumber = null;
                      item.partName = null;
                      setRowRender((prev: any) => !prev);
                    }}
                  />
                </Grid>
              ) : (
                <Grid item xs={2}>
                  <AddIconButton
                    tooltipTitle="add part"
                    onClick={() => {
                      setIsOpenPopup(true);
                      setPartIdx(index);
                    }}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </td>
        <td style={{ width: '200px', minWidth: '200px' }}>
          {item?.ri_part_id?.part_number || item?.partNumber}
        </td>
        <td style={{ textAlign: 'center', width: '150px' }}>
          {/* <TableInput
            isView={isView}
            type="number"
            name="prob_qty"
            value={item?.prob_qty ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          /> */}
          {getTotalProblematicQty(defects, item)}
        </td>
        {defects.map((defect: any) => (
          <td
            style={{ textAlign: 'center', minWidth: '150px', width: '100px' }}
          >
            <TableInput
              isView={isView}
              type="number"
              name={defect._id}
              value={item[defect._id] || ''}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </td>
        ))}

        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="validation_of_problem"
            value={item?.validation_of_problem ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="return_by"
            value={item?.return_by ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="received_by"
            value={item?.received_by ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="remarks"
            value={item?.remarks ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>
        {!isView && (
          <td style={{ textAlign: 'center' }}>
            <DeleteIconButton
              tooltipTitle="DELETE"
              onClick={() => {
                handleDelete(item?._id, index);
              }}
            />
          </td>
        )}
      </tr>
    </>
  );
};

export default TBodyRow;
