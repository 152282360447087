import { useState } from 'react';
//mport ObservationInput from './ObservationInput';
import CustomSelect from '../../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import { TableInput } from '../../../../../../components/common';

interface IProps {
  apiData: any;
}
const TBody = ({ apiData }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      {apiData.map((data: any, index: number) => {
        const { defectName, freq, cumFreq, cumPerc } = data;
        return (
          <tr>
            <td>{index + 1}</td>
            <td>{defectName}</td>
            <td>{freq}</td>
            <td>{cumFreq}</td>
            <td>{cumPerc}</td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default TBody;
