import moment from 'moment';
import { TableInput, TextAreaInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { useQuery } from '../../../../../hooks/UseQuery';
import React from 'react';

interface Ifoot {
  preparedBy: string | null | undefined;
  approvedBy: string | null | undefined;
}
const TFoot = ({ moduleData, updateModuleData }: any) => {
  const isView = useQuery('isView') === 'true' ? true : false;
  const handleInputChange = (
    fieldName: string,
    value: string | number | null
  ) => {
    updateModuleData('key-pair', null, fieldName, { [fieldName]: value });
  };

  return (
    <tfoot style={{ background: "#15aabf" }}>
      <tr>
        <th></th>
        <th>STATUS</th>
        <th colSpan={4} style={{ textAlign: 'left' }}>
          <TableInput
            isView={isView}
            name="status"
            type="text"
            value={moduleData.status}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
      </tr>
      <tr>
        <th></th>
        <th>DATE</th>
        <th colSpan={4} style={{ textAlign: 'left', background: "white" }}>
          <DatePickerMui
            label=""
            value={(moduleData?.check_date && moment(moduleData?.check_date)) || null}
            onChange={(date) =>
              handleInputChange("check_date", date)
            }

          />
        </th>
      </tr>
      <tr>
        <th></th>
        <th>DONE BY</th>
        <th colSpan={4} style={{ textAlign: 'left' }}>
          <TableInput
            isView={isView}
            name="done_by"
            type="text"
            value={moduleData.done_by}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
      </tr>
      <tr>
        <th></th>
        <th>CHECKED BY</th>
        <th colSpan={4} style={{ textAlign: 'left' }}>
          <TableInput
            isView={isView}
            name="checked_by"
            type="text"
            value={moduleData.checked_by}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
      </tr>
      <tr>
        <th></th>
        <th>APPROVED BY</th>
        <th colSpan={4} style={{ textAlign: 'left' }}>
          <TableInput
            isView={isView}
            name="approved_by"
            type="text"
            value={moduleData.approved_by}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
      </tr>
      <tr>
        <th></th>
        <th>REMARKS</th>
        <th colSpan={4} style={{ textAlign: 'left' }}>
          <TextAreaInput
            isView={isView}
            name="remarks"
            // type="text"
            value={moduleData.remarks}
            onChange={(e: any) =>
              handleInputChange(e.target.name, e.target.value)
            }
          />
        </th>
      </tr>
    </tfoot>
  );
};

export default React.memo(TFoot);
