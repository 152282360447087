import React, { useEffect, useState } from 'react';
import TController from './components/view/TController';
import { IData } from './helpers/interface';
import { initialState } from './helpers/initialState';
import lodash from 'lodash';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import { useQuery } from '../../../hooks/UseQuery';
import PartTable from './components/view/PartTable';
import ModalCustom from '../../../components/common/Modal';
import useConfirm from '../../../components/common/useConfirm';
import RejectionTable from './components/view/RejectionTable';

const View = () => {
  const [moduleData, setModuleData] = useState<any>(
    lodash.cloneDeep(initialState)
  );
  const [partIdx, setPartIdx] = useState(false);
  const [rejectionsIndx, setRejectionsIndx] = useState(0);
  const [isOpenPopupForRejections, setIsOpenPopupRejections] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [employeesProd, setEmployeesProd] = useState([]);
  const [employeesQA, setEmployeesQA] = useState([]);
  const [machineData, setMachineData] = useState([]);
  const [operators, setOperators] = useState([]);
  const [operatorsFinalInspector, setOperatorsFinalInspector] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const isView = useQuery('isView') === 'true' ? true : false;
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?'
  );

  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?'
  );
  const [DialogUpdate, confirmUpdate] = useConfirm(
    'Update',
    'Are you sure you want to update this document?'
  );
  useEffect(() => {
    axios
      .get(`/api/employeeRoute/employee/customerForAutoComplete`)
      .then((res) => {
        setEmployees(res?.data);
      })
      .catch((err) => console.log(err));
    axios
      .get(`/api/employeeRoute/gettingEmployeeByProdOrQa`)
      .then((res) => {
        setEmployeesProd(res?.data?.prod);
        setEmployeesQA(res?.data?.qa);
      })
      .catch((err) => console.log(err));
    axios
      .get('/api/machineRoutes/machine/ForAutocomplete')
      .then((res) => {
        setMachineData(res?.data);
      })
      .catch((err) => console.log(err));
    axios
      .get(`/api/productionWorkersRoutes/workersList/workersForAutoComplete`)
      .then((res) => {
        setOperators(res?.data);
      })
      .catch((err) => console.log(err));
    axios
      .get(`/api/productionWorkersRoutes/worker/finalInspector/autocomplete`)
      .then((res) => {
        setOperatorsFinalInspector(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/finalInspectionReport/${id}`)
        .then((res) => {
          setModuleData(res?.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const handleSumbit = async (isSubmitted: boolean) => {
    if (!moduleData.part_id) {
      alert('Please select part');
      return;
    }
    if (!moduleData.module_date) {
      alert('Please select date');
      return;
    }
    if (!moduleData.machine_id) {
      alert('Please select machine');
      return;
    }
    if (!moduleData.operator_id) {
      alert('Please select shift A operator');
      return;
    }
    if (id) {
      const ans = await confirmUpdate();
      if (!ans) return;
      await axios
        .put(`/api/finalInspectionReport/${id}`, moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('Updated Successfully');
          }
        })
        .catch((err) => console.log(err));
    } else {
      const ans = await confirmSubmit();
      if (!ans) return;
      await axios
        .post('/api/finalInspectionReport', moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('created Successfully');
            navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handelDeleteRows = async (rowIdx: number) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    let data = { ...moduleData };
    data.processes.splice(rowIdx, 1);
    setModuleData(data);
  };

  return (
    <>
      <Box>
        <ChildHeader text="FINAL INSPECTION REPORT ">
          {!isView && (
            <>
              <SubmitButton
                label={moduleData._id ? 'Update' : 'Submit'}
                onClick={() => handleSumbit(true)}
              />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
          handleSumbit,
          handelDeleteRows,
          isOpenPopup,
          setIsOpenPopup,
          setIsOpenPopupRejections,
          setRejectionsIndx,
          setPartIdx,
          employees,
          employeesProd,
          employeesQA,
          machineData,
          operators,
          operatorsFinalInspector,
        }}
      />

      <ModalCustom
        title="Part List"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <PartTable
          {...{
            moduleData,
          }}
        />
      </ModalCustom>
      <ModalCustom
        title="Rejections List"
        openModal={isOpenPopupForRejections}
        closeModal={() => {
          setIsOpenPopupRejections(false);
        }}
      >
        <RejectionTable
          process={moduleData.processes[rejectionsIndx]}
          isView={isView}
        />
      </ModalCustom>
      <DialogDeleteRow isSubmitContent={false} />
      <DialogSubmit />
      <DialogUpdate />
    </>
  );
};

export default View;
