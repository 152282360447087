import React, { useEffect, useState } from 'react';
import TController from './components/view/TController';
import { IData } from './helpers/interface';
import { initialState, process } from './helpers/initialState';
import lodash from 'lodash';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';

const View = () => {
  const [moduleData, setModuleData] = useState<any>(
    lodash.cloneDeep(initialState)
  );
  const [partIdx, setPartIdx] = useState(false);
  const [rejectionsIndx, setRejectionsIndx] = useState(0);
  const [isOpenPopupForRejections, setIsOpenPopupRejections] = useState(false);
  const [moulders, setMoulders] = useState([]);

  const navigate = useNavigate();
  const { id } = useParams();
  const isView = useQuery('isView') === 'true' ? true : false;
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [shift, setShift] = useState('');
  const [operators, setOperators] = useState([]);

  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?'
  );
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?'
  );
  const [DialogUpdate, confirmUpdate] = useConfirm(
    'Update',
    'Are you sure you want to update this document?'
  );
  const [DialogImport, confirmImport] = useConfirm(
    'Import',
    'Are you sure you want to import this document?'
  );

  useEffect(() => {
    axios
      .get(`/api/productionMouldingRoutes/moulderList/moulderForAutoComplete`)
      .then((res) => {
        setMoulders(res.data);
      })
      .catch((err) => console.log(err));
    axios
      .get(`/api/employeeRoute/employee/customerForAutoComplete`)
      .then((res) => {
        setEmployees(res.data);
      })
      .catch((err) => console.log(err));
    axios
      .get(`/api/productionWorkersRoutes/workersList/workersForAutoComplete`)
      .then((res) => {
        setOperators(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/shiftProductionReport/${id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const handleSumbit = async (isSubmitted: boolean) => {
    if (id) {
      const ans = await confirmUpdate();
      if (!ans) return;
      await axios
        .put(`/api/shiftProductionReport/${id}`, moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('Updated Successfully');
          }
        })
        .catch((err) => console.log(err));
    } else {
      const ans = await confirmSubmit();
      if (!ans) return;
      await axios
        .post('/api/shiftProductionReport', moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('created Successfully');
            navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleImport = async (date: any, shift: any) => {
    if (!date) {
      alert('Please select date');
      return;
    }
    const ans = await confirmImport();
    if (!ans) return;

    try {
      const response = await axios.get(
        `/api/twoHourlyProductionReport/getEightHourlyData/getData?date=${date}&shift=${shift}`
      );

      response?.data?.forEach((subProcess: any) => {
        const targetId = subProcess?.part_id?._id;
        const index = moduleData.processes.findIndex(
          (obj: any) => obj.part_id && obj.part_id._id === targetId
        );
        if (index === -1) {
          moduleData.processes.push({
            ...process,
            ...subProcess,
            ...{
              produced_qty:
                +subProcess?.total_ok_qty ||
                0 + +subProcess?.total_reason_qty ||
                0 + +subProcess?.spd_mkt ||
                0 + +subProcess?.closing_wip_qty ||
                0 - +subProcess?.opening_wip_qty ||
                0,
            },
          });
        } else {
          moduleData.processes[index] = {
            ...moduleData.processes[index],
            ...subProcess,
            ...{
              produced_qty:
                +moduleData.processes?.[index]?.total_ok_qty +
                +moduleData.processes?.[index]?.total_reason_qty +
                +moduleData.processes?.[index]?.spd_mkt +
                +moduleData.processes?.[index]?.closing_wip_qty -
                +moduleData.processes?.[index]?.opening_wip_qty,
            },
          };
        }
      });

      setModuleData({ ...moduleData }); // Update state with a new object reference
      alert('Imported Successfully');
    } catch (error) {
      alert('Hourly Production Report Not exist !!!');
      console.error(error);
    }
  };
  const handelDeleteRows = async (rowIdx: number) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    let data = { ...moduleData };
    data.processes.splice(rowIdx, 1);
    setModuleData(data);
  };

  return (
    <>
      <Box>
        <ChildHeader text="SHIFT PRODUCTION REPORT ">
          {!isView && (
            <>
              <SubmitButton
                label="Import Hourly Production Report"
                onClick={() => {
                  handleImport(
                    moduleData.final_inspection_report_date,
                    moduleData.shift
                  );
                }}
              />
              <SubmitButton
                label={moduleData._id ? 'Update' : 'Submit'}
                onClick={() => handleSumbit(true)}
              />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
          handleSumbit,
          handelDeleteRows,
          isOpenPopup,
          setIsOpenPopup,
          setIsOpenPopupRejections,
          setRejectionsIndx,
          setPartIdx,
          moulders,
          setShift,
          employees,
          operators,
        }}
      />
      <DialogDeleteRow isSubmitContent={false} />
      <DialogSubmit />
      <DialogImport />
      <DialogUpdate />
    </>
  );
};

export default View;
